import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
// import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
// ClientName: any = localStorage.getItem("ClientName");
userDatalist: any = [];
clientdetailId: any = 0;
dataTable: any;
statusValue:any={};
clientName : any = localStorage.getItem("ClientInfoName");
imageUrl = "assets/img/user_icon.png"
clientLoginUrl:any = ""
token:any = ""
UserToAsset:any = {"createdTime":"","callSequence":"1","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
userData:any={};
selectedUserId:any='';
roleList:any=[];
assetDatalist:any=[];
assignAsset:any=[];
numbers = [];
  breadcrumbObj: any;
  HistoryList: any = [];
  roleListAssign: any = [];
  sessionHistoryList: any = [];
  customerName: any = '';
  clientModalName: any = '';
constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }
ngOnInit() {
   this.route.params.subscribe(params => {
      if(params['id']){
        this.clientdetailId = params['id'];
        localStorage.setItem("clientdetailId", this.clientdetailId);
      }
  });
  if(this.clientdetailId){
   this.getUserListByClient();
  }
  let User = JSON.parse(localStorage.getItem('Token'))
  let token = User ? User.access_token : ""
  this.token = token
  this.clientLoginUrl = this.apiService.ClientUrl +"impersonateuser?token="+ token+"&userId="+this.clientdetailId;
  this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
    $(document).on('hidden.bs.modal', function () {
      if($('.modal.show').length)
      {
        $('body').addClass('modal-open');
      }
    });
}


/* Get User list */
getUserListByClient(){
  this.userDatalist=[];
  // Destroy Data Table if is already and clear the data
  if ($.fn.DataTable.isDataTable('#data_table_client_user')){
    $('#data_table_client_user').DataTable().destroy();
    this.userDatalist=[];
  }

  this.apiService.getAll('/user/getAllUserbyType?userType=Client&clientId='+this.clientdetailId).subscribe(
    data => {
       if(data.statusCode == 100){
          this.userDatalist = data.user;
          for (let userData of this.userDatalist) {
            if(userData['commModeStr']){
              userData['commModeStr']  = userData['commModeStr'].replace(/[\[\]']+/g, "");
            }
          }
          this.chRef.detectChanges();
          const table: any = $('#data_table_client_user');
          this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0,4,6,9,10,11] }]});
        }else{
          this.userDatalist = [];
          this.chRef.detectChanges();
          if ($.fn.DataTable.isDataTable('#data_table_client_user')){
            $('#data_table_client_user').DataTable().destroy();
          };
          const table: any = $('#data_table_client_user');
          this.chRef.detectChanges();
          this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [0,4,6,9,10,11] }]});

          // this.userDatalist = [];
          // this.chRef.detectChanges();
          // const table: any = $('#data_table');
          // this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0,4,8,9,10] }]});
         }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}

/* Change Client Status */
ChangeUserStatus(id,status){
  Swal({
      title: 'Are you sure?',
      text: "You want to Change Status.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((result) => {
    if (result.value) {
      if(status == false){
        this.statusValue = {"userId":id,"isActive":1};
      }else{
        this.statusValue = {"userId":id,"isActive":0};
      }

     // this.apiService.update('public/user/updateUserStatus',this.statusValue).subscribe(
     this.apiService.update('user/updateUserStatus',this.statusValue).subscribe(
     data => {
         if(data.statusCode == 100){
           Swal({
                position: 'top-end',
                type: 'success',
                title: 'Status Changed Successfully',
                showConfirmButton: false,
                timer: 1500
              }) ;
               this.getUserListByClient();
          }else{
            Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
           }
        },
       error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
        }
      })
}

/* Delete the User */
DeleteUser(userId){
  Swal({
   title: 'Are you sure?',
   text: "You want to delete this User.",
   type: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33',
   confirmButtonText: 'Yes, delete it!'
 }).then((result) => {
   if (result.value) {
    this.apiService.delete('user/deleteUser?userId='+userId, '').subscribe(
      data => {
          if(data.statusCode == 100){
               this.alertService.sweetMessage('success',"User deleted successfully");
               this.getUserListByClient();
           }else{
             this.alertService.sweetMessage('error',data.responseMessage);
            }
         },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
               }
           );
       }
   })
}

/* Deactivate the User */
DeactivateUser(userId){
  Swal({
   title: 'Are you sure?',
   text: "If you deactivate this user then it will no longer be able to log into AMCi1440 and not receive the event notifications. Deactivating the user will remove all its association with Tier, Site and Assets. Would you like to proceed?",
   type: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33',
   confirmButtonText: 'Yes'
 }).then((result) => {
   if (result.value) {
    this.apiService.getAll('user/deactivateUser?userId='+userId+'&isDeactivated=1').subscribe(
      data => {
          if(data.statusCode == 100){
               this.alertService.sweetMessage('success',"User deactivated successfully");
               this.getUserListByClient();
           }else{
             this.alertService.sweetMessage('error',data.responseMessage);
            }
         },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
               }
           );
       }
   })
}

// Restore Deactivated User 
RestoreUser(userId){
  Swal({
   title: 'Are you sure?',
   text: "You want to restore this User.",
   type: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33',
   confirmButtonText: 'Yes, restore it!'
 }).then((result) => {
   if (result.value) {
    this.apiService.getAll('user/deactivateUser?userId='+userId+'&isDeactivated=0').subscribe(
      data => {
          if(data.statusCode == 100){
               this.alertService.sweetMessage('success',"User restored successfully");
               this.getUserListByClient();
           }else{
             this.alertService.sweetMessage('error',data.responseMessage);          
           }
         },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
               }
           );
       }
   })
 }

 impersonate(url, user){

  Swal({
    title: 'Warning',
    text: "You will be redirected to the Client Web Application. You will be impersonating "+user+".\nAny changes made will affect the "+user+" login." ,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonText: 'CANCEL',
    cancelButtonColor: '#d33',
    confirmButtonText: 'CONTINUE'
  }).then((result) => {
    if (result.value) {
      window.open(url,'_blank');
    }
  })
 }

/* Set User data for asset assign model */
setUserData(userData){
  this.userData = userData;
  this.UserToAsset.user.userId = userData.userId;
  this.selectedUserId = userData.userId;
  this.getRoleList();
  this.getAllAssetList(userData.userId);
  this.getAssignAssets();
}

getAllAssetList(id) {
  var URL = 'asset/getAssetListByUserAndTier?userId='+id;
  this.apiService.getAll(URL).subscribe(
      data => {
          if (data.statusCode == 100) {
              this.assetDatalist = data.responseList;
          } else {
              this.assetDatalist = [];
          }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
  );
}

getRoleList(){
  this.apiService.getAll('assetRole/getAllAssetRole').subscribe(
    data => {
      if(data.statusCode == 100){
        this.roleList = data.responseList;
      }else{
        this.roleList =[];
        /*this.alertService.sweetMessage('error',data.responseMessage); */
      }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
    );
}

getAssignAssets(){
  var URL = 'asset/getAssetListAndRoleCountByUser?userId='+this.selectedUserId;
  if ($.fn.DataTable.isDataTable('#assignAssetTable')){
    $('#assignAssetTable').DataTable().destroy();
  };   
  this.apiService.getAll(URL).subscribe(
        data => {
            if (data.statusCode == 100) {
                this.assignAsset = data.responseList;
                         let numbers = []  
                         let total = this.assignAsset[1].length+2
                for (let index = 0; index < total; index++) {
                  const element = this.assignAsset[index];
                  numbers.push(index+1)
                }
                this.numbers = numbers
                  this.chRef.detectChanges();
                  setTimeout(() => {
                    const table: any = $('#assignAssetTable');
                    this.dataTable = table.DataTable({"bInfo": false});
                  }, 100);
            } else {
              // if ($.fn.DataTable.isDataTable('#assignAssetTable')){
              //   $('#assignAssetTable').DataTable().destroy();
              // };
              // const table: any = $('#assignAssetTable');
              // this.dataTable = table.DataTable({"bInfo": false});
              this.assignAsset = data.responseList;
              let numbers = []  
              let total = this.assignAsset[1].length+2
              for (let index = 0; index < total; index++) {
                const element = this.assignAsset[index];
                numbers.push(index+1)
              }
              this.numbers = numbers
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
}


getHistory(id){
  this.getSessionHistory(id);
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('user/getUserHistoryByUserId?userId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.userList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}

getSessionHistory(id){
  this.sessionHistoryList = []
  if ($.fn.DataTable.isDataTable('#sessionHistoryDatatable')) {
    $('#sessionHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('user/getUserSessionHistoryByUserId?userId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.sessionHistoryList = data.userList;
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
      
}


setRolesList(roles){
  this.roleListAssign = roles
}
  
  customeViewMoreModal(data) {
    // $('#HistoryModel').modal('hide');
    this.customerName = '';
    this.clientModalName = data.firstName + ' ' + (data.lastName ? data.lastName : '');
    let tempArr = []
    data.customers.map(items => {
      if (items.name) {
        tempArr.push(items.name)
      }
    });

    this.customerName = tempArr.length ? tempArr.toString() : '';
  }
  getTieToolTip(tiers){
    let str = ''
    if (tiers) {
      if (tiers.length) {
        tiers.map((obj,index)=>{
          if (index==0) {
            str += obj.tierName
          }else{
            str = str+', '+obj.tierName
          }
        })
      }
    }
    return str;
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }
}
