import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AlertService, FormatDateService, CommonServicesService } from '../../../../services/index';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
declare var $ : any, Highcharts:any;
declare var jQuery : any, moment:any;
import 'datatables.net';
import 'datatables.net-bs4';



// Include the core fusioncharts file from core  -
import FusionCharts from 'fusioncharts/core';

// Include the chart from viz folder
// E.g. - import ChartType from fusioncharts/viz/[ChartType]
// import Line from 'fusioncharts/viz/line';
import pie2d from 'fusioncharts/viz/pie2d';

// Include the fusion theme
import FusionTheme from 'fusioncharts/themes/es/fusioncharts.theme.fusion'
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

// Add the chart and theme as dependency
// E.g. FusionCharts.addDep(ChartType)
FusionCharts.addDep(pie2d);
FusionCharts.addDep(FusionTheme);
FusionCharts.options.SVGDefinitionURL='absolute'

@Component({
  selector: 'app-system-health',
  templateUrl: './system-health.component.html',
  styleUrls: ['./system-health.component.css']
})
export class SystemHealthComponent implements OnInit {

  tierId  : any = (localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  clientId: any = (localStorage.getItem("clientId")?JSON.parse(localStorage.getItem("clientId")):null);
  HistoryList: any = [];
    responsibilityList: any = [];
  userId  : any = localStorage.getItem("userId");
  siteList: any = [];
  assetDatalist : any = [];
  dataTable : any;
  dropdownSettingsSite  = {};
  dropdownSettingsAsset = {};
  checkSiteArray  : any = [];
  checkAssetArray : any = [];
  site  : any = '';
  asset : any = '';
  customerId  : any = '';
  LiveFeedArr : any = [];
  statusUrl   : any = "A";
  mapPointerData: any = [];
  positions : any = [];
  errorText : any = "";
  styleExp  : any = {
    'display' : 'none'
  };
  
  message: any;
  subscription: Subscription;
  clientFilterId : any = '';
  
  private bounds: google.maps.LatLngBounds;
  private map: google.maps.Map;
  zoomLevel : any;
  latitude  : number;
  longitude : number;
  
  alarmCount  : number;
  offlineCount  : number;
  noCommCount  : number;
  warningCount  : number;
  normalCount  : number;
  totalActiveCount  : number;
  newCount  : number;
  deliveredCount  : number;
  repairCount  : number;
  scrappedCount  : number;
  totalSitesCount  : number;
  deactivatedCount : number;
  assetVisitArr : any = [];
  healthCheckArr : any = [];
  assetData : any = [];
  EventBarGraph : any = [];
  chartObj :any;
  handler: any;
  
  chartBarObj :any;
  handlerBar: any;
  activeDeactiveArr : any = [];
  totalActivated : number;
  totalDeactivated : number;
  healthCheckChartArr : any = [];
  tempArr : any = [];
  assetDatalistPopup : any = [];
  yearArray : any = [];
  healthCheckList : any = [];
  totalActiveDeactiveCount : number;
  clientTopDropdownValue : number;
  clientName : any = localStorage.getItem("clientName");
  performAction : number = 0;
  showAckBtn : number = 0;
  showAckErcBtn : number = 0;
  showERC : number = 0;
  
  dashboardClientName: string = '';
    rollUpEventList: any = [];
  health_service_list: any = [];


LinedataSet = {
  chart: {
    caption: "Data",
    yaxisname: "y-axis label",
    subcaption: "",
    showhovereffect: "1",
    numbersuffix: "%",
    drawcrossline: "1",
    plottooltext: "<b>$dataValue</b> of youth were on $seriesName",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        {
          label: "2012"
        },
        {
          label: "2013"
        },
        {
          label: "2014"
        },
        {
          label: "2015"
        },
        {
          label: "2016"
        }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Facebook",
      data: [
        {
          value: "62"
        },
        {
          value: "64"
        },
        {
          value: "64"
        },
        {
          value: "66"
        },
        {
          value: "78"
        }
      ]
    },
    {
      seriesname: "Instagram",
      data: [
        {
          value: "16"
        },
        {
          value: "28"
        },
        {
          value: "34"
        },
        {
          value: "42"
        },
        {
          value: "54"
        }
      ]
    },
    {
      seriesname: "LinkedIn",
      data: [
        {
          value: "20"
        },
        {
          value: "22"
        },
        {
          value: "27"
        },
        {
          value: "22"
        },
        {
          value: "29"
        }
      ]
    },
    {
      seriesname: "Twitter",
      data: [
        {
          value: "18"
        },
        {
          value: "19"
        },
        {
          value: "21"
        },
        {
          value: "21"
        },
        {
          value: "24"
        }
      ]
    }
  ]
};

width = "100%";
height = 400;
type = "msline";
dataFormat = "json";
dataSource = this.LinedataSet;
  chartType: any = '';
  notification_service_list: any = [];
  MessagethroughputArr: any = [];
  NotificationthroughputArr: any = [];
  emailBodyMsg: string = "";
  emailData: any = {
    emails:'',
    message:''
  };
  showValidEmailErr: boolean;
  showEmailErr: boolean;
  emailArray: any = [];
  emailIdArr:any = [];
  AmciServiceDetailsArr: any;


  constructor(
    private router:Router,
    private apiService: ApiService,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private chRef: ChangeDetectorRef,
    private CommonServicesService: CommonServicesService
  ) {
    let self = this;
    // subscribe to header component sendClientId to get Client Id
      this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this)).share()
      .subscribe(
        message => {
          this.clientFilterId = message.id;
          // setTimeout(function(){
            setTimeout(() => {
              this.clientName = localStorage.getItem("clientName");
            }, 1000);
            if (this.clientFilterId) {
              // self.getAssetStatusWidget();
            }
          // }, 6000);   
         
          //this.getLatestLiveFeed();
          // this.getAssetStatusWidget();
        }
      );
  
  }
  
  healthdataSource:any = {
      "chart": {
        //caption: "Market Share of Web Servers",
        plottooltext: "$percentValue Services are $label on the server", // <b>$percentValue</b> of web servers run on $label servers
        showlegend: "1",
        showpercentvalues: "1",
        legendposition: "bottom",
        usedataplotcolorforlabels: "1",
        theme: "fusion",
        width: '600', 
        height: '200',
      }, 
      "data" : this.tempArr
  };
  
  widthC = "100%";
  heightC = 430//"100%";//
  typeC = 'column3d';  
  dataFormatC = 'json';
  dataSourceC:any = {
      "chart": {
          "caption": "",
              "subcaption": "",
              "showlegend": "1",
              "xaxisname": "Event Status",
              "yaxisname": "Number of Events",
              "theme": "fusion",
      },
      "data": this.EventBarGraph
  };
  
  healthwidth = "100%";
  healthheight = "100%";
  healthtype = "pie3d";
  healthdataFormat = "json";
  curMonth : any = '';
  curYear : any = '';
  

  curselectedDay = 1;
  serviceObj:any = {};
  showGraph = false;

    ngOnDestroy() {
      // clear the header filter when user leave the page
      if($.fn.DataTable.isDataTable('#data_table_feed')){
        $('#data_table_feed').DataTable().destroy();
      };    
      if(this.chartObj !== undefined){ 
        this.chartObj.dispose(); 
        console.log("destroy");
       }

      this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
      // unsubscribe to ensure no memory leaks
      this.subscription.unsubscribe();
  
      this.CommonServicesService.getDashboardClientName().subscribe().unsubscribe();
    }
  
  
  ngOnInit() {
    this.getHealthCheckPieChart();
    //this.getMessagethroughput();
    //this.getNotificationthroughput();


    this.gethealthServiceList()
    let d = new Date();
    var y = d.getFullYear();
    this.curYear = y;
    y -= 29;
    for (var i = 0; i < 30; i++)
    {
      this.yearArray.push(y + i);
    }
  
    var date = new Date();
    let curMonth =date.getMonth() + 1;
    this.curMonth = curMonth;
    let self = this;
    if(this.clientId){
      this.getAllSiteList();
      // setTimeout(function(){
      //   self.getLatestLiveFeed();
      //   self.getAssetStatusWidget();
      // }, 6000); 
    }
    // setTimeout(function(){
    //     self.getLatestLiveFeed();
    //     self.getAssetStatusWidget();
    //   }, 1000); 
    // this.getAssetStatusWidget();
    // /this.getMonthlyActivationsAndDeactivations();
    this.getAllhealthCheck();
    this.getNotificationServiceList();
          this.CommonServicesService.getDashboardClientName().takeUntil(componentDestroyed(this)).subscribe((data) => {
            this.dashboardClientName = data.clientName;
            console.log("getDashboardclientName=====", data)
          })
          $(document).on('hidden.bs.modal', function () {
            if($('.modal.show').length)
            {
              $('body').addClass('modal-open');
            }
          });
  }
  
  
      initialized($event){
        this.chartBarObj = $event.chart;
      }
  
    ngAfterViewInit(){

    }
  
    // Function to format Date
    DateFormat(startDate, gmtOffset){
        return this.formatDateService.formatDate(startDate, gmtOffset);
    }
  
    gethealthServiceList(){
      
      var URL = 'healthCheck/getProcessServiceList';
      this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){
            this.health_service_list = data.responseList;
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_health_service_list');
              table.DataTable();          
            }, 300);
          }else{ 
            this.health_service_list = [];
            setTimeout(() => {
              const table: any = $('#data_table_health_service_list');
              table.DataTable();          
            }, 300);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

    getNotificationServiceList(){
      
      var URL = 'healthCheck/getNotificationService';
      this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){
            this.notification_service_list = data.responseList;
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_notification_service_list');
              table.DataTable();          
            }, 300);
          }else{ 
            this.notification_service_list = [];
            setTimeout(() => {
              const table: any = $('#data_table_notification_service_list');
              table.DataTable();          
            }, 300);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }


    dayChange(val){
      this.curselectedDay = val.target.value;
      console.log(this.curselectedDay, val.target.value);
      if (this.chartType=='MESSAGE') {
        if (this.serviceObj.serviceId) {
          this.getServiceDetails(this.serviceObj, this.chartType)
        }
      } else if(this.chartType=='UI') {
        this.getClientFacingServiceDetails(this.serviceObj, this.chartType)
      } else {
        this.getInAppServiceDetails(this.serviceObj, this.chartType)
      }
      setTimeout(() => {
        $(document).scrollTop($(document).height());
        // window.scrollTo(0,document.querySelector(".scrollingContainer").scrollHeight);
      }, 2000);
    }

    getServiceDetails(listData, type){
      this.chartType = type;

      listData.showButton = (listData.running && listData.warning) ? true : false;

      this.serviceObj = listData;
      this.showGraph = true
      var URL = 'healthCheck/getProcessServiceDetails?objectId=' + listData.serviceId+ '&days='+(this.curselectedDay ? this.curselectedDay : 1);
      this.apiService.getAll(URL).subscribe(
        data => {
          setTimeout(() => {
            $("html, body").animate({ scrollTop: document.body.scrollHeight }, "slow");
            // $(document).scrollTop($(document).height());
          }, 1000);
          if(data.statusCode == 100 ||  data.statusCode == 103){   
            let Arr = [];

            let dataObjJson = {}
            let valueArr = data.responseList ? data.responseList  : [];
            valueArr.map((val, key)=>{
                
              if (dataObjJson[val.counter_name]) {
                dataObjJson[val.counter_name].push([new Date(val.top_of_hour).getTime(), val.count]) // moment(val.top_of_hour, "YYYY-MM-DD").unix()
              } else {
                dataObjJson[val.counter_name] = []
                dataObjJson[val.counter_name].push([new Date(val.top_of_hour).getTime(), val.count])
                
              }

                Arr.push(val);
            });
            let ArrList = []
            console.log(dataObjJson, "------------------------------");
            for (const objKey in dataObjJson) {
              if (Object.prototype.hasOwnProperty.call(dataObjJson, objKey)) {
                const element = dataObjJson[objKey];
                // console.log(objKey, element);
                let obj1 = {
                  name:objKey,
                  data:element
                }
                ArrList.push(obj1)
              }
            }
            this.tempArr = Arr;
          
            // if (ArrList.length   == 0) {
            //   Highcharts.setOptions({lang: {noData: "Your custom message"}})
            // }

            //Draw chart
            let chart = Highcharts.chart('chart-container-detail', {

              title: {
                text:  data.statusCode == 103 ? "No Data Available" : `${ this.chartType == 'MESSAGE' ? listData.description : listData.name } Details`
              },
              tooltip:{
                shared: true
              },
              xAxis: {
                type: 'datetime',
              },
              yAxis: {
                title: {
                  text: ' Value'
                }
              },
              legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
              },

              series: ArrList,//.length ? ArrList : [{type: 'line',name: 'Incoming',data: []}],//JSONData,
              lang: {
                noData: "No Data Available"
              },
              noData: {
                attr:{},
                  style: {
                      fontWeight: 'bold',
                      fontSize: '15px',
                      color: '#303030',
                      align:'center',
                      verticalAlign: 'middle'
                  }
              }
            });

            // if (ArrList.length == 0) {
            //   chart.renderer.text('No Data Available'/* , 100,100 */)
            //     .css({
            //         color: '#4572A7',
            //         fontSize: '30px',
            //         'text-align':'center',
            //         fontWeight: 'bold',
            //         verticalAlign: 'middle',
            //         x:'50%'
            //     })
            //     .add();
            // }



          }else{ 
            this.healthCheckChartArr = [];
          }
          setTimeout(() => {
            var dataSource = this.healthdataSource;
            this.healthdataSource.data = this.tempArr; 
          }, 1000);
          setTimeout(() => {
            this.handler = this.clickChart.bind(this);
            // this.chartObj.addEventListener('dataplotClick', this.handler);
          }, 1000);

        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

    getClientFacingServiceDetails(listData, type){
      this.chartType = type;
      listData.showButton = (listData.status)? false : true;
      this.showGraph = true
      this.serviceObj = listData;
      var URL = 'healthCheck/getAmciServiceDetails?servletname=' + listData.name+ '&days='+(this.curselectedDay ? this.curselectedDay : 1);
      this.apiService.getAll(URL).subscribe(
        data => {
          setTimeout(() => {
            // $(document).scrollTop($(document).height());
            $("html, body").animate({ scrollTop: document.body.scrollHeight }, "slow");
          }, 1000);
          if(data.statusCode == 100 ||  data.statusCode == 103){   
            let Arr = [];

            let dataObjJson = {"Up Time":[], "downTime":[]}
            let valueArr = data.responseList ? data.responseList : []
            valueArr.map((val, key)=>{
                dataObjJson["Up Time"].push([new Date(val.serviceDate).getTime(), val.upTimecount]) // moment(val.top_of_hour, "YYYY-MM-DD").unix()
                dataObjJson.downTime.push([new Date(val.serviceDate).getTime(), val.downTimecount])
            
                Arr.push(val);
            });
            let ArrList = []
            console.log(dataObjJson, "------------------------------");
            for (const objKey in dataObjJson) {
              if (Object.prototype.hasOwnProperty.call(dataObjJson, objKey)) {
                const element = dataObjJson[objKey];
                // console.log(objKey, element);
                let obj1 = {
                  name:objKey,
                  data:element
                }
                ArrList.push(obj1)
              }
            }
            this.tempArr = Arr;
          
            //Draw chart
            Highcharts.chart('chart-container-detail', {

              title: {
                text: data.statusCode == 103 ? "No Data Available" : `${ this.chartType == 'MESSAGE' ? listData.description : listData.name } Details`
              },
              tooltip:{
                shared: true
              },
              xAxis: {
                type: 'datetime',
              },
              yAxis: {
                title: {
                  text: ' Value'
                }
              },
              legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
              },

              series: ArrList//JSONData,
            });

          }else{ 
            this.healthCheckChartArr = [];
          }
          setTimeout(() => {
            var dataSource = this.healthdataSource;
            this.healthdataSource.data = this.tempArr; 
          }, 1000);
          setTimeout(() => {
            this.handler = this.clickChart.bind(this);
            // this.chartObj.addEventListener('dataplotClick', this.handler);
          }, 1000);

        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

    getInAppServiceDetails(listData, type){
      this.chartType = type;
      listData.showButton = (listData.servicestatus)? false : true;
      this.serviceObj = listData;
      this.showGraph = true

      var URL = 'healthCheck/getInappNotificationDetails?days='+(this.curselectedDay ? this.curselectedDay : 1);
      this.apiService.getAll(URL).subscribe(
        data => {
          setTimeout(() => {
            // $(document).scrollTop($(document).height());
            $("html, body").animate({ scrollTop: document.body.scrollHeight }, "slow");

          }, 1000);
          if(data.statusCode == 100 ||  data.statusCode == 103){   
            let Arr = [];

            let dataObjJson = {"Failed":[], "Sent":[], "ACK":[]}
            let valueArr = data.responseList ?  data.responseList : [];
            valueArr.map((val, key)=>{
                
              // console.log(moment(val.serviceDate, "yyyy-MM-dd HH:MM:SS:sss").unix(),"+++++++++", new Date(val.serviceDate).getTime());
              

              // if (dataObjJson[val.counter_name]) {
                dataObjJson["Failed"].push([new Date(val.serviceDate).getTime(), val.InapFailedcount]) // moment(val.top_of_hour, "YYYY-MM-DD").unix()
                dataObjJson.Sent.push([new Date(val.serviceDate).getTime(), val.InapSentcount])
                dataObjJson.ACK.push([new Date(val.serviceDate).getTime(), val.InapACKcount])
              // } else {
              //   dataObjJson[val.counter_name] = []
              //   dataObjJson[val.counter_name].push([new Date(val.top_of_hour).getTime(), val.count])
                
              // }

                // let obj = {
                //   'label' : val.label,
                //   'value' : val.value,
                //   'color' : val.color
                // };
                Arr.push(val);
            });
            let ArrList = []
            console.log(dataObjJson, "------------------------------");
            for (const objKey in dataObjJson) {
              if (Object.prototype.hasOwnProperty.call(dataObjJson, objKey)) {
                const element = dataObjJson[objKey];
                // console.log(objKey, element);
                let obj1 = {
                  name:objKey,
                  data:element
                }
                ArrList.push(obj1)
              }
            }
            this.tempArr = Arr;
          
            //Draw chart
            Highcharts.chart('chart-container-detail', {

              title: {
                text: data.statusCode == 103 ? "No Data Available" : `${ this.chartType == 'MESSAGE' ? listData.description : listData.name } Details`
              },
              tooltip:{
                shared: true
              },
              xAxis: {
                type: 'datetime',
              },
              yAxis: {
                title: {
                  text: ' Value'
                }
              },
              legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
              },

              series: ArrList//JSONData,
            });

          }else{ 
            this.healthCheckChartArr = [];
          }
          setTimeout(() => {
            var dataSource = this.healthdataSource;
            this.healthdataSource.data = this.tempArr; 
          }, 1000);
          setTimeout(() => {
            this.handler = this.clickChart.bind(this);
            // this.chartObj.addEventListener('dataplotClick', this.handler);
          }, 1000);

        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

      // Function to format Date
    DateFormatEvent(startDate, gmtOffset){
        let d1  = new Date(startDate)
        var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
        // console.log(d, startDate,d1, moment(d1).format("MM-DD-YYYY HH:mm:ss"));
        
        if(startDate){
          var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
          return formattedDate;
        }else{
          return "";
        }
          // return this.formatDateService.formatDate(startDate, gmtOffset);
      }
  
  /* Get Site Management list */
  getAllSiteList(){
  
    if(this.clientId != null){
     this.apiService.getAll('site/getSiteDropDownListByClient?clientId='+this.clientId+'&userId='+this.userId).subscribe(
     data => {
         if(data.statusCode == 100){
            this.siteList = data.responseList;
          }else{
            this.siteList = [];
          }
        },
       error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
        );
     }
  
  }
  
    healthCheckDetail(event){   
     // Destroy Data Table if is already and clear the data
      if ($.fn.DataTable.isDataTable('#DATATABLEhEALTH')){
        $('#DATATABLEhEALTH').DataTable().destroy();
        this.healthCheckList=[];
      }    
  
      var URL = 'healthCheck/getAllhealthCheck?status='+event;
      this.healthCheckList = [];     
      
      $('#healthCheckModel').modal("show");
      this.apiService.getAll(URL).subscribe(
      data => {
  
        if (data.statusCode == 100) {
  
            this.healthCheckList = JSON.parse(JSON.stringify(data.responseList));
  
            this.chRef.detectChanges();
            if ($.fn.DataTable.isDataTable('#DATATABLEhEALTH')){
              $('#DATATABLEhEALTH').DataTable().destroy();
            } 
            setTimeout(() => {
              const table: any = $('#DATATABLEhEALTH');
              this.dataTable   = table.DataTable({
                "columnDefs": [{ 
                  "orderable": false, 
                  "targets": 2 
                }]
              }
              );
            }, 1000);
  
        }
        else {
            this.assetDatalistPopup = [];
            if ($.fn.DataTable.isDataTable('#DATATABLEhEALTH')){
              $('#DATATABLEhEALTH').DataTable().destroy();
              this.healthCheckList=[];
            }
            const table: any = $('#DATATABLEhEALTH');
            this.dataTable   = table.DataTable(
            {
              "columnDefs": [{ 
                "orderable": false, 
                "targets": 2 
              }]
            }
            );
  
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
  );
  }
  
  
  
    getHealthCheckPieChart() {
      var URL = 'healthCheck/getHealthCheckPieChart';
        this.apiService.getAll(URL).subscribe(
          data => {
            if(data.statusCode == 100){   
              this.healthCheckChartArr = data.responseList;
              let Arr = [];
              let valueArr = this.healthCheckChartArr
              valueArr.map((val, key)=>{
                  let obj:any = {
                    // 'label' : val.label,
                    // 'value' : val.value,
                    color : val.color,
                    name:val.label,
                    y:val.value
                  };
                  // if (key == 0) {
                  //   obj.sliced= true,
                  //   obj.selected = true
                  // }

                  Arr.push(obj);
                  this.tempArr = Arr;

                  Highcharts.chart('health-container', {
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: 'System health'
                    },
                    tooltip: {
                        pointFormat:  '{point.percentage:.1f} % Services are {point.name} on the server' //'{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    },
                    series: [{
                        // name: 'Brands',
                        colorByPoint: true,
                        data: Arr 
                    }]
                });
                              


              });
            }else{ 
              this.healthCheckChartArr = [];
            }
            setTimeout(() => {
              var dataSource = this.healthdataSource;
              this.healthdataSource.data = this.tempArr; 
            //    FusionCharts.ready(function() {
            //     var myChart = new FusionCharts({
            //       type: "pie2d",
            //       renderAt: "chart-container",
            //       width: "100%",
            //       height: "90%",
            //       dataFormat: "json",
            //       dataSource
            //     }).render();
            // });      
            }, 1000);
            setTimeout(() => {
              this.handler = this.clickChart.bind(this);
              // this.message = this.clickPlotMsg;
              // this.attached = true;
              // this.chartObj.addEventListener('dataplotClick', this.handler);
            }, 1000);
  
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
    }
  
    initializedChartD($event) {
      this.chartObj = $event.chart;
    }
  
    clickChart(event){
      if (event.data.categoryLabel == "Down") {
        console.log(event.data.categoryLabel);
        this.healthCheckDetail(false)
        
      }else if (event.data.categoryLabel == "Running") {
        console.log(event.data.categoryLabel);
        this.healthCheckDetail(true)
      }
      
    }
    getAllhealthCheck() {      
        var URL = 'healthCheck/getAllhealthCheck';
        this.apiService.getAll(URL).subscribe(
          data => {
            if(data.statusCode == 100){
              this.healthCheckArr = data.responseList;
              this.chRef.detectChanges();
              setTimeout(() => {
                const table: any = $('#data_table_health_check');
                this.dataTable   = table.DataTable();          
              }, 300);
            }else{ 
              this.healthCheckArr = [];
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
    }

    getMessagethroughput() {      
      var URL = 'healthCheck/getMessagethroughput';
      this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){
            this.MessagethroughputArr = data.responseList;
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_Messagethroughput');
              this.dataTable   = table.DataTable();          
            }, 300);
          }else{ 
            this.MessagethroughputArr = [];
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
    
    getNotificationthroughput(){
      this.getMessagethroughput();
      var URL = 'healthCheck/getNotificationthroughput';
      this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){
            this.NotificationthroughputArr = data.responseList;
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_Notificationthroughput');
              this.dataTable   = table.DataTable();          
            }, 300);
          }else{ 
            this.NotificationthroughputArr = [];
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

    emailVerify() {  
      this.emailBodyMsg = "";
      let allEmailValid = false;
      if(this.emailData.emails!=""){
        this.emailArray  = this.emailData.emails.split(',');
        if((this.emailArray.length != 0) && (this.emailArray.length > 0)){
          let emailExp = new RegExp("[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,3}$")
          this.emailArray.forEach((data,index) => {
            // console.log("data=======",data,'++++++++++',emailExp.test(data))
            if (!emailExp.test(data.trim())) {
              this.showValidEmailErr = true
              allEmailValid = true
            }
          })
        }
      }
  
      // check for email required
      if(this.emailData.emails) {
        this.showEmailErr = false;
      }else {
        this.showEmailErr = true;
        this.showValidEmailErr = false;
      }
  
      // check for valid email id
      if (allEmailValid) {
        this.showValidEmailErr = true
      }else {
        this.showValidEmailErr = false
      }  
  
      // check for msg
      // if (this.emailData.message) {
      //   this.showMsgErr = false
      // }else {
      //   this.showMsgErr = true
      // }
  
      if ((!this.showEmailErr) && (!this.showValidEmailErr)) {
          this.emailBodyMsg = this.emailData.message;
          if(this.emailArray.length > 0){
            this.emailIdArr=[];
            this.emailArray.forEach((data,index) => {
              var exist = this.emailIdArr.indexOf(data.trim());
              if (exist == '-1') {
                this.emailIdArr.push(data.trim());
              }
            });
          //   console.log("emailIdArr---", this.emailIdArr)
          } 
          if(this.emailBodyMsg!=""){
            this.emailBodyMsg = this.emailBodyMsg.replace(new RegExp('\n', 'g'), "<br/>");
          }
          this.emailSend();
        }
    }
    
    emailSend(){
      var URL = ""
      let reqObj = {}
      if (this.chartType == 'INAPP') {
        URL = `healthCheck/sendTroubleshootInappNotification`
        reqObj = {
          "emailBodyMessage": this.emailBodyMsg,
          "days":this.curselectedDay,
          "emailIds": this.emailIdArr
        }
      } else if (this.chartType == 'UI') {
        URL = `healthCheck/sendTroubleshootInformationforamciservice`
        reqObj = {
          "emailBodyMessage": this.emailBodyMsg,
          "days":this.curselectedDay,
          "serviceName":this.serviceObj.name,
          "emailIds": this.emailIdArr
        }
      } else {
        URL = `healthCheck/sendTroubleshootInformation`
        reqObj = {
          "emailBodyMessage": this.emailBodyMsg,
          // "days":this.curselectedDay,
          // "serviceName":this.serviceObj.name,
          "emailIds": this.emailIdArr
        }
      }
      
      this.apiService.create(URL, reqObj).subscribe(
        data => {
          if(data.statusCode == 100){
            this.alertService.sweetMessage('success',(data.responseMessage ? data.responseMessage :"Success"));
            this.resetModalData();
            $("#closeModal").click();
          }else{ 

          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

    resetModalData() {
      this.emailData = {
        emails:'',
        message:''
      };
      this.emailArray = [];
      this.showEmailErr = false;  
      this.showValidEmailErr = false;
      // this.showMsgErr = false;
    }
  
    getAmciServiceListDetails(serviceObj){
      console.log(serviceObj     , " -- -- - - - --  --");
      if($.fn.DataTable.isDataTable('#data_table_AMCIthroughput')){
        $('#data_table_AMCIthroughput').DataTable().destroy();
      };   
      var URL = `healthCheck/getAmciServicestatus?servletname=${serviceObj.name}&days=${this.curselectedDay}`
      this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){
            if($.fn.DataTable.isDataTable('#data_table_AMCIthroughput')){
              $('#data_table_AMCIthroughput').DataTable().destroy();
            };   
            this.AmciServiceDetailsArr = data.responseList;
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_AMCIthroughput');
              this.dataTable   = table.DataTable();          
            }, 500);
          }else{ 
            this.NotificationthroughputArr = [];
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
    
    getAmciServiceInAppNotificationStatusList(serviceObj){
      if($.fn.DataTable.isDataTable('#data_table_Notificationthroughput')){
        $('#data_table_Notificationthroughput').DataTable().destroy();
      };   
      var URL = `healthCheck/getInappNotificationstatus?days=${this.curselectedDay}`
      this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){
            if($.fn.DataTable.isDataTable('#data_table_Notificationthroughput')){
              $('#data_table_AMCIthroughput').DataTable().destroy();
            };   
            this.NotificationthroughputArr = data.responseList;
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_Notificationthroughput');
              this.dataTable   = table.DataTable();          
            }, 500);
          }else{ 
            this.NotificationthroughputArr = [];
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
}
