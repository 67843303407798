import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import "rxjs/Rx"
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
// import * as NProgress from 'nprogress';
declare var moment:any;

var ip = location.host;
console.log(location.host);

var apiUrl: String = "https://admin.amci1440.com/admin/api/v1";
var loginUrl: String = "https://admin.amci1440.com";
var imgUrl: String = "https://admin.amci1440.com/";
var clientUrl: String = "https://www.amci1440.com/"
var CustomerUrl: String = "https://customer.amci1440.com/login?"

var routeNavigate:any='';

@Injectable()
export class ApiService {
    // NProgress: any;
    clientUser: any;
    clientId: any;
    userId = 0;
    clientmigration :any = null;
    tiermigration:any = null;
    sitemigration:any = null;
    usermigration:any = null;
    customermigration:any = null;
    loaderImg : any = '';
    public static permission: any={};

    AppUrlService = apiUrl
    LoginUrlService = loginUrl
    
    ClientUrl = clientUrl
    Customer_Url = CustomerUrl
    tierUserMigration:any = null;
    baseBath : String = apiUrl;

    constructor(private http: Http, private router: Router) {
        routeNavigate = this.router
    }
    /*Function for getting user token*/
    getUserToken(): Promise<any> {
        return this.http.get('/token').map((response: Response) =>{
            return response.json();
        }).toPromise();
    }
    appUrl:String="http://"+ip;
    end_point: String;
    data: any;
    id: Number;

    //imageBasePath:string= apiUrl=="http://s09.cdnsolutionsgroup.com:8768/api/v1" ? "http://amciimages.cdnsolutionsgroup.com"  :    "http://192.168.0.112/images" ;

    // imageBasePath:string= "https://client.satalarm.com/";
    //imageBasePath:string="http://amciimages.cdnsolutionsgroup.com/";

    imageBasePath:String = imgUrl == "http://192.168.0.112" || imgUrl == "https://s09.cdnsolutionsgroup.com/" ? imgUrl + '/' : imgUrl;

    refreshToken(end_point, obj){
        let headers = new Headers(/* { 'Authorization': token.access_token} */);
        headers.append("Content-Type" , "application/x-www-form-urlencoded");
        const httpOptions = new RequestOptions({ headers: headers });
        let finalRequestBody = "refresh_token=";
        finalRequestBody=finalRequestBody+obj.refresh_token+"&grant_type=refresh_token"
        // finalRequestBody=finalRequestBody+obj.password+""
        return this.http.post(loginUrl+'/'+end_point,finalRequestBody,httpOptions)
        .map((res: Response)=>{
            return res.json();
        })
        .catch((error:any)=>{
            return Observable.throw(error.json());
          });
    }

    getUserInfoData(end_point){
        if (this.isTokenAvailable()) {   
            return this.http.get(apiUrl+'/'+end_point,this.AuthorizationHeader())
            .map((res: Response)=>{
                return res.json();
            })
            .catch((error:any)=>{
                return Observable.throw(error.json());
            });
        }
    }

    getAllDashboard(end_point){
        if (this.isTokenAvailable()) {
            return this.http.get(apiUrl+'/'+end_point,this.AuthorizationHeader())
                .map(this.handleSuccessD)
                .catch(this.handleErrorD);
        }
    }

    getAll(end_point){
        // NProgress.start();
        if (this.isTokenAvailable()) {
            this.clinetLoader();
            return this.http.get(apiUrl+'/'+end_point, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }

    getAllFromRoot(end_point){
        // NProgress.start();
        if (this.isTokenAvailable()) {
            this.clinetLoader();

            return this.http.get(apiUrl+'/'+end_point, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }
    

    getLoginDatawithoutToken(end_point){
        // NProgress.start();
        this.clinetLoader();

        return this.http.get(apiUrl+'/'+end_point)
            .map(this.handleSuccess)
            .catch(this.handleError);
    }

    userActivate(end_point, data){
        // if (this.checkTokenExist()) {
            // NProgress.start();
            this.clinetLoader()
            return this.http.put(apiUrl+'/'+end_point, data)
                .map(this.handleSuccess)
                .catch(this.handleError);
        // }
    }

    
    resetPassword(end_point){
        return this.http.get(apiUrl+'/'+end_point)
            .map(this.handleSuccess)
            .catch(this.handleError);
    }

    getById(end_point, id: number){

        if (this.isTokenAvailable()) {
            this.clinetLoader();
            // NProgress.start();
            return this.http.get(apiUrl+'/'+end_point+'/' + id , this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }

    // login(end_point, data) {
    //     NProgress.start();
    //     return this.http.post(apiUrl+'/'+end_point, data)
    //         .map(this.handleSuccess)
    //         .catch(this.handleError);
    // }
    login(end_point, data) {
        let headers = new Headers();
        headers.append("Content-Type" , "application/x-www-form-urlencoded");
        const httpOptions = new RequestOptions({ headers: headers });
        // NProgress.start();
        let finalRequestBody = "username=";
        finalRequestBody=finalRequestBody+data.username+"&password="
        finalRequestBody=finalRequestBody+data.password+"&grant_type="
        finalRequestBody=finalRequestBody+data.grant_type+"&user_type=admin";;
        this.clinetLoader()
        return this.http.post(loginUrl+'/'+end_point, finalRequestBody, httpOptions)
            .map(this.handleSuccessLogin)
            .catch(this.handleErrorLogin);
    }

    private handleSuccessLogin(res: Response){
        // NProgress.done();
        localStorage.setItem("t11",new Date().getTime().toString())
        document.cookie = "t11=;"//+data.refresh_token
        // document.cookie = "t11="+new Date().getTime().toString()+'; path=/';
        document.cookie = "t11="+new Date().getTime().toString()+'; path=/; expires=' + new Date(moment().add(600, 'days')).toUTCString();
        $("#loaderImgIcon").css("display","none");
        return res;
    }

    private handleErrorLogin(error:any) {
        // NProgress.done();
        $("#loaderImgIcon").css("display","none");
        return Observable.throw(error.json());
    }
    create(end_point, data) {
        // if (this.isTokenAvailable()) {
            this.clinetLoader();
            // NProgress.start();
            return this.http.post(apiUrl+'/'+end_point, data, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        // }
    }
    logout(end_point){
        // NProgress.start();
        this.clinetLoader();
        return this.http.delete(loginUrl+'/'+end_point, this.AuthorizationHeader())
            .map(this.handleSuccessLogin)
            .catch(this.handleErrorLogin);
    }
    update(end_point, data) {
        // if (this.isTokenAvailable()) {
            this.clinetLoader();
            // NProgress.start();
            return this.http.put(apiUrl+'/'+end_point, data, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        // }
    }
    delete(end_point, data) {
        if (this.isTokenAvailable()) {
            this.clinetLoader();
            // NProgress.start();
            return this.http.delete(apiUrl + '/'+end_point,  this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }

    private handleSuccessStatus(res: Response){
        $("#loaderImgIcon").css("display","none");
        return res.json();
    }

    /* private helper methods */

    private handleSuccessD(res: Response){
        $("#loaderImgIcon").css("display","none");
        var statusCode = res.json().statusCode;
        var responseMessage = res.json().responseMessage;
        if(statusCode == 109){
            localStorage.clear();
            localStorage.setItem("logoutMessage" , responseMessage);
            routeNavigate.navigate(['/login']);
        }
        if(statusCode == 199){
            localStorage.clear();
            localStorage.setItem("logoutMessage" , responseMessage);
            routeNavigate.navigate(['/login']);
        }
        // NProgress.done();
        return res.json();
    }

    private handleErrorD(error:any) {
        $("#loaderImgIcon").css("display","none");
        var errorstatusFlag = error.ok;
        var errorStatus = error.status;
        console.log(error , "----------------------------");
        
        if((errorstatusFlag == false) && ( errorStatus == 401)) {
        //     Swal({
        //             position: 'top-end',
        //             type: 'error',
        //             title: 'Session has been expired, login again',
        //             showConfirmButton: false,
        //             timer: 4000
        //           })
            localStorage.clear();
            // localStorage.removeItem('Token')
            routeNavigate.navigate(['/login']);
            return Observable.throw(error.json());
        }
        else if(errorStatus == 199){
            localStorage.clear();
            localStorage.setItem("logoutMessage" , error.message);
            routeNavigate.navigate(['/login']);
        }
        else if(errorStatus == 0 ){
            let token = JSON.parse(localStorage.getItem('Token'));
            if (token && token.access_token) {
                console.log('-----------------------1');
                let headers = new Headers({ 'Authorization': 'bearer '+token.access_token});
                // "Access-Control-Allow-Headers":"*", "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS", 'Authorization': 'bearer '+token.access_token,
                // fetch(apiUrl+'/user/isUserSessionExpaired', {method:"POST",headers :{ 'Content-Type': 'application/json',  /*  mode: 'no-cors' */},body:JSON.stringify({sessionToken:token.access_token})})
                //     .then((response) => {
                //         console.log("-----------------------------11");
                        
                //         return response.json();
                //     })
                //     .then((myJson) => {
                //         console.log(myJson);
                //         if (myJson.statusCode != 100) {
                //             localStorage.clear();
                //             // localStorage.removeItem("Token");
                //             routeNavigate.navigate(['/login']);
                //         }
                //     }).catch((error)=>{
                //         console.log(error.status, error.statusCode, error.errorStatus, error);
                        
                //     });
                }

        }

        // NProgress.done();
        return Observable.throw(error.json());
    }

    private handleSuccess(res: Response){
        localStorage.setItem("t11",new Date().getTime().toString())
        var statusCode = res.json().statusCode;
        var responseMessage = res.json().responseMessage;
        if(statusCode == 109){
            localStorage.clear();
            localStorage.setItem("logoutMessage" , responseMessage);
            routeNavigate.navigate(['/login']);
        }else if(statusCode == 199){
            localStorage.clear();
            localStorage.setItem("logoutMessage" , responseMessage);
            routeNavigate.navigate(['/login']);
        }
        document.cookie = "t11=;"//+data.refresh_token
        document.cookie = "t11="+new Date().getTime().toString()+'; path=/; expires=' + new Date(moment().add(600, 'days')).toUTCString();
        $("#loaderImgIcon").css("display","none");
        // NProgress.done();
        return res.json();
    }

    private handleError(error:any) {
        $("#loaderImgIcon").css("display","none");
        var responseMessage = error.json().responseMessage;
        var responsestatus = error.json().status;
        var errorstatusFlag = error.ok;
        var errorStatus = error.status;

        if((errorstatusFlag == false) && (errorStatus == 401)) {
        //     // Swal({
        //     //         position: 'top-end',
        //     //         type: 'error',
        //     //         title: 'Session has been expired, login again',
        //     //         showConfirmButton: false,
        //     //         timer: 4000
        //     //       })
            localStorage.clear();
            // localStorage.removeItem('Token')
            routeNavigate.navigate(['/login']);
            return Observable.throw(error.json());
        }
        else if(responsestatus == 199){
            //console.log('Api Error '+ responseMessage +' Status Code '+statusCode);
            localStorage.clear();
            localStorage.removeItem("Token");
            routeNavigate.navigate(['/login']);
        }else if(errorStatus == 109){
            localStorage.clear();
            localStorage.setItem("logoutMessage" , responseMessage);
            routeNavigate.navigate(['/login']);
        }
        else if(errorStatus == 0 ){
            let token = JSON.parse(localStorage.getItem('Token'));
            if (token && token.access_token) {
                console.log('-----------------------1');
                let headers = new Headers({ 'Authorization': 'bearer '+token.access_token});
                // "Access-Control-Allow-Headers":"*", "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS", 'Authorization': 'bearer '+token.access_token,
                // fetch(apiUrl+'/user/isUserSessionExpaired', {method:"POST",headers :{ 'Content-Type': 'application/json',  /*  mode: 'no-cors' */},body:JSON.stringify({sessionToken:token.access_token})})
                //     .then((response) => {
                //         console.log("-----------------------------11");
                        
                //         return response.json();
                //     })
                //     .then((myJson) => {
                //         console.log(myJson);
                //         if (myJson.statusCode != 100) {
                //             localStorage.clear();
                //             // localStorage.removeItem("Token");
                //             routeNavigate.navigate(['/login']);
                //         }
                //     }).catch((error)=>{
                //         console.log(error.status, error.statusCode, error.errorStatus, error);
                        
                //     });
                }

        }

        // else if(errorStatus == 105){
        //    localStorage.setItem("errorText" , responseMessage);
        // //    routeNavigate.navigate(['/error']);
        // }
        // NProgress.done();
        return Observable.throw(error.json());
    }
    // create authorization header with jwt token

    private AuthorizationHeader() {
        let token = JSON.parse(localStorage.getItem('Token'));
        if (token && token.access_token) {
            let headers = new Headers({ 'Authorization': 'bearer '+token.access_token});
            return new RequestOptions({ headers: headers });
        }else{
            routeNavigate.navigate(['/login']);            
        }
    }

    /* end private helper methods */

    getThirdPartyAPI(end_point){
        if (this.isTokenAvailable()) {
        // NProgress.start();
            return this.http.get(end_point, this.AuthorizationHeader())
                .map(this.handleSuccess)
                .catch(this.handleError);
        }
    }


    clinetLoader(){
        this.loaderImg =  localStorage.getItem("loaderImg") ? this.imageBasePath+localStorage.getItem("loaderImg") : 'assets/img/load.gif'
        $("#loaderImgIcon").attr("src",this.loaderImg);
        // $("#loaderImgIcon").css("display","block");
        // $("#loading-bar-spinner").css("background" , "url("+this.loaderImg+") rgba(255, 255, 255, 0.53);");
        setTimeout(() => {
            $("#loading-bar-spinner").css("background-image" , "url('"+this.loaderImg+"')");
        }, 200);

    }

    isTokenAvailable(){
        let token = JSON.parse(localStorage.getItem('Token'));
        if (token && token.access_token){
            return true
        } else{
            routeNavigate.navigate(['/login']);
            return false;
        }
    }

}
