import { Component, OnInit ,ViewChild, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService } from '../../../../../services/index';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import Swal from 'sweetalert2'

import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-scheduler-setting',
  templateUrl: './scheduler-setting.component.html',
  styleUrls: ['./scheduler-setting.component.css']
})
export class SchedulerSettingComponent implements OnInit {

  dataTable   : any;
  serviceLsit : any = [];
  clientName : any = localStorage.getItem("clientName");
  userData: any = JSON.parse(localStorage.getItem("clientUser"));
  loginUserType: any = this.userData ?  this.userData.userType : '';
  HistoryList: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private chRef: ChangeDetectorRef
    ) { }

  
  // Function to format Date
  DateFormat(startDate, gmtOffset){
    return this.formatDateService.formatDate(startDate, gmtOffset);
  }

  ngOnInit() {
    this.getSchedularLsit();
  }

  getSchedularLsit(){
    this.apiService.getAll('ProcessServiceScheduler/getProcessServiceSchedulerList').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.serviceLsit = data.responseList;
          if ($.fn.DataTable.isDataTable('#serviceTable')){
            $('#serviceTable').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#serviceTable');
          this.dataTable = table.DataTable({"order": [],"destroy":true,"bDestroy":true,"columnDefs": [{ "orderable": false, "targets": [4,5] }]});
        }else{
          this.serviceLsit = [];
          if ($.fn.DataTable.isDataTable('#serviceTable')){
            $('#serviceTable').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#serviceTable');
          this.dataTable = table.DataTable({"order": [], "destroy":true,"bDestroy":true,"columnDefs":[{"orderable": false, "targets": [4,5] }]});
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  activeORdeactive(id, status){
    var st = 0;
    var option;
    if(status == false){
      var st = 1;
      option = 'Activate'
    }else{
      st = 0;
      option = 'Deactivate'
    }
    var obj = {
      "schedulerId":id,
      "status":st
    }
    Swal({
      title: 'Are you sure?',
      html: "You want to "+option,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, '+option+' it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.update('ProcessServiceScheduler/updateProcessServiceSchedulerStatus', obj).subscribe(
          data => {
            if(data.statusCode == 100){
              this.alertService.sweetMessage('success', data.responseMessage);
              this.getSchedularLsit();
            }else
              { this.alertService.sweetMessage('error', data.responseMessage); }
          },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");}
        );
      }
    })
  }

  // new Function to format Date
  newDateFormat(dateStr){
    if(dateStr!="" && dateStr!=null){
      var formattedDate = moment(dateStr,"DD-MM-YYYY HH:mm:ss").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }



getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('ProcessServiceScheduler/getProcessServiceSchedulrHistory?schedulerId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}
}
