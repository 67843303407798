import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})

export class PopupService {

 constructor() { }

  successAlert(msg){
    Swal({
      position: 'top-end',
      type: 'success',
      title: msg,
      showConfirmButton: false,
      timer: 3500
    })
  }

  errorAlert(msg) {
    Swal({
      position: 'top-end',
      type: 'error',
      title: msg,
      showConfirmButton: false,
      timer: 3500
    })
  }

}
