import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AlertService, FormatDateService, CommonServicesService } from '../../../../services/index';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
declare var $ : any;
declare var jQuery : any, moment:any;
import 'datatables.net';
import 'datatables.net-bs4';



// Include the core fusioncharts file from core  -
import FusionCharts from 'fusioncharts/core';

// Include the chart from viz folder
// E.g. - import ChartType from fusioncharts/viz/[ChartType]
// import Line from 'fusioncharts/viz/line';
import pie2d from 'fusioncharts/viz/pie2d';

// Include the fusion theme
import FusionTheme from 'fusioncharts/themes/es/fusioncharts.theme.fusion'
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

// Add the chart and theme as dependency
// E.g. FusionCharts.addDep(ChartType)
FusionCharts.addDep(pie2d);
FusionCharts.addDep(FusionTheme);
FusionCharts.options.SVGDefinitionURL='absolute'


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit,OnDestroy {

tierId  : any = (localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
clientId: any = (localStorage.getItem("clientId")?JSON.parse(localStorage.getItem("clientId")):null);
HistoryList: any = [];
  responsibilityList: any = [];
userId  : any = localStorage.getItem("userId");
siteList: any = [];
assetDatalist : any = [];
dataTable : any;
dropdownSettingsSite  = {};
dropdownSettingsAsset = {};
checkSiteArray  : any = [];
checkAssetArray : any = [];
site  : any = '';
asset : any = '';
customerId  : any = '';
LiveFeedArr : any = [];
statusUrl   : any = "A";
mapPointerData: any = [];
positions : any = [];
errorText : any = "";
styleExp  : any = {
  'display' : 'none'
};

message: any;
subscription: Subscription;
clientFilterId : any = '';

private bounds: google.maps.LatLngBounds;
private map: google.maps.Map;
zoomLevel : any;
latitude  : number;
longitude : number;

alarmCount  : number;
offlineCount  : number;
noCommCount  : number;
warningCount  : number;
normalCount  : number;
totalActiveCount  : number;
newCount  : number;
deliveredCount  : number;
repairCount  : number;
scrappedCount  : number;
totalSitesCount  : number;
deactivatedCount : number;
assetVisitArr : any = [];
healthCheckArr : any = [];
assetData : any = [];
EventBarGraph : any = [];
chartObj :any;
handler: any;

chartBarObj :any;
handlerBar: any;
activeDeactiveArr : any = [];
totalActivated : number;
totalDeactivated : number;
healthCheckChartArr : any = [];
tempArr : any = [];
assetDatalistPopup : any = [];
yearArray : any = [];
healthCheckList : any = [];
totalActiveDeactiveCount : number;
clientTopDropdownValue : number;
clientName : any = localStorage.getItem("clientName");
performAction : number = 0;
showAckBtn : number = 0;
showAckErcBtn : number = 0;
showERC : number = 0;

dashboardClientName: string = '';
  rollUpEventList: any = [];

constructor(
  private router:Router,
  private apiService: ApiService,
  private alertService: AlertService,
  private formatDateService: FormatDateService,
  private chRef: ChangeDetectorRef,
  private CommonServicesService: CommonServicesService
) {
  let self = this;
  // subscribe to header component sendClientId to get Client Id
    this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this)).share()
    .subscribe(
      message => {
        this.clientFilterId = message.id;
        // setTimeout(function(){
          setTimeout(() => {
            this.clientName = localStorage.getItem("clientName");
          }, 1000);
          if (this.clientFilterId) {
            self.getLatestLiveFeed();
            self.getAssetStatusWidget();
          }
        // }, 6000);   
       
        //this.getLatestLiveFeed();
        // this.getAssetStatusWidget();
      }
    );

}

dataSource:any = {
    "chart": {
      //caption: "Market Share of Web Servers",
      plottooltext: "$percentValue Services are $label on the server", // <b>$percentValue</b> of web servers run on $label servers
      showlegend: "1",
      showpercentvalues: "1",
      legendposition: "bottom",
      usedataplotcolorforlabels: "1",
      theme: "fusion",
      width: '600', 
      height: '200',
    }, 
    "data" : this.tempArr
};

widthC = "100%";
heightC = 430//"100%";//
typeC = 'column3d';  
dataFormatC = 'json';
dataSourceC:any = {
    "chart": {
        "caption": "",
            "subcaption": "",
            "showlegend": "1",
            "xaxisname": "Event Status",
            "yaxisname": "Number of Events",
            "theme": "fusion",
    },
    "data": this.EventBarGraph
};

width = "100%";
height = "100%";
type = "pie2d";
dataFormat = "json";
curMonth : any = '';
curYear : any = '';

  ngOnDestroy() {
    // clear the header filter when user leave the page
    if($.fn.DataTable.isDataTable('#data_table_feed')){
      $('#data_table_feed').DataTable().destroy();
      this.LiveFeedArr = [];
    };  
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();

    this.CommonServicesService.getDashboardClientName().subscribe().unsubscribe();
  }


ngOnInit() {
  this.getHealthCheckPieChart();
  // setTimeout(() => {
  //   var dataSource = this.dataSource;
  //   this.dataSource.data = this.tempArr; 
  //    FusionCharts.ready(function() {
  //     var myChart = new FusionCharts({
  //       type: "pie2d",
  //       renderAt: "chart-container",
  //       width: "100%",
  //       height: "90%",
  //       dataFormat: "json",
  //       dataSource
  //     }).render();
  // });      
  // }, 4000);
  let d = new Date();
  var y = d.getFullYear();
  this.curYear = y;
  y -= 29;
  for (var i = 0; i < 30; i++)
  {
    this.yearArray.push(y + i);
  }

  var date = new Date();
  let curMonth =date.getMonth() + 1;
  this.curMonth = curMonth;
  let self = this;
  if(this.clientId){
    this.getAllSiteList();
    // setTimeout(function(){
    //   self.getLatestLiveFeed();
    //   self.getAssetStatusWidget();
    // }, 6000); 
  }
  // setTimeout(function(){
  //     self.getLatestLiveFeed();
  //     self.getAssetStatusWidget();
  //   }, 1000); 
  this.getLast10AssetsVisitByUser();
  // this.getAssetStatusWidget();
  // /this.getMonthlyActivationsAndDeactivations();
  this.getAllhealthCheck();
  this.getActiveDeactivebyProvider(this.curMonth, this.curYear);

  this.dropdownSettingsSite = {
    singleSelection: false,
    idField: 'siteId',
    textField: 'siteName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };

  this.dropdownSettingsAsset = {
        singleSelection: false,
        idField: 'assetId',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 0,
        allowSearchFilter: true
      };

      jQuery('.rst-date').datetimepicker({
          language: 'en',
          pickTime: true,
          pickDate: true,
          minDate: 0,
          showButtonPanel: true,
          inline: true,
          sideBySide: true,
          autoclose: true,
          keepOpen: false,
        }).on('changeDate', function(){
          $('.bootstrap-datetimepicker-widget').hide();
          $(this).datepicker('hide');
      });

      jQuery('.rst-time').datetimepicker({
            pickDate: false,
            pickTime: true,
            autoclose: true,
            pickSeconds: false,
            pickMinutes: true,
            timeFormat: "HH:mm"
        });

        this.CommonServicesService.getDashboardClientName().takeUntil(componentDestroyed(this)).subscribe((data) => {
          this.dashboardClientName = data.clientName;
          console.log("getDashboardclientName=====", data)
        })
        $(document).on('hidden.bs.modal', function () {
          if($('.modal.show').length)
          {
            $('body').addClass('modal-open');
          }
        });
}


    initialized($event){
      this.chartBarObj = $event.chart;
    }

  ngAfterViewInit(){
    this.getMapPointerData();
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
      return this.formatDateService.formatDate(startDate, gmtOffset);
  }


    // Function to format Date
  DateFormatEvent(startDate, gmtOffset){
      let d1  = new Date(startDate)
      var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
      // console.log(d, startDate,d1, moment(d1).format("MM-DD-YYYY HH:mm:ss"));
      
      if(startDate){
      	var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
        return formattedDate;
      }else{
        return "";
      }
        // return this.formatDateService.formatDate(startDate, gmtOffset);
    }


  // initializedChartD($event) {
  //   this.chartObj = $event.chart;
  // }

  // Function to filter Data
  getLiveFilterData(e) {
    if (e.target.value == 'alarm') {
        this.statusUrl = "A"
    } else if (e.target.value == 'warning') {
        this.statusUrl = "W"
    } else if (e.target.value == 'normal') {
        this.statusUrl = "N"
    } else if (e.target.value == 'offline') {
        this.statusUrl = "S"
    } else if (e.target.value == 'nocomm') {
        this.statusUrl = "C"
    }
    this.getMapPointerData();
  }

  getMapPointerData() {
    // var URL = 'asset/getAssetByAdmin?clientId=' + this.clientId + '&userId=' + this.userId;
    var URL = 'asset/getAssetByAdmin';
    if (this.tierId != null && this.tierId != '') {
        var tierString = '&tierId=' + this.tierId.toString();
        var URL = URL.concat(tierString);
    }
    if (this.site != '') {
        var siteString = '&siteId=' + this.site;
        var URL = URL.concat(siteString);
    }
    if (this.customerId != null && this.customerId != '') {
        var customerString = '&customerId=' + this.customerId.toString();
        var URL = URL.concat(customerString);
    }
    if (this.statusUrl != '') {
        var statusString = '?status=' + this.statusUrl.toString();
        var URL = URL.concat(statusString);
    }
    if (this.asset != '') {
        var assetString = '&assetId=' + this.asset;
        var URL = URL.concat(assetString);
    }

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.mapPointerData = data.responseList;
          this.positions = [];
          this.errorText = "";
          this.styleExp.display = "none";
          // this.zoomLevel = 6;

          let position = [];
          Object.keys(this.mapPointerData).forEach(key => {
            if(this.mapPointerData[key]['latitude'] != null && this.mapPointerData[key]['longitude'] != null){

              if (this.mapPointerData[key]['alarmState'] == "Offline") {
                this.mapPointerData[key]['alarmState'] = "Suspended";
              }
              // this.latitude = this.mapPointerData[key]['latitude']
              // this.longitude = this.mapPointerData[key]['longitude']
              position.push([
                this.mapPointerData[key]['latitude'],
                this.mapPointerData[key]['longitude'],
                this.mapPointerData[key]['alarmState'],
                this.mapPointerData[key]['customerName'],
                this.mapPointerData[key]['assetDescription'],
                this.mapPointerData[key]['site'],
                this.mapPointerData[key]['city'],
                this.mapPointerData[key]['state'],
                this.mapPointerData[key]['country'],
                this.mapPointerData[key]['lastEventTime'],
                this.mapPointerData[key]['assetId'],
                this.mapPointerData[key]['name'],
                this.mapPointerData[key]['proximity'],
              ]);
            }
          });

            var self = this;
            setTimeout(()=>{
              self.positions = position;
            },3000);

        } else {
          if(data.responseMessage != 'Permission not granted') {
            this.errorText = data.responseMessage;
            this.styleExp.display = "block";
            this.mapPointerData = [];
            this.positions = [];
          }else {
            // this.errorText = data.responseMessage;
            // this.styleExp.display = "block";
            this.mapPointerData = [];
            this.positions = [];
          }
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // Zoom Functionality
  getBoundsZoomLevel(bounds, mapDim) {
    var WORLD_DIM = {
        height: 256,
        width: 256
    };
    var ZOOM_MAX = 21;

    function latRad(lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

    // Graph functions
    onMapReady(map?:any) {
        this.map = map;
        this.bounds = new google.maps.LatLngBounds();
    }

    // Graph functions
    onMarkerInit(marker) {
      let loc = new google.maps.LatLng(marker.position[0], marker.position[1]);

      this.bounds.extend(loc);
      this.zoomLevel = this.getBoundsZoomLevel(this.bounds, {
        "height": 330,
        "width": 550
      });

      var data = this.bounds.getCenter();
      this.map.fitBounds(this.bounds);
      this.map.setCenter(this.bounds.getCenter());

      var that = this;
      setTimeout(()=>{
          this.latitude = data.lat();
          this.longitude = data.lng();
          this.zoomLevel = this.zoomLevel;
      },3000)

      this.chRef.detectChanges();
    }


/* Get Site Management list */
getAllSiteList(){

  if(this.clientId != null){
   this.apiService.getAll('site/getSiteDropDownListByClient?clientId='+this.clientId+'&userId='+this.userId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.siteList = data.responseList;
        }else{
          this.siteList = [];
        }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
   }

}

clickChartC(event){
    this.apiService.getAll('healthCheck/getAllhealthCheck?status=true').subscribe(
      data => {
        if(data.statusCode == 100){
          this.healthCheckList = data.responseList;
        }else{
          this.healthCheckList = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
}


getAllAssetList(){
     this.apiService.getAll('asset/getAssetDropDownList?userId='+this.userId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetDatalist = data.responseList;
        }else{
           this.assetDatalist = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* On Item Select Site */
  onItemSelectSite(item: any) {
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if(exist == '-1'){
        this.checkSiteArray.push(item.siteId.toString());
        this.getAllAssetList();
      }

  }

  /* On Item DeSelect Site */
  onItemDeselectSite(item: any){
  var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if(exist != '-1'){
        this.checkSiteArray.splice(exist,1);
        this.getAllAssetList();
      }
  }

  /* On All Select Site */
  onSelectAllSite(items: any) {
    this.checkSiteArray = [];
    Object.keys(items).forEach(key=> {
        this.checkSiteArray.push(items[key]['siteId'].toString());
      });
      this.getAllAssetList();
  }
  /* On All DeSelect Site */
  onDeSelectAllSite(){
    this.checkSiteArray = [];
    this.getAllAssetList();
  }
  /* On Item Select Asset */
  onItemSelectAsset(item: any) {
      var exist = this.checkAssetArray.indexOf(item.assetId.toString());
      if(exist == '-1'){
          this.checkAssetArray.push(item.assetId.toString());
        }
    }

 /* On All DeSelect Site */
  onDeSelectAllAsset(){
    this.checkAssetArray = [];
  }
 /* On All Select Site */
  onSelectAllAsset(items: any) {
    this.checkAssetArray = [];
    Object.keys(items).forEach(key=> {
        this.checkAssetArray.push(items[key]['assetId'].toString());
      });
      this.getAllAssetList();
  }
  /* On Item DeSelect Asset */
  onItemDeselectAsset(item: any){
    var exist = this.checkAssetArray.indexOf(item.assetId.toString());
      if(exist != '-1'){
        this.checkAssetArray.splice(exist,1);
      }
  }

  changeLink(link){    
    this.router.navigate(link)
  }

  monthChange(){
    let selectedMonth = $('#months').children("option:selected").val();
    let selectedYear = $('#selectedYear').children("option:selected").val();
    this.getActiveDeactivebyProvider(selectedMonth, selectedYear);
  }

  assetDetail(statusValue, flag){        
   // Destroy Data Table if is already and clear the data
    if ($.fn.DataTable.isDataTable('#asset_data_table')){
      $('#asset_data_table').DataTable().destroy();
      this.assetDatalistPopup=[];
    }    

    // var URL = 'asset/getAssetStateValue?userId=' + this.userId+((this.clientFilterId && this.clientFilterId != 0) ? '&clientId='+this.clientFilterId : '&clientId='+this.clientTopDropdownValue);
    // changed as per the requirements on 15/11/2019
    var URL = 'asset/getAssetStateValue?userId=' + this.userId+((this.clientFilterId && this.clientFilterId != 0) ? '&clientId='+this.clientFilterId : '');

    this.assetDatalistPopup = [];
     
    if (statusValue != false && flag == 1) {
        var statusString = '&alarmState=' + statusValue;
        var URL = URL.concat(statusString);
    } else if (statusValue != false && flag == 2) {
        var statusString = '&status=' + statusValue;
        var URL = URL.concat(statusString);
    }
 
    $('#assetListModel').modal("show");
    this.apiService.getAll(URL).subscribe(
    data => {

      if (data.statusCode == 100) {
          let Arr = []
          this.assetDatalistPopup = [];
          let objArr =  data.responseList;          

          objArr.map((val,key)=>{
              let objValue = val
              if (!val.notificationPlan) {
                  objValue.notificationPlan = {};
              }
              if (!val.shiftGroup) {
                  objValue.shiftGroup = {};
              }
              if (!val.customer) {
                  objValue.customer = {};
              }
              if (!val.site) {
                  objValue.site = {};
              }
              Arr.push(objValue);
          });

          this.assetDatalistPopup = JSON.parse(JSON.stringify(Arr));

          this.chRef.detectChanges();

          const table: any = $('#asset_data_table');
          this.dataTable   = table.DataTable();

      }
      else {
          this.assetDatalistPopup = [];
          if ($.fn.DataTable.isDataTable('#asset_data_table')){
            $('#ticket_table').DataTable().destroy();
            this.assetDatalistPopup=[];
          }
          const table: any = $('#asset_data_table');
          this.dataTable   = table.DataTable();

      }
    },
    error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
);
}

  healthCheckDetail(event){   
   // Destroy Data Table if is already and clear the data
    if ($.fn.DataTable.isDataTable('#DATATABLEhEALTH')){
      $('#DATATABLEhEALTH').DataTable().destroy();
      this.healthCheckList=[];
    }    

    var URL = 'healthCheck/getAllhealthCheck?status='+event;
    this.healthCheckList = [];     
    
    $('#healthCheckModel').modal("show");
    this.apiService.getAll(URL).subscribe(
    data => {

      if (data.statusCode == 100) {


          this.healthCheckList = JSON.parse(JSON.stringify(data.responseList));

          this.chRef.detectChanges();
          if ($.fn.DataTable.isDataTable('#DATATABLEhEALTH')){
            $('#DATATABLEhEALTH').DataTable().destroy();
          } 
          setTimeout(() => {
            const table: any = $('#DATATABLEhEALTH');
            this.dataTable   = table.DataTable({
              "columnDefs": [{ 
                "orderable": false, 
                "targets": 2 
              }]
            }
            );
          }, 1000);

      }
      else {
          this.assetDatalistPopup = [];
          if ($.fn.DataTable.isDataTable('#DATATABLEhEALTH')){
            $('#DATATABLEhEALTH').DataTable().destroy();
            this.healthCheckList=[];
          }
          const table: any = $('#DATATABLEhEALTH');
          this.dataTable   = table.DataTable(
          {
            "columnDefs": [{ 
              "orderable": false, 
              "targets": 2 
            }]
          }
          );

      }
    },
    error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
);
}


  getActiveDeactivebyProvider(selectedMonth, selectedYear) {
      if($.fn.DataTable.isDataTable('#data_table_subscriptions')){
        $('#data_table_subscriptions').DataTable().destroy();       
      }   
      // this.assetVisitArr = [];
      var date = new Date();
      let curMonth =date.getMonth() + 1;
      this.curMonth = curMonth;
      var URL = 'asset/getActiveDeactivebyProvider?month='+selectedMonth+'&year='+selectedYear;
      this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){
            // if($.fn.DataTable.isDataTable('#data_table_subscriptions')){
            //   $('#data_table_subscriptions').DataTable().destroy();       
            // }  
            //  this.assetVisitArr = [];
            this.activeDeactiveArr = data.responseList;
            this.totalActivated = data.activeCount;
            this.totalDeactivated = data.deactiveCount;
            this.totalActiveDeactiveCount = data.totalActiveDeactiveCount;
            this.chRef.detectChanges();
            setTimeout(() => {
              const table : any = $('#data_table_subscriptions');
              this.dataTable   = table.DataTable();          
            }, 500);
          }else{ 
            this.activeDeactiveArr = [];
            setTimeout(() => {
              const table : any = $('#data_table_subscriptions');
              this.dataTable   = table.DataTable();  
            },500);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
  }

  renderComplete($event){
    // this.zone.run(() => {
    //   this.message += ', renderComplete';
    // })
    Array.from($("[class$=pie]")).forEach(function(elm) {
      Array.from($(elm).children()).forEach(function(path:any) {
        path.style.cursor = 'pointer';
      })
    })
  }

  getHealthCheckPieChart() {
    var URL = 'healthCheck/getHealthCheckPieChart';
      this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){   
            this.healthCheckChartArr = data.responseList;
            let Arr = [];
            let valueArr = this.healthCheckChartArr
            valueArr.map((val, key)=>{
                let obj = {
                  'label' : val.label,
                  'value' : val.value,
                  'color' : val.color
                };
                Arr.push(obj);
                this.tempArr = Arr;
            });
          }else{ 
            this.healthCheckChartArr = [];
          }
          setTimeout(() => {
            var dataSource = this.dataSource;
            this.dataSource.data = this.tempArr; 
          //    FusionCharts.ready(function() {
          //     var myChart = new FusionCharts({
          //       type: "pie2d",
          //       renderAt: "chart-container",
          //       width: "100%",
          //       height: "90%",
          //       dataFormat: "json",
          //       dataSource
          //     }).render();
          // });      
          }, 1000);
          setTimeout(() => {
            this.handler = this.clickChart.bind(this);
            // this.message = this.clickPlotMsg;
            // this.attached = true;
            this.chartObj.addEventListener('dataplotClick', this.handler);
          }, 1000);

        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
  }

  initializedChartD($event) {
    this.chartObj = $event.chart;
  }

  clickChart(event){
    if (event.data.categoryLabel == "Down") {
      console.log(event.data.categoryLabel);
      this.healthCheckDetail(false)
      
    }else if (event.data.categoryLabel == "Running") {
      console.log(event.data.categoryLabel);
      this.healthCheckDetail(true)
    }
    
  }
  getAllhealthCheck() {      
      var URL = 'healthCheck/getAllhealthCheck';
      this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){
            this.healthCheckArr = data.responseList;
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_health_check');
              this.dataTable   = table.DataTable();          
            }, 300);
          }else{ 
            this.healthCheckArr = [];
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
  }

  getLast10AssetsVisitByUser(){
      if($.fn.DataTable.isDataTable('#data_table_last_10_asset')){
        $('#data_table_last_10_asset').DataTable().destroy();
        this.assetVisitArr = [];
      };  

      var URL = 'assetVisit/getLast10AssetVisitByUser?userId='+this.userId;
      this.apiService.getAll(URL).subscribe(
        data => {
          let countArr = data.responseList;
          if(data.statusCode == 100){
            this.assetVisitArr = data.responseList;
            // console.log('this.assetVisitArr', this.assetVisitArr);
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_last_10_asset');
              this.dataTable   = table.DataTable({"aaSorting": []});          
            }, 1000);
          }else{ 
            this.assetVisitArr = [];
            // if($.fn.DataTable.isDataTable('#data_table_last_10_asset')){
            //   $('#data_table_last_10_asset').DataTable().destroy();
            //   this.assetVisitArr = [];
            // };
            setTimeout(() => {
              const table: any = $('#data_table_last_10_asset');
              this.dataTable   = table.DataTable(); 
            }, 1000);  
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
  }

  getAssetStatusWidget() {    
    if(this.clientFilterId != null && this.clientFilterId != 0) {
      var URL = 'asset/getAssetAlarmStateCount?userId='+this.userId+((this.clientFilterId && this.clientFilterId != 0) ? '&clientId='+this.clientFilterId : '&clientId='+this.clientFilterId);
    } else {
      var URL = 'asset/getAssetAlarmStateCount?userId='+this.userId//+'&clientId='+this.clientFilterId;
    }

    this.apiService.getAll(URL).subscribe(
      data => {
        let countArr = data.responseList;
        if(data.statusCode == 100){
          Object.keys(countArr).forEach(key=> {          
            var alarmState = countArr[key]['alarmState'];
            var countValue = countArr[key]['value'];
            if(alarmState == 'Alarm')
            {
              this.alarmCount = countValue;  
            } else if(alarmState == 'No comm') {
              this.noCommCount = countValue;  
            } else if(alarmState == 'Suspended') {
              this.offlineCount = countValue;  
            } else if(alarmState == 'Warning') {
              this.warningCount = countValue;  
            } else if(alarmState == 'Normal') {
              this.normalCount = countValue;  
            } else if(alarmState == 'totalActiveCount') {
              this.totalActiveCount = countValue;  
            } else if(alarmState == 'New') {
              this.newCount = countValue;  
            } else if(alarmState == 'Delivered') {
              this.deliveredCount = countValue;  
            } else if(alarmState == 'Repair') {
              this.repairCount = countValue;  
            } else if(alarmState == 'Scrapped') {
              this.scrappedCount = countValue;  
            } else if(alarmState == 'totalSitesCount') {
              this.totalSitesCount = countValue;  
            } else if(alarmState == 'Deactive') {
              this.deactivatedCount = countValue;  
            }
            
          });

        }
      },
      error => {
        // this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getLatestLiveFeed(){     
    this.clientTopDropdownValue =  $('#client_dropdown_top').children("option:selected").val();
    if($.fn.DataTable.isDataTable('#data_table_feed')){
      $('#data_table_feed').DataTable().destroy();
      this.LiveFeedArr = [];
    };   
    if(this.clientFilterId != null && this.clientFilterId != 0) {
      var URL = 'wffnotification/getLatestEvents?clientId='+this.clientFilterId;
    } else {
      var URL = 'wffnotification/getLatestEvents'//&clientId='+this.clientTopDropdownValue;
    }

    if(this.customerId != null && this.customerId !=''){
      var customerString     = '&customerId='+this.customerId.toString();
      var URL = URL.concat(customerString);
    }

    this.apiService.getAll(URL).subscribe(
        data => {
          if(data.statusCode == 100){
            if($.fn.DataTable.isDataTable('#data_table_feed')){
              $('#data_table_feed').DataTable().destroy();
              this.LiveFeedArr = [];
            };   
            this.LiveFeedArr = data.responseList;
            this.chRef.detectChanges();
            setTimeout(() => {
              if($.fn.DataTable.isDataTable('#data_table_feed')){
                $('#data_table_feed').DataTable().destroy();
                // this.LiveFeedArr = [];
              };   
              const table: any = $('#data_table_feed');
              /* this.dataTable   =  */table.DataTable({"order": [], "columnDefs": [{ "orderable": false, "targets": 10 }]});
            }, 1000);
          }else{ 
            if($.fn.DataTable.isDataTable('#data_table_feed')){ 
              $('#data_table_feed').DataTable().destroy();
              this.LiveFeedArr = [];
            };   
            this.LiveFeedArr = [];
            setTimeout(() => {
              if($.fn.DataTable.isDataTable('#data_table_feed')){
                $('#data_table_feed').DataTable().destroy();
              };
              const table: any = $('#data_table_feed');
              /* this.dataTable   =  */table.DataTable({ "destroy": true, "paging": false,"order": [], "retrieve": true, "searching": false});
            }, 1000);
          }
        },
        error => {
          // this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
  }


  notificationDetail(eventData, eventListType) {
    let runTimeStatus = eventData.runTimeStatus;
    let licenseIsAck = eventData.licenseIsAck;
    let acknowledgeByMe = eventData.acknowledgeByMe;
    let assignToMe = eventData.assignToMe;
    let technicianId = eventData.technicianId;      
    let licenseIsTechEntry = eventData.licenseIsTechEntry;
    let isAck = eventData.isAck;
    let clientId = eventData.clientId;

    if(this.userId == eventData.technicianId && runTimeStatus == 2) {
       this.performAction = 1;
    } 

    // console.log('notification detail', eventData.acknowledgeByMe, eventData.licenseIsAck, eventData.assignToMe);
    
    // console.log('notification detail 2', eventData.technicianId, eventData.runTimeStatus, eventData.licenseIsTechEntry);
    

    if(licenseIsAck == true && acknowledgeByMe == false && assignToMe == true) {
      console.log('in if detail');
      this.showAckBtn = 1;
    } else {
      this.showAckBtn = 0;
      console.log('in else detail');
    }

    if(
      licenseIsAck == true && 
      acknowledgeByMe == false && 
      assignToMe == true && 
      (technicianId == null || technicianId == 0) && 
      licenseIsTechEntry == true && 
      runTimeStatus == 1
      ) {
      console.log('in if detail showAckErcBtn');
      this.showAckErcBtn = 1;
    } else {
      this.showAckErcBtn = 0;
      console.log('in else detail showAckErcBtn');
    }

    if(
      licenseIsAck == true && 
      assignToMe == true && 
      (technicianId == null || technicianId == 0) &&         
      runTimeStatus == 1
      ) {
      this.showERC = 1;
    } else {
      this.showERC = 0;
    }

    let obj = {
      id:null,
      type:null
    }
    let pVal = 0;
    if(eventData.isParent == false) {
      pVal = 0;
    } else if(eventData.isParent == true) {
      pVal = 1;
    }
    obj.type = eventData.notificationType;
    if (eventData.notificationType == "Notification") {
        obj.id = eventData.notificationId;
    } else if (eventData.notificationType == "RunTime") {
        obj.id = eventData.runTimeEventId;
    }
    if(eventListType == 'approval' || eventListType == 'approved') {
      obj.type = 'RunTime';
      obj.id = eventData.runTimeEventId;
      this.performAction = 1;
      eventData.licenseIsTechEntry = true;
    }

    this.router.navigate(["eventDetails", eventData.assetId,], 
      {
        queryParams: 
        { 
          type: obj.type, 
          id :obj.id, 
          rcpId:eventData.runTimeCollectionPointId, 
          notificationId : eventData.notificationId ? eventData.notificationId : 0, 
          isParent : pVal, 
          performAction : this.performAction, 
          showAckBtn : this.showAckBtn,
          showAckErcBtn : this.showAckErcBtn,
          showERC : this.showERC,
          licenseIsTechEntry : eventData.licenseIsTechEntry, 
          eventListType : eventListType,
          assignToMe : assignToMe == true ? 1 : 0,
          isAck : isAck == true ? 1 : 0,
          clientId : clientId ? clientId : 0,
          previousRoute: this.router.url ,
          source:'D'
        }
    });
  }

  getHistory(obj){
    this.HistoryList = []
    let URL = ''
    if (obj.notificationType == 'Notification') {
      URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.notificationId+'&isevent=false'
      // URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+943731+'&isevent=true'
    }else{
      URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.runTimeEventId+'&isevent=true'
    }

    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll(URL).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                 table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                 table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }
  setResponsibilityList(list){
    this.responsibilityList = list
  }
  setEventrollupLists(list){
    this.rollUpEventList = list
  }
  getCommentDecoded(comment){
    return decodeURIComponent(comment)
  }
}
