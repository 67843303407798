import { Component, OnInit, ChangeDetectorRef, ViewChild , ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $: any;
declare var jQuery: any;
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { environment } from "../../../../../../environments/environment"

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})

export class AddClientComponent implements OnInit {
  clientUser: any = JSON.parse(localStorage.getItem("clientUser"));
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  countryList: any = [];
  stateList: any = [];
  dataTable: any;
  contactData: any = [];
  clientdetailId: any = 0;
  loginPageContent:any = ""

  filePath:any = "assets/img/upload-img.png";
  filePathInner:any = "assets/img/upload-img.png";
  filePathMobile:any = "assets/img/upload-img.png";
  urlForLocal:any ;
  imageUrl :any =  "assets/img/upload-img.png";

  imgBasePath : any ;
  newClientId : any ;
  client: any = {
    "description": "",
    "maxDaysHistory": "", "maxInactivityHours": "",
    "billingName": "", "deactivationTime": "",
    "requiresPinVerification": false,"isLive": false,
    "isActive": true, "contactEmail": '',
    "criticalThresHold": "25", "contactPhone": '', "alternatePhone": '',
    "emailDomain" : '',
    "clientCode" : '',
    "clientDomain" : '',
    "isNotification":false
  };
  criticalThresHold = []
  lastImage :any ;
  imageError = "";
  imageTitle = "";
  // canChangeEmailDomain : any = "";
  canChangeEmailDomain : boolean = false;


  @ViewChild("addClient") addClient:any;
  @ViewChild("clientContact") clientContact:any;
  @ViewChild('fileUploader') fileUploader:ElementRef;
  @ViewChild('imageUrlName') imageUrlName: ElementRef;
  imageName:any;
  imageNameInner:any;
  imageNameMobile:any;

  contact: any =
  {
    "firstName": "",
    "lastName": "",
    "note": "",
    "emailId": "",
    "phoneNumber": "",
    "mobileNumber": "",
    "userType": "Client",
    client: {}
  }

  branding: any = {};
  userListTableData: any = [];
  assetListTableData: any = [];
  ImageTitle :any  = 'select image';
  // clientName : any = localStorage.getItem("clientName");
  clientName : any = ""; // to change current client name in breadcrumb

  notificationPlan:boolean = false;
  assetErrMsg:any = '';
  assetArrayForTeam:any = [];
  
  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }

  ngOnInit() {

    jQuery('.rst-date').datetimepicker({
      language: 'en',
      pickTime: true,
      pickDate: true,
      minDate: 0,
      inline: true,
      sideBySide: true
    });

    for(let i = 0;i<=100;i++){
      this.criticalThresHold.push(i);
    }

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.clientdetailId = params['id'];
      }
       localStorage.setItem("clientdetailId", this.clientdetailId);
    });
    if (this.clientdetailId) {
      this.getClientById();
      this.getContactData();
    }
  }


  /* Get Client by Id*/
  getClientById() {
    this.apiService.getAll('client/getClientById?clientId=' + this.clientdetailId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.client = data.response;
          this.canChangeEmailDomain = this.client.canChangeEmailDomain;

          // to change current client name in breadcrumb start
          if((this.client.description!=null) && ( this.client.description!="")){
            this.clientName = "";
            this.clientName = this.client.description;
            localStorage.setItem('ClientInfoName',this.clientName)
          }
          // to change current client name in breadcrumb end

          if(this.client.logoFile != null){
            this.imageUrl = this.apiService.imageBasePath +this.client.logoFile;
            let imageArr = this.client.logoFile.split('/');
            this.imageTitle = imageArr[2];
          }
    

          let obj = this.client;
          this.client.isLiveShow = data.response.isLive;
          this.branding = {
            clientDomain : obj.clientDomain,
            clientCode: obj.clientCode,
          }
          this.imageName = null;
          this.imageNameInner = null;
          this.imageNameMobile = null;
          $("#logoFile").val('')
          $("#logoFileInner").val('')
          $("#logoFileMobile").val('')
          // this.urlForLocal = "192.168.0.112/images"
          this.loginPageContent = obj.loginPageContent ? obj.loginPageContent : ""
          this.filePath = obj.logoFile ? this.apiService.imageBasePath+obj.logoFile : "assets/img/upload-img.png";
          this.imageUrl = obj.logoFile ? this.apiService.imageBasePath+obj.logoFile : "assets/img/upload-img.png";
          this.filePathMobile = obj.appLogoPath ? this.apiService.imageBasePath+obj.appLogoPath : "assets/img/upload-img.png";
          this.filePathInner = obj.clientInnerLogoPath ? this.apiService.imageBasePath+obj.clientInnerLogoPath : "assets/img/upload-img.png";
          // // -------------------------------------------------------  branding details from client

        }
        else
          { this.alertService.sweetMessage('error',data.responseMessage); }
      },
      error =>
        { this.alertService.sweetMessage('error','Error in network'); }
    );
  }


  /* Create Client */
  SubmitClient() {
    this.assetArrayForTeam = [];
    if ($.fn.DataTable.isDataTable('#notification_plan_table')) {
      $('#notification_plan_table').DataTable().destroy();
    }
    
    this.client.deactivationTime = $('#deactivationTime').val();
    if (this.addClient.valid && (this.client.contactPhone != this.client.alternatePhone) ) {
      if (this.clientdetailId == 0) {
        this.apiService.create('client/createClient', this.client).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.newClientId = data.response.clientId;
              this.imageSave(6,this.imageName);
              this.alertService.sweetMessage('success', 'Client details added successfully');
              this.client = {};
              this.router.navigate(['/client']);
            }else {
              this.alertService.sweetMessage('error', data.responseMessage, 5000);
            }
          },
          error =>
          {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
      else {
        this.assetArrayForTeam = [];
        let obj = this.client
        delete obj.logoFile
        delete obj.appLogoPath
        delete obj.clientInnerLogoPath

        this.apiService.update('client/updateClient', obj).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.newClientId = this.clientdetailId;
              this.imageSave(6,this.imageName);
              this.router.navigate(['/client']);            
              this.alertService.sweetMessage('success','Client details Updated successfully');

            } else if (data.statusCode == 112) {
              this.assetArrayForTeam = [];
              // this.assetErrMsg = data.responseMessage;
              this.assetErrMsg = 'Cannot activate the Notifications, as for the following assets no team is available'
              this.notificationConfirmation();
              this.assetArrayForTeam = data.response.assetDtos;
              if ($.fn.DataTable.isDataTable('#notification_plan_table')) {
                $('#notification_plan_table').DataTable().destroy();
              }
              setTimeout(() => {
                const table: any = $('#notification_plan_table');
                this.dataTable = table.DataTable();                    
              }, 500);
              console.log("this.assetArrayForTeam--",this.assetArrayForTeam);
            }
            else
              {
                this.assetArrayForTeam = [];
                if ($.fn.DataTable.isDataTable('#notification_plan_table')) {
                    $('#notification_plan_table').DataTable().destroy();
                };
                setTimeout(() => {
                  const table: any = $('#notification_plan_table');
                  this.dataTable = table.DataTable();                    
                }, 500);

                 this.alertService.sweetMessage('error',data.responseMessage, 6000); }
          },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
      }
    }
    // else{
      //this.alertService.sweetMessage('error','please fill all required fields.');
    //}
  }

  // function to check whether we can make Client Live or not
  goClientLive(){
    var liveCheck = $('input[name="isClientLive"]:checked').val();
    if(liveCheck == 'on' && liveCheck != 'undefined'){
      this.apiService.getAll('client/getCLientGoLiveObjects?clientId='+this.clientdetailId).subscribe(
        data => {
          if (data.statusCode == 100) {
              this.userListTableData = data.response.users;
              this.assetListTableData = data.response.assets;
              this.chRef.detectChanges();
              const table: any = $('#users_table');
              this.dataTable = table.DataTable();
              const table_assets: any = $('#assets_table');
              this.dataTable = table_assets.DataTable();

              $('#users_block').css('display', 'block');
              $('#assets_block').css('display', 'block');
          }else{
            $('input[name="isLive"]:checked').click();
            this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

  // function to check whether we can make Asset Live or not
  goAssetLive(){
    var liveCheck = $('input[name="isAssetLive"]:checked').val();
    if(liveCheck == 'on' && liveCheck != 'undefined'){
    let reqObj:any = {
      "clientId": this.clientdetailId,
    
     }
      this.apiService.create('asset/getAssetByClient', reqObj).subscribe(
        data => {
          if (data.statusCode == 100) {
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

  /* Get Contact data list */
  getContactData() {
    this.apiService.getAll('contactType/getClientContactListByClientId?clientId=' + this.clientdetailId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.contactData = data.contactTypeList;
          this.chRef.detectChanges();
          const table: any = $('#data_table');
          this.dataTable = table.DataTable();
        }
        else {
          this.contactData = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  SaveContact() {
    this.contact.client.clientId = this.clientdetailId;


    if (this.clientContact.valid) {
      if (this.contact.userId) {
        // this.apiService.update('user/updateClientContact/', this.contact).subscribe(
        this.apiService.update('contactType/updateClientContact/', this.contact).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getContactData();
              this.alertService.sweetMessage('success',data.responseMessage);

              this.contact =
                {
                  "firstName": "",
                  "lastName": "",
                  "note": "",
                  "emailId": "",
                  "phoneNumber": "",
                  "mobileNumber": "",
                  "userType": "",
                  client: {}
                }
                this.clientContact.reset();
                this.clientContact.submitted = false;
            }
            else
              { this.alertService.sweetMessage('error',data.responseMessage); }
          },
           error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
      } else {

        this.clientContact.submitted = false;
        this.apiService.create('contactType/createClientContact', this.contact).subscribe(
        // this.apiService.create('user/createClientContact', this.contact).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getContactData();
              this.alertService.sweetMessage('success',data.responseMessage);

              this.contact =
                {
                  "firstName": "",
                  "lastName": "",
                  "note": "",
                  "emailId": "",
                  "phoneNumber": "",
                  "mobileNumber": "",
                  "userType": "",
                  client: {}
                }
                this.clientContact.reset();
                this.clientContact.submitted = false;
            }
            else
              { this.alertService.sweetMessage('error',data.responseMessage);   }
          },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");}
        );
      }
    }


  }

  SubmitBranding(){
    let obj = this.branding
    obj.clientId = this.clientdetailId;
    let val = this.loginPageContent.replace('',"");
    obj.loginPagecontent =  val//this.loginPageContent//.substring(1, 199);
    // console.log(obj,val,this.loginPageContent);
    this.imageSave(6,this.imageName);
    this.imageSave(4,this.imageNameMobile);
    this.imageSave(5,this.imageNameInner);
    this.apiService.update('client/createBranding', obj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.getContactData();
          this.getClientById();
          this.alertService.sweetMessage('success',data.responseMessage);
          // this.branding = {}
          this.getClientById();
        }
        else
          { this.alertService.sweetMessage('error',data.responseMessage); }
      },
      error =>
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");}
    );
  }

  imageSave(section,imageName){
    const uploadData = new FormData();
    if(imageName){
      uploadData.append('file', imageName[0], imageName[0].name);
      this.apiService.create('uploadImage/uploadImage?section='+section+'&sectionId='+this.newClientId, uploadData).subscribe(
        data => {
          if(data.statusCode == 100){
            setTimeout(()=>{
              this.imageName = null;
              this.imageNameInner = null;
              this.imageNameMobile = null;
            },2000)
          }
          else
            { this.alertService.sweetMessage('error',data.responseMessage); }
        },
        error =>
          { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
    }
  }

// when user click on image to upload Image
  onFileChanged(event) {
    const file = event.target.files;
    const fileType = file[0]['type'];
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    this.imageError = "";
    
    // invalid file type code goes here.
    if (!validImageTypes.includes(fileType)) {
      this.imageUrl = "assets/img/upload-img.png";
      this.imageName = null;
      this.imageTitle = "";
      this.imageError = "Please select valid image type such as jpg, jpeg, png.";
      return;
    }

    if(this.imageError==""){
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
          var img = new Image();
          img.src = _event.target.result;
          img.onload = () => {
              // this.imageUrl = reader.result as string;
              if (img.width <= 300 && img.height <= 300) {
                this.imageUrl = reader.result as string;
                this.imageName = file;
                this.imageTitle = file[0]['name'];
              } else {
                this.imageUrl = "assets/img/upload-img.png";
                this.imageName = null;
                this.imageTitle = "";
                this.imageError = "Image Size should be less than 300X300";
                return;
              }
          };
      }
    }



      // if ((file[0].size/1024)>500) {
      //     this.alertService.sweetMessage('error',"Size should be below 500 KB");
      //     if (this.lastImage) {
      //       if (this.lastImage.imageUrl) {
      //         this.ImageTitle = this.lastImage.ImageTitle;
      //         this.imageUrl = this.lastImage.imageUrl;
      //         this.ImageTitle = this.lastImage.ImageTitle;
      //         this.imageName = this.lastImage.imageName;
      //       }else{
      //         this.ImageTitle = 'select image';
      //         this.fileUploader.nativeElement.value = null;
      //         $("#myFileImage").val(null);
      //         this.imageUrl = "assets/img/upload-img.png"
      //         // this.alertService.sweetMessage('error','Image should not be greater then 300 X 300');
      //         this.ImageTitle = 'select image';
      //       event.target.value = ""
      //       event.target.files = null
      //       }

      //     }
      // } else {
      //   if(file[0].type != 'image/png' && file[0].type != 'image/jpg' &&  file[0].type != 'image/jpeg'){
      //     this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG image are allowed");
      //     this.ImageTitle = 'select image';
      //     this.fileUploader.nativeElement.value = null;
      //     $("#myFileImage").val(null);
      //     event.target.value = ""
      //     event.target.files = null
      //     return;
      //   }

        // var reader = new FileReader();
        // reader.readAsDataURL(file[0]);
        // reader.onload = (_event:any) => {
        //   var img = new Image();
        //   img.src = _event.target.result;
        //   img.onload = () => {
        //     if (img.width <= 300 && img.height <= 300) {
        //       this.filePath   = reader.result;
        //       this.imageName  = file;
        //       this.ImageTitle = file[0].name;
        //       this.imageUrl   = reader.result as string;
        //       this.lastImage = {
        //         filePath : this.filePath,
        //         imageName : this.imageName,
        //         ImageTitle : this.ImageTitle,
        //         imageUrl : this.imageUrl
        //       }
        //     }
        //     else {
        //       this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
        //       if (this.lastImage) {
        //         if (this.lastImage.imageUrl) {
        //           this.ImageTitle = this.lastImage.ImageTitle;
        //           this.imageUrl = this.lastImage.imageUrl;
        //           this.ImageTitle = this.lastImage.ImageTitle;
        //           this.imageName = this.lastImage.imageName;
        //         }else{
        //           this.ImageTitle = 'select image';
        //           this.fileUploader.nativeElement.value = null;
        //           event.target.files = null
        //           event.target.value = ""
        //           this.imageUrl = "assets/img/upload-img.png"
        //           // this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
        //           $("#myFileImage").val(null);
        //         }
        //       }
        //     }
        //   };
        // }
      // }
    }

    resetFile(){
      $("#myFileImage").attr("value", "");
      if (this.lastImage) {
        if (this.lastImage.imageUrl) {
          this.ImageTitle = this.lastImage.ImageTitle;
          this.imageUrl = this.lastImage.imageUrl;
          this.ImageTitle = this.lastImage.ImageTitle;
          this.imageName = this.lastImage.imageName;
        }else{
          this.ImageTitle = 'select image';
          this.fileUploader.nativeElement.value = null;
          this.imageUrl = "assets/img/upload-img.png"
          // this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
          $("#myFileImage").val(null);
        }
      }else{
        this.ImageTitle = 'select image';
        this.fileUploader.nativeElement.value = null;
        this.imageUrl = "assets/img/upload-img.png"
        // this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
        $("#myFileImage").val(null);
      }
    }

    onFileChangedInner(event) {
      const file = event.target.files;
      if ((file[0].size/1024)>500) {
        this.alertService.sweetMessage('error',"Size should be below 500 KB");
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = (_event:any) => {
          var img = new Image();
          img.src = _event.target.result;
          img.onload = () => {
            if (img.width < 300 && img.height < 300) {
              this.filePathInner = reader.result;
              this.imageNameInner = file;
            } else {
              this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
            }
          };
        }
      }
    }

      onFileChangedMobile(event) {
          const file = event.target.files;
          // console.log(file[0],(file[0].size/1024),file[0].height,file);
          if ((file[0].size/1024)>500) {
              this.alertService.sweetMessage('error',"Size should be below 500 KB");
          } else {
            var reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = (_event:any) => {
              var img = new Image();
              img.src = _event.target.result;
              img.onload = () => {
                if (img.width < 170 && img.height < 170) {
                  this.filePathMobile = reader.result;
                  this.imageNameMobile = file;
                } else {
                  this.alertService.sweetMessage('error','Image should not be greater than 170 X 170');
                }
              };
            }
          }
        }

    // user/getClientContactById?userId=4286
    getContactById(id){
      // this.apiService.getAll('user/getClientContactById?userId=' +id).subscribe(
      this.apiService.getAll('contactType/getContactTypeById?contactTypeId=' +id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.contact = data.contactType;
          } else {
            // this.contactData = [];
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

    DeleteClientContact(userId){
      Swal({
          title: 'Are you sure?',
          text: "You want to delete this Contact.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.apiService.delete('user/deleteUser?userId='+userId, '').subscribe(
            data => {
              if(data.statusCode == 100){
                this.getContactData();
                  this.chRef.detectChanges();
                  const table: any = $('.table');
                  this.dataTable = table.DataTable();
                  this.alertService.sweetMessage('success','Deleted Successfully');
              }else{
                this.alertService.sweetMessage('error',data.responseMessage);
              }
            },
            error =>
              { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
            );
          }
        })
    }
  

    /* Notification plan confirmation box*/
    notificationConfirmation(){
      Swal({
      title: 'Are You Sure?',
      text: "You want to activate the Notifications for this Client?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      // if (result.value && this.assetArrayForTeam.length > 0)
    if (result.value) {
      $("#assetModal").click();
        }else {
          // this.client.isNotificationRequired = false;      
          this.client.isNotification = false;  
          // this.router.navigate(['/client']);

        }
      })
    }

    closeTeamListModal() {
    // this.router.navigate(['/client']);
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
      // this.router.navigate(['/client']);      
    }

  removeInputImage() {
    this.imageUrlName.nativeElement.value = '';
    this.imageTitle = '';
  }

  // function for deleting image of branding 
  removeBrandingImage() {
    Swal({
      title: 'Are you sure?',
      text: 'You want to remove image.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        if (this.clientdetailId) {
          this.apiService.delete("uploadImage/removeImage?section=6&sectionId=" + this.clientdetailId, '').subscribe(
            data => {
              if (data.statusCode == 100) {
                this.alertService.sweetMessage('success', 'Image removed successfully');
                this.imageUrl = 'assets/img/upload-img.png'
              } else {
                this.alertService.sweetMessage('error', data.responseMessage);
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        } else {
          this.alertService.sweetMessage('success', 'Image removed successfully');
          this.imageUrl = 'assets/img/upload-img.png'
        }
      }
    })
  }
}