import { Component, OnInit, ViewChild } from '@angular/core';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { ActivatedRoute } from '@angular/router';
import { AlertService, ApiService } from 'src/app/services';

@Component({
  selector: 'app-tax-list',
  templateUrl: './tax-list.component.html',
  styleUrls: ['./tax-list.component.css']
})
export class TaxListComponent implements OnInit {
  taxList: any = [];
  taxDataObj: any = {};
  updateTaxDataObj: any = {};
  @ViewChild('updatetaxForm') updatetaxForm:any;
  @ViewChild('newtaxForm') newtaxForm:any;

  constructor( private route: ActivatedRoute, 
    private apiService: ApiService , 
    private alertService: AlertService,) { }

  ngOnInit() {
    this.getTaxList();
  }

  addNewTax(){
    this.newtaxForm.submitted = false;
  }

  getTaxList(){
    if ($.fn.DataTable.isDataTable('#plan-table-list')){
      $('#plan-table-list').DataTable({"order": [],paging:false, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [2] }]}).destroy();
    };
    let URL = 'tax/taxes'
    
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.taxList = data.responseList;

          setTimeout(() => {
            const table: any = $('#plan-table-list');
            let dataTable = table.DataTable({"order": [], paging:true, "searching":true, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [2] }]});
          }, 1000);
        }else{
          this.taxList = [];
          const table: any = $('#plan-table-list');
          let dataTable = table.DataTable({"order": [],paging:true,searching:true, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [2] }]});
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
  }

  getTaxById(id){
    this.updatetaxForm.submitted = false;

    this.apiService.getAll('tax/getTaxById?taxId='+id).subscribe(
      data => {
        if(data.statusCode == 100){
          this.updateTaxDataObj = data.response;
        }else{
         
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
  }

  addTax(addTax){
    console.log(addTax);
    if (!addTax.valid) {
      return
    }
    this.apiService.create('tax/createTax', this.taxDataObj).subscribe(
      data => {
         if(data.statusCode == 100){
           $('#addTaxModal').modal('hide');
           this.alertService.sweetMessage('success','Success'); 
         this.getTaxList()
          // this.location.back();
            // this.userDatalist = data.user;
          }else{
            this.alertService.sweetMessage('error',data.responseMessage); 

          }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  updateTax(){
    console.log(this.updatetaxForm.valid);
    if (!this.updatetaxForm.valid) {
      return
    }
    this.apiService.update('tax/updateTax', this.updateTaxDataObj).subscribe(
      data => {
         if(data.statusCode == 100){
          $('#updateTaxModal').modal('hide');
          this.alertService.sweetMessage('success','Success');
         this.getTaxList();
          // this.location.back();
            // this.userDatalist = data.user;
          }else{
            this.alertService.sweetMessage('error',data.responseMessage); 

          }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }
}
