import { Component, OnInit ,ChangeDetectorRef,ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as _ from "lodash";
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'


@Component({
  selector: 'app-add-notitfication',
  templateUrl: './add-notitfication.component.html',
  styleUrls: ['./add-notitfication.component.css']
})
export class AddNotitficationComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");

comm_modes:any=[];
levels:any=[];
wait_intevals:any=[];
attempt:any=[];
totalEscalationLevel:any=[];
escalationLevels: any=[];
assetList:any[]=[];
assetFilter :any =[];
assetDataRecord:any=[];
customerList:any=[];  
assetArray:any=[];
dataAdapter: any = [];
data: any = [];
tierListData: any = [];
tierArray : any=[];
checkTreeArray: any = [];
disableTreeArray:any=[];
records: any = [];
totalNotificationAssets:number=0;
totalAssets:number=0;
planId:number=0;
notificationPlan: any = {"alarmAge":"1","name":"","escalationLevels":[],"assets":[],"client":{},"tiers":[],"alarmNPStateType":{"npStateTypeId":"1"},"normalNPStateType":{"npStateTypeId":"6"},"noScheduleNPStateType":{"npStateTypeId":"13"}};
new_escalation_list:any={};
source: any = {};
items:any = [];
time:number = 0;
alarmList:any=[];
normalList:any=[];
schedularList:any=[];
clientName : any = localStorage.getItem("clientName");

constructor(private router:Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService ){}

  ngOnInit() {
  	this.getNotificationSelectData();
  	this.getTierList();
  	this.getAssetList();
    this.getAlarmList();
    this.getNormalList();
    this.getSchedularList();
  this.route.params.subscribe(params => {
      if(params['id']){
      this.planId = params['id'];
    }
  });
  if(this.planId){
    this.getNotificationPlanId(this.planId);
  }
}


polulateItems(){
    var t = new Date();
    t.setHours(0);
    t.setMinutes(0);
    t.setSeconds(0);
  
  for (let entry of this.escalationLevels) {
    for (let i=0; i< entry.attempt;i++) {   
      t.setMinutes(t.getMinutes()+parseInt(entry.waitInterval))
      this.items.push({"communicationMode":entry.communicationMode,"time":(t.getHours()+":"+t.getMinutes()) });
    }
  }
}

/* Handle tier tree chacked unchecked Event */
CheckChange(event){
  if(event.args.checked == true){
    var exist = this.checkTreeArray.indexOf(event.args.element.id);
    if(exist == '-1'){
        this.checkTreeArray.push(event.args.element.id);    
      }    
  }else if(event.args.checked == false){
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if(exist != '-1'){
        this.checkTreeArray.splice(exist,1);  
      }
  }
  if(this.checkTreeArray.length > 0){
  let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"><span class="textRight">+' + this.checkTreeArray.length+' </span></div>';
          this.myDropDownButton.setContent(dropDownContent);   
  }else{
    let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
              this.myDropDownButton.setContent(dropDownContent);
  }
}
/* Create Tier array */
createTierArray(){
  Object.keys(this.checkTreeArray).forEach(key=> {
    if(this.checkTreeArray[key] != 0){
        this.tierArray.push({"tierId":this.checkTreeArray[key]});   
      }
    });     
  this.notificationPlan.tiers = this.tierArray  
};
/* check tree checkbox */
checkedCheckboxOnLoad(){
   Object.keys(this.checkTreeArray).forEach(key=> {
    let checkedItem = document.getElementById(this.checkTreeArray[key]);    
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem,true);        
  });  
}
treeItemDisable(){
  Object.keys(this.disableTreeArray).forEach(key=> {
    let checkedItem = document.getElementById(this.disableTreeArray[key]);    
        this.myTree.expandItem(checkedItem);
        this.myTree.disableItem(checkedItem);      
  }); 
} 

/* Drop Asset  */ 
onItemDrop(e: any) {
  var assetData = e.dragData;
  var index = this.assetList.findIndex(x => x.assetId==assetData.assetId);
  this.assetList.splice(index,1);
  this.assetDataRecord.push(assetData);
  this.assetArray.push({"assetId": assetData.assetId });
  this.totalNotificationAssets = this.totalNotificationAssets + 1;
  this.totalAssets = this.totalAssets - 1;
}

/* Remove Asset  */ 
removeAsset(assetDataR,index){
  this.assetDataRecord.splice(index, 1);
  this.assetArray.splice(index, 1);
  this.assetList.push(assetDataR)  
  this.totalNotificationAssets = this.totalNotificationAssets - 1;
  this.totalAssets = this.totalAssets + 1;
}

/* Add escalation in array */
addEscalation(){  
  	if(Object.keys(this.new_escalation_list).length >=4){
  		this.escalationLevels.push(this.new_escalation_list);
  		this.new_escalation_list = {};
		}
    this.items = [];
    this.polulateItems()
  }
/* Remove escalation in array */
removeEscalation(i){
	this.escalationLevels[i]._destroy = true;
  this.escalationLevels.splice(i,1);   
  this.items = [];
  this.polulateItems()  
}


/* Get assets list */
getAssetList(){
  this.apiService.getAll('asset/getUnassignedNPAssetByClient?clientId='+this.clientId+'&userId='+this.userId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.assetList = data.responseList;
          this.totalAssets = this.assetList.length;          
        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
   error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }

/* Get tier list */
getTierList(){
  this.apiService.getAll('tier/getAllTierByUser?clientId='+this.clientId+'&userId='+this.userId).subscribe(
      data => {
          if(data.statusCode == 100){
              this.tierListData = data.responseList; 
              this.data = this.tierListData;
               Object.keys(this.data).forEach(key=> {
                if(this.data[key]['flag'] == false){
                  this.disableTreeArray.push(this.data[key]['tierId']);                          
                }                
              });
              this.source = {
                  datatype: 'json',
                  datafields: [
                      { name: 'tierId' },
                      { name: 'parentTierId' },
                      { name: 'tierName' },                            
                  ],
                  id: 'tierId',
                  localdata: this.data
              };
              this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
              this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' },{ name: 'tierId', map: 'id'}]);
              var self = this;
               setTimeout(function(){
                 self.treeItemDisable();
                },1000);
               let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
              this.myDropDownButton.setContent(dropDownContent);
              }else{
              Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
                  }
              },
              error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");              
              }
          );
      }

/* Get getNotificationSelectData list */
getNotificationSelectData(){
	this.apiService.getAll('escalationLevel/getEscalationData').subscribe(
    data => {
        if(data.statusCode == 100){
            this.comm_modes = data.response.comm_modes;
            this.levels = data.response.levels;
            this.wait_intevals = data.response.wait_intevals;
            this.attempt = data.response.attempt;
        }else{
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
	            }
            },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
}
/* Get Alarm state list */
getAlarmList(){
  this.apiService.getAll('npStateType/getNpStateTypeBehlafOfAlarmState').subscribe(
   data => {
       if(data.statusCode == 100){
          this.alarmList = data.responseList;            
        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
   error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }

/* Get Normal state list */
getNormalList(){
  this.apiService.getAll('npStateType/getNpStateTypeBehlafOfNormalState').subscribe(
   data => {
       if(data.statusCode == 100){
          this.normalList = data.responseList;            
        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
   error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }
/* Get Event state list */
getSchedularList(){
  this.apiService.getAll('npStateType/getNpStateTypeBehlafOfNoScheduleState').subscribe(
   data => {
       if(data.statusCode == 100){
          this.schedularList = data.responseList;            
        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
   error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }

/* Add and update notification Plan */
onSubmit() {
	if(Object.keys(this.new_escalation_list).length >=4){
		this.escalationLevels.push(this.new_escalation_list);
		this.new_escalation_list = {};
	}
  this.createTierArray();
	this.notificationPlan.escalationLevels = this.escalationLevels;
	this.notificationPlan.assets = this.assetArray;
	this.notificationPlan.client = {clientId:this.clientId};
  /*console.log(this.notificationPlan);
  return false;*/
  if(this.planId){
	this.apiService.update('notification_plan/updateNotificationPlanEscalationLevel', this.notificationPlan ).subscribe(
    data => {
      if(data.statusCode == 100){
      	Swal({
	        position: 'top-end',
	        type: 'success',
	        title: 'Plan update successfully',
	        showConfirmButton: false,
	        timer: 1500
        })
        this.router.navigate(['/notification-management']);       
      }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
        }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      )
}else{
	this.apiService.create('notification_plan/createNotificationPlanEscalationLevel', this.notificationPlan).subscribe(
	  data => {
	      if(data.statusCode == 100){
	      	  Swal({
	            position: 'top-end',
	            type: 'success',
	            title: 'Notification Plan added successfully',
	            showConfirmButton: false,
	            timer: 1500
	          })
	          this.router.navigate(['/notification-management']);
	      }else{
	      	  Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })                  
	      }
	  },
	  error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
	    })
	}

}


/* Get plan detail by click on edit section */
getNotificationPlanId(planId){
  this.apiService.getAll('notification_plan/getNotificationPlan?notificationId='+this.planId).subscribe(
      data => {
            if(data.statusCode == 100){
              this.notificationPlan = data.response;
              if(this.notificationPlan.alarmNPStateType == null){
                this.notificationPlan.alarmNPStateType = {};
              }
              if(this.notificationPlan.normalNPStateType == null){
                this.notificationPlan.normalNPStateType = {};
              }
              if(this.notificationPlan.noScheduleNPStateType == null){
                this.notificationPlan.noScheduleNPStateType = {};
              }
                this.assetDataRecord = data.response.assets;
                this.escalationLevels = data.response.escalationLevels;
                this.polulateItems();
                Object.keys(this.notificationPlan.tiers).forEach(key=> {
                  this.checkTreeArray.push(this.notificationPlan.tiers[key]['tierId'].toString());   
                });
                var that = this;
                setTimeout(function(){
                  that.checkedCheckboxOnLoad();   
                },2000);

                this.totalNotificationAssets = (this.assetDataRecord?this.assetDataRecord.length:0);
          if(this.assetDataRecord){
              for(let assetsM of this.assetDataRecord) {
                 this.assetArray.push({"assetId": assetsM.assetId});
                var index = this.assetList.findIndex(x => x.assetId == assetsM.assetId);
                if(index != -1){
                  this.assetList.splice(index,1);  
                }              
              }    
            }                              
            }else{
                Swal({
                    position: 'top-end',
                    type: 'error',
                    title: data.responseMessage,
                    showConfirmButton: false,
                    timer: 1500
                  })
                  }
                },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  

}



}
