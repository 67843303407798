import { Component, OnInit ,ChangeDetectorRef,ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService } from '../../../../../services/index';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
import 'datatables.net';
declare var jQuery : any;
declare var $ : any;
declare var d3:any,moment:any;
import 'datatables.net-bs4';


@Component({
  selector: 'app-call-instruction',
  templateUrl: './call-instruction.component.html',
  styleUrls: ['./call-instruction.component.css']
})
export class CallInstructionComponent implements OnInit {

  @ViewChild('CallDetailsForm') CallDetailsForm: any;


  callInstructionData : any = {
  	"wffNotificationMsgId":'',
  	"calloutStartTimestamp":"",
  	"calloutEndTimestamp":"",
  	"contactName":"",
  	"contactPhoneNumber":"",
  	"wffCallDispositionId":"",
  	"dispositionNotes":"",
    "callIdentifier":"",
    "agentIdentifier":"",
    "notificationMsgId": ""
  }

  isShow       : any = localStorage.getItem("isTabShow")
  clientId     : any = localStorage.getItem("clientId");
  userId       : any = localStorage.getItem("userId");
  userData     : any = JSON.parse(localStorage.getItem("clientUser"));
  logedinId    : any = localStorage.getItem("userId");
  dataTable    : any ;
  Datalist     : any = [];
  EventDetials : any = [];
  disposition  : any = [];
  callID       : any;
  isFollowUp   : any = false;
  followUpData : any = JSON.parse(localStorage.getItem('FollowUpData'));

  calloutStartTimestamp : any;
  calloutEndTimestamp   : any;
  callLogDetails        : any;
  callLogId   : any = 0;
  duration    : any;
  contactName :any;

  contactPhoneNumber    : any;
  wffCallDispositionId  : any;
  dispositionNotes      : any;
  EmailCallToken        : any;

  rollUpdataList:any = [];
  startdateError = ''
  enddateError = ''
  clientName : any = localStorage.getItem("clientName");
  runTimeEventId:any = null;
  eventType:any = null;
  assignedContactListData:any = [];
  wffNotificationId:any = null
  HistoryList: any = [];
  
constructor(
  private router        : Router,
  private route         : ActivatedRoute,
  private apiService    : ApiService,
  private alertService  : AlertService,
  private chRef         : ChangeDetectorRef,
  private formatDateService: FormatDateService ) { }

  ngOnInit() {
    jQuery('.rst-date').datetimepicker({
      language: 'en',
      pickTime: true,
      pickDate: true,
      minDate: 0,
      inline: true,
      sideBySide: true
    });

    let user = JSON.parse(localStorage.getItem("clientUser"));

    // Check if user come from FOLLOWUP Data OR NOT
      if(this.followUpData){
        if(this.followUpData.status == 'nodata'){
          this.isFollowUp = false
        }else{
          this.isFollowUp = true;
        }
      }

    this.route.params.subscribe(params => {
     
      if(params['id'] && this.logedinId != null) {
        this.callID = params['id'];
       }

    });

    this.route
    .queryParams
    .subscribe(params => {
      
        // this.clientId = params['clientId'];
        this.runTimeEventId = params['runTimeEventId'];
        this.eventType = params['type'];
        this.wffNotificationId = params['wffNotificationId'];
    });


    this.EmailCallToken = localStorage.getItem("EmailCallToken");

    this.getCallInstructionDetails();
    this.getDisposition();
    this.getCallLogDetails();

    let id = localStorage.getItem("userId");
    this.callInstructionData.agentIdentifier = id;
    this.callInstructionData.callIdentifier  = this.callID;
    $(document).ready(function(){
        $(this).scrollTop(0);
    });
  }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
    return this.formatDateService.formatDate(startDate, gmtOffset);
  }
  // DateFormat(item.stopDate, '')



// // Function to get Roll Up Data
//   getRollUpData(){
//     // runTimeEvent/rollUpRunTimeEvents?clientId=5&userId=5&parentRunTimeEventId=73595&rcpId=756
//     this.apiService.getAll('runTimeEvent/rollUpRunTimeEvents?clientId='+this.clientId+'&userId='+this.userId+'&parentRunTimeEventId=73595&rcpId='+this.rcpId).subscribe(
//       data => {
//         if(data.statusCode == 100){
//           this.rollUpdataList = data.responseList ? data.responseList : [];
//         }else{
//           this.rollUpdataList = [];
//         }
//       },
//       error =>{
//           this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
//       }
//   );
// }




// Get all the Data for Asset , Event And Call Instruction User List
getCallInstructionDetails(){
  if(!this.logedinId){
    return;
  }
  var URL = '';
  if(this.EmailCallToken == 0 || this.EmailCallToken == null){
    URL = 'callCenterInstruction/getCallCenterInstructionDetail?wffNotificationMsgId='+this.callID
  }else {
    URL = 'callCenterInstruction/getCallCenterInstructionDetail?notificationMsgId='+this.callID
  }
  this.apiService.getAll(URL)
  .subscribe(
  data => {
    if(data.statusCode == 100){

    this.Datalist     = data.response;
    this.EventDetials = data.response.wffNotification;

    var option = {"order": [],"columnDefs": [{ "orderable": false, "targets": 6 }]}
    this.ClearAndCreateTable('asset_dataTable', option)
    this.ClearAndCreateTable('callInstruction_table', option)

    
  }else{

    this.Datalist = [];
    var option = {"order": [],"columnDefs": [{ "orderable": false, "targets": 6 }]}
    this.ClearAndCreateTable('asset_dataTable', option)
    this.ClearAndCreateTable('callInstruction_table', option)

  }
  },
  error =>
    { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
  )
}

  // Get List of Disposition Drop Down List
  getDisposition(){

    this.apiService.getAll('callCenterInstruction/getAllWffCallDisposition')
    .subscribe(
    data => {
      if(data.statusCode == 100){
        this.disposition     = data.responseList;
      }else
        { this.disposition = []; }
    },
    error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    )
  }


getCallLogDetails(){
  if(!this.logedinId){
    return;
  }
  var URL = '';
  if(this.EmailCallToken == 0 || this.EmailCallToken == null){
    // URL = 'callCenterInstruction/getWffNotificationMsgCallCenter?wffNotificationMsgId='+this.callID
    URL = 'callCenterInstruction/getCallLogByWffNotificationMsgId?'+ (this.runTimeEventId ? "runTimeEventId="+this.runTimeEventId :'wffNotificationMsgId='+this.callID)
  }else {
    // URL = 'callCenterInstruction/getWffNotificationMsgCallCenter?notificationMsgId='+this.callID
    URL = 'callCenterInstruction/getCallLogByWffNotificationMsgId?notificationMsgId='+this.callID
  }

    // 13516918
  this.apiService.getAll(URL)
  .subscribe(
  data => {
    if(data.statusCode == 100){

    this.callLogDetails  = data.responseList;
    this.callLogDetails.forEach((obj, i) => {
      obj.duration = this.formatDateService.dateDiffrence(obj.calloutStartTimestamp,obj.calloutEndTimestamp)
      // obj.duration = this.formatDateService.dateDiffrence(obj.calloutStartTimestamp,obj.calloutEndTimestamp)
    });

        if ( $.fn.DataTable.isDataTable('#CallLogTable') ) {
          $('#CallLogTable').DataTable().destroy();
        }

        this.chRef.detectChanges();
        const table: any = $('#CallLogTable');
        this.dataTable = table.DataTable();

      }else
        {
          this.callLogDetails = [];
          if ( $.fn.DataTable.isDataTable('#CallLogTable') ) {
            $('#CallLogTable').DataTable().destroy();
          }

          this.chRef.detectChanges();
          const table: any = $('#CallLogTable');
          this.dataTable = table.DataTable();
        }
    },
    error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    )

  }

// Function to set the call start Date if user click on Now Date
  setStartDateTime(){
    // this.calloutStartTimestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    let val = moment().format("YYYY-MM-DD HH:mm:ss")
    $('#startDate').val(val)
  }

// Function to set the call End Date if user click on Now Date
  setEndDateTime(){
    // this.calloutEndTimestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    let val = moment().format("YYYY-MM-DD HH:mm:ss")
    $('#endDate').val(val)
  }

  UserClick(event){
    console.log(event,"------------");
    this.contactName = event.userDTO.userName
    this.contactPhoneNumber = event.userDTO.phoneNumber

  }

  userClickEvent(user:any){
    console.log(user);
    this.contactName = user.userName
    this.contactPhoneNumber = user.phoneNumber
  
  }

// Function to sumbit the Call Detail form
  submitCallDetails() {
    console.log(this.CallDetailsForm.valid);
    this.startdateError = ''
    this.enddateError = ''
    let value = $('#startDate').val()
      this.calloutStartTimestamp = value
      console.log(value, this.calloutStartTimestamp);
      let value2 = $('#endDate').val()
      this.calloutEndTimestamp = value2
      console.log(value2, this.calloutEndTimestamp);
      

      if (!this.calloutStartTimestamp && !this.calloutEndTimestamp) {
        this.startdateError = 'Call Start required'
        this.enddateError = 'Call End required'
        return
      }
      if (!this.calloutStartTimestamp) {
        this.startdateError = 'Call Start required'
        return
      }
      if (!this.calloutStartTimestamp) {
        this.enddateError = 'Call End required'        
        return
      }
      let diff = moment(this.calloutEndTimestamp).diff(moment(this.calloutStartTimestamp))
      console.log(diff);
      if (diff<0) {
        this.startdateError = 'Please select valid date'
        return
      }
      this.CallDetailsForm.value.calloutStartTimestamp = this.calloutStartTimestamp;
      this.CallDetailsForm.value.calloutEndTimestamp = this.calloutEndTimestamp;
      console.log(this.CallDetailsForm.value);
      
      
    if (this.CallDetailsForm.valid) {

      

      // return
      if(this.callLogId == 0){

   
        let id = localStorage.getItem("userId");
        // this.callInstructionData.agentIdentifier = id;
        // this.callInstructionData.callIdentifier  = this.callID;
    
        var URL = '';
        console.log(this.EmailCallToken)
        if(this.EmailCallToken == 0 || this.EmailCallToken == null){
          // this.CallDetailsForm.value.wffNotificationMsgId = this.callID;
          if (this.runTimeEventId) {
            this.CallDetailsForm.value.runTimeEventId = this.runTimeEventId
          }else{
            this.CallDetailsForm.value.wffNotificationMsgId = this.callID;
          }
          this.CallDetailsForm.value.notificationMsgId    = null;
        }else {
          this.CallDetailsForm.value.notificationMsgId    = this.callID;
          this.CallDetailsForm.value.wffNotificationMsgId = null;
          URL = 'callCenterInstruction/getWffNotificationMsgCallCenter?notificationMsgId='+this.callID
        }
    
          // this.CallDetailsForm.value.wffNotificationMsgId = this.callID;
          this.CallDetailsForm.value.callIdentifier  = this.callID;
          // this.CallDetailsForm.value.agentIdentifier = id; // Give login user name
          
          // this.CallDetailsForm.value.agentIdentifier = this.userData.userName; // Give login user name
          this.CallDetailsForm.value.userId = this.userId; // Give login user name
          let DataObj:any = this.CallDetailsForm.value
          console.log(this.wffNotificationId);
          
          if (this.wffNotificationId) {
            DataObj.wffNotificationId = this.wffNotificationId
          }
    
          this.apiService.create('callCenterInstruction/createCallLog',DataObj)
          .subscribe(
          data => {
            if(data.statusCode == 100){
              this.getCallLogDetails();
              this.callInstructionData = {
                "wffNotificationMsgId":'',
                "calloutStartTimestamp":"",
                "calloutEndTimestamp":"",
                "contactName":"",
                "contactPhoneNumber":"",
                "wffCallDispositionId":"",
                "dispositionNotes":"",
                "callIdentifier":"",
                "agentIdentifier":""
              }
              this.callLogId = "";
              this.calloutStartTimestamp = "";
              this.calloutEndTimestamp   = "";
              this.contactName = "";
              this.contactPhoneNumber   = "";
              this.wffCallDispositionId = "";
              this.dispositionNotes = "";
              this.CallDetailsForm.submitted = false;
              this.alertService.sweetMessage('success',data.responseMessage);
            }else
            {
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
          )
    
      } else{
    
          this.CallDetailsForm.value.callLogId = this.callLogId;
          this.CallDetailsForm.value.userId    = this.userId;
          // this.CallDetailsForm.value.wffNotificationMsgId = this.callID;
          if(this.EmailCallToken == 0 || this.EmailCallToken == null){
            // this.CallDetailsForm.value.wffNotificationMsgId = this.callID;
            if (this.runTimeEventId) {
              this.CallDetailsForm.value.runTimeEventId = this.runTimeEventId
            }else{
              this.CallDetailsForm.value.wffNotificationMsgId = this.callID;
            }
            this.CallDetailsForm.value.notificationMsgId    = null//this.EmailCallToken;
          }else {
            this.CallDetailsForm.value.notificationMsgId    = this.EmailCallToken;//this.callID;
            this.CallDetailsForm.value.wffNotificationMsgId = null;
          }
          console.log(this.wffNotificationId);
          let DataObj:any = this.CallDetailsForm.value
          if (this.wffNotificationId) {
            DataObj.wffNotificationId = this.wffNotificationId
          }
          this.apiService.create('callCenterInstruction/updateCallLog',this.CallDetailsForm.value)
          .subscribe(
          data => {
            if(data.statusCode == 100){
              this.CallDetailsForm.submitted = false;
              this.getCallLogDetails();
              this.callInstructionData = {
                "wffNotificationMsgId":'',
                "calloutStartTimestamp":"",
                "calloutEndTimestamp":"",
                "contactName":"",
                "contactPhoneNumber":"",
                "wffCallDispositionId":"",
                "dispositionNotes":"",
                "callIdentifier":"",
                "agentIdentifier":""
              }
    
              this.callLogId = "";
              this.calloutStartTimestamp = "";
              this.calloutEndTimestamp   = "";
              this.contactName = "";
              this.contactPhoneNumber   = "";
              this.wffCallDispositionId = "";
              this.dispositionNotes = "";
    
              this.alertService.sweetMessage('success',data.responseMessage);
            }else
              { this.alertService.sweetMessage('error','Please Try Latter'); }
          },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
          )
      }
    }

}

  getCallLogById(iid){
    this.apiService.getAll('callCenterInstruction/getCalLogById?callLogId='+iid)
    .subscribe(
      data => {
        if(data.statusCode == 100){
          // this.callInstructionData = data.response;
          this.callLogId = data.response.callLogId;
          this.calloutStartTimestamp = moment(data.response.calloutStartTimestamp,"DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
          this.calloutEndTimestamp  = moment(data.response.calloutEndTimestamp,"DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
          this.contactName = data.response.contactName;
          this.contactPhoneNumber = data.response.contactNumber;
          this.wffCallDispositionId = data.response.wffCallDisposition.wffCallDepositionId;
          this.dispositionNotes = data.response.dispositionNotes;

        }else
          { this.alertService.sweetMessage('error','Please Try Latter'); }
      },
      error =>
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      )
  }


  ClearAndCreateTable(tableName,options){

    if ( $.fn.DataTable.isDataTable('#asset_dataTable') || $.fn.DataTable.isDataTable('#callInstruction_table') ) {
        $('.table').DataTable().destroy();
    }

      this.chRef.detectChanges();
      const table: any = $('#asset_dataTable');
      const table2: any = $('#callInstruction_table');
      this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 6 }]});

  }


  getAssignedContactsList(){
    this.apiService.getAll('asset/getAssetContact?assetId=10147418').subscribe(
      data => {
        if(data.statusCode == 100){
          this.assignedContactListData = data.responseList;
        }else{
          this.assignedContactListData =[];
         /* this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  getHistory(obj){
    this.HistoryList = []
    let URL = 'callCenterInstruction/getCallLogHistory?callLogId='+obj.callLogId
   

    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll(URL).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                 table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                 table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    // console.log(d, startDate,d1, moment(d1).format("MM-DD-YYYY HH:mm:ss"));
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
      // return this.formatDateService.formatDate(startDate, gmtOffset);
  }

}
