import { Component, OnInit } from '@angular/core';
import * as version from '../../../../../assets/version.json'

declare var moment:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  buildDate:any = ''

  constructor() { }

  ngOnInit() {
    this.buildDate = moment(version.version).format('YYYYMMDD')
  }

}
