import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { environment } from "../../../../../../environments/environment"

@Component({
  selector: 'app-admin-branding',
  templateUrl: './admin-branding.component.html',
  styleUrls: ['./admin-branding.component.css']
})
export class AdminBrandingComponent implements OnInit {

  @ViewChild("addClient") addClient:any;
  @ViewChild("clientContact") clientContact:any;
  @ViewChild("filePathName") filePathName: ElementRef;
  @ViewChild("filePathInnerName") filePathInnerName: ElementRef;
  @ViewChild("filePathBrandingName") filePathBrandingName: ElementRef;
  @ViewChild("filePathBrandingAppName") filePathBrandingAppName: ElementRef;
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);

  dataTable: any;
  contactData: any = [];
  loginPageContent:any = ""
  tabName : any = 'Client';
  filePath:any          = "assets/img/upload-img.png";
  filePathInner:any     = "assets/img/upload-img.png";
  filePathMobile:any    = "assets/img/upload-img.png";
  filePathBranding:any  = "assets/img/upload-img.png";
  filePathBrandingApp:any  = "assets/img/upload-img.png";
  defaultImage:any = 'assets/img/upload-img.png';
  client : any = {};

  imageName:any;
  imageNameInner:any;
  imageNameMobile:any;
  clientBrandLogoWeb:any;
  clientBrandLogoApp:any;

  branding: any = {};
  clientCode : any = '';
  permissionData:any=[];
  Logintooltip      : any = 'Login Page Logo';
  Innertooltip      : any = 'Admin inner page logo';
  BrandWebtooltip   : any = 'Admin Branding logo for Web';
  BrandApptooltip   : any = 'Admin Branding logo for App';
  clientName : any = localStorage.getItem("clientName");
  HistoryList: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef
    ) { }


  ngOnInit() {
    this.getAdminBranding()
  }


  SubmitBranding(){
    let obj = this.branding
    obj.parentOrgId = this.userId;
    let val = this.loginPageContent.replace('',"");
    obj.loginPageContent =  val //this.loginPageContent//.substring(1, 199);

    var adminDomain = $('#adminDomain').val();
    var adminCode = $('#adminCode').val();

    if(adminDomain == '') {
      $('#adminDomain_err').css('display', 'block');
      $('#adminDomain').addClass('is-invalid');
    } else {
      $('#adminDomain').removeClass('is-invalid');
      $('#adminDomain_err').css('display', 'none');
    }
    // show admin code error
    if(adminCode == '') {
      $('#adminCode').addClass('is-invalid');
      $('#adminCode_err').css('display', 'block');
    } else {
      $('#adminCode').removeClass('is-invalid');
      $('#adminCode_err').css('display', 'none');
    }
    if (adminDomain == '' || adminCode == '') {
      return;
    }

    this.apiService.update('ParentOrganization/updateAdminBranding', obj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.alertService.sweetMessage('success', 'Updated successfully' );
          // this.branding = {}
        } else {
          var adminDomain = $('#adminDomain').val();
          var adminCode = $('#adminCode').val();
          
          this.alertService.sweetMessage('error', data.responseMessage );

          if(adminDomain == '' && adminCode == '') {
            $('#adminDomain_err').css('display', 'block');
            $('#adminCode_err').css('display', 'block');
            return;
          } else {
            $('#adminDomain_err').css('display', 'none');
            $('#adminCode_err').css('display', 'none');
          }
        }
      },
     error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );


  }

  imageSave(section,imageName){

    const uploadData = new FormData();
    const domain = location.host;

    if(imageName){
      uploadData.append('file', imageName[0], imageName[0].name);
      this.apiService.create('uploadImage/uploadImage?section='+section+'&sectionId='+this.userId+'&domain='+domain, uploadData).subscribe(
        data => {
            if(data.statusCode == 100){
              setTimeout(()=>{
                this.imageName = null;
                this.imageNameInner = null;
                this.imageNameMobile = null;
                this.clientBrandLogoWeb = null;
                this.clientBrandLogoApp = null;
              },2000)
            }else{
                this.alertService.sweetMessage('error',data.responseMessage);
                  }
                },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

  }

  /**********************************************
    Image upload For Login Page Logo
  ************************************/
  onFileChanged(event) {

      const file = event.target.files;


      if ((file[0].size/1024)>500)
        { this.alertService.sweetMessage('error',"Size should be below 500 KB");  }
      else {

        if(file[0].type != 'image/png' && file[0].type != 'image/jpg' &&  file[0].type != 'image/jpeg'){
          this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG image are allowed");
          return;
        }


        var reader = new FileReader();
        reader.readAsDataURL(file[0]);

        reader.onload = (_event:any) => {
          var img = new Image();
          img.src = _event.target.result;

          img.onload = () => {
            if (img.width < 500 && img.height < 500) {
              this.filePath = reader.result;
              this.imageName = file;
              this.imageSave(11,this.imageName);
            } else {
              this.alertService.sweetMessage('error',"Image should not be greater than 500 X 500");
            }
          };

        }
      }

    }

    /**********************************************
      Image upload For Cleint Inner page Logo
    ************************************/
        onFileChangedInner(event) {
        const file = event.target.files;

          console.log(file[0].type);

          if ((file[0].size/1024)>500)
            { this.alertService.sweetMessage('error',"Size should be below 500 KB"); }
          else {

            if(file[0].type != 'image/png' && file[0].type != 'image/jpg' &&  file[0].type != 'image/jpeg'){
              this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG image are allowed");
              return;
            }

            var reader = new FileReader();
            reader.readAsDataURL(file[0]);

            reader.onload = (_event:any) => {
              var img = new Image();
              img.src = _event.target.result;
              img.onload = () => {
                if (img.width <= 300 && img.height <= 300) {
                  this.filePathInner = reader.result;
                  this.imageNameInner = file;
                  this.imageSave(12,this.imageNameInner);
                } else {
                  this.alertService.sweetMessage('error',"Image should not be greater than 300 X 300");
                }
              };
            }
          }
        }

        /**********************************************
          Image upload For Cleint Branding Logo For WEB
        ****************************************************/

        onFileChangedClientBrandWeb (event) {
          const file = event.target.files;
          // console.log(file[0],(file[0].size/1024),file[0].height,file);
          // console.log(file[0].size/1024);
          console.log(file[0].type);

          if(file[0].type != 'image/gif'){
            this.alertService.sweetMessage('error',"Only Gif image are allowed as loader");
            return;
          }

          if ((file[0].size/1024)>500) {
            Swal(
              { position: 'top-end', type: 'error',
                title: 'Size should be below 500 KB',
                showConfirmButton: false, timer: 2500
              })
          }else {

            var reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = (_event:any) => {
              var img = new Image();
              img.src = _event.target.result;

              img.onload = () => {
                if (img.width <= 200 && img.height <= 200) {
                  this.filePathBranding = reader.result;
                  this.clientBrandLogoWeb  = file;
                  this.imageSave(13,this.clientBrandLogoWeb);
                }
                else {
                  this.alertService.sweetMessage('error',"Image should not be greater than 200 X 200");
                }
              };
            }
          }
        }
        /**********************************************
          Image upload For Cleint Branding Logo For App
        ************************************************************/

        onFileChangedClientBrandApp (event) {
          const file = event.target.files;
          // console.log(file[0],(file[0].size/1024),file[0].height,file);
          // console.log(file[0].size/1024);

          if(file[0].type != 'image/gif'){
            this.alertService.sweetMessage('error',"Only Gif image are allowed as loader");
            return;
          }

          if ((file[0].size/1024)>500) {
            Swal({
              position: 'top-end',
              type: 'error',
              title: 'Size should be below 500 KB',
              showConfirmButton: false,
              timer: 2500
            })
          } else {

            var reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = (_event:any) => {
              var img = new Image();
              img.src = _event.target.result;

              img.onload = () => {
                if (img.width <= 170 && img.height <= 170) {
                  this.filePathBrandingApp = reader.result;
                  this.clientBrandLogoApp  = file;
                  this.imageSave(14,this.clientBrandLogoApp);
                }
                else {
                  this.alertService.sweetMessage('error',"Image should not be greater than 170 X 170");
                }
              };
            }
          }
        }


  /* Get Client by Id*/
  getAdminBranding() {
    // this.apiService.getAll('ParentOrganization/getParentOrganizationById?parentOrgId='+this.userId).subscribe(
      this.apiService.getAll('ParentOrganization/getParentOrganizationById').subscribe(
    data => {
      if (data.statusCode == 100) {
        this.client = data.response[0];
        let obj = this.client;
        this.branding = {
          parentOrgclientDomain : obj.parentOrgclientDomain,
          parentOrgCode: obj.parentOrgCode,
        }

        this.loginPageContent = obj.loginPageContent ? obj.loginPageContent : ""
       

        // Admin Login Page Logo
          this.filePath = obj.appLogoPath ? this.apiService.imageBasePath+obj.appLogoPath : "assets/img/upload-img.png";
         
        // Admin Web Loader img
          this.filePathBranding = obj.loaderImg ? this.apiService.imageBasePath+obj.loaderImg : "assets/img/upload-img.png";

        // Admin inner Page Logo
          this.filePathInner = obj.parentOrgInnerLogoPath ? this.apiService.imageBasePath+obj.parentOrgInnerLogoPath : "assets/img/upload-img.png";

        // Admin App Loader img
          this.filePathBrandingApp = obj.loaderImgMobile ? this.apiService.imageBasePath+obj.loaderImgMobile : "assets/img/upload-img.png";

        // Set Loader image again when user change the loader image
          localStorage.setItem("loaderImg", obj.loaderImg);
          // this.apiService.clinetLoader();

        // display none loader coz above method set and enable the loader
          $("#loaderImgIcon").css("display","none");

        } else {
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }
  removeInputImage(type: any) {
    //  clear file data after operation 
    if (type == 11) {
      this.filePathName.nativeElement.value = "";
      this.Logintooltip = '';
    } else if (type == 12) {
      this.filePathInnerName.nativeElement.value = "";
      this.Innertooltip = '';
    } else if (type == 13) {
      this.filePathBrandingName.nativeElement.value = "";
      this.BrandWebtooltip = '';
    } else if (type == 14) {
      this.filePathBrandingAppName.nativeElement.value = "";
      this.BrandApptooltip = '';
    }
  }

  // function for deleting image of branding 
  removeBrandingImage(section, sectionName) {
    Swal({
      title: 'Are you sure?',
      text: `You want to remove ${sectionName}.`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        const domain = location.host;
        this.apiService.delete("uploadImage/removeImage?section=" + section + "&sectionId=" + this.userId+'&domain='+domain, '').subscribe(
          data => {
            if (data.statusCode == 100) {
              this.alertService.sweetMessage('success', `${sectionName} removed successfully`);
              if (section == 11) {
                this.filePath = 'assets/img/upload-img.png'
              } else if (section == 12) {
                this.filePathInner = 'assets/img/upload-img.png'
              } else if (section == 13) {
                this.filePathBranding = 'assets/img/upload-img.png'
              } else if (section == 14) {
                this.filePathBrandingApp = 'assets/img/upload-img.png'
              }
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }


getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('ParentOrganization/getParentOrganizationHistoryById?domain=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}

DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}
}
