import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
declare var d3:any,moment:any;
import * as _ from "lodash";

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
fileTypeList:any=[];
groupName :any={};
roleArray:any=[];
clientName : any = localStorage.getItem("clientName");
clientUser: any = JSON.parse(localStorage.getItem("clientUser"));
loginUserType : any = this.clientUser ?  this.clientUser.userType : '';//to show breadcrumb conditionly

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService ) { }


  ngOnInit() {
  	this.getFileTypeList();
  }
  
/* Function for getting All Role List */
  getFileTypeList(){
    this.apiService.getAll('fileType/getFileList').subscribe(
      data => {
        if(data.statusCode == 100){
          this.fileTypeList = data.responseList; 
          /*for(var i=0 ; i < this.fileTypeList.length; i++) {
            this.roleArray.push(this.fileTypeList[i].fileTypeId);            
          }
          for(var i=0 ; i < this.fileTypeList.length; i++) {	
            var perID = this.fileTypeList[i].fileTypeId;
            $('.inner-label #'+perID).prop('checked', true);  
            var groupName = $('.inner-label #'+perID).attr('name') 
            this.groupName = groupName;
            this.SelectgroupName();            
          }*/
                  
        }else{
          // Swal({
          //   position: 'top-end',
          //   type: 'error',
          //   title: data.responseMessage,
          //   showConfirmButton: false,
          //   timer: 1500
          // })
        }
      },
      error => {
        Swal({
          position: 'top-end',
          type: 'error',
          title: 'Error in network',
          showConfirmButton: false,
          timer: 1500
        })
      }
    );
  }

/* Add user */            
submit(){
  var fileTypeList = { "fileList":this.fileTypeList};
  console.log(this.fileTypeList);  
  this.apiService.update('fileType/updateFileType', fileTypeList).subscribe(
  data => {
      if(data.statusCode == 100){        
          Swal({
            position: 'top-end',
            type: 'success',
            title: 'File Type updated Successfully',
            showConfirmButton: false,
            timer: 1500
          })                  
      }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })                 
          }
        },
  error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

/* Select Section wise permission & checked inputs */ 
  SelectgroupName(){  	
    var  total_input = $('.inner-label input[name='+ this.groupName +']').length;
    var checked_input = $('.inner-label input[name='+ this.groupName +']:checked').length;
    if(checked_input == total_input){
      $('.outer-label input[name='+ this.groupName +']').prop('checked', true);
    }
  }

  /* single Check permssion */
  checkinnerTick(event,permData1,permData){
  	this.groupName = permData;
    if(event.target.checked == true){
      this.SelectgroupName();
      this.roleArray.push(permData1);      
    }else{
      var index = _.indexOf(this.roleArray, permData1, 0)
      this.roleArray.splice(index,1) ;      
    }
  }
}
