import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, CommonServicesService } from '../../../../../../services/index';
import * as $ from 'jquery'
import * as _ from "lodash";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-roles-add',
  templateUrl: './client-roles-add.component.html',
  styleUrls: ['./client-roles-add.component.css']
})
export class ClientRolesAddComponent implements OnInit, OnDestroy {

  clientId : any = (localStorage.getItem("clientId")?JSON.parse(localStorage.getItem("clientId")):null);
  userId   : any = localStorage.getItem("userId");

  permissionData : any = [];
  clientDetailed : any = localStorage.getItem('clientDetailed');

  groupName : any = {};
  clientList: any = [];
  roleArray : any = [];
  roleFilter: any = [];
  roleData  : any = {"roleName":"","client":{"clientId":''},"permissions":[],"isCustomerRole" : false };
  roleId    : number = 0;

  AllroleArray : any = [];
  roleDatalist : any = [];
  clientRoleID : any = localStorage.getItem('clientRoleId');

  message: any;
  subscription: Subscription;
  roleClientId = null
  clientdetailId :any;


  @Output() getRoleListEvent = new EventEmitter<String>();
  clientName : any = localStorage.getItem("clientName");

  constructor(
    private router  : Router,
    private route   : ActivatedRoute,
    private apiService  : ApiService ,
    private alertService: AlertService,
    private CommonServicesService: CommonServicesService
  ) {

    // subscribe to header component sendClientId to get Client Id
    this.subscription = this.CommonServicesService.getClientSectionRoleId()
      .subscribe(
        message => {
          // console.log(message);
          this.roleId = message.id;
          this.clientRoleID = message.id;
          this.getRoleById();
        }
      );

      // subscribe to header component sendClientId to get Client Id
      this.subscription = this.CommonServicesService.clearAddRolePageData()
        .subscribe(
          message => {
            // console.log('innn', message);
            this.clientRoleID = 0;
            this.roleData  = {"roleName":"","client":{"clientId":''},"permissions":[],"isCustomerRole" : false };
            this.getAllPermissionList();
            this.getAllRoleList();
          }
        );

   }

   ngOnDestroy() {
     // clear the header filter when user leave the page
     this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
     // unsubscribe to ensure no memory leaks
     this.subscription.unsubscribe();
   }

    ngOnInit() {
      this.route.params.subscribe(params => {
        if(params['id']){
          this.clientdetailId = params['id'];
        }
      });
      this.getAllPermissionList();
      this.getAllRoleList();
      // this.getClientList();
      // this.roleId = 5;
      $(".nav-link").attr('href','javascript:void(0)')
      this.route.params.subscribe(params => {
        if(params['id']){
          this.roleId = params['id'];
          this.roleClientId = params['id'];
        }
      });
      // console.log(this.roleId);
      if(this.roleId){
        this.roleData.client.clientId = this.roleId;
        // this.getRoleById();
      }
  }

  /* Function for getting All Permission List */
    getAllPermissionList(){
      this.apiService.getAll('permission/getAllPermission').subscribe(
        data => {
          if(data.statusCode == 100){
            this.permissionData = data.responseList;
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

    /* Function for getting All Role List */
      getAllRoleList(){
        var URL = 'role/getRoleListByClient'
        if((this.clientdetailId != "") && (this.clientdetailId !=0) && (this.clientdetailId != null)){
          var clientId   = '?&userType=client&clientId='+this.clientdetailId;
          URL = URL.concat(clientId);
        }else{
          var amciUser   = '?&userType=amci';
          URL = URL.concat(amciUser);
        }
        this.apiService.getAll(URL).subscribe(
          data => {
            if(data.statusCode == 100)
              { this.roleDatalist = data.responseList; }
            else
              { this.alertService.sweetMessage('error',data.responseMessage); }
          },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
      // }
    }

  /* checkall Check Section */
    checkAll(event,permdata){
      if(event.target.checked == true){
        this.roleArray=[];
        $('.uroles-type-select input').prop('checked', true);
        for(var i=0 ; i < this.permissionData.length; i++) {
          for(var j=0 ; j < this.permissionData[i].permissions.length; j++) {
            this.roleArray.push(this.permissionData[i].permissions[j].permissionId);
          }
        }
      }else {
        $('.uroles-type-select input').prop('checked', false);
        this.roleArray=[];
      }
    }


    /* checked permssion by Section */
    checkTick(event,permData){
      this.groupName = permData.groupName;
      if(event.target.checked == true){
        for(var i=0 ; i < permData.permissions.length; i++) {
          this.roleArray.push(permData.permissions[i].permissionId);
          $('.inner-label input[name='+ this.groupName +']').prop('checked', true);
          this.SelectAll();
        }
        this.roleArray = _.uniq(this.roleArray);
      }else{
        for(var i=0 ; i < permData.permissions.length; i++) {
          $('.inner-label input[name='+ this.groupName +']').prop('checked', false);
          $('.select-all input').prop('checked', false);
          var index = _.indexOf(this.roleArray, permData.permissions[i].permissionId, 0)
          // console.log(index);
          
          this.roleArray.splice(index,1) ;
        }
        // console.log(this.roleArray);
        
      }
    }

  /* single Check permssion */
    checkinnerTick(event,permData1,permData){
      this.groupName = permData;
      if(event.target.checked == true){
        this.SelectgroupName();
        this.roleArray.push(permData1);
      }else{
        var index = _.indexOf(this.roleArray, permData1, 0)
        this.roleArray.splice(index,1) ;
        $('.outer-label input[name='+ this.groupName +']').prop('checked', false);
        $('.select-all input').prop('checked', false);
      }
      this.SelectAll()
    }

  /* Select All  permmsion & checked inputs */
    SelectAll(){
      var  total_in = $('.outer-label input').length;
      var checked_in = $('.outer-label input[type="checkbox"]:checked').length;
      console.log(checked_in == total_in, checked_in , total_in);
      
      console.log("checked_in == total_in", checked_in == total_in, checked_in , total_in);
      if(checked_in == total_in){
        $('.select-all input').prop('checked', true);
      }
    }
  /* Select Section wise permission & checked inputs */
    SelectgroupName(){
      var  total_input = $('.inner-label input[name='+ this.groupName +']').length;
      var checked_input = $('.inner-label input[name='+ this.groupName +']:checked').length;
      if(checked_input == total_input){
        $('.outer-label input[name='+ this.groupName +']').prop('checked', true);
      }
    }


    /* Get Role by Id*/
    getRoleById(){
      $(document).find(".roleList").removeClass("inner-shadow");
        $("#roleBox_"+this.roleId).addClass("inner-shadow");
        $('.uroles-type-select input').prop('checked', false);
        this.roleArray = [];
      this.apiService.getAll('role/getRole?roleId='+this.roleId).subscribe(
       data => {
         if(data.statusCode == 100){
            this.roleData = data.response;
            for(var i=0 ; i < this.roleData.permissions.length; i++) {
              this.roleArray.push(this.roleData.permissions[i].permissionId);
            }
            for(var i=0 ; i < this.roleData.permissions.length; i++) {
              var perID = this.roleData.permissions[i].permissionId;
              $('.inner-label #'+perID).prop('checked', true);
              var groupName = $('.inner-label #'+perID).attr('name')
              this.groupName = groupName;
              this.SelectgroupName();
            }
            setTimeout(() => {
              this.SelectAll();
            }, 1500);
          }else
            { this.alertService.sweetMessage('error',data.responseMessage); }
        },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
          );
        }


  /* Create and Update Role */
    SubmitRole(){
      // console.log("------------------------------");
      
      this.roleData.client.clientId = this.roleClientId;

      // console.log(this.roleData,this.roleData.client.clientId);
      if(this.clientRoleID == null || this.clientRoleID == undefined || this.clientRoleID == 0 ){
        this.AllroleArray = [];
        for(var i=0 ; i < this.roleArray.length; i++) {
          var AllroleArray = {'permissionId':this.roleArray[i]};
          this.AllroleArray.push(AllroleArray);
        }
        
        this.roleData.permissions =  this.AllroleArray;
        this.roleData.creator     =  this.userId;

       this.apiService.create('role/createRole', this.roleData).subscribe(
         data => {
          $('.select-all input').prop('checked', false);
            if(data.statusCode == 100){
              this.alertService.sweetMessage('success','Role Added Successful',);
              this.getRoleListEvent.emit();
              this.router.navigate(['/clientRoles',this.clientDetailed]);
            }else
              { this.alertService.sweetMessage('error',data.responseMessage); }
          },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");  $('.select-all input').prop('checked', false);}
          );
      }else{
        this.AllroleArray = [];
          for(var i=0 ; i < this.roleArray.length; i++) {
              var AllroleArray = {'permissionId':this.roleArray[i]};
              this.AllroleArray.push(AllroleArray);
            }

          this.roleData.permissions =  this.AllroleArray;
          this.roleData.creator     =  this.userId;

          this.apiService.update('role/updateRole', this.roleData).subscribe(
            data => {
              $('.select-all input').prop('checked', false);
              if(data.statusCode == 100){
                this.alertService.sweetMessage('success','Role Update Successful');
                localStorage.setItem('clientRoleId', '0')
                this.clientRoleID = 0;
                this.getRoleListEvent.emit();
                this.roleData = {"roleName":"","client":{"clientId":''},"permissions":[]};
                this.router.navigate(['/clientRoles',this.clientDetailed]);
              }else
                { this.alertService.sweetMessage('error',data.responseMessage); }
            },
            error =>
              { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");  $('.select-all input').prop('checked', false);}
          );
        }
    }

    replacePermissionName(permissionName){
      if (permissionName) {
        let str = permissionName;
        let name = str.replace(/-/g," ")
        return name;
      }
    }

    changeCustomerRole(){
      setTimeout(() => {
        var  total_in = $('.outer-label input').length;
        var checked_in = $('.outer-label input[type="checkbox"]:checked').length;
        console.log(checked_in == total_in, checked_in , total_in);
        
        console.log("checked_in == total_in", checked_in == total_in, checked_in , total_in);
        if(checked_in == total_in){
          $('.select-all input').prop('checked', true);
        }else{
          $('.select-all input').prop('checked', false);
        }
      }, 1000);
    }
}
