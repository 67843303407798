import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService, CommonServicesService}  from '../../../../../../services/index';
declare var $ : any;
declare var jQuery : any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import flatpickr from "flatpickr";

import { Location } from '@angular/common';

@Component({
  selector: 'app-new-default-plan',
  templateUrl: './new-default-plan.component.html',
  styleUrls: ['./new-default-plan.component.css']
})
export class NewDefaultPlanComponent implements OnInit {

  @ViewChild('addDefaultForm') addDefaultForm: any;

  PlanDataObj:any = {
    planType:{
      planTypeId:''
    }
  }
  planTypeList:any = [];
  planId:any = null;

  constructor(private route: ActivatedRoute, 
    private apiService: ApiService , 
    private alertService: AlertService, 
    private chRef: ChangeDetectorRef,
    private location: Location,
    private formatDateService: FormatDateService,
    private CommonServicesService: CommonServicesService,) { }

  ngOnInit() {
    this.getPlanTypeList();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.planId = params['id'];
      }
    });
    if (this.planId) {
      this.getPlanById();
    }
  }

  getPlanById(){
    this.apiService.getAll('billingRange/getById?billingRangeId='+this.planId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.PlanDataObj = data.response;
          this.PlanDataObj.upToRange = this.PlanDataObj.upToRange/1000
        }else{
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
  }

  addDefaultPlan(){
    console.log(this.PlanDataObj, '---------------------------');
    console.log(this.PlanDataObj.perUnitPrice, parseFloat(this.PlanDataObj.perUnitPrice));
    this.PlanDataObj.price = parseFloat(this.PlanDataObj.perUnitPrice)*(this.PlanDataObj.upToRange*1000)
    console.log(this.PlanDataObj, this.addDefaultForm.valid);
    // for (let index = 0; index < this.planTypeList.length; index++) {
    //   const element = this.planTypeList[index];
    //   if (element.planTypeId == this.PlanDataObj.planType.planTypeId) {
    //     this.PlanDataObj.planType.name = element.name;
    //     break
    //   }
      
    // }
    if (!this.addDefaultForm.valid) {
      return
    }
    this.PlanDataObj.upToRange = this.PlanDataObj.upToRange*1000
    if (this.planId) {
      this.PlanDataObj.billingRangeId = this.planId

      this.apiService.update('billingRange/update', this.PlanDataObj).subscribe(
        data => {
           if(data.statusCode == 100){
            
            this.location.back();
            }else{
              this.alertService.sweetMessage('error',data.responseMessage); 
  
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    } else {
      this.apiService.create('billingRange/create', this.PlanDataObj).subscribe(
        data => {
           if(data.statusCode == 100){
           
            this.location.back();
              // this.userDatalist = data.user;
            }else{
              this.alertService.sweetMessage('error',data.responseMessage); 
  
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

  getPlanTypeList(){
    
    this.apiService.getAll('planType/list').subscribe(
      data => {
        if(data.statusCode == 100){
          this.planTypeList = data.responseList;   
         
        }else{
          this.planTypeList = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      ); 
  }

}
