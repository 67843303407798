import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  templateUrl: './roles.component.html'
})

export class RolesComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
clientdetailId: any = 0;
roleDatalist: any = [];
dataTable: any;
clientName : any = localStorage.getItem("clientName");
  breadcrumbObj: any;

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }


ngOnInit() {
  this.route.params.subscribe(params => {
      if(params['id']){
      this.clientdetailId = params['id'];
    }

  });
  this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
  if(this.clientdetailId){
   this.getRoleListByClientList();
  }  
}

/* Get Role By Client list */
getRoleListByClientList(){
   this.apiService.getAll('Client/getRoleListByClient?clientId='+this.clientdetailId).subscribe(
   data => {
       if(data.statusCode == 100){
         this.roleDatalist = data.responseList;
         this.chRef.detectChanges(); 
           const table: any = $('table');
          this.dataTable = table.DataTable();
        }else{
          this.roleDatalist = [];
          this.chRef.detectChanges(); 
           const table: any = $('table');
          this.dataTable = table.DataTable();
       
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
             
        );
}




}
