import { Component, OnInit } from '@angular/core';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { ActivatedRoute } from '@angular/router';
import { AlertService, ApiService } from 'src/app/services';
@Component({
  selector: 'app-plan-management',
  templateUrl: './plan-management.component.html',
  styleUrls: ['./plan-management.component.css']
})
export class PlanManagementComponent implements OnInit {

  planList:any = []
  siteList: any[] = [];
  constructor( private route: ActivatedRoute, 
    private apiService: ApiService , 
    private alertService: AlertService,) { }

  ngOnInit() {
    this.getplanList();
  }

  getplanList(){
    if ($.fn.DataTable.isDataTable('#plan-table-list')){
      $('#plan-table-list').DataTable({"order": [],paging:false, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [9,10] }]}).destroy();
    };
    let URL = 'billingPlan/list'
    
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.planList = data.responseList;

          setTimeout(() => {
            const table: any = $('#plan-table-list');
            let dataTable = table.DataTable({"order": [], paging:true, "searching":true, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [4,5] }]});
          }, 1000);
        }else{
          this.planList = [];
          const table: any = $('#plan-table-list');
          let dataTable = table.DataTable({"order": [],paging:true,searching:true, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [4,5] }]});
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
  }

  getSiteList(billingPlanId){
    this.siteList = []
    this.apiService.getAll('billingPlan/getAllSiteByPlanId?billingPlanId='+billingPlanId).subscribe(
      data => {
        $("#SiteListModel").modal("show")
        if(data.statusCode == 100){
          this.siteList = data.responseList;

        }else{
          this.siteList = [];
         
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
  }
}
