import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, AlertService, CommonServicesService } from 'src/app/services';
import { Location } from '@angular/common';
declare var moment:any;
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  clientName: string;
  invoiceData:any = {}
  invoiceId: any = null;
  constructor(private route: ActivatedRoute, private location: Location,
    private apiService: ApiService , 
    private alertService: AlertService, private CommonServicesService: CommonServicesService) { }

  ngOnInit() {
    this.clientName = localStorage.getItem("clientName");
    // this.getinvoicedata();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.invoiceId = params['id'];
      }
    });
    if (this.invoiceId) {
      this.getinvoicedata();
    }
  }

  getinvoicedata(){

    this.apiService.getAll('invoice/invoiceById?invoiceId='+this.invoiceId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.invoiceData = data.response;
        }else{
          this.invoiceData = {}
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
/*     this.invoiceData =  [
      {
          "invoiceId": 5,
          "billing": {
              "billingId": 1025,
              "client": {
                  "clientId": 104,
                  "description": "Asset Care Services"
              },
              "fromDate": "2020-04-01",
              "toDate": "2020-04-30",
              "billingDetail": [
                  {
                      "billingDetailId": 1025,
                      "site": {
                          "siteId": 690,
                          "siteName": "Site1"
                      },
                      "asset": {
                          "assetId": 26099,
                          "name": "Generator"
                      },
                      "planType": {
                          "planTypeId": 1,
                          "name": "Email"
                      },
                      "quantity": 48,
                      "price": 24,
                      "taxPercent": 0,
                      "tax": 0,
                      "totalAmount": 24
                  },
                  {
                      "billingDetailId": 1026,
                      "site": {
                          "siteId": 690,
                          "siteName": "Site1"
                      },
                      "asset": {
                          "assetId": 26099,
                          "name": "Generator"
                      },
                      "planType": {
                          "planTypeId": 2,
                          "name": "SMS"
                      },
                      "quantity": 48,
                      "price": 48,
                      "taxPercent": 0,
                      "tax": 0,
                      "totalAmount": 48
                  }
              ]
          },
          "invoiceNumber": "10420200709",
          "invoiceDate": "2020-07-09 14:33:58",
          "amount": 72,
          "tax": 0,
          "totalAmount": 72,
          "referenceNumber": "123",
          "referenceDate": "2020-07-06 00:00:00",
          "paymentMode": {
              "paymentModeId": 2,
              "name": "Check "
          }
      }
  ] */
  }

  goBack(){
    this.location.back();
  }

  getDateFormate(date){
    return moment(date, "YYYY-MM-DD").format('MMMM YYYY');
  }
}
