import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService, CommonServicesService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import flatpickr from "flatpickr";
import { Subscription } from 'rxjs';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  user: any = JSON.parse(localStorage.getItem("clientUser"));
  clientName : any = localStorage.getItem("clientName");
  invoiceList: any = [];
  dataTable: any = {};

  constructor(
  private router:Router, 
  private apiService: ApiService , 
  private alertService: AlertService , 
  private chRef: ChangeDetectorRef,
  private CommonServicesService: CommonServicesService) {
  
  }

ngOnInit() {
  this.getInvoiceList();
}

ngOnDestroy() {
    
  
}
DateFormat(startDate) {
  var d = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS");
  if (startDate) {
    var formattedDate = moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  } else {
    return "";
  }
}


/* Get invoice list */
getInvoiceList(){

  var URL = 'invoice/invoiceList';


  this.invoiceList = []

  this.apiService.getAll(URL).subscribe(
    data => {
       if(data.statusCode == 100){
          this.invoiceList = data.responseList ? data.responseList : [] ;
          

          if ($.fn.DataTable.isDataTable('#data_table_invoice')){
            $('#data_table_invoice').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#data_table_invoice');
          this.dataTable = table.DataTable({"order": [], "search": { regex: false, smart: false },"columnDefs": [/* { "orderable": false, "targets": [0,5,6,7,8] } */]});


        }else{
          this.invoiceList = [];
         

          if ($.fn.DataTable.isDataTable('#data_table_invoice')){
            $('#data_table_invoice').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#data_table_invoice');
          this.dataTable = table.DataTable({"order": [],"columnDefs": [/* { "orderable": false, "targets": [0,5,6,7,8] } */]});

        }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
		}
  );
}

  
}
