import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-client-role',
  templateUrl: './client-role.component.html',
  styleUrls: ['./client-role.component.css']
})
export class ClientRoleComponent implements OnInit {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  roleDatalist : any = [];
  dataTable: any;
  roleId :number = 1;
  search:any='';
  clientName : any = localStorage.getItem("clientName");

  constructor(private router:Router, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.getAllRoleList();
  }



  seachRecord(){
    this.getAllRoleList();
  }
  /* Reset Search Record */
  resetSearch(){
    this.search = "";
    this.getAllRoleList();
  }
    /* Function for getting All Role List */
    getAllRoleList(){

      var URL = 'role/getAllRoleList';
      // var URL = 'role/getAllRole?userId='+this.userId;
      if(this.search !=''){
        var searchString   = '&search='+this.search;
        var URL = URL.concat(searchString);
      }
      if(this.customerId != null && this.customerId !=''){
        var customerString     = '&customerId='+this.customerId.toString();
        var URL = URL.concat(customerString);
      }
      if(this.tierId != null && this.tierId !=''){
        var tierString     = '&tierId='+this.tierId.toString();
        var URL = URL.concat(tierString);
      }

      // this.apiService.getAll(URL).subscribe(
      this.apiService.getAll("role/getAllRoleByType?userType=Client").subscribe(
        data => {
          if(data.statusCode == 100){
            this.roleDatalist = data.responseList;
            this.chRef.detectChanges();
             const table: any = $('table');
            this.dataTable = table.DataTable();
          }else{
            this.roleDatalist = [];
            this.chRef.detectChanges();
              const table: any = $('table');
              this.dataTable = table.DataTable();
           /* Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })*/
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

    DeleteRole(roleId){
      Swal({
        title: 'Are you sure?',
        text: "You want to delete this Role.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
         this.apiService.delete('role/deleteRole?roleId='+roleId, '').subscribe(
         data => {
             if(data.statusCode == 100){
               Swal({
                    position: 'top-end',
                    type: 'success',
                    title: 'Role Delete Successfully',
                    showConfirmButton: false,
                    timer: 1500
                  }) ;

                   this.getAllRoleList();

              }else{
                Swal({
                    position: 'top-end',
                    type: 'error',
                    title: data.responseMessage,
                    showConfirmButton: false,
                    timer: 1500
                  })
               }
            },
           error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                  }
              );
      }
      })
    }

}
