import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable ,  Subject ,  BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2'

@Injectable()
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;
    public languageData: Subject<string> = new BehaviorSubject<any>({});
    public campaignId: Subject<any> = new BehaviorSubject<any>(0);
    public componentName: Subject<any> = new BehaviorSubject<any>('');

    constructor(private router: Router) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                }
            }
        });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message });
        this.removeMessage();
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message });
        this.removeMessage();
    }
    
    warning(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'warning', text: message });
        this.removeMessage();
    }
    
    info(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'info', text: message });
        this.removeMessage();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    
    setLanguageData(data:any) {
        this.languageData.next(data);
    }
    
    setCampaignId(id:any) {
        this.campaignId.next(id);
    }
    setComponentName(name:any) {
        this.componentName.next(name);
    }
    
    removeMessage(){
        setTimeout(() => {
            this.subject.next({});
        }, 6000);
    }

    sweetMessage(type,message,time?:number){
         Swal({
                position: 'top-end',
                type: type,
                title: message,
                showConfirmButton: false,
                timer: time ? time : 3500
              }) ;
    }

    errorMessage(error, status, message,time?:number){
        let errorMessageRes = message;
        console.log(status, "--status")
        var isOnline = navigator.onLine;
        if(!isOnline) {
            errorMessageRes = "Check your network connection";
        }
        if (status == 400) {
            console.log(status, "Bad Request");
            errorMessageRes = "Bad Request";
        } else if(status == 502){
            console.log(status, "Proxy Error");
            errorMessageRes = "Proxy Error";
        } else if(status == 500 && error.message == "Read timed out"){
            console.log(status, "Read timed out");
            errorMessageRes = "Read timed out";
        } else if(status == 500){
            console.log(status, "Internal server error");
            errorMessageRes = "Internal server error";
        } else if(status == 404){
            console.log(status, "Request Not Found");
            errorMessageRes = "Request Not Found";
        } else if(status == 408){
            console.log(status, "Request timed out. Please retry.");
            errorMessageRes = "Request timed out. Please retry.";
        } else if(status == 504){
            console.log(status, "Gateway Timeout");
            errorMessageRes = "Gateway Timeout";
        } else if(status == 401){
            console.log(status, "Invalid Session");
            errorMessageRes = "Invalid Session";
        }


        Swal({
               position: 'top-end',
               type: 'error',
               title: errorMessageRes,
               showConfirmButton: false,
               timer: time ? time : 3500
             }) ;
   }
    
    getClientCode(){
      // return this.clientCode  = localStorage.getItem("clientCode")
      return localStorage.getItem("clientCode")
    }
}
