import { Component, OnInit } from '@angular/core';
// import { EmployeeService } from '../../employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'
import { ApiService } from 'src/app/services';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.css']
})
export class AssetComponent implements OnInit {

assetId:number=0;
assetDetail:any={};

constructor( private apiService: ApiService , private route: ActivatedRoute) { }

 ngOnInit() {
 	 this.route.params.subscribe(params => {
      this.assetId = params['assetId'];
  });

  this.getAssesDetail();
 }

 /* Get client list on load*/
getAssesDetail(){
  this.apiService.getLoginDatawithoutToken('classicSatAlarm/getAssetInfoByAsset?assetId='+this.assetId).subscribe(
      data => {
          if(data.statusCode == 100){
              this.assetDetail = data.response;

              }else{

                  }
              },
              error => {

              }
          );
      }


    }
