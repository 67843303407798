import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService} from '../../../../../services/index';
// import * as $ from 'jquery'
import * as _ from "lodash";

import 'datatables.net';
declare var jQuery : any;
declare var $ : any;
import 'datatables.net-bs4';

@Component({
  selector: 'app-customer-migrate',
  templateUrl: './customer-migrate.component.html',
  styleUrls: ['./customer-migrate.component.css']
})
export class CustomerMigrateComponent implements OnInit {

  userData : any = null ;
  userhead : any ={};
  userbody : any ={};
  dataTable : any;
  clientName : any = localStorage.getItem("clientName");

  constructor(private router:Router, private apiService: ApiService ,private alertService: AlertService , private chRef: ChangeDetectorRef) { }


  ngOnInit() {
    if(this.apiService.customermigration == null){
    	this.router.navigate(['/migration-tool']);
    }
    else {
      this.userData =  this.apiService.customermigration;
      this.userhead = this.userData[0];
      this.userData.splice(0, 1);
      this.userbody = this.userData

      if ( $.fn.DataTable.isDataTable('#data_table') ) {
        $('#data_table').DataTable().destroy();
      }
      this.chRef.detectChanges();
       const table: any = $('#data_table');

      this.dataTable = table.DataTable({
        "order": []
      });
      setTimeout(() => {
        $(function () {
          $('[data-toggle="tooltip100"]').tooltip()
        })
      }, 1000);
      // this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 6 }]});

    }
  }

}
