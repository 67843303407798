import { Component, OnInit } from '@angular/core';
// import { EmployeeService } from '../../employee.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2'
import { ApiService } from 'src/app/services';


@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

clientList:any=[];
companyId:number=0;
clientId:number=0;
companyRequest:any={"clientRequest":{"clientId":""},"company":{"companyId":""}};
constructor( private apiService: ApiService , private route: ActivatedRoute) { }

 ngOnInit() {
 	 this.route.params.subscribe(params => {
      this.companyRequest.company.companyId = params['companyId'];
  });

if(this.companyRequest.company.companyId){
	this.getClientByCompanyId(this.companyRequest.company.companyId);
}
  this.getClientList();
 }

 /* Get client list on load*/
getClientList(){
  this.apiService.getLoginDatawithoutToken('classicSatAlarm/getAllClient').subscribe(
      data => {
          if(data.statusCode == 100){
              this.clientList = data.responseList;

              }else{

                  }
              },
              error => {

              }
          );
      }

getClientByCompanyId(compId){
  this.apiService.getLoginDatawithoutToken('classicSatAlarm/getClientByCompany?companyId='+compId).subscribe(
      data => {
          if(data.statusCode == 100){
              this.clientId = data.response.clientId;
              }else{

                  }
              },
              error => {

              }
          );
      }
    saveClient(){
		this.apiService.create('classicSatAlarm/mapCompanyToClient ', this.companyRequest).subscribe(
		    data => {
		        if(data.statusCode == 100){
		            Swal({
		              position: 'top-end',
		              type: 'success',
		              title: 'Success',
		              showConfirmButton: false,
		              timer: 1500
		            })
		        }else{
		            Swal({
		                position: 'top-end',
		                type: 'error',
		                title: data.responseMessage,
		                showConfirmButton: false,
		                timer: 1500
		              })
		            }
		          },
		    error => {
		        Swal({
		            position: 'top-end',
		            type: 'error',
		            title: 'Error in network.',
		            showConfirmButton: false,
		            timer: 1500
		          })
		        }
		      );
		}
    }
