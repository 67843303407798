import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
//import * as $ from 'jquery';
//import * as $AB from 'jquery';
declare var d3:any;
declare var $: any, moment: any;
//declare var modal:any;
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'

@Component({
  templateUrl: './tiers.component.html', 
  styleUrls: ['./tiers.component.css'] 
})

export class clientTierComponent implements OnInit {

@ViewChild('myTree') myTree: jqxTreeComponent;
@ViewChild('tierAddForm') tierAddForm: any;
@ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
dataAdapter: any = [];
source: any = {};
SiteFilter: any = {};
data: any = [];
tierListData: any = [];
records: any = [];
userFilter :any =[];
tierId : number = 0;
parentTierId : number = 0;
selectTierId : number = 0;
parentTier : any = {};
allSitesData : any = [];
treeData:any={};
treeJSON:any=[];
tree:any={};
panTimer:any={};
translateCoords:any={};
translateX:any={};
translateY:any={};
scaleX:any={};
scaleY:any={};
scale:any={};
links:any={};
nodePaths:any={};
nodesExit:any={};
domNode:any={};
x:any={};
y:any={};
dragListener:any={};
dragStarted:any={};
relCoords:any={};
parentLink:any={};
nodes:any={};
node:any={};
nodeEnter:any={};
siteList:any[]=[];
totalTierSite:number=0;
totalSite:number=0;
siteDataRecord:any=[];
licenseList:any=[];
siteArray:any=[];
clientdetailId: any = 0;
addTier:any={"tierName": "","license":{"licenseId":""},"parentTier":{"tierId":this.tierId},"client":{"clientId":this.clientdetailId}};
unsavedChanges = false;
// ClientName: any = localStorage.getItem("ClientName");
ClientName : any = localStorage.getItem("ClientInfoName");
errorText = '';
    breadcrumbObj: any;
    isSiteChange: boolean;
    HistoryList: any = [];

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }

ngOnInit(){

 this.route.params.subscribe(params => {
      if(params['id']){
      this.clientdetailId = params['id'];
    }

  });
  if(this.clientdetailId){
   this.getTreeList();
  this.getAllSitesList();
  this.getTierList(); 
  }
  this.addTier.client.clientId = this.clientdetailId
  this.route
  .queryParams
  .subscribe(params => {
      this.breadcrumbObj = params['source'];
      // this.urlCustomerId = params.cus_id;
  })
}

/* Drop Site  */ 
onItemDrop(e: any) {
  var siteData = e.dragData;
  var index = this.siteList.findIndex(x => x.siteId==siteData.siteId);
  this.siteList.splice(index,1);
  this.siteDataRecord.push(siteData);
  this.siteArray.push({"siteId": siteData.siteId });
  this.totalTierSite = this.totalTierSite + 1;
  this.totalSite = this.totalSite - 1;
  this.isSiteChange = true;
}

/* Remove Site  */ 
removeSite(siteDataR,index){


 Swal({
    title: 'Are you sure?',
    text: "You want to Remove this Site.",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Remove it!'
  }).then((result) => {
    if (result.value) {
  
      this.apiService.getAll('tier/removeSite?siteId='+siteDataR.siteId).subscribe(
        data => {
            if(data.statusCode == 100){

                let Findindex = this.siteDataRecord.findIndex(function(obj){
                    return obj.siteId == siteDataR.siteId
                })

                this.siteDataRecord.splice(Findindex, 1);
                this.siteArray.splice(Findindex, 1);
                this.siteList.push(siteDataR)
                this.getAllSitesList();
                this.totalTierSite = this.totalTierSite - 1;
                this.totalSite = this.totalSite + 1;
            }else{
            }
        },
        error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
    }
    })
  
}


/* Get All sites list */
getAllSitesList(){
  this.apiService.getAll('site/getUnassignedSiteByClient?clientId='+this.clientdetailId).subscribe(
  data => {
      if(data.statusCode == 100){
          this.allSitesData = data.responseList; 
          this.siteList = this.allSitesData;
          this.totalSite = this.allSitesData.length;    
          }else{
          /*Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })*/
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");               
          }
      );
    }
/* Get All sites list */
getAllLicenseList(){
  this.apiService.getAll('license/getLicenceDropDownList?clientId='+this.clientdetailId).subscribe(
  data => {
      if(data.statusCode == 100){
          this.licenseList = data.responseList;     
          }else{
        /*  Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })*/
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");               
          }
      );
    }
/* Show tier label in dropdown on get by IdApi */
showLableInTierOnLoad(){
if(this.parentTier){
  if(this.parentTier.tierId){
    this.parentTierId = this.parentTier.tierId;
   let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + this.parentTier.tierName + '</div>';
   this.myDropDownButton.setContent(dropDownContent);
  }else{
      console.log("=============================", 'id 0');
      this.parentTierId = 0;
      for (let index = 0; index < this.tierListData.length; index++) {
        const element = this.tierListData[index];
        if (element.tierId == this.parentTierId) {
            let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + element.tierName + '</div>';
            this.myDropDownButton.setContent(dropDownContent);
        }
        
    }
    // let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
    // this.myDropDownButton.setContent(dropDownContent);
    }
}
     
}
/* Show tier label in dropdown on tree select */
myTreeOnSelect(event: any){
  this.parentTierId = event.args.element.id;
    let item = this.myTree.getItem(event.args.element);
    let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + item.label + '</div>';
    this.myDropDownButton.setContent(dropDownContent);
};

/* Get tier list */
getTierList(){
    // tier/getAllTierByClient?clientId='+this.clientdetailId+'&userId='+this.userId
  this.apiService.getAll('tier/getAllTierByClient?clientId='+this.clientdetailId+'&userId='+this.userId).subscribe(
    // this.apiService.getAll('tier/getAllTierByClient?clientId='+this.clientdetailId+'&userId='+this.clientdetailId).subscribe(
      data => {
          if(data.statusCode == 100){
              this.tierListData = data.responseList; 
              this.data = this.tierListData
            //   this.source = {
            //       datatype: 'json',
            //       datafields: [
            //           { name: 'tierId' },
            //           { name: 'parentTierId' },
            //           { name: 'tierName' },                            
            //       ],
            //       id: 'tierId',
            //       localdata: this.data
            //   };
            //   this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
            //   this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' },{ name: 'tierId', map: 'id'}]);

            
            if (this.tierListData.length == 0) {
                // this.data = {
                //     tierId: 0,
                //     tierName: this.treeData.name,
                //     level: 0,
                //     parentTierId: null
                // }
                let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + this.treeData.name + '</div>';
                this.myDropDownButton.setContent(dropDownContent);
            }
            this.source = {
                datatype: 'json',
                datafields: [
                    { name: 'tierId' },
                    { name: 'parentTierId' },
                    { name: 'tierName' },                            
                ],
                id: 'tierId',
                localdata: this.data
            };
            this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
            this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' },{ name: 'tierId', map: 'id'}]);
              }else{
              /*Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })*/
                  }
              },
              error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");               
              }
          );
      }

/* Get All sites list */
getTierSitesList(tierId){
  this.tierId = tierId;
  if(this.tierId != 0 /* && this.selectTierId != this.tierId */){
    this.selectTierId = this.tierId;
  this.apiService.getAll('tier/getTier?clientId='+this.clientdetailId+'&tierId='+this.selectTierId).subscribe(
  data => {
      if(data.statusCode == 100){
        if(data.response.parentTier){
          this.parentTier = data.response.parentTier;            
      }else{
          this.parentTier = {tierId:0};
          this.parentTierId = 0;
          for (let index = 0; index < this.tierListData.length; index++) {
              const element = this.tierListData[index];
              if (element.tierId == this.parentTierId) {
                  let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + element.tierName + '</div>';
                  this.myDropDownButton.setContent(dropDownContent);
              }
              
          }
           }   
          this.addTier = data.response;
          if(data.response.license == null){
            this.addTier.license = {"licenseId":""};
          }       
          this.siteDataRecord = data.response.sites;
          this.totalTierSite = this.siteDataRecord.length;
          this.showLableInTierOnLoad();          
        }else{
          /*Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })*/
              }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");               
          }
      );
    }
  } 
/* Get All sites list */
getTreeList(){    
//   this.apiService.getAll('tier/tierHierarchyByClientTier?clientId='+this.clientdetailId+'&userId='+this.userId).subscribe(
  this.apiService.getAll('tier/tierHierarchyByClientTier?clientId='+this.clientdetailId).subscribe(

      data => {
          if(data.statusCode == 100 || data.statusCode == 103){
            $( "svg" ).remove();
            
            
            let obj = {
                "tierId": 0,
                "flag": false,
                "parentTierId": 0,
                "name": this.ClientName
            }
            this.treeData = data.statusCode == 103 ? {} : data.response;

            
            // Calculate total nodes, max label length
            var totalNodes = 0;
            var tierData = this;
            var maxLabelLength = 0;
            // variables for drag/drop
            var selectedNode = null;
            var draggingNode = null;
            // panning variables
            var panSpeed = 200;
            var panBoundary = 20; // Within 20px from edges will pan when dragging.
            // Misc. variables
            var i = 0;
            var duration = 750;
            var root;

            // size of the diagram
            var viewerWidth = $(document).width();
            var viewerHeight = ($(document).height())-200;
            var tree = d3.layout.tree()
                .size([viewerHeight, viewerWidth]);
            // define a d3 diagonal projection for use by the node paths later on.
            var diagonal = d3.svg.diagonal()
                .projection(function(d) {
                    return [d.y, d.x];
                });
    // A recursive helper function for performing some setup by walking through all nodes

    var visit = function(parent, visitFn, childrenFn) {
        if (!parent) return;
        visitFn(parent);
        var children = childrenFn(parent);
        if (children) {
            var count = children.length;
            for (var i = 0; i < count; i++) {
                visit(children[i], visitFn, childrenFn);
            }
        }
    }

    // Call visit function to establish maxLabelLength
    visit(this.treeData, function(d) {
        totalNodes++;
        if(d.name){
          maxLabelLength = Math.max(d.name.length, maxLabelLength);  
        }else{
          maxLabelLength = 20;
        }        
    }, function(d) {
        return d.children && d.children.length > 0 ? d.children : null;
    });


    // sort the tree according to the node names

    var sortTree = function() {
        tree.sort(function(a, b) {
          if(a.name && b.name){
            return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1;
        }else{
              return b.name;
        } 

        
        });
    }
    // Sort the tree initially incase the JSON isn't in a sorted order.
    sortTree();

    var mainw = 960,
    mainh = 500;
    
    var contextMenu:any = function() {
    var height,
        width, 
        margin = 0.1, // fraction of width
        items = [], 
        rescale = false, 
        style = {
            'rect': {
                'mouseout': {
                    'fill': 'rgb(70, 130, 180)',
                    'stroke': 'rgb(162, 194, 220)',
                    'stroke-width': '1px'
                },
                'mouseover': {
                    'fill': 'rgb(41,106,160)'
                }
            },
            'text': {
                'fill': 'white',
                'font-size': '13'
            }
        };
        
    
    var menu = function(x, y, z,name) {
        d3.select('.context-menu').remove();
        scaleItems(z);
      
        // Draw the menu
        d3.select('svg')
            .append('g').attr('class', 'context-menu')
            .selectAll('tmp')
            .data(items).enter()
            .append('g').attr('class', 'menu-entry').attr('tierID', z).attr('name', name)
            .style({'cursor': 'pointer'})
            .on('mouseover', function(){ 
                d3.select(this).select('rect').style(style.rect.mouseover) })
            .on('mouseout', function(){ 
                d3.select(this).select('rect').style(style.rect.mouseout) });
        
            d3.selectAll('.menu-entry')
                .append('rect')
                .attr('x', x)
                .attr('y', function(d, i){ return y + (i * height) * 1.2; })
                .attr('width', width * 1.5)
                .attr('height', height * 1.2)
                .style(style.rect.mouseout);
            
            d3.selectAll('.menu-entry')
                .append('text')
                .text(function(d){ return d; })
                .attr('x', x)
                .attr('y', function(d, i){ return y + (i * height) * 1.2; })
                .attr('dy', height - margin + 1)
                .attr('dx', margin)
                .style(style.text);
                  

        // Other interactions
        d3.select('body')
            .on('click', function() {
                d3.select('.context-menu').remove();
            });
        $('.context-menu').on('click', '.menu-entry', function(d) {
          var text = $(this).text();
          
          if(text == "Add Tier"){
            var tID = $(this).attr('tierID');
            var name = $(this).attr('name');
            tierData.getTierList();
            tierData.siteDataRecord = [];
            tierData.addTier.tierId = 0;
            tierData.addTier.tierName = "";
            tierData.addTier.isCallCenterActive = false;
            tierData.addTier.isSmsActive = false;
            tierData.addTier.isTewApprovalRequired = false;
            tierData.tierId = 0;   
            tierData.parentTier.tierId = tID;  
            tierData.addTier.level = 0;  
            tierData.addTier.license.licenseId  = null; 
            tierData.parentTier.tierName = name; 
            tierData.records = []; 
            setTimeout(() => {
                tierData.siteDataRecord = [];
                tierData.addTier.tierId = 0;
                tierData.addTier.tierName = "";
                tierData.addTier.isCallCenterActive = false;
                tierData.addTier.isSmsActive = false;
                tierData.addTier.isTewApprovalRequired = false;
                tierData.tierId = 0;   
                tierData.parentTier.tierId = tID;  
                tierData.addTier.level = 0;  
                tierData.addTier.license.licenseId  = null; 
                tierData.parentTier.tierName = name; 
                // tierData.records = []; 
            }, 1500);
            tierData.showLableInTierOnLoad();
            tierData.getAllLicenseList();
            tierData.tierAddForm.submitted = false;
             $('#addTier').modal('show');
          }else if(text == "Update Tier"){
            var tID = $(this).attr('tierID');
            tierData.getTierList();
            tierData.tierAddForm.submitted = false
            tierData.getAllLicenseList();
            $('#addTier').modal('show');            
          }else if(text == "History"){
            var tID = $(this).attr('tierID');
            tierData.getHistory(tID)          
          }else{
            var tID = $(this).attr('tierID');
            tierData.deleteTier(tID);
          }
          
          //click();
          
         // $(".node").trigger("click");
          //console.log(d);
        });
    }
     
    menu["items"] = function(e) {
        if (!arguments.length) return items;
        for (var i in arguments) items.push(arguments[i]);
        rescale = true;
        return menu;
    }

    // Automatically set width, height, and margin;
    var scaleItems = function(tierId) {
        if (rescale) {
            d3.select('svg').selectAll('tmp')
                .data(items).enter()
                .append('text')
                .text(function(d){ return d; })
                .style(style.text)
                .attr('x', -1000)
                .attr('y', -1000)
                .attr('class', 'tmp');
            var z = d3.selectAll('.tmp')[0]
                      .map(function(x){ return x.getBBox(); });
            width = d3.max(z.map(function(x){ return x.width; }));
            margin = margin * width;
            width =  width + 2 * margin;
            // height = d3.max(z.map(function(x){ return x.height + margin / 2; }));
            height = d3.max(z.map(function(x){ return x.height + margin * 1.5; }));
            
            // cleanup
            d3.selectAll('.tmp').remove();
            rescale = false;
        }
    }
    return menu;
} 
    var menu = contextMenu().items('Add Tier','Update Tier','Delete Tier','History');
    
    var PerentmenuItem = contextMenu()

    PerentmenuItem = PerentmenuItem.items('Add Tier');

    var pan = function(domNode, direction) {
        var speed = panSpeed;
        if (this.panTimer) {
            clearTimeout(this.panTimer);
            this.translateCoords = d3.transform(svgGroup.attr("transform"));
            if (direction == 'left' || direction == 'right') {
                this.translateX = direction == 'left' ? this.translateCoords.translate[0] + speed : this.translateCoords.translate[0] - speed;
                this.translateY = this.translateCoords.translate[1];
            } else if (direction == 'up' || direction == 'down') {
                this.translateX = this.translateCoords.translate[0];
                this.translateY = direction == 'up' ? this.translateCoords.translate[1] + speed : this.translateCoords.translate[1] - speed;
            }
            this.scaleX = this.translateCoords.scale[0];
            this.scaleY = this.translateCoords.scale[1];
            this.scale = zoomListener.scale();
            svgGroup.transition().attr("transform", "translate(" + this.translateX + "," + this.translateY + ")scale(" + this.scale + ")");
            d3.select(domNode).select('g.node').attr("transform", "translate(" + this.translateX + "," + this.translateY + ")");
            zoomListener.scale(zoomListener.scale());
            zoomListener.translate([this.translateX, this.translateY]);
            this.panTimer = setTimeout(function() {
                pan(domNode, direction);
            }, 50);
        }
    }

    // Define the zoom function for the zoomable tree

    var zoom  = function() {
        svgGroup.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
    }


    // define the zoomListener which calls the zoom function on the "zoom" event constrained within the scaleExtents
    var zoomListener = d3.behavior.zoom().scaleExtent([0.1, 3]).on("zoom", zoom);

    var initiateDrag = function(d, domNode,nodes,dragStarted) {
        draggingNode = d;
        d3.select(domNode).select('.ghostCircle').attr('pointer-events', 'none');
        d3.selectAll('.ghostCircle').attr('class', 'ghostCircle show');
        d3.select(domNode).attr('class', 'node activeDrag');

        svgGroup.selectAll("g.node").sort(function(a, b) { // select the parent and sort the path's
            if (a.id != draggingNode.id) return 1; // a is not the hovered element, send "a" to the back
            else return -1; // a is the hovered element, bring "a" to the front
        });
        // if nodes has children, remove the links and nodes
        
        //console.log(self.nodes);
        if (nodes.length > 1) {
            // remove link paths
            let links = tree.links(nodes);
            var nodePaths = svgGroup.selectAll("path.link")
                .data(links, function(d) {
                    return d.target.id;
                }).remove();
            // remove child nodes
          var  nodesExit = svgGroup.selectAll("g.node")
                .data(nodes, function(d) {
                    return d.id;
                }).filter(function(d, i) {
                    if (d.id == draggingNode.id) {
                        return false;
                    }
                    return true;
                }).remove();
        }

        // remove parent link
        var parentLink = tree.links(tree.nodes(draggingNode.parent));
        svgGroup.selectAll('path.link').filter(function(d, i) {
            if (d.target.id == draggingNode.id) {
                return true;
            }
            return false;
        }).remove();
        dragStarted = null;
    }

    // define the baseSvg, attaching a class for styling and the zoomListener
    var baseSvg = d3.select("#tree").append("svg")
        .attr("width", viewerWidth)
        .attr("height", viewerHeight)
        .attr("class", "overlay")
        .call(zoomListener)
        .on("contextmenu", function (d, i) {
          d3.event.preventDefault();
        });

    // Define the drag listeners for drag/drop behaviour of nodes.
    var dragListener = d3.behavior.drag()
        .on("dragstart", function(d) {
            if (d == root) {
                return;
            }
            this.dragStarted = true;
            let nodes = tree.nodes(d);
            this.nodes = nodes;
            d3.event.sourceEvent.stopPropagation();
            // it's important that we suppress the mouseover event on the node being dragged. Otherwise it will absorb the mouseover event and the underlying node will not detect it d3.select(this).attr('pointer-events', 'none');
        })
        .on("drag", function(d) {
            if (d == root) {
                return;
            }
            if (this.dragStarted) {
               var domNode = this;
                initiateDrag(d, domNode,this.nodes,this.dragStarted);
            }

            // get coords of mouseEvent relative to svg container to allow for panning
            this.relCoords = d3.mouse($('svg').get(0));
            if (this.relCoords[0] < panBoundary) {
                this.panTimer = true;
                pan(this, 'left');
            } else if (this.relCoords[0] > ($('svg').width() - panBoundary)) {
                this.panTimer = true;
                pan(this, 'right');
            } else if (this.relCoords[1] < panBoundary) {
                this.panTimer = true;
                pan(this, 'up');
            } else if (this.relCoords[1] > ($('svg').height() - panBoundary)) {
                this.panTimer = true;
                pan(this, 'down');
            } else {
                try {
                    clearTimeout(this.panTimer);
                } catch (e) {

                }
            }

            d.x0 += d3.event.dy;
            d.y0 += d3.event.dx;
            var node = d3.select(this);
            node.attr("transform", "translate(" + d.y0 + "," + d.x0 + ")");
            updateTempConnector();
        }).on("dragend", function(d) {
            if (d == root) {
                return;
            }
            this.domNode = this;

            let api1 = tierData

            // console.log(d, selectedNode);
            let innerselectedNode = selectedNode

            if (selectedNode) {
                
                Swal({
                    title: 'Are you sure?',
                    text: "You want to change the tier structure.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Discard',
                    confirmButtonText: 'Save Changes'
                }).then((result) => {
                if (result.value) {
                    // console.log(d, innerselectedNode , draggingNode);
                    let tierObjData = d
                    // // delete tierObj.parent.children
                    // tierObj.parentTierId = innerselectedNode.tierId;
                   
                    // api1.apiService.create('tier/updateTierLicense', tierObj).subscribe(
                    //     data => {
                    //         if(data.statusCode == 100){
                    //             api1.resetTierData();
                    //             //$('#addTier').modal('hide');
                    //         }else{
                    //             Swal({
                    //                 position: 'top-end',
                    //                 type: 'error',
                    //                 title: data.responseMessage,
                    //                 showConfirmButton: false,
                    //                 timer: 1500
                    //                 })                 
                    //         }
                    //     },
                    //     error => {
                    //         Swal({
                    //             position: 'top-end',
                    //             type: 'error',
                    //             title: 'Error in network',
                    //             showConfirmButton: false,
                    //             timer: 1500
                    //         })
                    //     }
                    // );


            
                if (innerselectedNode) {
                    // now remove the element from the parent, and insert it into the new elements children
                    // tierData.UpdateParent(tierObjData.tierId,tierObjData.name, innerselectedNode.tierId);

                    let tierObj2 = {
                        tierId : tierObjData.tierId,
                        "parentTier": {
                            "tierId": innerselectedNode.tierId
                        },
                        // tierName:name
                    }
                    // console.log(tierId, name,parenttierId);
                    // let tierObj = d
                                    // delete tierObj.parent.children
                    // tierObj2.parentTierId = innerselectedNode.tierId;
                    tierData.apiService.update('tier/updateTierParent', tierObj2).subscribe(
                        data => {
                            if(data.statusCode == 100){
                                tierData.resetTierData();
                                //$('#addTier').modal('hide');
                                var index = draggingNode.parent.children.indexOf(draggingNode);
                                if (index > -1) {
                                    draggingNode.parent.children.splice(index, 1);
                                }
                                if (typeof innerselectedNode.children !== 'undefined' || typeof innerselectedNode._children !== 'undefined') {
                                    if (typeof innerselectedNode.children !== 'undefined') {
                                        innerselectedNode.children.push(draggingNode);
                                    } else {
                                        innerselectedNode._children.push(draggingNode);
                                    }
                                } else {
                                    innerselectedNode.children = [];
                                    innerselectedNode.children.push(draggingNode);
                                }
                            }else{
                                Swal({
                                    position: 'top-end',
                                    type: 'error',
                                    title: data.responseMessage,
                                    showConfirmButton: false,
                                    timer: 1500
                                    })                 
                            }
                        },
                        error => {
                            console.log(error);
                            endDrag(this.domNode);
                            
                            Swal({
                                position: 'top-end',
                                type: 'error',
                                title: 'Error in network',
                                showConfirmButton: false,
                                timer: 3500
                            })
                        }
                    );

                    
                    // Make sure that the node being added to is expanded so user can see added node is correctly moved
                    expand(innerselectedNode);
                    sortTree();
                    console.log(this.domNode)
                    endDrag(this.domNode);
                } else {
                    endDrag(this.domNode);
                }
                } else {
                    endDrag(this.domNode);
                }
            })
            } else {
                endDrag(this.domNode);
            }

        });

    var endDrag = function(domNode) {

        // console.log(domNode);
        

        selectedNode = null;
        d3.selectAll('.ghostCircle').attr('class', 'ghostCircle');
        d3.select(domNode).attr('class', 'node');
        // now restore the mouseover event or we won't be able to drag a 2nd time
        d3.select(domNode).select('.ghostCircle').attr('pointer-events', '');
        updateTempConnector();
        if (draggingNode !== null) {
            update(root);
            centerNode(draggingNode);
            draggingNode = null;
        }
    }

    // Helper functions for collapsing and expanding nodes.

    var collapse = function (d) {
        if (d.children) {
            d._children = d.children;
            d._children.forEach(collapse);
            d.children = null;
        }
    }

    var expand = function(d) {
        if (d._children) {
            d.children = d._children;
            d.children.forEach(expand);
            d._children = null;
        }
    }

    var overCircle = function(d) {
        selectedNode = d;
        updateTempConnector();
    };
    var outCircle = function(d) {
        selectedNode = null;
        updateTempConnector();
    };

    // Function to update the temporary connector indicating dragging affiliation
    var updateTempConnector = function() {
        var data = [];
        if (draggingNode !== null && selectedNode !== null) {
            // have to flip the source coordinates since we did this for the existing connectors on the original tree
            data = [{
                source: {
                    x: selectedNode.y0,
                    y: selectedNode.x0
                },
                target: {
                    x: draggingNode.y0,
                    y: draggingNode.x0
                }
            }];
        }
        var link = svgGroup.selectAll(".templink").data(data);

        link.enter().append("path")
            .attr("class", "templink")
            .attr("d", d3.svg.diagonal())
            .attr('pointer-events', 'none');

        link.attr("d", d3.svg.diagonal());

        link.exit().remove();
    };

    // Function to center node when clicked/dropped so node doesn't get lost when collapsing/moving with large amount of children.

    var centerNode = function(source) {
        let scale = zoomListener.scale();
        var x = -source.y0;
        var y = -source.x0;
        x = x * scale + viewerWidth / 5;
        y = y * scale + viewerHeight / 2;
        d3.select('g').transition()
            .duration(duration)
            .attr("transform", "translate(" + x + "," + y + ")scale(" + scale + ")");
        zoomListener.scale(scale);
        zoomListener.translate([x, y]);
    }

    // Toggle children function

    var toggleChildren = function(d) {
        if (d.children) {
            d._children = d.children;
            d.children = null;
        } else if (d._children) {
            d.children = d._children;
            d._children = null;
        }
        return d;
    }

    // Toggle children on click.
    var self = this;
    var click = function(d) {
      //console.log(d)
        //self.addTier.tierName = d.name;
        $("#tree").find(".nodeCircle").removeClass("active");
        $(this).children('.nodeCircle').addClass('active');
        if (d.tierId) {
            self.getTierSitesList(d.tierId);
        }
        if (d3.event.defaultPrevented) return; // click suppressed
        //d = toggleChildren(d);
        update(d);
        //centerNode(d);
    }

    var update = function(source) {
        // Compute the new height, function counts total children of root node and sets tree height accordingly.
        // This prevents the layout looking squashed when new nodes are made visible or looking sparse when nodes are removed
        // This makes the layout more consistent.
        var levelWidth = [1];
        var childCount = function(level, n) {

            if (n.children && n.children.length > 0) {
                if (levelWidth.length <= level + 1) levelWidth.push(0);

                levelWidth[level + 1] += n.children.length;
                n.children.forEach(function(d) {
                    childCount(level + 1, d);
                });
            }
        };
        childCount(0, root);
        var newHeight = d3.max(levelWidth) * 25; // 25 pixels per line  
        tree = tree.size([newHeight, viewerWidth]);

        // Compute the new tree layout.
        
        var nodes = tree.nodes(root).reverse();
        var links = tree.links(nodes);

        // Set widths between levels based on maxLabelLength.
        nodes.forEach(function(d) {
            d.y = (d.depth * (maxLabelLength * 9)); //maxLabelLength * 10px
            // alternatively to keep a fixed scale one can set a fixed depth per level
            // Normalize for fixed-depth by commenting out below line
            // d.y = (d.depth * 500); //500px per level.
        });

        // Update the nodes…
        var node = svgGroup.selectAll("g.node")
            .data(nodes, function(d) {
                return d.id || (d.id = ++i);
            });

        // Enter any new nodes at the parent's previous position.
       var nodeEnter = node.enter().append("g")
            // .call(dragListener)
            .attr("class", "node")
            .attr("transform", function(d) {
                return "translate(" + source.y0 + "," + source.x0 + ")";
            })
            .on('click', click);

        nodeEnter.append("circle")
            .attr('class', 'nodeCircle')
            .attr("r", 0)
            .style("fill", function(d) {
                return d._children ? "lightsteelblue" : "#fff";
            })
            .on("contextmenu", function (d, i) {
              click(d);
            var y_pos = $(this).offset().top;
            d3.event.preventDefault();
                // menu(d3.event.layerX+5,d3.event.layerY,d.tierId,d.name);
                if (d.tierId == 0) {
                    PerentmenuItem(d3.event.layerX+5,d3.event.layerY,d.tierId,d.name,d.flag);
                }else{
                    menu(d3.event.layerX+5,d3.event.layerY,d.tierId,d.name,d.flag);
                }
            // react on right-clicking
        });

        nodeEnter.append("text")
            .attr("x", function(d) {
                return d.children || d._children ? -7 : 10;
            })
            .attr("dy", ".35em")
            .attr('class', 'nodeText')
            .attr("text-anchor", function(d) {
                return d.children || d._children ? "end" : "start";
            })
            .text(function(d) {
                return d.name;
            })
            .style("fill-opacity", 0);

        // phantom node to give us mouseover in a radius around it
        nodeEnter.append("circle")
            .attr('class', 'ghostCircle')
            .attr("r", 30)
            .attr("opacity", 0.2) // change this to zero to hide the target area
            .style("fill", "red")
            .attr('pointer-events', 'mouseover')
            .on("mouseover", function(node) {
                overCircle(node);
            })
            .on("mouseout", function(node) {
                outCircle(node);
            });

        // Update the text to reflect whether node has children or not.
        node.select('text')
            .attr("x", function(d) {
                return d.children || d._children ? -7 : 10;
            })
            .attr("text-anchor", function(d) {
                return d.children || d._children ? "end" : "start";
            })
            .text(function(d) {
                return d.name;
            });

        // Change the circle fill depending on whether it has children and is collapsed
        node.select("circle.nodeCircle")
            .attr("r", 4.5)
            .style("fill", function(d) {
                return d._children ? "lightsteelblue" : "#fff";
            });

        // Transition nodes to their new position.
        var nodeUpdate = node.transition()
            .duration(duration)
            .attr("transform", function(d) {
                return "translate(" + d.y + "," + d.x + ")";
            });

        // Fade the text in
        nodeUpdate.select("text")
            .style("fill-opacity", 1);

        // Transition exiting nodes to the parent's new position.
        var nodeExit = node.exit().transition()
            .duration(duration)
            .attr("transform", function(d) {
                return "translate(" + source.y + "," + source.x + ")";
            })
            .remove();

        nodeExit.select("circle")
            .attr("r", 0);

        nodeExit.select("text")
            .style("fill-opacity", 0);

        // Update the links…
        var link = svgGroup.selectAll("path.link")
            .data(links, function(d) {
                return d.target.id;
            });

        // Enter any new links at the parent's previous position.
        link.enter().insert("path", "g")
            .attr("class", "link")
            .attr("d", function(d) {
                var o = {
                    x: source.x0,
                    y: source.y0
                };
                return diagonal({
                    source: o,
                    target: o
                });
            });

        // Transition links to their new position.
        link.transition()
            .duration(duration)
            .attr("d", diagonal);

        // Transition exiting nodes to the parent's new position.
        link.exit().transition()
            .duration(duration)
            .attr("d", function(d) {
                var o = {
                    x: source.x,
                    y: source.y
                };
                return diagonal({
                    source: o,
                    target: o
                });
            })
            .remove();

        // Stash the old positions for transition.
        nodes.forEach(function(d) {
            d.x0 = d.x;
            d.y0 = d.y;
        });
    }

    // Append a group which holds all nodes and which the zoom Listener can act upon.
    var svgGroup = baseSvg.append("g");
    // Define the root
    root = this.treeData;
    root.x0 = 500;
    root.y0 = 0;

    // Layout the tree initially and center on the root node.
    update(root);
    centerNode(root);              
              }else {
                /*Swal({
                  position: 'top-end',
                  type: 'error',
                  title: data.responseMessage,
                  showConfirmButton: false,
                  timer: 1500
                })*/
              }
            },
              error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );  
}

UpdateParent(tierId, name,parenttierId){
    let tierObj2 = {
        tierId : tierId,
        "parentTier": {
            "tierId": parenttierId
        },
        // tierName:name
    }
    console.log(tierId, name,parenttierId);
    // let tierObj = d
                    // delete tierObj.parent.children
    // tierObj2.parentTierId = innerselectedNode.tierId;
    this.apiService.update('tier/updateTierParent', tierObj2).subscribe(
    data => {
        if(data.statusCode == 100){
            this.resetTierData();
            //$('#addTier').modal('hide');
        }else{
            Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 3500
                })                 
        }
    },
    error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
);}

modelChanged(oldVal, newVal) {
    // if (oldVal != newVal && this.unsavedChanges == false) {
      this.unsavedChanges = true;
    // }
  }

  UpdateTierData(){
    let obj = {
        tierId : this.addTier.tierId,
        tierName : this.addTier.tierName,
        parentTier : this.addTier.parentTier,
        license : this.addTier.license,
        sites : this.siteDataRecord,
        client : {
            clientId:this.clientdetailId
        }
    }

    this.apiService.update('tier/updateTier', obj).subscribe(
        data => {
            if(data.statusCode == 100){
                this.isSiteChange = false;
                this.resetTierData();
                $('#closepopup').click();
                $('#addTier').modal('hide');
                this.alertService.sweetMessage('success','Tier Updated Successfully');
            }else{
                this.alertService.sweetMessage('error',data.responseMessage);
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );

}

/* Add and Update tier List*/
saveTier(){
    this.addTier.sites = this.siteDataRecord;
    this.addTier.parentTier = {"tierId":this.parentTierId};
/*
    console.log(this.addTier);
    return false;*/

    if (!this.tierAddForm.valid) {
        return
    }

    if(this.tierId == 0){  
        delete this.addTier.tierId;      
        this.addTier.user = {"userId":this.userId};

        this.apiService.create('tier/registerTier', this.addTier).subscribe(
            data => {
                if(data.statusCode == 100){
                    this.resetTierData();
                    $('#addTier').modal('hide');
                    this.tierAddForm.submitted = false
                    Swal({
                    position: 'top-end',
                    type: 'success',
                    title: 'Tier Add Successfully',
                    showConfirmButton: false,
                    timer: 3500
                    })
                }else{
                    Swal({
                        position: 'top-end',
                        type: 'error',
                        title: data.responseMessage,
                        showConfirmButton: false,
                        timer: 3500
                        })                 
                }
            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }else{

        if (this.parentTierId == this.addTier.tierId) {
            this.errorText = "Parent and Child tier should not be same."
            setTimeout(() => {
              this.errorText = ''
            }, 6000);
            return
        }
        this.apiService.update('tier/updateTier', this.addTier).subscribe(
            data => {
                if(data.statusCode == 100){
                    this.resetTierData();
                    this.tierAddForm.submitted = false
                    $('#addTier').modal('hide');
                    Swal({
                    position: 'top-end',
                    type: 'success',
                    title: 'Tier Update Successfully',
                    showConfirmButton: false,
                    timer: 3500
                    })
                }else{
                    Swal({
                        position: 'top-end',
                        type: 'error',
                        title: data.responseMessage,
                        showConfirmButton: false,
                        timer: 3500
                        })                 
                }
            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }
}

/* Delete tier */
deleteTier(TID){
  Swal({
      title: 'Are you sure?',
      text: "You want to delete this Tier.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
    if (result.value) {
     this.apiService.delete('tier/deleteTier?tierId='+TID, '').subscribe(
     data => {
         if(data.statusCode == 100){
           Swal({
                position: 'top-end',
                type: 'success',
                title: 'Tier Delete Successfully',
                showConfirmButton: false,
                timer: 1500
              }) ;
              this.resetTierData()
               this.getTreeList();

          }else{
            Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 3500
              })
           }
        },
       error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
        }
      })
}

/* Reset tier data after add and update specific tier */
resetTierData(){
    this.getTreeList();
    this.getAllSitesList();
    this.getTierList();
    this.tierId = 0;
    this.parentTier = {};
    this.parentTierId = 0;
    this.totalTierSite = 0;
    this.selectTierId = 0;
    this.siteDataRecord = [];
    this.showLableInTierOnLoad();
    this.addTier= {"tierName":'',"client":{"clientId":this.clientdetailId},"license":{"licenseId":""}};
}

createClientTier(){
    let obj = {
        "tierId": 0,
        "flag": false,
        "parentTierId": 0,
        "name": this.ClientName,
        user : {"userId":this.userId}
    }

        this.apiService.create('tier/registerTier', obj).subscribe(
            data => {
                if(data.statusCode == 100){
                    this.resetTierData();
                    //$('#addTier').modal('hide');
                    Swal({
                    position: 'top-end',
                    type: 'success',
                    title: 'Tier Add Successfully',
                    showConfirmButton: false,
                    timer: 3500
                    })
                }else{
                    Swal({
                        position: 'top-end',
                        type: 'error',
                        title: data.responseMessage,
                        showConfirmButton: false,
                        timer: 3500
                        })                 
                }
            },
            error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );

}
addTierPopupOpen(){
    // this.isTreeDisable = false;
    // var tID = $(this).attr('tierID');
    // var name = $(this).attr('name');
    this.tierAddForm.submitted = false

    this.getTierList();
    this.siteDataRecord = [];
    this.addTier.tierId = 0;
    this.addTier.tierName = "";
    this.addTier.isCallCenterActive = false;
    this.addTier.isSmsActive = false;
    this.addTier.isTewApprovalRequired = false;
    this.tierId = 0;   
    this.parentTier.tierId = 0;  
    this.addTier.level = 0;  
    this.addTier.license.licenseId  = null; 
    // this.parentTier.tierName = "name"; 
    this.records = [];
    this.parentTierId = 0;
    this.getAllLicenseList();
    this.showLableInTierOnLoad();
    $('#addTier').modal('show');
}


getHistory(id){
    $('#HistoryModel').modal('show');
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('tier/getTierHistory?tierId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
        var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }
    
}