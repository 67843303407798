import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'customer-app-users',
  templateUrl: './customer-users.component.html',
  styleUrls: ['./customer-users.component.css']
})
export class CustomerUsersComponent implements OnInit {

    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    customerName : any = localStorage.getItem("customerName");
    userDatalist: any = [];
    dataTable: any;
    customerId:any=0;
    statusValue:any={};
    permissionData:any=[];
    tierId:any = null;
    search:any = null;
    clientCode : any = '';
    imageUrl = "assets/img/user_icon.png";
    isActive :any = localStorage.getItem("isCustActive");
    tierIdlist:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);

    newClientId:any = '';
    clientObj:any = {};
  breadcrumbObj: any;
  HistoryList: any = [];
  roleList: any = [];
  sessionHistoryList: any = [];
    constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
      this.permissionData = ApiService.permission;
     
    }

    // function to format a data
    DateFormat(startDate){
      if(startDate){
          return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
      }else{
          return "";
      }
    }

    DateFormatEvent(startDate, gmtOffset){
      let d1  = new Date(startDate)
      var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
      
      if(startDate){
      	var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
        return formattedDate;
      }else{
        return "";
      }
    }
    
    ngOnInit() {
      this.clientCode = this.alertService.getClientCode();
      this.isActive   = localStorage.getItem("isCustActive");
      this.route.params.subscribe(params => {
        if(params['id']){
          this.customerId = params['id'];
        }
      });

      this.route.queryParams.subscribe(params => {
        this.newClientId = params.clientId;
        this.clientId = this.newClientId
        this.breadcrumbObj = params['source'];
      });

      if(this.customerId){
        this.getUserListByCustomer();
      }
      this.getClientById();

      $(document).on('hidden.bs.modal', function () {
        if($('.modal.show').length)
        {
          $('body').addClass('modal-open');
        }
      });
    }

    /* Get client by Id*/
    getClientById(){
      this.apiService.getAll('client/getClientById?clientId='+this.newClientId).subscribe(
      data => {
          if(data.statusCode == 100){
              this.clientObj = data.response;

            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
        error =>
          { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
    }
/* Get User list */
getUserListByCustomer(){
  // Destroy Data Table if is already and clear the data
    if ($.fn.DataTable.isDataTable('#custUser_table')){
      $('#custUser_table').DataTable().destroy();
      this.userDatalist=[];
    }
    var URL = 'customer/getUserListByCustomerAndClient?customerId='+this.customerId+'&clientId='+this.clientId
    if(this.tierIdlist != null && this.tierIdlist !=''){
      var tierIdString = '&tierId='+this.tierIdlist.toString();
      URL = URL.concat(tierIdString);
    }

    this.apiService.getAll(URL).subscribe(
      data => {
         if(data.statusCode == 100){
            this.userDatalist = data.userList;
            this.chRef.detectChanges();
            if ($.fn.DataTable.isDataTable('#custUser_table')){
              $('#custUser_table').DataTable().destroy();
            };
            const table: any = $('#custUser_table');
            this.dataTable   = table.DataTable({
              "order": [],"columnDefs": [{ "orderable": false, "targets": [0,5,6,7] }]
            });
          }else{
            this.userDatalist = [];
              if ($.fn.DataTable.isDataTable('#custUser_table')){
                $('#custUser_table').DataTable().destroy();
              };
              const table: any = $('#custUser_table');
              this.chRef.detectChanges();
              this.dataTable   = table.DataTable({
                "order": [],"columnDefs": [{ "orderable": false, "targets": [0,5,6,7] }]
              });
            /*this.alertService.sweetMessage('error',data.responseMessage); */
           }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
    }

    /* Change Customer Status */
    ChangeUserStatus(id,status){
      Swal({
          title: 'Are you sure?',
          text: "You want to Change Status.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change it!'
        }).then((result) => {
        if (result.value) {
          if(status == false){
            this.statusValue = {"userId":id,"isActive":1};
          }else{
            this.statusValue = {"userId":id,"isActive":0};
          }

         this.apiService.update('user/updateUserStatus',this.statusValue).subscribe(
         data => {
             if(data.statusCode == 100){
                  this.alertService.sweetMessage('success','Status Changed Successfully');
                   this.getUserListByCustomer();
              }else{
                this.alertService.sweetMessage('error',data.responseMessage);
               }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
              );
            }
          })
    }

// Function to delete User
DeleteUser(userId){
  Swal({
    title: 'Are you sure?',
    text: "You want to delete this User.",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
      if (result.value) {
      this.apiService.delete('user/deleteCustomerUser?userId='+userId, '').subscribe(
        data => {
          if(data.statusCode == 100){
            this.alertService.sweetMessage('success','User Deleted Successfully');
            this.getUserListByCustomer();
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
      }
    })
  }


/* Deactivate the User */
DeactivateUser(userId){
  Swal({
   title: 'Are you sure?',
   text: "If you deactivate this user then it will no longer be able to log into AMCi1440 and not receive the event notifications. Deactivating the user will remove all its association with Tier, Site and Assets. Would you like to proceed?",
   type: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33',
   confirmButtonText: 'Yes'
 }).then((result) => {
   if (result.value) {
    this.apiService.getAll('user/deactivateUser?userId='+userId+'&isDeactivated=1').subscribe(
      data => {
          if(data.statusCode == 100){
               this.alertService.sweetMessage('success',"User deactivated successfully");
               this.getUserListByCustomer();
           }else{
             this.alertService.sweetMessage('error',data.responseMessage);
             
            }
         },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
               }
           );
       }
   })
 }
 
 // Restore Deactivated User 
 RestoreUser(userId){
  Swal({
   title: 'Are you sure?',
   text: "You want to restore this User.",
   type: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33',
   confirmButtonText: 'Yes, restore it!'
 }).then((result) => {
   if (result.value) {
    this.apiService.getAll('user/deactivateUser?userId='+userId+'&isDeactivated=0').subscribe(
      data => {
          if(data.statusCode == 100){
               this.alertService.sweetMessage('success',"User restored successfully");
               this.getUserListByCustomer();
           }else{
             this.alertService.sweetMessage('error',data.responseMessage);          
           }
         },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
               }
           );
       }
   })
 }

getHistory(id){
  this.getSessionHistory(id);
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('user/getUserHistoryByUserId?userId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.userList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}

getSessionHistory(id){
  this.sessionHistoryList = []
  if ($.fn.DataTable.isDataTable('#sessionHistoryDatatable')) {
    $('#sessionHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('user/getUserSessionHistoryByUserId?userId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.sessionHistoryList = data.userList;
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
      
}

setRolesList(roles){
  this.roleList = roles
}
}
