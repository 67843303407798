import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import * as _ from "lodash";
import { jqxTreeComponent } from '../../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../../jqwidgets-ts/angular_jqxdropdownbutton';


@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css']
})
export class CustomerDetailComponent implements OnInit {
@ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('customerContact') customerContact: any;

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  contactUserId: number = 0;
  countryList:any=[];
  stateList:any=[];
  customerId:number=0;
  contactData:any=[];
  dataTable: any;
  imgBasePath:any="";
  customer: any = {"name":"","isActive":false,"emailId":"","billingAddress":"","city":"","country":{"countryId":""},"state":{"stateId":""}, "sites": [],"contactNumber":"","alternateNumber":"","client":{"clientId":this.clientId},"customerImg":""};

  contact: any =
  {
    "firstName":"",
    "lastName":"",
    "note":"",
    "title": "",
    "emailId":"",
    "phoneNumber":"",
    // "mobileNumber":"",
    "secondaryPhoneNumber":"",
    "userType":"",
    "client":{
      "clientId":''
    },
    "customer":{
      "customerId": ''
    }
  }
  clientCode : any = '';
  permissionData:any=[];
  validNumbers:boolean = false;


  newClientId: any = '';
  clientObj:any = {};
  breadcrumbObj: any;
  HistoryList: any = [];

  constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
   }

  // Function to format data
  DateFormat(startDate){
    if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
      return "";
    }
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
      if(params['id']){
      this.customerId = params['id'];
      this.contact.customer.customerId = this.customerId;
    }
    });


    this.route.queryParams.subscribe(params => {
      this.newClientId = params.clientId;
      this.clientId = this.newClientId
      this.breadcrumbObj = params['source'];
    });

    if(this.customerId){
      this.getCustomerById();
      this.getContactData();
      this.getClientById();
    }
  }

  /* Get client by Id*/
  getClientById(){
    this.apiService.getAll('client/getClientById?clientId='+this.newClientId).subscribe(
    data => {
        if(data.statusCode == 100){
            this.clientObj = data.response;

          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
      error =>
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
  }

  /* Get Customer by Id*/
  getCustomerById(){
    this.apiService.getAll('customer/getCustomerById?customerId='+this.customerId).subscribe(
     data => {
         if(data.statusCode == 100){
            this.customer = data.response;
            if(data.response.country== null){
              this.customer.country = {};
            }
            localStorage.setItem("customerName", this.customer.name);
            localStorage.setItem("isCustActive", this.customer.isActive);
            if(this.customer.imageLocation){
                  this.imgBasePath = this.apiService.imageBasePath+this.customer.imageLocation;
            }
            if(data.response.state == null){
              this.customer.state = {};
            }
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
           }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }


    /* Create Contact */
    SubmitContact() {
      this.contact.customer.customerId = this.customerId;
      this.contact.client.clientId = this.newClientId
      this.contact.phoneNumber = this.contact.phoneNumber ? this.contact.phoneNumber : '';
      this.contact.secondaryPhoneNumber = this.contact.secondaryPhoneNumber ? this.contact.secondaryPhoneNumber : '';
      if((this.contact.phoneNumber!="" && this.contact.secondaryPhoneNumber!=""))
      {
        if((this.contact.phoneNumber == this.contact.secondaryPhoneNumber)){
          return;
        }else{
          if (this.customerContact.valid) {
            if (this.contactUserId == 0) {
                this.apiService.create('contactType/createCustomerContact', this.contact).subscribe(
                    data => {
                        if (data.statusCode == 100) {
                            this.alertService.sweetMessage('success','Contact Details Added Successfully');
                            this.getContactData();
                            this.contact = {
                                "firstName": "",
                                "lastName": "",
                                "note": "",
                                "title" : "",
                                "emailId": "",
                                "phoneNumber": "",
                                // "mobileNumber": "",
                                "secondaryPhoneNumber": "",
                                "userType": "",
                                "client": {
                                    "clientId": this.clientId
                                },
                                "customer": {
                                    "customerId": this.customerId
                                }
                            };
                            this.customerContact.submitted = false;
                            // this.getContactData();

                        } else {
                            this.alertService.sweetMessage('error',data.responseMessage);
                        }
                    },
                    error =>{
                      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
            } else {
                this.apiService.update('contactType/updateCustomerContact', this.contact).subscribe(
                    data => {
                        if (data.statusCode == 100) {
                            this.alertService.sweetMessage('success','Contact Details Updated Successfully');
                            this.getContactData()

                            this.customerContact.resetForm();
                            this.contactUserId = 0;

                        } else {
                            this.alertService.sweetMessage('error',data.responseMessage);
                        }
                    },
                   error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
            }
          }
        }
      }else{
         if (this.customerContact.valid) {
            if (this.contactUserId == 0) {
                this.apiService.create('contactType/createCustomerContact', this.contact).subscribe(
                    data => {
                        if (data.statusCode == 100) {
                            this.alertService.sweetMessage('success','Contact Details Added Successfully');
                            this.getContactData();
                            this.contact = {
                                "firstName": "",
                                "lastName": "",
                                "note": "",
                                "title" : "",
                                "emailId": "",
                                "phoneNumber": "",
                                // "mobileNumber": "",
                                "secondaryPhoneNumber": "",
                                "userType": "",
                                "client": {
                                    "clientId": this.clientId
                                },
                                "customer": {
                                    "customerId": this.customerId
                                }
                            };
                            this.customerContact.submitted = false;
                            // this.getContactData();

                        } else {
                            this.alertService.sweetMessage('error',data.responseMessage);
                        }
                    },
                    error =>{
                      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
            } else {
                this.apiService.update('contactType/updateCustomerContact', this.contact).subscribe(
                    data => {
                        if (data.statusCode == 100) {
                            this.alertService.sweetMessage('success','Contact Details Updated Successfully');
                            this.getContactData()

                            this.customerContact.resetForm();
                            this.contactUserId = 0;

                        } else {
                            this.alertService.sweetMessage('error',data.responseMessage);
                        }
                    },
                   error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
            }
        }
      }
        
    }

  /* Get Contact by Id*/
  getContactById(contact) {
      this.contactUserId = contact.contactTypeId;
      // this.apiService.getAll('user/getCustomerContactById?userId=' + this.contactUserId).subscribe(
      this.apiService.getAll('contactType/getContactTypeById?contactTypeId=' + this.contactUserId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.contact = data.contactType;
              } else {
                  this.alertService.sweetMessage('error',data.responseMessage);
              }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }

  // Function to Delete Contact
  deleteContact(userId) {
        Swal({
            title: 'Are you sure?',
            text: "You want to delete this contact.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                // this.apiService.getAll('contactType/deleteCustomerContact?userId=' + userId).subscribe(
                this.apiService.delete('contactType/deleteCustomerContact?contactTypeId=' + userId, '').subscribe(
                  data => {
                    if (data.statusCode == 100) {
                        this.alertService.sweetMessage('success','Contact Deleted Successfully');
                        this.contactUserId = 0;
                        this.contact = {
                          "firstName":"", "lastName":"", "note":"",
                          "title": "", "emailId":"", "phoneNumber":"",
                          "secondaryPhoneNumber":"", "userType":"",
                          "client":{ "clientId":this.clientId },
                          "customer":{ "customerId": '' }
                        }
                        this.getContactData();
                    }
                    else
                    { this.alertService.sweetMessage('error',data.responseMessage); }
                    },
                   error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
            }
        });
    }


  /* Get Contact data list */
  getContactData(){
    if ($.fn.DataTable.isDataTable('#con_table')){
      $('#con_table').DataTable().destroy();
    };
    $('#con_table').DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [4] }]});
    this.apiService.getAll('contactType/getCustomerContactListByClientId?clientId='+this.clientId+'&customerId='+this.customerId).subscribe(
      data => {
          if(data.statusCode == 100){
            this.contactData = data.contactTypeList ? data.contactTypeList : [];
            if ($.fn.DataTable.isDataTable('#con_table')){
              $('#con_table').DataTable().destroy();
            };
            // this.contactData = data.userList;
            this.chRef.detectChanges();
            const table: any = $('#con_table');
            this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [4] }]});
          }else{
            this.contactData = [];
            if ($.fn.DataTable.isDataTable('#con_table')){
              $('#con_table').DataTable().destroy();
            };
            this.chRef.detectChanges();
            const table: any = $('#con_table');
            this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [4] }]});
            /*this.alertService.sweetMessage('error',data.responseMessage); */
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
  }

  /* Delete Notification Management list */
  DeleteUser(userId){
     Swal({
      title: 'Are you sure?',
      text: "You want to delete this Contact.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
       this.apiService.getAll('user/deleteUser/'+userId).subscribe(
       data => {
           if(data.statusCode == 100){
             //this.alertService.sweetMessage('success','Contact Deleted Successfully');

                 this.getContactData();


            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
             }
          },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
            );
    }
    })
  }


  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('contactType/getContactTypeHistoryById?contactTypeId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.contactTypeList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }
}
