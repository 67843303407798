import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, CommonServicesService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import * as _ from "lodash";
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { FormBuilder } from '@angular/forms';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Location } from '@angular/common';

declare var moment:any;
@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.css']
})
export class AddSiteComponent implements OnInit {



  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;


  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  tiersId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
  countryList: any = [];
  stateList: any = [];
  assetList: any[] = [];
  assetFilter: any = [];
  siteAssetFilter: any = [];
  assetDataRecord: any = [];
  customerList: any = [];
  totalSiteAssets: number = 0;
  Sitelocation: boolean = false;
  totalAssets: number = 0;
  tierId: number = 0;
  siteId: number = 0;
  assetArray: any = [];
  dataAdapter: any = [];
  source: any = {};
  data: any = [];
  disableTreeArray:any=[];
  tierListData: any = [];
  records: any = [];
  permissionData: any = [];
  latLongEnable: any = false;
  unsavedChanges: any = false;
  apiParamData:any;
  isAssetAdd: any = false;
  assetCount: any = 0;
  clientCode:any ='';
  isCountryId: boolean = false;
  isdisabled : boolean = false;
  site: any = {
    "siteName": "", "city": "", "isActive": 1, "zipCode": "",
    "longitude": 0.0, "latitude": 0,
    "client": { "clientId": this.clientId },
    "customer": { "customerId": "" },
    "country": { "countryId": "" ,  "countryName": ""},
    "state": { "stateId": "" },
    "isAutoFetchAssetLocation": false,
    "tier":{"tierId":""}
  };

  assetLat  : any ;
  assetLong : any ;
  default : number = 0;
  userAgent : any;
  ErroObj:any = {}
  clientFilterId:any = null;
  customClientFilterId:any = null;
  clientName : any = localStorage.getItem("clientName");
  breadcrumbObj: any;
  c_Id: any;
  c_name: any;
  assetIdDetail: any;

  constructor(private router: Router, private route: ActivatedRoute, private commonServicesService:CommonServicesService , private apiService: ApiService, private alertService: AlertService, private formBuilder: FormBuilder, private location:Location) {
    // this.permissionData = ApiService.permission;
    this.commonServicesService.getClientId().takeUntil(componentDestroyed(this))
      .subscribe(
        message => {
          this.clientFilterId = message.id;
          setTimeout(() => {
            this.clientName = localStorage.getItem("clientName");
          }, 1000);

          this.route.params.subscribe(params => {
            if (params['id']) {
              this.siteId = params['id'];
            }
          });
          if (this.siteId) {
            this.getSiteById();
          }

          // if (this.clientFilterId!='0' && this.clientFilterId!=null) {
          //   this.getTierList();
          //   this.getAssetList();
          //   // this.getAllSiteList();
          // }else{
            
          // }

          // else {
          //   this.router.navigate(['/site-management']);
          // }
        },
        error=>{
          console.log(error)
        }
      );
      this.route.params.subscribe(params => {
        if (params['clientId']) {
          this.clientFilterId = params['clientId'];
        }
      });
      if (this.clientFilterId) {
        this.getTierList();
        this.getAssetList();
      }
  }

  ngOnInit() {
    
    var _this = this;
    $(document).ready(function() {
      if ((screen.width>=1024) && (screen.height>=768)) {
        // alert('Screen size: 1024x768 or larger');
        _this.userAgent = 'desktop';
      }
      else {
        // alert('Screen size: less than 1024x768, 800x600 maybe?');
        _this.userAgent = 'tablet';
      }
    });

    // this.userAgent    = navigator.platform
    this.clientCode   = this.alertService.getClientCode();
    this.Sitelocation = this.site.isAutoFetchAssetLocation;

    if(this.Sitelocation == true){
      this.isdisabled = true;
    }
    else{
      this.isdisabled = false;
    }
    this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        this.c_Id = params.c_id;
        this.c_name = params.c_name;
        this.assetIdDetail = params.assetId;
    })

    // this.getAssetList();
    this.getCountryList();
    // this.getTierList();
    //this.getCustomerList();
    // this.showLableInTierOnLoad(); 

  }

  DateFormat(startDate){
  if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
  }else{
      return "";
    }
 }

 ngOnDestroy(){
   
 }

  modelChanged(oldVal, newVal) {
  //  console.log(this.unsavedChanges);
    if (oldVal != newVal && this.unsavedChanges == false) {
      this.unsavedChanges = true;
    }
    //do something with new value
  }

  /* Show tier label in dropdown on tree select */
  myTreeOnSelect(event: any) {
    this.myDropDownButton.close();    
    if((this.disableTreeArray).indexOf(parseInt(event.args.element.id)) != -1){
      let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
      this.myDropDownButton.setContent(dropDownContent);
      this.tierId = 0;
    }else{
      this.tierId = event.args.element.id;
      let item = this.myTree.getItem(event.args.element);
      let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + item.label + '</div>';
      this.myDropDownButton.setContent(dropDownContent);
    }
  };

  /****************
  Fill Adress Detail if user select Obtain Site Location Automatically
  **************************/
  setLatLog () {

    setTimeout(() => {
      if(this.Sitelocation == true) {
        this.isdisabled = true;
        this.site.latitude = this.assetLat
        this.site.longitude = this.assetLong
        var tempLat;
        var tempLong;

          if(this.assetCount > 0){
            // console.log(this.assetLat && this.assetLong)
            if( Math.abs(this.assetLat) && Math.abs(this.assetLong)){
                tempLat  = this.assetLat;
                tempLong = this.assetLong;
            }else{
                tempLat  = this.site.latitude
                tempLong = this.site.longitude;
            }
            this.getAssetLatLongOnDrop(tempLat  , tempLong );
          }

      }else {
        this.isdisabled = true;
      }
    }, 100);

    // console.log(this.assetLat , this.assetLong)

  }

  /* Drop Asset  */
  onItemDrop(e: any) {

    this.unsavedChanges = true;

    if(e.hasOwnProperty('dragData')) {
      var assetData = e.dragData;
    }else{
      var assetData = e;
    }

    this.assetCount = (this.assetCount + 1)

    if(this.isAssetAdd == false) {
      this.assetCount = 1
      this.isAssetAdd = true;

        if(this.Sitelocation == true){
            this.getAssetLatLongOnDrop(assetData.latitude , assetData.longitude);
        }
    }

    // /console.log("asset == > "+ JSON.stringify(assetData));
    var index = this.assetList.findIndex(x => x.assetId == assetData.assetId);

        this.assetLat  = assetData.latitude;
        this.assetLong = assetData.longitude;

        if (this.assetDataRecord.length == 0 && this.Sitelocation == true) {
            this.site.latitude = assetData.latitude;
            this.site.longitude = assetData.longitude;
            this.site.country.countryId = assetData.countryId != null ? assetData.countryId : "";
            if(assetData.countryId != null) { this.getStateListByCountryId(assetData.countryId); }
            this.site.state.stateId = assetData.stateId != null ? assetData.stateId : "";
            this.site.city = assetData.city != null ? assetData.city : "";
            this.latLongEnable = true;
        }


    this.assetList.splice(index, 1);
    this.assetDataRecord.push(assetData);

    this.assetArray.push({ "assetId": assetData.assetId });
    this.totalSiteAssets = this.totalSiteAssets + 1;
    this.totalAssets = this.totalAssets - 1;

  }

  /* Remove Asset  */
  removeAsset(assetDataR, index) {
    this.assetCount = (this.assetCount - 1)

    if( this.Sitelocation == true && (this.assetCount == 0 || this.assetCount == -1) ) {
        this.isAssetAdd = false;
        //this.getAssetLatLongOnDrop(assetDataR.latitude , assetDataR.longitude)
    }
    this.unsavedChanges = true;
    this.assetDataRecord.splice(index, 1);
    this.assetArray.splice(index, 1);
    this.assetList.push(assetDataR)
    this.totalSiteAssets = this.totalSiteAssets - 1;
    this.totalAssets = this.totalAssets + 1;

    if(this.assetDataRecord.length == 0){
      this.site.city = "";
       this.latLongEnable = false;
       this.site.latitude = "";
       this.site.longitude = "";
       this.site.country.countryId = "";
       this.site.state.stateId = "";
      this.Sitelocation = false
    }

  }


/****************************************************
  API Call
  to Get Country and State from Lat Long
*****************************************************/

getAssetLatLongOnDrop(lat,long){

  if(lat && long){
    var URL = 'asset/getAddressByLongLat?latitude=' + lat + '&longitude=' + long;
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {

            if(data.response.countryId == null){
              this.isCountryId = true;
            }
            this.site.latitude  = lat;
            this.site.longitude = long
            this.site.city = data.response.city;
            this.site.country.countryName = data.response.countryName;
            this.site.country.countryId = data.response.countryId;
            this.site.state.stateId = data.response.stateId;
            this.getStateListByCountryId(data.response.countryId)
            this.showLableInTierOnLoad();
        }
        else
          { this.alertService.sweetMessage('error', 'No records found'); }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );

  }

}

  /* Show tier label in dropdown on get by IdApi */
  showLableInTierOnLoad() {
    if (this.site.tier.tierName && this.site.tier.tierId) {
      this.tierId = this.site.tier.tierId;
      let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + this.site.tier.tierName + '</div>';
      if (this.myDropDownButton) {
        this.myDropDownButton.setContent(dropDownContent);
      }
    } else {
      let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
      if (this.myDropDownButton) {
        this.myDropDownButton.setContent(dropDownContent);
      }
    }

  }

  /* Get assets list */
  getAssetList() {
    // var URL = 'asset/getUnassignedSiteAssetByClient?clientId=' + this.clientFilterId/*  + '&userId=' + this.userId; */
    // var URL = 'asset/getUnassignedSiteAssetByClient'+((this.clientFilterId && this.clientFilterId != 0) ? '?clientId='+this.clientFilterId : '');
    var URL = 'asset/getUnassignedSiteAssetByClient?';
    if(this.clientFilterId && this.clientFilterId != 0){
      var clientString = 'clientId='+this.clientFilterId;
      var URL = URL.concat(clientString);
    }else{
      var clientString = 'clientId='+this.customClientFilterId;
      var URL = URL.concat(clientString);
    }
    
    // if (this.customerId != null && this.customerId != '') {
    //   var customerString = '&customerId=' + this.customerId.toString();
    //   var URL = URL.concat(customerString);
    // }
    // if (this.tiersId != null && this.tiersId != '') {
    //   var tierString = '&tierId=' + this.tiersId.toString();
    //   var URL = URL.concat(tierString);
    // }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetList = data.responseList;
          this.totalAssets = this.assetList.length;
        } else {
          this.assetList = [];
          /*Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })*/
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }



  /* Add and update Site */
  SubmitSite(formValue) {
    this.site.client.clientId = this.clientFilterId;
    var siteName = $('#siteName').val();
    let isError = false;
    // if (this.tierId.toString() == '' && siteName == '') {
    //   $('#siteName_err').css('display', 'block');
    //   $('#tier_err').css('display', 'block');
    //   document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
    //   isError = true;
    //   // return;
    // } else {
    //   $('#siteName_err').css('display', 'none');
    //   $('#tier_err').css('display', 'none');
    // }

    // if (siteName == '') {
    //   $('#siteName_err').css('display', 'block');
    //   document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
    //   isError = true;
    //   // return;
    // } else {
    //   $('#siteName_err').css('display', 'none');
    // }
    console.log(this.tierId);
    
    if (this.tierId.toString() == '' || this.tierId.toString() == '0') {
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
      $('#tier_err').css('display', 'block');
      isError = true;
      return;
    } else {
      $('#tier_err').css('display', 'none');
    }

    this.unsavedChanges = false;

    if (!formValue.invalid) {

      this.site.isAutoFetchAssetLocation = this.Sitelocation;
      this.site.tier = { "tierId": this.tierId };
      this.site.assets = this.assetArray;

      // this.apiParamData = this.site;
      this.site.siteId = this.siteId;
      // Deep copy
      // var newObject = jQuery.extend(true, {}, this.site);
      var newObject = JSON.parse(JSON.stringify(this.site));


      if (!this.Sitelocation) {
        if (!this.site.country.countryId) {
          document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
          this.ErroObj.countryErr = 'Please Select Country';
        }else{
          this.ErroObj.countryErr = null;
        }

        // console.log(this.site.state.stateId ,!this.site.state.stateId,"counrty" , this.site.country.countryId)
        if (this.site.state.stateId=="") {
          document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
          this.ErroObj.stateErr = 'Please Select State';
        }else{
          this.ErroObj.stateErr = null;
        }

        if (!this.site.country.countryId || this.site.state.stateId=="") {
            return;
        }
      }

      if (!this.site.country.countryId) {
        newObject.country = null;
        this.ErroObj.countryErr = null;
      }

      if (!this.site.state.stateId) {
        // newObject.state.stateId = "";
        newObject.state = null;
        this.ErroObj.stateErr = null;
      }

      if (!this.site.customer.customerId) {
        newObject.customer = null;
      }

      if (this.tierId != 0) {
        if (this.siteId == 0) {
          this.apiService.create('site/registerSite', newObject).subscribe(
            data => {
             
              if (data.statusCode == 100) {
                this.site.country = { "countryId": "", "countryName": "" }
                this.site.state = { "stateId": ""}
                this.site.customer = { "customerId": "" }
                this.alertService.sweetMessage('success', 'Site added Successfully');
                // this.router.navigate(['/site-management']);
                this.location.back();
              } else {
                this.alertService.sweetMessage('error', data.responseMessage);
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        } else {
          this.apiService.update('site/updateSite', newObject).subscribe(
            data => {
              console.clear();
              if (data.statusCode == 100) {
                this.alertService.sweetMessage('success', 'Site Updated Successfully');
                this.router.navigate(['/site-management']);
                this.location.back();
              } else { this.alertService.sweetMessage('error', data.responseMessage); }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        }
      }
      else { 
        // this.alertService.sweetMessage('error', 'Please Select Tier');
        $('#tier_err').css('display', 'block');
      }
    } else {
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
       this.alertService.sweetMessage('error', 'Please fill in all the required fields'); }
  }

  /* Get Site by Id*/
  getSiteById() {
    this.apiService.getAll('site/getSiteDetailById?siteId=' + this.siteId).subscribe(
      data => {
        if (data.statusCode == 100) {

          this.site = {
            "siteName": data.response.siteName || "",
            "city": data.response.city || "",
            "address": data.response.address || "",
            "county": data.response.county || "",
            "isActive": 1,
            "zipCode": data.response.zipCode || "",
            "longitude": data.response.longitude || 0.0,
            "latitude": data.response.latitude || 0.0,
            "client": data.response.client || { "clientId": this.clientFilterId },
            "customer": data.response.customer || { "customerId": "" },
            "country": data.response.country || { "countryId": "" ,  "countryName": ""},
            "state": data.response.state || { "stateId": 0 },
            "isAutoFetchAssetLocation": data.response.isAutoFetchAssetLocation || true,
            "tier": data.response.tier || {"tierId":""}
          };
           if(data.response.tier){
             if(data.response.tier.client){
               let tierClientDetail = data.response.tier.client;
               this.customClientFilterId = tierClientDetail.clientId;
             }
           }

          this.assetLat = JSON.parse(JSON.stringify(data.response.latitude))
          this.assetLong = JSON.parse(JSON.stringify(data.response.longitude))

          if (data.response.latitude != "" && data.response.longitude != "") {
            this.latLongEnable = true;
          }
          if (data.response.country) {
            this.getStateListByCountryId(data.response.country.countryId);
          } else {
            this.site.country = {};
          }
          if (data.response.customer == null) {
            this.site.customer = {};
          }
          if (data.response.state == null) {
            this.site.state = {};
          }else{
            this.site.state.stateId = data.response.state.stateId;
          }
          this.Sitelocation = data.response.isAutoFetchAssetLocation;
          if(this.Sitelocation == false){
            this.isdisabled = false;
          }
          else {
            this.isdisabled = true;
          }
          this.assetDataRecord = data.response.assets ? data.response.assets : [];
          this.totalSiteAssets = data.response.assets ? data.response.assets.length : 0;
          for (let assetsM of this.assetDataRecord) {
            this.assetArray.push({ "assetId": assetsM.assetId });
            var index = this.assetList.findIndex(x => x.assetId == assetsM.assetId);
            if (index != -1) {
              this.assetList.splice(index, 1);
            }
          }
          this.showLableInTierOnLoad();
        } else {
          // Swal({
          //   position: 'top-end',
          //   type: 'error',
          //   title: data.responseMessage,
          //   showConfirmButton: false,
          //   timer: 1500
          // })
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  // /* Get Role list */
  // getCustomerList() {
  //   var URL = 'customer/getCustomerByClient?clientId=' + this.clientId;
  //   if (this.customerId != null && this.customerId != '') {
  //     var customerString = '&customerId=' + this.customerId.toString();
  //     var URL = URL.concat(customerString);
  //   }
  //   if (this.tiersId != null && this.tiersId != '') {
  //     var tierString = '&tierId=' + this.tiersId.toString();
  //     var URL = URL.concat(tierString);
  //   }
  //   this.apiService.getAll(URL).subscribe(
  //     data => {
  //       if (data.statusCode == 100) {
  //         this.customerList = data.responseList;
  //       } else {
  //         this.customerList = [];
  //        /*Swal({
  //            position: 'top-end',
  //            type: 'error',
  //            title: data.responseMessage,
  //            showConfirmButton: false,
  //            timer: 1500
  //          })*/
  //       }
  //     },
  //    error =>{
        //       this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        //     }
  //   );
  // }

  /* Get tier list */
  getTierList() {
    var URL = 'tier/getAllTierByUser?';
    if(this.clientFilterId!=null && this.clientFilterId != '0'){
      var clientString     = 'clientId='+this.clientFilterId;
      var URL = URL.concat(clientString);
    }
    else{
      var clientString     = 'clientId='+this.customClientFilterId;
      var URL = URL.concat(clientString);
    }
    // this.apiService.getAll('tier/getAllTierByUser?clientId=' + this.clientFilterId /* + '&userId=' + this.userId */).subscribe(
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.tierListData = data.responseList;
          this.data = this.tierListData
          this.source = {
            datatype: 'json',
            datafields: [
              { name: 'tierId' },
              { name: 'parentTierId' },
              { name: 'tierName' },
            ],
            id: 'tierId',
            localdata: this.data
          };
          this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
          this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);
          // Object.keys(this.data).forEach(key => {
          //               if (this.data[key]['flag'] == false) {
          //                   this.disableTreeArray.push(this.data[key]['tierId']);
          //               }
          //           });
           var self = this;
              setTimeout(function() {
              // self.treeItemDisable();
            }, 1000);
          let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
            this.myDropDownButton.setContent(dropDownContent);
            this.showLableInTierOnLoad();
        } else {
          this.tierListData = {};

          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

treeItemDisable() {
        Object.keys(this.disableTreeArray).forEach(key => {
            let checkedItem = document.getElementById(this.disableTreeArray[key]);
            this.myTree.expandItem(checkedItem);
            this.myTree.disableItem(checkedItem);
        });
    }
  /* Get state list by country id */
  getStateListByCountryId(countryId) {
    if (countryId && countryId != "" && countryId != "null") {

      this.isCountryId = false;
      this.apiService.getAll('state/getStateList/' + countryId).subscribe(
        data => {
          if (data.statusCode == 100) {
             //this.site.state.stateId = '';
            this.stateList = data.responseList;
            this.showLableInTierOnLoad();
          } else {
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
    }else{
      this.stateList=[];
    }
  }

  /* Get Country list */
  getCountryList() {
    this.apiService.getAll('country/getCountry').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.countryList = data.responseList;
          this.showLableInTierOnLoad();
        } else {
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  cancelChanges(){
    this.location.back();
  }
}
