import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AlertService } from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import * as _ from "lodash";
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { environment } from "../../../../../../environments/environment"

@Component({
  selector: 'app-add-center',
  templateUrl: './add-center.component.html',
  styleUrls: ['./add-center.component.css']
})

export class AddCenterComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('addCallCenterForm') addCallCenterForm: any;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService) { }

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  tiersId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
  newUserId: any = 0;
  clientIds: any = []
  ClientIdArr: any = [];
  clientDataList: any = [];
  clientsDropReq: any = false;

  dropdownSettingsClient: any = {
    singleSelection: false,
    idField: 'clientId',
    textField: 'description',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };

  data: any = {
    phoneNumber:"",
    alternateNumber:""
  };
  clientName: any = localStorage.getItem("clientName");

  ngOnInit() {

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.newUserId = params['id'];
        this.getCallcenterById();
      }
    });

    this.getClientDrodownlist();
    this.dropdownSettingsClient = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'description',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
  }

  ngOnDestroy() {
    $('.multiselect-dropdown').removeClass('is-invalid');
  }

  // Get Client Drop Down Value
  getClientDrodownlist() {
    this.apiService.getAll('client/getClientDropDownList').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.clientDataList = data.responseList;
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* On Item Select Site */
  onItemSelectClient(item: any) {
    this.clientsDropReq = false;
    var exist = this.ClientIdArr.indexOf(item.clientId.toString());
    if (exist == '-1') {
      this.ClientIdArr.push(item.clientId.toString());
    }
    this.checkClientsDropDown();
  }

  /* On Item DeSelect Site */
  onItemDeselectClient(item: any) {
    this.clientsDropReq = false;
    var exist = this.ClientIdArr.indexOf(item.clientId.toString());
    if (exist != '-1') {
      this.ClientIdArr.splice(exist, 1);
    }
    this.checkClientsDropDown();
  }

  /* On All Select Site */
  onSelectAllClient(items: any) {
    this.clientsDropReq = false;
    this.ClientIdArr = [];
    Object.keys(items).forEach(key => {
      this.ClientIdArr.push(items[key]['clientId'].toString());
    });
    this.checkClientsDropDown();
  }

  /* On All DeSelect Site */
  onDeSelectAllClient() {
    this.clientsDropReq = false;
    this.ClientIdArr = [];
    this.checkClientsDropDown();
    // this.getAllAssetList();
  }

  checkClientsDropDown() {   
    // this.ClientIdArr.map((val, index) => {
    //   // client.push({clientId:val});
    //   this.data.clients[index].clientId;
    // }) 
    if (this.ClientIdArr === undefined || this.ClientIdArr.length === 0) {
      this.clientsDropReq = true;
      $('.multiselect-dropdown').addClass('is-invalid');
    } else {
      this.clientsDropReq = false;
      $('.multiselect-dropdown').removeClass('is-invalid');
    }
  }
  /* Add OR Update Call Center */
  addCallCenter() {
    let client = []
    this.ClientIdArr.map((val, index) => {
      // client.push({clientId:val});
      this.data.clients[index].clientId;
    })

    if (this.data.clients === undefined || this.data.clients.length === 0) {
      this.clientsDropReq = true;
      $('.multiselect-dropdown').addClass('is-invalid');
    } else {
      this.clientsDropReq = false;
      $('.multiselect-dropdown').removeClass('is-invalid');
    }
    // Add New Call Center
    if(this.addCallCenterForm.valid && (this.data.phoneNumber == this.data.alternateNumber && this.data.phoneNumber.length == 10  && this.data.alternateNumber.length == 10) ) {
      return;
    }else if((this.addCallCenterForm.valid && (this.data.phoneNumber != this.data.alternateNumber && this.data.phoneNumber.length == 10  && this.data.alternateNumber.length == 10) ) ||
    (this.addCallCenterForm.valid && this.data.phoneNumber.length != 0) ||
    (this.addCallCenterForm.valid && this.data.alternateNumber.length != 0) ||
    (this.addCallCenterForm.valid && this.data.phoneNumber.length == 0 && this.data.alternateNumber.length == 0) 
    ) {
      //Adding Call Center
      if(this.newUserId == 0){
        this.apiService.create('callcenter/createCallCenter', this.data).subscribe(
          data => {
            if(data.statusCode == 100){
              this.alertService.sweetMessage('success','Call Center added successful');
              this.router.navigate(['/callCenter']);
            }else
              { this.alertService.sweetMessage('error',data.responseMessage); }
          },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
      }else{
// Update Call Center
        var obj = this.data;
        obj.callCenterId = this.newUserId;

        this.apiService.update('callcenter/updateCallCenter', obj ).subscribe(
          data => {
            if(data.statusCode == 100){
              this.alertService.sweetMessage('success','Call Center updated successful');
              this.router.navigate(['/callCenter']);
            }else
              { this.alertService.sweetMessage('error',data.responseMessage); }
          },
          error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
      }   
    }
  }


  // Get Calll center Details by ID
  getCallcenterById() {
    this.apiService.getAll('callcenter/getCallCenterBycallCenterId?callCenterId=' + this.newUserId)
      .subscribe(
        data => {
          if (data.statusCode == 100) {
            this.data = data.responseList[0];
            this.data.phoneNumber = data.responseList[0].phoneNumber ? data.responseList[0].phoneNumber : ""
            this.data.alternateNumber = data.responseList[0].alternateNumber ? data.responseList[0].alternateNumber : ""
            // Map the object value
            data.responseList[0].clients.map((val, key) => {
              // Get the value from object and push into the array for show client drop down list
              Object.keys(val).forEach(key => {
                // Check if value is already present in the array
                var exist = this.ClientIdArr.indexOf(val['clientId'].toString());
                if (exist == '-1') {
                  this.ClientIdArr.push(val['clientId'].toString());
                }
              });
            })

          } else { this.alertService.sweetMessage('error', data.responseMessage); }
        },
        error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
  }



}
