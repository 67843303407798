import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StructureModule }  from './structure/structure.module';
import { LoginModule }  from './structure/login/login.module';
import { ForgotPasswordComponent } from './structure/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './structure/reset-password/reset-password.component';
import { EventAckComponent } from './structure/event-ack/event-ack.component';
// import { CallInstructionComponent } from './structure/admin/section/Call-Center-User/call-instruction/call-instruction.component';
import { UserActiveComponent } from './structure/user-activation/user-activation.component';
import { ApiService, DataService } from './services/index';
import { AlertService } from './services/index';
import { PopupService } from './services/PopupAlert.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';

import { CallInstructionEmailComponent } from './structure/call-instruction-email/call-instruction-email.component';
import { EventResolve } from './services/event.resolve.service';

@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    EventAckComponent,
    UserActiveComponent,
    CallInstructionEmailComponent
    // CallInstructionComponent
  ],
  imports: [
    StructureModule,
    LoginModule,
    BrowserModule,
    HttpModule,
	  FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoadingBarHttpModule
  ],
    providers: [
    ApiService,
    AlertService,
    CookieService,
    PopupService,
    EventResolve,
    DataService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
