import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
clientIsLive : any = localStorage.getItem("clientIsLive");
userId: any = localStorage.getItem("userId");
assetDatalist: any = [];
assetArray     : any = {'assets' : []};
dataTable: any;
totalNotLiveAssets : any = '';
clientdetailId: any = 0;
clientAssetList : any = [];
// clientName : any = localStorage.getItem("clientName");
clientName : any = localStorage.getItem("ClientInfoName");


selectAllCheckBox: boolean = false;

  tempAssetList: any = [];
  selectedItems: any = [];
  dropdownList: any = [];
  dropdownSettings: any = {
    singleSelection: false,
    idField: 'tierId',
    textField: 'tierName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };
  isClientLive = false;
  breadcrumbObj: any;
  HistoryList: any = [];
  assetUserList: any = [];

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }

ngOnInit() {
  this.route.params.subscribe(params => {
      if(params['id']){
      this.clientdetailId = params['id'];
    }
  });
  if(this.clientdetailId){
    this.getAssetListbyClient();
  }
  this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
}

// setAssetArray(val){
//     var Object = {
//     "assetId" : val
//   }
  
  // var index = this.assetArray.assets.findIndex(function(person) {
  //   return val.assetId == "val";
  // }

  //var exist = this.assetArray.assets.indexOf(item.assetId);
  //if(exist == '-1'){
      // this.assetArray.assets.push(Object);
    //}
    //console.log(this.assetArray);
// }

 // Setting Array for asset to make it live
 setAssetArray(val, event) {
  console.log(val, "=============================val++++++++++++event=========================", event)
  if (event.target.checked) {
    var Object1 = {
      "assetId": val
    }

    //var exist = this.assetArray.indexOf(item.assetId);
    //if(exist == '-1'){
    this.assetArray.assets.push(Object1);
    //}
  } else {
    let IndeXVal = -1
    for (let index = 0; index < this.assetArray.assets.length; index++) {
      const element = this.assetArray.assets[index];
      if (element.assetId == val) {
        IndeXVal = index
        break;
      }
    }
    if (IndeXVal > -1) {
      this.assetArray.assets.splice(IndeXVal, 1)
    }

  }

  // console.log(this.assetArray.assets);

}

assetsOk(){
  $('#closemodalAssets').click();
  $('#shwAssetsMsg').html('');
  let self = this;
  setTimeout(function(){
    self.getAssetListbyClient();
  }, 500);
  this.alertService.sweetMessage('success','Assets are marked live successfully');
}

assetsGoLive(){
  console.log("newobj================", this.assetArray)
  this.assetArray.clientId = this.clientdetailId;
  this.apiService.update('asset/makeAssetGoLive', this.assetArray).subscribe(
     data => {
          if(data.statusCode == 100){  
            let flag = 1;
            let response = data.responseList;
            Object.keys(response).forEach(key => {
              let curAssetId = response[key]['assetId'];
              let curIsLive = response[key]['islive'];
              if(curIsLive == 'no') {
                flag = 0;
                // $('#'+curAssetId).parent('tr').css('background', '#e8b8bf');
                $('.'+curAssetId).css('background', '#e8b8bf');
              }
            });
            if(flag == 1) {
              // $('#shwAssetMsgSucc').css('display', 'block');
              $('#shwAssetMsg').css('display', 'none');
              this.assetsOk();
            } else {
              $('#shwAssetMsg').css('display', 'block');
              $('#shwAssetMsgSucc').css('display', 'none');
            }
            // $('.assetOkBtn').css('display', 'block');
            // $('.assetLiveBtn').css('display', 'none');
          }else{
             this.alertService.sweetMessage('error', data.responseMessage);  
          }
        },
       error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
     );
}

getAssetsOfClient(){ 
  let totalNotLiveAssets = this.totalNotLiveAssets;
  console.log("=================");
  
  if(totalNotLiveAssets != ''){
    // var URL = 'asset/getAssetByClient?clientId='+this.clientdetailId+'&userId='+this.userId;
    this.tempAssetList = [];
    // var URL = 'asset/getAssetByClient?clientId='+this.clientdetailId;
    var URL = 'asset/getAssetByClient';
    let reqObj:any = {
      "clientId": this.clientdetailId,
      "isAssetLive": false
    
     }
     this.apiService.create(URL, reqObj).subscribe(
      data => {
        if(data.statusCode == 100){
         $('.assets').click();         
          this.clientAssetList = data.responseList;               
          this.clientAssetList.map((obj, index) => {
            obj.isSelected = obj.isAssetLive;
            this.tempAssetList.push(obj);
          })
          this.chRef.detectChanges();
          const table : any = $('#asset_table');
          this.dataTable = table.DataTable();  

          this.getTierbyClient(this.clientdetailId);
           

        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
   }  
}

  /* Function for getting AssetList by Client */
  getAssetListbyClient(){    
    if ($.fn.DataTable.isDataTable('#data_table_client_asset')){
      $('#data_table_client_asset').DataTable().destroy();
    };
    this.assetDatalist = [];
    let reqObj:any = {
      "clientId": this.clientdetailId,
      // "isAssetLive": true
    
     }
    this.apiService.create('asset/getAssetByClient', reqObj).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetDatalist = data.responseList;
          this.isClientLive = data.isClientLive;
          $('#nonLiveAssetsCount').addClass('has-non-live-assets');         
          this.totalNotLiveAssets = data.totalNotLiveAssets;
          this.chRef.detectChanges();
          if ($.fn.DataTable.isDataTable('#data_table_client_asset')){
            $('#data_table_client_asset').DataTable().destroy();
          };
          setTimeout(() => {
            const table: any = $('#data_table_client_asset');
            this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [9,10,11] }]});     
          }, 500);
          
        }else{
          $('#nonLiveAssetsCount').addClass('zero-non-live-assets');
          this.assetDatalist = data.responseList;
          this.chRef.detectChanges();
          if ($.fn.DataTable.isDataTable('#data_table_client_asset')){
            $('#data_table_client_asset').DataTable().destroy();
          };
          setTimeout(() => {
            const table: any = $('#data_table_client_asset');
            this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [9,10,11] }]});     
          }, 500);
  
          /*Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })*/
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  

  getTierbyClient(clientId) {
    this.apiService.getAll("tier/getTiersByClient?clientId=" + clientId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.dropdownList = data.responseList;
          this.selectedItems = JSON.parse(JSON.stringify(this.dropdownList))
          // let arr = [];
          // this.dropdownList.map((obj,index)=>{
          //   let tempObj = {
          //     tierId:
          //   }
          // })
          console.log(data, "data=====================")
        } else {
          this.dropdownList = [];
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );

  }

  onItemSelect(event) {
    console.log("===========on item select=============", this.selectedItems)
    setTimeout(() => {
      this.selectedItems.map((obj, index) => {
        this.clientAssetList.map((items) => {
          if (obj.tierId == items.tier.tierId) {
            var exist = _.findIndex(this.tempAssetList, function (o) { return o.assetId == items.assetId; });
            // var exist = this.tempAssetList.indexOf(items.tier.tierId);
            console.log(exist);

            if (exist == -1) {
              items.isSelected = items.isAssetLive
              this.tempAssetList.push(items);
            }
          }
        })
      })
      if(this.selectAllCheckBox) {
        this.selectAll({ target: { checked: true } });
      }
    }, 500);
  }


  onItemDeselect(item) {
    this.clientAssetList.map((items) => {
      if (item.tierId == items.tier.tierId) {

        var exist = _.findIndex(this.tempAssetList, function (o) { return o.tier.tierId == items.tier.tierId; });
        console.log(exist);
        if (exist != -1) {
          this.tempAssetList.splice(exist, 1);
        }

      }
    })
    setTimeout(() => {
      let assetIdArr = [];
      this.assetArray.assets.map((obj,index)=>{
        var exist = _.findIndex(this.tempAssetList, function (o) { return o.assetId == obj.assetId; });  
        console.log(exist ,"----------------======================");
         
        if (exist != -1) {
          assetIdArr.push(obj)
          console.log(assetIdArr);
          
        }
      })
      this.assetArray.assets = assetIdArr;
      console.log(this.assetArray.assets);
      
    }, 1000);

  }

  selectAll(event) {
    if (event.target.checked) {
      setTimeout(() => {        
        this.tempAssetList.map((item, index) => {
          if (!item.isAssetLive /* && item.totalUser != 0 */) {
            this.tempAssetList[index].isSelected = true;
            var exist = _.findIndex(this.assetArray.assets, function (o) { return o.assetId == item.assetId; });
            if (exist == -1) {
              var Object1 = {
                "assetId": item.assetId
              }
              console.log("item===============",item)
              this.assetArray.assets.push(Object1);
            }
          }
        })
      }, 100);
    } else {
      this.tempAssetList.map((item) => {
        if (!item.isAssetLive) {
          item.isSelected = false;
          let IndeXVal = -1
          for (let index = 0; index < this.assetArray.assets.length; index++) {
            const element = this.assetArray.assets[index];
            if (element.assetId == item.assetId) {
              IndeXVal = index
              break;
            }
          }
          if (IndeXVal > -1) {
            this.assetArray.assets.splice(IndeXVal, 1)
          }
        }
      })
    }
  }



  onSelectAll(event) {

  }

  onDeSelectAll() {
    this.tempAssetList = [];
    this.assetArray.assets = []
  }

  checkChange(event) {
    if (!event.target.checked) { 
      this.selectAllCheckBox = false;      
    }    
  }

getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('asset/getAssetHistoryByAsset?assetId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}
setList(users){
  this.assetUserList = users;
}
DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}
}
