import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-clientmenu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.css']
})
export class ClientMenuComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
clientdetailId: any = 0;
clientName : any = localStorage.getItem("clientName");
  breadcrumbObj: any;

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService ) { }


 ngOnInit() {
  this.route.params.subscribe(params => {
      if(params['id']){
      this.clientdetailId = params['id'];
    }
  });
  this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })

}
}
