import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService, CommonServicesService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.css']
})
export class UnitsComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  assetId : any = 0;
  userassetList:any=[];
  roleList:any=[];
  UserList:any=[];

  assetsumaryData:any =[];
  UserToAsset:any = {
    "createdTime":"",
    "modifiedTime":"",
    "creator":"",
    "lastModifier":"",
    "user":{
      "userId":""
    },
    "asset":{
      "assetId":''
    },
    "assetRole":{
      "assetRoleId":""

    }
  }

  AssetUserUpdate:any = {
    "createdTime":"",
    "modifiedTime":"",
    "creator":"",
    "lastModifier":"",
    "user":{
      "userId":""
    },
    "asset":{
      "assetId":''
    },
    "assetRole":{
      "assetRoleId":""

    }
  }
  numbers = [];
  total:any = 0
  siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  clientCode : any = '';
  permissionData:any=[];
  breadcrumbObj: any;
  urlCustomerId: any;
  CdName: any;
  HistoryList: any[];

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private alertService: AlertService,
    private formatDateService: FormatDateService,
    private CommonServicesService: CommonServicesService,
    private chRef: ChangeDetectorRef
    ) {
    // this.permissionData = ApiService.permission;
    this.subscription = this.CommonServicesService.getClientId()
    .subscribe(
      message => {
        this.clientFilterId = message.id;
        if (this.clientFilterId!='0' && this.clientFilterId!=null) {
          this.route.params.subscribe(params => {
            if(params['id']){
              this.assetId = params['id'];
            }
            if(params['clientId']){
              this.clientIdURL = params['clientId'];
            }
          });
        }else{
          this.route.params.subscribe(params => {
            if(params['id']){
              this.assetId = params['id'];
            }
            if(params['clientId']){
              this.clientIdURL = params['clientId'];
              this.clientFilterId = this.clientIdURL;
            }
          });
        }
      }
    );
  }
  // Function to format Date
  DateFormat(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }


  // permissionData:any=[];
  clientIdURL: any = '';
  subscription: Subscription;
  clientFilterId : any = '';
  clientName : any = localStorage.getItem("clientName");
  clientTopDropdownValue : any;


  ngOnInit(){
    this.clientCode = this.alertService.getClientCode();
    // this.route.params.subscribe(params => {
    //   if(params['id']){
    //     this.assetId = params['id'];
    //   }
    //   if(params['clientId']){
    //     this.clientIdURL = params['clientId'];
    //   }
    // });
    if(this.assetId){
      // this.getUserByAsset();
      this.getAssetSummaryData();
      this.getUnitsByAsset();
    }
    // this.getRoleList();
    // this.getUserList();
    this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        this.urlCustomerId = params.cus_id;
    })
  }

  // Function to set latitude and longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;
  }

  // Listen For event Recive from child
    reciveAsset(){
      this.getAssetSummaryData();
    }


  /* Function for getting Asset Summary Data */
  getAssetSummaryData(){
    let clientIdURL = this.clientIdURL;
    let cid = '';
    if(clientIdURL != ''){
      cid = 'clientId='+this.clientIdURL+'&';
    }
    let URL = 'asset/getAdminAssetSummaryById?'+cid+'assetId='+this.assetId+'&userId='+this.userId;
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData= data.response;
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Get User by Asset list */
  getUserByAsset(){
    let clientIdURL = this.clientIdURL;
    let cid = '';
    if(clientIdURL != ''){
      cid = 'clientId='+this.clientIdURL+'&';
    }
    let URL = 'user/getUserByAsset?'+cid+'assetId='+this.assetId+'&userId='+this.userId;
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.userassetList = data.userList;
          this.userassetList = data.userList;
          this.total = this.userassetList.length + 2;
          this.numbers = [];
            for (var i = 1; i < this.total; i++) {
                this.numbers.push(i);
                //visit(children[i], visitFn, childrenFn);
            }
        }else{
          this.userassetList = [];
          this.numbers = ['1'];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Get role list */
  getRoleList(){
    this.apiService.getAll('assetRole/getAllAssetRole').subscribe(
      data => {
        if(data.statusCode == 100){
          this.roleList = data.responseList;
        }else{
          this.roleList =[];

          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Get User list */
  getUserList(){
    // this.clientTopDropdownValue = $('#client_dropdown_top').children("option:selected").val();
    // let cid = '';
    // if(this.clientFilterId != null && this.clientFilterId !=0){
    //   cid = this.clientFilterId;
    // } else {
    //   cid = this.clientTopDropdownValue;
    // }

    // as client dropdown is disabled so new client id (from url) send in api request
    let clientIdURL = this.clientIdURL;
    let cid = '';
    if(clientIdURL != ''){
      cid = this.clientIdURL;
    }

    // this.apiService.getAll('user/getUserByTier?clientId='+cid+'&assetId='+this.assetId+'&isAssetTeam=true').subscribe(
      this.apiService.getAll('user/getUserByTierWithCustomerUser?assetId='+this.assetId+"&isActive=true&clientId="+this.clientId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.UserList = data.userList;
        }else{
          this.UserList =[];
          //this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Add User by Asset  */
  AddUserByAsset(){
    this.UserToAsset.asset.assetId = this.assetId;
    this.apiService.create('assetUser/addUserToAsset', this.UserToAsset).subscribe(
      data => {
        if(data.statusCode == 100){

          this.alertService.sweetMessage('success','Asset User Added Successfully');
          this.getUserByAsset();
          this.UserToAsset = {"createdTime":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
          this.UserToAsset = {"createdTime":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Delete User */
  DeleteUser(assetdata){
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Asset User.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('assetUser/deleteAssetUser?assetId='+this.assetId+'&userId='+assetdata.userId,'').subscribe(
          data => {
            if(data.statusCode == 100){

              this.alertService.sweetMessage('success','Asset User Deleted Successfully');
              this.getUserByAsset();
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
          );
      }
    })
  }

  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('assetUser/getAssetUserHistoryByAssetAndUser?assetId='+this.assetId+'&userId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }
  getUnitsByAsset(){
    this.apiService.getAll('asset/getUnitByAssetId?assetId='+this.assetId).subscribe(
      data => {

        if(data.statusCode == 100){
          this.userassetList = data.responseList;
          if($.fn.DataTable.isDataTable('#unit_data_table')){
            $('#unit_data_table').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#unit_data_table');
           table.DataTable();
        }else{
          this.userassetList = [];
          this.numbers = ['1'];

          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }
}
