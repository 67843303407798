import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
LicenseDatalist: any = [];
dataTable: any;
clientdetailId: any = 0;
clientName : any = localStorage.getItem("clientName");

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }


  ngOnInit() {
  this.route.params.subscribe(params => {
      if(params['id']){
      this.clientdetailId = params['id'];
    }

  });
  if(this.clientdetailId){
    this.getLicenseListByClient();
  }  
}
 /* Get license  list */
getLicenseListByClient(){
   this.apiService.getAll('license/getLicenseListByClient?clientId='+this.clientdetailId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.LicenseDatalist = data.responseList; 
          this.chRef.detectChanges();         
           const table: any = $('table');
          this.dataTable = table.DataTable();
        }else{
        
          this.chRef.detectChanges();         
           const table: any = $('table');
          this.dataTable = table.DataTable();
       
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }             
        );
}

}
