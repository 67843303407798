import { Component, OnInit ,ChangeDetectorRef,ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService , AlertService, CommonServicesService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import * as _ from "lodash";
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { environment } from "../../../../../../environments/environment"
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AddUserComponent implements OnInit {
  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('addUserForm') addUserForm: any;
  @ViewChild('imageUrlName') imageUrlName: ElementRef;

userData: any = JSON.parse(localStorage.getItem("clientUser"));
loginUserType: any = this.userData ?  this.userData.userType : '';
clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
tiersId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
newUserId:number = 0;
roleList: any = [];
roles : any = [];
customerList:any=[];
tierList:any=[];
tierListData: any = [];
checkCustomerArray:any=[];
commMode:number=0;
dataAdapter: any = [];
source: any = {};
dropdownSettings:any={};
data: any = [];
tierArray : any=[];
selectedItems:any=[];
customerArray:any=[];
records: any = [];
checkTreeArray: any = [];
disableTreeArray:any=[];
roleIds: any = [];
ClientIdArr:any = [];
clientArrayList : any = [];
isClientDisable:boolean=false;
isGlobalUserDisable:boolean=false;


user: any = {
  "title":"",
  "firstName":"",
  "middleName":"",
  "lastName":"",
  "phoneCarrier":"Verizon",
  "note":"",
  "login":"",
  "password":"",
  "conpassword":"",
  "phonePin":"",
  "isActive":true,
  "isAppUser":false,
  "communicationMode":"",
  "userType":"AMCi",
  "clients":{"clientId":null},
  "emailId":"",
  "phoneNumber":"",
  "customers":[],
  "secondaryPhoneNumber":"",
  "secondaryPhoneCarrier":"",
  "isGlobalUser" : false,
};

comm: any = {"Push":false,"Email":false,"SMS":false,"IVRS":false,"Call":false};
clientDataList:any = [];
clientIds:any= []
checkCommMode:boolean;
dropdownSettingsClient = {
  singleSelection: false,
  idField: 'clientId',
  textField: 'description',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 0,
  allowSearchFilter: true
};
permissionData  : any = {};

passwordError : any = 'Password is required';


PassInfo   : any = `    1. Password must be at least 8 characters.
2. Password must contain at least 1 numeric character.
3. Password must contain at least 1 uppercase character.
4. Password must contain at least 1 special character !@#/\$%^&*().
5. Password cannot contain spaces.`;

PassInfoToolTip  : any = `
1. Password must be at least 8 characters.
2. Password must contain at least 1 numeric character.
3. Password must contain at least 1 uppercase character.
4. Password must contain at least 1 special character !@#/\$%^&*().
5. Password cannot contain spaces.`;

passwordPattern:boolean = false;
roleError = '';

emailDomain = ''
showGlobalUser = false;
phoneCarrierList    : any = [];
clientName : any = localStorage.getItem("clientName");
imageUrl   : any = "assets/img/upload-img.png";
imageName  : any = {};
imageTitle = "";
imageError = "";
subscription: Subscription;

constructor(private router:Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService,  private CommonServicesService: CommonServicesService) {
  this.permissionData = JSON.parse(localStorage.getItem("permission"));
  this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
    .subscribe(
      message => {
        setTimeout(() => {
          this.clientName = localStorage.getItem("clientName");
        }, 1000);
      }
    );

}

ngOnDestroy() {
}

ngOnInit() {
  // this.getCustomerList();
  // this.getTierList();
  let amciEmailDomain = localStorage.getItem("clientUser") ? JSON.parse(localStorage.getItem("clientUser")).amciEmailDomain : '' 
  this.emailDomain = amciEmailDomain
  this.getClientDrodownlist();
  this.getPhoneCarrierList();
    this.route.params.subscribe(params => {
     if(params['id']){
      this.newUserId = params['id'];
    }
  });
  if(this.newUserId){
    // this.getUserById();
  }else {
  this.getRoleList('AMCi');
  }
  this.dropdownSettings = {
      singleSelection: false,
      idField: 'customerId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
}

/* Handle tier tree chacked unchecked Event */
CheckChange(event){
  if(event.args.checked == true){
    var exist = this.checkTreeArray.indexOf(event.args.element.id);
    if(exist == '-1'){
        this.checkTreeArray.push(event.args.element.id);
      }
  }else if(event.args.checked == false){
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if(exist != '-1'){
        this.checkTreeArray.splice(exist,1);
      }
  }
}

/* On Item Select Customer */
onItemSelect(item: any) {
    var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
    if(exist == '-1'){
        this.checkCustomerArray.push(item.customerId.toString());
      }
  }

/* On Item DeSelect Customer */
onItemDeselect(item: any){
  var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
    if(exist != '-1'){
        this.checkCustomerArray.splice(exist,1);
      }
}
/* On All Select Customer */
onSelectAll(items: any) {
  this.checkCustomerArray = [];
  Object.keys(items).forEach(key=> {
      this.checkCustomerArray.push(items[key]['customerId'].toString());
    });
}
/* On All DeSelect Customer */
onDeSelectAll(){
  this.checkCustomerArray = [];
}
/* Create Tier array */
createTierArray(){
  Object.keys(this.checkTreeArray).forEach(key=> {
    if(this.checkTreeArray[key] != 0){
        this.tierArray.push({"tierId":this.checkTreeArray[key]});
      }
    });
  this.user.tiers = this.tierArray
};
/* Create Tier array */
createCustomerArray(){
  Object.keys(this.checkCustomerArray).forEach(key=> {
    if(this.checkCustomerArray[key] != 0){
        this.customerArray.push({"customerId":this.checkCustomerArray[key]});
      }
    });
  this.user.customers = this.customerArray
};
/* check tree checkbox */
checkedCheckboxOnLoad(){
   Object.keys(this.checkTreeArray).forEach(key=> {
    let checkedItem = document.getElementById(this.checkTreeArray[key]);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem,true);
  });
}

checkItemCommunication(){
  this.commMode = this.user.communicationMode;
  var number = this.user.communicationMode;
  // if((number - 16) >= 0){
  //   number = number - 16;
  //   this.comm.Call = true;

  // }
  if((number - 8) >= 0){
    number = number - 8;
    this.comm.IVRS = true;
  }
  if((number - 4) >= 0){
    number = number - 4;
    this.comm.Call = true;
  }
  if((number - 2) >= 0){
    number = number - 2;
    this.comm.SMS = true;
  }
  if((number - 1) >= 0){
    number = number - 1;
    this.comm.Email = true;
  }
}

/* Create communication logic */
getCommMode(e){
  this.checkCommMode = false;
  if(e.target.name == 'Email'){
    if(e.target.checked){
      this.commMode = this.commMode + 1;
    }else{
      this.commMode = this.commMode - 1;
    }
  }else if(e.target.name == 'SMS'){
    if(e.target.checked){
      this.commMode = this.commMode + 2;
    }else{
      this.commMode = this.commMode - 2;
    }
  }else if(e.target.name == 'Call'){
    if(e.target.checked){
      this.commMode = this.commMode + 4;
    }else{
      this.commMode = this.commMode - 4;
    }
  }
  else if(e.target.name == 'IVRS'){
    if(e.target.checked){
      this.commMode = this.commMode + 8;
    }else{
      this.commMode = this.commMode - 8;
    }
  }
  // else{
  //   if(e.target.checked){
  //     this.commMode = this.commMode + 16;
  //   }else{
  //     this.commMode = this.commMode - 16;
  //   }
  // }
  this.user.communicationMode = this.commMode;
}

/* Create role array */
updateRoleOptions(rlist, event) {
  if(event.target.checked == true){
    this.roles.push({"roleId":rlist.roleId});
    this.roleError = ''
  }else{
       for(var i=0 ; i < this.roles.length; i++) {
    if(this.roles[i].roleId == rlist.roleId){
       this.roles.splice(i,1);
      }
    }
  }
  this.user.roles = this.roles;
  if(this.roles.length == 0){
    this.roleError = 'Please select role'
  }
}

treeItemDisable(){
  // Object.keys(this.disableTreeArray).forEach(key=> {
  //   let checkedItem = document.getElementById(this.disableTreeArray[key]);
  //       this.myTree.expandItem(checkedItem);
  //       this.myTree.disableItem(checkedItem);
  // });
}

/* Get Phone Carrier List */
getPhoneCarrierList(){
   this.apiService.getAll('user/getPhoneCarrierList').subscribe(
    data => {
       if(data.statusCode == 100){
          this.phoneCarrierList = data.responseList;
          this.phoneCarrierList.forEach(key=> {
            if(key == 'Verizon') {
              setTimeout(function(){
                $('.'+key).attr('selected', 'true');  
              }, 500);              
            }
          });
        }else{
          this.phoneCarrierList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
     }
  );
}

 /* Get tier list */
getTierList(){
  this.apiService.getAll('tier/getAllTierByUser?clientId='+this.clientId+'&userId='+this.userId).subscribe(
    data => {
        if(data.statusCode == 100){
            this.tierListData = data.responseList;
            this.data = this.tierListData;
            Object.keys(this.data).forEach(key=> {
              if(this.data[key]['flag'] == false){
                this.disableTreeArray.push(this.data[key]['tierId']);
              }
            });
            this.source = {
                datatype: 'json',
                datafields: [
                    { name: 'tierId' },
                    { name: 'parentTierId' },
                    { name: 'tierName' },
                ],
                id: 'tierId',
                localdata: this.data
            };
            this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
            this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' },{ name: 'tierId', map: 'id'}]);
              var self = this;
              setTimeout(function(){
                self.treeItemDisable();
              },1000);
            }else{
            Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
                }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    getClientDrodownlist(){
      this.apiService.getAll('client/getClientDropDownList').subscribe(
        data => {
            if(data.statusCode == 100){
                this.clientDataList = data.responseList;
            }else{
                Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
                    }
                    if(this.newUserId){
                      this.getUserById();
                    }

                },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
    }


    /* On Item Select Site */
  onItemSelectClient(item: any) {
    var exist = this.ClientIdArr.indexOf(item.clientId.toString());
    if(exist == '-1'){
      this.ClientIdArr.push(item.clientId.toString());
    }
  }

  /* On Item DeSelect Site */
  onItemDeselectClient(item: any){
      var exist = this.ClientIdArr.indexOf(item.clientId.toString());
    if(exist != '-1'){
        this.ClientIdArr.splice(exist,1);
      }
  }

  /* On All Select Site */
  onSelectAllClient(items: any) {
    this.ClientIdArr = [];
    Object.keys(items).forEach(key=> {
        this.ClientIdArr.push(items[key]['clientId'].toString());
    });
  }

  /* On All DeSelect Site */
  onDeSelectAllClient(){
    this.ClientIdArr = [];
    // this.getAllAssetList();
  }

  hideError () {
    $("#passerror").css("display", "none");
  }

  clearError() {
    $("#Uppasserror").css("display", "none");
  }


  passwordChangeEvent(obj){
    var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
    if(obj && obj.length>7 && regx.test(obj) == false )  {
      this.passwordPattern = true;
    }else{
      this.passwordPattern = false;
    }
  }
/* Add user */
addUser(data){
  // this.createTierArray();
  // this.createCustomerArray();  
  var _this = this;
  this.ClientIdArr.map((val,key)=>{
    _this.clientArrayList.push({'clientId':val});
  })

  if(this.comm.Email || this.comm.SMS){
    this.checkCommMode = false;
  }else{
    this.checkCommMode = true;
  }
  if(!data.form.valid && (this.user.phoneNumber == this.user.secondaryPhoneNumber)) {
    document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });    
  }
  if(this.roles.length <= 0){
    this.roleError = 'Please select role'
    document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
    return;
  }else{
    this.roleError = '';
  }

  // var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
  // var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
  // if(this.user.password && regx.test(this.user.password) == false && this.newUserId == 0) {
  //   this.passwordPattern = true;
  // }else{
  //   this.passwordPattern = false;
  // }

    // if (this.addUserForm.valid && this.user.conpassword == this.user.password) {
    if(this.addUserForm.valid && !this.checkCommMode && (this.user.phoneNumber != this.user.secondaryPhoneNumber)){
      if (this.user.communicationMode ) {
          // if (this.ClientIdArr.length>0) {
            // this.user.clients = this.clientArrayList;
            let phoneCarrierVal = $("#phoneCarrier option:selected").val();
            this.user.phoneCarrier = phoneCarrierVal;
            if(this.newUserId == 0){
              // delete this.user.conpassword
              // this.user.clients = client;
              this.user.clients = this.clientArrayList;
              this.apiService.create('user/registerUser', this.user).subscribe(
                data => {
                    this.clientArrayList = [];
                    if(data.statusCode == 100){
                      this.imageSave(data.user.userId);
                      // if(this.imageName){
                      //   if(this.imageName.length){
                      //     this.imageSave(data.user.userId);
                      //   }
                      // }else{
                      //   this.router.navigate(['/user-management']);
                      // }
                      Swal({
                        position: 'top-end',
                        type: 'success',
                        title: 'User added Successfully',
                        showConfirmButton: false,
                        timer: 1500
                      });

                      this.router.navigate(['/user-management']);
                    }else{
                        Swal({
                            position: 'top-end',
                            type: 'error',
                            title: data.responseMessage,
                            showConfirmButton: false,
                            timer: 1500
                          })
                        }
                      },
                error => {
                  this.clientArrayList = [];
                  this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                    }
                  );
              }else{
                // delete this.user.conpassword
                this.user.clients = this.clientArrayList;
                this.apiService.update('user/updateUser', this.user ).subscribe(
                    data => {
                      this.clientArrayList = [];
                      if(data.statusCode == 100){
                        this.imageSave(this.newUserId);
                        // Swal({
                        //   position: 'top-end',
                        //   type: 'success',
                        //   title: 'User update Successfully',
                        //   showConfirmButton: false,
                        //   timer: 1500
                        // })
                        // this.router.navigate(['/user-management']);
                        // if(this.imageName){
                        //   if(this.imageName.length){
                        //     this.imageSave(this.newUserId);
                        //   }else{
                        //     if(this.userId == this.user.userId){
                        //       console.log("admin and login user")
                        //       this.getUserInfo();
                        //     }else{
                        //       console.log("not a login user")
                        //       this.router.navigate(['/user-management']);
                        //     }
                        //   }
                        // }else{
                        //   if(this.userId == this.user.userId){
                        //     this.getUserInfo();
                        //   }else{
                        //     this.router.navigate(['/user-management']);
                        //   }
                        // }
                        Swal({
                          position: 'top-end',
                          type: 'success',
                          title: 'User updated Successfully',
                          showConfirmButton: false,
                          timer: 1500
                        })
                      }else{
                        Swal({
                          position: 'top-end',
                          type: 'error',
                          title: data.responseMessage,
                          showConfirmButton: false,
                          timer: 1500
                        })
                      }
                    },
                    error => {
                      this.clientArrayList = [];
                      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                    }
                  );
            }
          } else {
          Swal({
              position: 'top-end',
              type: 'error',
              title: 'please select communication mode.',
              showConfirmButton: false,
              timer: 1500
            })
        }
    }
    else {
      this.clientArrayList = [];
    }
  }

/* Get Role list */
getRoleList(type){
  // var URL = 'role/getAllRoleList';
  var URL = 'role/getAllRoleByType?userType='+type;
  // var URL = 'role/getRoleListByClient?clientId='+this.clientId+'&userId='+this.userId;
  // if(this.customerId != null && this.customerId !=''){
  //   var customerString     = '&customerId='+this.customerId.toString();
  //   var URL = URL.concat(customerString);
  // }
  // if(this.tiersId != null && this.tiersId !=''){
  //   var tierString     = '&tierId='+this.tiersId.toString();
  //   var URL = URL.concat(tierString);
  // }
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.roleList = data.responseList;
        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }


/* Get Role list */
getCustomerList(){
  var URL = 'customer/getCustomerListByClient?clientId='+this.clientId+'&userId='+this.userId;
   if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tiersId != null && this.tiersId !=''){
    var tierString = '&tierId='+this.tiersId.toString();
    var URL = URL.concat(tierString);
  }
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.customerList = data.responseList;
        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
      error => {
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }

getUserById(){
  this.apiService.getAll('user/getUserByUserId?userId='+this.newUserId).subscribe(
   data => {
       if(data.statusCode == 100){
        //  if(this.userId===data.user.userId){
          // this.getUserInfo();
        //  }

        if(data.user.isGlobalUser){
           this.isClientDisable=true;
           this.isGlobalUserDisable=true;
        }

          this.user = data.user;
          if(this.user.imageLocation != null){
            this.imageUrl = this.apiService.imageBasePath +this.user.imageLocation;
            let imageArr = this.user.imageLocation.split('/');
            this.imageTitle = imageArr[1];
          }
          
          if(this.user.secondaryPhoneCarrier===null){
            this.user.secondaryPhoneCarrier="";
          }

          if(this.user.phoneCarrier===null){
            this.user.phoneCarrier="Verizon";
          }

          if (this.user.userType) {
            this.getRoleList(this.user.userType);
          }

          this.checkItemCommunication();
          Object.keys(this.user.tiers).forEach(key=> {
            this.checkTreeArray.push(this.user.tiers[key]['tierId'].toString());
          });

          var that = this;
          setTimeout(function(){
            that.checkedCheckboxOnLoad();
          },2000);

          this.selectedItems = this.user.customers;
          if(this.user.customers.length){
            Object.keys(this.user.customers).forEach(key=> {
              this.checkCustomerArray.push(this.user.customers[key]['customerId'].toString());
            });
          }

          if(this.user.clients.length){
            Object.keys(this.user.clients).forEach(key=> {
              this.ClientIdArr.push(this.user.clients[key]['clientId'].toString());
            });  
          }
          


              this.roles = data.user.roles;

              this.roleIds = _.map(this.user.roles, 'roleId');
              this.clientIds = this.user.clients

              let id = [];
              this.user.clients.map((obj,index)=>{
                id.push(obj.clientId.toString())
              })
              this.ClientIdArr = id

              // let objArr = []
              // this.user.clients.map((obj,index)=>{
              //     let objectData = _.find(this.clientDataList, function(obj2) {
              //         return obj.clientId == obj2.clientId;
              //     })
              //     if (objectData) {
              //       objArr.push({clientId:objectData.clientId, description:objectData.description,/* isDisabled:null  */})      
              //     }
              //   }
              // )

              // this.clientIds = objArr;
            

                    var self = this;
                    _.map(this.user.roleIds, function(value, key){
                        self.user.roleIds.push({roleId:value.id});
                      });
                      this.changeEmail()


        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }


  changeEmail(){
        setTimeout(() => {
          let domain = this.user.login ? ( this.user.login.indexOf('@')>=0 ? this.user.login.split('@') : this.user.login.split('@')) : this.user.login.split('@')
          if (domain.length > 0) {
            if (domain[1] && domain[1] == this.emailDomain) {
              
              this.showGlobalUser = true;
            }else{
              this.showGlobalUser = false;
            }
          }else{
            this.showGlobalUser = false;
          }
        }, 200);
  }

/***************************
 Upload Image On Change
**************************/
onFileChanged(event) {
  this.imageTitle = "";
  this.imageError = "";
  const file = event.target.files;
  const fileType = file[0]['type'];
  const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  // invalid file type code goes here.
  if (!validImageTypes.includes(fileType)) {
      this.imageUrl = "assets/img/upload-img.png";
      this.imageName = null;
      this.imageTitle = "";
      this.imageError = "Please select valid image type such as jpg, jpeg, png.";return;
  }

  if(this.imageError==""){
    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (_event: any) => {
     var img = new Image();
     img.src = _event.target.result;
     img.onload = () => {
      if( img.width <= 300 && img.height <= 300 ) {
        this.imageUrl = reader.result as string;
        this.imageName = file;
        this.imageTitle = file[0]['name'];
      }
      else {
        this.imageUrl = "assets/img/upload-img.png";
        this.imageName = null;
        this.imageTitle = "";
        this.imageError = "Image Size should be less than 300X300"
        return;
      }
     };
    }
  }
}

// function to save the image
imageSave(newUserId) {
  if(this.imageError==""){
    const uploadData = new FormData();
    if(this.imageName.length){
      uploadData.append('file', this.imageName[0], this.imageName[0].name);
      this.apiService.create('uploadImage/uploadImage?section=1&sectionId=' + newUserId, uploadData).subscribe(
          data => {
              if (data.statusCode == 100) {
                // if(this.userId == this.user.userId){
                //   this.getUserInfo();
                // }else{
                //   this.router.navigate(['/user-management']);
                // }
              } else {
                  this.alertService.sweetMessage('error',data.responseMessage);
              }
              this.getUserInfo();
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            if(this.userId == this.user.userId){
              this.getUserInfo();
            }
          }
      );
    }else{
      this.getUserInfo();
    }
  }else{
    this.getUserInfo();
  }
}



// Handel Global User
isGlobalUserChange(event){
  if(event.target.checked == true){
    this.isClientDisable = true;
    this.ClientIdArr = [];
    let clientArr = [];
    Object.keys(this.clientDataList).forEach(key=> {
      clientArr.push(this.clientDataList[key]['clientId'].toString());
    });
    this.ClientIdArr = clientArr;
    this.clientIds = clientArr;
  }
  else
  {
    this.ClientIdArr = [];
    this.clientIds = [];
    this.isClientDisable = false;
  }
}

getUserInfo(){
  this.apiService.getAllDashboard('user/getUserInfo').subscribe(
    data => {
      if(data.statusCode == 100){
        console.log("data.response in get user info----->", data.response);
        localStorage.setItem("clientUser", JSON.stringify(data.response));
        localStorage.setItem("userId", data.response.userId);
        localStorage.setItem("clientId", data.response.defaultClientId);
        localStorage.setItem("permission",JSON.stringify(data.response.permissions));
        var logdata = JSON.parse(localStorage.getItem("clientUser"));
      }else if(data.statusCode == 105){
        var msg = data.responseMessage;
      }else{
      }
      // this.location.back();
      if(this.loginUserType != 'CallCenter'){
        this.router.navigate(['/user-management']);
      }else{
        // this.location.back();
        this.router.navigate(['/callcenter/call-center-dashboard']);
      }
    },
    error => {
    }
  );
}

  removeInputImage() {
    this.imageUrlName.nativeElement.value = '';
    this.imageTitle = '';
  }

  // function for deleting image of branding 
  removeBrandingImage() {
    Swal({
        title: 'Are you sure?',
        text: 'You want to remove image.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
        if (result.value) {
            if(this.newUserId) {
                this.apiService.delete("uploadImage/removeImage?section=1&sectionId=" + this.newUserId, '').subscribe(
                    data => {
                        if (data.statusCode == 100) {
                            this.alertService.sweetMessage('success', 'Image removed successfully');
                            this.imageUrl = 'assets/img/upload-img.png'
                        } else {
                            this.alertService.sweetMessage('error', data.responseMessage);
                        }
                    },
                    error => {
                        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                    }
                );
            }else {
                this.alertService.sweetMessage('success', 'Image removed successfully');
                this.imageUrl = 'assets/img/upload-img.png'
            }
        }
    })
  }


}
