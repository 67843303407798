import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ViewComponent } from './view.component';
import { DashboardComponent } from './section/dashboard/dashboard.component';

/* tier */
import { TierComponent } from './section/tier/tier.component';
import { TiersComponent } from './section/tier/tiers/tiers.component';

/* client */
import { ClientComponent } from './section/client/client.component';
import { AddClientComponent } from './section/client/add-client/add-client.component';
import { AddClientdetailComponent } from './section/client/clientdetail/add-client/add-client.component';
// import { ClientUsersComponent } from './section/client/users/users.component';
/* menu client  */
import { ClientMenuComponent } from './section/client/client-menu/client-menu.component'
import { CustomerComponent } from './section/client/customer/customer.component'

/* Roles */
import { RolesComponent } from './section/client/roles/roles.component';
/* Tier */
import { clientTierComponent } from './section/client/tiers/tiers.component';

import { SitesComponent } from './section/client/sites/sites.component';
/* Package */
import { LicenseComponent } from './section/client/license/license.component';
/* Notification-plan */
import { NotificationPlanComponent } from './section/client/notification-plan/notification-plan.component';
/* Users */
import { UsersComponent } from './section/client/users/users.component';
import { AddUserComponent } from './section/user-management/adduser/adduser.component';
/* Role Management */
import { RoleManagementComponent } from './section/role-management/role-management.component';
import { AddRoleComponent } from './section/role-management/addrole/addrole.component';
/* Site Management */
import { SiteManagementComponent } from './section/site-management/site-management.component';
import { AddSiteComponent } from './section/site-management/add-site/add-site.component';
/* User Management */
import { UserManagementComponent } from './section/user-management/user-management.component';
/* Asset Management */
import { AssetManagementComponent } from './section/asset-management/asset-management.component';
/* Notification Plan Management */
import { NotificationPlanManagementComponent } from './section/notification-plan-management/notification-plan-management.component';
import { AddNotitficationComponent } from './section/notification-plan-management/add-notitfication/add-notitfication.component';
/* Support */
// import { SupportComponent } from './section/support/support.component';
/* Report */
import { ReportComponent } from './section/report/report.component';
/* Setting */
import { SettingComponent } from './section/setting/setting.component';
/* Assets */
import { AssetsComponent } from './section/client/assets/assets.component';


/* Shift  */
// import { ShiftManagementComponent } from './section/shift-management/shift-management.component';
// import { ShiftComponent } from './section/shift-management/shift/shift.component';
/* Scheduler  */
// import { SchedulerComponent } from './section/scheduler/scheduler.component';

/* Notification event  */
import { NotificationEventComponent } from './section/notification-event/notification-event.component';
/* Migration Tool */
import { MigrationToolComponent } from './section/migration/migration-tool.component';
import { TierMigrateComponent } from './section/migration/tier-migrate/tier-migrate.component';
import { SiteMigrateComponent } from './section/migration/site-migrate/site-migrate.component';
import { ClientMigrateComponent } from './section/migration/client-migrate/client-migrate.component';
import { UserMigrateComponent } from './section/migration/user-migrate/user-migrate.component';

import { AssetUserComponent } from './section/asset-summary/asset-user/asset-user.component';
// import { AssetUserComponent } from './section/asset-management/asset-user/asset-user.component';
import { LiveFeedComponent } from './section/live-feed/live-feed.component';
// import { ClientBillComponent } from './section/client-bill/client-bill.component';
import { ClientUserAddComponent } from './section/client/client-user-add/client-user-add.component';

// Component For Add product Type
// import { ProductTypeManagementComponent } from './section/product/product-type/product-type-management.component';

// Component For Add Product Type
// import { AddProductTypeComponent } from './section/product/add-product-type/AddProductType.component';

// Component For Add product Template Attribute
// import { AddProductTemplateAttributeComponent } from './section/product/add-product-template-attribute/AddProductTemplateAttribute.component';
// import { ProductTemplateAttributeComponent } from './section/product/add-product-template-attribute/product-template-attribute/product-template-attribute.component';

//Component for Product template
// import { ProductTemplateComponent } from './section/product/product-template/product-template/product-template.component';
// import { AddProductTemplateComponent } from './section/product/product-template/add-product-template/add-product-template.component';

// Create Product
// import { CreateProductComponent } from './section/product/create-product/create-product.component';

import { ClientRoleComponent } from './section/role-management/client-role/client-role.component';

import { GlobalSearchComponent } from './section/global-search/global-search.component';

import { ClientBrandingComponent } from './section/client/client-branding/client-branding.component';

// new modules
import { ClientRolesComponent } from './section/client/client-roles/client-roles.component';

import { ClientRolesAddComponent } from './section/client/client-roles/client-roles-add/client-roles-add.component';


// Asset Summary Section
import { AssetSummaryComponent } from './section/asset-summary/asset-summary.component';
import { AssetsummaryMenuComponent } from './section/asset-summary/assetsummary-menu/assetsummary-menu.component';
import { AssetEditComponent } from './section/asset-summary/asset-edit/asset-edit.component';
/* Asset Graph  */
import { AssetGraphComponent } from './section/asset-summary/asset-graph/asset-graph.component';
/* Modem Traffic  */
import { ModemTrafficComponent } from './section/asset-summary/modem-traffic/modem-traffic.component';
/* Comments  */
// import { CommentsComponent } from './section/asset-summary/comments/comments.component';
/* Documents  */
import { DocumentsComponent } from './section/asset-summary/documents/documents.component';
/* mEvents */
import { EventsComponent } from './section/asset-summary/events/events.component';
/* Partlets  */
// import { PartletsComponent } from './section/asset-summary/partlets/partlets.component';
// /* Products  */
// import { ProductsComponent } from './section/asset-summary/products/products.component';
// /* Activities  */
// import { ActivitiesComponent } from './section/asset-summary/activities/activities.component';
/* Assets Log */
import { AssetsLogComponent } from './section/asset-summary/assets-log/assets-log.component';

import { CallCenterDashboardComponent } from './section/Call-Center-User/call-center-dashboard/call-center-dashboard.component';

import { CallInstructionComponent } from './section/Call-Center-User/call-instruction/call-instruction.component';

import { DataPointComponent } from './section/asset-summary/data-point/data-point.component';
// import { AssetCalenderComponent } from './section/asset-summary/assets-calender/assets-calender.component';
// import { AssetsDataPocketComponent } from './section/asset-summary/assets-data-pocket/assets-data-pocket.component';
import { CopyScheduleComponent } from './section/asset-summary/copy-schedule/copy-schedule.component';

import { EventDetailsComponent } from './section/event-details/event-details.component';

import { SchedulerSettingComponent } from './section/setting/scheduler-setting/scheduler-setting.component';

import { CustomerMigrateComponent } from './section/migration/customer-migrate/customer-migrate.component';

import { CallCenterManagmentComponent } from './section/call-center-managment/call-center-list/call-center-managment.component';


import { AddCenterComponent } from './section/call-center-managment/add-center/add-center.component';

import { CallCenterUserComponent } from './section/call-center-managment/call-center-user/call-center-user-list/call-center-user.component';

import { AddCenterUserComponent } from './section/call-center-managment/call-center-user/add-center-user/add-center-user.component';

import { AdminBrandingComponent } from './section/setting/admin-branding/admin-branding.component';
import { UpdateProfileComponent } from './section/update-profile/update-profile.component';

import { RuntimeEventDetailComponent } from './section/Call-Center-User/runtime-event-detail/runtime-event-detail.component';
import { EventResolve } from 'src/app/services/event.resolve.service';
import { ConsumptionReportComponent } from './section/report/consumption-report/consumption-report.component';
import { UserTierMigrateComponent } from './section/migration/user-tier-migrate/user-tier-migrate.component';

import { AddCustomerComponent } from './section/client/customer/add-customer/add-customer.component';

import {CustomerDetailComponent} from './section/client/customer/customer-detail/customer-detail.component'
import {CustomerNotificationPlanComponent} from './section/client/customer/customer-notification-plan/customer-notification-plan.component'
import {CustomerAssetsComponent} from './section/client/customer/customer-assets/customer-assets.component'
import {CustomerRolesComponent} from './section/client/customer/customer-roles/customer-roles.component'
import {CustomerUsersComponent} from './section/client/customer/customer-users/customer-users.component'
import {AddCustomerUserComponent} from './section/client/customer/customer-users/add-customer-user/add-customer-user.component'
import {ViewCustomerUserComponent} from './section/client/customer/customer-users/view-customer-user/view-customer-user.component'
import { PlanManagementComponent } from './section/acount-management/plan-management/plan-management.component';
import { NewPlanComponent } from './section/acount-management/plan-management/new-plan/new-plan.component';
import { DefaultPlanComponent } from './section/acount-management/default-plan/default-plan.component';
import { NewDefaultPlanComponent } from './section/acount-management/default-plan/new-default-plan/new-default-plan.component';
import { ConsumptionDataComponent } from './section/acount-management/consumption-data/consumption-data.component';
import { BillManagementComponent } from './section/acount-management/bill-management/bill-management.component';
import { NewBillComponent } from './section/acount-management/bill-management/new-bill/new-bill.component';
import { InvoiceComponent } from './section/acount-management/invoice/invoice.component';
import { BillGenerateComponent } from './section/acount-management/bill-generate/bill-generate.component'; 
import { InvoiceListComponent } from './section/acount-management/invoice-list/invoice-list.component';// <-- import the module
import { TaxListComponent } from './section/acount-management/tax-list/tax-list.component';
import { InAppNotificationComponent } from './section/asset-summary/in-app-notification/in-app-notification.component';
import { UnitsComponent } from './section/asset-summary/units/units.component';
import { SystemHealthComponent } from './section/system-health/system-health.component';

const routes: Routes = [{
  path: '',
  component: ViewComponent, resolve :{event:EventResolve/* , userInfo:UserInfoResolve */},
  children: [
    {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    /* {
      path: '',
      component: DashboardComponent
    }, */
    {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'client',
    component: ClientComponent

  },{
    path: 'addclient',
    component: AddClientComponent
  },{
    path: 'addclient/:id',
    component: AddClientComponent,
  },
  {
    path: 'amci-role-management',
    component: RoleManagementComponent,
  },
  {
    path: 'client-role-management',
    component: ClientRoleComponent,
  },
  {
    path: 'site-management',
    component: SiteManagementComponent,
  },{
    path: 'user-management',
    component: UserManagementComponent,
  // },{
  //   path: 'shift-management',
  //   component: ShiftManagementComponent,
  // },{
  //   path: 'shiftgroup/:id',
  //   component: ShiftComponent,
  // },{
  //   path: 'scheduler-management',
  //   component: SchedulerComponent,
  // },{
  //   path: 'scheduler-management/:id',
  //   component: SchedulerComponent,
  },{
    path: 'asset-management',
    component: AssetManagementComponent,
  },{
    path: 'notification-management',
    component: NotificationPlanManagementComponent,
  // },{
  //   path: 'support',
  //   component: SupportComponent,
  },{
    path: 'report',
    component: ReportComponent,
  },
  {
    path: 'setting',
    component: SettingComponent,
  },
  {
    path: 'scheduler-setting',
    component: SchedulerSettingComponent,
  },
  {
    path: 'clientdetail/:id',
    component: AddClientdetailComponent,
  },

  {
    path: 'clientuserdetail/:id',
    component: ClientUserAddComponent,
  },
  {
    path: 'clientuserdetail',
    component: ClientUserAddComponent,
  },
  {
    path: 'sites/:id',
    component: SitesComponent,
  },
  {
    path: 'branding/:id',
    component: ClientBrandingComponent,
  },
  {
    path: 'license/:id',
    component: LicenseComponent,
  },
  {
    path: 'clientRoles/:id',
    component: ClientRolesComponent,
  },
  {
    path: 'add-client-role/:id',
    component: ClientRolesAddComponent,
  },
  {
    path: 'assets/:id',
    component: AssetsComponent,
  },{
    path: 'notifications/:id',
    component: NotificationPlanComponent,
  },{
    path: 'notification-event',
    component: NotificationEventComponent,
  },{
    path: 'roles',
    component: RolesComponent,
  },
  {
    path: 'customer/:id',
    component: CustomerComponent
  },
  {
    path: 'users/:id',
    component: UsersComponent,
  },{
    path: 'tierclient/:id',
    component: clientTierComponent,
  },
  {
    path: 'tier/dashboard',
    component: TierComponent,
  },{
    path: 'tier/tiers',
    component: TiersComponent,
  },{
    path:'site/addsite',
    component:AddSiteComponent,
  },{
    path: 'site/addsite/:id/:clientId',
    component: AddSiteComponent,
  },{
    path:'addnotification',
    component:AddNotitficationComponent,
  },{
    path:'addnotification/:id',
    component:AddNotitficationComponent,
  },{
    path:'role-management/addrole',
    component:AddRoleComponent,
  },{
    path:'role-management/addrole/:id',
    component:AddRoleComponent,
  },{
    path:'user/adduser',
    component:AddUserComponent,
  },{
    path: 'user/adduser/:id',
    component: AddUserComponent
  },{
    path: 'clientmenu',
    component: ClientMenuComponent
  },
  {
    path: 'assetUser/:id',
    component: AssetUserComponent
  },
  {
    path: 'assetUser/:id/:clientId',
    component: AssetUserComponent
  },
  {
    path: 'LiveFeed/:id',
    component: LiveFeedComponent
  },
  // {
  //   path: 'client-bill',
  //   component: ClientBillComponent
  // },
  {
    path: 'migration-tool',
    component: MigrationToolComponent
  },
  {
    path: 'client-migrate',
    component: ClientMigrateComponent
  },
  {
    path: 'tier-migrate',
    component: TierMigrateComponent
  },
  {
    path: 'site-migrate',
    component: SiteMigrateComponent
  },
  {
    path: 'user-migrate',
    component: UserMigrateComponent
  },
  {
    path: 'customer-migrate',
    component: CustomerMigrateComponent
  },
  // {
  //   path: 'product',
  //   component: CreateProductComponent
  // },
  // {
  //   path: 'product-type',
  //   component: ProductTypeManagementComponent
  // },
  // {
  //   path: 'add-product-type',
  //   component: AddProductTypeComponent
  // },
  // {
  //   path: 'add-edit-product-template/:id',
  //   component: AddProductTemplateComponent
  // },
  // {
  //   path: 'add-product-template-attribute',
  //   component: AddProductTemplateAttributeComponent
  // },
  // {
  //   path: 'product-template',
  //   component: ProductTemplateComponent
  // },
  // {
  //   path: 'add-edit-product-template',
  //   component: AddProductTemplateComponent
  // },
  // {
  //   path: 'product-template-attributes',
  //   component: ProductTemplateAttributeComponent
  // },
  {
    path: 'global-search/:key',
    component: GlobalSearchComponent
  },
  {
    path: 'assetsummary/:id',
    component: AssetSummaryComponent
  },
  {
    path: 'assetsummary/:id/:clientId',
    component: AssetSummaryComponent
  }, {
    path: 'assetunit/:id/:clientId',
    component: UnitsComponent
  },
  {
    path: 'assetsummarymenu',
    component: AssetsummaryMenuComponent
  },
  {
    path:'asset-edit',
    component:AssetEditComponent
  },
  {
    path:'assetGraph/:id',
    component:AssetGraphComponent
  },
  {
    path:'assetGraph/:id/:clientId',
    component:AssetGraphComponent
  },
  {
    path: 'modemtraffic/:id/:clientId',
    component: ModemTrafficComponent
  },
  {
    path: 'modemtraffic/:id',
    component: ModemTrafficComponent
  },
  // {
  //   path: 'comments/:id',
  //   component: CommentsComponent
  // },
  {
    path: 'documents/:id',
    component: DocumentsComponent
  },
  {
    path: 'documents/:id/:clientId',
    component: DocumentsComponent
  },
  {
    path: 'events/:id',
    component: EventsComponent
  },
  {
    path: 'events/:id/:clientId',
    component: EventsComponent
  },
  // {
  //   path: 'partlets/:id',
  //   component: PartletsComponent
  // },
  // {
  //   path: 'products/:id',
  //   component: ProductsComponent
  // },{
  //   path: 'activities/:id',
  //   component: ActivitiesComponent
  // },
  {
    path: 'assetsLog/:id',
    component: AssetsLogComponent
  },{
    path: 'assetsLog/:id/:clientId',
    component: AssetsLogComponent
  },{
    path: 'callcenter/call-center-dashboard',
    component: CallCenterDashboardComponent
  },
  {
    path: 'callcenter/call-center-instruction/:id',
    component: CallInstructionComponent
  }, 
  {
    path: 'callcenter/call-center-instruction/:id/:isFollowUp',
    component: CallInstructionComponent
  },
  {
    path: 'datapoint/:id',
    component: DataPointComponent
  },
  {
    path: 'datapoint/:id/:clientId',
    component: DataPointComponent
  },
  // {
  //   path: 'assets-calender/:id',
  //   component: AssetCalenderComponent
  // },
  // {
  //   path: 'assetsdatapocket/:id',
  //   component: AssetsDataPocketComponent
  // },
  {
    path: 'copy-schedule/:id',
    component: CopyScheduleComponent
  },
  {
    path: 'eventDetails/:id',
    component: EventDetailsComponent
  },
  {
    path: 'callCenter',
    component: CallCenterManagmentComponent
  },
  {
    path: 'add-callCenter',
    component: AddCenterComponent
  },
  {
    path: 'add-callCenter/:id',
    component: AddCenterComponent
  },
  {
    path: 'callCenter-user',
    component: CallCenterUserComponent
  },
  {
    path: 'callCenter-user/:id',
    component: CallCenterUserComponent
  },
  {
    path: 'add-call-user',
    component: AddCenterUserComponent
  },
  {
    path: 'add-call-user/:id',
    component: AddCenterUserComponent
  },
  {
    path: 'admin-branding',
    component: AdminBrandingComponent
  },{
    path: 'update_profile/:id',
    component: UpdateProfileComponent
  },
  {
    path: 'user-tier-migrate',
    component: UserTierMigrateComponent
  },
  {
    path: 'callcenter/runtime-event-detail',
    component: RuntimeEventDetailComponent
  }/* ,
  {
    path: '**',
    redirectTo: '/login'
  } */
  ,{
    path: 'consumption-report',
    component: ConsumptionReportComponent
  },
  {
    path: 'add-customer',
    component: AddCustomerComponent
  },
  {
    path: 'add-customer/:id',
    component: AddCustomerComponent
  },{
    path: 'customerdetail/:id',
    component: CustomerDetailComponent,
  },{
    path: 'customer-notifications/:id',
    component: CustomerNotificationPlanComponent,
  },{
    path:'customer-asset/:id',
    component:CustomerAssetsComponent
  },{
    path:'customer-role/:id',
    component:CustomerRolesComponent
  },{
    path:'customer-user/:id',
    component:CustomerUsersComponent
  },{
    path:'add-customer-user/:id',
    component:AddCustomerUserComponent
  },{
    path:'add-customer-user',
    component:AddCustomerUserComponent
  },{
    path:'view-customer-user/:id',
    component:ViewCustomerUserComponent
  },
  {
    path:'plan-management',
    component:PlanManagementComponent
  }, 
  {
    path:'new-plan',
    component:NewPlanComponent
  }, 
  {
    path:'new-plan/:id',
    component:NewPlanComponent
  },
  {
    path:'default-plan',
    component:DefaultPlanComponent
  }, 
  {
    path:'new-default-plan',
    component:NewDefaultPlanComponent
  },
  {
    path:'new-default-plan/:id',
    component:NewDefaultPlanComponent
  }, 
  {
    path:'consumption-data',
    component:ConsumptionDataComponent
  },
  {
    path:'bills',
    component:BillManagementComponent
  }, {
    path:'view-bill/:id',
    component:NewBillComponent
  },{
    path:'invoice/:id',
    component:InvoiceComponent
  },
  {
    path:'Bill-Generate',
    component: BillGenerateComponent
  },
  {
    path:'invoice-list',
    component: InvoiceListComponent
  },
  {
    path:'tax-list',
    component: TaxListComponent
  },
  {
    path: 'in-app-notification/:id/:clientId',
    component: InAppNotificationComponent
  },
  {
    path: 'in-app-notification/:id',
    component: InAppNotificationComponent
  },
  {
    path:'health-dashboard',
    component: SystemHealthComponent
  },

  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewsRoutingModule {
}
