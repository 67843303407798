import { Component, OnInit ,ChangeDetectorRef,ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService, CommonServicesService}from '../../../../services/index';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
import 'datatables.net';
declare var jQuery : any;
declare var $ : any, moment: any;

import 'datatables.net-bs4';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  user: any = JSON.parse(localStorage.getItem("clientUser"));
  userType: any = this.user ?  this.user.userType : '';
  userDatalist:any = [];
  dataTable: any ;
  search:any='';
  filterValue : any='';
  userStatusFilter : any = '';
  emailDomain:any = ''
  imageUrl:any = "assets/img/user_icon.png";
  clientTopDropdownValue : number;
  subscription: Subscription;
  clientFilterId : any = '';
  clientName : any = localStorage.getItem("clientName");
  HistoryList: any = [];
  roleList: any = [];
  sessionHistoryList: any = [];

  constructor(
  private router:Router, 
  private apiService: ApiService , 
  private alertService: AlertService , 
  private chRef: ChangeDetectorRef,
  private CommonServicesService: CommonServicesService) {
    // this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
    // .subscribe(
    //   message => {
    //     setTimeout(() => {
    //       this.clientName = localStorage.getItem("clientName");
    //     }, 1000);    
    //     this.clientFilterId = message.id;
    //     if(this.clientFilterId) {
    //       this.getUserManagementList();  
    //     }        
    //   },
    //   error=>{
    //     console.log(error)
    //   }
    // );

    // subscribe to header component sendClientId to get Client Id
    this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
    .subscribe(
      message => {
        this.clientFilterId = message.id;
        this.getUserManagementList();
        setTimeout(() => {
          this.clientName = localStorage.getItem("clientName");
        }, 1000);
      }
    );
  }

ngOnInit() {
  // let user = JSON.parse(localStorage.getItem("clientUser"));
  //setTimeout(function(){
    // this.userType = user.userType;
    // this.getUserManagementList();
  //}, 500);

$(document).on('hidden.bs.modal', function () {
  if($('.modal.show').length)
  {
    $('body').addClass('modal-open');
  }
});
}

DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}

ngOnDestroy() {
  // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    // this.subscription.next()
    // this.subscription.complete()
    // if ($.fn.DataTable.isDataTable('#data_table_usermanagement')){
    //   $('#data_table_usermanagement').DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [0,5,6,7,8] }]}).destroy();
    //   this.userDatalist = [];
    // };
}

/* On Search */
seachRecord(){
  this.getUserManagementList();
}

/* Reset Search Record */
resetSearch(){
  this.search = "";
  this.getUserManagementList();
}

filterUserByStatus(){
  this.getUserManagementList();
}

getEmailDomain(){
  this.apiService.getAll('getEmailDomain').subscribe(
  data => {
      if(data.statusCode == 100){
        this.emailDomain = data.emailDomain;
       }else{
       }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}

/* Get Site Management list */
getUserManagementList(){
  let user = JSON.parse(localStorage.getItem("clientUser"));
  this.userType = user.userType;
  this.clientTopDropdownValue =  $('#client_dropdown_top').children("option:selected").val();
  var URL = 'user/getAllUserbyType?userType='+this.userType;

  if(this.userStatusFilter != ""){
    if(this.userStatusFilter == 2){
      var statusfilter = '&isDeactivated=1';
    }else{
      var statusfilter = '&isActive='+this.userStatusFilter;
    }
    URL = URL.concat(statusfilter);
  }

  if(this.clientFilterId && this.clientFilterId !='' && this.clientFilterId != 0) {
    var params = '&clientId='+this.clientFilterId;
    URL = URL.concat(params);
  }

  // Destroy Data Table and clear the data, if its already exist
  // if ( $.fn.DataTable.isDataTable('#data_table_usermanagement') ) {
  //   $('#data_table_usermanagement').DataTable().destroy();
  //   this.userDatalist = [];
  // }

  this.apiService.getAll(URL).subscribe(
    data => {
       if(data.statusCode == 100){
          this.userDatalist = data.user;
          // const table: any = $('#data_table_usermanagement');
          // this.chRef.detectChanges();
          // this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [0,5,6,7,8] }]});

          if ($.fn.DataTable.isDataTable('#data_table_usermanagement')){
            $('#data_table_usermanagement').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#data_table_usermanagement');
          this.dataTable = table.DataTable({"order": [], "search": { regex: false, smart: false },"columnDefs": [{ "orderable": false, "targets": [0,5,6,7,8] }]});


        }else{
          this.userDatalist = [];
          // this.chRef.detectChanges();
          // if ($.fn.DataTable.isDataTable('#data_table_usermanagement')){
          //   $('#data_table_usermanagement').DataTable().destroy();
          // };
          // const table: any = $('#data_table_usermanagement');
          // this.chRef.detectChanges();
          // this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [0,5,6,7,8] }]});

          if ($.fn.DataTable.isDataTable('#data_table_usermanagement')){
            $('#data_table_usermanagement').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#data_table_usermanagement');
          this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [0,5,6,7,8] }]});

        }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
		}
  );
}

  deleteUser(id){
      // user/deleteUser?userId=4234
      Swal({
        title: 'Are you sure?',
        text: "You want to delete this User.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
      if (result.value) {
       this.apiService.delete('user/deleteUser?userId='+id, '').subscribe(
       data => {
           if(data.statusCode == 100){
            this.getUserManagementList();
                 this.chRef.detectChanges();
                // const table: any = $('#data_table');
                // this.dataTable = table.DataTable();
             Swal({
                  position: 'top-end',
                  type: 'success',
                  title: 'User Delete Successfully',
                  showConfirmButton: false,
                  timer: 1500
                }) ;

            }else{
              Swal({
                  position: 'top-end',
                  type: 'error',
                  title: data.responseMessage,
                  showConfirmButton: false,
                  timer: 1500
                })
             }
          },
         error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
          }
        })
  }

    // Restore Deactivated User
  RestoreUser(userId){
    Swal({
    title: 'Are you sure?',
    text: "You want to restore this User.",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, restore it!'
  }).then((result) => {
    if (result.value) {
      this.apiService.getAll('user/deactivateUser?userId='+userId+'&isDeactivated=0').subscribe(
        data => {
            if(data.statusCode == 100){
                this.alertService.sweetMessage('success',"User restored successfully");
                this.getUserManagementList();
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
            );
        }
    })
  }


/* Deactivate the User */
DeactivateUser(userId){
  Swal({
   title: 'Are you sure?',
   text: "If you deactivate this user then it will no longer be able to log into AMCi1440 and not receive the event notifications. Deactivating the user will remove all its association with Tier, Site and Assets. Would you like to proceed?",
   type: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33',
   confirmButtonText: 'Yes'
 }).then((result) => {
   if (result.value) {
    this.apiService.getAll('user/deactivateUser?userId='+userId+'&isDeactivated=1').subscribe(
      data => {
          if(data.statusCode == 100){
               this.alertService.sweetMessage('success',"User deactivated successfully");
               this.getUserManagementList();
           }else{
             this.alertService.sweetMessage('error',data.responseMessage);

            }
         },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
               }
           );
       }
   })
 }
 
 
 getHistory(id){
  this.getSessionHistory(id)
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('user/getUserHistoryByUserId?userId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.userList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}

getSessionHistory(id){
  this.sessionHistoryList = []
  if ($.fn.DataTable.isDataTable('#sessionHistoryDatatable')) {
    $('#sessionHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('user/getUserSessionHistoryByUserId?userId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.sessionHistoryList = data.userList;
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
      
}

setRolesList(roles){
  this.roleList = roles
}

getClientTooltip(clients){
  let str = ''
  if (clients) {
    if (clients.length) {
      clients.map((obj,index)=>{
        if (index==0) {
          str += obj.description
        }else{
          str = str+', '+obj.description
        }
      })
    }
  }
  return str;
}
}
