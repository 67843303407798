
import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
// import * as _ from "lodash";
// import 'datatables.net';
// import 'datatables.net-bs4';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.css']
})
export class SitesComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
siteDatalist: any = [];
clientdetailId: any = 0;
dataTable: any;
// ClientName: any = localStorage.getItem("ClientName");
clientName : any = localStorage.getItem("ClientInfoName");
siteLat:number = 39.381266;
siteLong:number = -97.922211;
mapMarkerPositions:any = []
siteName:string="";
zoomLevel : any;
bounds: any;
assignAsset: any = [];
  breadcrumbObj: any;
  siteHistoryList: any = [];

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }


  ngOnInit() {
  this.route.params.subscribe(params => {
        if(params['id']){
        this.clientdetailId = params['id'];
      }

    });
    if(this.clientdetailId){
      this.getSiteListByClient();
    }
    this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
  }

 /* Get Site  list */
getSiteListByClient(){
    this.siteDatalist = []
    if ($.fn.DataTable.isDataTable('#data_table_client_site')){
      $('#data_table_client_site').DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 5 }]}).destroy();
    };
  //  this.apiService.getAll('site/getSiteListByClient?clientId='+this.clientdetailId+'&userId='+this.userId+'&alphabateSortingOreder=1').subscribe(
   this.apiService.getAll('site/getSiteListByClient?clientId='+this.clientdetailId+'&alphabateSortingOreder=1').subscribe(
   data => {
       if(data.statusCode == 100){
          this.siteDatalist = data.responseList;
          this.chRef.detectChanges();
          if ($.fn.DataTable.isDataTable('#data_table_client_site')){
            $('#data_table_client_site').DataTable().destroy();
          };
          const table: any = $('#data_table_client_site');
          this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 6 }]}); 
          // this.chRef.detectChanges();
          //  const table: any = $('table');
          // this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 6 }]});
        }else{
          this.siteDatalist = [];
          // this.chRef.detectChanges();
          // const table: any = $('table');
          // this.dataTable = table.DataTable();
          this.chRef.detectChanges();
          const table: any = $('#data_table_client_site');
          this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 6 }]});
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
}

/* Delete Customer Management list */
DeleteSite(sitedatalist){
  Swal({
   title: 'Are you sure?',
   text: "You want to delete this Site.",
   type: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33',
   confirmButtonText: 'Yes, delete it!'
 }).then((result) => {
   if (result.value) {
    this.apiService.delete('site/deleteSite?siteId='+sitedatalist.siteId, '').subscribe(
    data => {
        if(data.statusCode == 100){
          Swal({
               position: 'top-end',
               type: 'success',
               title: 'Site Delete Successfully',
               showConfirmButton: false,
               timer: 3500
             }) ;
            this.getSiteListByClient();
         }else{
           Swal({
               position: 'top-end',
               type: 'error',
               title: data.responseMessage,
               showConfirmButton: false,
               timer: 3500
             })
          }
       },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
       }
       );
       }
 })
}

// show map on site position
setLatLong(lat,long,name){
  this.siteLat  = lat;
  this.siteLong = long;
  let arr = []
  arr.push([lat, long]);
  this.mapMarkerPositions = arr
  this.siteName = name;
  this.bounds = new google.maps.LatLngBounds();
  let loc = new google.maps.LatLng(lat, long);
  this.bounds.extend(loc);
  var data = this.bounds.getCenter();
  this.zoomLevel = 21;
}

getAssignAssets(sitedatalist){
  this.assignAsset = [];
  // console.log('getAssignAssets', sitedatalist);
  var URL = 'asset/getAssetByAdmin?siteId=' + sitedatalist.siteId+"&isAssetLive=true";
  if (this.clientdetailId != null && this.clientdetailId != 0) {
    var clientIdString = '&clientId=' + this.clientdetailId.toString();
    var URL = URL.concat(clientIdString);
  }
  if ($.fn.DataTable.isDataTable('#assignAssetTable')) {
    $('#assignAssetTable').DataTable().destroy();
  };
  this.apiService.getAll(URL).subscribe(
    data => {
      if (data.statusCode == 100) {
        this.assignAsset = [];
        this.assignAsset = data.responseList;
        this.chRef.detectChanges();
        setTimeout(() => {
          const table: any = $('#assignAssetTable');
          this.dataTable = table.DataTable({ "bInfo": false });
        }, 100);
      } else {
        this.assignAsset = [];
      }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}

SiteHistory(sitedatalist){
  this.siteHistoryList = []
  if ($.fn.DataTable.isDataTable('#siteHistoryDatatable')) {
    $('#siteHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('site/getSiteHistory?siteId=' + sitedatalist.siteId).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.siteHistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#siteHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#siteHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}
DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}

}
