import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, ActivatedRoute,RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { LoginComponent } from './login.component';

export const routes: Routes = [
	// { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule//.forChild(routes),
  ],
  declarations: [LoginComponent]
})
export class LoginModule { }
