import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

import { jqxTreeComponent } from '../../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})

export class AddCustomerComponent implements OnInit {
    @ViewChild('myTree') myTree: jqxTreeComponent;
    @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
    @ViewChild('customerContact') customerContact: any;
    @ViewChild('imageUrlName') imageUrlName: ElementRef;
    clientId: any = localStorage.getItem("clientId");
    userId: any = localStorage.getItem("userId");
    contactUserId: number = 0;
    countryList: any = [];
    stateList: any = [];
    customerId: number = 0;
    contactData: any = [];
    dataTable: any;
    imageName: any = {};
    imgBasePath: any = "";
    checkClient: boolean = false;
    customer: any = {
        "name": "",
        "isActive": 1,
        "emailId": "",
        "customerCode" : "",
        "billingAddress": "",
        "city": "",
        "country": {
            "countryId": "0"
        },
        "state": {
            "stateId": "0"
        },
        "user":
    	   {
    	   	"userId":this.userId
    	   },
        "sites": [],
        "contactNumber": "",
        "alternateNumber": "",
        "client": {
            "clientId": ''
        }
    };
    
    contact: any = {
        "firstName": "",
        "lastName": "",
        "note": "",
        "title": "",
        "emailId": "",
        "phoneNumber": "",
        "secondaryPhoneNumber": "",
        // "userType": "Customer",
        "userType": "Customer Contact",
        "client": {
            "clientId": ''
        },
        "customer": {
            "customerId": ""
        }
    }

    imageUrl = "assets/img/upload-img.png";
    clientCode: any ='';
    permissionData:any=[];
    imageError = "";
    imageTitle = "";
    newClientId: any = '';
    clientObj:any = {};
    breadcrumbObj: any;

    constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef, private formBuilder: FormBuilder) {
        this.permissionData = ApiService.permission;
    }

    // Function to format Date
    DateFormat(startDate){
      if(startDate){
        return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
      }else{
        return "";
      }
    }

    ngOnInit() {
        this.clientCode = this.alertService.getClientCode();
        this.getCountryList();
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.customerId = params['id'];
                this.contact.customer.customerId = this.customerId;
            }
        });

        this.route.queryParams.subscribe(params => {
          this.newClientId = params.clientId;
          this.clientId = this.newClientId;
          this.breadcrumbObj = params['source'];
        });

      
        if (this.customerId) {
            this.getCustomerById();
            this.getContactData();
        }

        function setInputFilter(textbox, inputFilter) {
          ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
            if(textbox){
              textbox.addEventListener(event, function() {
                if (inputFilter(this.value)) {
                  this.oldValue = this.value;
                  this.oldSelectionStart = this.selectionStart;
                  this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                  this.value = this.oldValue;
                  this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                }
              });
            }
          });
        }

        setInputFilter(document.getElementById("contactNumber"), function(value) {
          return /^[0-9+]*$/i.test(value);
        });

        setInputFilter(document.getElementById("alternateNumber"), function(value) {
          return /^[0-9+]*$/i.test(value);
        });

        setInputFilter(document.getElementById("Phone"), function(value) {
         return /^[0-9+]*$/i.test(value);
        });

        setInputFilter(document.getElementById("Mobile"), function(value) {
            return /^[0-9+]*$/i.test(value);
        });
        this.getClientById();
    }

    /* Get client by Id*/
    getClientById(){
        this.apiService.getAll('client/getClientById?clientId='+this.newClientId).subscribe(
        data => {
            if(data.statusCode == 100){
                this.clientObj = data.response;

            }else{
                this.alertService.sweetMessage('error',data.responseMessage);
            }
            },
        error =>
            { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
        );
    }

    // Function to Save Image
    imageSave() {
        if(this.imageError==""){
            const uploadData = new FormData();
            if(this.imageName.length){
                uploadData.append('file', this.imageName[0], this.imageName[0].name);
                this.apiService.create('uploadImage/uploadImage?section=2&sectionId=' + this.customerId, uploadData).subscribe(
                    data => {
                        if (data.statusCode == 100) {
                            this.getCustomerById();
                        } else {
                            this.alertService.sweetMessage('error',data.responseMessage);
                        }
                    },
                error =>{
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                }
                );
            }
        }
    }

    // Function called on file change
    onFileChanged(event) {
        this.imageTitle = "";
        this.imageError = "";
        const file = event.target.files;
        const fileType = file[0]['type'];
        const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
        // invalid file type code goes here.
        if (!validImageTypes.includes(fileType)) {
            this.imageUrl = "assets/img/upload-img.png";
            this.imageName = null;
            this.imageTitle = "";
            this.imageError = "Please select valid image type such as jpg, jpeg, png.";return;
        }
        if(this.imageError==""){
            var reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = (_event: any) => {
                var img = new Image();
                img.src = _event.target.result;
                img.onload = () => {
                    // this.imageUrl = reader.result as string;
                    if (img.width <= 300 && img.height <= 300) {
                        this.imageUrl = reader.result as string;
                        this.imageName = file;
                        this.imageTitle = file[0]['name'];
                        // if (this.customerId != 0) {
                        //     this.imageSave();
                        // }
                    } else {
                    //   this.alertService.sweetMessage('error','Image should not be greater than 300 X 300');
                        this.imageUrl = "assets/img/upload-img.png";
                        this.imageName = null;
                        this.imageTitle = "";
                        this.imageError = "Image Size should be less than 300X300"
                        return;
                    }
                };
            }
        }
        // setTimeout(() => {
        //     this.imageError = ''
        // }, 6000);
    }

    /* Create Customer */
    SubmitCustomer(formValue) {
      this.customer.client.clientId = this.newClientId
        if (!formValue.invalid && (this.customer.alternateNumber != this.customer.contactNumber)) {
            $('#err_msg').html("");
            window.scroll(0,0);
            if (this.customerId == 0) {
                this.apiService.create('customer/createCustomer', this.customer).subscribe(
                    (data:any) => {
                        if (data.statusCode == 100) {
                            this.customerId = data.response.customerId;
                            if (this.imageName != '') {
                                this.imageSave();
                            }
                            this.alertService.sweetMessage('success','Customer details added successfully');
                            this.router.navigate(['/customer/'+this.clientId], {queryParams:{source:this.breadcrumbObj}});
                        } else {
                            $('#err-msg').html(data.responseMessage);
                            this.showAlert(0);
                        }
                    },
                   error =>{
                        $('#err-msg').html("Something went wrong, please try again");
                        this.showAlert(0);
             });
            } else {
                this.customer.customerId = this.customerId;
                this.apiService.update('customer/updateCustomer', this.customer).subscribe(
                    data => {
                        if (data.statusCode == 100) {
                            if (this.imageName != '') {
                                this.imageSave();
                            }
                            this.alertService.sweetMessage('success','Customer details updated successfully');
                            this.router.navigate(['/customer/'+this.clientId], {queryParams:{source:this.breadcrumbObj}});
                         
                        } else {
                            $('#err-msg').html(data.responseMessage);
                            this.showAlert(0);
                        }
                    },
                    error =>{
                $('#err-msg').html("Something went wrong, please try again");
                this.showAlert(0);
             });
            }
        }
    }

    /* Create Contact not using now  */
    SubmitContact() {
        this.contact.client.clientId = this.newClientId
        this.contact.customer.customerId = this.customerId;
        this.contact.phoneNumber = this.contact.phoneNumber ? this.contact.phoneNumber : '';
        this.contact.secondaryPhoneNumber = this.contact.secondaryPhoneNumber ? this.contact.secondaryPhoneNumber : '';
        // Add New Call Center
     if(this.customerContact.valid && (this.contact.phoneNumber == this.contact.secondaryPhoneNumber  && this.contact.phoneNumber.length == 10  && this.contact.secondaryPhoneNumber.length == 10) ) {
        return;
      }else if((this.customerContact.valid && (this.contact.phoneNumber != this.contact.secondaryPhoneNumber && this.contact.phoneNumber.length == 10  && this.contact.secondaryPhoneNumber.length == 10) ) ||
      (this.customerContact.valid && this.contact.phoneNumber.length != 0) ||
      (this.customerContact.valid && this.contact.secondaryPhoneNumber.length != 0) ||
      (this.customerContact.valid && this.contact.phoneNumber.length == 0 && this.contact.secondaryPhoneNumber.length == 0) 
      ) {       
       /*  if (this.customerContact.valid) { */
            if (this.contactUserId == 0) {
                this.apiService.create('contactType/createCustomerContact', this.contact).subscribe(
                    data => {
                        if (data.statusCode == 100) {
                         this.alertService.sweetMessage('success','Contact details added successfully');
                            this.getContactData();
                            this.checkClient=true;
                            this.contact = {
                                "firstName": "",
                                "lastName": "",
                                "note": "",
                                "title": "",
                                "emailId": "",
                                "phoneNumber": "",
                                "secondaryPhoneNumber": "",
                                 // "userType": "Customer",
                                 "userType": "Customer Contact",
                                "client": {
                                    "clientId": this.clientId
                                },
                                "customer": {
                                    "customerId": this.customerId
                                }
                            };
                            this.customerContact.submitted = false;
                        } else {
                            this.alertService.sweetMessage('error',data.responseMessage);
                        }
                    },
                    error =>{
                        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
            } else {               
                this.apiService.update('contactType/updateCustomerContact', this.contact).subscribe(
                    data => {
                        if (data.statusCode == 100) {

                            this.alertService.sweetMessage('success','Contact details updated successfully');
                            this.getContactData()
                            this.contactUserId = 0;
                            this.customerContact.reset()

                        } else {
                            this.alertService.sweetMessage('error',data.responseMessage);
                        }
                        this.customerContact.submitted = false;                        
                    },
                    error =>{
                        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
            }
        /* } */
    }
}

    /* Get Contact data list */
    getContactData() {
        if ($.fn.DataTable.isDataTable('#con_table')){
            $('#con_table').DataTable().destroy();
        };
        $('#con_table').DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [4] }]});
        this.apiService.getAll('contactType/getCustomerContactListByClientId?clientId=' + this.clientId + '&customerId=' + this.customerId).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.contactData = data.contactTypeList;
                    if ($.fn.DataTable.isDataTable('#con_table')) {
                        $('#con_table').DataTable().destroy();
                    };
                    this.chRef.detectChanges();
                    const table: any = $('#con_table');
                    this.dataTable = table.DataTable({"order": [], "columnDefs": [{"orderable": false, "targets": [4]}]});
                } else {
                    this.contactData = [];
                    if ($.fn.DataTable.isDataTable('#con_table')) {
                        $('#con_table').DataTable().destroy();
                    };
                    this.chRef.detectChanges();
                    const table: any = $('#con_table');
                    this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": [4] }]});
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get Contact by Id*/
    getContactById(contact) {
        this.contactUserId = contact.contactTypeId;
        this.apiService.getAll('contactType/getContactTypeById?contactTypeId=' + this.contactUserId).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.contact = data.contactType;
                } else {
                    this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
           error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    /* Get Customer by Id*/
    getCustomerById() {
        this.apiService.getAll('customer/getCustomerById?customerId=' + this.customerId).subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.customer = data.response;
                    if (this.customer.imageLocation) {
                        this.imageUrl = this.apiService.imageBasePath + this.customer.imageLocation;
                        let imageArr = this.customer.imageLocation.split('/')
                        this.imageTitle = imageArr[1];
                    }
                    if (data.response.country) {
                        this.getStateListByCountryId(data.response.country.countryId);
                    } else {
                        this.customer.country = {countryId:"0"};
                    }
                    if (this.customer.state === null) {
                        this.customer.state = {stateId:"0"};
                    }
                } else {
                    // this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // resetImage(){
    //     this.imageUrl = this.imgBasePath ? this.imgBasePath : 'assets/img/upload-img.png';
    //     this.imageError = '';
    // }

    changeCountry(){
        this.customer.state = {stateId:"0"}; 
    }

    /* Get state list by country id */
    getStateListByCountryId(countryId) {
        this.stateList = [];
        if(countryId!=="0"){
            $('#err_msg').html("");
            this.apiService.getAll('state/getStateList/' + countryId).subscribe(
                data => {
                    // this.customer.state = {stateId:"0"};
                    if (data.statusCode == 100) {
                        this.stateList = [];
                        this.stateList = data.responseList;
                    } else {
                        this.stateList = [];
                        // this.alertService.sweetMessage('error',data.responseMessage);
                        $('#err_msg').html(data.responseMessage);
                        $('#err_msg').css('display', 'block');
                    }
                },
                error =>{
                    // this.customer.state = {stateId:"0"};
                    this.stateList = [];
                //    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                    $('#err_msg').html("Something went wrong, please try again");
                    $('#err_msg').css('display', 'block');
                 }
            );
        }
    }
    /* */

    showAlert(alertType){
        if(alertType===1){
            $(".success-alert").addClass("show");
            $(".success-alert").css("display","block");
            window.setTimeout(function() {$(".success-alert").slideUp(500, 
                function() { 
                    // $(this).remove();
                    $(".success-alert").removeClass("hide");
                });
            }, 4000);
        }else{
            $(".err-alert").addClass("show");
            $(".err-alert").css("display","block");
            window.setTimeout(function() {$(".err-alert").slideUp(500, 
                function() { 
                    // $(this).remove();
                    $(".err-alert").removeClass("hide");
                });
            }, 4000);
        }
    }

    /* Get Country list */
    getCountryList() {
        this.apiService.getAll('country/getCountry').subscribe(
            data => {
                if (data.statusCode == 100) {
                    this.countryList = data.responseList;
                } else {
                    this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
            error =>{
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }

    // Function to delete a contact of User
    deleteContact(userId) {
        Swal({
            title: 'Are you sure?',
            text: "You want to delete this contact.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                // this.apiService.getAll('user/deleteCustomerContact?userId=' + userId).subscribe(
                this.apiService.delete('contactType/deleteCustomerContact?contactTypeId=' + userId, '').subscribe(
                    data => {
                        if (data.statusCode == 100) {
                          this.contactUserId = 0;
                          this.contact = {
                              "firstName": "", "lastName": "",
                              "note": "", "title": "",
                              "emailId": "", "phoneNumber": "",
                              "secondaryPhoneNumber": "",
                               "userType": "Customer Contact",
                              "client": { "clientId": this.clientId },
                              "customer": { "customerId": ""}
                          };
                            this.alertService.sweetMessage('success','Contact deleted successfully');
                            this.getContactData();

                        } else {
                            this.alertService.sweetMessage('error',data.responseMessage);
                        }
                    },
                    error =>{
                        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
                );
            }
        })
    }

    closeAlert(alertType){
        if(alertType===1){
            $(".success-alert").addClass("hide");
            $(".success-alert").css("display","none");
        }else{
            $(".err-alert").addClass("hide");
            $(".err-alert").css("display","none");
        }
    }

    removeInputImage() {
        this.imageUrlName.nativeElement.value = '';
        this.imageTitle = '';
    }

    // function for deleting image of branding 
    removeBrandingImage() {
        Swal({
            title: 'Are you sure?',
            text: 'You want to remove image.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.value) {
                if(this.customerId) {
                    this.apiService.delete("uploadImage/removeImage?section=2&sectionId=" + this.customerId, '').subscribe(
                        data => {
                            if (data.statusCode == 100) {
                                this.alertService.sweetMessage('success', 'Image removed successfully');
                                this.imageUrl = 'assets/img/upload-img.png'
                            } else {
                                this.alertService.sweetMessage('error', data.responseMessage);
                            }
                        },
                        error => {
                            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                        }
                    );
                }else {
                    this.alertService.sweetMessage('success', 'Image removed successfully');
                    this.imageUrl = 'assets/img/upload-img.png'
                }
            }
        })
    }

}
