import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService} from '../../../../../services/index';
// import * as $ from 'jquery'
import * as _ from "lodash";

import 'datatables.net';
declare var jQuery : any;
declare var $ : any;
import 'datatables.net-bs4';

@Component({
  selector: 'app-site-migrate',
  templateUrl: './site-migrate.component.html',
  styleUrls: ['./site-migrate.component.css']
})
export class SiteMigrateComponent implements OnInit {

siteData  : any = null ;
sitehead : any ={};
sitebody : any ={};
dataTable : any;
clientName : any = localStorage.getItem("clientName");

constructor(private router:Router, private apiService: ApiService ,private alertService: AlertService , private chRef: ChangeDetectorRef) { }


ngOnInit() {
if(this.apiService.sitemigration == null){
	this.router.navigate(['/migration-tool']);
}
else {
this.siteData =  this.apiService.sitemigration;
this.sitehead = this.siteData[0]
this.siteData.splice(0, 1);
this.sitebody = this.siteData;
if ( $.fn.DataTable.isDataTable('#data_table') ) {
  $('#data_table').DataTable().destroy();
}
this.chRef.detectChanges();
 const table: any = $('#data_table');

this.dataTable = table.DataTable({
  "order": []
});
// this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 6 }]});

}
}


}
