import { Component, OnInit } from '@angular/core';
// import { EmployeeService } from '../employee.service';
import { ApiService } from 'src/app/services';


@Component({
  selector: 'app-dashboard-classic',
  templateUrl: './dashboard-classic.component.html',
  styleUrls: ['./dashboard-classic.component.css']
})
export class DashboardClassicComponent implements OnInit {

clientList:any=[];

constructor( private apiService: ApiService) { }

  ngOnInit() {
  this.getTierList();
  }


getTierList(){
  this.apiService.getLoginDatawithoutToken('classicSatAlarm/getAllClient').subscribe(
      data => {
          if(data.statusCode == 100){
              this.clientList = data.responseList;
              }else{

                  }
              },
              error => {

              }
          );
      }
}
