import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService, CommonServicesService}  from '../../../../services/index';
import Swal from 'sweetalert2'
import { Subscription } from 'rxjs';
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/


// Include the core fusioncharts file from core  -
// import FusionCharts from 'fusioncharts/core';

// Include the chart from viz folder
// E.g. - import ChartType from fusioncharts/viz/[ChartType]
// import Line from 'fusioncharts/viz/line';

// Include the fusion theme
// import FusionTheme from 'fusioncharts/themes/es/fusioncharts.theme.fusion'

// // Add the chart and theme as dependency
// // E.g. FusionCharts.addDep(ChartType)
// FusionCharts.addDep(Line);
// FusionCharts.addDep(FusionTheme);


declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
// import 'datatables.net-bs4';
declare var moment: any;
import { SortablejsOptions } from 'angular-sortablejs';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';


@Component({
  selector: 'app-asset-summary',
  templateUrl: './asset-summary.component.html',
  styleUrls: ['./asset-summary.component.css']
})

export class AssetSummaryComponent implements OnInit {

  clientId: any;
  clientIdURL : any = '';
  userId: any = localStorage.getItem("userId");
  assetsumaryData : any = {};
  assetActionData : any = [];
  assetActionListData:any=[];
  eventGraphData : any = {};
  assetId :any = 0;
  statusData:any={};
  dataTable: any;
  GraphData:any=[];
  unit:any='';
  addAction:any={"assetOther":{"assetOtherId":""},"asset":{"assetId":""},"user":{"userId":""}};
  userassetList:any=[];
  roleList:any=[];
  UserList:any=[];
  siteLat:any = "";
  siteLong:any = "";  
  UserToAsset:any = {
    "createdTime":"",
    "modifiedTime":"",
    "creator":"",
    "callSequence" : "",
    "lastModifier":"",
    "user": {
      "userId":""
    },
    "asset": {
      "assetId":''
    },
    "assetRole": {
      "assetRoleId":""
    }
  };
  assignContact:any =  {
    "asset":{
      "assetId": 0
    },
    "users":[]
  }
  // permissionData:any=[];
  data:any=[];
  options1:any={};

  channelNumber:any = null
  lastEventData : any = [];
  elapsedTime : any;
  assignedContactListData : any = [];
  calendarData : any = [];
  byTimeData : any = [];
  dt : any = '';
  currDate : any = '';
  shiftGroupId : any = '';
  npCallSchedule : any = '';
  npCallType : any = '';
  calendarName : any = '';
  assetRoleNotificationData : any = [];
  clientFilterId : any = '';
  subscription: Subscription;
  notificationPlanName : any = '';
  ackData : any;
  clientName : any = localStorage.getItem("clientName");
  clientTopDropdownValue : any;
  selectDate:any="";
  eventDate:any= moment().format("YYYY-MM-DD");
  weekday : any = [];
  monthArr : any = [];
  byTimeEscData : any = [];
  waitInterval : number;
  byTimeIsEsc : number = 0;
  isShowCallOrder:any = false;
  numbers:any=[];
  total:any=1;
  isLoading = false;
  terminaltrafficData:any = [];
  lastEventDataList : any = [];
  performAction:any = 0;
  showAckBtn : number = 0;
  showAckErcBtn : number = 0;
  showERC : number = 0;
  latLongobj = []
  DateError:any = null
  breadcrumbObj:any = null;
  urlCustomerId:any = null;
  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private formatDateService: FormatDateService,
    private alertService: AlertService,
    private CommonServicesService: CommonServicesService,
    private chRef: ChangeDetectorRef) {
      // subscribe to header component sendClientId to get Client Id
      this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
      .subscribe(
        message => {
          // this.clientFilterId = message.id; 
          if (this.clientFilterId!='0' && this.clientFilterId!=null) {
            this.route.params.subscribe(params => {
              if(params['id']){
                this.assetId = params['id'];
              }
              if(params['clientId']){
                this.clientId = this.clientIdURL = params['clientId'];
                localStorage.setItem("clientdetailId",this.clientIdURL);
              }
            });
          }else{
            this.route.params.subscribe(params => {
              if(params['id']){
                this.assetId = params['id'];
              }
              if(params['clientId']){
                this.clientIdURL = params['clientId'];
                localStorage.setItem("clientdetailId",this.clientIdURL);
                this.clientFilterId = this.clientIdURL;
              }
            });
          }     
        },
        error=>{
          console.log(error)
        }
      );
   }

    clientCode : any = '';

    // Function to format Date
    DateFormat(startDate, gmtOffset){
      if (startDate) {
        var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
        return formattedDate;
      } else {
        return '-'
      }
    }
    
    DateFormatDateOnly(startDate, gmtOffset){
      if (startDate) {
        var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY");
        return formattedDate;
      } else {
        return '-'
      }
    }

    ngOnInit() {
      let crdt = new Date();   

      this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          this.urlCustomerId = params.cus_id;
      })
      // Week days array
      // var weekday = new Array(7);
      this.weekday[0] = "Sunday";
      this.weekday[1] = "Monday";
      this.weekday[2] = "Tuesday";
      this.weekday[3] = "Wednesday";
      this.weekday[4] = "Thursday";
      this.weekday[5] = "Friday";
      this.weekday[6] = "Saturday";
      // month name array
      // var monthArr = new Array(12);
      this.monthArr[0] = "January";
      this.monthArr[1] = "February";
      this.monthArr[2] = "March";
      this.monthArr[3] = "April";
      this.monthArr[4] = "May";
      this.monthArr[5] = "June";
      this.monthArr[6] = "July";
      this.monthArr[7] = "August";
      this.monthArr[8] = "September";
      this.monthArr[9] = "October";
      this.monthArr[10] = "November";
      this.monthArr[11] = "December";

      var n = this.weekday[crdt.getDay()];
      var mnth = this.monthArr[crdt.getMonth()];
      var dt = crdt.getDate();
      this.currDate = n+', '+dt+' '+mnth;
      this.dt = moment().format('YYYY-MM-DD');

      this.clientCode = this.alertService.getClientCode();
      // this.route.params.subscribe(params => {
      //   if(params['id']){
      //     this.assetId = params['id'];
      //   }
      //   if(params['clientId']){
      //     this.clientIdURL = params['clientId'];
      //     localStorage.setItem("clientdetailId",this.clientIdURL);
      //   }
      // });

      if(this.assetId){
         this.getAssetSummaryData();
         this.getAssetActionData();
         this.getAssetActionListData();
         this.getUserByAsset();
         this.getRoleList();
         //this.getUserList();
         this.getAssignedContactsList();
         this.getCalendarData(this.dt);
         this.getUserList();
      }

      jQuery('.rst-date').datetimepicker({
        language: 'en',
        pickTime: true,
        pickDate: true,
        minDate: 0,
        showButtonPanel: true,
        inline: true,
        sideBySide: true,
        autoclose: true,
        keepOpen: false,
      });
      jQuery('.rst-time').datetimepicker({
          pickDate: false,
          pickTime: true,
          pickSeconds: false,
          pickMinutes: true,
          timeFormat: "HH:mm"
      });

      jQuery('#datetimepicker').datetimepicker({
          language: 'en',
          pickTime: true,
          pickDate: true,
          minDate: 0,
          minTime: 0,
          startDate: new Date(), // 0
          showButtonPanel: true,
          inline: true,
          sideBySide: true,
          autoclose: true,
          keepOpen: false
        }).on('changeDate', function(){
          $(this).datepicker('hide');
      });


      let today = moment().format('YYYY-MM-DD HH:MM:ss' );
      let notifyDateToday = moment().format('YYYY-MM-DD HH:MM:ss');
      $('#notifyDate').val(notifyDateToday);      
      $('#endDate').val(today);
      $('#startDate').val(moment().subtract(45,'days').format('YYYY-MM-DD HH:MM:ss'));
      // console.log($('#startDate').val(),$('#endDate').val())
      this.getLastEventDetails();
    }

    ngOnDestroy() {
      // clear the header filter when user leave the page
      this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
      // unsubscribe to ensure no memory leaks
      this.subscription.unsubscribe();
    }

    options = {
      onSort: (event: any) => {
        var items = event.to.children;
        this.assignContact.asset.assetId = this.assetId;
          for (var i = 0; i < items.length; i++) {
              this.assignContact.users.push({"userId":$(items[i]).attr('Id'), "callSequence":(i +1)});
          }

          this.apiService.update('assetUser/updateCallSeqInAssetUser', this.assignContact).subscribe(
          data => {

            if(data.statusCode == 100){
              this.alertService.sweetMessage('success','Asset user updated successfully');
            this.getUserByAsset();
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
         error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
             }
        );
      }
  };

  // Function to set latitude and longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;

    this.latLongobj = [
      lat,
      long
    ]
  }

  // Drag and drop functionality
  onItemDrop(e: any) {
    var assetdata = e.dragData;
  }

  // function to update event graph data
  updateEventGraphData(){
    this.data = this.GraphData;
    this.options1 = {width: 'auto',labels: ['Date',this.unit],stepPlot: true, xlabel: 'Date', ylabel: this.unit, animatedZooms: true, pointSize: 4};
  }

// Listen For event Recive from child
  reciveAsset(){
    this.getAssetSummaryData();
  }

  refreshApis() {
     this.getAssetSummaryData();
     this.getAssetActionData();
     this.getAssetActionListData();
     this.getUserByAsset();
     this.getRoleList();
     this.getAssignedContactsList();
     this.getCalendarData(this.dt);
     this.getUserList();
  }

  // Assigned Contacts Section Start
  // User Role Section
  getAssignedContactsList(){
    this.apiService.getAll('asset/getAssetContact?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.total = 0;
          Object.keys(data.responseList).forEach(key=> {
              // getting all the data 
              var isAck = data.responseList[key].isAck;
              var isCall = data.responseList[key].isCall;
              var isEmail = data.responseList[key].isEmail;
              var isEmailOrSMS = data.responseList[key].isEmailOrSMS;
              var isEsc = data.responseList[key].isEsc;
              var isEscCall = data.responseList[key].isEscCall;
              var isEscEmail = data.responseList[key].isEscEmail;
              var isEscEmailOrSMS = data.responseList[key].isEscEmailOrSMS;
              var isEscSMS = data.responseList[key].isEscSMS;
              var isSms = data.responseList[key].isSms;
              var isEscCall = data.responseList[key].isEscCall;
              var callType = data.responseList[key].callType;
              // setting numbers for assigning call sequence
              // if((isEscCall == true) && (callType == 1 || callType == 2)) {
                this.total = this.total + data.responseList[key].userList.length; 
              // }
               // Notifications will be sent or not
              if(!isSms && !isEscSMS && !isEscEmailOrSMS && !isEscEmail && !isEscCall && !isEsc && !isEmailOrSMS && !isEmail && !isCall && !isAck) {             
                data.responseList[key].showArrow = false;
              } else {
                data.responseList[key].showArrow = true;
              }              
          });
          // console.log('getAssignedContactsList this.numbers', this.numbers);
          this.assignedContactListData = data.responseList;
          this.numbers = [];
          for (var i = 1; i < this.total + 2; i++) {
            this.numbers.push(i);                  
          }
        }else{
          this.assignedContactListData = [];
        }
      },
      error =>{
         this.alertService.sweetMessage('error',"Something went wrong, please try again ");
      }
    );
  }
  
  // Show user detail section 
  showNotificationData(assetRoleId){
    $('#role-notification-'+assetRoleId).css('display', 'block');
    $('#rotateArrow-'+assetRoleId).css('display', 'block');
    $('#closeArrow-'+assetRoleId).css('display', 'none');
    this.getAssetRoleNotification(assetRoleId);
  }

  // Hide user detail section
  hideNotificationData(assetRoleId){
    $('.role-notification-'+assetRoleId).css('display', 'none');
    $('#rotateArrow-'+assetRoleId).css('display', 'none');
    $('#closeArrow-'+assetRoleId).css('display', 'block');
  }

  selectPreDate() {
    this.eventDate =moment(this.eventDate).subtract(1, 'days').format("YYYY-MM-DD")
    this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
    this.getCalendarData(this.eventDate);
  }

  selectNextDate() {
    this.eventDate =moment(this.eventDate).add(1, 'days').format("YYYY-MM-DD")
    this.selectDate = moment(this.eventDate, "YYYY-MM-DD").format("dddd DD MMMM");
    this.getCalendarData(this.eventDate);
  }

  // By Calendar
  getCalendarData(eventDate) { 
    let newDt = new Date(eventDate);

    var n = this.weekday[newDt.getDay()];
    var mnth = this.monthArr[newDt.getMonth()];
    var dt = newDt.getDate();
    this.currDate = n+', '+dt+' '+mnth;
    this.dt = moment().format('YYYY-MM-DD');

    let changeDate = moment(newDt).format('YYYY-MM-DD hh:mm:ss');

    this.apiService.getAll('shiftCallOrder/getShiftUserDetail?date='+changeDate+'&shiftGroupId='+this.shiftGroupId).subscribe(
    // this.apiService.getAll('asset/getAssetContactByCalendar?assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.calendarData = data.responseList;
          this.calendarName = this.calendarData[0].calendarName;
        }else{
          this.calendarData = [];
        }
      },
      error =>{
         this.alertService.sweetMessage('error',"Something went wrong, please try again ");
      }
    );
  }

  // Fixed Call Order 
  getUserByAsset(){
    this.clientTopDropdownValue = $('#client_dropdown_top').children("option:selected").val();
    let cid = '';
    if(this.clientFilterId != null && this.clientFilterId !=0){
      cid = this.clientFilterId;
    } else {
      cid = this.clientTopDropdownValue;
    }
    this.apiService.getAll('user/getUserByAsset?clientId='+this.clientId+'&assetId='+this.assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.userassetList = data.userList;
        }else{
          this.userassetList = [];
           /*this.alertService.sweetMessage('error',data.responseMessage); */

        }
      },
      error =>{            
               this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  // Unassigned Users
  getUserList(){
    this.clientTopDropdownValue = $('#client_dropdown_top').children("option:selected").val();
    let cid = '';
    if(this.clientFilterId != null && this.clientFilterId !=0){
      cid = this.clientFilterId;
    } else {
      cid = this.clientTopDropdownValue;
    }
    // this.apiService.getAll('user/getUserByTier?clientId='+this.clientId+'&assetId='+this.assetId+"&isAssetTeam=true").subscribe(
      this.apiService.getAll('user/getUserByTierWithCustomerUser?assetId='+this.assetId+"&isActive=true&clientId="+this.clientId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.UserList = data.userList;
          // this.total = this.total + this.UserList.length;
          // // setting call sequence dropdown data         
          // for (var i = 1; i < this.total; i++) {
          //   this.numbers.push(i);                  
          // }
        }else{
          this.UserList =[];
          this.numbers = ['1'];
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
     }
    );
  }

  // Preview notification : By Time
  previewNotification() {
    let notifyDate = $('#notifyDate').val();
    let notificationType = $('#notificationType').val();

    this.apiService.getAll('asset/getAssetContactByTime?assetId='+this.assetId+'&notificationType='+notificationType+'&byTime='+notifyDate).subscribe(      
        data => {
          if(data.statusCode == 100){
            this.byTimeData = [];
            this.byTimeEscData = [];
            let objData = data.responseList;
            objData.map((val,key)=>{
              let EscType = val.EscType;
              let isEsc = val.isEsc;
              if(isEsc == true) {
                this.byTimeIsEsc = 1;  
              }
              this.waitInterval = val.waitInterval;
              
              if(EscType == 1) {
                this.byTimeData.push(val);
              } else if(EscType == 2) {
                this.byTimeEscData.push(val);
              }
            }); 
            // console.log('final preview data', this.byTimeEscData, this.byTimeData);
          }else{
            this.byTimeData = [];
            this.byTimeEscData = [];
            this.byTimeIsEsc = 0; 
              //this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error =>{
          this.alertService.sweetMessage('error', "Something went wrong, please try again.");
        }
      );
  }

  // Getting roles 
  getAssetRoleNotification(assetRoleId) {
     //this.apiService.getAll('asset/getAssetRoleNotification?assetId=15133&assetRoleId=2').subscribe(
    this.apiService.getAll('asset/getAssetRoleNotification?assetId='+this.assetId+'&assetRoleId='+assetRoleId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetRoleNotificationData = data.responseList; 
        }else{
          this.assetRoleNotificationData =[];
         /* this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
      }
    );
  }

  // Remove User
  removeUser(userId, assetId){    
    Swal({
      title: 'Are you sure?',
      text: "You want to remove this Asset User.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('assetUser/deleteAssetUser?assetId='+this.assetId+'&userId='+userId,'').subscribe(
          data => {
            if(data.statusCode == 100){
              this.numbers.pop();
              this.alertService.sweetMessage('success','Asset User removed successfully');
              this.getUserByAsset();
              this.getAssignedContactsList();
              this.getUserList();
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
             }
          );
      }
    })
  }
  // Assigned Contacts Section Ends

  /* Function for getting Asset Summary Data */
  getAssetSummaryData(){    
    let clientIdURL = this.clientIdURL;
    let cid = '';
    if(clientIdURL != ''){
      cid = 'clientId='+this.clientIdURL+'&';
    }
    let URL = 'asset/getAdminAssetSummaryById?'+cid+'assetId='+this.assetId+'&userId='+this.userId;
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData = data.response;
          this.npCallSchedule = data.response.npCallSchedule;
          this.npCallType = data.response.npCallType;
          this.notificationPlanName = data.response.notificationPlanName;
          this.shiftGroupId = data.response.shiftGroup ? data.response.shiftGroup.shiftGroupId : '';
          this.getCalendarData(this.dt);
          // set flag to show call order while adding user
          this.isShowCallOrder = this.notificationPlanName ?  (this.npCallSchedule == 1 ? true : false) : false;
          if(this.assetsumaryData.site == null){
            this.assetsumaryData.site = {};
          }
          if(this.assetsumaryData.site.customer == null){
            this.assetsumaryData.site.customer = {};
          }
          if($.fn.DataTable.isDataTable('#recentStatus')){
            $('#recentStatus').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#recentStatus');
          this.dataTable = table.DataTable();
        }else{
          this.assetsumaryData= [];
          this.chRef.detectChanges();
          const table: any = $('#recentStatus');
          this.dataTable = table.DataTable();
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
             }
    );
  }

  /* Function for Asset Action data */
  getAssetActionData(){  
    let clientIdURL = this.clientIdURL;
    let cid = '';
    if(clientIdURL != ''){
      cid = 'clientId='+this.clientIdURL+'&';
    }
    let URL = 'assetActions/getAssetActionsByAsset?'+cid+'assetId='+this.assetId+'&userId='+this.userId;  
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetActionData= data.responseList;
        }else{
             this.assetActionData= [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
             }
    );
  }

  /* Function for Asset Action data */
  getAssetActionListData(){
    this.apiService.getAll('assetActions/assetActionList?assetId='+this.assetId+'&userId='+this.userId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetActionListData= data.responseList;
          // if($.fn.DataTable.isDataTable('#assetAction')){
          //   $('#assetAction').DataTable().destroy();
          // };
          this.sortDataTableData(this.assetActionListData,'outgoingCellularId',"desc");
          this.chRef.detectChanges();
          const table: any = $('#assetAction');
          this.dataTable = table.DataTable();
          // this.dataTable = table.DataTable({aaSorting: [[2, 'desc']]});
        }else{
          this.assetActionListData= [];
          this.chRef.detectChanges();
          const table: any = $('#assetAction');
          this.dataTable = table.DataTable();
          // this.dataTable = table.DataTable({aaSorting: [[2, 'desc']]});
          if($.fn.DataTable.isDataTable('#assetAction')){
            $('#assetAction').DataTable().destroy();
          };
        }
        if (this.assetActionListData.length == 0) {
          this.assetActionListData = [{"buttonName":"Cause Stop","processState":2,"processStateMessage":"Delivered","creationTimestamp":"2019-07-31 14:59:31:537","assetButton":"Cause Stop","outgoingCellularId":675895,"outgoingIdpId":null,"enableDaylightSavings":true,"gmtOffset":-6,"tz":"CDT"},]
          this.chRef.detectChanges();
          const table: any = $('#assetAction');
          this.dataTable = table.DataTable();
        } 
      },
      error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
             }
    );
  }

  /** Function to Sort Response Data **/
  sortDataTableData(items,sortBy,desc){
       if (sortBy && sortBy != null) {
              items = items.sort((a, b) => {
               const sortA = a[sortBy];
               const sortB = b[sortBy];
               if (desc) {
                   if (sortA < sortB) return 1;
                   if (sortA > sortB) return -1;
                   return 0;
               } else {
                   if (sortA < sortB) return -1;
                   if (sortA > sortB) return 1;
                   return 0;
               }
           });
          return items;
       }else{
          return items;
       }
  }

  ResetDate(){
    // $('#startDate').val('');
    // $('#endDate').val('')
    let today = moment().format('YYYY-MM-DD HH:MM:ss')
    $('#endDate').val(today);
    $('#startDate').val(moment().subtract(45,'days').format('YYYY-MM-DD HH:MM:ss'));

    this.getDrawEventGraph(this.channelNumber,this.statusData);

    this.getDrawEventGraph(this.channelNumber,this.statusData);

  }

  // function to event graph
  getDrawEventGraph(channelNumber,recentstatus){
    this.GraphData = [];
    this.channelNumber = channelNumber
    this.statusData = recentstatus;

    let start = $('#startDate').val();
    let end = $('#endDate').val();

    let clientIdURL = this.clientIdURL;
    let cid = '';
    if(clientIdURL != ''){
      cid = 'clientId='+this.clientIdURL+'&';
    }
    let diff = moment(end).diff(moment(start),'days')

    if( diff <0){
      this.DateError = "Please Enter valid Start Date and End Date"
    }
    else {
      this.DateError = null
      let URL = 'asset/drawEventGraph?'+cid+'assetId='+this.assetId+'&channelNumber='+channelNumber+ (start ? '&fromDate='+moment(start).format('YYYY-MM-DD HH:mm:ss'):'')+ (end ? "&toDate="+moment(end).format('YYYY-MM-DD HH:mm:ss'):'');  

      this.apiService.getAll(URL).subscribe(
          data => {
            if(data.statusCode == 100){
              this.eventGraphData= data.response;
              if(this.eventGraphData.dataPoints){
                var str = "";
                Object.keys(this.eventGraphData.dataPoints).forEach(key=> {
                      var tempArr = [];
                      //var date = new Date(this.eventGraphData.dataPoints[key]['eventTime']);
                      //tempArr.push(date);2009/07/12

                      str += this.eventGraphData.dataPoints[key]['eventTime'] + ', '+this.eventGraphData.dataPoints[key]['originalValue'];
                      tempArr.push(new Date(moment(this.eventGraphData.dataPoints[key]['eventTime'], "YYYY-MM-DD HH:mm:ss:SSS").format('YYYY/MM/DD HH:mm:ss')));
                      tempArr.push(this.eventGraphData.dataPoints[key]['originalValue']);

                      ;
                      this.GraphData.push(tempArr);
                      });

                this.unit = this.eventGraphData.description?this.eventGraphData.description:'';
                this.updateEventGraphData();
                // document.getElementById("openModalButton").click();
                $('#graphModal1').modal('show');
              }

            }else{
              $('#graphModal1').modal('show');

              /*this.alertService.sweetMessage('error',data.responseMessage); */
            }
          },
          error =>{
                this.alertService.sweetMessage('error',"Something went wrong, please try again ");
              }
        );
      }
  }

  getLastWords(data) {
    var n = data.split(" ");
    return n[n.length - 1] == '.' ? '-' : n[n.length - 1];

}

  // Function to add action
  addActionData(){
  if(this.addAction.assetOther.assetOtherId !=''){

    var obj = this.assetActionData.find(o => o.assetOtherId === this.addAction.assetOther.assetOtherId);
    // console.log(obj.actionName);

    Swal({
      title: 'Are you sure?',
      text: "Do you want to send "+obj.actionName+" action to the Asset?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {

        this.addAction.asset.assetId = this.assetId;
        this.addAction.user.userId   = this.userId;

        this.apiService.create('assetActions/postAssetAction', this.addAction).subscribe(
          data => {
              if(data.statusCode == 100){
                   this.alertService.sweetMessage('success', obj.actionName+' action has been sent to the asset');
                  this.addAction.assetOther.assetOtherId = '';
                  this.getAssetActionListData();
              }else{
                  this.alertService.sweetMessage('error',data.responseMessage);
              }
          },
          error =>{
                   this.alertService.sweetMessage('error',"Something went wrong, please try again ");
                 }
        )
      }
    })

  }
  }

  /* Get role list */
  getRoleList(){
    this.apiService.getAll('assetRole/getAllAssetRole').subscribe(
      data => {
        if(data.statusCode == 100){
          this.roleList = data.responseList;
        }else{
          this.roleList =[];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
             }
    );
  }

  /* Add User by Asset  */
  AddUserByAsset(){
    this.UserToAsset.asset.assetId = this.assetId;
    this.apiService.create('assetUser/addUserToAsset', this.UserToAsset).subscribe(
      data => {
        if(data.statusCode == 100){
        this.alertService.sweetMessage('success','Asset User Added Successfully');
          this.getUserByAsset();
          this.getAssignedContactsList();
          this.getUserList();
          this.UserToAsset = {"createdTime":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
             }
    );
  }

  /* Delete User */
  DeleteUser(assetdata){
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Asset User.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('assetUser/deleteAssetUser?assetId='+this.assetId+'&userId='+assetdata.userId,'').subscribe(
          data => {
            if(data.statusCode == 100){
              this.alertService.sweetMessage('success','Asset user Deleted Successfully');
              this.getUserByAsset();
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>{
               this.alertService.sweetMessage('error',"Something went wrong, please try again ");
             }
          );
      }
    })
  }


  /* Function for Asset Action data */
getLastEventDetialsOld(){
  this.apiService.getAll('wffnotification/getLatestEventByAsset?assetId='+this.assetId).subscribe(
    data => {
      if(data.statusCode == 100){
        this.lastEventData = data.response;
        this.elapsedTime = this.formatDateService.dateDiffrence(this.lastEventData.createDate,this.lastEventData.localDateConverted)
      }else{
        this.lastEventData= [];
      }
    },
    error =>
        { this.alertService.sweetMessage('error',"Something went wrong, please try again "); }
  );
}

  TimeChange(time){    
    return moment(moment().format('DD-MM-YYYY')+' '+time,'DD-MM-YYYY HH:mm:ss').format('HH:mm')
  }



rootCasue(eventData){

    let obj = {
          id:null,
          type:null
        }
        obj.type = eventData.notificationType
        if (eventData.notificationType == "Notification") {
            obj.id = eventData.notificationId
        } else if (eventData.notificationType == "RunTime") {
          obj.id = eventData.runTimeEventId
        }

    this.router.navigate(["eventDetails",this.assetId,],
      {queryParams: 
        { 
          type  : obj.type, 
          id    : obj.id, 
          rcpId : eventData.runTimeCollectionPointId,
          notificationId : eventData.notificationId,
          isParent : eventData.isParent  
        }
      })
  }

  acknoledge(wffid){
      Swal({
        title: 'Are you sure?',
        text: "You want to Acknowledge this event.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Acknowledge!'
      }).then((result) => {
        if (result.value) {
          this.ackData.wffNotification.wffNotificationId = wffid;
          this.apiService.create('wffnotification/createEventHsitory',this.ackData).subscribe(
            data => {
              if(data.statusCode == 100){
                this.alertService.sweetMessage('success','Acknowledged Successfully');
                this.getLastEventDetails();
              }else{
                this.alertService.sweetMessage('error',data.responseMessage);
              }
            },
            error =>{
                 this.alertService.sweetMessage('error',"Something went wrong, please try again ");
               }
            );
        }
      })
    }

    // Search method for Asset records according to date
  getRecords (val) {
    let obj:any = {}
    if (val.outgoingCellularId) {
        obj.id = val.outgoingCellularId
        // obj.type = "cellular"
    } else if(val.outgoingIdpId) {
        obj.id = val.outgoingIdpId
        obj.type = "idp"
    }


      var URL = 'packetHeader/getAssetActionTraffic?assetId='+this.assetId;

      if (val.outgoingCellularId) {

          var idStringOne = '&ObjectType=OutgoingCellular&OutgoingCellular='+val.outgoingCellularId.toString();
          var URL = URL.concat(idStringOne);
      }

      if (val.outgoingIdpId) {
          var idStringTwo = '&ObjectType=OutgoingIDP&outgoingIdpId='+val.outgoingIdpId.toString();
          var URL = URL.concat(idStringTwo);
      }
      this.isLoading = true;

      var fromDate = $("#startDate11").val();
      var endDate = $("#endDate").val();
      //this.apiService.getAll('packetHeader/getTerminalTrafficByAsset?topClause=10&assetId=10200&fromDate=2019-01-06 00:00:00&toDate=2019-06-04 11:49:53').subscribe(
      // this.apiService.getAll('packetHeader/getAssetActionTraffic?assetId='+this.assetId+(obj.id ? '&cellularId='+obj.id :'')/* + (obj.id ? '&id='+obj.id :'') + (obj.type ? '&type='+obj.type : '') *//*'&fromDate=2019-01-06 00:00:00&toDate=2019-06-04 11:49:53' //+fromDate+'&toDate='+endDate */).subscribe(
      this.apiService.getAll(URL).subscribe(
        data => {
          this.isLoading = false;

          if(data.statusCode == 100){
            this.terminaltrafficData = data.responseList;
          }
          else{
            this.terminaltrafficData =[];
            //this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
        error =>
          {
            this.isLoading = false;

            this.alertService.sweetMessage('error',"Something went wrong, please try again ");
          }

      )
    }

    // Last Message Events
    getLastEventDetails(){
      // wffnotification/getLastMsgEventByAssetId?assetId=20694this.assetId
      this.apiService.getAll('wffnotification/getLastMsgEventByAssetId?assetId='+this.assetId).subscribe(
        data => {
          if(data.statusCode == 100){
            console.log('getLastEventDetails================', data);
            this.lastEventDataList = data.responseList;       
            // this.getRuntimeEventDetails(963905);
            // this.elapsedTime = this.formatDateService.dateDiffrence(this.lastEventData.createDate,this.lastEventData.localDateConverted)
          }else{
            this.lastEventDataList = [];
            /*this.alertService.sweetMessage('error',data.responseMessage); */
          }
        },
        error =>{
                this.alertService.sweetMessage('error',"Something went wrong, please try again ");
              }
      );
  }

  getRuntimeEventDetails(runTimeEventId, listData){
    this.apiService.getAll('runTimeEvent/getTEWPermissionByRunTimeEventId?runTimeEventId='+runTimeEventId).subscribe(
      data => {
        if(data.statusCode == 100){
          console.log('runTimeEvent', data, listData);;
            let eventData = data.response ;    
  
            let runTimeStatus = eventData.runTimeStatus;
            let licenseIsAck = eventData.licenseIsAck;
            let acknowledgeByMe = eventData.acknowledgeByMe;
            let assignToMe = eventData.assignToMe;
            let technicianId = eventData.technicianId;      
            let licenseIsTechEntry = eventData.licenseIsTechEntry;
            let isAck = eventData.isAck;
            let eventListType = '';
            let isApproved = eventData.isApproved;
            
            if((runTimeStatus == 1 || runTimeStatus == 2) && (isApproved == null || isApproved == 0)) {
              eventListType = 'liveFeeds';
            } else if((runTimeStatus == 3 && (isApproved == null || isApproved == 0))) {
              eventListType = 'approval';
            } else if((runTimeStatus == 3 && (isApproved == null || isApproved == 0))) {
              eventListType = 'approved';
            }
  
            if(this.userId == eventData.technicianId && runTimeStatus == 2) {
              this.performAction = 1;
            } 
            
            if(licenseIsAck == true && acknowledgeByMe == false && assignToMe == true) {
              this.showAckBtn = 1;
            } else {
              this.showAckBtn = 0;
            }
  
            if(
              licenseIsAck == true && 
              acknowledgeByMe == false && 
              assignToMe == true && 
              (technicianId == null || technicianId == 0) && 
              licenseIsTechEntry == true && 
              runTimeStatus == 1
              ) {
              this.showAckErcBtn = 1;
            } else {
              this.showAckErcBtn = 0;
            }
  
            if(
              licenseIsAck == true && 
              assignToMe == true && 
              (technicianId == null || technicianId == 0) &&         
              runTimeStatus == 1
              ) {
              this.showERC = 1;
            } else {
              this.showERC = 0;
            }
  
            let obj = {
              id:null,
              type:null
            }
            let pVal = 0;
            if(eventData.isParent == false) {
              pVal = 0;
            } else if(eventData.isParent == true) {
              pVal = 1;
            }
            obj.type = eventData.notificationType;
            if (listData.notificationType == "Notification") {
                obj.id = listData.notificationId;
            } else if (listData.notificationType == "RunTime") {
                obj.id = listData.runTimeEventId;
            }
            if(eventListType == 'approval' || eventListType == 'approved') {
              obj.type = 'RunTime';
              obj.id = eventData.runTimeEventId;
              this.performAction = 1;
              eventData.licenseIsTechEntry = true;
            }
  
            this.router.navigate(["eventDetails", this.assetId,], 
              {
                queryParams: 
                { 
                  type: listData.notificationType, 
                  id :obj.id, 
                  rcpId:eventData.runTimeCollectionPointId, 
                  notificationId : listData.notificationId ? listData.notificationId : 0, 
                  isParent : pVal, 
                  performAction : this.performAction, 
                  showAckBtn : this.showAckBtn,
                  showAckErcBtn : this.showAckErcBtn,
                  showERC : this.showERC,
                  licenseIsTechEntry : eventData.licenseIsTechEntry, 
                  eventListType : eventListType,
                  assignToMe : assignToMe == true ? 1 : 0,
                  isAck : isAck == true ? 1 : 0,
                  source:this.breadcrumbObj
                }
            });
        }else{        
        }
      },
      error =>
        {
          this.alertService.sweetMessage('error',"Something went wrong, please try again ");
        }
    );
  }

}
