import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService, CommonServicesService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import flatpickr from "flatpickr";
import { Subscription } from 'rxjs';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  host: {
    '(document:click)': 'onClick($event)',
  },
  selector: 'app-consumption-report',
  templateUrl: './consumption-report.component.html',
  styleUrls: ['./consumption-report.component.css']
})
export class ConsumptionReportComponent implements OnInit {
  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('tierArea') tierArea: ElementRef;
  @ViewChild('treeArea') treeArea: ElementRef;

  userId: any = localStorage.getItem("userId");
  clientId: any = (localStorage.getItem("clientId")?JSON.parse(localStorage.getItem("clientId")):null);
  clientName : any = localStorage.getItem("clientName");
  data: any = [];
  dataAdapter: any = [];
  source: any = {};
  records: any = [];
  checkTreeArray: any = [];
  tiersArray: any = [];
  selectAllTier: any = [];
  tierIdForApi:any;
  disableTreeArray: any = [];
  enableTreeArray:any = [];
  disableTreeLength:any=0;
  newDisableTreeArray:any=[];
  dropdownSettingsSite:any = {};
  siteList: any = [
    {siteId:1, siteName:'Test Site 1'},
    {siteId:1, siteName:'Test Site 2'},
    {siteId:1, siteName:'Test Site 3'},
    {siteId:1, siteName:'Test Site 4'}
  ];
  selectedSite: any = [];
  checkSiteArray: any = [];
  siteIdForApi:any;
  dataTable: any;
  filterObj:any = {
    "startDate":new Date(),
    "endDate": new Date(),
    "topClause": 50,
    "selectedAssetId": 0,
    "selectedSite": "",
  }
  showTier: boolean = false;
  hideTempTier: boolean = false;
  tierToggleClass: any = "";
  tierPlaceholder:any = "Select Tier";
  assetDatalist: any = [
    {assetId:1, assetName:'Test Asset 1'},
    {assetId:1, assetName:'Test Asset 2'},
    {assetId:1, assetName:'Test Asset 3'},
    {assetId:1, assetName:'Test Asset 4'}
  ];
  selectedAsset: any = [];
  dropdownSettingsAsset:any ={};
  checkAssetArray: any = [];
  assetIdForApi:any;
  newCheckDateDiff:boolean = false;
  startDateErr:any = "";
  endDateErr:any = "";
  message: any;
  subscription: Subscription;
  clientFilterId : any = '';
  tierId: number = 0;
  site: any = {
    "siteName": "", "city": "", "isActive": 1, "zipCode": "",
    "longitude": 0.0, "latitude": 0,
    "client": { "clientId": this.clientId },
    "customer": { "customerId": "" },
    "country": { "countryId": "" ,  "countryName": ""},
    "state": { "stateId": 0 },
    "isAutoFetchAssetLocation": false,
    "tier":{"tierId":""}
  };
  hide :  boolean = true;

  constructor(private router: Router, 
    private route: ActivatedRoute, 
    private apiService: ApiService , 
    private alertService: AlertService, 
    private chRef: ChangeDetectorRef,
    private formatDateService: FormatDateService,
    private CommonServicesService: CommonServicesService,
    private eRef: ElementRef) {
      let self = this;
      // subscribe to header component sendClientId to get Client Id
        this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
        .subscribe(
          message => {
            this.clientFilterId = message.id;
              setTimeout(() => {
                this.clientName = localStorage.getItem("clientName");
              }, 1000);
           
            //this.getLatestLiveFeed();
            // this.getAssetStatusWidget();
          }
        );



    }

    toggle() {
      if(this.hide) {
        this.hide = false;
        console.log('in if', this.hide);
      } else {
        this.hide = true;
        console.log('in else', this.hide);
      }
    }

  ngOnInit() {
    console.log("client--",this.clientFilterId)
    this.setFlatPicker();//initialize date picker using flatpicker
    this.dropdownSettingsSite = {
      singleSelection: false,
      idField: 'siteId',
      textField: 'siteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      placeholder : "Select Site" 
    };
    this.dropdownSettingsAsset = {
      singleSelection: false,
      idField: 'assetId',
      textField: 'assetName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      placeholder : "Select Asset",
    };
    if(this.clientFilterId) {
      this.getTierList();
      // this.getSiteManagementList();
      // this.getAllAssetList();
    }

    // setTimeout(() => {
    //   var slider:any = document.getElementById("myRange");
    //   var output:any = document.getElementById("demo");
    //   output.innerHTML = slider.value;
      
    //   slider.oninput = function() {
    //     output.innerHTML = this.value;
    //   }
    // }, 1000);

  }

  ngOnDestroy() {
    // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  //setting FlatPicker Property
  setFlatPicker() {
      var self = this;
      //faltpicke config for end Date
      flatpickr('#endDate',{
        enableTime: true,
        enableSeconds : true,
        // dateFormat: "d-m-Y H:i:S",
        dateFormat: "m-d-Y H:i:S",
        defaultDate:new Date(), 
        time_24hr : true,   
        onChange: function(selectedDates, dateStr, instance) {
          self.filterObj.endDate = selectedDates;
        },      
      });
  
      //faltpicke config for start Date
      flatpickr('#startDate',{
        enableTime: true,
        enableSeconds : true,
        // dateFormat: "d-m-Y H:i:S",
        dateFormat: "m-d-Y H:i:S",
        defaultDate:new Date(), 
        time_24hr : true,  
        onChange: function(selectedDates, dateStr, instance) {
          console.log("selectedDates",selectedDates,"dateStr",dateStr,"instance",instance)
          self.filterObj.startDate = selectedDates;
        }, 
      });
  }

  onClick(event) {
      if (!(this.tierArea.nativeElement.contains(event.target))) {
        this.showTier = false;
      }
  
      if (!this.showTier) { 
        this.tierToggleClass = ""; 
      } else { 
        if (!(this.tierArea.nativeElement.contains(event.target)) && !(this.treeArea.nativeElement.contains(event.target))) {        
         this.showTier = false;
         this.tierToggleClass = "";
        } else {
          this.tierToggleClass = "site-m-after";
        } 
      }
  }

  /* Show tier label in dropdown on tree select */
  myTreeOnSelect(event: any) {
      this.myDropDownButton.close();    
      if((this.disableTreeArray).indexOf(parseInt(event.args.element.id)) != -1){
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
        this.myDropDownButton.setContent(dropDownContent);
        this.tierId = 0;
      } else {
        this.tierId = event.args.element.id;
        let item = this.myTree.getItem(event.args.element);
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + item.label + '</div>';
        this.myDropDownButton.setContent(dropDownContent);
      }
  };

  /* Show tier label in dropdown on get by IdApi */
  showLableInTierOnLoad() {
      if (this.site.tier.tierName && this.site.tier.tierId) {
        this.tierId = this.site.tier.tierId;
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + this.site.tier.tierName + '</div>';
        if (this.myDropDownButton) {
          this.myDropDownButton.setContent(dropDownContent);
        }
      } else {
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
        if (this.myDropDownButton) {
          this.myDropDownButton.setContent(dropDownContent);
        }
      }
  
  }

  showHideTierTree(event) {
    if(!this.showTier){
      this.showTier = true;
      this.tierToggleClass="site-m-after";
    } else {
      this.showTier = false;
      this.tierToggleClass="";
    }
  }

  hideTier() {
    this.showTier = false;
  }

  // Get all tier list
  getTierList() {
    var URL = 'tier/getAllTierByUser?userId=' + this.userId + '&clientId=' + this.clientFilterId;

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.data = data.responseList;
          
          // Object.keys(this.data).forEach(key => {
          //   if (this.data[key]['flag'] == false) {
          //     this.disableTreeArray.push(this.data[key]['tierId']);
          //   }
          // });
          for (let object of this.data) {
            if(!object.flag) {
              this.disableTreeArray.push(object.tierId.toString());
            }
          }
          // disable tree length
          this.getEnableDisableTier()
          
          this.source = {
            datatype: 'json',
            datafields: [
                {name: 'tierId'}, 
                {name: 'parentTierId'},
                {name: 'tierName'}
              ],
              id: 'tierId',
              localdata: this.data
          };
          this.dataAdapter = new jqx.dataAdapter(this.source, {
            autoBind: true
          });
          this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [
                            {
                              name: 'tierName',
                              map: 'label'
                            }, 
                            {
                              name: 'tierId',
                              map: 'id'
                            }]);
          var self = this;
          setTimeout(function() {
            self.treeItemDisable();
          }, 500);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getEnableDisableTier() {
    for (let object of this.data) {
      if(object.flag){
        this.enableTreeArray.push(object.tierId.toString());
      } else {
        this.newDisableTreeArray.push(object.tierId.toString());
      }
    }
    this.disableTreeLength = this.newDisableTreeArray.length;
  }

  // Function to disable items
  treeItemDisable() {
      Object.keys(this.disableTreeArray).forEach(key => {
          let checkedItem = document.getElementById(this.disableTreeArray[key]);
          this.myTree.expandItem(checkedItem);
          this.myTree.disableItem(checkedItem);
      });
      if (this.checkTreeArray.length != 0) {
        this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
      } else {
        this.tierPlaceholder = "Select Tier";
      }
  }



  /* Get Site Management list */
  getSiteManagementList() {
      this.siteList = []
      this.selectedSite = [];
      this.checkSiteArray = [];
      this.siteIdForApi = "";
      this.checkAssetArray = [];
      this.assetDatalist = [];
      this.selectedAsset = [];
      this.assetIdForApi = "";
      this.filterObj.selectedAssetId = 0;
      this.filterObj.selectedSite = "";
  
          var URL = 'site/getSiteDropDownListByClient?alphabetSortingOrder=1&userId=' + this.userId + '&clientId=' + this.clientFilterId;;
          
          // let Tier = this.checkTreeArray.join();
          // if (Tier != null && Tier != '') {
          //     var tierString = '&tierId=' + Tier.toString();
          //     var URL = URL.concat(tierString);
          // }
          // if (this.checkTreeArray.length == 0) {    
          //     this.siteList = []
          //     this.selectedSite = [];
          // }else {
              this.apiService.getAll(URL).subscribe(
                  data => {
                      if (data.statusCode == 100) {
                        if(this.checkTreeArray.length != 0) {
                          this.siteList = data.responseList;
                          let tempSelected = []
                          this.selectedSite.map((obj, index)=> {
                              let findObj = _.find(this.siteList, { siteId: obj.siteId});
                              if (findObj) {
                                  tempSelected.push(obj);
                              }
                          })
                          this.selectedSite = tempSelected;
                        }
                      } else {
                          this.siteList = [];
                      }
                  },
                  error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                  }
              );
          // }
  }

  // Getting Asset List
  getAllAssetList() {
    this.assetDatalist = [];
    this.filterObj.selectedAssetId = 0;
    var URL = 'asset/getAssetByAdmin';
    if(this.clientFilterId!= null && this.clientFilterId != '' && this.clientFilterId != 0) {
      let clientStr = "?clientId=" + this.clientFilterId;
      var URL = URL.concat(clientStr);
    }
    if (this.siteIdForApi != null && this.siteIdForApi != '') {
        var siteString = '&siteId=' + this.siteIdForApi.toString();
        var URL = URL.concat(siteString);
    }
    if (this.tierIdForApi != null && this.tierIdForApi != '') {
        var tierString = '&tierId=' + this.tierIdForApi.toString();
        var URL = URL.concat(tierString);
    }
    // if (this.checkSiteArray.length == 0) {    
    //     this.assetDatalist = [];
    //     // this.selectedAsset = [];
    //     this.filterObj.selectedAssetId = 0;
    // }else {
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                  if(this.checkSiteArray.length != 0) {
                    this.assetDatalist = data.responseList;
                    let tempSelected = []
                    this.selectedAsset.map((obj, index)=> {
                        let findObj = _.find(this.assetDatalist, { assetId: obj.assetId});
                        // console.log(findObj)
                        if (findObj) {
                            tempSelected.push(obj);
                        }
                    })
                    this.selectedAsset = tempSelected;
                  }
                } else {
                    this.assetDatalist = [];
                    this.selectedAsset = [];
                }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    // }
  }
  

}
