import { Component, OnInit ,ViewChild, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, ActivationEnd, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService , AlertService, CommonServicesService } from '../../../../services/index';
// declare var $ : any,moment:any;
// declare var jQuery : any;
import Swal from 'sweetalert2'
import { jqxTreeComponent } from '../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../jqwidgets-ts/angular_jqxdropdownbutton'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import { CookieService } from 'ngx-cookie-service';
import { CompileShallowModuleMetadata } from '@angular/compiler';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

	@Component({
		selector: 'app-header',
		templateUrl: './header.component.html',
		styleUrls: ['./header.component.css']
	})
	export class HeaderComponent implements OnInit {

		clientUser: any = JSON.parse(localStorage.getItem("clientUser"));
		isGlobalUser : any = JSON.parse(localStorage.getItem("isGlobalUser"));
		clientId	: any = localStorage.getItem("clientId");
		userId		: any = localStorage.getItem("userId");
		profileImg 	: any = "assets/img/favicon.ico";
		dropdownList 	: any	=	[];
		selectedItems :	any={};
		checkCustomerArray	:	any	=	[];
		dropdownSettings	:	any={
			singleSelection	: false,
			idField					: 'clientId',
			textField				: 'description',
			selectAllText		: 'Select All',
			unSelectAllText	: 'UnSelect All',
			itemsShowLimit	: 0,
			allowSearchFilter: true
		};
		dataTable			: any;
		searchResult  : any = [];
		searchkey 		: any;
		isPermission 	: any;
		permissionData: any;
		subscription	: Subscription;
		message		: any;
		filterId 	: any = 0;
		userType 	: any = this.clientUser ?  this.clientUser.userType : '';
		intervalObj:any;
		intervalObjToken: any;
		innerLogo:any = 'assets/img/cdm_logo_w.png';
		isDropdownDisable = false
		emailToken : any = localStorage.getItem("EmailCallToken");
		hideClientFilter : boolean = false;
		subscriptiondata:any;

		constructor(
			private router:Router,
			private apiService: ApiService,
			private chRef: ChangeDetectorRef,
			private alertService: AlertService,
			private CommonServicesService: CommonServicesService,private route: ActivatedRoute, private ck:CookieService
		) {
				// this.permissionData = ApiService.permission;
	      // this.isPermission = ApiService.isPermission;
				//
	      // if (ApiService.isPermission == 0 || ApiService.isPermission == undefined) {
	      //     ApiService.permission = (localStorage.getItem("permission")?JSON.parse(localStorage.getItem("permission")):'');
				//
	      //     if(this.permissionData){
	      //         this.permissionData = ApiService.permission;
	      //     }else{
	      //         localStorage.clear();
	      //     }
				//
		  // }

		  this.subscriptiondata = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
		  .subscribe(
			message => {
			  this.filterId = message.id;
			 console.log(message, "header subscribe");
			 for (let index = 0; index < this.dropdownList.length; index++) {
				const element = this.dropdownList[index];
				// check for all client to be used in breadcrumb
				if(this.filterId==0){
					localStorage.setItem('clientName',"All Client")	
					this.CommonServicesService.setDashboardClientName('');	
					break;
				}else{
					if (element.clientId == this.filterId) {
						localStorage.setItem('clientName',this.dropdownList[index].description)	
						this.CommonServicesService.setDashboardClientName(this.dropdownList[index].description);	
						break;
					}
				}
			}
	
			 
			},
			error=>{
			  console.log(error)
			}
		  );
		  
		  let clientId = localStorage.getItem('ClientID') ? localStorage.getItem('ClientID') : null

		//   if (clientId) {
		// 	  this.sendClientId(clientId)
		// 	  this.filterId = clientId;
		//   }
			}

		

		
		sendClientId(id): void {
		// send Client id to subscribers via observable subject
		localStorage.setItem('ClientID',id)
		this.CommonServicesService.sendClientId(id);
		for (let index = 0; index < this.dropdownList.length; index++) {
			const element = this.dropdownList[index];
			// check for all client to be used in breadcrumb
			if(id==0){
				localStorage.setItem('clientName',"All Client")	
				this.CommonServicesService.setDashboardClientName('');	

				break;
			}else{
				if (element.clientId == id) {
					localStorage.setItem('clientName',this.dropdownList[index].description)	
					this.CommonServicesService.setDashboardClientName(this.dropdownList[index].description);	
					break;
				}
			}
		}
		// this.dropdownList.find((function(obj){
		// 	if (obj.clientId == id) {
		// 		localStorage.setItem('clientName',this.dropdownList[0].description)		
		// 	}
		// }))
    }

		ngOnInit() {
			this.hideClientFilter = false;
			setTimeout(function(){
				this.filterId = $('#client_dropdown_top').children("option:selected").val();
				console.log('test', $('#client_dropdown_top').children("option:selected").val());
			}, 500);
			
			var logdata = JSON.parse(localStorage.getItem("clientUser"));

			// if(this.emailToken != '0' && this.emailToken != null){
			// 	this.router.navigate(['/callcenter/call-center-instruction',this.emailToken]);
			// 	return;
			// }else if(logdata.userType == 'CallCenter'){
			// 	this.router.navigate(['/callcenter/call-center-dashboard']);
			// 	return;
			// } else{
			// 	// this.router.navigate(['/dashboard']);
			// }	

			this.innerLogo = localStorage.getItem('logoImage') ? localStorage.getItem('logoImage') : 'assets/img/cdm_logo_w.png';
			if(this.clientUser){
				this.userType = this.clientUser.userType ?  this.clientUser.userType : '';
			}
		// Capture the Enter Key press event for Global Search
			var _this = this;
			$(document).on('keypress',function(e) {
				if(e.which == 13) {
					_this.globalSearch()
				}
			})
			if (this.userType=='CallCenter') {
				this.CommonServicesService.setClientFlag(true)
			}
			this.clientUser = JSON.parse(localStorage.getItem("clientUser"));
			if (!this.clientUser) {
					localStorage.clear();
					this.router.navigate(['/login']);
					// Swal({
					// 	position: 'top-end',
					// 	type: 'error',
					// 	title: "Please login!",
					// 	showConfirmButton: false,
					// 	timer: 3500
					// })
					return;
			}
			else{
				this.getClientList();
			}
			
			this.profileImg = this.clientUser.imageLocation ? this.apiService.imageBasePath+this.clientUser.imageLocation :"assets/img/favicon.ico";

			let pathName = window.location.pathname;
			let pathUrlArr = pathName.split('/');
			if (pathUrlArr.length > 2) {
				let pathId = pathUrlArr[2];
				// console.log("pathId--->",pathId)
				if (pathName == '/addclient/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/clientdetail/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/users/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/sites/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/clientRoles/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/assets/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/customer/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/tierclient/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/notifications/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/branding/'+pathId){
					this.hideClientFilter = true;
				}else if(pathName == '/clientuserdetail/'+pathId){
					this.hideClientFilter = true;	
				}else{
					this.hideClientFilter = false;
				}
			}else{
				if (pathName == '/client') {
					this.getClientListUser();
					this.hideClientFilter = true;
				}else if (pathName == '/addclient'){
					this.hideClientFilter = true;
				}else if(pathName == '/clientuserdetail'){
					this.hideClientFilter = true;	
				}else{
					this.hideClientFilter = false;
				}
			}
		
			this.router.events.subscribe(val => {
				window.scrollTo(0,0);
				// console.log("this.router.url",this.router.url);
				if (localStorage.getItem("clientUser")) {
					this.clientUser = JSON.parse(localStorage.getItem("clientUser"));
					this.profileImg = this.clientUser ? (this.clientUser.imageLocation ? this.apiService.imageBasePath+this.clientUser.imageLocation : 'assets/img/favicon.ico') :"assets/img/favicon.ico";
				}
				if (val instanceof NavigationStart) {
					// console.log("route change",val.url,val)
					let clientUrlArr = val.url.split('/');
					// console.log(clientUrlArr, '-------------------on NavigationStart');

					if (clientUrlArr.length > 2) {
						let urlId = clientUrlArr[2];
						if(val.url == '/addclient/'+urlId) {
							this.hideClientFilter = true;
						}else if(val.url == '/clientdetail/'+urlId){
							this.hideClientFilter = true;
						}else if(val.url == '/users/'+urlId){
							this.hideClientFilter = true;
						}else if(val.url == '/sites/'+urlId){
							this.hideClientFilter = true;
						}else if(val.url == '/clientRoles/'+urlId){
							this.hideClientFilter = true;
						}else if(val.url == '/assets/'+urlId){
							this.hideClientFilter = true;
						}else if(val.url == '/customer/'+urlId){
							this.hideClientFilter = true;
						}else if(val.url == '/tierclient/'+urlId){
							this.hideClientFilter = true;
						}else if(val.url == '/notifications/'+urlId){
							this.hideClientFilter = true;
						}else if(val.url == '/branding/'+urlId){
							this.hideClientFilter = true;
						}else if(val.url == '/clientuserdetail/'+urlId){
							this.hideClientFilter = true;	
						}else{
							this.hideClientFilter = false;
						}
					}else{
						if (val.url == '/client') {
							this.getClientListUser();
							this.hideClientFilter = true;
							// this.filterId = 0;
							// this.CommonServicesService.sendClientId(this.filterId);
						}else if (val.url == '/addclient'){
							this.hideClientFilter = true;
						}else if(val.url == '/clientuserdetail'){
							this.hideClientFilter = true;	
						}else{
							this.hideClientFilter = false;
							// this.filterId = this.dropdownList.length ? this.dropdownList[0].clientId : 0;
							// this.CommonServicesService.sendClientId(this.filterId);
						}
					}
				}
				if (val instanceof NavigationEnd) {
					// console.log("route change 2",val)
					let arr1 = val.url.split('/')
					console.log(arr1, '-------------------on NavigationEnd');

					if (arr1.length>1) {
						if (arr1[1] == ('sites' || 'clientdetail' || 'users' || 'clientRoles' || 'assets' || 'customer' || 'tierclient' || 'notifications' || 'branding'  || 'clientuserdetail')) {
							this.isDropdownDisable = true;							
						}
						else if (arr1[1] == ('clientdetail' || 'users' || 'clientRoles' || 'assets' || 'customer' || 'tierclient' || 'notifications' || 'branding'  || 'clientuserdetail')) {
							this.isDropdownDisable = true;
						} 
						else if (arr1[1] == 'clientdetail') {
							this.isDropdownDisable = true;							
						} 
						else if (arr1[1] == 'users') {
							this.isDropdownDisable = true;							
						} 
						else if (arr1[1] == 'clientRoles') {
							this.isDropdownDisable = true;
						} 
						else if (arr1[1] == 'assets') {
							this.isDropdownDisable = true;
						} 
						else if (arr1[1] == 'customer') {
							this.isDropdownDisable = true;							
						} 
						else if (arr1[1] == 'tierclient') {
							this.isDropdownDisable = true;							
						} 
						else if (arr1[1] == 'notifications') {
							this.isDropdownDisable = true;						} 
						else if (arr1[1] == 'branding') {
							this.isDropdownDisable = true;							
						} 
						else if (arr1[1] == 'clientuserdetail') {
							this.isDropdownDisable = true;
						} 
						else if (arr1[1] == 'migration-tool') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} else if (arr1[1] == 'user-tier-migrate') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} else if (arr1[1] == 'client-migrate') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} else if (arr1[1] == 'tier-migrate') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} else if (arr1[1] == 'site-migrate') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} else if (arr1[1] == 'user-migrate') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} else if (arr1[1] == 'customer-migrate') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} else if (arr1[1] == 'add-callCenter') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} 
						else if (arr1[1] == 'setting') {
							this.isDropdownDisable = true;
						} 
						else if (arr1[1] == 'scheduler-setting') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} 
						else if (arr1[1] == 'admin-branding') {
							this.hideClientFilter = true;
							this.isDropdownDisable = true;
						} 
						else if (arr1[1] == 'callCenter-user') {
							this.hideClientFilter = true;
							this.isDropdownDisable = true;
						} 
						else if (arr1[1] == 'add-call-user') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} 
						// disabled drop down as client id from url will be used in this menue start
						else if (arr1[1] == 'assetsummary') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} else if (arr1[1] == 'assetGraph') {
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						} else if (arr1[1] == 'assetUser') {
							this.hideClientFilter = true;
							this.isDropdownDisable = true;
						} else if (arr1[1] == 'assetsLog') {
							this.hideClientFilter = true;
							this.isDropdownDisable = true;
						} else if (arr1[1] == 'datapoint') {
							this.hideClientFilter = true;
							this.isDropdownDisable = true;
						} else if (arr1[1] == 'documents') {
							this.hideClientFilter = true;
							this.isDropdownDisable = true;
						} else if (arr1[1] == 'events') {
							this.hideClientFilter = true;
							this.isDropdownDisable = true;
						} else if (arr1[1] == 'modemtraffic') {
							this.hideClientFilter = true;
							this.isDropdownDisable = true;
						}
						// disabled drop down as client id from url will be used in this menue end
						else if (arr1[1] == 'update_profile') {
							this.isDropdownDisable = true;
						} else if (arr1[1] == 'site' && arr1[2]=='addsite') {
							this.isDropdownDisable = true;
						} else if (arr1[1] == 'addclient') {
							this.isDropdownDisable = true;
						} else if (arr1[1] == 'amci-role-management') {
							this.isDropdownDisable = true;
						} else if (arr1[1] == 'client'){
							this.isDropdownDisable = true;
							this.hideClientFilter = true;
						}
						else {
							this.isDropdownDisable = false;
							this.hideClientFilter = false;
						}
					} else {
						this.isDropdownDisable = false;						
					}
				}
			});


			let Urlarr = this.router.url.split('/')
			// console.log(Urlarr, '-------------------on refresh');
			
			if (Urlarr.length>1) {

				if (Urlarr[1] == ('sites' || 'clientdetail' || 'users' || 'clientRoles' || 'assets' || 'customer' || 'tierclient' || 'notifications' || 'branding'  || 'clientuserdetail')) {
					this.isDropdownDisable = true;					
				}
				else if (Urlarr[1] == ('clientdetail' || 'users' || 'clientRoles' || 'assets' || 'customer' || 'tierclient' || 'notifications' || 'branding'  || 'clientuserdetail')) {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'clientdetail') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'users') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'clientRoles') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'assets') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'customer') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'tierclient') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'notifications') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'branding') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'clientuserdetail') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'migration-tool') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} else if (Urlarr[1] == 'user-tier-migrate') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} else if (Urlarr[1] == 'client-migrate') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} else if (Urlarr[1] == 'tier-migrate') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} else if (Urlarr[1] == 'site-migrate') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} else if (Urlarr[1] == 'user-migrate') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} else if (Urlarr[1] == 'customer-migrate') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				}
				else if (Urlarr[1] == 'setting') {
					this.isDropdownDisable = true;
				} 
				else if (Urlarr[1] == 'scheduler-setting') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} 
				else if (Urlarr[1] == 'admin-branding') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				}
				else if (Urlarr[1] == 'callCenter-user') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				}
				 else if (Urlarr[1] == 'add-call-user') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} 
				// disabled drop down as client id from url will be used in this menue start
				else if (Urlarr[1] == 'assetsummary') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'assetGraph') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'assetUser') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'assetsLog') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'datapoint') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'documents') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'events') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'modemtraffic') {
					this.hideClientFilter = true;
					this.isDropdownDisable = true;
				}
				// disabled drop down as client id from url will be used in this menue end

				else if (Urlarr[1] == 'update_profile') {
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'site' && Urlarr[2]=='addsite') {
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'addclient') {
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'amci-role-management') {
					this.isDropdownDisable = true;
				} else if (Urlarr[1] == 'client'){
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} else if (Urlarr[1] == 'add-callCenter') {
					this.isDropdownDisable = true;
					this.hideClientFilter = true;
				} 
				else {
					this.isDropdownDisable = false;
				}

			} else {
				this.isDropdownDisable = false;						
			}


			

			$(document).ready(function () {
				$("td .dropdown-toggle").click(function(e){
					$('tr').removeClass('active');
					$(this).parents('tr').toggleClass('active');
				});
				$(document).click(function(e){
					$('tr').removeClass('active');
				});

				$('.slide-main-menu li.has-chidren > a').on('click', function(){
					$(this).parent("li.has-chidren").toggleClass("dropdown-active");
					$(this).next("ul.menu-dropdown").slideToggle();
				});
				$('.main > a').on('click', function(){
					$('.sm-toggle').removeClass('smt-active');
					$('body').removeClass('body-smt-active');
					$('.slide-menu-wrap').removeClass('slide-menu-active');
				});
				// var header_height = $("header.main-header").height();
				// $("body").removeClass('login').css({"padding-top": header_height});

				var header_height = $("header.main-header").height();
				$("body").removeClass('login').css({"padding-top": header_height});
				$(window).resize(function(){
					var header_height = $("header.main-header").height();
					$("body").removeClass('login').css({
						"padding-top": header_height
					});
				});
			});

			this.intervalObj = setInterval(()=>{
				this.getUserInfo();
			},300000)
			this.intervalObjToken = setInterval(()=>{
				this.refreshtoken();
			},900000)// 1800000 (30 min)

			if(this.emailToken != '0' && this.emailToken != null){
				this.router.navigate(['/callcenter/call-center-instruction',this.emailToken]);
				return;
			}else if(logdata.userType == 'CallCenter'){
				this.router.navigate(['/callcenter/call-center-dashboard']);
				return;
			} else{
				// this.router.navigate(['/dashboard']);
			}	
		}


		ngOnDestroy(): void {
			//Called once, before the instance is destroyed.
			//Add 'implements OnDestroy' to the class.
			clearInterval(this.intervalObj);
			clearInterval(this.intervalObjToken);
			
		}


		refreshtoken(){
			// console.log("refresh_token");
			let token = JSON.parse(localStorage.getItem('Token'))
			let lasttime = this.ck.get('t11') //localStorage.getItem('t11')
			let isLogin = this.ck.get('isLoginUser')
			// console.log(lasttime,moment().diff(moment(new Date(parseInt(lasttime)))),new Date(parseInt(lasttime)));
			let diff = moment().diff(moment(new Date(parseInt(lasttime))))
			if (!token) {
				return
			}

			if(diff/1000 > 3600 /* 3600 */){
				if (isLogin) {
					
				}else{
					// $('.model').modal('hide');
					$('.modal.show').modal('hide') 
					localStorage.removeItem('Token')            
					localStorage.clear()
					// console.log("redirect-login");
					// this.alertService.sweetMessage('error',"Session timed out.");
					Swal({
						position: 'top-end',
						type: 'error',
						title: 'Session timed out.',
						showConfirmButton: false,
						timer: 10000
					})
					this.router.navigate(['/login']);
					return
				}
			}

			// if(diff/1000 > 500){
			// 	localStorage.removeItem('Token')
			// }
			// if(diff/1000 > 1000){

            // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  uncomment for refresh token
            // -------------------------------------------------------------start -----------------------
			let obj ={
				refresh_token: token.refresh_token,
				grant_type: "refresh_token"
			}
			
			// this.apiService.refreshToken('userauth/oauth/token',obj).subscribe(
			// 	data => {
	
			// // this.apiService.getUserInfoData('user/updateSession?userId='+this.userId+'&token='+token.access_token+'&newToken='+data.access_token).subscribe(
			// 	// 	dataToken => {        
			// 		// 	},
			// 		// 	errorToken => {
			// 			// 		console.log(errorToken);
			// 			// 	}
			// 			// );
			// 			localStorage.setItem("Token", JSON.stringify(data));
						
			// 			document.cookie = "refresh_token=;"//+data.refresh_token
			// 			document.cookie = "refresh_token="+data.refresh_token+'; path=/; expires=' + new Date(moment().add(30, 'days')).toUTCString();
						
			// 		},
			// 		error => {
			// 			console.log(error);
			// 		}
			// 	);
							
			// -------------------------------------------------------------end -----------------------
							
							
							// }
							
							
							
			// let token = JSON.parse(localStorage.getItem('Token'))
			// let lasttime = this.ck.get('t11')
			// // console.log(lasttime,moment().diff(moment(new Date(parseInt(lasttime)))),new Date(parseInt(lasttime)));
			// let diff = moment().diff(moment(new Date(parseInt(lasttime))))
			// if (!token) {
			// 	return
			// }
			// if(diff/1000 > 500 /* 3600 */){
			// 	let isLogin = this.ck.get('islogin')
			// 	if (isLogin) {
					
			// 	}else{
			// 		localStorage.removeItem('Token')            
			// 		localStorage.clear()
			// 		return
			// 	}
			// }
			// // if(diff/1000 > 1200){
			// 	let obj ={
			// 		refresh_token: token.refresh_token,
			// 		grant_type: "refresh_token"
			// 	}
			// 	// console.log(token.access_token, "token");
				
			// 	this.apiService.refreshToken('userauth/oauth/token',obj).subscribe(
			// 		data => {
			// 			// console.log(data.access_token, "tokenc", data);
	
			// 			// this.apiService.getUserInfoData('user/updateSession?userId='+this.userId+'&token='+token.access_token+'&newToken='+data.access_token).subscribe(
			// 			//     dataToken => {        
								
			// 			//     },
			// 			//     errorToken => {
			// 			//         console.log(errorToken);
								
			// 			//     }
			// 			// );
			// 			// setTimeout(() => {
			// 				localStorage.setItem("Token", JSON.stringify(data));
	
			// 				document.cookie = "refresh_token=;"//+data.refresh_token
			// 				document.cookie = "refresh_token="+data.refresh_token
			// 			// }, 500);
						
	
			// 		},
			// 		error => {
			// 			console.log(error);
						
			// 		}
			// 	);
			// // }
			
			
		}	

		// Get User info at the time user loged in
	getUserInfo(){

		let token = JSON.parse(localStorage.getItem('Token'));
		        /* ---------------------------- remove as per 0074192 and 75062----- */

        // if (token && token.access_token) {
        //     fetch(this.apiService.AppUrlService+'/user/getUserInfo', {method:"GET",headers :{ 'Content-Type': 'application/json',  /*  mode: 'no-cors' */  'Authorization': 'bearer '+token.access_token}})
        //         .then((response) => {                    
        //             return response.json();
        //         })
        //         .then((myJson) => {
        //             console.log(myJson);
        //             if (myJson.statusCode == 100) {    
		// 				localStorage.setItem("clientUser", JSON.stringify(myJson.response));
		// 				localStorage.setItem("userId", myJson.response.userId);
		// 				localStorage.setItem("clientId", myJson.response.defaultClientId);
		// 				localStorage.setItem("permission",JSON.stringify(myJson.response.permissions));
        //                 return myJson;
        //                 // localStorage.clear();
        //                 // // localStorage.removeItem("Token");
        //                 // routeNavigate.navigate(['/login']);
        //             }
        //         }).catch((error)=>{
        //             console.log(error.status, error.statusCode, error.errorStatus, error);
        //             return error
        //         });
        // }

	//   this.apiService.getAllDashboard('user/getUserInfo').subscribe(
    //   data => {
    //     if(data.statusCode == 100){
          
    //         localStorage.setItem("clientUser", JSON.stringify(data.response));
    //         localStorage.setItem("userId", data.response.userId);
	// 		localStorage.setItem("clientId", data.response.defaultClientId);
	// 		localStorage.setItem("permission",JSON.stringify(data.response.permissions));
			
    //         var logdata = JSON.parse(localStorage.getItem("clientUser"));

      
	// 		  }else if(data.statusCode == 105){
	// 		    var msg = data.responseMessage;
	// 		  }else{
	// 		 }
	// 	  },
	// 	  error => {
  		
	// 	  }
	//   );
  }


		setCustomer() {
	      // if(this.checkCustomerArray.length > 0 || this.checkTreeArray.length > 0){
	        location.reload();
	      // }
	    }

		 /* On Item Select Site */
  onItemSelect(item: any) {
    var exist = this.checkCustomerArray.indexOf(item.clientId.toString());
    if(exist == '-1'){
        this.checkCustomerArray.push(item.clientId.toString());
      }

  }

  /* On Item DeSelect Site */
  onItemDeselect(item: any){
  var exist = this.checkCustomerArray.indexOf(item.clientId.toString());
    if(exist != '-1'){
        this.checkCustomerArray.splice(exist,1);
      }
  }

  /* On All Select Site */
  onSelectAll(items: any) {
    this.checkCustomerArray = [];
    Object.keys(items).forEach(key=> {
        this.checkCustomerArray.push(items[key]['clientId'].toString());
      });
  }
  /* On All DeSelect Site */
  onDeSelectAll(){
    this.checkCustomerArray = [];
  }


/***************************
  Method for Global Search
***********************************/

    globalSearch(){

			if(this.searchkey) {
	      var keyValue = this.searchkey;
	      this.searchkey = ' '
					if(keyValue.trim()){
						this.router.navigate(['/global-search',keyValue]);
					}
	    }
    }


  logout() {
	this.apiService.logout('userauth/oauth/revokeToken').subscribe(
		data => {
		   if(data.status == 200) {
				localStorage.clear();
				$("body").removeClass('body-smt-active');
				this.router.navigate(['/login']);
				document.cookie = "isLoginUser=;"
				document.cookie = "isLoginUser=;"
				this.ck.delete('isLoginUser');
				this.ck.deleteAll();
				this.ck.delete('refresh_token');
				// this.ck.deleteAll();
			} else {
			}
		},
		error => {
			//this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
		}
		);
}


		toggleSidebar(){
			$('.sm-toggle').toggleClass('smt-active');
			$('.slide-menu-wrap').toggleClass('slide-menu-active');
			$('body').toggleClass('body-smt-active');
		}


		/* Get Client list */
		getClientList(){
			if(this.userType != 'CallCenter'){
				this.apiService.getAll('client/getClientList').subscribe(
					data => {
						this.CommonServicesService.setClientFlag(true)
						if(data.statusCode == 100){
							this.dropdownList = data.responseList;
						 	// this.dropdownList.forEach(function(key, index){							  
							//   if(index == 0) {
							// 	// this.sendClientId(this.dropdownList[0].clientId)
							//   	setTimeout(function(){
							//   		$('.client_'+index).attr('selected', 'true');
							//   		let clientName = $('#client_dropdown_top').children("option:selected").text().trim();
							// 		$('#clientNameBreadcrum').text(clientName);
							// 		localStorage.setItem('clientName',clientName)  			
							// 	  }, 500);
								  							  	 
							//   }
							// });
							for (const clientObj of this.dropdownList) {
								// console.log(clientObj.description.toLowerCase(), clientObj.description);
								if (clientObj.description.toLowerCase() == 'amci') {
									// console.log('-------------', clientObj.clientId);
									this.filterId = clientObj.clientId;
									this.sendClientId(this.filterId)
									localStorage.setItem('clientName',clientObj.description)
									break;
								}
							}
							if (!this.filterId && this.dropdownList.length) {
								let pathName = window.location.pathname;
								// if(pathName != '/client') {
									this.filterId = this.dropdownList[0].clientId
									this.sendClientId(this.dropdownList[0].clientId)
									localStorage.setItem('clientName',this.dropdownList[0].description)
								// }								
							}
							// this.filterId = this.clientId!=null ? (this.clientId!='null' ? this.clientId : (this.dropdownList.length ? this.dropdownList[0].clientId : '')) : (this.dropdownList.length ? this.dropdownList[0].clientId : '')
							// this.sendClientId(this.filterId)
						}else{
							Swal({
								position: 'top-end',
								type: 'error',
								title: data.responseMessage,
								showConfirmButton: false,
								timer: 3500
							})
						}
					},
					error => {
						this.CommonServicesService.setClientFlag(true)
						this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
					}
					);
			}
		}

		getClientListUser(){
			if(this.userType != 'CallCenter'){
				this.apiService.getAll('client/getClientList').subscribe(
					data => {
						if(data.statusCode == 100){
							this.dropdownList = data.responseList;
						 	// this.dropdownList.forEach(function(key, index){							  
							//   if(index == 0) {
							// 	// this.sendClientId(this.dropdownList[0].clientId)
							//   	setTimeout(function(){
							//   		$('.client_'+index).attr('selected', 'true');
							//   		let clientName = $('#client_dropdown_top').children("option:selected").text().trim();
							// 		$('#clientNameBreadcrum').text(clientName);
							// 		localStorage.setItem('clientName',clientName)  			
							// 	  }, 500);
								  							  	 
							//   }
							// });
							// this.sendClientId(this.dropdownList[0].clientId)
							// this.filterId = this.clientId!=null ? (this.clientId!='null' ? this.clientId : (this.dropdownList.length ? this.dropdownList[0].clientId : '')) : (this.dropdownList.length ? this.dropdownList[0].clientId : '')
							// this.sendClientId(this.filterId)
						}else{
							// Swal({
							// 	position: 'top-end',
							// 	type: 'error',
							// 	title: data.responseMessage,
							// 	showConfirmButton: false,
							// 	timer: 1500
							// })
						}
					},
					error => {
						// this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
					}
					);
			}
		}
	}
