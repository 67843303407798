import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServicesService } from 'src/app/services';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html'
})
export class ViewComponent implements OnInit {
  subscription: any;
  isClientList:any = {}
	
  constructor(private router:Router, private CommonServicesService: CommonServicesService) { }

  ngOnInit() {
    this.subscription = this.CommonServicesService.getClientFlag().takeUntil(componentDestroyed(this))
    .subscribe(
      message => {
       console.log(message, "----------------------------");
        this.isClientList = message;
      }
    );
  
  }
  
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    
  }

}


