import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, CommonServicesService, ApiService } from 'src/app/services';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
declare var moment:any;
@Component({
  selector: 'app-new-bill',
  templateUrl: './new-bill.component.html',
  styleUrls: ['./new-bill.component.css']
})
export class NewBillComponent implements OnInit {
  subscription: any;
  clientFilterId: any;
  clientName: string;
  billId: any;
  billDataObj:any = { billingDetail:[], client:{}};
  

  constructor( private route: ActivatedRoute, 
    private apiService: ApiService , 
    private alertService: AlertService, private CommonServicesService: CommonServicesService) { }

  ngOnInit() {
    this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
      .subscribe(
        message => {
          this.clientFilterId = message.id;
          setTimeout(() => {
            this.clientName = localStorage.getItem("clientName");
          }, 1000);
          if (this.clientFilterId) {
            // this.getbillList();
            // this.getAllCustomerList();
            // this.getAllSiteList();
          }else{
            // this.getbillList();
          }
        },
        error=>{
          console.log(error)
        }
    );
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.billId = params['id'];
      }
    });
    if (this.billId) {
      this.getbillById();
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    
  }

  getDateFormate(date){
    return moment(date, "YYYY-MM-DD").format('MMMM YYYY');
  }

  getbillById(){
    this.apiService.getAll('billing/billById?billingId='+this.billId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.billDataObj = data.response;
        }else{
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
  }


  calculateTotal(){
    let total = 0
    this.billDataObj.billingDetail.map((item,i)=>{
      total += /* item.price*item.tax/100 + */ item.totalAmount
    })
    return total.toFixed(2);
  }

}
