import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AlertService, CommonServicesService } from '../../../../services/index';
import Swal from 'sweetalert2'
import { Subscription } from 'rxjs';
declare var $: any;
declare var jQuery: any;
declare var d3: any, moment: any;
import 'datatables.net';
import 'datatables.net-bs4';
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";

@Component({
  selector: 'app-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.css']
})
export class SiteManagementComponent implements OnInit, OnDestroy {

  clientId: any = (localStorage.getItem("clientId") ? JSON.parse(localStorage.getItem("clientId")) : null);
  userId: any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  tierId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
  sitemanagementDatalist: any = [];
  tierClientId: any;
  siteId: any = null;
  dataTable: any;
  dropdownList = [];
  countyList: any = [];
  dropdownSettings = {};
  dropdownSettingsState = {};
  search: any = '';
  county: any = '';
  checkStateArray: any = [];
  countryId: number = 0;
  state: any = '';
  countryList: any = [];
  stateList: any = [];
  clientListData: any = [];
  message: any;
  subscription: Subscription;
  clientFilterId: any = '';
  clientTopDropdownValue: any;
  clientName: any = localStorage.getItem("clientName");
  siteLat: number = 39.381266;
  siteLong: number = -97.922211;
  mapMarkerPositions: any = []
  siteName: string = "";
  zoomLevel: any;
  bounds: any;
  assignAsset: any = [];
  siteHistoryList: any = [];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef,
    private CommonServicesService: CommonServicesService
  ) {
    // subscribe to header component sendClientId to get Client Id
    this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
      .subscribe(
        message => {
          this.clientFilterId = message.id;
          setTimeout(() => {
            this.clientName = localStorage.getItem("clientName");
          }, 1000);
          // if (this.clientFilterId) {
            this.getSiteManagementList();
          // }
        }
      );
  }

  ngOnDestroy() {
    // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }


  ngOnInit() {
    let self = this;
    // self.getSiteManagementList();
    self.getCountryList();
    // self.getClientDropDownList();
    // this.getCountyList();

    this.dropdownSettingsState = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
  }

  /* On Search */
  seachRecord() {
    this.state = this.checkStateArray;
    this.getSiteManagementList();
  }
  /* Reset Search Record */
  resetSearch() {
    this.search = "";
    this.county = "";
    this.countryId = 0;
    this.state = "";
    this.checkStateArray = [];
    this.getSiteManagementList();
    //this.onDeSelectAll();
  }
  /* On Item Select State */
  onItemSelectState(item: any) {
    var exist = this.checkStateArray.indexOf(item.stateId.toString());
    if (exist == '-1') {
      this.checkStateArray.push(item.stateId.toString());
    }
  }

  /* On Item DeSelect State */
  onItemDeselectState(item: any) {
    var exist = this.checkStateArray.indexOf(item.stateId.toString());
    if (exist != '-1') {
      this.checkStateArray.splice(exist, 1);
    }
  }
  /* On All Select State */
  onSelectAllState(items: any) {
    this.checkStateArray = [];
    Object.keys(items).forEach(key => {
      this.checkStateArray.push(items[key]['stateId'].toString());
    });
  }
  /* On All DeSelect State */
  onDeSelectAllState() {
    this.checkStateArray = [];
  }

  /* Get CountyList list */
  getCountyList() {
    if (this.clientId) {
      var URL = 'site/getSiteCountyByClient?clientId=' + this.clientId + '&userId=' + this.userId;
      if (this.customerId != null && this.customerId != '') {
        var customerString = '&customerId=' + this.customerId.toString();
        var URL = URL.concat(customerString);
      }
      if (this.tierId != null && this.tierId != '') {
        var tierString = '&tierId=' + this.tierId.toString();
        var URL = URL.concat(tierString);
      }
      this.apiService.getAll(URL).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.countyList = data.responseList;
          } else {
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }
  /* Get state list by country id */
  getStateListByCountryId(countryId) {
    if (countryId != 0) {
      this.apiService.getAll('state/getStateList?countryId=' + countryId).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.stateList = data.responseList;
          } else {
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

  /* Get Country list */
  getCountryList() {
    this.apiService.getAll('country/getCountry').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.countryList = data.responseList;
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }




  getClientDropDownList() {
    this.apiService.getAll('client/getClientDropDownList').subscribe(
      data => {
        if (data.statusCode == 100) {

          this.clientListData = data.responseList;
          if (this.clientListData.length > 0) {
            this.tierClientId = this.clientListData[0].clientId
            this.clientId = this.clientListData[0].clientId
            // this.getTreeList();
            this.getSiteManagementList()
          }

        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

// getSiteByClientData(){
//   console.log(this.tierClientId)
//   this.clientId = this.tierClientId;
//   this.getSiteManagementList()
// }

/* Get Site Management list */
// getSiteManagementList(){
//   this.clientTopDropdownValue =  $('#client_dropdown_top').children("option:selected").val();
//    var URL = 'site/getAllSiteList?alphabateSortingOreder=1';
//     if(this.clientFilterId != null && this.clientFilterId !=0){
//       var clientIdString     = '&clientId='+this.clientFilterId.toString();
//       var URL = URL.concat(clientIdString);
//     }
//     // if(this.clientFilterId != null && this.clientFilterId != 0){
//     //   var clientString     = '&clientId='+this.clientFilterId.toString();
//     //   var URL = URL.concat(clientString);
//     // } 
//     // else {
//     //   var clientString     = '&clientId='+this.clientTopDropdownValue.toString();
//     //   var URL = URL.concat(clientString);
//     // }
//     this.sitemanagementDatalist = []
//     if ($.fn.DataTable.isDataTable('#data_table')){
//       $('#data_table').DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 5 }]}).destroy();
//     };
//     this.apiService.getAll(URL).subscribe(
//       data => {
//         if(data.statusCode == 100){
//           this.sitemanagementDatalist = data.responseList;
//           // this.chRef.detectChanges();
//           if ($.fn.DataTable.isDataTable('#data_table')){
//             $('#data_table').DataTable().destroy();
//           };
//           const table: any = $('#data_table');
//           this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 5 }]}); 
//         }else{
//           this.sitemanagementDatalist = [];
//           this.chRef.detectChanges();
//           const table: any = $('#data_table');
//           this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 5 }]});
//         }
//       },
//       error =>
//       { 
//         this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
//       }
//   );
// }


getSiteManagementList(){
  this.clientTopDropdownValue = $('#client_dropdown_top').children("option:selected").val();
  var URL = 'site/getAllSiteList?alphabateSortingOreder=1';
  if (this.clientFilterId != null && this.clientFilterId != 0) {
    var clientIdString = '&clientId=' + this.clientFilterId.toString();
    var URL = URL.concat(clientIdString);
  }
  // if(this.clientFilterId != null && this.clientFilterId != 0){
  // var clientString = '&clientId='+this.clientFilterId.toString();
  // var URL = URL.concat(clientString);
  // } 
  // else {
  // var clientString = '&clientId='+this.clientTopDropdownValue.toString();
  // var URL = URL.concat(clientString);
  // }
  this.sitemanagementDatalist = []
  if ($.fn.DataTable.isDataTable('#data_table_site_List')) {
    $('#data_table_site_List').DataTable({ "order": [], "destroy": true, "columnDefs": [{ "orderable": false, "targets": 6 }] }).destroy();
  };
  this.apiService.getAll(URL).subscribe(
    data => {
      if (data.statusCode == 100) {
        this.sitemanagementDatalist = data.responseList;
        // this.chRef.detectChanges();
        if ($.fn.DataTable.isDataTable('#data_table_site_List')) {
          $('#data_table_site_List').DataTable().destroy();
        };
        setTimeout(() => {
          const table: any = $('#data_table_site_List');
          this.dataTable = table.DataTable({ "order": [], "destroy": true, "columnDefs": [{ "orderable": false, "targets": 6 }] });
        }, 600);
      } else {
        this.sitemanagementDatalist = [];
        this.chRef.detectChanges();
        setTimeout(() => {
          const table: any = $('#data_table_site_List');
          this.dataTable = table.DataTable({ "order": [], "destroy": true, "columnDefs": [{ "orderable": false, "targets": 6 }] });
        }, 500);
      }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}

/* Delete Customer Management list */
DeleteSite(sitedatalist){
  Swal({
    title: 'Are you sure?',
    text: "You want to delete this Site.",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.value) {
      this.apiService.delete('site/deleteSite?siteId=' + sitedatalist.siteId, '').subscribe(
        data => {
          if (data.statusCode == 100) {
            Swal({
              position: 'top-end',
              type: 'success',
              title: 'Site Delete Successfully',
              showConfirmButton: false,
              timer: 1500
            });
            this.getSiteManagementList();
          } else {
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  })
}

SiteHistory(sitedatalist){
  this.siteHistoryList = []
  if ($.fn.DataTable.isDataTable('#siteHistoryDatatable')) {
    $('#siteHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('site/getSiteHistory?siteId=' + sitedatalist.siteId).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.siteHistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#siteHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#siteHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}

// show map on site position
setLatLong(lat, long, name){
  this.siteLat = lat;
  this.siteLong = long;
  let arr = []
  arr.push([lat, long]);
  this.mapMarkerPositions = arr
  this.siteName = name;
  this.bounds = new google.maps.LatLngBounds();
  let loc = new google.maps.LatLng(lat, long);
  this.bounds.extend(loc);
  var data = this.bounds.getCenter();
  this.zoomLevel = 21;
}

getAssignAssets(sitedatalist){
  this.assignAsset = [];
  // console.log('getAssignAssets', sitedatalist);
  this.clientTopDropdownValue = $('#client_dropdown_top').children("option:selected").val();
  var URL = 'asset/getAssetByAdmin?siteId=' + sitedatalist.siteId+"&isAssetLive=true";
  if (this.clientFilterId != null && this.clientFilterId != 0) {
    var clientIdString = '&clientId=' + this.clientFilterId.toString();
    var URL = URL.concat(clientIdString);
  }
  if ($.fn.DataTable.isDataTable('#assignAssetTable')) {
    $('#assignAssetTable').DataTable().destroy();
  };
  this.apiService.getAll(URL).subscribe(
    data => {
      if (data.statusCode == 100) {
        this.assignAsset = [];
        this.assignAsset = data.responseList;
        this.chRef.detectChanges();
        setTimeout(() => {
          const table: any = $('#assignAssetTable');
          this.dataTable = table.DataTable({ "bInfo": false });
        }, 100);
      } else {
        this.assignAsset = [];
      }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
  );
}

// setBreadcrumbDataObj(){
//   this.apiService.breadcrumbDataObj = [
//     {
//       name:"Site"
//     }
//   ]
// }
DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}

}
