import { Component, OnInit ,ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, CommonServicesService } from '../../../../services/index';
declare var $ : any;
declare var jQuery : any;
import Swal from 'sweetalert2'
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef,
    private CommonServicesService: CommonServicesService,
    private location :Location
    ){
      // this.permissionData = ApiService.permission;
      // this.isPermission = ApiService.isPermission;
      //
      //
      // if (ApiService.isPermission == 0 || ApiService.isPermission == undefined) {
      //     ApiService.permission = (localStorage.getItem("permission")?JSON.parse(localStorage.getItem("permission")):'');
      //
      //     if(this.permissionData){
      //         this.permissionData = ApiService.permission;
      //     }else{
      //         localStorage.clear();
      //     }
      //
      // }

      // subscribe to header component sendClientId to get Client Id
        this.subscription = this.CommonServicesService.getClientId()
        .subscribe(
          message => {
            this.clientFilterId = message.id;
            this.globalSearch();
          }
        );

  }



  isPermission: any;
  permissionData: any;
  searchResult: any;
  dataTable: any;
  clientId: any = localStorage.getItem("clientId") ? localStorage.getItem("clientId") : 0;;
  userId  : any = localStorage.getItem("userId") ? localStorage.getItem("userId") : 0;
  tierId  : any = (localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);

  customerId:any = (localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);

  searchKey : any = "";
  clientCode: any ='';

  imageUrl = "assets/img/user_icon.png"

  message: any;
  subscription: Subscription;
  clientFilterId : any = 0;
  clientName : any = localStorage.getItem("clientName");

  ngOnInit() {
    // this.clientCode = this.alertService.getClientCode();
    this.route.params.subscribe(params => {
      if (params['key']) {
          this.searchKey = params['key'].trim();
          this.globalSearch()
        }
    });


  }


  ngOnDestroy() {
    // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }



  /***************************
    Method for Global Search
  ***********************************/

  globalSearch(){
    
    if(this.searchKey){

      var URL = 'asset/globalSearch?&userId='+this.userId+'&search='+encodeURIComponent(this.searchKey);

      if(this.clientFilterId !=0 && this.clientFilterId !=null){
          let clientFID = '&clientId='+this.clientFilterId;
          URL = URL.concat(clientFID);
      }

      this.apiService.getAll(URL).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.searchResult = data.responseList;

              // this.sortDataTableData(this.searchResult,'userId',"desc"); // USER LIST
              if ($.fn.DataTable.isDataTable('#searchTable')){
                  $('#searchTable').DataTable().destroy();
              };
              this.chRef.detectChanges();
              const table: any = $('#searchTable');
              this.dataTable = table.DataTable(
                {
                  "order": [],
                  "destroy":true,
                  "bDestroy":true,
                  "columnDefs": [{ "orderable": false, "targets": 5 }]
                }
              );
          }else{
              this.searchResult = [];
              if ($.fn.DataTable.isDataTable('#searchTable')){
                $('#searchTable').DataTable().destroy();
              };
              this.chRef.detectChanges();
              const table: any = $('#searchTable');
              this.dataTable = table.DataTable(
                {
                  "order": [],
                  "destroy":true,
                  "bDestroy":true,
                  "columnDefs": [{ "orderable": false, "targets": 5 }]
                }
              );
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }

}
