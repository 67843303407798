import { Component, OnInit ,ChangeDetectorRef,ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService , AlertService}from '../../../../../../services/index';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
import * as _ from "lodash";

import 'datatables.net';
declare var jQuery : any;
declare var $ : any;
declare var d3:any,moment:any;
import 'datatables.net-bs4';


@Component({
  selector: 'app-call-center-user',
  templateUrl: './call-center-user.component.html',
  styleUrls: ['./call-center-user.component.css']
})
export class CallCenterUserComponent implements OnInit {
  HistoryList: any = [];
  sessionHistoryList: any = [];

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private chRef: ChangeDetectorRef,
    private alertService: AlertService ) { }

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tiersId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  newUserId : any = 0;
  clientDataList:any = [];
  clientIds:any= []
  userDataList : any = [];
  dataTable : any ;
  clientName : any = localStorage.getItem("clientName");

  ngOnInit() {
    this.getCallCenterUserlist();
  }

  // Get Call Center List
  getCallCenterUserlist(){

    if ( $.fn.DataTable.isDataTable('#lsit_dataTable') ) {
      $('#lsit_dataTable').DataTable().destroy();
      this.userDataList = [];
    }
  // &isActive=1
    this.apiService.getAll('user/getAllUserbyType?userType=CallCenter').subscribe(
      data => {
        if(data.statusCode == 100){
          this.userDataList = data.user;
          this.chRef.detectChanges();
          const table: any = $('#lsit_dataTable');
          this.dataTable = table.DataTable({"order": [],"search": { regex: false, smart: false },"columnDefs": [{ "orderable": false, "targets": 4 }]});
        }else{
          this.userDataList = [];
          this.chRef.detectChanges();
          const table: any = $('#lsit_dataTable');
          this.dataTable = table.DataTable({"order": [],"search": { regex: false, smart: false },"columnDefs": [{ "orderable": false, "targets": 4 }]});
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  deleteCallCenterUser(id){
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('user/deleteUser?userId='+id, '').subscribe(
          data => {
            if(data.statusCode == 100){
              this.getCallCenterUserlist();
              this.alertService.sweetMessage('success','Call Center user deleted successfully');
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }


  getHistory(id){
    this.getSessionHistory(id)
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('callcenter/getCallCenterUserHistory?userId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.userList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

getSessionHistory(id){
  this.sessionHistoryList = []
  if ($.fn.DataTable.isDataTable('#sessionHistoryDatatable')) {
    $('#sessionHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('user/getUserSessionHistoryByUserId?userId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.sessionHistoryList = data.userList;
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#sessionHistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
      
}

  DateFormat(dateStr){
    if(dateStr){
        // return moment(dateStr).format("YYYY-MM-DD hh:mm:ss");
        var dateTime = new Date(dateStr);
        return moment(dateTime).format("YYYY-MM-DD HH:MM:SS");
    }else{
        return "-";
    }
  }

  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }
}
