import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService, CommonServicesService}  from '../../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import flatpickr from "flatpickr";
import { Subscription } from 'rxjs';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Location } from '@angular/common';
@Component({
  host: {
    '(document:click)': 'onClick($event)',
    },
  selector: 'app-new-plan',
  templateUrl: './new-plan.component.html',
  styleUrls: ['./new-plan.component.css']
})
export class NewPlanComponent implements OnInit {
  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('tierArea') tierArea: ElementRef;
  @ViewChild('treeArea') treeArea: ElementRef;
  @ViewChild('addDefaultForm') addDefaultForm: any;

  userId: any = localStorage.getItem("userId");
  clientId: any = (localStorage.getItem("clientId")?JSON.parse(localStorage.getItem("clientId")):null);
  clientName : any = localStorage.getItem("clientName");
  data: any = [];
  dataAdapter: any = [];
  source: any = {};
  records: any = [];
  checkTreeArray: any = [];
  tiersArray: any = [];
  selectAllTier: boolean = false;
  tierIdForApi:any;
  disableTreeArray: any = [];
  enableTreeArray:any = [];
  disableTreeLength:any=0;
  newDisableTreeArray:any=[];
  dropdownSettingsSite:any = {};
  siteList: any = [];
  selectedSite: any = [];
  checkSiteArray: any = [];
  siteIdForApi:any;
  dataTable: any;
  filterObj:any = {
    "startDate":new Date(),
    "endDate": new Date(),
    "topClause": 50,
    "selectedAssetId": 0,
    "selectedSite": "",
  }
  showTier: boolean = false;
  hideTempTier: boolean = false;
  tierToggleClass: any = "";
  tierPlaceholder:any = "Select Tier";
  assetDatalist: any = [];
  selectedAsset: any = [];
  dropdownSettingsAsset:any ={};
  checkAssetArray: any = [];
  assetIdForApi:any;
  newCheckDateDiff:boolean = false;
  startDateErr:any = "";
  endDateErr:any = "";
  message: any;
  subscription: Subscription;
  clientFilterId : any = '';
  tierId: number = 0;
  site: any = {
    "siteName": "", "city": "", "isActive": 1, "zipCode": "",
    "longitude": 0.0, "latitude": 0,
    "client": { "clientId": this.clientId },
    "customer": { "customerId": "" },
    "country": { "countryId": "" ,  "countryName": ""},
    "state": { "stateId": 0 },
    "isAutoFetchAssetLocation": false,
    "tier":{"tierId":""}
  };
  hide :  boolean = true;
  planTypeList: any;
  selectedPlan: any;
  createdPlanID: any;
  planTypeListdata: any = [];
  allplanTypeListdata: any = [];
  customplanTypeListdata: any = [];
  newPlanObject:any = {
    "client": {
      "clientId": "",
      // "clientName": "AMCi test"
    },
    "site" : [ ]
    ,
    "planType":{
      "planTypeId":null
    },
      "planName":"",
      "upToRange":"",
    "perUnitPrice":"",
    "price":"",
    "exceedPerUnitPrice":"",
    // "active":"true",
    "expireDate":"",
    "repeatAfterExpire":"",
    "billingRange":{"billingRangeId":10},
    billingRecipient:[],
    "tax":{"taxId":'',}
  }
  clientError: boolean;
  siteError: boolean;
  formSubmitted: boolean;
  PlanDataObj:any = {
    planType:{
      planTypeId:''
    }
  }
  daysList: any = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
  alreadyAddedEmail: boolean;
  newEmail:any = ''
  showEmailUser: boolean = false;
  Taxlist:any  []
  clientUserList:any = [];
  dropdownSettingsUser = {
    singleSelection: false,
    idField: 'userId',
    textField: 'userName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true,
    placeholder : "Select User",
  };

  constructor(private router: Router, 
    private route: ActivatedRoute, 
    private apiService: ApiService , 
    private alertService: AlertService, 
    private chRef: ChangeDetectorRef,
    private location: Location,
    private formatDateService: FormatDateService,
    private CommonServicesService: CommonServicesService,
    private eRef: ElementRef) {
      let self = this;
      // subscribe to header component sendClientId to get Client Id
        this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
        .subscribe(
          message => {
            this.clientFilterId = message.id;
              setTimeout(() => {
                this.clientName = localStorage.getItem("clientName");
              }, 1000);
           
            //this.getLatestLiveFeed();
            // this.getAssetStatusWidget();
          }
        );
    }

    toggle() {
      if(this.hide) {
        this.hide = false;
        console.log('in if', this.hide);
      } else {
        this.hide = true;
        console.log('in else', this.hide);
      }
    }

  ngOnInit() {
    console.log("client--",this.clientFilterId)
    this.setFlatPicker();//initialize date picker using flatpicker
    this.dropdownSettingsSite = {
      singleSelection: false,
      idField: 'siteId',
      textField: 'siteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      placeholder : "Select Site" 
    };
    this.dropdownSettingsAsset = {
      singleSelection: false,
      idField: 'assetId',
      textField: 'assetName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      placeholder : "Select Asset",
    };
    if(this.clientFilterId) {
      // this.getTierList();
      // this.getSiteManagementList();
      // this.getAllAssetList();
    }
    this.getClientList();
    this.getPlanTypeList();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.createdPlanID = params['id'];
      }
    });
    if (this.createdPlanID) {
      this.getPlanById();
    }
    this.getTaxlist();
  }

  getTaxlist(){
    
    this.apiService.getAll('tax/taxes').subscribe(
      data => {
        if(data.statusCode == 100){
         this.Taxlist = data.responseList
        }else{
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      ); 
  }

  getclientUser(id){
    
    this.apiService.getAll('user/getUserDropDownByClient?clientId='+id+'&userType=Client').subscribe(
      data => {
        if(data.statusCode == 100){
         this.clientUserList = data.userList
        }else{
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      ); 
  }

  

  getPlanById(){
    this.apiService.getAll('billingPlan/getById?billingPlanId='+this.createdPlanID).subscribe(
      data => {
        if(data.statusCode == 100){
          this.newPlanObject = data.response
          this.selectedSite = data.response.site
          this.siteList = data.response.site
          if (this.newPlanObject.planType) {
            this.selectedPlan = this.newPlanObject.planType.planTypeId
            this.newPlanObject.planType.planTypeId = JSON.parse(JSON.stringify(this.selectedPlan))
            this.planTypeChangeGet(this.newPlanObject.planType)
          }
          if (this.newPlanObject.client) {
            this.getTierList(this.newPlanObject.client.clientId)
            this.getclientUser(this.newPlanObject.client.clientId)
          }
          if (!this.newPlanObject.tax) {
            this.newPlanObject.tax = {taxId:''}
          }

        }else{
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      ); 
  }

  planTypeChangeGet(planTypeItem){
    // this.newPlanObject.planName = null
    // this.newPlanObject.upToRange = null
	  // this.newPlanObject.perUnitPrice = null
	  // this.newPlanObject.price = null
    // this.newPlanObject.exceedPerUnitPrice = null
    this.planTypeListdata = [];
    this.customplanTypeListdata = [];
    this.apiService.getAll('billingRange/list?planTypeId='+planTypeItem.planTypeId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.allplanTypeListdata = data.responseList; 
          this.allplanTypeListdata.map((obj,index)=>{
            if (obj.customPlan) {
              this.customplanTypeListdata.push(obj)
            } else {
              this.planTypeListdata.push(obj)
            }
          })
          
          
        }else{
          this.allplanTypeListdata = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      );
  }

  getPlanTypeList(){
    
    this.apiService.getAll('planType/list').subscribe(
      data => {
        if(data.statusCode == 100){
          this.planTypeList = data.responseList;   
          if (!this.createdPlanID) {
            
            if (this.planTypeList.length) {
              this.planTypeChange(this.planTypeList[0])
              this.selectedPlan = this.planTypeList.length ? this.planTypeList[0].planTypeId : null;
              this.newPlanObject.planType.planTypeId = this.selectedPlan
            }
          }
        }else{
          this.planTypeList = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      ); 
  }
  
  selectPlanValue(planTypeListdataitem){
    this.newPlanObject.billingRange.billingRangeId = planTypeListdataitem.billingRangeId
    this.newPlanObject.planName = planTypeListdataitem.planName
    this.newPlanObject.upToRange = planTypeListdataitem.upToRange
	  this.newPlanObject.perUnitPrice = planTypeListdataitem.perUnitPrice
	  this.newPlanObject.price = planTypeListdataitem.price
    this.newPlanObject.exceedPerUnitPrice = planTypeListdataitem.exceedPerUnitPrice;
    this.newPlanObject.customPlan = planTypeListdataitem.customPlan ? true  : false
  }

  planTypeChange(planTypeItem){
    if (this.createdPlanID) {
      return
    }
    this.newPlanObject.planName = null
    this.newPlanObject.upToRange = null
	  this.newPlanObject.perUnitPrice = null
	  this.newPlanObject.price = null
    this.newPlanObject.exceedPerUnitPrice = null
    this.newPlanObject.customPlan = null;
    this.selectedPlan = planTypeItem.planTypeId;
    this.newPlanObject.planType.planTypeId = JSON.parse(JSON.stringify(this.selectedPlan))
    this.planTypeListdata = [];
    this.customplanTypeListdata = [];
    this.apiService.getAll('billingRange/list?planTypeId='+planTypeItem.planTypeId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.allplanTypeListdata = data.responseList; 
          this.allplanTypeListdata.map((obj,index)=>{
            if (obj.customPlan) {
              this.customplanTypeListdata.push(obj)
            } else {
              this.planTypeListdata.push(obj)
            }
          })
        }else{
          this.allplanTypeListdata = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      );
  }

  newCustomPlan(){
    this.addDefaultForm.submitted = false;
    this.PlanDataObj = {
      planType:{
        planTypeId:''
      },
      customPlan:true
    }
    $("#CustomPlanModel").modal("show")
  }

  addDefaultPlan(){
    console.log(this.PlanDataObj, '---------------------------');
    console.log(this.PlanDataObj.perUnitPrice, parseFloat(this.PlanDataObj.perUnitPrice));
    this.PlanDataObj.price = parseFloat(this.PlanDataObj.perUnitPrice)*(this.PlanDataObj.upToRange*1000)
    console.log(this.PlanDataObj, this.addDefaultForm.valid);
    // for (let index = 0; index < this.planTypeList.length; index++) {
    //   const element = this.planTypeList[index];
    //   if (element.planTypeId == this.PlanDataObj.planType.planTypeId) {
    //     this.PlanDataObj.planType.name = element.name;
    //     break
    //   }
      
    // }
    if (!this.addDefaultForm.valid) {
      return
    }
    // return
    this.PlanDataObj.upToRange = this.PlanDataObj.upToRange*1000
    // if (this.planId) {
    //   this.PlanDataObj.billingRangeId = this.planId

    //   this.apiService.update('billingRange/update', this.PlanDataObj).subscribe(
    //     data => {
    //        if(data.statusCode == 100){
            
    //         this.location.back();
    //         }else{
    //           this.alertService.sweetMessage('error',data.responseMessage); 
  
    //         }
    //     },
    //     error => {
    //       this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    //     }
    //   );
    // } else {
      this.apiService.create('billingRange/create', this.PlanDataObj).subscribe(
        data => {
           if(data.statusCode == 100){
            $("#CustomPlanModel").modal("hide")
              // this.userDatalist = data.user;
              this.planTypeChangeGet(this.newPlanObject.planType)
            }else{
              this.alertService.sweetMessage('error',data.responseMessage); 
  
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    // }
  }

  ngOnDestroy() {
    // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  //setting FlatPicker Property
  setFlatPicker() {
      var self = this;
      //faltpicke config for end Date
      flatpickr('#endDate',{
        enableTime: false,
        enableSeconds : false,
        // dateFormat: "d-m-Y H:i:S",
        // dateFormat: "m-d-Y H:i:S",
        dateFormat: "Y-m-d",
        defaultDate:new Date(), 
        time_24hr : false,   
        onChange: function(selectedDates, dateStr, instance) {
          self.filterObj.endDate = selectedDates;
        },      
      });
  
      //faltpicke config for start Date
      flatpickr('#startDate',{
        enableTime: false,
        enableSeconds : false,
        // dateFormat: "d-m-Y H:i:S",
        dateFormat: "m-d-Y",
        defaultDate:new Date(), 
        time_24hr : false,  
        onChange: function(selectedDates, dateStr, instance) {
          console.log("selectedDates",selectedDates,"dateStr",dateStr,"instance",instance)
          self.filterObj.startDate = selectedDates;
        }, 
      });
  }

  onClick(event) {
      if (!(this.tierArea.nativeElement.contains(event.target))) {
        this.showTier = false;
      }
  
      if (!this.showTier) { 
        this.tierToggleClass = ""; 
      } else { 
        if (!(this.tierArea.nativeElement.contains(event.target)) && !(this.treeArea.nativeElement.contains(event.target))) {        
         this.showTier = false;
         this.tierToggleClass = "";
        } else {
          this.tierToggleClass = "site-m-after";
        } 
      }
  }

  /* Show tier label in dropdown on tree select */
  myTreeOnSelect(event: any) {
      this.myDropDownButton.close();    
      if((this.disableTreeArray).indexOf(parseInt(event.args.element.id)) != -1){
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
        this.myDropDownButton.setContent(dropDownContent);
        this.tierId = 0;
      } else {
        this.tierId = event.args.element.id;
        let item = this.myTree.getItem(event.args.element);
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + item.label + '</div>';
        this.myDropDownButton.setContent(dropDownContent);
      }
  };

  /* Show tier label in dropdown on get by IdApi */
  showLableInTierOnLoad() {
      if (this.site.tier.tierName && this.site.tier.tierId) {
        this.tierId = this.site.tier.tierId;
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + this.site.tier.tierName + '</div>';
        if (this.myDropDownButton) {
          this.myDropDownButton.setContent(dropDownContent);
        }
      } else {
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
        if (this.myDropDownButton) {
          this.myDropDownButton.setContent(dropDownContent);
        }
      }
  
  }

  showHideTierTree(event) {
    if(!this.showTier){
      this.showTier = true;
      this.tierToggleClass="site-m-after";
    } else {
      this.showTier = false;
      this.tierToggleClass="";
    }
  }

  hideTier() {
    this.showTier = false;
  }
newClientId: any = '';
  // Get all tier list
  getTierList(clientId) {
    this.clientError = false;
    this.tierPlaceholder = "Select Tier";
    this.checkTreeArray = [];
    this.newClientId = clientId//this.newPlanObject.clientId;
    var URL = 'tier/getAllTierByUser?userId=' + this.userId + '&clientId=' + this.newClientId;

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.data = data.responseList;
          
          // Object.keys(this.data).forEach(key => {
          //   if (this.data[key]['flag'] == false) {
          //     this.disableTreeArray.push(this.data[key]['tierId']);
          //   }
          // });
          for (let object of this.data) {
            if(!object.flag) {
              this.disableTreeArray.push(object.tierId.toString());
            }
          }
          // disable tree length
          // this.getEnableDisableTier()
          
          this.source = {
            datatype: 'json',
            datafields: [
                {name: 'tierId'}, 
                {name: 'parentTierId'},
                {name: 'tierName'}
              ],
              id: 'tierId',
              localdata: this.data
          };
          this.dataAdapter = new jqx.dataAdapter(this.source, {
            autoBind: true
          });
          this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [
                            {
                              name: 'tierName',
                              map: 'label'
                            }, 
                            {
                              name: 'tierId',
                              map: 'id'
                            }]);
          var self = this;
          setTimeout(function() {
            // self.treeItemDisable();
          }, 500);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

    // Select all function for tier
    selectAllTree(event){
      this.checkTreeArray = [];
      if (event.target.checked) {
          for (let object of this.data) {
            // if(object.flag){
              this.checkTreeArray.push(object.tierId.toString());
            // }
          }
          setTimeout(() => {
            this.checkedCheckboxOnLoad()
          }, 2000);
      }else{
          this.checkTreeArray = []
          this.checkedCheckboxOnLoad()
          for (let object of this.data) {
              let checkedItem = document.getElementById(object.tierId.toString());
              this.myTree.expandItem(checkedItem);
              this.myTree.checkItem(checkedItem, false);
          }
      }
      if (this.checkTreeArray.length != 0) {
        this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
      }
      setTimeout(() => {
          this.getSiteManagementList();
          // this.getAllAssetList();
      }, 100);
    }

    /* Handle tier tree chacked unchecked Event */
  CheckChange(event) {
    if (event.args.checked == true) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist == '-1') {
        this.checkTreeArray.push(event.args.element.id);
        setTimeout(() => {
          this.getSiteManagementList();
          // this.getAllAssetList();
        }, 100);
      }
    } else if (event.args.checked == false || event.args.checked == null) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist != '-1') {
        this.checkTreeArray.splice(exist, 1);
        setTimeout(() => {
          this.getSiteManagementList();
          // this.getAllAssetList();
        }, 100);
      }
    }

    if (this.checkTreeArray.length == 0) {
      this.dropdownSettingsSite.placeholder = "Select a tier first";
      // this.dropdownSettingsAsset.placeholder = "Select a tier first";
      this.tierPlaceholder = "Select Tier"; 
    }else{
      this.dropdownSettingsSite.placeholder = "Select Site";
      // this.dropdownSettingsAsset.placeholder = "Select Asset";
      this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
    }

    
    if(this.disableTreeLength==0) {
      if (this.data.length == this.checkTreeArray.length) {
        this.selectAllTier = true            
      }else{
        this.selectAllTier = false
      }
    }else{
      let actualLength = this.data.length-this.disableTreeLength;
      if (actualLength == this.checkTreeArray.length) {
        this.selectAllTier = true            
      }else{
        this.selectAllTier = false
      }
    }
  }

    // checked checkbox on load of tree
  checkedCheckboxOnLoad() {
    Object.keys(this.checkTreeArray).forEach(key => {
        let checkedItem = document.getElementById(this.checkTreeArray[key]);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem, true);
    });
  }

  getEnableDisableTier() {
    for (let object of this.data) {
      if(object.flag){
        this.enableTreeArray.push(object.tierId.toString());
      } else {
        this.newDisableTreeArray.push(object.tierId.toString());
      }
    }
    this.disableTreeLength = this.newDisableTreeArray.length;
  }

  // Function to disable items
  treeItemDisable() {
      Object.keys(this.disableTreeArray).forEach(key => {
          let checkedItem = document.getElementById(this.disableTreeArray[key]);
          this.myTree.expandItem(checkedItem);
          this.myTree.disableItem(checkedItem);
      });
      if (this.checkTreeArray.length != 0) {
        this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
      } else {
        this.tierPlaceholder = "Select Tier";
      }
  }



  /* Get Site Management list */
  getSiteManagementList() {
      this.siteList = []
      this.selectedSite = [];
      this.checkSiteArray = [];
      this.siteIdForApi = "";
      this.checkAssetArray = [];
      this.assetDatalist = [];
      this.selectedAsset = [];
      this.assetIdForApi = "";
      this.filterObj.selectedAssetId = 0;
      this.filterObj.selectedSite = "";
  
          var URL = 'site/getSiteDropDownListByClient?alphabetSortingOrder=1&userId=' + this.userId + '&clientId=' + this.newClientId;;
          
          // let Tier = this.checkTreeArray.join();
          // if (Tier != null && Tier != '') {
          //     var tierString = '&tierId=' + Tier.toString();
          //     var URL = URL.concat(tierString);
          // }
          // if (this.checkTreeArray.length == 0) {    
          //     this.siteList = []
          //     this.selectedSite = [];
          // }else {
              this.apiService.getAll(URL).subscribe(
                  data => {
                      if (data.statusCode == 100) {
                        if(this.checkTreeArray.length != 0) {
                          this.siteList = data.responseList;
                          let tempSelected = []
                          this.selectedSite.map((obj, index)=> {
                              let findObj = _.find(this.siteList, { siteId: obj.siteId});
                              if (findObj) {
                                  tempSelected.push(obj);
                              }
                          })
                          this.selectedSite = tempSelected;
                        }
                      } else {
                          this.siteList = [];
                      }
                  },
                  error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                  }
              );
          // }
  }

  // Getting Asset List
  getAllAssetList() {
    this.assetDatalist = [];
    this.filterObj.selectedAssetId = 0;
    var URL = 'asset/getAssetByAdmin';
    if(this.newClientId!= null && this.newClientId != '' && this.newClientId != 0) {
      let clientStr = "?clientId=" + this.newClientId;
      var URL = URL.concat(clientStr);
    }
    if (this.siteIdForApi != null && this.siteIdForApi != '') {
        var siteString = '&siteId=' + this.siteIdForApi.toString();
        var URL = URL.concat(siteString);
    }
    if (this.tierIdForApi != null && this.tierIdForApi != '') {
        var tierString = '&tierId=' + this.tierIdForApi.toString();
        var URL = URL.concat(tierString);
    }
    // if (this.checkSiteArray.length == 0) {    
    //     this.assetDatalist = [];
    //     // this.selectedAsset = [];
    //     this.filterObj.selectedAssetId = 0;
    // }else {
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                  if(this.checkSiteArray.length != 0) {
                    this.assetDatalist = data.responseList;
                    let tempSelected = []
                    this.selectedAsset.map((obj, index)=> {
                        let findObj = _.find(this.assetDatalist, { assetId: obj.assetId});
                        // console.log(findObj)
                        if (findObj) {
                            tempSelected.push(obj);
                        }
                    })
                    this.selectedAsset = tempSelected;
                  }
                } else {
                    this.assetDatalist = [];
                    this.selectedAsset = [];
                }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    // }
  }


  //----------------client dropdown section-----------------
  clientDropDownList: any = [];
  /* Get Client list */
  getClientList(){
      this.apiService.getAll('client/getClients').subscribe(
        data => {
          this.CommonServicesService.setClientFlag(true)
          if(data.statusCode == 100){
            this.clientDropDownList = data.responseList;   
          }else{
           
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
        );    
  }

  /*---------------------Site--------------- */

  /* On item select site */
  onItemSelectSite(item: any) {
    this.siteError = false;
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if (exist == '-1') {
        this.checkSiteArray.push(item.siteId.toString());
        this.siteIdForApi = this.checkSiteArray.join();
        if(this.siteList.length == this.checkSiteArray.length) {
        }
        // this.getAllAssetList();
    }
  }

  /* On item de-select site */
  onItemDeselectSite(item: any) {
    this.siteError = false;
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if (exist != '-1') {
        this.checkSiteArray.splice(exist, 1);
        this.siteIdForApi = this.checkSiteArray.join();
        // this.getAllAssetList();
    }
  }

  /* On select all site */
  onSelectAllSite(items: any) {
    this.siteError = false;
    this.checkSiteArray = [];
    this.siteIdForApi = this.checkSiteArray.join();
    Object.keys(items).forEach(key => {
        this.checkSiteArray.push(items[key]['siteId'].toString());
        this.siteIdForApi = this.checkSiteArray.join();
    });
    
    setTimeout(()=> {
      // this.getAllAssetList();
    }, 500);
  }

  /* On de-select all site */
  onDeSelectAllSite() {
    this.siteError = false;
    this.checkSiteArray = [];
    this.siteIdForApi = this.checkSiteArray.join();
    // this.getAllAssetList();
    this.checkAssetArray = [];
    this.assetDatalist = [];
    this.selectedAsset = [];
    this.assetIdForApi = "";
  }


  /*-----------------Asset-------------------*/
  /* On Item Select Asset */
  onItemSelectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetId.toString());
    if (exist == '-1') {
        this.checkAssetArray.push(item.assetId.toString());
        this.assetIdForApi = this.checkAssetArray.join();
    }
  }

  /* On Item DeSelect Asset */
  onItemDeselectAsset(item: any) {
      var exist = this.checkAssetArray.indexOf(item.assetId.toString());
      if (exist != '-1') {
          this.checkAssetArray.splice(exist, 1);
          this.assetIdForApi = this.checkAssetArray.join();
      }
  }

  /* On All Select Asset */
  onSelectAllAsset(items: any) {
      this.checkAssetArray = [];
      Object.keys(items).forEach(key => {
          this.checkAssetArray.push(items[key]['assetId'].toString());
          this.assetIdForApi = this.checkAssetArray.join();
      });
  }

  /* On All DeSelect Site */
  onDeSelectAllAsset() {
      this.checkAssetArray = [];
      this.assetIdForApi = this.checkAssetArray.join();
  }
  // Function to format Date
  DateFormat(dateStr){
    if(dateStr){
        // return moment(dateStr).format("YYYY-MM-DD hh:mm:ss");
        var dateTime = new Date(dateStr);
        return moment(dateTime).format("YYYY-MM-DD");
    }else{
        return "";
    }
  }
invoiceData: any = {};

  SavePlanData() {
    this.formSubmitted = true;
    this.clientError = false;
    this.siteError = false;
    let siteArr = [], isError = false
    console.log(this.selectedSite);
    if (this.selectedSite.length == 0) {
      isError = true;
      this.siteError = true;
    }
    this.selectedSite.map((obj,i)=>{
      siteArr.push({siteId:obj.siteId})
    })
    
    this.newPlanObject.site = siteArr
    console.log(this.newPlanObject, "-------------------------------", JSON.stringify(this.newPlanObject));
    
    if(!this.newPlanObject.upToRange){
      this.alertService.sweetMessage('error', 'Please select plan')
      isError = true
    }
    if(!this.newPlanObject.client.clientId){
      this.alertService.sweetMessage('error', 'Please select client')
      this.clientError = true
      isError = true
    }
    if (isError) {
      return
    }
    // this.newPlanObject.planName = planTypeListdataitem.planName
    // this.newPlanObject.upToRange = planTypeListdataitem.upToRange
	  // this.newPlanObject.perUnitPrice = planTypeListdataitem.perUnitPrice
	  // this.newPlanObject.price = planTypeListdataitem.price
	  // this.newPlanObject.exceedPerUnitPrice = planTypeListdataitem.exceedPerUnitPrice
    console.log(this.newPlanObject, "-------------------------------", JSON.stringify(this.newPlanObject));
    // return
    // this.startDateErr = "";
    // this.endDateErr = "";

    // this.showTier = false;
    // let startDate = this.DateFormat(this.filterObj.startDate);
    // let endDate = this.DateFormat(this.filterObj.endDate);
    // this.tierIdForApi = this.checkTreeArray.join();

    // if(startDate=="" && endDate==""){
    //   this.startDateErr = "Start Date is required"
    //   this.endDateErr = "End Date is required"
    //   return;
    // }
    // if(startDate==""){
    //   this.startDateErr = "Start Date is required"
    //   return;
    // }
    // if(endDate==""){
    //   this.endDateErr = "End Date is required"
    //   return;
    // }
    
    if (this.createdPlanID) {
      this.newPlanObject.id = this.createdPlanID
      this.apiService.update('billingPlan/update', this.newPlanObject).subscribe(
        data => {
           if(data.statusCode == 100){
            //  this.invoiceData = data.response ? data.response : {}; 
            //  console.log('consumption data', this.invoiceData)
            this.location.back();
              // this.userDatalist = data.user;
            }else{
             this.alertService.sweetMessage('error',data.responseMessage); 
          // this.alertService.errorMessage(data, data.status, "Something went wrong, please try again ");
  
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    } else {
      this.apiService.create('billingPlan/create', this.newPlanObject).subscribe(
        data => {
           if(data.statusCode == 100){
            //  this.invoiceData = data.response ? data.response : {}; 
            //  console.log('consumption data', this.invoiceData)
            this.location.back();
              // this.userDatalist = data.user;
            }else{
              this.alertService.sweetMessage('error',data.responseMessage); 
  
            }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
        
  }

  addEmail() {
    var email_id = $('#newEmail').val();
    let self = this
    if(email_id!=""){
        // var exist = this.newPlanObject.billingRecipient.indexOf(email_id);
        var exist = this.newPlanObject.billingRecipient.findIndex((obj)=> obj.email == email_id)
        if (exist == '-1') {
            this.alreadyAddedEmail = false;
            this.newPlanObject.billingRecipient.push({email:email_id});   
            setTimeout(function(){
                // $('#newEmail').val('');
                self.newEmail = ''
            }, 100);
        }else{
            this.alreadyAddedEmail = true;
        }
    }
    // this.hideUserandEmailValidatonMsg();
}

removeEmail(email){
    // var exist = this.newPlanObject.billingRecipient.indexOf(email);
    var exist = this.newPlanObject.billingRecipient.findIndex((obj)=> obj.email == email)

    if (exist != '-1') {
        this.newPlanObject.billingRecipient.splice(exist, 1);
    }
}

ToggleEmailMenu(){
  this.showEmailUser = !this.showEmailUser
}
}