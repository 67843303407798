import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService } from '../../../../../services/index';
// import * as $ from 'jquery'
import * as _ from "lodash";

import 'datatables.net';
declare var jQuery: any;
declare var $: any;
import 'datatables.net-bs4';

@Component({
  selector: 'app-user-tier-migrate',
  templateUrl: './user-tier-migrate.component.html',
  styleUrls: ['./user-tier-migrate.component.css']
})
export class UserTierMigrateComponent implements OnInit {


  tierUserData: any = null;
  tierUserHead: any = {};
  tierUserBody: any = {};
  dataTable: any;
  clientName: any = localStorage.getItem("clientName");

  constructor(private router: Router, private apiService: ApiService, private alertService: AlertService, private chRef: ChangeDetectorRef) { }


  ngOnInit() {
    console.log(this.apiService.tierUserMigration)
    if (this.apiService.tierUserMigration == null) {
      this.router.navigate(['/migration-tool']);
    }
    else {
      this.tierUserData = this.apiService.tierUserMigration;
      this.tierUserHead = this.tierUserData[0]
      this.tierUserData.splice(0, 1);
      this.tierUserBody = JSON.parse(JSON.stringify(this.tierUserData));
      console.log("tierUserBody",this.tierUserBody)
      if ($.fn.DataTable.isDataTable('#data_table')) {
        $('#data_table').DataTable().destroy();
      }
      // this.chRef.detectChanges();
      setTimeout(() => {
        const table: any = $('#data_table');  
        this.dataTable = table.DataTable({
          "order": []
        });        
      }, 100);
      // this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 6 }]});

    }
  }

}
