import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
//import { LoginModule } from './login/login.module';
import { ViewModule } from './admin/view.module';
import { DashboardClassicComponent } from './Classic/dashboard-classic.component';
import { CompanyComponent } from './Classic/company/company.component';
import { TransferSiteComponent } from './Classic/transfer-site/transfer-site.component';
import { AssetComponent } from './Classic/asset/asset.component';

@NgModule({
  imports: [
    CommonModule,
  //  LoginModule,
    ViewModule,
    FormsModule
  ],
  declarations: [
    DashboardClassicComponent,
    CompanyComponent,
    TransferSiteComponent,
    AssetComponent
  ]
})
export class StructureModule { }
