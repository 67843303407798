import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import * as _ from "lodash";

@Component({
  selector: 'app-addrole',
  templateUrl: './addrole.component.html',
  styleUrls: ['./addrole.component.css']
})
export class AddRoleComponent implements OnInit {

clientId: any = (localStorage.getItem("clientId")?JSON.parse(localStorage.getItem("clientId")):null);
userId: any = localStorage.getItem("userId");
roleId: number = 0;
permissionData:any=[];
groupName :any={};
clientList:any=[];
roleArray:any=[];
roleFilter :any =[];
AllroleArray:any=[];
roleDatalist:any=[];
// roleData: any = {"roleName":"","client":{"clientId":this.clientId},"permissions":[]}
roleData: any = {"roleName":"","permissions":[]};
clientName : any = localStorage.getItem("clientName");

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService ) { }

  ngOnInit() {
  this.getAllPermissionList();
  this.getAllRoleList();
  this.getClientList();
  // this.roleId = 5;

  this.route.params.subscribe(params => {
      if(params['id']){
      this.roleId = params['id'];
    }
  });
  // if(this.roleId){
  //   this.getRoleById();
  // }
}


/* checkall Check Section */
  checkAll(event,permdata){
    if(event.target.checked == true){
      this.roleArray=[];
      $('.uroles-type-select input').prop('checked', true);
      for(var i=0 ; i < this.permissionData.length; i++) {
        for(var j=0 ; j < this.permissionData[i].permissions.length; j++) {
          this.roleArray.push(this.permissionData[i].permissions[j].permissionId);
        }
      }
    }else {
      $('.uroles-type-select input').prop('checked', false);
      this.roleArray=[];
    }
  }

getClientID(id){
  this.roleData.client.clientId = id;
}

getClientList(){
      this.apiService.getAll('client/getClientList').subscribe(
        data => {
          if(data.statusCode == 100){
            this.clientList = data.responseList;

          }else{

            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
        );
    }

  /* checked permssion by Section */
  checkTick(event,permData){
    this.groupName = permData.groupName;
    if(event.target.checked == true){
      for(var i=0 ; i < permData.permissions.length; i++) {
        this.roleArray.push(permData.permissions[i].permissionId);
        $('.inner-label input[name='+ this.groupName +']').prop('checked', true);
        this.SelectAll();
      }
      this.roleArray = _.uniq(this.roleArray);
    }else{
      for(var i=0 ; i < permData.permissions.length; i++) {
        $('.inner-label input[name='+ this.groupName +']').prop('checked', false);
        $('.select-all input').prop('checked', false);
        var index = _.indexOf(this.roleArray, permData.permissions[i].permissionId, 0)
        this.roleArray.splice(index,1) ;
      }
    }
  }

/* single Check permssion */
  checkinnerTick(event,permData1,permData){
    this.groupName = permData;
    if(event.target.checked == true){
      this.SelectgroupName();
      this.roleArray.push(permData1);
    }else{
      var index = _.indexOf(this.roleArray, permData1, 0)
      this.roleArray.splice(index,1) ;
      $('.outer-label input[name='+ this.groupName +']').prop('checked', false);
      $('.select-all input').prop('checked', false);
    }
    this.SelectAll()
  }

/* Select All  permmsion & checked inputs */
  SelectAll(){
    var  total_in = $('.outer-label input').length;
    var checked_in = $('.outer-label input[type="checkbox"]:checked').length;
    if(checked_in == total_in){
      $('.select-all input').prop('checked', true);
    }
  }
/* Select Section wise permission & checked inputs */
  SelectgroupName(){
    var  total_input = $('.inner-label input[name='+ this.groupName +']').length;
    var checked_input = $('.inner-label input[name='+ this.groupName +']:checked').length;
    if(checked_input == total_input){
      $('.outer-label input[name='+ this.groupName +']').prop('checked', true);
    }
  }
/* Function for getting All Permission List */
  getAllPermissionList(){
    this.apiService.getAll('permission/getAllPermission').subscribe(
      data => {
        if(data.statusCode == 100){
          this.permissionData = data.responseList;
        }else{
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  /* Create Role */
SubmitRole(){
    if(this.roleId == 0){
      this.AllroleArray = [];
      for(var i=0 ; i < this.roleArray.length; i++) {
        var AllroleArray = {'permissionId':this.roleArray[i]};
        this.AllroleArray.push(AllroleArray);
      }
      this.roleData.permissions =  this.AllroleArray;
      this.roleData.creator =  this.userId;

     this.apiService.create('role/createRole', this.roleData).subscribe(
          data => {
              if(data.statusCode == 100){
                Swal({
                position: 'top-end',
                type: 'success',
                title: 'Role Added Successfully',
                showConfirmButton: false,
                timer: 1500
              })
                this.router.navigate(['/amci-role-management']);
              }else{
                  Swal({
                    position: 'top-end',
                    type: 'error',
                    title: data.responseMessage,
                    showConfirmButton: false,
                    timer: 1500
                  })
                    }
                  },
              error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
        }else{
          for(var i=0 ; i < this.roleArray.length; i++) {
              var AllroleArray = {'permissionId':this.roleArray[i]};
              this.AllroleArray.push(AllroleArray);
            }

          this.roleData.permissions =  this.AllroleArray;
          this.roleData.creator =  this.userId;
          this.apiService.update('role/updateRole', this.roleData).subscribe(
            data => {
              if(data.statusCode == 100){
                Swal({
                position: 'top-end',
                type: 'success',
                title: 'Role Update Successfully',
                showConfirmButton: false,
                timer: 1500
              })
                // this.router.navigate(['/role-management']);
                this.router.navigate(['/amci-role-management']);
              }else{
                  Swal({
                    position: 'top-end',
                    type: 'error',
                    title: data.responseMessage,
                    showConfirmButton: false,
                    timer: 1500
                  })
                    }
                  },
              error => {
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
      }
  }

getRoledetailByID(roleId){
  this.roleId = roleId;
  this.getRoleById();
}
/* Get Role by Id*/
getRoleById(){
    // $(document).find(".roleList").removeClass("inner-shadow");
    // $("#roleBox_"+this.roleId).addClass("inner-shadow");
    $('.uroles-type-select input').prop('checked', false);
    this.roleArray = [];
  this.apiService.getAll('role/getRole?roleId='+this.roleId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.roleData = data.response;
          for(var i=0 ; i < this.roleData.permissions.length; i++) {
            this.roleArray.push(this.roleData.permissions[i].permissionId);
          }
          for(var i=0 ; i < this.roleData.permissions.length; i++) {
            var perID = this.roleData.permissions[i].permissionId;
            $('.inner-label #'+perID).prop('checked', true);
            var groupName = $('.inner-label #'+perID).attr('name')
            this.groupName = groupName;
            this.SelectgroupName();
            setTimeout(() => {
              this.SelectAll();
            }, 1600);
          }
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
         }
      },
   error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }
/* Function for getting All Role List */
  getAllRoleList(){
    // if(this.clientId != null){
    // this.apiService.getAll('role/getRoleListByClient?clientId='+this.clientId+'&userId='+this.userId).subscribe(

    var URL = 'role/getRoleListByClient'

    if(this.clientId !='' && this.clientId != null){
      var clientId   = '?&userType=client&clientId='+this.clientId;
      URL = URL.concat(clientId);
    }else{
      var amciUser   = '?&userType=amci';
      URL = URL.concat(amciUser);
    }

    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.roleDatalist = data.responseList;
          if(this.roleId){
            this.getRoleById();
          }
        }else{
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  // }
}

replacePermissionName(permissionName){
  if (permissionName) {
    let str = permissionName;
    let name = str.replace(/-/g," ")
    return name;
  }
}

}
