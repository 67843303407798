import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService} from '../../../../services/index';
import * as $ from 'jquery'
import * as _ from "lodash";
import Swal from 'sweetalert2'

@Component({
  selector: 'app-migration-tool',
  templateUrl: './migration-tool.component.html',
  styleUrls: ['./migration-tool.component.css']
})

export class MigrationToolComponent implements OnInit {
clientfileName :any= "Select Client txt File";
tierfileName :any= "Select Tier txt File";
sitefileName :any= "Site/Asset txt File";
userfileName :any= "Select User txt File";
customerfileName :any= "Select Customer txt File";
clientfiledata : any = null;
migrateClientData : any = null;
migrateTierData : any = null;
tierfiledata : any = null;
userfiledata : any = null;
customerfiledata : any = null;
migrateSiteData : any = null;
migrateUserData : any = null;
migrateCustomerData : any = null;
sitefiledata : any = null;
clientName : any = localStorage.getItem("clientName");

userTierFileName:string = 'Tier/User txt File';
userTierFileData:string = '';
migrateTierUserData:any = null;
clientsList: any = [];
selectedClient: string = '';
downloadUrl:string = '';
TierUserButtonTitle:string = 'Select Client First';

/* ---Hold Test buttons calling API------ */
holdClientTest:boolean = false;
holdTierTest: boolean = false;
holdCustomerTest: boolean = false;
holdUserTest: boolean = false;
holdSiteAssetTest: boolean = false;
holdeTierUserTest: boolean = false;


/* ---Hold Migrate buttons calling API------ */
holdClientMigrate:boolean = false;
holdTierMigrate: boolean = false;
holdCustomerMigrate: boolean = false;
holdUserMigrate: boolean = false;
holdSiteAssetMigrate: boolean = false;
holdeTierUserMigrate: boolean = false;

constructor(private router:Router, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }

ngOnInit() {
	this.getClientListUser();
  }

/* ---------- On browse Client Csv file  ---------- */
onFileChangeClient(event){
	if(event.target.files && event.target.files.length) {
    if(event.target.files[0].type != 'text/plain'){
	//   this.alertService.sweetMessage('error',"Only .txt files are allowed");
	  Swal({ position: 'top-end', type: 'error',
			title: "Only .txt files are allowed", showConfirmButton: false,
			timer: 10000
		}) ;
      return;
    }else{
      this.clientfileName = event.target.files[0].name;
	  this.clientfiledata = event.target.files[0];
    }

	}
	}
/* ---------- On browse Tier Csv  file  ---------- */
onFileChangeTier(event){
	if(event.target.files && event.target.files.length) {
    if(event.target.files[0].type != 'text/plain'){
	//   this.alertService.sweetMessage('error',"Only .txt files are allowed");
	  Swal({ position: 'top-end', type: 'error',
			title: "Only .txt files are allowed", showConfirmButton: false,
			timer: 10000
		}) ;
      return;
    }else{
      this.tierfileName = event.target.files[0].name;
      this.tierfiledata = event.target.files[0]
    }
	}
	}
/* ---------- On browse Tier Csv file  ---------- */
onFileChangeSite(event){
	if(event.target.files && event.target.files.length) {
    if(event.target.files[0].type != 'text/plain'){
	//   this.alertService.sweetMessage('error',"Only .txt files are allowed");
	  Swal({ position: 'top-end', type: 'error',
			title: "Only .txt files are allowed", showConfirmButton: false,
			timer: 10000
		}) ;
      return;
    }else{
      this.sitefileName = event.target.files[0].name;
      this.sitefiledata = event.target.files[0]
    }
	}
}
/* ---------- On browse Tier Csv file  ---------- */
onFileChangeUser(event){
	if(event.target.files && event.target.files.length) {
    if(event.target.files[0].type != 'text/plain'){
	//   this.alertService.sweetMessage('error',"Only .txt files are allowed");
	  Swal({ position: 'top-end', type: 'error',
			title: "Only .txt files are allowed", showConfirmButton: false,
			timer: 10000
		}) ;
      return;
    }else{
      this.userfileName = event.target.files[0].name;
      this.userfiledata = event.target.files[0]
    }
	}
}
/* ---------- On browse Customer Csv file  ---------- */
onFileChangeCustomer(event){
	if(event.target.files && event.target.files.length) {
    if(event.target.files[0].type != 'text/plain'){
	//   this.alertService.sweetMessage('error',"Only .txt files are allowed");
	  Swal({ position: 'top-end', type: 'error',
			title: "Only .txt files are allowed", showConfirmButton: false,
			timer: 10000
		}) ;
      return;
    }else{
      this.customerfileName = event.target.files[0].name;
      this.customerfiledata = event.target.files[0]
    }
	}
}

/*Function for test Client files*/
	testClient() {
	this.holdClientTest = true;
	let form_data = new FormData();
	form_data.append('file', this.clientfiledata);
		this.apiService.create('migration/testClientMigration', form_data).subscribe(
			data => {
				this.holdClientTest = false;
				if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){
					this.migrateClientData  = form_data;
					this.alertService.sweetMessage('success',"Test Done Successfully");
				}

				else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
					// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
					Swal({ position: 'top-end', type: 'error',
						title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
						timer: 10000
					}) ;
					this.apiService.clientmigration = data.responseList;
					this.router.navigate(['/client-migrate']);

					this.clientfiledata = null;
					this.migrateClientData= form_data;
					this.clientfileName = "Select Client CSV File";
				}
				else{
          Swal({ position: 'top-end', type: 'error',
                 title: data.responseMessage, showConfirmButton: false,
                 timer: 10000
               }) ;
          // this.alertService.sweetMessage('error',data.responseMessage);
			}
			},
			error => {
				this.holdClientTest = false;
				this.clientfiledata = null;
				this.migrateClientData= null;
				this.clientfileName = "Select Client txt File";
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ",10000);
        // this.alertService.sweetMessage('error',"Error in network");
			}
		);
    }



 /*Function for migrate Client files*/
	migrateClient() {
		this.holdClientMigrate = true;
		this.apiService.create('migration/clientMigration', this.migrateClientData).subscribe(
			data => {
				this.holdClientMigrate = false;
				if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){

				this.alertService.sweetMessage('success',"Migration Completed Successfully");
				this.clientfiledata = null;
				this.migrateClientData= null;
				this.clientfileName = "Select Client CSV File";
				}
				else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
					// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
					Swal({ position: 'top-end', type: 'error',
						title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
						timer: 10000
					}) ;
					this.apiService.clientmigration = data.responseList;
					this.clientfiledata = null;
					this.migrateClientData= null;
					this.clientfileName = "Select Client CSV File";
				this.router.navigate(['/client-migrate']);

				}
				else{

          Swal({ position: 'top-end', type: 'error',
                 title: data.responseMessage, showConfirmButton: false,
                 timer: 10000
               }) ;
          // this.alertService.sweetMessage('error',data.responseMessage);
			}
			},
			error => {
				this.holdClientMigrate = false;
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
        // this.alertService.sweetMessage('error',"Error in network");
			}
		);
    }

/*Function for Test Tier  files*/
	testTier() {
	this.holdTierTest = true;
	let form_data = new FormData();
	form_data.append('file', this.tierfiledata);
		this.apiService.create('migration/testClientTierMigration', form_data).subscribe(
			data => {
			this.holdTierTest = false;
			if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){
				this.migrateTierData  = form_data;
			this.alertService.sweetMessage('success',"Test Done Successfully");
				}
					else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
					// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
					Swal({ position: 'top-end', type: 'error',
						title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
						timer: 10000
					}) ;
					this.apiService.tiermigration = data.responseList;
						this.tierfiledata = null;
							this.migrateTierData = null;
							this.tierfileName = "Select Tier CSV File";
						this.router.navigate(['/tier-migrate']);
					}
				else{
          Swal({ position: 'top-end', type: 'error',
                 title: data.responseMessage, showConfirmButton: false,
                 timer: 10000
               }) ;
          // this.alertService.sweetMessage('error',data.responseMessage);
			}
			},
			error => {
				this.holdTierTest = false;
				this.tierfiledata = null;
				this.migrateTierData = null;
				this.tierfileName = "Select Tier txt File";
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
        // this.alertService.sweetMessage('error',"Error in network");
			}
		);
    }

/*Function for Migrate Tier files*/
	migrateTier() {
		this.holdTierMigrate = true;
		this.apiService.create('migration/clientTierMigration', this.migrateTierData).subscribe(
			data => {
				this.holdTierMigrate = false;
				if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){
						this.alertService.sweetMessage('success',"Migration Completed Successfully");
						this.tierfiledata = null;
						this.migrateTierData = null;
						this.tierfileName= "Select Tier CSV File";
				}
				else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
					// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
					Swal({ position: 'top-end', type: 'error',
						title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
						timer: 10000
					}) ;

						this.apiService.tiermigration = data.responseList;
						this.tierfiledata = null;
						this.migrateTierData = null;
						this.tierfileName = "Select Tier CSV File";
						this.router.navigate(['/tier-migrate']);

				}
				else{
          Swal({ position: 'top-end', type: 'error',
                 title: data.responseMessage, showConfirmButton: false,
                 timer: 10000
               }) ;
          // this.alertService.sweetMessage('error',data.responseMessage);
				}
			},
			error => {
				this.holdTierMigrate = false;
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
        // this.alertService.sweetMessage('error',"Error in network");
			}
		);
    }

    /*Function for Test Site/Asset files*/
	testSite() {
	this.holdSiteAssetTest = true;
	let form_data = new FormData();
	form_data.append('file', this.sitefiledata);
		this.apiService.create('migration/testClientSiteMigration', form_data).subscribe(
			data => {
			this.holdSiteAssetTest = false;
			if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){
				this.migrateSiteData  = form_data;
			this.alertService.sweetMessage('success',"Test Done Successfully");
				}
					else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
					// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
					Swal({ position: 'top-end', type: 'error',
						title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
						timer: 10000
					}) ;
					this.apiService.sitemigration = data.responseList;
						this.sitefiledata = null;
						this.migrateSiteData = null;
						this.sitefileName = "Select Site CSV File";
						this.router.navigate(['/site-migrate']);
					}
				else{
          Swal({ position: 'top-end', type: 'error',
                 title: data.responseMessage, showConfirmButton: false,
                 timer: 10000
               }) ;
          // this.alertService.sweetMessage('error',data.responseMessage);
			}
			},
			error => {
				this.holdSiteAssetTest = false;
				this.sitefiledata = null;
				this.migrateSiteData = null;
				this.sitefileName = "Site/Asset txt File";
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
        // this.alertService.sweetMessage('error',"Error in network");
			}
		);
    }

/*Function for migrate Site/Asset files*/
	migrateSite() {
		this.holdSiteAssetMigrate = true;
		this.apiService.create('migration/clientSiteMigration', this.migrateSiteData).subscribe(
			data => {
				this.holdSiteAssetMigrate = false;
				if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){
						this.alertService.sweetMessage('success',"Migration Completed Successfully");
						this.sitefiledata = null;
						this.migrateSiteData = null;
						this.sitefileName= "Select Tier CSV File";
				}
				else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
					// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
					Swal({ position: 'top-end', type: 'error',
						title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
						timer: 10000
					}) ;
						this.apiService.sitemigration = data.responseList;
						this.tierfiledata = null;
						this.migrateSiteData = null;
						this.sitefileName = "Select Site CSV File";
						this.router.navigate(['/site-migrate']);

				}
				else{
          Swal({ position: 'top-end', type: 'error',
                 title: data.responseMessage, showConfirmButton: false,
                 timer: 10000
               }) ;
          // this.alertService.sweetMessage('error',data.responseMessage);
				}
			},
			error => {
				this.holdSiteAssetMigrate = false;
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
        // this.alertService.sweetMessage('error',"Error in network");
			}
		);
    }

    /*Function for Test User files*/
	testUser() {
	this.holdUserTest = true;
	let form_data = new FormData();
	form_data.append('file', this.userfiledata);
		this.apiService.create('migration/userTestMigration', form_data).subscribe(
			data => {
			this.holdUserTest = false;
			if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){
				this.migrateUserData  = form_data;
				this.alertService.sweetMessage('success',"Test Done Successfully");
			}else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
				// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
				Swal({ position: 'top-end', type: 'error',
						title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
						timer: 10000
					}) ;
				this.apiService.usermigration = data.responseList;
					this.userfiledata = null;
					this.migrateUserData = null;
					this.userfileName = "Select User txt File";
					this.router.navigate(['/user-migrate']);
				}else{
          Swal({ position: 'top-end', type: 'error',
                 title: data.responseMessage, showConfirmButton: false,
                 timer: 10000
               }) ;
					// this.alertService.sweetMessage('error',data.responseMessage);
				}
			},
			error => {
				this.holdUserTest = false;
				this.userfiledata = null;
				this.migrateUserData = null;
				this.userfileName = "Select User txt File";
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
        // this.alertService.sweetMessage('error',"Error in network");
			}
		);
    }

/*Function for migrate User files*/
	migrateUser() {
		this.holdUserMigrate = true;
		this.apiService.create('migration/userMigration', this.migrateUserData).subscribe(
			data => {
				this.holdUserMigrate = false;
				if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){
						this.alertService.sweetMessage('success',"Migration Completed Successfully");
						this.userfiledata = null;
						this.migrateUserData = null;
						this.userfileName= "Select User txt File";
				}
				else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
					// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
					Swal({ position: 'top-end', type: 'error',
						title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
						timer: 10000
					}) ;
						this.apiService.sitemigration = data.responseList;
						//this.tierfiledata = null;
						//this.migrateSiteData = null;
						this.userfileName = "Select User txt File";
						this.router.navigate(['/user-migrate']);

				}
				else{
          Swal({ position: 'top-end', type: 'error',
                 title: data.responseMessage, showConfirmButton: false,
                 timer: 10000
               }) ;
					// this.alertService.sweetMessage('error',data.responseMessage);
				}
			},
			error => {
				this.holdUserMigrate = false;
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
				// this.alertService.sweetMessage('error',"Error in network");
			}
		);
    }


    /*Function for Test Customer files*/
    	testCustomer() {
			this.holdCustomerTest = true;
    	let form_data = new FormData();
    	form_data.append('file', this.customerfiledata);
    		this.apiService.create('migration/customerTestMigration', form_data).subscribe(
    			data => {
					this.holdCustomerTest = false;
    			if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){
    				this.migrateCustomerData  = form_data;
    				this.alertService.sweetMessage('success',"Test Done Successfully");
    			}else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
					// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
					Swal({ position: 'top-end', type: 'error',
						title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
						timer: 10000
					}) ;
    				this.apiService.customermigration = data.responseList;
    					this.customerfiledata = null;
    					this.migrateCustomerData = null;
    					this.customerfileName = "Select User txt File";
    					this.router.navigate(['/customer-migrate']);
    				}else{
              Swal({ position: 'top-end', type: 'error',
                     title: data.responseMessage, showConfirmButton: false,
                     timer: 10000
                   }) ;
    					// this.alertService.sweetMessage('error',data.responseMessage);
    				}
    			},
    			error => {
						this.holdCustomerTest = false;
						this.customerfiledata = null;
    					this.migrateCustomerData = null;
    					this.customerfileName = "Select Customer txt File";
						this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
            // this.alertService.sweetMessage('error',"Error in network");
    			}
    		);
        }

    /*Function for migrate User files*/
    	migrateCustomer() {
				this.holdCustomerMigrate = true;
    		this.apiService.create('migration/customerMigration', this.migrateCustomerData).subscribe(
    			data => {
						this.holdCustomerMigrate = false;
    				if(data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")){
    						this.alertService.sweetMessage('success',"Migration Completed Successfully");
    						this.customerfiledata = null;
    						this.migrateCustomerData = null;
    						this.customerfileName= "Select User txt File";
    				}
    				else if(data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")){
						// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
						Swal({ position: 'top-end', type: 'error',
							title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
							timer: 10000
						}) ;
    						this.apiService.customermigration = data.responseList;
    						//this.tierfiledata = null;
    						//this.migrateSiteData = null;
    						this.customerfileName = "Select User txt File";
    						this.router.navigate(['/customer-migrate']);

    				}
    				else{
              Swal({ position: 'top-end', type: 'error',
                     title: data.responseMessage, showConfirmButton: false,
                     timer: 10000
                   }) ;
    					// this.alertService.sweetMessage('error',data.responseMessage);
    				}
    			},
    			error => {
						this.holdCustomerMigrate = false;
						this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
    				// this.alertService.sweetMessage('error',"Error in network");
    			}
    		);
        }

    // downloadFile(fileName){
    //   var request = new XMLHttpRequest();
    //   document.execCommand('SaveAs',true,fileName)
    //   return
    //   console.log(document.execCommand('SaveAs',true,fileName))
    //   if (navigator.msSaveBlob) {
    //     return navigator.msSaveBlob('blob', fileName);
    //   }else {
    //       // for modern browsers
    //       var a = document.createElement('a');
    //       a.href = fileName;
    //       a.download = fileName;
    //       a.style.display = 'none';
    //       document.body.appendChild(a);
    //       a.click();
    //     }
		// }
		
		resetInput(event){
			event.target.value = null;
		}

		/* On browser User and Tier Csv File*/

onFileChangeTierAndUser(event) {
	if (event.target.files && event.target.files.length) {
			if (event.target.files[0].type != 'text/plain') {
					//   this.alertService.sweetMessage('error',"Only .txt files are allowed");
					Swal({
							position: 'top-end', type: 'error',
							title: "Only .txt files are allowed", showConfirmButton: false,
							timer: 10000
					});
					return;
			} else {
					this.userTierFileName = event.target.files[0].name;
					this.userTierFileData = event.target.files[0]
			}
	}

}


/*Function for Test Tier/User files*/
testUserTier() {
	this.holdeTierUserTest = true;
	let form_data = new FormData();
	form_data.append('file', this.userTierFileData);
	this.apiService.create('migration/testTierUserMigration', form_data).subscribe(
			data => {
					this.holdeTierUserTest = false;
					if (data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")) {
							this.migrateTierUserData = form_data;
							this.alertService.sweetMessage('success', "Test Done Successfully");
					}
					else if (data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "failed")) {
							// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
							Swal({
									position: 'top-end', type: 'error',
									title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
									timer: 10000
							});
							this.apiService.tierUserMigration = data.responseList;
							this.userTierFileData = null;
							this.migrateTierUserData = null;
							this.userTierFileName = "Select Tier/User CSV File";
							this.router.navigate(['/user-tier-migrate']);
					}
					else {
							Swal({
									position: 'top-end', type: 'error',
									title: data.responseMessage, showConfirmButton: false,
									timer: 10000
							});
							// this.alertService.sweetMessage('error',data.responseMessage);
					}
			},
			error => {
				this.holdeTierUserTest = false;
				this.userTierFileData = null;
				this.migrateTierUserData = null;
				this.userTierFileName = "Tier/User txt File";
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
					// this.alertService.sweetMessage('error',"Error in network");
			}
	);

}



/*Function for migrate Tier/user files*/
migrateTierUser() {
	this.holdeTierUserMigrate = true;
	this.apiService.create('migration/tierUserMigration', this.migrateTierUserData).subscribe(
			data => {
					this.holdeTierUserMigrate = false;
					if (data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")) {
							this.alertService.sweetMessage('success', "Migration Completed Successfully");
							this.userTierFileData = null;
							this.migrateTierUserData = null;
							this.userTierFileName = "Select Tier/User CSV File";
					}
					else if (data.statusCode == 100 && (data.responseMessage == "Failed" || data.responseMessage == "Failed")) {
							// this.alertService.sweetMessage('error',"Migration Test Failed. Please correct the errors..");
							Swal({
									position: 'top-end', type: 'error',
									title: "Migration Test Failed. Please correct the errors.", showConfirmButton: false,
									timer: 10000
							});
							this.apiService.tierUserMigration = data.responseList;
							this.userTierFileData = null;
							this.migrateTierUserData = null;
							// this.sitefileName = "Select Site CSV File";
							this.userTierFileName = "Select Tier/User CSV File";
							this.router.navigate(['/user-tier-migrate']);

					}
					else {
							Swal({
									position: 'top-end', type: 'error',
									title: data.responseMessage, showConfirmButton: false,
									timer: 10000
							});
							// this.alertService.sweetMessage('error',data.responseMessage);
					}
			},
			error => {
					this.holdeTierUserMigrate = false;
					this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ", 10000);
					// this.alertService.sweetMessage('error',"Error in network");
			}
	);		
}

//call funtion in ngOnInit
getClientListUser() {
	this.apiService.getAll('client/getClientList').subscribe(
			data => {
					if (data.statusCode == 100) {
							this.clientsList = data.responseList;
							console.log("this.dropdownList", this.clientsList)
					} else {

					}
			},
			error => {
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
	);
}


downloadSampleForTierUser() {
	if(this.selectedClient) {
			const URL = 'migration/getTierUserDataDownload?clientId='+this.selectedClient		
			this.apiService.getAll(URL).subscribe(
			data => {
					if (data.statusCode == 100 && (data.responseMessage == "success" || data.responseMessage == "Success")) {
							// console.log(data, "======================data")
					}
					// else {
					// 	this.alertService.sweetMessage('error', data.responseMessage);
					// }
			},
			error => {
					var mainUrl = this.apiService.baseBath+'/'+URL;
					this.downloadUrl = mainUrl;
					$("#downloadReportBtn").attr("href", mainUrl);
					var te = document.getElementById('downloadReportBtn');
					te.click();
			}
	);
	}
}

selectedClientId(event) {
	if(event.target.value != 'SelectClient') {
			this.selectedClient = event.target.value;
			if(this.selectedClient) {
					this.TierUserButtonTitle = '';
			}
	}else {
			this.TierUserButtonTitle = 'Select Client First';		
			this.selectedClient = '';	
	}
}

}
