import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-call-instruction-email',
  templateUrl: './call-instruction-email.component.html',
  styleUrls: ['./call-instruction-email.component.css']
})
export class CallInstructionEmailComponent implements OnInit {

  constructor(
    private router:Router,
    private route : ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
     if(params['id'])
      {
        console.log(params['id'])
          // this.notificationMsgId = params['id'];
          var logdata = JSON.parse(localStorage.getItem("clientUser"));
          if(localStorage.getItem("userId") && logdata.userType == 'CallCenter'){
            localStorage.setItem("EmailCallToken", params['id']);
            this.router.navigate(['/callcenter/call-center-instruction',params['id']]);
          }else{
            console.log(params['id'])
            localStorage.setItem("EmailCallToken", params['id']);
            this.router.navigate(['/login']);

          }

      }
      else{
        localStorage.setItem("EmailCallToken", '0');
      }
    });

  }

}
