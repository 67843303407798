import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../../services/index';
import Swal from 'sweetalert2'
declare var $: any;
declare var jQuery: any;
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../../jqwidgets-ts/angular_jqxdropdownbutton'


@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientdetailComponent implements OnInit {
@ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild("clientContact") clientContact:any;

@ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
clientId: any = localStorage.getItem("clientId");
clientUser: any = JSON.parse(localStorage.getItem("clientUser"));
clientdetailId: any = 0;
userId: any = localStorage.getItem("userId");
countryList:any=[];
stateList:any=[];
contactData:any=[];
dataTable: any;
imgBasePath:any="";
client: any = {
"description":"","maxDaysHistory":"","maxInactivityHours":"","billingName":"","deactivationTime":"",
"requiresPinVerification":false,"isActive":false};

contact: any =
{
  "firstName":"",
  "lastName":"",
  "emailId":"",
  "phoneNumber":"",
  "secondaryPhoneNumber":"",
  "userType":"Client Contact",
  "client":{
    "clientId":this.clientdetailId
  }
}

imageUrl :any =  "assets/img/upload-img.png";
// clientName : any = localStorage.getItem("clientName");
clientName : any = "";
canChangeEmailDomain : any = "";
  breadcrumbObj: any;
  HistoryList: any = [];

constructor(private router:Router, private route: ActivatedRoute,private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }


 ngOnInit() {

//  jQuery('.rst-date').datetimepicker({
//       language: 'en',
//       pickTime: true,
//       pickDate: true,
//       minDate: 0,
//       inline: true,
//       sideBySide: true
//     });
    this.route.params.subscribe(params => {
      if(params['id']){
      this.clientdetailId = params['id'];
      localStorage.setItem('clientdetailId', this.clientdetailId)
    }
  });
  if(this.clientdetailId){
    this.getClientById();
    this.getContactData();
  }
  this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
}


/* Get client by Id*/
getClientById(){
  this.apiService.getAll('client/getClientById?clientId='+this.clientdetailId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.client = data.response;
          this.canChangeEmailDomain = this.client.canChangeEmailDomain;
          this.canChangeEmailDomain = this.client.canChangeEmailDomain;
          let isLive = this.client.isLive;
          localStorage.setItem('clientIsLive', isLive);

          if((this.client.description!=null) && ( this.client.description!="")){
            this.clientName = "";
            this.clientName = this.client.description;
            localStorage.setItem('ClientInfoName',this.clientName)
          }
          if(this.client.logoFile != null){
            this.imageUrl = this.apiService.imageBasePath +this.client.logoFile;
          }
          // this.imgBasePath = this.apiService.imageBasePath+this.client.clientInnerLogoPath;
          // this.imageUrl    = this.apiService.imageBasePath+this.client.logoFile;
          // this.imageUrl    = this.apiService.imageBasePath+this.client.appLogoPath;

          if(data.response.country== null){
            this.client.country = {};
          }
          // localStorage.setItem('ClientName',this.client.description)

          if(data.response.state == null){
            this.client.state = {};
          }

        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
         }
      },
     error =>
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
}


SaveContact() {
  this.contact.client.clientId = this.clientdetailId;
  if (this.clientContact.valid && (this.contact.secondaryPhoneNumber != this.contact.phoneNumber)) {
      if (this.contact.contactTypeId) {
        // this.apiService.update('user/updateClientContact/', this.contact).subscribe(
        this.apiService.update('contactType/updateClientContact', this.contact).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getContactData();
              this.alertService.sweetMessage('success',data.responseMessage);
              this.contact =
                {
                  "firstName": "",
                  "lastName": "",
                  "emailId": "",
                  "phoneNumber": "",
                  "secondaryPhoneNumber": "",
                  "userType": "",
                  client: {}
                }
                this.contact.contactTypeId = '';
                this.clientContact.reset();
                this.clientContact.submitted = false;
            } else {
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error => {
            this.alertService.sweetMessage('error','Error in network');
          }
        );
      } else {
        this.clientContact.submitted = false;
        // this.apiService.create('user/createClientContact', this.contact).subscribe(
        this.apiService.create('contactType/createClientContact', this.contact).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getContactData();
              this.alertService.sweetMessage('success',data.responseMessage);
              this.contact =
                {
                  "firstName": "",
                  "lastName": "",
                  "emailId": "",
                  "phoneNumber": "",
                  "secondaryPhoneNumber": "",
                  "userType": "",
                  client: {}
                }
                this.clientContact.reset();
                this.clientContact.submitted = false;
            } else {
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    }
  }


/* Get Contact data list */
getContactData(){
  if ($.fn.DataTable.isDataTable('#contactTable')){
    $('#contactTable').DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [4] }]}).destroy();
  };
  // this.apiService.getAll('user/getClientContactListByClientId?clientId='+this.clientdetailId).subscribe(
  this.apiService.getAll('contactType/getClientContactListByClientId?clientId='+this.clientdetailId).subscribe(
    data => {
      if(data.statusCode == 100){
        this.contactData = data.contactTypeList;
        // this.chRef.detectChanges();
        // const table: any = $('.table');
        // this.dataTable = table.DataTable();
        this.chRef.detectChanges();
        setTimeout(() => {
          const table: any = $('#contactTable');
          this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [4] }]});
        }, 1000);
      }else{
        this.contactData = [];
        // this.chRef.detectChanges();
        // const table: any = $('.table');
        // this.dataTable = table.DataTable();
        this.chRef.detectChanges();
        const table: any = $('#contactTable');
        this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [4] }]});
      }
    },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
    );
}

 // user/getClientContactById?userId=4286
    getContactById(id){
      this.apiService.getAll('contactType/getContactTypeById?contactTypeId=' +id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.contact = data.contactType;
          } else {
            // this.contactData = [];

          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

     DeleteClientContact(userId){
      Swal({
          title: 'Are you sure?',
          text: "You want to delete this Contact.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.apiService.delete('contactType/deleteClientContact?contactTypeId='+userId, '').subscribe(
            data => {
              if(data.statusCode == 100){
                this.getContactData();
                this.alertService.sweetMessage('success',"Delete Successfully");
              }else{
                this.alertService.sweetMessage('error',data.responseMessage);
              }
            },
            error =>
              { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
            );
          }
        })
    }


  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('contactType/getContactTypeHistoryById?contactTypeId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.contactTypeList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

}
