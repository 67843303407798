import { Injectable } from '@angular/core';
declare var d3:any,moment:any;

@Injectable({
  providedIn: 'root'
})
export class FormatDateService {

  constructor() { }

  formatDate(dateToFormat, gmtOffset = ''){

    var d = moment(dateToFormat, "YYYY.MM.DD HH.mm.ss sssZ").toDate();
  	if(dateToFormat){
      	var formattedDate = moment(d).format("MM-DD-YYYY HH:mm:ss");
        return formattedDate;
    }else{
        return "";
    }
  }

  dateDiffrence(callstart,callend){
    var startTime = moment(callstart, "YYYY.MM.DD HH.mm.ss").toDate();
    var EndTime   = moment(callend, "YYYY.MM.DD HH.mm.ss").toDate();
	  var secNum  = (EndTime-startTime) / 1000;
	  // var secNum  = parseInt((EndTime-startTime) / 1000);
	  var days    = Math.floor(secNum / (3600 * 24));
	  var hours   = (Math.floor((secNum - (days * (3600 * 24))) / 3600));
	  var minutes = (Math.floor((secNum - (days * (3600 * 24)) - (hours * 3600)) / 60));
	  var seconds = (Math.floor(secNum - (days * (3600 * 24)) - (hours * 3600) - (minutes * 60)));
    return hours + ':' + minutes + ':' + seconds;
  }


}
