import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService, CommonServicesService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours} from 'date-fns';
import flatpickr from "flatpickr";
import { Subscription } from 'rxjs';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
@Component({
  host: {
    '(document:click)': 'onClick($event)',
    },
  selector: 'app-consumption-data',
  templateUrl: './consumption-data.component.html',
  styleUrls: ['./consumption-data.component.css']
})
export class ConsumptionDataComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('tierArea') tierArea: ElementRef;
  @ViewChild('treeArea') treeArea: ElementRef;

  userId: any = localStorage.getItem("userId");
  clientId: any = (localStorage.getItem("clientId")?JSON.parse(localStorage.getItem("clientId")):null);
  clientName : any = localStorage.getItem("clientName");
  data: any = [];
  dataAdapter: any = [];
  source: any = {};
  records: any = [];
  checkTreeArray: any = [];
  tiersArray: any = [];
  selectAllTier: boolean = false;
  tierIdForApi:any;
  disableTreeArray: any = [];
  enableTreeArray:any = [];
  disableTreeLength:any=0;
  newDisableTreeArray:any=[];
  dropdownSettingsSite:any = {};
  siteList: any = [];
  selectedSite: any = [];
  checkSiteArray: any = [];
  siteIdForApi:any;
  dataTable: any;
  filterObj:any = {
    "startDate":new Date(),
    "endDate": new Date(),
    "topClause": 50,
    "selectedAssetId": 0,
    "selectedSite": "",
  }
  showTier: boolean = false;
  hideTempTier: boolean = false;
  tierToggleClass: any = "";
  tierPlaceholder:any = "Select Tier";
  assetDatalist: any = [];
  selectedAsset: any = [];
  dropdownSettingsAsset:any ={};
  checkAssetArray: any = [];
  assetIdForApi:any;
  newCheckDateDiff:boolean = false;
  startDateErr:any = "";
  endDateErr:any = "";
  message: any;
  subscription: Subscription;
  clientFilterId : any = '';
  tierId: number = 0;
  site: any = {
    "siteName": "", "city": "", "isActive": 1, "zipCode": "",
    "longitude": 0.0, "latitude": 0,
    "client": { "clientId": this.clientId },
    "customer": { "customerId": "" },
    "country": { "countryId": "" ,  "countryName": ""},
    "state": { "stateId": 0 },
    "isAutoFetchAssetLocation": false,
    "tier":{"tierId":""}
  };
  hide :  boolean = true;
  YearArr = []
  MonthArr = [{value:'01', month: "January"}, {value:'02', month:"February"},{value:'03', month: "March"},{value:'04', month: "April"}, {value:'05', month:"May"},{value:'06', month: "June"} ,{value:'07', month:"July"}, {value:'08', month:"August"}, {value:'09', month:"September"},{value:'10', month: "October"},{value:'11', month:"November"}, {value:'12', month:"December"}];
  alreadyAddedEmail: boolean;
  dropdownSettingsUser = {
    singleSelection: false,
    idField: 'userId',
    textField: 'userName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true,
    placeholder : "Select User",
  };
  billingRecipient: any = [];
  newEmail: string = '';
  clientUserList: any = [];
  showEmailUser: boolean = false;

  constructor(private router: Router, 
    private route: ActivatedRoute, 
    private apiService: ApiService , 
    private alertService: AlertService, 
    private chRef: ChangeDetectorRef,
    private formatDateService: FormatDateService,
    private CommonServicesService: CommonServicesService,
    private eRef: ElementRef) {
      let self = this;
      // subscribe to header component sendClientId to get Client Id
        this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
        .subscribe(
          message => {
            this.clientFilterId = message.id;
              setTimeout(() => {
                this.clientName = localStorage.getItem("clientName");
              }, 1000);
           
            //this.getLatestLiveFeed();
            // this.getAssetStatusWidget();
          }
        );
    }

    toggle() {
      if(this.hide) {
        this.hide = false;
        console.log('in if', this.hide);
      } else {
        this.hide = true;
        console.log('in else', this.hide);
      }
    }

  ngOnInit() {
    for (let index = 0; index < 5; index++) {
      let y = new Date().getFullYear();
      this.YearArr.push(y-index);
      
    }

    console.log("client--",this.clientFilterId)
    this.setFlatPicker();//initialize date picker using flatpicker
    this.dropdownSettingsSite = {
      singleSelection: false,
      idField: 'siteId',
      textField: 'siteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      placeholder : "Select Site" 
    };
    this.dropdownSettingsAsset = {
      singleSelection: false,
      idField: 'assetId',
      textField: 'assetName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true,
      placeholder : "Select Asset",
    };
    if(this.clientFilterId) {
      // this.getTierList();
      // this.getSiteManagementList();
      // this.getAllAssetList();
    }
    this.getClientList();
  }


  ngOnDestroy() {
    // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }


  getclientUser(id){
    
    this.apiService.getAll('user/getUserDropDownByClient?clientId='+id+'&userType=Client').subscribe(
      data => {
        if(data.statusCode == 100){
         this.clientUserList = data.userList
        }else{
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      ); 
  }


  addEmail() {
    var email_id = $('#newEmail').val();
    let self = this
    if(email_id!=""){
        // var exist = this.billingRecipient.indexOf(email_id);
        var exist = this.billingRecipient.findIndex((obj)=> obj.email == email_id)
        if (exist == '-1') {
            this.alreadyAddedEmail = false;
            this.billingRecipient.push({email:email_id});   
            setTimeout(function(){
                // $('#newEmail').val('');
                self.newEmail = ''
            }, 100);
        }else{
            this.alreadyAddedEmail = true;
        }
    }
    // this.hideUserandEmailValidatonMsg();
}

removeEmail(email){
    // var exist = this.billingRecipient.indexOf(email);
    var exist = this.billingRecipient.findIndex((obj)=> obj.email == email)

    if (exist != '-1') {
        this.billingRecipient.splice(exist, 1);
    }
}

ToggleEmailMenu(){
  this.showEmailUser = !this.showEmailUser
}

  //setting FlatPicker Property
  setFlatPicker() {
      var self = this;
      //faltpicke config for end Date
      flatpickr('#endDate',{
        enableTime: false,
        enableSeconds : false,
        // dateFormat: "d-m-Y H:i:S",
        // dateFormat: "m-d-Y H:i:S",
        dateFormat: "m-d-Y",
        defaultDate:new Date(), 
        time_24hr : false,   
        onChange: function(selectedDates, dateStr, instance) {
          self.filterObj.endDate = selectedDates;
        },      
      });
  
      //faltpicke config for start Date
      flatpickr('#startDate',{
        enableTime: false,
        enableSeconds : false,
        // dateFormat: "d-m-Y H:i:S",
        dateFormat: "m-d-Y",
        defaultDate:new Date(), 
        time_24hr : false,  
        onChange: function(selectedDates, dateStr, instance) {
          console.log("selectedDates",selectedDates,"dateStr",dateStr,"instance",instance)
          self.filterObj.startDate = selectedDates;
        }, 
      });
  }

  onClick(event) {
      if (!(this.tierArea.nativeElement.contains(event.target))) {
        this.showTier = false;
      }
  
      if (!this.showTier) { 
        this.tierToggleClass = ""; 
      } else { 
        if (!(this.tierArea.nativeElement.contains(event.target)) && !(this.treeArea.nativeElement.contains(event.target))) {        
         this.showTier = false;
         this.tierToggleClass = "";
        } else {
          this.tierToggleClass = "site-m-after";
        } 
      }
  }

  /* Show tier label in dropdown on tree select */
  myTreeOnSelect(event: any) {
      this.myDropDownButton.close();    
      if((this.disableTreeArray).indexOf(parseInt(event.args.element.id)) != -1){
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
        this.myDropDownButton.setContent(dropDownContent);
        this.tierId = 0;
      } else {
        this.tierId = event.args.element.id;
        let item = this.myTree.getItem(event.args.element);
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + item.label + '</div>';
        this.myDropDownButton.setContent(dropDownContent);
      }
  };

  /* Show tier label in dropdown on get by IdApi */
  showLableInTierOnLoad() {
      if (this.site.tier.tierName && this.site.tier.tierId) {
        this.tierId = this.site.tier.tierId;
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;">' + this.site.tier.tierName + '</div>';
        if (this.myDropDownButton) {
          this.myDropDownButton.setContent(dropDownContent);
        }
      } else {
        let dropDownContent = '<div style="position: relative; margin-left: 3px; margin-top: 4px;"> Select Tier </div>';
        if (this.myDropDownButton) {
          this.myDropDownButton.setContent(dropDownContent);
        }
      }
  
  }

  showHideTierTree(event) {
    if(!this.showTier){
      this.showTier = true;
      this.tierToggleClass="site-m-after";
    } else {
      this.showTier = false;
      this.tierToggleClass="";
    }
  }

  hideTier() {
    this.showTier = false;
  }
newClientId: any = '';
  // Get all tier list
  getTierList() {
    this.newClientId = this.filterObj.client;
    this.tierPlaceholder = "Select Tier";
    this.checkTreeArray = [];

    var URL = 'tier/getAllTierByUser?userId=' + this.userId + '&clientId=' + this.newClientId;

    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.data = data.responseList;
          
          // Object.keys(this.data).forEach(key => {
          //   if (this.data[key]['flag'] == false) {
          //     this.disableTreeArray.push(this.data[key]['tierId']);
          //   }
          // });
          for (let object of this.data) {
            if(!object.flag) {
              this.disableTreeArray.push(object.tierId.toString());
            }
          }
          // disable tree length
          // this.getEnableDisableTier()
          
          this.source = {
            datatype: 'json',
            datafields: [
                {name: 'tierId'}, 
                {name: 'parentTierId'},
                {name: 'tierName'}
              ],
              id: 'tierId',
              localdata: this.data
          };
          this.dataAdapter = new jqx.dataAdapter(this.source, {
            autoBind: true
          });
          this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [
                            {
                              name: 'tierName',
                              map: 'label'
                            }, 
                            {
                              name: 'tierId',
                              map: 'id'
                            }]);
          var self = this;
          setTimeout(function() {
            // self.treeItemDisable();
          }, 500);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

    // Select all function for tier
    selectAllTree(event){
      this.checkTreeArray = [];
      if (event.target.checked) {
          for (let object of this.data) {
            // if(object.flag){
              this.checkTreeArray.push(object.tierId.toString());
            // }
          }
          setTimeout(() => {
            this.checkedCheckboxOnLoad()
          }, 2000);
      }else{
          this.checkTreeArray = []
          this.checkedCheckboxOnLoad()
          for (let object of this.data) {
              let checkedItem = document.getElementById(object.tierId.toString());
              this.myTree.expandItem(checkedItem);
              this.myTree.checkItem(checkedItem, false);
          }
      }
      if (this.checkTreeArray.length != 0) {
        this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
      }
      setTimeout(() => {
          this.getSiteManagementList();
          // this.getAllAssetList();
      }, 100);
    }

    /* Handle tier tree chacked unchecked Event */
  CheckChange(event) {
    if (event.args.checked == true) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist == '-1') {
        this.checkTreeArray.push(event.args.element.id);
        setTimeout(() => {
          this.getSiteManagementList();
          // this.getAllAssetList();
        }, 100);
      }
    } else if (event.args.checked == false || event.args.checked == null) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist != '-1') {
        this.checkTreeArray.splice(exist, 1);
        setTimeout(() => {
          this.getSiteManagementList();
          // this.getAllAssetList();
        }, 100);
      }
    }

    if (this.checkTreeArray.length == 0) {
      this.dropdownSettingsSite.placeholder = "Select a tier first";
      // this.dropdownSettingsAsset.placeholder = "Select a tier first";
      this.tierPlaceholder = "Select Tier"; 
    }else{
      this.dropdownSettingsSite.placeholder = "Select Site";
      // this.dropdownSettingsAsset.placeholder = "Select Asset";
      this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
    }

    
    if(this.disableTreeLength==0) {
      if (this.data.length == this.checkTreeArray.length) {
        this.selectAllTier = true            
      }else{
        this.selectAllTier = false
      }
    }else{
      let actualLength = this.data.length-this.disableTreeLength;
      if (actualLength == this.checkTreeArray.length) {
        this.selectAllTier = true            
      }else{
        this.selectAllTier = false
      }
    }
  }

    // checked checkbox on load of tree
  checkedCheckboxOnLoad() {
    Object.keys(this.checkTreeArray).forEach(key => {
        let checkedItem = document.getElementById(this.checkTreeArray[key]);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem, true);
    });
  }

  getEnableDisableTier() {
    for (let object of this.data) {
      if(object.flag){
        this.enableTreeArray.push(object.tierId.toString());
      } else {
        this.newDisableTreeArray.push(object.tierId.toString());
      }
    }
    this.disableTreeLength = this.newDisableTreeArray.length;
  }

  // Function to disable items
  treeItemDisable() {
      Object.keys(this.disableTreeArray).forEach(key => {
          let checkedItem = document.getElementById(this.disableTreeArray[key]);
          this.myTree.expandItem(checkedItem);
          this.myTree.disableItem(checkedItem);
      });
      if (this.checkTreeArray.length != 0) {
        this.tierPlaceholder = "+" + this.checkTreeArray.length + " Selected";
      } else {
        this.tierPlaceholder = "Select Tier";
      }
  }



  /* Get Site Management list */
  getSiteManagementList() {
      this.siteList = []
      this.selectedSite = [];
      this.checkSiteArray = [];
      this.siteIdForApi = "";
      this.checkAssetArray = [];
      this.assetDatalist = [];
      this.selectedAsset = [];
      this.assetIdForApi = "";
      this.filterObj.selectedAssetId = 0;
      this.filterObj.selectedSite = "";
  
          var URL = 'site/getSiteDropDownListByClient?alphabetSortingOrder=1&userId=' + this.userId + '&clientId=' + this.newClientId;;
          
          // let Tier = this.checkTreeArray.join();
          // if (Tier != null && Tier != '') {
          //     var tierString = '&tierId=' + Tier.toString();
          //     var URL = URL.concat(tierString);
          // }
          // if (this.checkTreeArray.length == 0) {    
          //     this.siteList = []
          //     this.selectedSite = [];
          // }else {
              this.apiService.getAll(URL).subscribe(
                  data => {
                      if (data.statusCode == 100) {
                        if(this.checkTreeArray.length != 0) {
                          this.siteList = data.responseList;
                          let tempSelected = []
                          this.selectedSite.map((obj, index)=> {
                              let findObj = _.find(this.siteList, { siteId: obj.siteId});
                              if (findObj) {
                                  tempSelected.push(obj);
                              }
                          })
                          this.selectedSite = tempSelected;
                        }
                      } else {
                          this.siteList = [];
                      }
                  },
                  error => {
                    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                  }
              );
          // }
  }

  // Getting Asset List
  getAllAssetList() {
    this.assetDatalist = [];
    this.filterObj.selectedAssetId = 0;
    var URL = 'asset/getAssetByAdmin';
    if(this.newClientId!= null && this.newClientId != '' && this.newClientId != 0) {
      let clientStr = "?clientId=" + this.newClientId;
      var URL = URL.concat(clientStr);
    }
    if (this.siteIdForApi != null && this.siteIdForApi != '') {
        var siteString = '&siteId=' + this.siteIdForApi.toString();
        var URL = URL.concat(siteString);
    }
    if (this.tierIdForApi != null && this.tierIdForApi != '') {
        var tierString = '&tierId=' + this.tierIdForApi.toString();
        var URL = URL.concat(tierString);
    }
    // if (this.checkSiteArray.length == 0) {    
    //     this.assetDatalist = [];
    //     // this.selectedAsset = [];
    //     this.filterObj.selectedAssetId = 0;
    // }else {
        this.apiService.getAll(URL).subscribe(
            data => {
                if (data.statusCode == 100) {
                  if(this.checkSiteArray.length != 0) {
                    this.assetDatalist = data.responseList;
                    let tempSelected = []
                    this.selectedAsset.map((obj, index)=> {
                        let findObj = _.find(this.assetDatalist, { assetId: obj.assetId});
                        // console.log(findObj)
                        if (findObj) {
                            tempSelected.push(obj);
                        }
                    })
                    this.selectedAsset = tempSelected;
                  }
                } else {
                    this.assetDatalist = [];
                    this.selectedAsset = [];
                }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    // }
  }


  //----------------client dropdown section-----------------
  clientDropDownList: any = [];
  /* Get Client list */
  getClientList(){
      this.apiService.getAll('client/getClients').subscribe(
        data => {
          this.CommonServicesService.setClientFlag(true)
          if(data.statusCode == 100){
            this.clientDropDownList = data.responseList;   
          }else{
           
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
        );    
  }

  /*---------------------Site--------------- */

  /* On item select site */
  onItemSelectSite(item: any) {
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if (exist == '-1') {
        this.checkSiteArray.push(item.siteId.toString());
        this.siteIdForApi = this.checkSiteArray.join();
        if(this.siteList.length == this.checkSiteArray.length) {
        }
        // this.getAllAssetList();
    }
  }

  /* On item de-select site */
  onItemDeselectSite(item: any) {
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if (exist != '-1') {
        this.checkSiteArray.splice(exist, 1);
        this.siteIdForApi = this.checkSiteArray.join();
        // this.getAllAssetList();
    }
  }

  /* On select all site */
  onSelectAllSite(items: any) {
    this.checkSiteArray = [];
    this.siteIdForApi = this.checkSiteArray.join();
    Object.keys(items).forEach(key => {
        this.checkSiteArray.push(items[key]['siteId'].toString());
        this.siteIdForApi = this.checkSiteArray.join();
    });
    
    setTimeout(()=> {
      // this.getAllAssetList();
    }, 500);
  }

  /* On de-select all site */
  onDeSelectAllSite() {
    this.checkSiteArray = [];
    this.siteIdForApi = this.checkSiteArray.join();
    // this.getAllAssetList();
    this.checkAssetArray = [];
    this.assetDatalist = [];
    this.selectedAsset = [];
    this.assetIdForApi = "";
  }


  /*-----------------Asset-------------------*/
  /* On Item Select Asset */
  onItemSelectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetId.toString());
    if (exist == '-1') {
        this.checkAssetArray.push(item.assetId.toString());
        this.assetIdForApi = this.checkAssetArray.join();
    }
  }

  /* On Item DeSelect Asset */
  onItemDeselectAsset(item: any) {
      var exist = this.checkAssetArray.indexOf(item.assetId.toString());
      if (exist != '-1') {
          this.checkAssetArray.splice(exist, 1);
          this.assetIdForApi = this.checkAssetArray.join();
      }
  }

  /* On All Select Asset */
  onSelectAllAsset(items: any) {
      this.checkAssetArray = [];
      Object.keys(items).forEach(key => {
          this.checkAssetArray.push(items[key]['assetId'].toString());
          this.assetIdForApi = this.checkAssetArray.join();
      });
  }

  /* On All DeSelect Site */
  onDeSelectAllAsset() {
      this.checkAssetArray = [];
      this.assetIdForApi = this.checkAssetArray.join();
  }
  // Function to format Date
  DateFormat(dateStr){
    if(dateStr){
        // return moment(dateStr).format("YYYY-MM-DD hh:mm:ss");
        var dateTime = new Date(dateStr);
        return moment(dateTime).format("YYYY-MM-DD");
    }else{
        return "";
    }
  }
invoiceData: any = [];
@ViewChild('formConsumption') formConsumption;
  runConsumptionReport() {

    console.log(this.formConsumption.value);
    let formvalue = this.formConsumption.value

    var firstDay = new Date(formvalue.year, formvalue.month, 1);
    var lastDay = new Date(formvalue.year, formvalue.month + 1, 0);
    const startOfMonth = moment(formvalue.month+"-01-"+formvalue.year, "M-DD-YYYY").startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment(formvalue.month+"01-"+formvalue.year, "M-DD-YYYY").endOf('month').format('YYYY-MM-DD');
    let siteIdArr = []
    formvalue.site.map((obj,index)=>{
      siteIdArr.push(obj.siteId)
    })
    
    this.startDateErr = "";
    this.endDateErr = "";
    console.log(this.formConsumption.valid, lastDay, firstDay, startOfMonth, endOfMonth)
    this.showTier = false;
    
    if (!this.formConsumption.valid) {
      return
    }
    
    var URL = 'billing/consumptionReportDateWise' ;
    let apiData:any = {
      "startDate": startOfMonth,
      "endDate": endOfMonth,
      "clientId": formvalue.clientId,
      "siteIds":siteIdArr.toString()
    }
    // if(this.newClientId!= null && this.newClientId != '' && this.newClientId != 0) {
    //   apiData.clientId = this.newClientId;
    //   // let clientStr = "&clientId=" + this.newClientId;
    //   // var URL = URL.concat(clientStr);
    // }
   
    console.log(apiData);
    // return
    
    this.apiService.create(URL, apiData).subscribe(
      data => {
         if(data.statusCode == 100){
           this.invoiceData = data.responseList ? data.responseList : []; 
           console.log('consumption data', this.invoiceData)
            // this.userDatalist = data.user;
          }else{
          }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );    
  }

  calculateTotal(){
    let total = 0
    this.invoiceData.map((item,i)=>{
      total += /* item.price* */item.tax/* /100 */ + item.price
    })
    return total.toFixed(2);
  }

}
