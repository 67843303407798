import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService, CommonServicesService}from '../../../../services/index';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
// import * as $ from 'jquery'
import 'datatables.net';
import 'datatables.net-bs4';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
declare var $:any, moment: any;

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  roleDatalist : any = [];
  dataTable: any;
  roleId :number = 1;
  search:any='';

  roleUserData : any;
  usersArrayForRoles : any = [];
  clientTopDropdownValue : number;
  subscription: Subscription;
  clientFilterId : any = '';
  clientName : any = localStorage.getItem("clientName");
  HistoryList: any = [];
  permissionListAssign: any = [];

  constructor(
    private router:Router, 
    private apiService: ApiService , 
    private alertService: AlertService , 
    private chRef: ChangeDetectorRef,
    private CommonServicesService: CommonServicesService
  ) {
    //   this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
    // .subscribe(
    //   message => {
    //     this.clientFilterId = message.id;
    //     console.log(this.clientFilterId);
    //     setTimeout(() => {
    //       this.clientName = localStorage.getItem("clientName");
    //     }, 1000);
    //     if(this.clientFilterId) {
    //       this.getAllRoleList();  
    //     }        
    //   },
    //   error=>{
    //     console.log(error)
    //   }
    // );
  }

  ngOnInit() {
    this.getAllRoleList();
  }

  ngOnDestroy(){
    // this.subscription.unsubscribe();
  }

seachRecord(){
  this.getAllRoleList();
}
/* Reset Search Record */
resetSearch(){
  this.search = "";
  this.getAllRoleList();
}
  /* Function for getting All Role List */
  getAllRoleList(){

    this.roleDatalist = []
    if($.fn.DataTable.isDataTable('#data_table')){
        $('#data_table').DataTable().destroy();
    };  
 
    this.clientTopDropdownValue =  $('#client_dropdown_top').children("option:selected").val();
    var URL = 'role/getAllRoleList';
    // var URL = 'role/getAllRole?userId='+this.userId;
    if(this.search !=''){
      var searchString   = '&search='+this.search;
      var URL = URL.concat(searchString);
    }
    if(this.customerId != null && this.customerId !=''){
      var customerString     = '&customerId='+this.customerId.toString();
      var URL = URL.concat(customerString);
    }
    if(this.tierId != null && this.tierId !=''){
      var tierString     = '&tierId='+this.tierId.toString();
      var URL = URL.concat(tierString);
    }

    this.apiService.getAll("role/getAllRoleByType?userType=Amci"/* ?userType=Client&clientId="+this.clientFilterId */).subscribe(
      data => {
        if(data.statusCode == 100){
          this.roleDatalist = data.responseList;
          if ($.fn.DataTable.isDataTable('#data_table')){
            $('#data_table').DataTable().destroy();
          };
          this.chRef.detectChanges();
          const table: any = $('#data_table');
          this.dataTable = table.DataTable({"order": [], "destroy":true,"columnDefs": [{ "orderable": false, "targets": [2] }]});
          // this.chRef.detectChanges();
          // const table: any = $('table');
          // this.dataTable = table.DataTable();
        }else{
          this.roleDatalist = [];
          this.chRef.detectChanges();
          const table: any = $('#data_table');
          this.dataTable = table.DataTable({"order": [],"destroy":true,"columnDefs": [{ "orderable": false, "targets": [2] }]});
          // this.dataTable = table.DataTable();
          // this.chRef.detectChanges();
          // const table: any = $('table');
          // this.dataTable = table.DataTable();
         }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  getUserByRole(){
    this.apiService.getAll('role/getRole?roleId='+this.roleId).subscribe(
     data => {
         if(data.statusCode == 100){
            this.roleUserData = data.response;

          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
           }
        },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  			}
        );
  }


  DeleteRole(roleId){
    console.log("roleId-----",roleId);
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this role?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('role/deleteRole?roleId='+roleId, '').subscribe(
          data => {
            if(data.statusCode == 100){
                this.alertService.sweetMessage('success','Role Deleted Successfully');
                this.getAllRoleList();
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }

  getUsersOfRoles(roleId){
    var URL = 'role/getRoleByClient?userId='+this.userId;
  //   if(this.search !=''){
  //     var searchString   = '&search='+this.search;
  //     var URL = URL.concat(searchString);
  //   }
  //   if(this.customerId != null && this.customerId !=''){
  //   var customerString     = '&customerId='+this.customerId.toString();
  //   var URL = URL.concat(customerString);
  // }
  // if(this.tierId != null && this.tierId !=''){
  //   var tierString     = '&tierId='+this.tierId.toString();
  //   var URL = URL.concat(tierString);
  // }
  // $('#showUserList').modal('show');
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          var _this = this;
          this.roleDatalist.forEach(function (arrayItem) {           
              if(roleId == arrayItem.roleId)
              {
                _this.usersArrayForRoles.roleName = arrayItem.roleName;
                _this.usersArrayForRoles.users = arrayItem.users;
              }          
          });             
          // $("#UserRoleModal").click();
          $('#UserRoleModal').modal('show');
        }else{
          this.usersArrayForRoles = [];
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      });
  }

  getRoleUserName(users){
    let userName = '';
    if (users) {
      users.map((obj,index)=>{
        if (index==0) {
          userName += (obj && obj.middleName ? obj.firstName+" ("+obj.middleName+") "+obj.lastName : obj.firstName +" " +obj.lastName)
        }else{
          userName = userName+', ' +(obj && obj.middleName ? obj.firstName+" ("+obj.middleName+") "+obj.lastName : obj.firstName +" " +obj.lastName)
        }
      })
    }
    return userName;
  }



  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('role/getRoleHistory?roleId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

  setPermissonList(permissions){
    this.permissionListAssign = permissions;
  }

  replacePermissionName(permission){
    let str = ''
    if (permission.groupName) {
      let strName = permission.groupName;
      let Name = strName.replace(/-/g," ")
      str = str+Name
    }
    if (permission.permission) {
      let strName = permission.permission;
      let Name = strName.replace(/-/g," ")
      str = str + ' '+Name
    }    
    return str;
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }
}
