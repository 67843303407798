import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AlertService } from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

email:any="";
loginPageContentData : any ;
imageFile : any ;
isLoading:any = false;

constructor(private router:Router, private apiService: ApiService, private alert:AlertService) { }

  ngOnInit() {
	$(document).ready(function () { 
    	$("body").addClass('login').css({"padding-top": "0px"});
	});
	this.getLoginData();
  }

  resetPassword(){
		this.isLoading = true;
		this.apiService.getLoginDatawithoutToken('user/forgetPassword?login='+this.email.trim()).subscribe(
			data => {
				if(data.statusCode == 100){
                    this.isLoading = false;
					Swal({
					  position: 'top-end',
					  type: 'success',
					  title: 'Reset Password link has been sent to your email, Please check.',
					  showConfirmButton: false,
					  timer: 3500
					})
					this.router.navigate(['/login']);
				}else{
                    this.isLoading = false;
					Swal({
		              position: 'top-end',
		              type: 'error',
					  			title: data.responseMessage/* 'Please enter valid Email-Id.' */,
					  			// title: 'Please enter valid Email-Id.',
		              showConfirmButton: false,
		              timer: 3500
		            })	
		            this.email="";				
				}
			},
			error => {
				this.isLoading = false;
				this.alert.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
		);
   }

      // Getting Login Data
  getLoginData(){
    var url = location.host//this.apiService.appUrl;
      this.apiService.getLoginDatawithoutToken('client/getClientByDomain?domain='+url).subscribe(
        data => {
        if(data.statusCode == 100){
         this.loginPageContentData=data.response.loginPageContent;
         this.imageFile = data.response.appLogoPath;
         let innerLogo = data.response.parentOrgInnerLogoPath;

         // Loader Image for client
         let loaderImg = data.response.loaderImg;
         localStorage.setItem("loaderImg", loaderImg);
		 localStorage.setItem("logoImage", this.apiService.imageBasePath+innerLogo);
		 console.log(this.imageFile);
		 
         if(document.getElementById('imageData')){
			if (this.imageFile) {
				document.getElementById('imageData').setAttribute( 'src', this.apiService.imageBasePath+this.imageFile );
			}else{
				document.getElementById('imageData').setAttribute( 'src', 'assets/img/default_logo.png' );
			}
         }
     }else{
		localStorage.removeItem("loaderImg");
		document.getElementById('imageData').setAttribute( 'src', 'assets/img/default_logo.png' );
	 }
          },
          error => {
            this.alert.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
    }

}
