import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService ,AlertService} from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-event-ack',
  templateUrl: './event-ack.component.html',
  styleUrls: ['./event-ack.component.css']
})
export class EventAckComponent implements OnInit {

kid:any="";
eid:any="";
msg:any="";
loginPageContentData:any;
imageFile: any;
UserList : any = "";

constructor(private router:Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService){

}

  	ngOnInit() {

		$(document).ready(function () {
			// CSS
		    $("body").css({"padding-top": "0px"});
	    });
	    this.route.queryParams.subscribe(queryParams => {
        
        console.log(queryParams)

	    	if(queryParams['k']){
		      this.kid = queryParams['k'];
		    }
		    if(queryParams['e']){
		      this.eid = queryParams['e'];
		    }
        this.getUserLsitForPopUp ();
		    // this.updateEventAck();
	  	});

	}


// Get User List to get users list who already did the Acknowlegde
getUserLsitForPopUp () {

  this.apiService.getLoginDatawithoutToken('user/getAckUserListByWffNotifcation?wffNotificationId='+this.kid).subscribe(
    data => {
      if(data.statusCode == 100 || data.statusCode == 103)
        {
          var _this = this;
          if(data.userList){
            this.UserList = "<p>Following users are already acknowledged:</p><ul class='text-left'>";
            data.userList.forEach(function (arrayItem) {
              var x = arrayItem.firstName;
              _this.UserList += '<li>'+x.charAt(0).toUpperCase() + x.slice(1)+'</li>';
            });
            this.UserList +='</ul>';
          }
          // this.UserList = "";
          this.updateEventAck();
        }
      else
        {
          this.UserList = '';
          // this.alertService.sweetMessage('error',data.responseMessage);
        }
    },
    error => {
      // this.alertService.sweetMessage('error',"Something went wrong, please try again.");
    }
  );


}


	// updateEventAck
	updateEventAck(){

  Swal({
    title: 'Are you sure you want to acknowledge this event?',
    // text: "You want to Acknowlegde this Event.",
    type: 'warning',
    html: this.UserList ? this.UserList : '',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Acknowledge'
  }).then((result) => {

    if (result.value) {

    if(!this.kid){
      this.alertService.sweetMessage('error',"Invalid URL.");
    }else if(!this.eid){
      this.alertService.sweetMessage('error',"Invalid URL.");
    }else{
      this.apiService.getLoginDatawithoutToken('wffnotification/wffNotificationEventAckByUser?k='+this.kid+'&e='+this.eid).subscribe(
        data => {
          if(data.statusCode == 100 || data.statusCode == 103){
            this.msg = data.responseMessage
          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }
  }
  })


}

}
