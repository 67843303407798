import { Component, OnInit, ViewChild,ChangeDetectorRef, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
declare var $:any;
import 'datatables.net';
import 'datatables.net-bs4';
declare var moment: any;
// declare var $:any,jquery:any;


@Component({
  selector: 'app-client-branding',
  templateUrl: './client-branding.component.html',
  styleUrls: ['./client-branding.component.css']
})
export class ClientBrandingComponent implements OnInit {
  @ViewChild('imageName') imageFileName: ElementRef;
  @ViewChild('logoFileInner') logoFileInnerName: ElementRef;
  @ViewChild('logoPathBranding') logoPathBrandingNmae: ElementRef;
  @ViewChild('filePathBrandApp') filePathBrandingAppName: ElementRef;

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  siteDatalist: any = [];
  clientdetailId: any = 0;
  dataTable: any;
  branding: any = {};

  imageName:any;
  imageNameInner:any;
  imageNameMobile:any;
  // ClientName: any = localStorage.getItem("ClientName");

  filePath:any          = "assets/img/upload-img.png";
  filePathInner:any     = "assets/img/upload-img.png";
  filePathMobile:any    = "assets/img/upload-img.png";
  filePathBranding:any  = "assets/img/upload-img.png";
  filePathBrandingApp:any  = "assets/img/upload-img.png";

  loginPageContent:any = "";
  clientBrandLogoWeb : any;
  clientBrandLogoApp : any;

  client: any = {
    "description":"","maxDaysHistory":"","maxInactivityHours":"","billingName": "","deactivationTime": "",
    "requiresPinVerification": false, "isActive": true, "contactEmail": '', "criticalThresHold": "25", "contactPhone": '', "alternatePhone": ''
  };
  clientName : any = localStorage.getItem("ClientInfoName");
  imageTitle = "";
  innerLogoTitle = "";
  mobileLoaderImgTitle = "";
  loaderImgTitle = "";
  breadcrumbObj: any;
  brandingHistoryList: any = [];
  defaultImage:any = "assets/img/upload-img.png";

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
        if(params['id']){
        this.clientdetailId = params['id'];
      }

    });
    if(this.clientdetailId){
      this.getClientById();
    }
    this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
  }


  /* Get Client by Id*/
  getClientById() {
    this.apiService.getAll('client/getClientById?clientId=' + this.clientdetailId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.client = data.response;
          let obj = this.client;
          this.branding = {
            clientDomain : obj.clientDomain,
            clientCode: obj.clientCode,
          }

          // this.branding = data.response;
          // let obj     = this.branding;

          // this.branding = {
          //   clientDomain : obj.clientDomain,
          //   clientCode   : obj.clientCode,
          // }

          this.imageName        = null;
          this.imageNameInner   = null;
          this.imageNameMobile  = null;

          $("#logoFile").val('')
          $("#logoFileInner").val('')
          $("#logoFileMobile").val('')

          // this.urlForLocal = "192.168.0.112/images"
          this.loginPageContent = obj.loginPageContent ? obj.loginPageContent : "";

          this.filePath = obj.logoFile ? this.apiService.imageBasePath+obj.logoFile : "assets/img/upload-img.png";
          // this.filePath = obj.appLogoPath ? this.apiService.imageBasePath+obj.logoFile : "assets/img/upload-img.png";
          if(obj.logoFile){
            let logoImgArr = obj.logoFile.split('/');
            this.imageTitle = logoImgArr[2];
          }

          this.filePathMobile = obj.appLogoPath ? this.apiService.imageBasePath+obj.appLogoPath : "assets/img/upload-img.png";

          // App Loader img
          this.filePathBrandingApp = obj.loaderImgMobile ? this.apiService.imageBasePath+obj.loaderImgMobile : "assets/img/upload-img.png";
          if(obj.loaderImgMobile){
            let loaderImgMobileArr = obj.loaderImgMobile.split('/');;
            this.mobileLoaderImgTitle = loaderImgMobileArr[2];
          }

          // Web Loader img
          this.filePathBranding = obj.loaderImg ? this.apiService.imageBasePath+obj.loaderImg : "assets/img/upload-img.png";
          if(obj.loaderImg){
            let loaderImgArr = obj.loaderImg.split('/');;
            this.loaderImgTitle = loaderImgArr[2];
          }
          
          // Set Loader image again when user change the loader image
          // localStorage.setItem("loaderImg", obj.loaderImg);
          // this.apiService.clinetLoader();

          // display none loader coz above method set and enable the loader
          // $("#loaderImgIcon").css("display","none");

          this.filePathInner = obj.clientInnerLogoPath ? this.apiService.imageBasePath+obj.clientInnerLogoPath : "assets/img/upload-img.png";
          if(obj.clientInnerLogoPath){
            let innerLogoArr = obj.clientInnerLogoPath.split('/');
            this.innerLogoTitle = innerLogoArr[2];
          }

        } else
          { this.alertService.sweetMessage('success',data.responseMessage); }
      },
      error =>
        { this.alertService.sweetMessage('error','Error in network'); }
    );
  }


  SubmitBranding(){
    let obj = this.branding
    obj.clientId = this.clientdetailId;
    let val = this.loginPageContent.replace('',"");
    obj.loginPagecontent =  val //this.loginPageContent//.substring(1, 199);
/* 
    $('#clientDomain, #clientCode').change(function() {    
      var a = $('#clientDomain').val();
      var b = $('#clientCode').val();
      if(!a) {
        $('#clientDomain').removeClass('is-invalid');
      } 
      if(!b) {
        $('#clientCode').removeClass('is-invalid');
      }
    })  */

    var clientDomain = $('#clientDomain').val();
    var clientCode = $('#clientCode').val();
    if(clientDomain == '') {
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });    
      $('#clientDomain_err').css('display', 'block');
      $('#clientDomain').addClass('is-invalid');
    } else {
      $('#clientDomain_err').css('display', 'none');
      $('#clientDomain').removeClass('is-invalid');
    }

    if(clientCode == '') {
      document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });    
      $('#clientCode_err').css('display', 'block');
      $('#clientCode').addClass('is-invalid');
    } else {
      $('#clientCode_err').css('display', 'none');
      $('#clientCode').removeClass('is-invalid');
    }
    if (clientDomain == '' || clientCode == '') {
      return
    }

    // let obj  = {
    //   "clientDomain": this.branding.clientDomain,
    //   "clientCode": this.branding.clientCode,
    //   "clientId": this.clientdetailId,
    //   "loginPagecontent": val
    // }

    // this.imageSave(3,this.imageName);
    // this.imageSave(4,this.imageNameMobile);
    // this.imageSave(5,this.imageNameInner);

    // this.apiService.update('client/createBranding', obj).subscribe(
    this.apiService.update('client/updateBranding', obj).subscribe(
      data => {
        if (data.statusCode == 100) {
          document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' }); 
          this.alertService.sweetMessage('success',data.responseMessage);
          this.getClientById();
          // this.branding = {}
          this.getClientById();
        } else {
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // SubmitBranding(){
  //   let obj = this.branding
  //   obj.clientId = this.clientId;
  //   let val = this.loginPageContent.replace('',"");
  //   obj.loginPagecontent =  val//this.loginPageContent//.substring(1, 199);

  //   var clientDomain = $('#clientDomain').val();
  //   var clientCode = $('#clientCode').val();

  //   if(clientDomain == '') {
  //     $('#clientDomain_err').css('display', 'block');
  //   } else {
  //     $('#clientDomain_err').css('display', 'none');
  //   }

  //   if(clientCode == '') {
  //     $('#clientCode_err').css('display', 'block');
  //   } else {
  //     $('#clientCode_err').css('display', 'none');
  //   }
  //   if (clientDomain == '' || clientCode == '') {
  //     return
  //   }

  //   this.apiService.update('client/updateBranding', obj).subscribe(
  //     data => {
  //       if (data.statusCode == 100) {
  //         this.getContactData();
  //         this.getClientById();
  //         this.alertService.sweetMessage('success', 'Updated successfully' );
  //         this.getClientById();
  //       } else {
  //       }
  //     },
  //    error =>{
  //              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  //            }
  //   );
  // }


  onFileChangedInner(event) {
      const file = event.target.files;
      const fileType = file[0]['type'];
      const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if ((file[0].size/1024) > 300) {
        Swal({
          position: 'top-end',
          type: 'error',
          title: 'Size should be below 300 KB',
          showConfirmButton: false,
          timer: 3500
        })
      } else {
        if (!validImageTypes.includes(fileType)) {
          this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG image are allowed");
          return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = (_event:any) => {
          var img = new Image();
          img.src = _event.target.result;
          img.onload = () => {
            if (img.width <= 300 && img.height <= 300) {
              this.filePathInner = reader.result;
              this.imageNameInner = file;
              this.imageSave(5,this.imageNameInner);
            } else {
              this.alertService.sweetMessage('error','Image should not be greater then 300 X 300');
            }
          };
        }
      }
    }


    onFileChangedMobile(event) {
        const file = event.target.files;
        // console.log(file[0],(file[0].size/1024),file[0].height,file);
        if ((file[0].size/1024)>500) {
          Swal({
            position: 'top-end',
            type: 'error',
            title: 'Size should be below 500 KB',
            showConfirmButton: false,
            timer: 3500
          })
        } else {
          var reader = new FileReader();
          reader.readAsDataURL(file[0]);

          reader.onload = (_event:any) => {

            var img = new Image();
            img.src = _event.target.result;

            img.onload = () => {
              if (img.width < 170 && img.height < 170) {
                this.filePathMobile = reader.result;
                this.imageNameMobile = file;
              } else {
                this.alertService.sweetMessage('error','Image should not be greater then 170 X 170');
              }
            };

          }
        }


      }

  /**********************************************
    Image upload For Login Page Logo
  ************************************/
  onFileChanged(event) {
      const file = event.target.files;
      const fileType = file[0]['type'];
      const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      // console.log(file[0].type);
      if ((file[0].size/1024) > 500)
      {
        this.alertService.sweetMessage('error',"Size should be below 500 KB"); 
      } else {
        // if(file[0].type != 'image/png' && file[0].type != 'image/jpg' &&  file[0].type != 'image/jpeg'){
        if (!validImageTypes.includes(fileType)) {
          this.alertService.sweetMessage('error',"Only PNG/JPG/JPEG image are allowed");
          return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = (_event:any) => {
          var img = new Image();
          img.src = _event.target.result;
          img.onload = () => {
            if (img.width < 500 && img.height < 500) {
              this.filePath = reader.result;
              this.imageName = file;
              // this.imageSave(4,this.imageName);
              this.imageSave(6,this.imageName);
            } else {
              this.alertService.sweetMessage('error',"Image should not be greater than 500 X 500");
            }
          };
        }
      }
    }




/**********************************************
  Image upload For Cleint Branding Logo For WEB
****************************************************/

onFileChangedClientBrandWeb (event) {
  const file = event.target.files;
  // console.log(file[0],(file[0].size/1024),file[0].height,file);
  // console.log(file[0].size/1024);
  // console.log(file[0].type);

  if(file[0].type != 'image/gif'){
    this.alertService.sweetMessage('error',"Only Gif image are allowed as loader");
    return;
  }

  if ((file[0].size/1024)>500) {
    Swal(
      { position: 'top-end', type: 'error',
        title: 'Size should be below 500 KB',
        showConfirmButton: false, timer: 3500
      })
  }else {

    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (_event:any) => {
      var img = new Image();
      img.src = _event.target.result;

      img.onload = () => {
        if (img.width <= 200 && img.height <= 200) {
          this.filePathBranding = reader.result;
          this.clientBrandLogoWeb  = file;
          this.imageSave(7,this.clientBrandLogoWeb);
        }
        else {
          this.alertService.sweetMessage('error',"Image should not be greater than 200 X 200");
        }
      };
    }
  }
}



/**********************************************
  Image upload For Cleint Branding Logo For App
************************************************************/

onFileChangedClientBrandApp (event) {
  const file = event.target.files;
  // console.log(file[0],(file[0].size/1024),file[0].height,file);
  // console.log(file[0].size/1024);

  if(file[0].type != 'image/gif'){
    this.alertService.sweetMessage('error',"Only Gif image are allowed as loader");
    return;
  }

  if ((file[0].size/1024)>500) {
    Swal({
      position: 'top-end',
      type: 'error',
      title: 'Size should be below 500 KB',
      showConfirmButton: false,
      timer: 3500
    })
  } else {

    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (_event:any) => {
      var img = new Image();
      img.src = _event.target.result;

      img.onload = () => {
        if (img.width <= 170 && img.height <= 170) {
          this.filePathBrandingApp = reader.result;
          this.clientBrandLogoApp  = file;
          this.imageSave(8,this.clientBrandLogoApp);
        }
        else {
          this.alertService.sweetMessage('error',"Image should not be greater than 170 X 170");
        }
      };
    }
  }
}


imageSave(section,imageName){
    const uploadData = new FormData();

    if(imageName){
      uploadData.append('file', imageName[0], imageName[0].name);
      this.apiService.create('uploadImage/uploadImage?section='+section+'&sectionId='+this.clientdetailId, uploadData).subscribe(
        data => {
            if(data.statusCode == 100){
              setTimeout(()=>{
                this.imageName = null;
                this.imageNameInner = null;
                this.imageNameMobile = null;
              },2000)
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
                },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
      );
    }

  }

  removeInputImage(type: any) {
    //  clear file data after operation 
    if (type == 6) {
      this.imageFileName.nativeElement.value = "";
      this.imageTitle = '';
    } else if (type == 5) {
      this.logoFileInnerName.nativeElement.value = "";
      this.innerLogoTitle = '';
    } else if (type == 7) {
      this.logoPathBrandingNmae.nativeElement.value = "";
      this.loaderImgTitle = '';
    } else if (type == 8) {
      this.filePathBrandingAppName.nativeElement.value = "";
      this.mobileLoaderImgTitle = '';
    }
  }

    // function for deleting image of branding 
    removeBrandingImage(section, sectionName) {
      Swal({
        title: 'Are you sure?',
        text: `You want to remove ${sectionName}.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove it!'
      }).then((result) => {
        if (result.value) {
          const domain = location.host;
          this.apiService.delete("uploadImage/removeImage?section=" + section + "&sectionId=" + this.clientdetailId, '').subscribe(
            data => {
              if (data.statusCode == 100) {
                this.alertService.sweetMessage('success', `${sectionName} removed successfully`);
                if (section == 6) {
                  this.filePath = 'assets/img/upload-img.png'
                } else if (section == 5) {
                  this.filePathInner = 'assets/img/upload-img.png'
                } else if (section == 7) {
                  this.filePathBranding = 'assets/img/upload-img.png'
                } else if (section == 8) {
                  this.filePathBrandingApp = 'assets/img/upload-img.png'
                }
              } else {
                this.alertService.sweetMessage('error', data.responseMessage);
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        }
      })
    }

    getBrandingHistory(id){
      this.brandingHistoryList = []
      if ($.fn.DataTable.isDataTable('#brandingHistoryDatatable')) {
        $('#brandingHistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
      };
          this.apiService.getAll('client/getClientBrandingHistoryById?clientId=' + id).subscribe(
            data => {
              if (data.statusCode == 100) {
                this.brandingHistoryList = data.responseList;
                setTimeout(() => {
                  const table: any = $('#brandingHistoryDatatable');
                  table.DataTable({ "order": [], "destroy": true });
                }, 1000);
              } else {
                setTimeout(() => {
                  const table: any = $('#brandingHistoryDatatable');
                  table.DataTable({ "order": [], "destroy": true });
                }, 1000);
                Swal({
                  position: 'top-end',
                  type: 'error',
                  title: data.responseMessage,
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          ); 
    }
    DateFormatEvent(startDate, gmtOffset){
      let d1  = new Date(startDate)
      var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
      
      if(startDate){
      	var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
        return formattedDate;
      }else{
        return "";
      }
    }
}
