import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService, FormatDateService } from '../../../../../services/index';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
import 'datatables.net';
declare var jQuery: any;
declare var $: any;
declare var d3: any, moment: any;
import 'datatables.net-bs4';


@Component({
  selector: 'app-call-center-dashboard',
  templateUrl: './call-center-dashboard.component.html',
  styleUrls: ['./call-center-dashboard.component.css']
})
export class CallCenterDashboardComponent implements OnInit {
  clientUser: any = null //JSON.parse(localStorage.getItem("clientUser"));
  loginUserType: any = this.clientUser ? this.clientUser.userType : '';//to show breadcrumb conditionly
  callCenterId: any = null //JSON.parse(localStorage.getItem("clientUser")).callCenterId;
  clientName: any = localStorage.getItem("clientName");

  dataTable: any;
  Datalist: any = [];
  userId: any;
  callID: any;
  EventAginglist: any = [];
  EventAginglistForAlarm: any = [];
  assetDropDownList: any = [];
  selectedClientId: any = '0';
  isShow: any;
  keyname: any;
  SearchData: any = false;
  dropdownList: any = [];
  filterId: any = '0';
  assetfilterId: any = '0';
  selectedAssetId: any = '0';
  searchResult: any = [];
  FollowUpObject: any = {};
  showLoading: boolean = false;
  showLoadingRuntime: boolean = false;
  showLoadingRuntimeAlarm: boolean = false;
  normalText: any = "4";
  normalTextAlarm: any = "8";
  callType: string = '';

  searchClientAssetsText: string = '';
  callInstructionList = [];
  isSearchLoading: boolean = false;
  isCallInstrunctionLoading: boolean = false;
  HistoryList: any = [];
  responsibilityList: any = [];
  rollUpEventList: any = [];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef,
    private formatDateService: FormatDateService
  ) { }

  ngOnInit() {
    this.callCenterId = JSON.parse(localStorage.getItem("clientUser")).callCenterId;
    this.clientUser = JSON.parse(localStorage.getItem("clientUser"));

    jQuery('.rst-date').datetimepicker({
      language: 'en',
      pickTime: true,
      pickDate: true,
      minDate: 0,
      inline: true,
      sideBySide: true,
      timeFormat: "HH:mm",
    });
    let defultDate = moment().format('YYYY-MM-DD HH:mm:ss');
    var eventTime = $('#eventTime').val(defultDate);
    this.userId = JSON.parse(localStorage.getItem("userId"));
    this.route.params.subscribe(params => {
      if (params['id']) { this.callID = params['id']; }
    });
    $(document).on('hidden.bs.modal', function () {
      if($('.modal.show').length)
      {
        $('body').addClass('modal-open');
      }
    });

    this.getRunTimeEventAgingList();
    this.getRunTimeEventAgingForAlarmList();
    this.getClientList();
    this.getCallInstructionList();
  }


  // Function to format Date
  DateFormat(startDate, gmtOffset) {
    return this.formatDateService.formatDate(startDate, gmtOffset);
  }


  /* Get Client list */
  getClientList() {
    this.apiService.getAll('callcenter/getCallCenterClientByUser?userId=' + this.userId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.dropdownList = data.responseList;
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  // Get Client ID on change
  sendClientId(val) {
    this.selectedClientId = val;
    this.getClientAssetList();
  }


  /* Get Asset list based on Client */
  getClientAssetList() {
    this.apiService.getAll('asset/getAssetDropDownByClient?clientId=' + this.selectedClientId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.assetDropDownList = data.responseList;
        } else {
          this.assetDropDownList = [];
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  // Get Client ID on change
  sendAssetId(val) {
    this.selectedAssetId = val;
  }



  // Get all the Data for Asset , Event And Call Instruction User List
  getCallInstructionList() {
    this.showLoading = true;
    this.Datalist = [];
    if ($.fn.DataTable.isDataTable('#recentEventTable')) {
      $('#recentEventTable').DataTable().destroy();
      // this.EventAginglistForAlarm = [];
      this.Datalist = [];
    }
    // this.apiService.getAll('wffnotification/getCallCenterWffNotificationByCallCenterUser').subscribe(
    this.apiService.getAllDashboard('wffnotification/getCallCenterWffNotificationByCallCenterUser').subscribe(
      data => {
        this.showLoading = false;
        if (data.statusCode == 100) {
          this.Datalist = [];
          this.Datalist = data.responseList;
          this.Datalist.forEach((obj, i) => {
            // obj.duration = this.formatDateService.dateDiffrence(obj.createDate,obj.localDateConverted)
            obj.duration = this.formatDateService.formatDate(obj.createDate, obj.localDateConverted)
          });
          this.chRef.detectChanges();
          const table: any = $('#recentEventTable');
          this.dataTable = table.DataTable({ "order": [], "columnDefs": [{ "orderable": false, "targets": [5,6] }] });
        } else {
          this.Datalist = [];
          this.chRef.detectChanges();
          const table: any = $('#recentEventTable');
          this.dataTable = table.DataTable({ "order": [], "columnDefs": [{ "orderable": false, "targets": [5,6] }] });
        }
      },
      error => { this.showLoading = false; this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    )
  }

  second_hrs_change() {
    this.normalText = $('#second_hrs').val();
    this.getRunTimeEventAgingList();
  }

  third_hrs_change() {
    this.normalTextAlarm = $('#third_hrs').val();
    this.getRunTimeEventAgingForAlarmList();
  }

  // Get all the Data for Run Time Event Aging
  getRunTimeEventAgingList() {
    this.showLoadingRuntime = true;
    let hrs = $('#second_hrs').val();
    let str = '';
    if (hrs != '1' && hrs != '0') {
      str = '&hours=' + hrs;
    }
    if ($.fn.DataTable.isDataTable('#runTimeEventAgingList_table')) {
      $('#runTimeEventAgingList_table').DataTable().destroy();
      this.EventAginglist = [];
    }
    this.apiService.getAll('runTimeEvent/getRuntimeAgingForNormalAsset?callCenterId=' + this.callCenterId + str).subscribe(
      data => {
        this.showLoadingRuntime = false;
        if (data.statusCode == 100) {
          this.EventAginglist = data.responseList;
          this.EventAginglist.forEach((obj, i) => {
            obj.duration = this.formatDateService.dateDiffrence(obj.stopDate, obj.localDateConverted)
          })
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#runTimeEventAgingList_table');
            this.dataTable = table.DataTable({ "order": [], "columnDefs": [{ "orderable": false, "targets": [4,5] }] });
          }, 1000);
        } else {
          this.EventAginglist = [];
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#runTimeEventAgingList_table');
            this.dataTable = table.DataTable({ "order": [], "columnDefs": [{ "orderable": false, "targets": [4,5] }] });
          }, 1000);
        }
      },
      error => { this.showLoadingRuntime = false; this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    )
  }

  followUpCall(data) {
    console.log('followUpCall', data);

    this.FollowUpObject = {
      "clientId": data.clientId,
      "runTimeEventId": data.runTimeEventId,
      "runTimeCollectionPointId": data.runTimeCollectionPointId,
      "isParent": data.isParent,
      "assetId": data.assetId,
      "wffNotificationMessageId": data.wffNotificationMessageId,
      "eventListType": 'Livefeeds',
      "type": 'RunTime',
      "performAction": 1,
      "showAckBtn": 0,
      "showAckErcBtn": 0,
      "showERC": 0,
      "licenseIsTechEntry": data.licenseIsTechEntry,
      "assignToMe": 1,
      "isAck": 1,
      "runTimeStatus": data.runTimeStatus
    }

    localStorage.setItem("isTabShow", 'true')
    // localStorage.setItem("FollowUpData", JSON.stringify(this.FollowUpObject));
    // this.router.navigate(["callcenter/call-center-instruction", '10061630'])
    this.router.navigate(["callcenter/call-center-instruction", data.wffNotificationMessageId], { queryParams: { clientId: data.clientId, runTimeEventId: data.runTimeEventId, type: 'RunTime', wffNotificationId: data.wffNotificationId } })

  }

  navigateToInstruction(wffmsgId) {
    if (wffmsgId) {
      localStorage.setItem("isTabShow", 'false')
      this.FollowUpObject = { "status": 'nodata' }
      localStorage.setItem("FollowUpData", JSON.stringify(this.FollowUpObject));
      this.router.navigate(["callcenter/call-center-instruction", wffmsgId])
    }

  }





  // Get all the Data for Run Time Event Aging for alram
  getRunTimeEventAgingForAlarmList() {
    this.showLoadingRuntimeAlarm = true;
    if ($.fn.DataTable.isDataTable('#runTimeEvent_AlarmList_table')) {
      $('#runTimeEvent_AlarmList_table').DataTable().destroy();
      this.EventAginglistForAlarm = [];
    }

    let hrs = $('#third_hrs').val();
    let str = '';
    if (hrs != '1' && hrs != '0') {
      str = '&hours=' + hrs;
    }
    this.apiService.getAll('runTimeEvent/getRuntimeEventsForAlarmingAsset?callCenterId=' + this.callCenterId + str).subscribe(
      data => {
        this.showLoadingRuntimeAlarm = false;
        if (data.statusCode == 100) {
          this.EventAginglistForAlarm = data.responseList;
          this.EventAginglistForAlarm.forEach((obj, i) => {
            obj.duration = this.formatDateService.dateDiffrence(obj.stopDate, obj.localDateConverted)
          })
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#runTimeEvent_AlarmList_table');
            this.dataTable = table.DataTable({ "order": [], "columnDefs": [{ "orderable": false, "targets": [4,5] }] });
          }, 1000);

        } else {
          this.EventAginglistForAlarm = [];
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#runTimeEvent_AlarmList_table');
            this.dataTable = table.DataTable({ "order": [], "columnDefs": [{ "orderable": false, "targets": [4,5] }] });
          }, 1000);

        }
      },
      error => { this.showLoadingRuntimeAlarm = false; this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    )
  }


  /* searching client, assets, site name */

  searchClientAssets() {
    this.isSearchLoading = true;
    if (this.searchClientAssetsText) {
      if ($.fn.DataTable.isDataTable('#search_tbl')) {
        $('#search_tbl').DataTable().destroy();
      };
      this.apiService.getAll("callCenterInstruction/getAssetLiveCallInstructionsBySearch?search=" + this.searchClientAssetsText).subscribe(
        data => {
          this.isSearchLoading = false;
          this.callType = '';
          if (data.statusCode == 100) {
            console.log("searched data=========", data.responseList);
            this.searchResult = data.responseList ? data.responseList : [];
            if ($.fn.DataTable.isDataTable('#search_tbl')) {
              $('#search_tbl').DataTable().destroy();
            };
            const table: any = $('#search_tbl');
            setTimeout(() => {
              table.DataTable({
                search: {
                  "regex": false,
                  "smart": false
                }
              });
            }, 1000);

          } else {
            this.searchResult = [];
            /*  if ($.fn.DataTable.isDataTable('#search_tbl')) {
               $('#search_tbl').DataTable().destroy();
             };
             setTimeout(() => {
               const table: any = $('#search_tbl');
               table.DataTable(
                 {
                   "order": [],
                   "destroy": true,
                   "bDestroy": true,
                 }
               );
             }, 1000); */
          }
        },
        error => {
          this.isSearchLoading = false;
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          this.callType = '';
        }
      );

    }
  }

 /*  getting call instructions list by asset id  */
 
  getCallInstructionByAssetId(id) {
    this.isCallInstrunctionLoading = true;
    if ($.fn.DataTable.isDataTable('#call_instruction')) {
      $('#call_instruction').DataTable().destroy();
    };
    document.getElementById("cli-assets-anchor").scrollIntoView({ behavior: 'smooth' });
    var eventTime = $('#eventTime').val();
    var URL = 'callcenter/getCallInstructionLookup?assetId=' + id;
    if (eventTime != '') {
      let eventTimeVal = '&byTime=' + eventTime
      URL = URL.concat(eventTimeVal);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        this.isCallInstrunctionLoading = false;
        if (data.statusCode == 100) {
          this.callInstructionList = data.responseList ? data.responseList : [];
          this.callType = data.scheduleType ? data.scheduleType : '';
          if ($.fn.DataTable.isDataTable('#call_instruction')) {
            $('#call_instruction').DataTable().destroy();
          };
          setTimeout(() => {
            const table: any = $('#call_instruction');
            table.DataTable(
              {
                search: {
                  "regex": false,
                  "smart": false
                }
              }
            );
          }, 1000);
        } else {
          this.callInstructionList = [];
          this.callType = '';
          /*      if ($.fn.DataTable.isDataTable('#call_instruction')) {
                 $('#call_instruction').DataTable().destroy();
               };
               setTimeout(() => {
                 const table: any = $('#call_instruction');
                 table.DataTable(
                   {
                     "order": [],
                     "destroy": true,
                     "bDestroy": true, 
                   }
                 );
               }, 1000); */
        }
      },
      error => {
        this.isCallInstrunctionLoading = false;
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );

  }

  getHistory(obj){
    this.HistoryList = []
    let URL = ''
    // if (obj.notificationType == 'Notification') {
    //   URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.notificationId+'&isevent=false'
    //   // URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+943731+'&isevent=true'
    // }else{
      URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.runTimeEventId+'&isevent=true'
    // }

    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll(URL).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                 table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                 table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }
  setResponsibilityList(list){
    this.responsibilityList = list
  }

  setEventrollupLists(list){
    this.rollUpEventList = list
  }
  getCommentDecoded(comment){
    return decodeURIComponent(comment)
  }

}