import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { EmployeeService } from '../../employee.service';
import Swal from 'sweetalert2'
import { ApiService } from 'src/app/services';


@Component({
  selector: 'app-transfer-site',
  templateUrl: './transfer-site.component.html',
  styleUrls: ['./transfer-site.component.css']
})
export class TransferSiteComponent implements OnInit {

clientList:any=[];
tierList:any=[];
siteList:any=[];
shiftGroupList:any=[];
assetList:any=[];
isNew:any=0;

client: any = {"assetId":"","description":"","companyAsset":{"companyId":""},"clientRequest":{"clientId":""},"site":{"siteName":"","siteId":"","tier":{"tierId":""}},"shiftGroup":{"shiftGroupId":""},"scheduleAssetId":""}

constructor( private apiService: ApiService , private route: ActivatedRoute) { }

 ngOnInit() {
  this.route.params.subscribe(params => {
      this.client.assetId = params['assetId'];
      this.client.companyAsset.companyId = params['companyId'];
  });

  /*if(this.assetId){
    this.getUserById();
  }
*/
  this.getClientList();
 }

 /* Get client list on load*/
getClientList(){
  this.apiService.getLoginDatawithoutToken('classicSatAlarm/getAllClient').subscribe(
      data => {
          if(data.statusCode == 100){
              this.clientList = data.responseList;
              }else{

                  }
              },
              error => {

              }
          );
      }

/* Get Tier list on client Selection */
getTierListByClientId(clientId){
  if(clientId){
    this.apiService.getLoginDatawithoutToken('classicSatAlarm/getAllTierByClient?clientId='+clientId).subscribe(
      data => {
          if(data.statusCode == 100){
              this.tierList = data.responseList;
              }else{

                  }
              },
              error => {

              }
          );
      }
    this.getShiftGroupByClientId(clientId);
  }


  /* Get Tier list on client Selection */
getShiftGroupByClientId(clientId){
  if(clientId){
    this.apiService.getLoginDatawithoutToken('classicSatAlarm/getShiftGroupByClient?clientId='+clientId).subscribe(
      data => {
          if(data.statusCode == 100){
              this.shiftGroupList = data.responseList;
              }else{

                  }
              },
              error => {

              }
          );
      }
  }


/* Get tier list on client selction */
getSiteListByTierId(tierId){
  if(tierId){
  this.apiService.getLoginDatawithoutToken('classicSatAlarm/getSitesByTier?tierId='+tierId).subscribe(
      data => {
          if(data.statusCode == 100){
              this.siteList = data.responseList;
              }else{

                  }
              },
              error => {

              }
          );
      }
}
getSiteId(siteId){
  if(siteId == 'null'){
    this.isNew = 1;
  }else{
    this.isNew = 0;
  }
}

getAssetListByShiftGroupId(shiftGroupId){
  if(shiftGroupId){
  this.apiService.getLoginDatawithoutToken('classicSatAlarm/getAssetByShiftGroup?shiftGroupId='+shiftGroupId).subscribe(
      data => {
          if(data.statusCode == 100){
              this.assetList = data.responseList;
              }else{

                  }
              },
              error => {

              }
          );
      }
}

submitRequest(){
  if(this.client.site.siteId == 'null'){
    this.client.site.siteId = null;
  }
   this.apiService.update('classicSatAlarm/mapFromAssetToScheduleHierarchy', this.client).subscribe(
    data => {
        if(data.statusCode == 100){
            Swal({
              position: 'top-end',
              type: 'success',
              title: 'Success',
              showConfirmButton: false,
              timer: 1500
            })
        }else{
            Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
    error => {
        Swal({
            position: 'top-end',
            type: 'error',
            title: 'Error in network.',
            showConfirmButton: false,
            timer: 1500
          })
        }
      );
}
}
