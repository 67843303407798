import { Component, OnInit ,ChangeDetectorRef,ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService , AlertService, CommonServicesService}from '../../../../../../services/index';
import Swal from 'sweetalert2'
import * as _ from "lodash";

import { jqxTreeComponent } from '../../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../../jqwidgets-ts/angular_jqxdropdownbutton'
import { environment } from "../../../../../../../environments/environment"
import { Subscription, Subject } from 'rxjs';
import {componentDestroyed} from "@w11k/ngx-componentdestroyed";

@Component({
  selector: 'app-add-center-user',
  templateUrl: './add-center-user.component.html',
  styleUrls: ['./add-center-user.component.css']
})
export class AddCenterUserComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('addCallCenterUserForm') addCallCenterUserForm: any;

  message: any;
  subscription: any = new Subject();
  clientFilterId : any = '';


  constructor(private router:Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private CommonServicesService: CommonServicesService ) 
  {
      // subscribe to header component sendClientId to get Client Id
      this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
      .subscribe(
        message => {
          this.clientFilterId = message.id;
          setTimeout(() => {
            this.clientName = localStorage.getItem("clientName");
          }, 1000);
        }
      );
  }

  ngOnDestroy() {
    // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    // console.log(typeof(this.subscription), this.subscription);
    this.subscription.unsubscribe();
    // this.subscription = null;
    this.subscription.next()
    this.subscription.complete()
    // this.subscription.remove(this.subscription);
  }


  clientId    : any = localStorage.getItem("clientId");
  userId      : any = localStorage.getItem("userId");
  customerId  : any = (localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tiersId     : any = (localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  newUserId   : any = 0;
  clientIds   : any = []
  ClientIdArr : any = [];
  clientDataList : any = [];
  dropdownCallCenter : any = [];
  callCenterDropdown : any = [];
  passwordPattern : any = false
  // callCenterId : any = "0";
  callCenterId_error : any = false;
  clientName : any = localStorage.getItem("clientName");

  data : any = {
    "title": '',
    "firstName": '',
    "middleName":"",
    "lastName": "",
    "note": "",
    "login": "",
    "phonePin": "",
    "isAppUser": false,
    "phoneNumber": '',
    "callCenter":{ "callCenterId": '' },
    "agentIdentifier" : ""
  }

  PassInfo   : any = `    1. Password must be at least 8 characters.
  2. Password must contain at least 1 numeric character.
  3. Password must contain at least 1 uppercase character.
  4. Password must contain at least 1 special character !@#/\$%^&*().
  5. Password cannot contain spaces.`;

  ngOnInit() {
    this.getClientDrodownlist();
    this.route.params.subscribe(params => {
      if(params['id']){
        this.newUserId = params['id'];
        this.getCallcenterUserById();
      }
    });
  }

  getClientDrodownlist(){
    var URL = 'callcenter/getCallCenterByUser?userId='+this.userId;
    // if((this.clientFilterId!=0) && (this.clientFilterId!="") && (this.clientFilterId!=null)){
    //   var clientIdStr  = '&clientId='+this.clientFilterId;
    //   URL = URL.concat(clientIdStr);
    // }
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.dropdownCallCenter = data.responseList;
        }else
          {
            this.dropdownCallCenter = [];
            // this.alertService.sweetMessage('error',data.responseMessage);
          }
      },
      error =>
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
  }

  passwordChangeEvent(obj){
    var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
    if(obj && obj.length>7 && regx.test(obj) == false )  {
      this.passwordPattern = true;
    }else{
      this.passwordPattern = false;
    }
  }


  /* Add OR Update Call Center */
  addCallCenterUser(){
    this.data.isAppUser = false;
    // this.data.callCenter.callCenterId = $("#t1").val(); 
    // this.data.title = $("#title").val();
    // if($("#t1").val() == '0'){
    //   this.callCenterId_error = true;
    //   return;
    // }
    
// Add New Call Center User
    if (this.addCallCenterUserForm.valid) {
        this.data.userType = "CallCenter";
        if(this.data.note.length>100){
          return;
        }
        console.log('final request', this.data);
        if(this.newUserId == 0){          
          this.apiService.create('user/createCallCenterUser', this.data).subscribe(
            data => {
              if(data.statusCode == 100){
                this.alertService.sweetMessage('success','Call Center User added successfully');
                this.router.navigate(['/callCenter-user']);
              }else
                { this.alertService.sweetMessage('error',data.responseMessage); }
            },
            error =>
              { this.alertService.sweetMessage('error',"Error in network"); }
          );
        }else{
          // Update Call Center User
          var obj = this.data;
          obj.userId = this.newUserId;

          this.apiService.update('user/updateCallCenterUser', obj ).subscribe(
            data => {
              if(data.statusCode == 100){
                this.alertService.sweetMessage('success','Call Center User updated sussessfully');
                this.router.navigate(['/callCenter-user']);
              }else
                { this.alertService.sweetMessage('error',data.responseMessage); }
            },
            error =>
              { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
          );
        }
      }
    }


// Get Calll center Details by ID
    getCallcenterUserById(){
      this.apiService.getAll('user/getCallCenterUserById?userId='+ this.newUserId)
      .subscribe(
        data => {
          if(data.statusCode == 100){
            this.data  = data.user;
            if (data.user.callCenter == null) {
              this.data.callCenter = {}
            }
            // $("#t1").val(data.user.callCenter.callCenterId);
            // this.callCenterId = data.user.callCenter.callCenterId;
            // $("#title").val(data.user.title);
            
            // Map the object value
            // data.responseList[0].clients.map((val,key)=>{
            // // Get the value from object and push into the array for show client drop down list
            //   Object.keys(val).forEach(key=> {
            //     // Check if value is already present in the array
            //     var exist = this.ClientIdArr.indexOf(val['clientId'].toString());
            //     if(exist == '-1'){
            //       this.ClientIdArr.push(val['clientId'].toString());
            //     }
            //   });
            // })

          }else
            { this.alertService.sweetMessage('error',data.responseMessage); }
        },
        error =>
          { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
    }



}
