import { Component, OnInit,ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService, CommonServicesService } from '../../../../services/index';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
// import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
declare var $:any, moment:any;


@Component({
  selector: 'app-notification-plan-management',
  templateUrl: './notification-plan-management.component.html',
  styleUrls: ['./notification-plan-management.component.css']
})
export class NotificationPlanManagementComponent implements OnInit,OnDestroy {

clientId: any = (localStorage.getItem("clientId")?JSON.parse(localStorage.getItem("clientId")):null);;
userId: any = localStorage.getItem("userId");
customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
notificationPlanData:any=[];
assetDatalist : any = [];
dataTable: any ;
dropdownSettingsSite = {};
dropdownSettingsAsset={};
search:any='';
site:any='';
asset:any='';
checkSiteArray:any=[];
checkAssetArray:any=[];
siteList:any=[];
tierClientId : any;
clientListData : any = [];

message: any;
subscription: Subscription;
clientFilterId : any = '';
clientName : any = localStorage.getItem("clientName");
eventDatalist = []
constructor(
  private router:Router,
  private apiService: ApiService ,
  private alertService: AlertService ,
  private chRef: ChangeDetectorRef,
  private CommonServicesService: CommonServicesService
) {

  // subscribe to header component sendClientId to get Client Id
    this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
    .subscribe(
      message => {
        setTimeout(() => {
          this.clientName = localStorage.getItem("clientName");
        }, 1000);
        this.clientFilterId = message.id;
        if (this.clientFilterId) {
          this.getNotificationData();
        }
      }
    );

 }

 
 ngOnInit() {

  }

 ngOnDestroy() {
   // clear the header filter when user leave the page
   this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
   // unsubscribe to ensure no memory leaks
   this.subscription.unsubscribe();
 }


/* On Item Select Site */
onItemSelectSite(item: any) {
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if(exist == '-1'){
        this.checkSiteArray.push(item.siteId.toString());
      }
  }

/* On Item DeSelect Site */
onItemDeselectSite(item: any){
  var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if(exist != '-1'){
        this.checkSiteArray.splice(exist,1);
      }
}
/* On All Select Site */
onSelectAllSite(items: any) {
  this.checkSiteArray = [];
  Object.keys(items).forEach(key=> {
      this.checkSiteArray.push(items[key]['siteId'].toString());
    });
}
/* On All DeSelect Site */
onDeSelectAllSite(){
  this.checkSiteArray = [];
}
/* On Item Select Asset */
onItemSelectAsset(item: any) {
    var exist = this.checkAssetArray.indexOf(item.assetId.toString());
    if(exist == '-1'){
        this.checkAssetArray.push(item.assetId.toString());
      }
  }

/* On Item DeSelect Asset */
onItemDeselectAsset(item: any){
  var exist = this.checkAssetArray.indexOf(item.assetId.toString());
    if(exist != '-1'){
        this.checkAssetArray.splice(exist,1);
      }
}
/* On All Select Asset */
onSelectAllAsset(items: any) {
  this.checkAssetArray = [];
  Object.keys(items).forEach(key=> {
      this.checkAssetArray.push(items[key]['assetId'].toString());
    });
}
/* On All DeSelect Site */
onDeSelectAllAsset(){
  this.checkAssetArray = [];
}

/* Reset Search Record */
resetSearch(){
  this.search = "";
  this.site = "";
  this.asset="";
  this.checkSiteArray=[];
  this.checkAssetArray=[];
  this.getNotificationData();
}



/* Get Site Management list */
getSiteManagementList(){
  if(this.clientId){
  var URL = 'site/getSiteListByClient?clientId='+this.clientId+'&userId='+this.userId;
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.siteList = data.responseList;
        }else{
          /*Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })*/
         }
      },
   error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }
}
getAllAssetList(){

   var URL = 'asset/getAssetDropDownList?userId='+this.userId;
  /*
  if(this.site !=''){
    var siteString     = '&siteId='+this.site;
    var URL = URL.concat(siteString);
  }*/
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetDatalist = data.responseList;
        }else{
           this.assetDatalist = [];
         /* Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })*/
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getClientDropDownList(){
    this.apiService.getAll('client/getClientDropDownList').subscribe(
        data => {
            if(data.statusCode == 100){

                this.clientListData = data.responseList;
                if(this.clientListData.length>0){
                    this.tierClientId = this.clientListData[0].clientId
                    this.clientId = this.clientListData[0].clientId
                    // this.getTreeList();
                    this.getNotificationData()
                }

             }else{
               this.alertService.sweetMessage('error',data.responseMessage);
              }
           },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
      }

  getNotificationByClientData(){
    this.clientId = this.tierClientId;
    this.getNotificationData()
  }
  DateFormat(startDate, gmtOffset){
      if(startDate){
      return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
      return "";
    }
}


/* Get Notification plan data list */
getNotificationData(){

  // if(this.clientId){

  // var URL = 'notification_plan/getNotificationPlanList';
  
  var URL = "asset/getWffLiveFeedByStatus?isAck=0"  //userId="+this.userId+"&  //"runTimeEvent/runTimeEventList?status=3&startlimit=0&endlimit=100"
  if(this.clientFilterId != null && this.clientFilterId !=0){
    var clientString     = '&clientId='+this.clientFilterId.toString();
    var URL = URL.concat(clientString);
  }

  if ($.fn.DataTable.isDataTable('#data_table')){
    $('#data_table').DataTable().destroy();
    this.eventDatalist = [];
  };

	this.apiService.getAll(URL).subscribe(
    data => {
      $('#data_table').DataTable().destroy();
      if(data.statusCode == 100){
        this.eventDatalist = data.responseList;
        this.chRef.detectChanges();
        const table: any = $('table');
        this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0,5] },{ "orderable": false, "targets": 6 }]});
      }else{
        this.eventDatalist = [];
        this.chRef.detectChanges();
        const table: any = $('table');
        this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0,5] },{ "orderable": false, "targets": 6 }]});
      }
    },
      error =>
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
}



/* Delete Notification Management list */
DeleteNotification(notification){
 Swal({
  title: 'Are you sure?',
  text: "You want to delete this Notification.",
  type: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, delete it!'
}).then((result) => {
  if (result.value) {
   this.apiService.getAll('notification_plan/deleteNotificationPlan?notificationPlanId='+notification.notificationPlanId).subscribe(
   data => {
       if(data.statusCode == 100){
         Swal({
              position: 'top-end',
              type: 'success',
              title: 'Notification Delete Successfully',
              showConfirmButton: false,
              timer: 1500
            }) ;

             this.getNotificationData();


        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
   error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }
})
}

}
