import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../services/index';
import Swal from 'sweetalert2';
import 'datatables.net';
import 'datatables.net-bs4';
import * as _ from "lodash";

@Component({
  selector: 'app-live-feed',
  templateUrl: './live-feed.component.html',
  styleUrls: ['./live-feed.component.css']
})
export class LiveFeedComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
livefeedData:any=[];
eventId:any = 0;
dataTable: any;
clientName : any = localStorage.getItem("clientName");

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService ) { }
ngOnInit() {
   this.route.params.subscribe(params => {
      if(params['id']){
      this.eventId = params['id'];
    }
  });
  if(this.eventId){
    this.getLivefeedDtata();
  }
 }


/* Get Site Management list */
getLivefeedDtata(){
   this.apiService.getAll('runTimeEvent/runTimeEventDetail?runTimeEventId='+this.eventId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.livefeedData = data.response;

        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
}
 
 getSubTotalDownTime(){
    let total = 0;
     _.map(this.livefeedData.runTimeEventResponsibilities, function(value, key) {
      total = total + parseInt(value.downtime);
    });
    return total; 

 } 

}
