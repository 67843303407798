import { Component, OnInit,ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, CommonServicesService } from '../../../../../services/index';
import Swal from 'sweetalert2'
import 'datatables.net';
import 'datatables.net-bs4';
declare var moment: any;
import { Subscription } from 'rxjs';

declare var $:any;
@Component({
  selector: 'app-client-roles',
  templateUrl: './client-roles.component.html',
  styleUrls: ['./client-roles.component.css']
})
export class ClientRolesComponent implements OnInit, OnDestroy {

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  roleDatalist : any = [];
  dataTable: any;
  roleId :number = 1;
  search:any='';
  clientdetailId :any;
  // ClientName: any = localStorage.getItem("ClientName");

  message: any;
  subscription: Subscription;
  usersArrayForRoles:any = [];
  clientName : any = localStorage.getItem("ClientInfoName");
  breadcrumbObj: any;
  HistoryList: any = [];
  permissionListAssign: any = [];

  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private alertService: AlertService ,
    private chRef: ChangeDetectorRef,
    private CommonServicesService: CommonServicesService
  ) { }


   ngOnDestroy() {
     // clear the header filter when user leave the page
     this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
     // unsubscribe to ensure no memory leaks
     this.subscription.unsubscribe();
   }

  ngOnInit() {
    this.route.params.subscribe(params => {
        if(params['id']){
        this.clientdetailId = params['id'];
      }
    });
    this.getAllRoleList();
    this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
  }


  addRoleRoute(){
      this.router.navigate(['/add-client-role',this.clientdetailId]);
  }

  // To set RoleId when user click on Edit icon
  getRoleID (roleid): void {
    localStorage.setItem('clientRoleId',roleid);
    // send Client id to subscribers via observable subject
    this.CommonServicesService.sendClientSectionRoleId(roleid);
  }

  reset(): void {
    /* Clear all the data from add role page after updating the role
    or click on Add icon to subscribers via observable subject
    */
    // this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    this.CommonServicesService.clearAddRolePageData().subscribe(
      message => {
        // console.log('Client List page = ', message);
      });
  }

  seachRecord(){
    this.getAllRoleList();
  }
  /* Reset Search Record */
  resetSearch(){
    this.search = "";
    this.getAllRoleList();
  }
      /* Function for getting All Role List */
      getAllRoleList(){
        if($.fn.DataTable.isDataTable('#client_role_table')){
          $('#client_role_table').DataTable().destroy();
        };
        this.apiService.getAll("role/getAllRoleByType?userType=Client&clientId="+this.clientdetailId).subscribe(
          data => {
            if(data.statusCode == 100){
              this.roleDatalist = data.responseList;
              this.chRef.detectChanges();
              const table: any = $('#client_role_table');
              this.dataTable = table.DataTable({"order": [], "destroy":true,"columnDefs": [{ "orderable": false, "targets": [2] },  {"width": "30%", "targets": 0},
              {"width":'60%', "targets": 1}, {"width":'10%', "targets": 2}]});
            }else{
              this.roleDatalist = [];
              this.chRef.detectChanges();
              const table: any = $('#client_role_table');
              this.dataTable = table.DataTable({"order": [], "destroy":true,"columnDefs": [{ "orderable": false, "targets": [2] }]});
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }

      DeleteRole(roleId){
        Swal({
          title: 'Are you sure?',
          text: "You want to delete this Role.",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
           this.apiService.delete('role/deleteRole?roleId='+roleId, '').subscribe(
           data => {
               if(data.statusCode == 100){
                 Swal({
                      position: 'top-end',
                      type: 'success',
                      title: 'Role Delete Successful',
                      showConfirmButton: false,
                      timer: 3500
                    }) ;

                     this.getAllRoleList();

                }else{
                  Swal({
                      position: 'top-end',
                      type: 'error',
                      title: data.responseMessage,
                      showConfirmButton: false,
                      timer: 3500
                    })
                 }
              },
             error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
                    }
                );
        }
        })
      }


      // getUsersOfRoles(roleId){
      //   var URL = 'role/getRoleByClient?userId='+this.userId+'&clientId='+this.clientdetailId;
      // //   if(this.search !=''){
      // //     var searchString   = '&search='+this.search;
      // //     var URL = URL.concat(searchString);
      // //   }
      // //   if(this.customerId != null && this.customerId !=''){
      // //   var customerString     = '&customerId='+this.customerId.toString();
      // //   var URL = URL.concat(customerString);
      // // }
      // // if(this.tierId != null && this.tierId !=''){
      // //   var tierString     = '&tierId='+this.tierId.toString();
      // //   var URL = URL.concat(tierString);
      // // }
      // $('#showUserList').modal('show');
      //   this.apiService.getAll(URL).subscribe(
      //     data => {
      //       if(data.statusCode == 100){
      //         var _this = this;
      //         this.roleDatalist.forEach(function (arrayItem) {           
      //             if(roleId == arrayItem.roleId)
      //             {
      //                _this.usersArrayForRoles.roleName = arrayItem.roleName;
      //                 _this.usersArrayForRoles.users = arrayItem.users;
      //             }          
                
      //         });             
      //         $("#UserRoleModal").click();
      //       }else{
      //         this.usersArrayForRoles = [];
      //       }
      //     },
      //     error =>{
      //              this.alertService.sweetMessage('error',"Something went wrong, please try again "); 
      //            }
      //   );
      // }

      getUsersOfRoles(roleId){
        var URL = 'role/getRoleByClient?userId='+this.userId+'&clientId='+this.clientdetailId;
      //   if(this.search !=''){
      //     var searchString   = '&search='+this.search;
      //     var URL = URL.concat(searchString);
      //   }
      //   if(this.customerId != null && this.customerId !=''){
      //   var customerString     = '&customerId='+this.customerId.toString();
      //   var URL = URL.concat(customerString);
      // }
      // if(this.tierId != null && this.tierId !=''){
      //   var tierString     = '&tierId='+this.tierId.toString();
      //   var URL = URL.concat(tierString);
      // }
        this.apiService.getAll(URL).subscribe(
          data => {
            if(data.statusCode == 100){
              var _this = this;
              this.roleDatalist.forEach(function (arrayItem) {           
                  if(roleId == arrayItem.roleId)
                  {
                    _this.usersArrayForRoles.roleName = arrayItem.roleName;
                    _this.usersArrayForRoles.users = arrayItem.users;
                  }          
              });             
              // $("#UserRoleModal").click();
              $('#UserRoleModal').modal('show');
            }else{
              this.usersArrayForRoles = [];
            }
          },
          error =>{
                   this.alertService.sweetMessage('error',"Something went wrong, please try again "); 
                 }
        );
      }

      getRoleUserName(users){
        let userName = '';
        if (users) {
          users.map((obj,index)=>{
            if (index==0) {
              userName += (obj && obj.middleName ? obj.firstName+" ("+obj.middleName+") "+obj.lastName : obj.firstName +" " +obj.lastName)
            }else{
              userName = userName+', ' +(obj && obj.middleName ? obj.firstName+" ("+obj.middleName+") "+obj.lastName : obj.firstName +" " +obj.lastName)
            }
          })
        }
        return userName;
      }
      
  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('role/getRoleHistory?roleId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

  setPermissonList(permissions){
    this.permissionListAssign = permissions;
  }

  replacePermissionName(permission){
    let str = ''
    if (permission.groupName) {
      let strName = permission.groupName;
      let Name = strName.replace(/-/g," ")
      str = str+Name
    }
    if (permission.permission) {
      let strName = permission.permission;
      let Name = strName.replace(/-/g," ")
      str = str + ' '+Name
    }    
    return str;
  }

  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }
}
