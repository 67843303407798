import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, AlertService } from '../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import * as _ from "lodash";
import { jqxTreeComponent } from '../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../jqwidgets-ts/angular_jqxdropdownbutton'
import { Location } from '@angular/common';
@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('addUserForm') addUserForm: any;
  @ViewChild('imageUrlName') imageUrlName: ElementRef;

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId: any = (localStorage.getItem("customerId") ? JSON.parse(localStorage.getItem("customerId")) : []);
  tiersId: any = (localStorage.getItem("tierId") ? JSON.parse(localStorage.getItem("tierId")) : []);
  newUserId: number = 0;
  roleList: any = [];
  roles: any = [];
  customerList: any = [];
  tierList: any = [];
  tierListData: any = [];
  checkCustomerArray: any = [];
  commMode: number = 0;
  dataAdapter: any = [];
  source: any = {};
  dropdownSettings: any = {};
  data: any = [];
  tierArray: any = [];
  selectedItems: any = [];
  customerArray: any = [];
  records: any = [];
  checkTreeArray: any = [];
  disableTreeArray: any = [];
  roleIds: any = [];
  ClientIdArr: any = [];
  user: any = { "title": "", "firstName": "", "middleName": "", "lastName": "", "note": "", "login": "", "password": "", "conpassword": "", "phonePin": "", "isActive": true, "isAppUser": false, "communicationMode": "", "userType": "AMCi", "emailId": "", "phoneNumber": "", "customers": [] };
  comm: any = { "Push": false, "Email": false, "SMS": false, "IVRS": false, "Call": false };
  clientDataList: any = [];
  clientIds: any = []
  checkCommMode: boolean;
  dropdownSettingsClient = {
    singleSelection: false,
    idField: 'clientId',
    textField: 'description',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };
  passwordError: any = 'Password is required';


  PassInfo: any = `    1. Password must be at least 8 characters.<br>
2. Password must contain at least 1 numeric character.<br>
3. Password must contain at least 1 uppercase character.<br>
4. Password must contain at least 1 special character !@#/\$%^&*().<br>
5. Password cannot contain spaces.`;

  PassInfoToolTip: any = `
1. Password must be at least 8 characters.
2. Password must contain at least 1 numeric character.
3. Password must contain at least 1 uppercase character.
4. Password must contain at least 1 special character !@#/\$%^&*().
5. Password cannot contain spaces.`;

  passwordPattern: boolean = false;
  roleError = '';

  emailDomain = ''
  showGlobalUser = false;

  imageUrl: any = "assets/img/upload-img.png";
  imgBasePath: any = "";
  imageError: any = "";
  imageName: any = "";
  imageTitle: any = "";
  clientName: any = localStorage.getItem("clientName");
  clientUser: any = JSON.parse(localStorage.getItem("clientUser"));
  loginUserType: any = this.clientUser ? this.clientUser.userType : '';//to show breadcrumb conditionly

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService, private location: Location) { }

  ngOnInit() {

    // this.getCustomerList();
    // this.getTierList();

    let amciEmailDomain = localStorage.getItem("clientUser") ? JSON.parse(localStorage.getItem("clientUser")).amciEmailDomain : ''
    this.emailDomain = amciEmailDomain
    this.getClientDrodownlist();
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.newUserId = params['id'];
      }
    });
    if (this.newUserId) {
      this.getUserById();
    }else {
    this.getRoleList('AMCi');
    }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'customerId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
  }

  /* Handle tier tree chacked unchecked Event */
  CheckChange(event) {
    if (event.args.checked == true) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist == '-1') {
        this.checkTreeArray.push(event.args.element.id);
      }
    } else if (event.args.checked == false) {
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if (exist != '-1') {
        this.checkTreeArray.splice(exist, 1);
      }
    }
  }

  /* On Item Select Customer */
  onItemSelect(item: any) {
    var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
    if (exist == '-1') {
      this.checkCustomerArray.push(item.customerId.toString());
    }
  }

  /* On Item DeSelect Customer */
  onItemDeselect(item: any) {
    var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
    if (exist != '-1') {
      this.checkCustomerArray.splice(exist, 1);
    }
  }
  /* On All Select Customer */
  onSelectAll(items: any) {
    this.checkCustomerArray = [];
    Object.keys(items).forEach(key => {
      this.checkCustomerArray.push(items[key]['customerId'].toString());
    });
  }
  /* On All DeSelect Customer */
  onDeSelectAll() {
    this.checkCustomerArray = [];
  }
  /* Create Tier array */
  createTierArray() {
    Object.keys(this.checkTreeArray).forEach(key => {
      if (this.checkTreeArray[key] != 0) {
        this.tierArray.push({ "tierId": this.checkTreeArray[key] });
      }
    });
    this.user.tiers = this.tierArray
  };
  /* Create Tier array */
  createCustomerArray() {
    Object.keys(this.checkCustomerArray).forEach(key => {
      if (this.checkCustomerArray[key] != 0) {
        this.customerArray.push({ "customerId": this.checkCustomerArray[key] });
      }
    });
    this.user.customers = this.customerArray
  };
  /* check tree checkbox */
  checkedCheckboxOnLoad() {
    Object.keys(this.checkTreeArray).forEach(key => {
      let checkedItem = document.getElementById(this.checkTreeArray[key]);
      this.myTree.expandItem(checkedItem);
      this.myTree.checkItem(checkedItem, true);
    });
  }

  checkItemCommunication() {
    this.commMode = this.user.communicationMode;
    var number = this.user.communicationMode;
    // if((number - 16) >= 0){
    //   number = number - 16;
    //   this.comm.Call = true;

    // }
    if ((number - 8) >= 0) {
      number = number - 8;
      this.comm.IVRS = true;
    }
    if ((number - 4) >= 0) {
      number = number - 4;
      this.comm.Call = true;
    }
    if ((number - 2) >= 0) {
      number = number - 2;
      this.comm.SMS = true;
    }
    if ((number - 1) >= 0) {
      number = number - 1;
      this.comm.Email = true;
    }
  }

  /* Create communication logic */
  getCommMode(e) {
    this.checkCommMode = false;
    if (e.target.name == 'Email') {
      if (e.target.checked) {
        this.commMode = this.commMode + 1;
      } else {
        this.commMode = this.commMode - 1;
      }
    } else if (e.target.name == 'SMS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 2;
      } else {
        this.commMode = this.commMode - 2;
      }
    } else if (e.target.name == 'Call') {
      if (e.target.checked) {
        this.commMode = this.commMode + 4;
      } else {
        this.commMode = this.commMode - 4;
      }
    }
    else if (e.target.name == 'IVRS') {
      if (e.target.checked) {
        this.commMode = this.commMode + 8;
      } else {
        this.commMode = this.commMode - 8;
      }
    }
    // else{
    //   if(e.target.checked){
    //     this.commMode = this.commMode + 16;
    //   }else{
    //     this.commMode = this.commMode - 16;
    //   }
    // }
    this.user.communicationMode = this.commMode;
  }

  /* Create role array */
  updateRoleOptions(rlist, event) {
    if (event.target.checked == true) {
      this.roles.push({ "roleId": rlist.roleId });
      this.roleError = ''
    } else {
      for (var i = 0; i < this.roles.length; i++) {
        if (this.roles[i].roleId == rlist.roleId) {
          this.roles.splice(i, 1);
        }
      }
    }
    this.user.roles = this.roles;
    if (this.roles.length == 0) {
      this.roleError = 'Please select role'
    }
  }

  treeItemDisable() {
    // Object.keys(this.disableTreeArray).forEach(key=> {
    //   let checkedItem = document.getElementById(this.disableTreeArray[key]);
    //       this.myTree.expandItem(checkedItem);
    //       this.myTree.disableItem(checkedItem);
    // });
  }

  /* Get tier list */
  getTierList() {
    this.apiService.getAll('tier/getAllTierByUser?clientId=' + this.clientId + '&userId=' + this.userId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.tierListData = data.responseList;
          this.data = this.tierListData;
          Object.keys(this.data).forEach(key => {
            if (this.data[key]['flag'] == false) {
              this.disableTreeArray.push(this.data[key]['tierId']);
            }
          });
          this.source = {
            datatype: 'json',
            datafields: [
              { name: 'tierId' },
              { name: 'parentTierId' },
              { name: 'tierName' },
            ],
            id: 'tierId',
            localdata: this.data
          };
          this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
          this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' }, { name: 'tierId', map: 'id' }]);
          var self = this;
          setTimeout(function () {
            self.treeItemDisable();
          }, 1000);
        } else {
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getClientDrodownlist() {
    this.apiService.getAll('client/getClientDropDownList').subscribe(
      data => {
        if (data.statusCode == 100) {
          this.clientDataList = data.responseList;
        } else {
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  /* On Item Select Site */
  onItemSelectClient(item: any) {
    var exist = this.ClientIdArr.indexOf(item.clientId.toString());
    if (exist == '-1') {
      this.ClientIdArr.push(item.clientId.toString());
    }

  }

  /* On Item DeSelect Site */
  onItemDeselectClient(item: any) {
    var exist = this.ClientIdArr.indexOf(item.clientId.toString());
    if (exist != '-1') {
      this.ClientIdArr.splice(exist, 1);
    }
  }

  /* On All Select Site */
  onSelectAllClient(items: any) {
    this.ClientIdArr = [];
    Object.keys(items).forEach(key => {
      this.ClientIdArr.push(items[key]['clientId'].toString());
    });
  }
  /* On All DeSelect Site */
  onDeSelectAllClient() {
    this.ClientIdArr = [];
    // this.getAllAssetList();
  }


  hideError() {
    $("#passerror").css("display", "none");
  }

  clearError() {
    $("#Uppasserror").css("display", "none");
  }


  passwordChangeEvent(obj) {
    var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
    if (obj && obj.length > 7 && regx.test(obj) == false) {
      this.passwordPattern = true;
    } else {
      this.passwordPattern = false;
    }
  }
  /* Add user */
  addUser() {
    // console.log("this.user---0000------>",this.user)
    // this.createTierArray();
    // this.createCustomerArray();

    // let client = []
    // this.ClientIdArr.map((val,key)=>{
    //   client.push({'clientId':val});
    // })

    // if(this.roles.length <= 0){
    //   this.roleError = 'Please select role'
    //   return;
    // }else{
    //   this.roleError = '';
    // }

    // var regx = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // var RegExpConsicutive = new RegExp("(?=.*[A-Za-z])(?=.{2,})");
    // if(this.user.password && regx.test(this.user.password) == false && this.newUserId == 0) {
    //   this.passwordPattern = true;
    // }else{
    //   this.passwordPattern = false;
    // }

    if (this.user.userType != "CallCenter") {
      // if(this.comm.Push || this.comm.Email || this.comm.Call || this.comm.IVRS || this.comm.SMS){
      if (this.comm.Email || this.comm.SMS) {
        this.checkCommMode = false;
      } else {
        this.checkCommMode = true;
      }
    }

    if (this.addUserForm.valid && this.user.conpassword == this.user.password && !this.checkCommMode) {
      this.apiService.update('user/updateUser', this.user).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.imageSave(this.newUserId)
            this.alertService.sweetMessage('success', 'User update Successfully');
          } else {
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }

    // else {
    //   Swal({
    //     position: 'top-end',
    //     type: 'error',
    //     title: 'Please enter all required fields.',
    //     showConfirmButton: false,
    //     timer: 1500
    //   })
    // }

  }

  /* Get Role list */
  getRoleList(type) {
    // var URL = 'role/getAllRoleList';
    var URL = 'role/getAllRoleByType?userType=' + type;
    // var URL = 'role/getRoleListByClient?clientId='+this.clientId+'&userId='+this.userId;
    // if(this.customerId != null && this.customerId !=''){
    //   var customerString     = '&customerId='+this.customerId.toString();
    //   var URL = URL.concat(customerString);
    // }
    // if(this.tiersId != null && this.tiersId !=''){
    //   var tierString     = '&tierId='+this.tiersId.toString();
    //   var URL = URL.concat(tierString);
    // }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.roleList = data.responseList;
        } else {
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  /* Get Role list */
  getCustomerList() {
    var URL = 'customer/getCustomerListByClient?clientId=' + this.clientId + '&userId=' + this.userId;
    if (this.customerId != null && this.customerId != '') {
      var customerString = '&customerId=' + this.customerId.toString();
      var URL = URL.concat(customerString);
    }
    if (this.tiersId != null && this.tiersId != '') {
      var tierString = '&tierId=' + this.tiersId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.customerList = data.responseList;
        } else {
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getUserById() {
    this.apiService.getAll('user/getUserByUserId?userId=' + this.newUserId).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.user = data.user;
          if (this.user.userType) {
            this.getRoleList(this.user.userType);
          }
          this.checkItemCommunication();
          Object.keys(this.user.tiers).forEach(key => {
            this.checkTreeArray.push(this.user.tiers[key]['tierId'].toString());
          });
          var that = this;

          this.selectedItems = this.user.customers;
          if (this.user.customers.length) {
            Object.keys(this.user.customers).forEach(key => {
              this.checkCustomerArray.push(this.user.customers[key]['customerId'].toString());
            });
          }
          this.roles = data.user.roles;

          this.roleIds = _.map(this.user.roles, 'roleId');
          this.clientIds = this.user.clients
          var self = this;
          _.map(this.user.roleIds, function (value, key) {
            self.user.roleIds.push({ roleId: value.id });
          });

          // this.imageUrl = this.user.imageLocation
          if (this.user.imageLocation != null) {
            this.imageUrl = this.apiService.imageBasePath + this.user.imageLocation;
            let imageArr = this.user.imageLocation.split('/');
            this.imageTitle = imageArr[1];
          }

        } else {
          Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


  changeEmail() {
    setTimeout(() => {
      let domain = this.user.emailId ? (this.user.emailId.indexOf('@') >= 0 ? this.user.emailId.split('@') : this.user.emailId.split('@')) : this.user.emailId.split('@')
      if (domain.length > 0) {
        if (domain[1] && domain[1] == this.emailDomain) {
          this.showGlobalUser = true;
        } else {
          this.showGlobalUser = false;
        }
      } else {
        this.showGlobalUser = false;
      }
    }, 200);

  }



  /***************************
   Upload Image On Change
  **************************/
  onFileChanged(event) {
    this.imageError = ""
    const file = event.target.files;
    // this.imageName = file;

    // let imageInput:any = document.getElementById('imageInput');
    // var isValid = /\.jpe?g$/i.test(imageInput.value);
    // if (!isValid) {
    //   alert('Only jpg files allowed!');
    //   return;
    // }
    const fileType = file[0]['type'];
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    // invalid file type code goes here.
    if (!validImageTypes.includes(fileType)) {
      // this.imageError = "please select image"
      // return;
      this.imageUrl = "assets/img/upload-img.png";
      this.imageName = null;
      this.imageTitle = "";
      this.imageError = "Please select valid image type such as jpg, jpeg, png.";
      return;
    }
    if (this.imageError == "") {
      var reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (_event: any) => {
        var img = new Image();
        img.src = _event.target.result;
        img.onload = () => {
          if (img.width <= 300 && img.height <= 300) {
            this.imageUrl = reader.result as string;
            this.imageUrl = reader.result as string;
            this.imageName = file;
            this.imageTitle = file[0]['name'];
          }
          else {
            // this.imageError = "Image Size should be less then 300X300"
            // return;
            this.imageUrl = "assets/img/upload-img.png";
            this.imageName = null;
            this.imageTitle = "";
            this.imageError = "Image Size should be less than 300X300";
            return;
          }
        };
      }
    }
    // if (this.userId != 0) { }
  }

  // function to save the image
  imageSave(newUserId) {
    if (this.imageError == "") {
      const uploadData = new FormData();
      if (this.imageName.length) {
        uploadData.append('file', this.imageName[0], this.imageName[0].name);
        // this.apiService.create('uploadImage/uploadImage?section=2&sectionId=' + this.customerId, uploadData).subscribe(
        // uploadImage/uploadImage?section=1&sectionId=4
        this.apiService.create('uploadImage/uploadImage?section=1&sectionId=' + newUserId, uploadData).subscribe(
          data => {
            if (data.statusCode == 100) {
              // this.getCustomerById();
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
            this.getUserInfo();
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            this.getUserInfo();
          });
      } else {
        this.getUserInfo();
      }
    } else {
      this.getUserInfo();
    }
  }

  getUserInfo() {
    this.apiService.getAllDashboard('user/getUserInfo').subscribe(
      data => {
        if (data.statusCode == 100) {
          localStorage.setItem("clientUser", JSON.stringify(data.response));
          localStorage.setItem("userId", data.response.userId);
          localStorage.setItem("clientId", data.response.defaultClientId);
          localStorage.setItem("permission", JSON.stringify(data.response.permissions));
          var logdata = JSON.parse(localStorage.getItem("clientUser"));
        } else if (data.statusCode == 105) {
          var msg = data.responseMessage;
        } else {
        }
        // this.location.back();
        if (this.loginUserType != 'CallCenter') {
          this.router.navigate(['/dashboard']);
        } else {
          // this.location.back();
          this.router.navigate(['/callcenter/call-center-dashboard']);
        }
      },
      error => {
      }
    );
  }

  removeInputImage() {
    this.imageUrlName.nativeElement.value = '';
    this.imageTitle = '';
  }

  // function for deleting image of branding 
  removeBrandingImage() {
    Swal({
      title: 'Are you sure?',
      text: 'You want to remove image.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete("uploadImage/removeImage?section=1&sectionId=" + this.newUserId, '').subscribe(
          data => {
            if (data.statusCode == 100) {
              this.alertService.sweetMessage('success', 'Image removed successfully');
              this.imageUrl = 'assets/img/upload-img.png'
            } else {
              this.alertService.sweetMessage('error', data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }



}
