import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService, FormatDateService, CommonServicesService}  from '../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  clientId: any = localStorage.getItem("clientdetailId");
  userId: any = localStorage.getItem("userId");
  assetId :any = 0;
  assetsumaryData : any ={};
  asseteventData: any = [];
  dataTable: any;
  siteLat:number = 39.381266;
  siteLong:number = -97.922211;
  clientCode: any = '';
  // permissionData:any=[];
  clientIdURL: any = '';
  subscription:any;
  clientFilterId:any = 0;
  clientName : any = localStorage.getItem("clientName");
  clientTopDropdownValue : any;
  checkDateDiff = false;
  maxRecords:any = 50;
  showAckBtn : number = 0;
  showAckErcBtn : number = 0;
  showERC : number = 0; 
  performAction : any = 0;
  breadcrumbObj:any = null;
  urlCustomerId:any = null;
  HistoryList: any = [];
  responsibilityList: any = [];
  rollUpEventList: any = [];
  constructor(
    private router:Router,
    private route: ActivatedRoute,
    private apiService: ApiService ,
    private formatDateService: FormatDateService,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef,
    private CommonServicesService :CommonServicesService
    ) {
    // this.permissionData = ApiService.permission;
    this.subscription = this.CommonServicesService.getClientId()
    .subscribe(
      message => {
        this.clientFilterId = message.id;
        if (this.clientFilterId!='0' && this.clientFilterId!=null) {
          this.route.params.subscribe(params => {
            if(params['id']){
              this.assetId = params['id'];
            }
            if(params['clientId']){
              this.clientIdURL = params['clientId'];
            }
          });
        }else{
          this.route.params.subscribe(params => {
            if(params['id']){
              this.assetId = params['id'];
            }
            if(params['clientId']){
              this.clientIdURL = params['clientId'];
              this.clientFilterId = this.clientIdURL;
            }
          });
        }
      },
      error=>{
        console.log(error)
      }
    );
   }

  // Function to format Date
  DateFormat(startDate, gmtOffset){
    if (startDate) {
      var formattedDate = moment(startDate,"YYYY-MM-DD HH:mm:ss:SSS").format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    } else {
      return '-'
    }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
     this.route.params.subscribe(params => {
        if(params['id']){
          this.assetId = params['id'];
        }
        if(params['clientId']){
          this.clientIdURL = params['clientId'];
        }
      });
    
      this.route
      .queryParams
      .subscribe(params => {
          this.breadcrumbObj = params['source'];
          this.urlCustomerId = params.cus_id;
      })

      var today = new Date();

  var hr  = (today.getHours() ? (today.getHours() > 9 ? today.getHours() : '0' + today.getHours()) : '00');

  var min = (today.getMinutes() ? (today.getMinutes() > 9 ? today.getMinutes() : '0' + today.getMinutes()) : '00');

  var sec = (today.getSeconds() ? (today.getSeconds() > 9 ? today.getSeconds() : '0' + today.getSeconds()) : '00');

  var month = ((today.getMonth()+1) ? ((today.getMonth()+1) > 9 ? (today.getMonth()+1) : '0' + (today.getMonth()+1)) : '00');

  var Tdate = (today.getDate() ? (today.getDate() > 9 ? today.getDate() : '0' + today.getDate()) : '00');

  var date     = today.getFullYear()+'-'+month+'-'+Tdate;
  var time     = hr + ":" + min + ":" + sec;
  var dateTime = date+' '+time;

// Fill default date time in the input fields
  $("#startDate").val( moment(date+' '+time).subtract(3,'days').format('YYYY-MM-DD HH:mm:ss')/*  date+' '+"00:00:00" */);
  $("#endDate").val(moment(date+' '+time).format('YYYY-MM-DD HH:mm:ss'));


    let self = this
    jQuery('.rst-date').datetimepicker({
        language: 'en',
        pickTime: true,
        pickDate: true,
        minDate: 0,
        inline: true,
        showButtonPanel: true,
        showTodayButton:true,
        showClose:true,
        sideBySide: true,
        endDate:new Date()
    }).on('changeDate', function(e){
      let startDate = $('#startDate').val();
      let endDate = $('#endDate').val();
      if(startDate && endDate){
          if(moment(startDate).isBefore(endDate)){
              self.checkDateDiff = false;
          }else{
              self.checkDateDiff = true;
          }
      }
      if(startDate == ''){
          $('#start_date_err').css('display', 'block');
          return;
      }
      else 
      {
          $('#start_date_err').css('display', 'none');
      }

      if(endDate == '')
      {
          $('#end_date_err').css('display', 'block');
          return;
      }
      else 
      {
          $('#end_date_err').css('display', 'none');
      }

    });;
    jQuery('.rst-time').datetimepicker({
        pickDate: true,
        pickTime: true,
        pickSeconds: false,
        pickMinutes: true,
        showClose:true,
        showButtonPanel: true,
        showTodayButton:true,
        timeFormat: "HH:mm:ss"
    });

    if(this.assetId){
      this.getAssetSummaryData();
      this.getAssetEventData();
    }
    $(document).on('hidden.bs.modal', function () {
      if($('.modal.show').length)
      {
        $('body').addClass('modal-open');
      }
    });
  }

  // Function to set latitude and longitude
  setLatLong(lat,long){
    this.siteLat = lat;
    this.siteLong = long;
  }

  // Listen For event Recive from child
    reciveAsset(){
      this.getAssetSummaryData();
    }

  /* Function for getting Root level tier data */
  getAssetSummaryData(){
    let clientIdURL = this.clientIdURL;
    let cid = '';
    if(clientIdURL != ''){
      cid = 'clientId='+this.clientIdURL+'&';
    }
    let URL = 'asset/getAdminAssetSummaryById?'+cid+'assetId='+this.assetId+'&userId='+this.userId;
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetsumaryData= data.response;
        }else{
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
    );
  }

  /* Function for getting Asset Event data */
  getAssetEventData(){
    // let clientFilterId = this.clientFilterId;
    // this.clientTopDropdownValue = $('#client_dropdown_top').children("option:selected").val();
    // let cid = '';
    // if(clientFilterId){
    //   cid = 'clientId='+this.clientFilterId+'&';
    // } else {
    //   cid = 'clientId='+this.clientTopDropdownValue+'&';
    // }

    // as client dropdown is disabled so new client id (from url) send in api request
    let clientIdURL = this.clientIdURL;
    let cid = '';
    if(clientIdURL != ''){
      cid = 'clientId='+this.clientIdURL+'&';
    }

    var fromDate = $("#startDate").val();
    var endDate = $("#endDate").val();
    
    if (fromDate == '' || fromDate == null) {
      
    }
    if (endDate == '' || endDate == null) {
      
    }

    if(fromDate == '')
    {
        $('#start_date_err').css('display', 'block');
        return;
    }
    else 
    {
        $('#start_date_err').css('display', 'none');
    }

    if(endDate == '')
    {
        $('#end_date_err').css('display', 'block');
        return;
    }
    else 
    {
        $('#end_date_err').css('display', 'none');
    }
    
    if(moment(fromDate).isBefore(endDate)){
      // this.addReportDataList();
    }
    else{ 
        this.checkDateDiff = true;
        return;
    }

    let URL = 'wffnotification/getAssetEventList?'+cid+'assetId='+this.assetId+'&userId='+this.userId+"&sDate="+fromDate+"&eDate="+endDate+"&endlimit="+this.maxRecords;
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.asseteventData= data.responseList;
          if($.fn.DataTable.isDataTable('#eventTable')){
            $('#eventTable').DataTable().destroy();
          };
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#eventTable');
            this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": [6] }]});
          }, 500);
          // this.dataTable = table.DataTable({aaSorting: [[3, 'desc']]});
        }else{
          this.asseteventData= [];
          this.chRef.detectChanges();
          // const table: any = $('#eventTable');
          // this.dataTable = table.DataTable(); 
          setTimeout(() => {
            const table: any = $('#eventTable');
            this.dataTable = table.DataTable({"columnDefs": [{ "orderable": false, "targets": [6] }]});
          }, 500);
          // this.dataTable = table.DataTable({aaSorting: [[3, 'desc']]});
          /*  this.alertService.sweetMessage('error',data.responseMessage);*/
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /** Function to Sort Response Data **/
  sortDataTableData(items,sortBy,desc){
       if (sortBy && sortBy != null) {
              items = items.sort((a, b) => {
               const sortA = a[sortBy];
               const sortB = b[sortBy];
               if (desc) {
                   if (sortA < sortB) return 1;
                   if (sortA > sortB) return -1;
                   return 0;
               } else {
                   if (sortA < sortB) return -1;
                   if (sortA > sortB) return 1;
                   return 0;
               }
           });
          return items;
       }else{
          return items;
       }
  }
  notificationDetail(eventData, eventListType) {
    let runTimeStatus = eventData.runTimeStatus;
    let licenseIsAck = eventData.licenseIsAck;
    let acknowledgeByMe = eventData.acknowledgeByMe;
    let assignToMe = eventData.assignToMe;
    let technicianId = eventData.technicianId;      
    let licenseIsTechEntry = eventData.licenseIsTechEntry;
    let isAck = eventData.isAck;
    let clientId = eventData.clientId;

    if(this.userId == eventData.technicianId && runTimeStatus == 2) {
       this.performAction = 1;
    } 

    console.log('notification detail', eventData.acknowledgeByMe, eventData.licenseIsAck, eventData.assignToMe);
    
    console.log('notification detail 2', eventData.technicianId, eventData.runTimeStatus, eventData.licenseIsTechEntry);
    

    if(licenseIsAck == true && acknowledgeByMe == false && assignToMe == true) {
      console.log('in if detail');
      this.showAckBtn = 1;
    } else {
      this.showAckBtn = 0;
      console.log('in else detail');
    }

    if(
      licenseIsAck == true && 
      acknowledgeByMe == false && 
      assignToMe == true && 
      (technicianId == null || technicianId == 0) && 
      licenseIsTechEntry == true && 
      runTimeStatus == 1
      ) {
      console.log('in if detail showAckErcBtn');
      this.showAckErcBtn = 1;
    } else {
      this.showAckErcBtn = 0;
      console.log('in else detail showAckErcBtn');
    }

    if(
      licenseIsAck == true && 
      assignToMe == true && 
      (technicianId == null || technicianId == 0) &&         
      runTimeStatus == 1
      ) {
      this.showERC = 1;
    } else {
      this.showERC = 0;
    }

    let obj = {
      id:null,
      type:null
    }
    let pVal = 0;
    if(eventData.isParent == false) {
      pVal = 0;
    } else if(eventData.isParent == true) {
      pVal = 1;
    }
    obj.type = eventData.notificationType;
    if (eventData.notificationType == "Notification") {
        obj.id = eventData.notificationId;
    } else if (eventData.notificationType == "RunTime") {
        obj.id = eventData.runTimeEventId;
    }
    if(eventListType == 'approval' || eventListType == 'approved') {
      obj.type = 'RunTime';
      obj.id = eventData.runTimeEventId;
      this.performAction = 1;
      eventData.licenseIsTechEntry = true;
    }

    this.router.navigate(["eventDetails", eventData.assetId,], 
      {
        queryParams: 
        { 
          type: obj.type, 
          id :obj.id, 
          rcpId:eventData.runTimeCollectionPointId, 
          notificationId : eventData.notificationId ? eventData.notificationId : 0, 
          isParent : pVal, 
          performAction : this.performAction, 
          showAckBtn : this.showAckBtn,
          showAckErcBtn : this.showAckErcBtn,
          showERC : this.showERC,
          licenseIsTechEntry : eventData.licenseIsTechEntry, 
          eventListType : eventListType ,
          assignToMe : assignToMe == true ? 1 : 0,
          isAck : isAck == true ? 1 : 0,
          clientId : clientId ? clientId : 0,
          // previousRoute: this.router.url,
          source:this.breadcrumbObj ? (this.breadcrumbObj+'E_D') : 'E_D',
          cus_id:this.urlCustomerId,
          aId:this.assetId, 
          // cusD_name:this.CdName,
          cust_name:this.assetsumaryData.site ? (this.assetsumaryData.site.customer ? this.assetsumaryData.site.customer.name: null) : null
        
        }
    });
  }

  getHistory(obj){
    this.HistoryList = []
    let URL = ''
    if (obj.notificationType == 'Notification') {
      URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.notificationId+'&isevent=false'
      // URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+943731+'&isevent=true'
    }else{
      URL = 'runTimeEvent/getRuntimeEventHistoryDetail?eventId='+obj.runTimeEventId+'&isevent=true'
    }

    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll(URL).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                 table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                 table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }
  setResponsibilityList(list){
    this.responsibilityList = list
  }

  setEventrollupLists(list){
    this.rollUpEventList = list
  }
  getCommentDecoded(comment){
    return decodeURIComponent(comment)
  }
}


