import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}  from '../../../../../../services/index';
import Swal from 'sweetalert2'
/*import * as $ from 'jquery'
declare var d3:any,moment:any;;*/
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-customer-notification-plan',
  templateUrl: './customer-notification-plan.component.html',
  styleUrls: ['./customer-notification-plan.component.css']
})

export class CustomerNotificationPlanComponent implements OnInit {
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerName : any = localStorage.getItem("customerName");
  notificationPlanData: any = [];
  dataTable: any;
  customerId:number=0;
  clientCode : any = '';
  permissionData:any=[];

  newClientId:any = '';
  clientObj:any = {};
  breadcrumbObj: any;
  
  constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService, private chRef: ChangeDetectorRef) {
    this.permissionData = ApiService.permission;
   }

  // Function to format a date
  DateFormat(startDate){
    if(startDate){
        return moment(startDate, "YYYY-MM-DD h:mm:a").format("MMM D, YYYY h:mm a")
    }else{
        return "";
      }
  }

  ngOnInit() {
    this.clientCode = this.alertService.getClientCode();
     this.route.params.subscribe(params => {
        if(params['id']){
        this.customerId = params['id'];
      }

    });

    this.route.queryParams.subscribe(params => {
      this.newClientId = params.clientId;
      this.clientId = this.newClientId
      this.breadcrumbObj = params['source'];
    });

    if(this.customerId){
      this.getNotificationPlanByCustomer();
    }
    this.getClientById();
  }

  /* Get client by Id*/
  getClientById(){
    this.apiService.getAll('client/getClientById?clientId='+this.newClientId).subscribe(
    data => {
        if(data.statusCode == 100){
            this.clientObj = data.response;

          }else{
            this.alertService.sweetMessage('error',data.responseMessage);
          }
        },
      error =>
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
      );
  }
  /* Get Notification plan data list */
  getNotificationPlanByCustomer(){
    var URL = 'customer/getNotificationPlanListByCustomerAndClient?customerId='+this.customerId+'&clientId='+this.clientId;
    if(this.tierId != null && this.tierId !=''){
      var tierString = '&tierId='+this.tierId.toString();
      var URL = URL.concat(tierString);
    }
    this.apiService.getAll(URL).subscribe(
      data => {

          if(data.statusCode == 100){
              this.notificationPlanData = data.responseList;
              if ($.fn.DataTable.isDataTable('#custNoti_table')){
                  $('#custNoti_table').DataTable().destroy();
                };
               this.chRef.detectChanges();
             const table: any = $('#custNoti_table');
            this.dataTable = table.DataTable();
          }else{
              this.notificationPlanData = [];
               this.chRef.detectChanges();
             const table: any = $('#custNoti_table');
            this.dataTable = table.DataTable();

              /*this.alertService.sweetMessage('error',data.responseMessage); */
                }
              },
         error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
      );
  }

}
