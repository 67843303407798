import { NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, ActivatedRoute,RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipeModule } from 'ngx-filter-pipe';
/* Partial */
import { HeaderComponent } from './partials/header/header.component';
import { FooterComponent } from './partials/footer/footer.component';
/* Dashboard */
import { DashboardComponent } from './section/dashboard/dashboard.component';
// import { MidDashboardComponent } from './section/dashboard/mid-dashboard/mid-dashboard.component';
/* View */
import { ViewComponent } from './view.component';
import { ViewsRoutingModule } from './view-routing.module';

/* tier */
import { TierComponent } from './section/tier/tier.component';
import { TiersComponent } from './section/tier/tiers/tiers.component';

/* client */
import { ClientComponent } from './section/client/client.component';
import { AddClientComponent } from './section/client/add-client/add-client.component';
import { clientTierComponent } from './section/client/tiers/tiers.component';
import { AddClientdetailComponent } from './section/client/clientdetail/add-client/add-client.component';
/* menu client  */
import { ClientMenuComponent } from './section/client/client-menu/client-menu.component'

/* Roles */
import { RolesComponent } from './section/client/roles/roles.component';
/* Site */
import { SitesComponent } from './section/client/sites/sites.component';
/* Customer */
import { CustomerComponent } from './section/client/customer/customer.component';
/* Package */

import { LicenseComponent } from './section/client/license/license.component';
/* Notification-plan */
import { NotificationPlanComponent } from './section/client/notification-plan/notification-plan.component';
/* Users */
import { UsersComponent } from './section/client/users/users.component';
import { AddUserComponent } from './section/user-management/adduser/adduser.component';
/* Role Management */
import { RoleManagementComponent } from './section/role-management/role-management.component';
import { AddRoleComponent } from './section/role-management/addrole/addrole.component';
/* Site Management */
import { SiteManagementComponent } from './section/site-management/site-management.component';
import { AddSiteComponent } from './section/site-management/add-site/add-site.component';
/* User Management */
import { UserManagementComponent } from './section/user-management/user-management.component';
/* Asset Management */
import { AssetManagementComponent } from './section/asset-management/asset-management.component';
/* Notification Plan Management */
import { NotificationPlanManagementComponent } from './section/notification-plan-management/notification-plan-management.component';
import { AddNotitficationComponent } from './section/notification-plan-management/add-notitfication/add-notitfication.component';
/* Support */
// import { SupportComponent } from './section/support/support.component';
/* Report */
import { ReportComponent } from './section/report/report.component';
/* Setting */
import { SettingComponent } from './section/setting/setting.component';
/* Assets */
import { AssetsComponent } from './section/client/assets/assets.component';
/* Shift  */
// import { ShiftManagementComponent } from './section/shift-management/shift-management.component';
// import { ShiftComponent } from './section/shift-management/shift/shift.component';
/* Scheduler  */
// import { SchedulerComponent } from './section/scheduler/scheduler.component';

/* Notification event  */
import { NotificationEventComponent } from './section/notification-event/notification-event.component';

/* Asset User Tool */
// import { AssetUserComponent } from './section/asset-management/asset-user/asset-user.component';
import { AssetUserComponent } from './section/asset-summary/asset-user/asset-user.component';

/* Live Feed Tool */
import { LiveFeedComponent } from './section/live-feed/live-feed.component';

/* Client Bill */
// import { ClientBillComponent } from './section/client-bill/client-bill.component';

/* Migration Tool */
import { MigrationToolComponent } from './section/migration/migration-tool.component';

import { ClientUserAddComponent } from './section/client/client-user-add/client-user-add.component';
import { TierMigrateComponent } from './section/migration/tier-migrate/tier-migrate.component';
import { SiteMigrateComponent } from './section/migration/site-migrate/site-migrate.component';
import { ClientMigrateComponent } from './section/migration/client-migrate/client-migrate.component';
import { UserMigrateComponent } from './section/migration/user-migrate/user-migrate.component';

// Component For Add product Type
// import { ProductTypeManagementComponent } from './section/product/product-type/product-type-management.component';

// Component For Add product Type
// import { AddProductTypeComponent } from './section/product/add-product-type/AddProductType.component';

// Component For Add product Template Attribute
// import { AddProductTemplateAttributeComponent } from './section/product/add-product-template-attribute/AddProductTemplateAttribute.component';
// component for the product template
// import { ProductTemplateComponent } from './section/product/product-template/product-template/product-template.component';
// import { AddProductTemplateComponent } from './section/product/product-template/add-product-template/add-product-template.component';

import { jqxTreeComponent } from './jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from './jqwidgets-ts/angular_jqxdropdownbutton';
import { jqxCheckBoxComponent } from './jqwidgets-ts/angular_jqxcheckbox';
import { Ng2DragDropModule } from 'ng2-drag-drop';
import { NguiMapModule} from '@ngui/map';
import { FusionChartsModule } from 'angular-fusioncharts';
// import * as FusionCharts from './js/fusioncharts';
// import * as Charts from './js/fusioncharts.charts';
// import * as FintTheme from './js/themes/fusioncharts.theme.fint';
// import * as FusionCharts from 'fusioncharts';
// import * as Charts from 'fusioncharts/fusioncharts.charts';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { ProductTemplateAttributeComponent } from './section/product/add-product-template-attribute/product-template-attribute/product-template-attribute.component';
import { EditorModule } from '@tinymce/tinymce-angular';
// import { CreateProductComponent } from './section/product/create-product/create-product.component';
import { ClientRoleComponent } from './section/role-management/client-role/client-role.component';
import { GlobalSearchComponent } from './section/global-search/global-search.component';
import { ClientBrandingComponent } from './section/client/client-branding/client-branding.component';

// new modules
import { ClientRolesComponent } from './section/client/client-roles/client-roles.component';
import { ClientRolesAddComponent } from './section/client/client-roles/client-roles-add/client-roles-add.component';
import { CallCenterDashboardComponent } from './section/Call-Center-User/call-center-dashboard/call-center-dashboard.component';

import { CallInstructionComponent } from './section/Call-Center-User/call-instruction/call-instruction.component';

// Asset Summary Section
import { SortablejsModule } from 'angular-sortablejs';
import { NgDygraphsModule } from 'ng-dygraphs';
import { AssetSummaryComponent } from './section/asset-summary/asset-summary.component';
import { AssetsummaryMenuComponent } from './section/asset-summary/assetsummary-menu/assetsummary-menu.component';
import { AssetEditComponent } from './section/asset-summary/asset-edit/asset-edit.component';
import { AssetGraphComponent } from './section/asset-summary/asset-graph/asset-graph.component';
import { ModemTrafficComponent } from './section/asset-summary/modem-traffic/modem-traffic.component';
// import { CommentsComponent } from './section/asset-summary/comments/comments.component';
import { DocumentsComponent } from './section/asset-summary/documents/documents.component';
import { EventsComponent } from './section/asset-summary/events/events.component';
// import { PartletsComponent } from './section/asset-summary/partlets/partlets.component';
// import { ProductsComponent } from './section/asset-summary/products/products.component';
// import { ActivitiesComponent } from './section/asset-summary/activities/activities.component';
import { AssetsLogComponent } from './section/asset-summary/assets-log/assets-log.component';
import { DataPointComponent } from './section/asset-summary/data-point/data-point.component';
// import { AssetsDataPocketComponent } from './section/asset-summary/assets-data-pocket/assets-data-pocket.component';
// import { AssetCalenderComponent } from './section/asset-summary/assets-calender/assets-calender.component';
import { CopyScheduleComponent } from './section/asset-summary/copy-schedule/copy-schedule.component';
import { EventDetailsComponent } from './section/event-details/event-details.component';
import { SchedulerSettingComponent } from './section/setting/scheduler-setting/scheduler-setting.component';
import { CustomerMigrateComponent } from './section/migration/customer-migrate/customer-migrate.component';
import { CallCenterManagmentComponent } from './section/call-center-managment/call-center-list/call-center-managment.component';
import { AddCenterComponent } from './section/call-center-managment/add-center/add-center.component';
import { CallCenterUserComponent } from './section/call-center-managment/call-center-user/call-center-user-list/call-center-user.component';
import { AddCenterUserComponent } from './section/call-center-managment/call-center-user/add-center-user/add-center-user.component';

import { AdminBrandingComponent } from './section/setting/admin-branding/admin-branding.component';
import { RuntimeEventDetailComponent } from './section/Call-Center-User/runtime-event-detail/runtime-event-detail.component';
import { UpdateProfileComponent } from './section/update-profile/update-profile.component';
import { TierEditModeComponent } from './section/tier/tier-edit-mode/tier-edit-mode.component';

import * as FusionCharts from './js/fusioncharts';
import * as Charts from './js/fusioncharts.charts';
import * as FintTheme from './js/themes/fusioncharts.theme.fint';
import { ConsumptionReportComponent } from './section/report/consumption-report/consumption-report.component';
import { UserTierMigrateComponent } from './section/migration/user-tier-migrate/user-tier-migrate.component';


import { AgmCoreModule } from '@agm/core'
import { TooltipModule } from 'ng2-tooltip-directive';
import { AddCustomerComponent } from './section/client/customer/add-customer/add-customer.component';
/* Customer Menu  */
import { CustomerMenuComponent } from './section/client/customer/customer-menu/customer-menu.component';
/* customer details */
import {CustomerDetailComponent} from './section/client/customer/customer-detail/customer-detail.component'
/* customer Notification Plan */
import {CustomerNotificationPlanComponent} from './section/client/customer/customer-notification-plan/customer-notification-plan.component'
/* customer assets  */
import {CustomerAssetsComponent} from './section/client/customer/customer-assets/customer-assets.component'
/* customer roles */
import {CustomerRolesComponent} from './section/client/customer/customer-roles/customer-roles.component'
/* custome users */
import {CustomerUsersComponent} from './section/client/customer/customer-users/customer-users.component'
/* add customer user */
import {AddCustomerUserComponent} from './section/client/customer/customer-users/add-customer-user/add-customer-user.component'
/* view customer user */
import {ViewCustomerUserComponent} from './section/client/customer/customer-users/view-customer-user/view-customer-user.component'
import {NgxPaginationModule} from 'ngx-pagination';
import { PlanManagementComponent } from './section/acount-management/plan-management/plan-management.component';
import { NewPlanComponent } from './section/acount-management/plan-management/new-plan/new-plan.component';
import { DefaultPlanComponent } from './section/acount-management/default-plan/default-plan.component';
import { NewDefaultPlanComponent } from './section/acount-management/default-plan/new-default-plan/new-default-plan.component';
import { ConsumptionDataComponent } from './section/acount-management/consumption-data/consumption-data.component';
import { BillManagementComponent } from './section/acount-management/bill-management/bill-management.component';
import { NewBillComponent } from './section/acount-management/bill-management/new-bill/new-bill.component';
import { InvoiceComponent } from './section/acount-management/invoice/invoice.component'; // <-- import the module
import { BillGenerateComponent } from './section/acount-management/bill-generate/bill-generate.component';
import { InvoiceListComponent } from './section/acount-management/invoice-list/invoice-list.component';
import { TaxListComponent } from './section/acount-management/tax-list/tax-list.component';
import { InAppNotificationComponent } from './section/asset-summary/in-app-notification/in-app-notification.component';
import { UnitsComponent } from './section/asset-summary/units/units.component';
import { SystemHealthComponent } from './section/system-health/system-health.component'; // <-- import the module

FusionChartsModule.fcRoot(FusionCharts, Charts, FintTheme)
FusionCharts.options.SVGDefinitionURL='absolute'
const VIEWS_COMPONENTS = [
  ViewComponent,
  HeaderComponent,
  FooterComponent,
  DashboardComponent,
  // MidDashboardComponent,
  TierComponent,
  TiersComponent,
  RolesComponent,
  SitesComponent,
  LicenseComponent,
  NotificationPlanComponent,
  UsersComponent,
  RoleManagementComponent,
  SiteManagementComponent,
  UserManagementComponent,
  AssetManagementComponent,
  NotificationPlanManagementComponent,
  NotificationEventComponent,
  // SupportComponent,
  ReportComponent,
  SettingComponent,
  AssetsComponent,
  AddSiteComponent,
  AddNotitficationComponent,
  AddUserComponent,
  AddRoleComponent,
  ClientComponent,
  AddClientComponent,
  // ShiftComponent,
  // ShiftManagementComponent,
  // SchedulerComponent,
  jqxTreeComponent,
  ClientMenuComponent,
  clientTierComponent,
  CustomerComponent,
  AddClientdetailComponent,
  AssetUserComponent,
  LiveFeedComponent,
  jqxDropDownButtonComponent,
  // ClientBillComponent,
  jqxCheckBoxComponent,
  ClientUserAddComponent,
  MigrationToolComponent,
  TierMigrateComponent,
  SiteMigrateComponent,
  ClientMigrateComponent,
  UserMigrateComponent,
  // ProductTypeManagementComponent,
  // AddProductTypeComponent,
  // AddProductTemplateAttributeComponent,
  // ProductTemplateComponent,
  // AddProductTemplateComponent,
  // ProductTemplateAttributeComponent,
  // CreateProductComponent,
  ClientRoleComponent,
  GlobalSearchComponent,
  ClientBrandingComponent,
  ClientRolesComponent,
  ClientRolesAddComponent,
  CallCenterDashboardComponent,
  AssetSummaryComponent,
  AssetsummaryMenuComponent,
  AssetEditComponent,
  AssetGraphComponent,
  ModemTrafficComponent,
  // CommentsComponent,
  DocumentsComponent,
  EventsComponent,
  // PartletsComponent,
  // ProductsComponent,
  // ActivitiesComponent,
  AssetsLogComponent,
  CallInstructionComponent,
  DataPointComponent,
  // AssetsDataPocketComponent,
  // AssetCalenderComponent,
  CopyScheduleComponent,
  EventDetailsComponent,
  SchedulerSettingComponent,
  CustomerMigrateComponent,
  CallCenterManagmentComponent,
  AddCenterComponent,
  CallCenterUserComponent,
  AddCenterUserComponent,
  AdminBrandingComponent,
  RuntimeEventDetailComponent,
  UserTierMigrateComponent,
  AddCustomerComponent,
  ConsumptionReportComponent,
  CustomerMenuComponent,
  CustomerDetailComponent,
  CustomerNotificationPlanComponent,
  CustomerAssetsComponent,
  CustomerRolesComponent,
  CustomerUsersComponent,
  AddCustomerUserComponent,
  ViewCustomerUserComponent,
  BillGenerateComponent,
  InvoiceListComponent,
  InAppNotificationComponent,
];

@NgModule({
  imports: [
   ViewsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyCL0Qt9KnWSrhOBpETUKORWvPzC_rbvh9g'}),//AIzaSyBiSNKpoKNL0U6H3dvL1pY5tMr87fCQGRw
    // NguiMapModule.forRoot({apiUrl: 'https://maps.googleapis.com/maps/api/js?callback=initialize&client=gme-americanmillennium'}),
    FusionChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCL0Qt9KnWSrhOBpETUKORWvPzC_rbvh9g'
    }),
    CommonModule,
    FilterPipeModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2DragDropModule.forRoot(),
    EditorModule,
    SortablejsModule,
    NgDygraphsModule,
    TooltipModule,
    NgxPaginationModule
  ],
  declarations: [
    ...VIEWS_COMPONENTS,
    UpdateProfileComponent,
    TierEditModeComponent,
    ConsumptionReportComponent,
    PlanManagementComponent,
    NewPlanComponent,
    DefaultPlanComponent,
    NewDefaultPlanComponent,
    ConsumptionDataComponent,
    BillManagementComponent,
    NewBillComponent,
    InvoiceComponent,
    BillGenerateComponent,
    InvoiceListComponent,
    TaxListComponent,
    UnitsComponent,
    SystemHealthComponent,
  
     ],
})


export class ViewModule {
}
