import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
declare var $:any, moment: any;

@Component({
  selector: 'app-notification-plan',
  templateUrl: './notification-plan.component.html',
  styleUrls: ['./notification-plan.component.css']
})
export class NotificationPlanComponent implements OnInit {
clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
notificationPlanData: any = [];
clientdetailId: any = 0;
dataTable: any;

// clientName : any = localStorage.getItem("clientName");
clientName : any = localStorage.getItem("ClientInfoName");
  breadcrumbObj: any;
  HistoryList: any = [];
  groupEscLevel: any = {};

constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }


  ngOnInit() {
    $(document).on('hidden.bs.modal', function () {
      if($('.modal.show').length)
      {
        $('body').addClass('modal-open');
      }
    });
   this.route.params.subscribe(params => {
      if(params['id']){
      this.clientdetailId = params['id'];
    }

  });
  this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
  if(this.clientdetailId){
   this.getNotificationPlanByClient();
  }
  }

 /* Get Notification plan data list */
getNotificationPlanByClient(){
  if ($.fn.DataTable.isDataTable('#notification_table')){
    $('#notification_table').DataTable().destroy();
    this.notificationPlanData = [];
  };
  //this.apiService.getAll('notification_plan/getNotificationPlanList?clientId='+this.clientdetailId+'&userId='+this.userId).subscribe(
    this.apiService.getAll('notification_plan/getNotificationPlanList?clientId='+this.clientdetailId).subscribe(
    data => {
        if(data.statusCode == 100){
            this.notificationPlanData = data.responseList;
             this.chRef.detectChanges();
             setTimeout(() => {
               const table: any = $('#notification_table');
              // this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 3 }]});
              this.dataTable = table.DataTable();
             }, 200);
        }else{
          this.notificationPlanData = [];
          this.notificationPlanData = data.responseList;
          this.chRef.detectChanges();
          const table: any = $('#notification_table');
          // this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 3 }]});
          this.dataTable = table.DataTable();
        }
      },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
}


/* Delete Notification Management list */
DeleteNotification(notification){
  Swal({
   title: 'Are you sure?',
   text: "You want to delete this Notification.",
   type: 'warning',
   showCancelButton: true,
   confirmButtonColor: '#3085d6',
   cancelButtonColor: '#d33',
   confirmButtonText: 'Yes, delete it!'
 }).then((result) => {
   if (result.value) {
    this.apiService.getAll('notification_plan/deleteNotificationPlan?notificationPlanId='+notification.notificationPlanId).subscribe(
    data => {
        if(data.statusCode == 100){
          Swal({
               position: 'top-end',
               type: 'success',
               title: 'Notification Delete Successfully',
               showConfirmButton: false,
               timer: 3500
             }) ;
            this.getNotificationPlanByClient();
         }else{
           Swal({
               position: 'top-end',
               type: 'error',
               title: data.responseMessage,
               showConfirmButton: false,
               timer: 3500
             })
          }
       },
    error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
       }
    );
   }
 })
}

getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('notification_plan/getNewNotificationPlanHistoryById?notificationId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}

getAssetTooltip(lists){
  let str = ''
  if (lists) {
    if (lists.length) {
      lists.map((obj,index)=>{
        if (index==0) {
          str += obj.assetName
        }else{
          str = str+', '+obj.assetName
        }
      })
    }
  }
  return str;
}

getTierTooltip(list){
  let str = ''
  if (list) {
    if (list.length) {
      list.map((obj,index)=>{
        if (index==0) {
          str += obj.tierName
        }else{
          str = str+', '+obj.tierName
        }
      })
    }
  }
  return str;
}

setGroupList(escalationLevellist){
  var grouped = _.groupBy(escalationLevellist, 'rollname')
  console.log(grouped);
  this.groupEscLevel = grouped;
  
}
DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}

}
