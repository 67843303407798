import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService } from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
declare var moment:any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

constructor(
  private router:Router,
  private route : ActivatedRoute,
  private apiService: ApiService,
  private alertService: AlertService,
  private cookieService:CookieService) { }

user: any = {"agent":"QWERRTTY","agentType":"WEB", "userType":"AMCi"};
userForm: any = {"agentType":"WEB","grant_type" : "password","client_id":"my-trusted-client"};
rememberMe:any = false;
errorMsg : any;
loginPageContentData : any ;
imageFile : any ;
loaderImg : any ;
// notificationMsgId: any;
emailToken : any = localStorage.getItem("EmailCallToken");
resMessage:any = '';
isLoading = false

ngOnInit() {

		$(document).ready(function () {
		  $("body").addClass('login')//.css({"padding-top": "0px"});
	  });
		// localStorage.clear();

		// let user1 = this.cookieService.get('#1bcd3')
		// let pass1 = this.cookieService.get('zxcv')
	  	// console.log(this.decryptPassword(user1), this.decryptPassword(pass1));
		  
		// this.userForm.username = this.decryptPassword(user1);
		// this.userForm.password = this.decryptPassword(pass1);
		// if (this.userForm.username && this.userForm.password) {
		// this.rememberMe = true;
		// }
		let username = window.atob(this.cookieService.get('#1bcd3usnb'))
		let pass = window.atob(this.cookieService.get('zxcv1qw2'))
		let islogin = this.cookieService.get('isLoginUser')
		// console.log(username, pass);
		
		this.userForm.username = username
		this.userForm.password = pass
		if (this.userForm.password && this.userForm.username) {
			this.rememberMe = true;
			if (islogin) {
				// this.login();
			} else {
				
			}
			}

		let permission = localStorage.getItem("Token");

		let userData = localStorage.getItem("clientUser");
		let userId = localStorage.getItem("userId");
    var logdata = JSON.parse(localStorage.getItem("clientUser"));

    if(logdata && permission){
      if(logdata.userType == 'CallCenter'){
        this.router.navigate(['/callcenter/call-center-dashboard']);
      }else if (permission && userData && userId) {
  			this.router.navigate(['/dashboard']);
  			return;
  		}
    }

	let errorMessage = localStorage.getItem('logoutMessage')
	if (errorMessage) {
		Swal({
			position: 'top-end',
			type: 'error',
			title: errorMessage,
			showConfirmButton: false,
			timer: 5000
			})
	}
	setTimeout(() => {
		localStorage.removeItem('logoutMessage')
	}, 5000);

  // Getting Login Data
  this.getLoginData();

}

login(){
	localStorage.removeItem('logoutMessage')
	// this.userForm.password = window.btoa(this.userForm.password);
	// this.apiService.login('userauth/oauth/token', this.userForm).subscribe(		
		this.resMessage = null;
		let obj ={
			username: this.userForm.username.trim(),
			password: window.btoa(this.userForm.password),
			grant_type: this.userForm.grant_type
		}
		this.isLoading = true
	this.apiService.login('userauth/oauth/login', obj).subscribe(
		 (data:any) => {
			 if(data.status == 200){

				let val = JSON.parse(data._body)

				 localStorage.setItem("Token", data["_body"]);
				 let dataObj = JSON.parse(localStorage.getItem('Token')) //data["_body"]
					// this.cookieService.set("refresh_token",dataObj.refresh_token)
					document.cookie = "refresh_token="+dataObj.refresh_token+"; expires=" + new Date(moment().add(30, 'days')).toUTCString();
				//  console.log(data._body, val);
				 if (val.statusCode == 109) {
					this.resMessage = val.responseMessage
				 } else {
					this.getUserInfo();
				 }
			 }else{
				 
			 }
		 },
		 error => {
			this.isLoading = false
	 if(error.error_description){
	   this.errorMsg = error.error_description
	 }else{
	   this.errorMsg = "Invalid Credentials"
	 }
	 this.alertService.errorMessage(error, error.status, this.errorMsg ? this.errorMsg : "Something went wrong, please try again ");
		 }
	 );
 }


 // Getting Logged-In User Data
getLoginData(){
 var url = location.host//this.apiService.appUrl;
   this.apiService.getLoginDatawithoutToken('ParentOrganization/getParentOrganizationByDomain?domain='+url).subscribe(//https://s13.cdnsolutionsgroup.com
     data => {
     if(data.statusCode == 100){
         this.loginPageContentData=data.response.loginPageContent;
         this.imageFile = data.response.appLogoPath;
         let innerLogo = data.response.parentOrgInnerLogoPath;

         // Loader Image for client
         this.loaderImg = data.response.loaderImg;
		//  localStorage.setItem("loaderImg", this.loaderImg);
		 if (this.loaderImg) {
			localStorage.setItem("loaderImg", this.loaderImg);
		}
		if (innerLogo) {
			localStorage.setItem("logoImage", this.apiService.imageBasePath+innerLogo);
		} 
		 console.log(this.imageFile);
		 
         if(document.getElementById('imageData')){
			if (this.imageFile) {
				document.getElementById('imageData').setAttribute( 'src', this.apiService.imageBasePath+this.imageFile );
			}else{
				document.getElementById('imageData').setAttribute( 'src', 'assets/img/default_logo.png' );
			}
         }
     }else{
		localStorage.removeItem("loaderImg");
		document.getElementById('imageData').setAttribute( 'src', 'assets/img/default_logo.png' );
	 }
       },
       error => {
		this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
       }
   );
 }

// Get User info at the time user loged in
	getUserInfo(){
		
	  this.apiService.getAll('user/getUserInfo').subscribe(
      data => {
		this.isLoading = false
        if(data.statusCode == 100){
			if (this.rememberMe) {
				this.cookieService.set('#1bcd3usnb',window.btoa(this.userForm.username.trim()) );
				this.cookieService.set('zxcv1qw2',window.btoa(this.userForm.password));
				document.cookie = "isLoginUser=;"//+data.refresh_token
				// document.cookie = "isLoginUser="+true
				document.cookie = "isLoginUser=true; expires=" + new Date(moment().add(600, 'days')).toUTCString();
			}else{
				this.cookieService.delete('#1bcd3usnb');
				this.cookieService.delete('zxcv1qw2');
				this.cookieService.delete('isLoginUser');
				
			}
        //   if (this.rememberMe) {
        // 	 this.cookieService.set('#1bcd3usnb',this.encryptPassword(this.user.username));
        // 	 this.cookieService.set('zxcv1qw2',this.encryptPassword(this.user.password));
        //   }else{
        // 	 this.cookieService.delete('#1bcd3usnb');
        // 	 this.cookieService.delete('zxcv1qw2');
		//   }
			localStorage.setItem("permission",JSON.stringify(data.response.permissions));		  
            localStorage.setItem("clientUser", JSON.stringify(data.response));
            localStorage.setItem("userId", data.response.userId);
			localStorage.setItem("clientId", data.response.defaultClientId);
			localStorage.setItem("isGlobalUser", data.response.isGlobalUser);
            var logdata = JSON.parse(localStorage.getItem("clientUser"));

            // console.log(logdata.userType)
            // console.log(this.emailToken)

          if(this.emailToken != '0' && this.emailToken != null){
            this.router.navigate(['/callcenter/call-center-instruction',this.emailToken]);
          }else if(logdata.userType == 'CallCenter'){
            this.router.navigate(['/callcenter/call-center-dashboard']);
          } else{
            this.router.navigate(['/dashboard']);
          }



          // Swal({
          //  position: 'top-end',
          //  type: 'success',
          //  title: 'Login Successfully',
          //  showConfirmButton: false,
          //  timer: 1500
          // })
			  }else if(data.statusCode == 105){
			    var msg = data.responseMessage;
				 this.resMessage = data.responseMessage
				 this.logout(msg);
			  }else{
				this.resMessage = data.responseMessage
			 }
		  },
		  error => {
			this.isLoading = false
			this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
		  }
	  );
  }

   logout(msg) {
	localStorage.clear();
	this.apiService.logout('userauth/oauth/revokeToken').subscribe(
		data => {
		   if(data.status == 200) {
			Swal({
				position: 'top-end',
				type: 'error',
				title: msg,
				showConfirmButton: false,
				timer: 1500
			  })
				localStorage.clear();
			} else {
			}
		},
		error => {
			//this.alertService.error(error.message?error.message:'Something went wrong please try again');
		}
		);
}

   encryptPassword(value){
		var encrypt = CryptoJS.AES.encrypt(value, 'LOGIN');
		// console.log(ciphertext);
		return encrypt;
	}

	decryptPassword(value){
		var bytes  = CryptoJS.AES.decrypt(value.toString(), 'LOGIN');
		var text = bytes.toString(CryptoJS.enc.Utf8);
		// console.log(plaintext,"decrypt");
		return text;

	}


}
