import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService} from '../../../../../services/index';
// import * as $ from 'jquery'
import * as _ from "lodash";

import 'datatables.net';
declare var jQuery : any;
declare var $ : any;
import 'datatables.net-bs4';

@Component({
  selector: 'app-tier-migrate',
  templateUrl: './tier-migrate.component.html',
  styleUrls: ['./tier-migrate.component.css']
})
export class TierMigrateComponent implements OnInit {

tierData  : any = null ;
tierhead : any ={};
tierbody : any ={};
dataTable : any;
clientName : any = localStorage.getItem("clientName");

constructor(private router:Router, private apiService: ApiService ,private alertService: AlertService , private chRef: ChangeDetectorRef) { }


ngOnInit() {
if(this.apiService.tiermigration == null){
	this.router.navigate(['/migration-tool']);
}
else {
this.tierData =  this.apiService.tiermigration;
this.tierhead = this.tierData[0]
this.tierData.splice(0, 1);
this.tierbody = this.tierData;
if ( $.fn.DataTable.isDataTable('#data_table') ) {
  $('#data_table').DataTable().destroy();
}
this.chRef.detectChanges();
 const table: any = $('#data_table');

this.dataTable = table.DataTable({
  "order": []
});
// this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 6 }]});

}
}

}
