import { Component, OnInit, ViewChild } from '@angular/core';
declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ApiService, CommonServicesService } from 'src/app/services';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import swal from 'sweetalert2';
import flatpickr from 'flatpickr';

@Component({
  selector: 'app-bill-management',
  templateUrl: './bill-management.component.html',
  styleUrls: ['./bill-management.component.css']
})
export class BillManagementComponent implements OnInit {

  @ViewChild('billTransObjForm') billTransObjForm;

  billList:any = []
  clientFilterId: any;
  subscription: any;
  clientName: string;
  YearArr = [];
  MonthArr = [{value:'01', month: "January"}, {value:'02', month:"February"},{value:'03', month: "March"},{value:'04', month: "April"}, {value:'05', month:"May"},{value:'06', month: "June"} ,{value:'07', month:"July"}, {value:'08', month:"August"}, {value:'09', month:"September"},{value:'10', month: "October"},{value:'11', month:"November"}, {value:'12', month:"December"}];
  filterObj:any = {
    month:'',
    year:'',
    status:''
  };
  billTransObj:any = {mode:''}
  paymentModeList: any[] = [];
  isRequiredMode:any = true;
  totalAmount: any;

  constructor( private route: ActivatedRoute, 
    private apiService: ApiService , private router:Router,
    private alertService: AlertService, private CommonServicesService: CommonServicesService) { }

  ngOnInit() {
    // this.getbillList();
    this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
      .subscribe(
        message => {
          this.clientFilterId = message.id;
          setTimeout(() => {
            this.clientName = localStorage.getItem("clientName");
          }, 1000);
          if (this.clientFilterId) {
            this.getbillList();
            // this.getAllCustomerList();
            // this.getAllSiteList();
          }else{
            this.getbillList();
          }
        },
        error=>{
          console.log(error)
        }
      );
      for (let index = 0; index < 5; index++) {
        let y = new Date().getFullYear();
        this.YearArr.push(y-index);
      }
      this.getPaymentModeList();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    
  }

  ngAfterViewInit(): void {
    let self = this
    flatpickr('#transaction_date',{
      enableTime: false,
      enableSeconds : false,
      // dateFormat: "d-m-Y H:i:S",
      dateFormat: "Y-m-d",
      defaultDate:new Date(), 
      time_24hr : false,   
      onChange: function(selectedDates, dateStr, instance) {
        self.billTransObj.transaction_date = selectedDates;
      },      
    });
  }

  resetSearch(){
    this.filterObj = {
      month:'',
      year:'',
      status:''
    };
    this.getbillList()
  }

  getbillList(){
    if ($.fn.DataTable.isDataTable('#plan-table-list')){
      $('#plan-table-list').DataTable({"order": [],paging:false, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [/* 9,10 */] }]}).destroy();
    };
    let URL = 'billing/bills?'
    let body:any = {}
    if (this.clientFilterId && this.clientFilterId !='0') {
      body.clientId = this.clientFilterId
      URL = URL+'clientId='+this.clientFilterId+'&'
    }
    if (this.filterObj.month && this.filterObj.year) {
      var firstDay = new Date(this.filterObj.year, this.filterObj.month, 1);
      var lastDay = new Date(this.filterObj.year, this.filterObj.month + 1, 0);
      const startOfMonth = moment(this.filterObj.month+"-01-"+this.filterObj.year, "M-DD-YYYY").startOf('month').format('YYYY-MM-DD');
      const endOfMonth   = moment(this.filterObj.month+"01-"+this.filterObj.year, "M-DD-YYYY").endOf('month').format('YYYY-MM-DD');
      body.startDate = startOfMonth
      body.endDate = endOfMonth
      URL = URL+'startDate='+startOfMonth+'&endDate='+endOfMonth+'&'

    }
    if (this.filterObj.status) {
      body.status = this.filterObj.status;
      URL= URL+'status='+this.filterObj.status
    }
    console.log(body);
    // return;
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.billList = data.responseList;

          setTimeout(() => {
            const table: any = $('#plan-table-list');
            let dataTable = table.DataTable({"order": [], paging:true, "searching":true, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [7] }]});
          }, 1000);
        }else{
          this.billList = [];
          setTimeout(() => {
            const table: any = $('#plan-table-list');
            let dataTable = table.DataTable({"order": [],paging:true,searching:true, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [7] }]});
          }, 1000);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
  }

  regenerate(data){
    swal({
      title: 'Are you sure?',
      text: "You want to Re-generate bill.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.apiService.create('billing/billReGenerate' , {id:data.billingId}).subscribe(
          data => {
            if (data.statusCode == 100) {
             this.getbillList();
            } else {
              this.alertService.sweetMessage('error',data.responseMessage); 
              
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }

  EditBillDetail(billing){
    this.totalAmount = billing.total;
    this.billTransObjForm.submitted = false;
    this.billTransObj = {mode:''}
    this.billTransObj.billingId = billing.billingId
  }

  getPaymentModeList(){
    
    this.apiService.getAll('paymentMode/payments').subscribe(
      data => {
        if(data.statusCode == 100){
          this.paymentModeList = data.responseList;

        }else{
          this.paymentModeList = [];
          
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
  }

  paymentModeChange(value){

    console.log(value, this.paymentModeList);
    for (let index = 0; index < this.paymentModeList.length; index++) {
      const element = this.paymentModeList[index];
      if (element.paymentModeId == value) {
        this.isRequiredMode = element.paymentModeKey == 'cash' ? false : true;
        break;
      }
      
    }
    
  }

  saveBillObj(){
    console.log(this.billTransObj, this.billTransObjForm.valid);
    if (!this.billTransObjForm.valid) {
      return
    }
    let obj = {
      "billingId" : this.billTransObj.billingId,
      "referenceNumber":this.billTransObj.transaction_ref,
      "referenceDate" : this.billTransObj.transaction_date,
       "paymentMode" : {
         "paymentModeId" : this.billTransObj.mode
       }
    }
    this.apiService.create('invoice/invoiceGenerate' , obj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.router.navigate(['invoice',data.response])
          this.alertService.sweetMessage('success',data.responseMessage); 

    $('#showUsersList').modal('hide')
    //data.response  => id
        } else {
          this.alertService.sweetMessage('error',data.responseMessage); 
          
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
    
  }
}
