import { Component, OnInit ,ChangeDetectorRef,ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';

import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import * as _ from "lodash";
import { jqxTreeComponent } from '../../../jqwidgets-ts/angular_jqxtree';
import { jqxDropDownButtonComponent } from '../../../jqwidgets-ts/angular_jqxdropdownbutton'

@Component({
  selector: 'app-client-user-add',
  templateUrl: './client-user-add.component.html',
  styleUrls: ['./client-user-add.component.css']
})
export class ClientUserAddComponent implements OnInit {

  @ViewChild('myTree') myTree: jqxTreeComponent;
  @ViewChild('myDropDownButton') myDropDownButton: jqxDropDownButtonComponent;
  @ViewChild('imageUrlName') imageUrlName: ElementRef;
  @ViewChild('addUserForm') addUserForm: any;

userData: any = JSON.parse(localStorage.getItem("clientUser"));
loginUserType: any = this.userData ?  this.userData.isGlobalUser : '';
// clientId: any = localStorage.getItem("clientId");
clientId: any = localStorage.getItem("clientdetailId");
userId: any = localStorage.getItem("userId");
customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
tiersId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
newUserId:number = 0;
roleList: any = [];
roles : any = [];
customerList:any=[];
tierList:any=[];
tierListData: any = [];
checkCustomerArray:any=[];
commMode:number=0;
dataAdapter: any = [];
source: any = {};
dropdownSettings:any={};
data: any = [];
tierArray : any=[];
selectedItems:any=[];
customerArray:any=[];
records: any = [];
checkTreeArray: any = [];
disableTreeArray:any=[];
roleIds: any = [];
clientName : any = localStorage.getItem("clientName");
CommunicationMode = false;
// showGlobalUser = false;
// user: any = {
//   "title":"",
//   "firstName":"",
//   "middleName":"",
//   "lastName":"",
//   "phoneCarrier":"Verizon",
//   "note":"",
//   "login":"",
//   // "password":"",
//   // "conpassword":"",
//   "phonePin":"",
//   // "isActive":true,
//   // "isAppUser":false,
//   "communicationMode":"",
//   "userType":"Client",
//   "client":{
//     "clientId":null
//   },
//   roles:[],
//   // "emailId":"",
//   "phoneNumber":"",
//   isGlobalUser:false,
//   "customers":[]
// };

user: any = {
  "title":"",
  "firstName":"",
  "middleName":"",
  "secondaryPhoneNumber":"",
  "lastName":"",
  "phoneCarrier":"Verizon",
  "secondaryPhoneCarrier":"",
  "note":"",
  "login":"",
  "password":"",
  "phonePin":"",
  "isActive":false,
  "isAppUser":false,
  "isGlobalUser" : false,
  "communicationMode":"",
  "userType":"Client",
  "client":{ "clientId":this.clientId },
  "emailId":"",
  "phoneNumber":"",
  "customers":[],
  "file" : '',
  // 'newPassword' : '',
  // 'cnfrmPassword' : '',
  // 'OldNewpassword' : ''
};

comm: any = {"Push":false,"Email":false,"SMS":false,"IVRS":false,"Call":false};
clientDataList:any = [];
clientData:any = null;
phoneCarrierList : any = [];
clientemaildomain = ''
RoleRequired = false
imageUrl :any =  "assets/img/upload-img.png";
imageName:any;
imageError = "";
imageTitle = "";
defaultSelect   : any = 'client';
ifClient      : any = true;
customerValue : any;
isAllCust     : any;
isAllCustomerSelected : any;
isCusDisable  : any = false;
isTreeDisable : any = false;
isCustTreeDisable = false
isAllCustTier : any = false;
SelectCustomerError = false
showGlobalUser = false;
FormObject    : any = new FormData();
isClientUserGlobalUser : boolean = false;
globalUserDisable : boolean = false;
checkEmailDomain:boolean = false;
clientDomain        : any = null;
  breadcrumbObj: any;



constructor(private router:Router, private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService ,private _location: Location) { }

clientdetailId : any = localStorage.getItem("clientdetailId");


ngOnInit() {
  // this.getRoleList();
  this.getCustomerList();
  this.getClientById()
  this.getTierList();
  this.getClientDrodownlist();
  this.getPhoneCarrierList();
  this.getClientDomain();
  // this.getRoleList('Client');
  this.route.params.subscribe(params => {
     if(params['id']){
      this.newUserId = params['id'];
      this.initialiseState();
    }
  });
  this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
  if(this.newUserId){
    // this.getUserById();
  }else{
    this.ClientORCustomer(this.defaultSelect)  
  }

  this.dropdownSettings = {
      singleSelection: false,
      idField: 'customerId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
}

initialiseState(){
  this.getUserById();
}

IsGlobalChange(e){
  this.checkTreeArray = [];
  this.checkCustomerArray = []
  if(e.target.checked){
    let objArr = []
    this.customerList.map((obj,index)=>{
      let obj12 = {
        'customerId':obj.customerId,
        'name':obj.name
      }
      objArr.push(obj12)
    })

    this.selectedItems = objArr;
    // console.log(this.tierListData);
    
    for (const iterator of this.tierListData) {
      let checkedItem = document.getElementById(iterator.tierId);
          this.myTree.expandItem(checkedItem);
          this.myTree.checkItem(checkedItem,true);

          let index = this.checkTreeArray.indexOf(iterator.tierId.toString())
          // console.log(index);
          if (index==-1) {
            this.checkTreeArray.push(iterator.tierId.toString())
          }          
    }

    // Object.keys(this.tierListData).forEach(key=> {
    //   console.log(key);
      
    //   this.checkTreeArray.push(this.clientData[key].tierId)
    //   let checkedItem = document.getElementById(this.tierListData[key].tierId);
    //       this.myTree.expandItem(checkedItem);
    //       this.myTree.checkItem(checkedItem,true);
    // });

    // this.ClientIdArr = [];
    // let allClientSelected = [];
    // Object.keys(this.clientDataList).forEach(key=> {
    //   this.ClientIdArr.push(this.clientDataList[key]['clientId'].toString());
    //   allClientSelected.push({clientId:this.clientDataList[key]['clientId'],name:this.clientDataList[key]['name']})
    // });
    // this.user.clients = [];
    // this.user.clients = this.ClientIdArr;
    // this.clientIds = allClientSelected;
}else{
  this.selectedItems = []
  
  this.checkTreeArray = []//.push(this.clientData.tierId)
  /* Object.keys(this.tierListData).forEach(key=> {
    let checkedItem = document.getElementById(this.tierListData[key].tierId);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem,false);
  }); */
  for (const iterator of this.tierListData) {
       
    // this.checkTreeArray.push(iterator.tierId)
    let checkedItem = document.getElementById(iterator.tierId);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem,false);
  }
  this.checkCustomerArray = []
  
}
}

/* Get Client Domain */
getClientDomain(){
  this.checkEmailDomain = false;
  this.apiService.getAll('client/getEmailDomainbyClient?clientId='+this.clientId).subscribe(
  data => {
    this.clientDomain = data.response ? data.response.emailDomain : '';
    if((this.clientDomain==''||this.clientDomain==null)){
      this.checkEmailDomain = false;
    }else if((this.clientDomain!='' && this.clientDomain!=null)){
      this.checkEmailDomain = true;
    }    
    this.changeEmail();
  },
  error => {
    this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
  }
  );
}

getUserName(clientData){
  for (let index = 0; index < this.clientDataList.length; index++) {
    const element = this.clientDataList[index];
      if (clientData == element.clientId) {
          return element.description
      }
  }
}

/* Handle tier tree chacked unchecked Event */
CheckChange(event){
  if(event.args.checked == true){
    var exist = this.checkTreeArray.indexOf(event.args.element.id);
    if(exist == '-1'){
      this.checkTreeArray.push(event.args.element.id);
    } 
    /* else if(exist != '-1') {
      this.checkTreeArray.splice(exist,1);
    } */
  }else if(event.args.checked == false){
      var exist = this.checkTreeArray.indexOf(event.args.element.id);
      if(exist != '-1'){
        this.checkTreeArray.splice(exist,1);
      }
  }
  else if(event.args.checked == null){
    var exist = this.checkTreeArray.indexOf(event.args.element.id);
    if(exist != '-1'){
      this.checkTreeArray.splice(exist,1);
    }
  }
  if(this.tierListData.length == this.checkTreeArray.length) {
    this.isAllCustTier = true;
  } else {
    this.isAllCustTier = false;    
  }

}

/* On Item Select Customer */
onItemSelect(item: any) {
  this.isAllCust = false;
  var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
  if(exist == '-1'){
    this.checkCustomerArray.push(item.customerId.toString());
  }
}

/* On Item DeSelect Customer */
onItemDeselect(item: any){
  var exist = this.checkCustomerArray.indexOf(item.customerId.toString());
  if(exist != '-1'){
    this.checkCustomerArray.splice(exist,1);
  }
  this.user.customers = this.checkCustomerArray;
}

/* On All Select Customer */
onSelectAll(items: any) {
  this.isAllCust = true;
  this.checkCustomerArray = [];
  Object.keys(items).forEach(key=> {
    this.checkCustomerArray.push(items[key]['customerId'].toString());
  });
  this.user.customers        = this.checkCustomerArray;
  this.isAllCustomerSelected = true;
}

/* On All DeSelect Customer */
onDeSelectAll(){
  this.isAllCust = false;
  this.checkCustomerArray = [];
  this.user.customers     = this.checkCustomerArray;
}

/* Create Tier array */
createTierArray(){
  this.tierArray = []
  // console.log(this.checkTreeArray);
  Object.keys(this.checkTreeArray).forEach(key=> {
    if(this.checkTreeArray[key] != 0){
        this.tierArray.push({"tierId":this.checkTreeArray[key]});
      }
    });
  this.user.tiers = this.tierArray
  this.FormObject.append("tiers" , JSON.stringify(this.tierArray));
};

/* Create Tier array */
createCustomerArray(){
  this.customerArray = []
  Object.keys(this.selectedItems).forEach(key=> {
    if(this.selectedItems[key] != 0){
        this.customerArray.push({"customerId":this.selectedItems[key].customerId});
      }
    });
  this.user.customers = this.customerArray;
  this.FormObject.append("customers" , JSON.stringify(this.customerArray));
};

/* check tree checkbox */
checkedCheckboxOnLoad(){
   Object.keys(this.checkTreeArray).forEach(key=> {
    let checkedItem = document.getElementById(this.checkTreeArray[key]);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem,true);
  });
}

// checkItemCommunication(){
//   this.commMode = this.user.communicationMode;
//   var number = this.user.communicationMode;
//   if((number - 16) >= 0){
//     number = number - 16;
//     this.comm.Call = true;

//   }
//   if((number - 8) >= 0){
//     number = number - 8;
//     this.comm.IVRS = true;
//   }
//   if((number - 4) >= 0){
//     number = number - 4;
//     this.comm.SMS = true;
//   }
//   if((number - 2) >= 0){
//     number = number - 2;
//     this.comm.Email = true;
//   }
//   if((number - 1) >= 0){
//     number = number - 1;
//     this.comm.Push = true;
//   }
// }

// /* Create communication logic */
// getCommMode(e){
//   if(e.target.name == 'Push'){
//     if(e.target.checked){
//       this.commMode = this.commMode + 1;
//     }else{
//       this.commMode = this.commMode - 1;
//     }
//   }else if(e.target.name == 'Email'){
//     if(e.target.checked){
//       this.commMode = this.commMode + 2;
//     }else{
//       this.commMode = this.commMode - 2;
//     }
//   }else if(e.target.name == 'SMS'){
//     if(e.target.checked){
//       this.commMode = this.commMode + 4;
//     }else{
//       this.commMode = this.commMode - 4;
//     }
//   }
//   else if(e.target.name == 'IVRS'){
//     if(e.target.checked){
//       this.commMode = this.commMode + 8;
//     }else{
//       this.commMode = this.commMode - 8;
//     }
//   }else{
//     if(e.target.checked){
//       this.commMode = this.commMode + 16;
//     }else{
//       this.commMode = this.commMode - 16;
//     }
//   }
//   this.user.communicationMode = this.commMode;
// }

// checkItemCommunication(){
//   this.commMode = this.user.communicationMode;
//   var number = this.user.communicationMode;
//   // if((number - 16) >= 0){
//   //   number = number - 16;
//   //   this.comm.Call = true;

//   // }
//   if((number - 8) >= 0){
//     number = number - 8;
//     this.comm.IVRS = true;
//   }
//   if((number - 4) >= 0){
//     number = number - 4;
//     this.comm.Call = true;
//   }
//   if((number - 2) >= 0){
//     number = number - 2;
//     this.comm.SMS = true;
//   }
//   if((number - 1) >= 0){
//     number = number - 1;
//     this.comm.Email = true;
//   }
// }

checkItemCommunication(){
  this.commMode = this.user.communicationMode;
  var number = this.user.communicationMode;
   if((number - 8) >= 0){
    number = number - 8;
    this.comm.Call = true;
  }
  if((number - 4) >= 0){
    number = number - 4;
    this.comm.IVRS = true;
  }
  if((number - 2) >= 0){
    number = number - 2;
    this.comm.SMS = true;
  }
  if((number - 1) >= 0){
    number = number - 1;
    this.comm.Email = true;
  }
}

/* Create communication logic */
getCommMode(e){
  if(e.target.name == 'emailId'){
    if(e.target.checked){
      this.commMode = this.commMode + 1;
    }else{
      this.commMode = this.commMode - 1;
    }
  }else if(e.target.name == 'SMS'){
    if(e.target.checked){
      this.commMode = this.commMode + 2;
    }else{
      this.commMode = this.commMode - 2;
    }
  }else if(e.target.name == 'IVRS'){
    if(e.target.checked){
      this.commMode = this.commMode + 4;
    }else{
      this.commMode = this.commMode - 4;
    }
  }else if(e.target.name == 'Call'){
    if(e.target.checked){
      this.commMode = this.commMode + 8;
    }else{
      this.commMode = this.commMode - 8;
    }
  }
  this.user.communicationMode = this.commMode;
  this.FormObject.append("communicationMode" , this.commMode);
}

/* Get client by Id*/
getClientById(){
  this.apiService.getAll('client/getClientById?clientId='+this.clientdetailId).subscribe(
   data => {
       if(data.statusCode == 100){
        this.clientemaildomain = data.response.emailDomain;
        }else{
          // this.alertService.sweetMessage('error',data.responseMessage);
         }
      },
     error =>
      { /* this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); */}
    );
}

changeEmail(){
  if(this.checkEmailDomain===true){
    setTimeout(() => {
      let domain = this.user.login ? ( this.user.login.indexOf('@')>=0 ? this.user.login.split('@') : this.user.login.split('@')) : this.user.login.split('@')
      if (domain.length > 0) {
        if (domain[1] && domain[1] == this.clientDomain) {
          this.showGlobalUser = true;
        }else{
          this.showGlobalUser = false;
        }
      }else{
        this.showGlobalUser = false;
      }
    }, 200);
  }
}

/* Get Phone Carrier List */
getPhoneCarrierList(){
   this.apiService.getAll('user/getPhoneCarrierList').subscribe(
    data => {
       if(data.statusCode == 100){
          this.phoneCarrierList = data.responseList;
          this.phoneCarrierList.forEach(key=> {
            if(key == 'Verizon') {
              setTimeout(function(){
                $('.'+key).attr('selected', 'true');  
              }, 500);              
            }
          });
        }else{
          this.phoneCarrierList = [];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
     }
  );
}

/* Create role array */
updateRoleOptions(rlist, event) {
  if(event.target.checked == true){
    this.roles.push({"roleId":rlist.roleId});
  }else{
       for(var i=0 ; i < this.roles.length; i++) {
    if(this.roles[i].roleId == rlist.roleId){
       this.roles.splice(i,1);
      }
    }
  }
  this.RoleRequired = false;
  this.user.roles = this.roles;
}

/*treeItemDisable(){
  Object.keys(this.disableTreeArray).forEach(key=> {
    let checkedItem = document.getElementById(this.disableTreeArray[key]);
        this.myTree.expandItem(checkedItem);
        this.myTree.disableItem(checkedItem);
  });
}
*/
 /* Get tier list */
getTierList(){
  this.apiService.getAll('tier/getAllTierByUser?clientId='+this.clientId+'&userId='+this.userId).subscribe(
    data => {
        if(data.statusCode == 100){
            this.tierListData = data.responseList;
            this.data = this.tierListData;
           /* Object.keys(this.data).forEach(key=> {
              if(this.data[key]['flag'] == false){
                this.disableTreeArray.push(this.data[key]['tierId']);
              }
            });*/
            this.source = {
                datatype: 'json',
                datafields: [
                    { name: 'tierId' },
                    { name: 'parentTierId' },
                    { name: 'tierName' },
                ],
                id: 'tierId',
                localdata: this.data
            };
            this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
            this.records = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' },{ name: 'tierId', map: 'id'}]);
             /* var self = this;
              setTimeout(function(){
                self.treeItemDisable();
              },1000);*/
            }else{
            this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }

    getClientDrodownlist(){
      this.apiService.getAll('client/getClientDropDownList').subscribe(
        data => {
            if(data.statusCode == 100){
                this.clientDataList = data.responseList;
                this.clientData = this.clientdetailId;
            }else{
                this.alertService.sweetMessage('error',data.responseMessage);
              }
                },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
    }

/* Add user */
// addUser(){
//   this.createTierArray();
//   this.createCustomerArray();
//   this.CommunicationMode = false
//   if (this.user.communicationMode) {
//     this.CommunicationMode = false;
//   }else{
//     this.CommunicationMode = true
//   }
//   if (this.user.roles.length==0) {
//     this.RoleRequired = true;
//     return
//   }else{
//     this.RoleRequired = false
//   }
  
//     if (this.addUserForm.valid /* && this.user.conpassword == this.user.password */) {
//       if (this.user.communicationMode ) {
//         // console.log(this.user)
//           // if (this.user.tiers.length>0) {
//             let phoneCarrierVal = $("#phoneCarrier option:selected").val();
//             this.user.phoneCarrier = phoneCarrierVal;
//             if(this.newUserId == 0){
//               delete this.user.conpassword
//               this.user.client = {clientId:this.clientData};  
//               // console.log(JSON.stringify(this.user),this.addUserForm.valid);            
//               this.apiService.create('user/createClientUser', this.user).subscribe(
//                 data => {
//                   if(data.statusCode == 100){
//                     this.alertService.sweetMessage('success','User added Successful');
//                      // this.router.navigate(['/user-management']);
//                      this._location.back();
//                   }else{
//                       this.alertService.sweetMessage('error',data.responseMessage);
//                       this.tierArray = [];
//                       this.customerArray = [];
//                   }
//                 },
//                 error => {
//                     this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
//                 }
//               );
//             }else{
//                 delete this.user.conpassword
//                 this.user.clients = [{clientId:this.clientData}]
//                 this.apiService.update('user/updateClientUser', this.user ).subscribe(
//                     data => {
//                       if(data.statusCode == 100){
//                           this.alertService.sweetMessage('success','User update successful');
//                         // this.router.navigate(['/user-management']);
//                         this._location.back();
//                       }else{
//                         this.alertService.sweetMessage('error',data.responseMessage);
//                       }
//                     },
//                     error => {
//                       this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
//                     }
//                   );
//             }
//           } else {
//             this.CommunicationMode = true

//             // this.alertService.sweetMessage('error','please select communication mode.');
//             this.tierArray = [];
//             this.customerArray = [];
//           }
//     } else {
//       this.tierArray = [];
//       this.customerArray = [];
//       }
//   }

/*****************
  Function to Add and update user
******************/
addUser(data){
  this.SelectCustomerError = false
  console.log(this.user);
  if(!data.form.valid && this.user.phoneNumber == this.user.secondaryPhoneNumber) {
    document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });    
  } 
  if(this.user.phoneNumber != this.user.secondaryPhoneNumber) {

    if(this.user.userType == 'Client' || this.user.userType == 'client'){
      if (this.user.isGlobalUser == false) {
        this.createTierArray();
        this.createCustomerArray();
      }
      if (this.user.isGlobalUser) {      
        delete this.user.customers;
        delete this.user.tiers;
      }
      delete this.user.customer;
      if(this.roles.length == 0){
        //this.alertService.sweetMessage('error','please select at least one Role.');
        $('#role_err').css('display', 'inline-block');
        document.getElementById("main-content-row").scrollIntoView({ behavior: 'smooth',  block: 'start', inline: 'start' });
        return;
      } else {
        $('#role_err').css('display', 'none');
      }
    let phoneCarrierVal = $("#phoneCarrier option:selected").val();
    this.user.phoneCarrier = phoneCarrierVal;
      // return
    if(this.newUserId == 0){
      this.apiService.create('user/createClientUser', this.user).subscribe(
        data => {
          if(data.statusCode == 100){
              if(this.imageName){
              this.imageSave(data.user.userId)
              }
              this.alertService.sweetMessage('success','User added successfully');
              this.router.navigate(['/users/'+this.clientData],{queryParams:{source:this.breadcrumbObj}});
          }else{
              this.tierArray     = [];
              this.customerArray = [];
              this.alertService.sweetMessage('error',data.responseMessage);
              // $('#err_msg').html(data.responseMessage);
              // $('#err_msg').css('display', 'block');
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    }else{
      this.apiService.update('user/updateClientUser', this.user).subscribe(
          data => {
            if(data.statusCode == 100){
              // this.imageSave(this.newUserId);
              if(this.imageName){
              this.imageSave(this.newUserId)
              }
              this.alertService.sweetMessage('success', 'User updated successfully');
              this.router.navigate(['/users/'+this.clientData],{queryParams:{source:this.breadcrumbObj}});
            }else{
              this.tierArray     = [];
              this.customerArray = [];
              this.alertService.sweetMessage('error', data.responseMessage);
              // $('#err_msg').html(data.responseMessage);
              // $('#err_msg').css('display', 'block');
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }

    }else{
      /************************** 
        Add user if USER SELECT the Customer from Drop Down 
      **************************************/
    console.log(this.user);
    
        this.createTierArray();
        var custArray = []
        // custArray.push({"customerId":this.customerValue})
        // this.user.customers = custArray;
        if (!this.customerValue || this.roles.length == 0) {
          if (!this.customerValue) {
            this.SelectCustomerError = true
          }
          if(this.roles.length == 0){
            //this.alertService.sweetMessage('error','please select at least one Role.');
            $('#role_err').css('display', 'inline-block');
            return;
          } else {
            $('#role_err').css('display', 'none');
          }
          return
        }
        this.user.customer = {"customerId":this.customerValue};
        delete this.user.customers;
        this.apiService.create('user/createCustomerUser', this.user).subscribe(
          data => {
            if(data.statusCode == 100){
                // this.imageSave(data.user)
                if(this.imageName){
                  this.imageSave(data.user)
                }
                this.alertService.sweetMessage('success','User added Successfully');
                this.router.navigate(['/users/'+this.clientData]);
            }
            else{ 
              this.alertService.sweetMessage('error',data.responseMessage); 
            }
          },
          error => { 
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
        );
    }
  }
}


/* Get Role list */
getRoleList(type){
  // console.log(type)
  // var URL = 'role/getAllRoleList';
  // var URL = 'role/getAllRoleByType?userType='+type+'&clientId='+this.clientId;
  var URL = 'role/getRoleByUser?clientId='+this.clientId;


  // https://s13.cdnsolutionsgroup.com/admin/api/v1/role/getRoleByUser?clientId=104 
  // var URL = 'role/getRoleListByClient?clientId='+this.clientId+'&userId='+this.userId;
  // if(this.customerId != null && this.customerId !=''){
  //   var customerString     = '&customerId='+this.customerId.toString();
  //   var URL = URL.concat(customerString);
  // }
  // if(this.tiersId != null && this.tiersId !=''){
  //   var tierString     = '&tierId='+this.tiersId.toString();
  //   var URL = URL.concat(tierString);
  // }
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.roleList = data.responseList;
        }else{
          this.roleList = [];
          this.alertService.sweetMessage('error',data.responseMessage);
        }
      },
      error => {
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }


/* Get Role list */
getCustomerList(){
  if(this.clientId == "null"){
      this.clientId = this.clientdetailId;
  }else{
    this.clientId = this.clientId;
  }
  var URL = 'customer/getCustomerListByClient?clientId='+this.clientId+'&userId='+this.userId;
   if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tiersId != null && this.tiersId !=''){
    var tierString     = '&tierId='+this.tiersId.toString();
    var URL = URL.concat(tierString);
  }
  this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.customerList = data.responseList;
        }else{
          this.customerList = [];
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 3500
            })
         }
      },
      error => {
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
  );
}

getUserById(){
  this.apiService.getAll('user/getUserByUserId?userId='+this.newUserId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.user = data.user;
          this.clientData = this.user.client.clientId;
          this.isClientUserGlobalUser = this.user ? this.user.isGlobalUser : '';

          // check for non global user can't edit a global user
          if((!this.loginUserType) && (this.isClientUserGlobalUser)){
            this.globalUserDisable = true;
          }
          if (this.user.userType) {
            this.getRoleList(this.user.userType);
          }
          if (this.user.title===null) {
            this.user.title="";
          }

          if(this.user.imageLocation != null){
            this.imageUrl = this.apiService.imageBasePath +this.user.imageLocation;
            let imageArr = this.user.imageLocation.split('/');
            this.imageTitle = imageArr[1];
          }

          if(this.user.secondaryPhoneCarrier===null){
            this.user.secondaryPhoneCarrier="";
          }

          if(this.user.phoneCarrier===null){
            this.user.phoneCarrier="Verizon";
          }

          this.checkItemCommunication();
          this.checkTreeArray = [];
          if(this.user.tiers.length!=0){
            Object.keys(this.user.tiers).forEach(key=> {
              this.checkTreeArray.push(this.user.tiers[key]['tierId'].toString());
            });
          }
          var that = this;
          setTimeout(function(){
            that.checkedCheckboxOnLoad();
          },2000);
          
          this.selectedItems = this.user.customers;
          
          if(this.user.customers.length){
              Object.keys(this.user.customers).forEach(key=> {
                    this.checkCustomerArray.push(this.user.customers[key]['customerId'].toString());
                  });
              if(this.checkCustomerArray.length===this.user.customers.length){
                this.isAllCust = true;
                this.isCusDisable = true;
              }
          }
          
          this.roles = data.user.roles;
          this.roleIds = _.map(this.user.roles, 'roleId');
          var self = this;
              // console.log(this.roleIds);
              _.map(this.user.roleIds, function(value, key){
                  self.user.roleIds.push({roleId:value.id});
                });
                this.changeEmail();
                let domain = this.user.login ? ( this.user.login.indexOf('@')>=0 ? this.user.login.split('@') : this.user.login.split('@')) : this.user.login.split('@')

            if (domain.length > 0) {
              if (domain[1] && domain[1] == this.clientDomain) {
                this.showGlobalUser = true;
              }else{
                this.showGlobalUser = false;
              }
            }else{
              this.showGlobalUser = false;
            }
            if (this.user.isGlobalUser) {
              setTimeout(() => {
                this.isGlobalUserChange({target:{checked:true}})
              }, 500);
            }

        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 3500
            })
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
  }

  // Check if its Customer or Client
  ClientORCustomer(value){
    if(value == 'client'){
      this.ifClient = true;
      this.user.userType = 'Client';
      setTimeout(()=>{
        this.isAllCustomerSelected = true;
        this.selectedItems      = this.customerList;
        this.onSelectAll(this.customerList)
        var obj = {
          "target" : 
            {'checked' : true}
        }
        this.isCusDisable   = true;  
        this.isAllCust      = true;
      },2000)

      for(var i=0 ; i < this.roles.length; i++) {
        this.roles.splice(i,1);
      }
      this.getTierList();
      this.getRoleList(value);

    }else{
      this.tierListData = []
      this.ifClient = false;
      this.user.userType = 'Customer';
      this.customerValue = '';
      this.checkCustomerArray = [];
      this.user.customers = this.checkCustomerArray;
      
      for(var i=0 ; i < this.roles.length; i++) {
         this.roles.splice(i,1);
      }
      this.isTreeDisable = true
      this.getCustomerRoleValue();
    }
  }

  getCustomerRoleValue(){
    this.apiService.getAll('role/getRoleByUser?isCustomerRole=true&clientId='+this.clientId)
    .subscribe(
      data => {
        if (data.statusCode == 100) 
          { this.roleList = data.responseList; }
        else
          { this.roleList = []; }
      },
      error => 
        { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
  }

/***************************
 Upload Image On Change
**************************/
onFileChanged(event) {
  this.imageTitle = "";
  this.imageError = "";
  const file = event.target.files;
  const fileType = file[0]['type'];
  const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
  // invalid file type code goes here.
  if (!validImageTypes.includes(fileType)) {
      this.imageUrl = "assets/img/upload-img.png";
      this.imageName = null;
      this.imageTitle = "";
      this.imageError = "Please select valid image type such as jpg, jpeg, png.";return;
  }

  if(this.imageError==""){
    var reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (_event: any) => {
     var img = new Image();
     img.src = _event.target.result;
     img.onload = () => {
      if( img.width <= 300 && img.height <= 300 ) {
        this.imageUrl = reader.result as string;
        this.imageName = file;
        this.imageTitle = file[0]['name'];
      }
      else {
        this.imageUrl = "assets/img/upload-img.png";
        this.imageName = null;
        this.imageTitle = "";
        this.imageError = "Image Size should be less than 300X300"
        return;
      }
     };
    }
  }
}

// function to save the image
imageSave(newUserId) {
  if(this.imageError==""){
    const uploadData = new FormData();
    if(this.imageName.length){
      uploadData.append('file', this.imageName[0], this.imageName[0].name);
      this.apiService.create('uploadImage/uploadImage?section=1&sectionId=' + newUserId, uploadData).subscribe(
          data => {
              if (data.statusCode == 100) {
              } else {
                  this.alertService.sweetMessage('error',data.responseMessage);
              }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
    }
  }
}

// Select All Customers
selectAllCustomers(e){
  if(e.target.checked == true){
    this.isAllCustomerSelected = true;
    this.selectedItems  = this.customerList;
    this.isCusDisable   = true;  
  }else{
    this.isAllCustomerSelected = false;
    this.selectedItems  = [];
    this.isCusDisable   = false;  
  }
}

getCustomerTierValue(custId){
  this.apiService.getAll('tier/getAllTierByCustomer?customerId='+custId+'&clientId='+this.clientId)
  .subscribe(
    data => {
      this.disableTreeArray = []
      if (data.statusCode == 100) {
        this.tierListData = data.responseList ? data.responseList :[];
        this.data = this.tierListData;
        let arr = []
        Object.keys(this.data).forEach(key=> {
          if(this.data[key]['flag'] == false){
            this.disableTreeArray.push(this.data[key]['tierId']);
          }else{
            arr.push(this.data[key]['tierId'].toString());
          }
        });
        this.checkTreeArray = arr
        this.checkedCheckboxOnLoad();
        var obj = {
          "target" : 
            {'checked' : true}
        }

        this.source = {
          datatype: 'json',
          datafields: [ { name: 'tierId' },
            { name: 'parentTierId' },
            { name: 'tierName' }, ],
          id: 'tierId',
          localdata: this.data
        };
        this.isCustTreeDisable = false;
        
        this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
        this.records     = this.dataAdapter.getRecordsHierarchy('tierId', 'parentTierId', 'items', [{ name: 'tierName', map: 'label' },{ name: 'tierId', map: 'id'}]);
        var self = this;
        setTimeout(function() { 
          self.treeItemDisable();
          self.checkedCheckboxOnLoad();
          self.selectAllCustTiers(obj)
        },1000);
        this.isTreeDisable = false;
        setTimeout(() => {
          this.isTreeDisable = true;
          this.isCustTreeDisable = true;
        }, 2000);

        this.isGlobalUserChange({target:{checked:false}})
       this.myTree.refresh();

      }else{ this.alertService.sweetMessage('error',data.responseMessage); }
    },
    error => 
      { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
  );
}

// Handel Global User
isGlobalUserChange(event){
  if(event.target.checked == true){
    if (this.defaultSelect == 'client') {
      this.isTreeDisable = true;
      this.isCusDisable = true;
      this.selectedItems = [];
      this.isAllCustTier = true;
      this.isAllCust = true;

      let customerUser = [];
      Object.keys(this.customerList).forEach(key=> {
        this.checkCustomerArray.push(this.customerList[key]['customerId'].toString());
        customerUser.push({customerId:this.customerList[key]['customerId'],name:this.customerList[key]['name']})
      });
      this.user.customers = this.checkCustomerArray;
      this.selectedItems = customerUser;

      Object.keys(this.tierListData).forEach(key=> {
        let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem,true);
      });

      setTimeout(function(){
        $('#customer .multiselect-dropdown .dropdown-list .multiselect-item-checkbox input').attr('disabled', 'disabled');
      }, 1000);
    }
    else{
      Object.keys(this.tierListData).forEach(key=> {
        let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem,true);
      });
    }
  }
  else
  {
    if (this.defaultSelect == 'client') {
        Object.keys(this.tierListData).forEach(key=> {
          let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
          this.myTree.expandItem(checkedItem);
          this.myTree.checkItem(checkedItem,false);
        });
        this.isAllCustTier = false;
        this.user.customers = this.checkCustomerArray = [];
        this.isTreeDisable = false;
    }else{
      Object.keys(this.tierListData).forEach(key=> {
        let checkedItem = document.getElementById(this.tierListData[key]['tierId']);
        this.myTree.expandItem(checkedItem);
        this.myTree.checkItem(checkedItem,false);
      });
      this.isAllCustTier = false;
      this.user.customers = this.checkCustomerArray = [];
      this.isTreeDisable = false;
    }
  }
}

treeItemDisable(){
  if(this.defaultSelect == 'customer')
  {
      // this.myTree.disableItem(checkedItem);
  }
  Object.keys(this.disableTreeArray).forEach(key=> {
    let checkedItem = document.getElementById(this.disableTreeArray[key]);    
        this.myTree.expandItem(checkedItem);
        // If permission is not there for updating tier than disable the tier item
        if(this.defaultSelect == 'customer')
        {
          this.myTree.disableItem(checkedItem);
        }
  });
}

// Select All Tiers
selectAllCustTiers(e){
  if(e.target.checked == true){
    // this.isAllCustTierSelected = true;
      this.checkTreeArray = [];
      Object.keys(this.data).forEach(key=> {
        // if (this.data[key].flag) {
          this.checkTreeArray.push(this.data[key]['tierId'].toString());
        // }
      });
      this.checkedCheckboxOnLoad()
      this.isTreeDisable   = true;  
  }else{
    // this.isAllCustomerSelected = false;
    this.isTreeDisable   = false;  
    // this.checkTreeArray = [];
    this.checkedCheckboxOnLoad()
     this.data.map((obj)=>{
      let checkedItem = document.getElementById(obj.tierId);
      this.myTree.expandItem(checkedItem);
      this.myTree.checkItem(checkedItem,false);
    })  
    // this.treeItemDisable();
  }
  
}
  removeInputImage() {
    this.imageUrlName.nativeElement.value = '';
    this.imageTitle = '';
  }

  // function for deleting image of branding 
  removeBrandingImage() {
    Swal({
      title: 'Are you sure?',
      text: 'You want to remove image.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        if(this.newUserId) {
          this.apiService.delete("uploadImage/removeImage?section=1&sectionId=" + this.newUserId, '').subscribe(
            data => {
              if (data.statusCode == 100) {
                this.alertService.sweetMessage('success', 'Image removed successfully');
                this.imageUrl = 'assets/img/upload-img.png'
              } else {
                this.alertService.sweetMessage('error', data.responseMessage);
              }
            },
            error => {
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
          );
        } else {
          this.alertService.sweetMessage('success', 'Image removed successfully');
          this.imageUrl = 'assets/img/upload-img.png'
        }
      }
    })
  }


}
