import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService , AlertService}from '../../../../../services/index';
import Swal from 'sweetalert2'
declare var $: any, moment: any;
declare var jQuery: any;
// import * as $ from 'jquery'
import * as _ from "lodash";
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-license',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

clientId: any = localStorage.getItem("clientId");
userId: any = localStorage.getItem("userId");
customerDatalist: any = [];
dataTable: any;
clientdetailId: any = 0;
// clientName : any = localStorage.getItem("clientName");
clientName : any = localStorage.getItem("ClientInfoName");
imageUrl = "assets/img/user_icon.png"
hrefUrl : any;
assignAsset:any=[];
userList:any = [];
  breadcrumbObj: any;
  HistoryList: any = [];


constructor(private router:Router,private route: ActivatedRoute, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }


ngOnInit() {
  this.route.params.subscribe(params => {
      if(params['id']){
      this.clientdetailId = params['id'];
    }
  });
  if(this.clientdetailId){
    this.getCustomerListByClient();
  }
  this.route
    .queryParams
    .subscribe(params => {
        this.breadcrumbObj = params['source'];
        // this.urlCustomerId = params.cus_id;
    })
  this.hrefUrl = this.apiService.Customer_Url
  // if(location.host == "demo.satalarm.com" && !this.hrefUrl){
  //   this.hrefUrl = "https://customer.satalarm.com/login?";
  // }else if (location.host == "adm.satalarm.com" && !this.hrefUrl) {
  //   this.hrefUrl = "https://customer.satalarm.com/login?";
  // } else{
  //   this.hrefUrl = "https://s14.cdnsolutionsgroup.com/login?";
  // }

}
 /* Get Customer list */
  getCustomerListByClient(){
  //  this.apiService.getAll('customer/getCustomerListByClient?clientId='+this.clientdetailId+'&userId='+this.userId).subscribe(
    if ($.fn.DataTable.isDataTable('#customer_table')){
      $('#customer_table').DataTable().destroy();
      this.customerDatalist=[];
    }
   this.apiService.getAll('customer/getCustomerListByClient?clientId='+this.clientdetailId/* +'&isActive=1' */).subscribe(
   data => {
       if(data.statusCode == 100){
          this.customerDatalist = data.responseList;
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#customer_table');
            this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0,5,8,9,10] }]});  
          }, 1000);
        }else{
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#customer_table');
            this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0,5,8,9,10] }]});  
          }, 1000);
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
            }
   )
  }

  getAssignAssets(customerdatalist){
    this.assignAsset = [];
    // var URL = 'asset/getAssetByClient?clientId='+this.clientdetailId+'&customerId='+customerdatalist.customerId+'&isAssetLive=true';
    if ($.fn.DataTable.isDataTable('#assignAssetTable')){
      $('#assignAssetTable').DataTable().destroy();
    };   
    var URL = 'asset/getAssetByClient';
    let reqObj:any = {
      "clientId": this.clientdetailId,
      customerIds:customerdatalist.customerId,
      "isAssetLive": true,
     }
    this.apiService.create(URL, reqObj).subscribe(
          data => {
              if (data.statusCode == 100) {
                this.assignAsset = [];
                this.assignAsset = data.responseList;
                this.chRef.detectChanges();
                setTimeout(() => {
                  const table: any = $('#assignAssetTable');
                  this.dataTable = table.DataTable({"bInfo": false});
                }, 100);
              } else {
                this.assignAsset = [];
              }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
    );
  }

  getUserList(customerdatalist){
    this.userList = [];
    var URL = 'customer/getUserListByCustomerAndClient?customerId='+customerdatalist.customerId+'&clientId='+this.clientdetailId
    if ($.fn.DataTable.isDataTable('#userTable')){
      $('#userTable').DataTable().destroy();
    };   
    this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                this.userList = data.userList;
                this.chRef.detectChanges();
                setTimeout(() => {
                  const table: any = $('#userTable');
                  this.dataTable = table.DataTable({"bInfo": false});
                }, 100);
              } else {
                this.userList = [];
              }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
    );
  }

 
  /* Delete Customer */
  DeleteCustomer(customerdata) {
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Customer.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('customer/deleteCustomer?customerId=' + customerdata.customerId, '').subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getCustomerListByClient();
              this.chRef.detectChanges();
              // const table: any = $('.table');
              // this.dataTable = table.DataTable();
              Swal({
                position: 'top-end',
                type: 'success',
                title: 'Customer deleted successfully',
                showConfirmButton: false,
                timer: 3500
              });

            } else {
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 3500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }
  

  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('customer/getCustomerHistoryById?customerId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0] }]});
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": [0] }]});
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }
  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }
}
