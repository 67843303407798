import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CommonServicesService {

  constructor() { }

  private subject = new BehaviorSubject<any>({id:null});
  private isClientListFlag = new BehaviorSubject<any>({flag:false});
  private ClientSectionRoleId = new Subject<any>();
  private ClearClientRolePage = new Subject<any>();

  private dashboardClientName = new BehaviorSubject<any>({clientName:''});

  //setting value for show client on dashboard
  setDashboardClientName(clientName:string) {
   this.dashboardClientName.next({clientName:clientName});
  }

  //getting value for dashboard client
  getDashboardClientName(): Observable<any> {
   return this.dashboardClientName.asObservable();
  }

// Send client Id when user selct any client from header client filter
   sendClientId(message: string) {
       this.subject.next({ id: message });
   }

   setClientFlag(val){
    this.isClientListFlag.next({flag:val})
   }

   getClientFlag(): Observable<any> {
    return this.isClientListFlag.asObservable();
  }

// this method use to get the updated vale from observable and send this value to all the subscribers
   getClientId(): Observable<any> {
       return this.subject.asObservable();
   }

// this method is use to clear the filter when user leave the current page
   clearClientFilter(): Observable<any> {
     // console.log('clear called')
      return this.subject.asObservable();
   }

// this method is use to call getRole By Id for Client Role Section when user perfom Edit Role
  sendClientSectionRoleId(id: string) {
    this.ClientSectionRoleId.next({ id: id });
  }

// this method use to get the updated vale from observable and send this value to all the subscribers
  getClientSectionRoleId(): Observable<any>{
    return this.ClientSectionRoleId.asObservable();
  }

  clearAddRolePageData(): Observable<any>{
    console.log('clear data')
    this.ClearClientRolePage.next({ id: 'clear' });
    return this.ClearClientRolePage.asObservable();
  }

}
