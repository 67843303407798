import { Component, OnInit,ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService, CommonServicesService } from '../../../../services/index';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2'
// import * as $ from 'jquery'
// import 'datatables.net';
// import 'datatables.net-bs4';

declare var $ : any;
declare var jQuery : any;
declare var d3:any,moment:any;
import 'datatables.net';
import 'datatables.net-bs4';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { PaginationInstance } from 'ngx-pagination';


@Component({
  selector: 'app-asset-management',
  templateUrl: './asset-management.component.html',
  styleUrls: ['./asset-management.component.css']
})
export class AssetManagementComponent implements OnInit,OnDestroy {

  @ViewChild("updateAssetForm") updateAssetForm:any;

  clientId: any = (localStorage.getItem("clientId")?JSON.parse(localStorage.getItem("clientId")):null);columnSortOrder: any[];
  HistoryList: any = [];
  assetUserList: any = [];
;
  userId: any = localStorage.getItem("userId");
  customerId:any ;
  tierId:any ;
  assetDatalist : any = [];
  dataTable: any;
  assetId :any = 0;
  dropdownSettingsSite = {};
  dropdownSettingsState={};
  dropdownSettingsStatus={};
  search:any='';
  site:any='';
  county:any='';
  status:any='';
  checkStateArray:any=[];
  checkSiteArray:any=[];
  checkStatusArray:any=[];
  countryId:number=0;
  state:any='';
  countryList:any=[];
  countyList:any=[];
  stateList:any=[];
  siteList:any=[];
  statusList=[{"status":"A","statusName":"Alarm"},{"status":"W","statusName":"Warning"},{"status":"O","statusName":"Offline"},{"status":"N","statusName":"Normal"},{"status":"S","statusName":"Suspended"}];

  message: any;
  subscription: Subscription;
  clientFilterId : any = '';
  customClientFilterId : any = '';
  assetData: any = {shiftGroup:{},notificationPlan:{},site:{},customer:{}};
  isShowCritical:any;
  isShowCalendar:any
  notificationListData: any = [];
  customerListData = [];
  shiftGroupListData: any = [];
  siteListData: any = [];
  clientTopDropdownValue : any;
  clientName : any = localStorage.getItem("clientName");

  isShowCallOrder:any = false;
  assetDataId : any;
  userassetList:any=[];
  total:any=1;
  numbers:any=[];
  SelectedAssetData:any = null;
  public config: PaginationInstance = { id: 'server', itemsPerPage: 10, currentPage: 1, totalItems: 10 }
  tableSearchCon:any = ''
  onPageChange(number: number) {
    console.log(number);
    
    this.config.currentPage = number;
    this.getAllAssetList()
}
pageSizeChange(){
  this.config.currentPage = 1;;
  this.getAllAssetList()
}

searchInputChange(event){
  console.log(event.target.value, this.tableSearchCon);
  if (this.tableSearchCon.length==0) {
    this.getAllAssetList()
  }
}
SearchAssetTable(){
  this.config.currentPage = 1
  this.getAllAssetList()
}


  constructor(
    private router:Router,
    private apiService: ApiService ,
    private alertService: AlertService ,
    private chRef: ChangeDetectorRef,
    private CommonServicesService: CommonServicesService
  ) {    
    // subscribe to header component sendClientId to get Client Id
      this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
      .subscribe(
        message => {
          this.clientFilterId = message.id;
          this.config.currentPage = 1
          this.config.itemsPerPage = 10
          setTimeout(() => {
            this.clientName = localStorage.getItem("clientName");
          }, 1000);
          if (this.clientFilterId) {
            this.getAllAssetList();
            // this.getAllCustomerList();
            // this.getAllSiteList();
          }else{
            this.getAllAssetList();
          }
        },
        error=>{
          console.log(error)
        }
      );
  }

  ngOnDestroy() {
    // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  tierClick(id){
    this.CommonServicesService.sendClientId(id);
  }

  ngOnInit() {    
    let self = this;
    // this.getCountryList();
    this.getSiteManagementList();
    // setTimeout(function(){
    //   self.getAllAssetList();
    // }, 500);

    this.dropdownSettingsSite = {
      singleSelection: false,
      idField: 'siteId',
      textField: 'siteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.dropdownSettingsState = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'status',
      textField: 'statusName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
  }
/* On Item Select Status */
onItemSelectStatus(item: any) {
    var exist = this.checkStatusArray.indexOf(item.status);
    if(exist == '-1'){
        this.checkStatusArray.push(item.status);
      }
  }

/* On Item DeSelect Status */
onItemDeselectStatus(item: any){
  var exist = this.checkStatusArray.indexOf(item.status);
    if(exist == '-1'){
        this.checkStatusArray.splice(exist,1);
      }
}
/* On All Select Status */
onSelectAllStatus(items: any) {
  Object.keys(items).forEach(key=> {
      this.checkStatusArray.push(items[key]['status'].toString());
    });
}
/* On All DeSelect Status */
onDeSelectAllStatus(){
  this.checkStatusArray = [];
}


/* On Item Select State */
onItemSelectState(item: any) {
    var exist = this.checkStateArray.indexOf(item.stateId);
    if(exist == '-1'){
        this.checkStateArray.push(item.stateId);
      }
  }

/* On Item DeSelect State */
onItemDeselectState(item: any){
  var exist = this.checkStateArray.indexOf(item.stateId);
    if(exist == '-1'){
        this.checkStateArray.splice(exist,1);
      }
}
/* On All Select State */
onSelectAllState(items: any) {
  Object.keys(items).forEach(key=> {
      this.checkStateArray.push(items[key]['stateId'].toString());
    });
}
/* On All DeSelect State */
onDeSelectAllState(){
  this.checkStateArray = [];
}
/* On Item Select Site */
onItemSelectSite(item: any) {
    var exist = this.checkSiteArray.indexOf(item.siteId);
    if(exist == '-1'){
        this.checkSiteArray.push(item.siteId);
      }
  }

/* On Item DeSelect Site */
onItemDeselectSite(item: any){
  var exist = this.checkSiteArray.indexOf(item.siteId);
    if(exist == '-1'){
        this.checkSiteArray.splice(exist,1);
      }
}
/* On All Select Site */
onSelectAllSite(items: any) {
  Object.keys(items).forEach(key=> {
      this.checkSiteArray.push(items[key]['siteId'].toString());
    });
}
/* On All DeSelect Site */
onDeSelectAllSite(){
  this.checkSiteArray = [];
}

/* On Search */
seachRecord(){
  this.site   = this.checkSiteArray.toString();
  this.state  = this.checkStateArray.toString();
  this.status = this.checkStatusArray.toString();
  this.getAllAssetList();
}
/* Reset Search Record */
resetSearch(){
  this.search = "";
  this.site = "";
  this.county="";
  this.status="";
  this.countryId=0;
  this.state="";
  this.checkSiteArray=[];
  this.checkStateArray=[];
  this.checkStatusArray=[];
  this.getAllAssetList();
}


/* Get Site Management list */
getSiteManagementList(){
  if(this.clientId){
   this.apiService.getAll('site/getSiteDropDownListByClient?clientId='+this.clientId+'&userId='+this.userId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.siteList = data.responseList;
        }else{
          this.siteList = [];
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
     );
}
}
/* Get state list by country id */
getStateListByCountryId(countryId){
  if(countryId != 0){
  this.apiService.getAll('state/getStateList?countryId='+countryId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.stateList = data.responseList;
        }else{
          this.stateList = [];
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
  }
}
/* Get CountyList list */
getCountyList(){
  if(this.clientId){
  var URL = 'site/getSiteCountyByClient?clientId='+this.clientId+'&userId='+this.userId;
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.countyList = data.responseList;
        }else{
          this.countyList = [];
         }
      },
      error => {
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }
}

columnSortOrderChange(val, event){
  setTimeout(() => {
    console.log(val,event.target.className);
    
    if(event.target.className == 'sorting_desc'){
      this.columnSortOrder = [val,'desc']
    }else if(event.target.className= 'sorting_asc' || event.target.className == 'sorting'){
      this.columnSortOrder = [val,'asc'] 
    }
  }, 500);
  
}

  /* Function for getting Root level tier data */
  getAllAssetList(){
    this.clientTopDropdownValue =  $('#client_dropdown_top').children("option:selected").val();    
    var URL = 'asset/getAssetByAdmin?isAssetLive=true';
    if(this.clientFilterId != null && this.clientFilterId !=0){
      var clientIdString     = '&clientId='+this.clientFilterId.toString();
      var URL = URL.concat(clientIdString);
    }
    // else {
    //   var clientIdString = '?clientId='+this.clientTopDropdownValue.toString();
    //   var URL = URL.concat(clientIdString);
    // }
    URL= URL+'&startlimit='+(this.config.itemsPerPage*this.config.currentPage-this.config.itemsPerPage)+"&endlimit="+this.config.itemsPerPage
    if (this.tableSearchCon.length) {
      URL = URL + '&search='+this.tableSearchCon
    }

    this.assetDatalist = [];
    if ($.fn.DataTable.isDataTable('#asset_list_data_table')){
      $('#asset_list_data_table').DataTable({"order": [],paging:false, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [9,10] }]}).destroy();
    };
    
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetDatalist = data.responseList;
          this.chRef.detectChanges();
          this.config.totalItems = data.recordsTotal

          setTimeout(() => {
            const table: any = $('#asset_list_data_table');
            this.dataTable = table.DataTable({"order": this.columnSortOrder ? [this.columnSortOrder] : [], paging:false, "searching":false, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [9,10] }]});
          }, 1000);
        }else{
          this.assetDatalist = [];
          this.config.totalItems = 0;
          this.chRef.detectChanges();
          const table: any = $('#asset_list_data_table');
          this.dataTable = table.DataTable({"order": [],paging:false,searching:false, "destroy": true,"columnDefs": [{ "orderable": false, "targets": [9,10] }]});
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    ); 
}

  getAsset(assetId){
    this.apiService.getAll("asset/getAsset?assetId="+assetId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.assetDatalist = data.responseList;
         this.chRef.detectChanges();
            const table: any = $('#asset_data_table');
            this.dataTable = table.DataTable();
        }else{
           this.assetDatalist = [];
         this.chRef.detectChanges();
            const table: any = $('#asset_data_table');
            this.dataTable = table.DataTable();
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }


    // Getting Asset Id
    getAssetId(data) {
      this.updateAssetForm.submitted = false;
      this.assetData = JSON.parse(JSON.stringify(data));
      if(this.clientFilterId=='0' || this.clientFilterId===null){
        if(data.client){
          this.customClientFilterId = data.client.clientId;
          this.getAllCustomerList();
          this.getAllSiteList();
          this.getAllNotificationList();
        }
      }else{
        this.getAllCustomerList();
        this.getAllSiteList();
        this.getAllNotificationList();
      }
      if (this.assetData.customer === null) {
        this.assetData.customer = {'customerId':''}
      }

      if (this.assetData.notificationPlan === null) {
        this.assetData.notificationPlan = {'notificationPlanId':''}
      }

      this.assetData.site.siteId = data.site.siteId;
      if(this.assetData.site.isRuntime == true) {
        this.isShowCritical = true
      }
      if(data.notificationPlan && data.notificationPlan.callSchedule == 2) {
        this.isShowCalendar = true;
      }
      else {
        this.isShowCalendar = false;
      }
      // if (this.clientFilterId) { 
      //   this.getAllNotificationList();
      // }
  }

// Getting All Notifications
getAllNotificationList() {
  if(this.clientFilterId=='0' || this.clientFilterId===null){
    var URL = 'notification_plan/getNotificationPlanByTierORisGlobalNp?clientId=' + this.customClientFilterId + '&assetId=' + this.assetData.assetId+'&alphabetSortingOrder=1'
  }else{
    var URL = 'notification_plan/getNotificationPlanByTierORisGlobalNp?clientId=' + this.clientFilterId + '&assetId=' + this.assetData.assetId+'&alphabetSortingOrder=1'
  }
  // var URL = 'notification_plan/getNoficationPlanDropDownListByClient?clientId=' + this.clientId + '&userId=' + this.userId
  // var URL = 'notification_plan/getNotificationPlanByTierORisGlobalNp?clientId=' + this.clientFilterId + '&assetId=' + this.assetData.assetId+'&alphabetSortingOrder=1'
  this.apiService.getAll(URL).subscribe(
    data => {
      if (data.statusCode == 100) {
        this.notificationListData = [];
        this.notificationListData = data.responseList;
        var _this = this;
        if(this.notificationListData.length==0){
          this.assetData.notificationPlan = {'notificationPlanId':''}
          this.notificationListData = [];
        }else{
          /* Check if call schedule value is 2
            at the time of get all the data then show calender DropDrown
          */
          this.notificationListData.forEach(function (arrayItem) {
            let SelectedId = _this.assetData.notificationPlan.notificationPlanId;
            let notifyId = arrayItem.notificationPlanId;
            console.log("notifyId---",notifyId," SelectedId---",SelectedId)
            if(SelectedId == notifyId && arrayItem.callSchedule == 2) {
              _this.getAllShiftList();
              _this.isShowCalendar = true;
            }
          });
        }
      } else {
          this.assetData.notificationPlan = {'notificationPlanId':''}
          this.notificationListData = [];
          /* this.alertService.sweetMessage('error',data.responseMessage); */
      }
    },
    error =>
    { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
  );
}


// Getting Site List
getAllSiteList() {
  if(this.clientFilterId=='0' || this.clientFilterId===null){
    var URL = 'site/getSiteDropDownListByClient?clientId=' + this.customClientFilterId + '&userId=' + this.userId+ '&alphabetSortingOrder=1'
  }else{
    var URL = 'site/getSiteDropDownListByClient?clientId=' + this.clientFilterId + '&userId=' + this.userId+ '&alphabetSortingOrder=1'
  }
  // var URL = 'site/getSiteDropDownListByClient?clientId=' + this.clientFilterId + '&userId=' + this.userId+ '&alphabetSortingOrder=1'
  this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
            this.siteListData = [];
            this.siteListData = data.responseList;
        } else {
            this.siteListData = [];
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
  );
}


    // Getting all Customers
    getAllCustomerList() {
      if(this.clientFilterId=='0' || this.clientFilterId===null){
        var URL = 'customer/getCustomerDropDownByClient?clientId=' + this.customClientFilterId + '&alphabetSortingOrder=1&isActive=1'
      }else{
        var URL = 'customer/getCustomerDropDownByClient?clientId=' + this.clientFilterId + '&alphabetSortingOrder=1&isActive=1'
      }
      // var URL = 'customer/getCustomerDropDownByClient?clientId=' + this.clientFilterId + '&userId=' + this.userId+ '&alphabetSortingOrder=1'
      this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.customerListData = [];
                  this.customerListData = data.responseList;
                  if(this.customerListData.length===0){
                    this.assetData.customer = {'customerId':''}
                    this.customerListData = [];
                  }
              } else {
                this.assetData.customer = {'customerId':''}
                this.customerListData = [];
                /* this.alertService.sweetMessage('error',data.responseMessage); */
              }
          },
         error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
    }


    // Getting shift List
    getAllShiftList() {
      if(this.clientFilterId=='0' || this.clientFilterId===null){
        var URL = 'shiftGroup/getShiftGroupDropDownByClient?clientId=' + this.customClientFilterId + '&userId=' + this.userId;
      }else{
        var URL = 'shiftGroup/getShiftGroupDropDownByClient?clientId=' + this.clientFilterId + '&userId=' + this.userId;
      }
      // var URL = 'shiftGroup/getShiftGroupDropDownByClient?clientId=' + this.clientFilterId + '&userId=' + this.userId
      this.apiService.getAll(URL).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.shiftGroupListData = data.responseList;
              } else {
                  this.shiftGroupListData = [];
              }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
  }

  updateAssets(){
    var _this = this;
    // else{  
    // }
    if(this.updateAssetForm.valid){
      if(_this.isShowCritical==false){
        delete _this.assetData.criticalThresHold;
      }

      // Deep copy
      var newObject = jQuery.extend(true, {}, _this.assetData);
      let obj = {
        assetId     : newObject.assetId,
        site        : newObject.site,
        customer    : newObject.customer.customerId ? newObject.customer : null,
        name        : newObject.name,
        shiftGroup  : newObject.shiftGroup,
        criticalThresHold : newObject.criticalThresHold,
        notificationPlan  :newObject.notificationPlan,
      }

      if(this.assetData.notificationPlan.notificationPlanId == "" || this.assetData.notificationPlan.notificationPlanId == undefined) {
        obj.notificationPlan = null;
      }
      if(this.assetData.shiftGroup == null || this.assetData.shiftGroup.shiftGroupId == "" || this.assetData.shiftGroup.shiftGroupId == undefined) {
        obj.shiftGroup = null;
      }

        // this.updateDataForAsset = obj;
        this.apiService.update('asset/updateAssetForDropDown', obj).subscribe(
          data => {
                if(data.statusCode == 100){
                    this.alertService.sweetMessage('success','Asset Updated successfully');
                    // Get aa assets
                    this.getAllAssetList();
                    this.closeModal();
                }else{
                  // this.assetData.notificationPlan.notificationPlanId = "";
                    this.alertService.sweetMessage('error',data.responseMessage);
                }
            },
            error =>{
              this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
        );
    }
  }

  isRuntime (event) {
    let isRuntimeValue = event.target.selectedOptions[0].id;
    if(isRuntimeValue == "true") {
      this.isShowCritical = true;
    }
    else {
      this.isShowCritical = false;
    }
  }

  // check if call schedule value is 2 then show calender DropDown
  isCallSchedule (event) {
    let callScheduleValue = event.target.selectedOptions[0].id;
    if(callScheduleValue == 2) {
      this.getAllShiftList();
      this.isShowCalendar = true;
    }
    else {
      this.isShowCalendar = false;
    }
  }

  // close modal window
  closeModal(){
   $("#closeModal").click();
  }

  // reset update asset modal
  resetUpdateAsset(){

  }


 // Getting Asset Data
 getAssetData(data22){  
    this.isShowCallOrder = data22.notificationPlan ?  (data22.notificationPlan.callSchedule == 1 ? true : false) : false
    console.log(this.isShowCallOrder,data22);
    this.SelectedAssetData = data22;
    this.assetDataId = JSON.parse(JSON.stringify(data22.assetId));
    this.apiService.getAll('user/getUserByAsset?clientId='+data22.client.clientId+'&assetId='+this.assetDataId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.userassetList = data.userList;
          this.total = this.userassetList.length + 2;
          this.numbers = [];
            for (var i = 1; i < this.total; i++) {
                this.numbers.push(i);
                //visit(children[i], visitFn, childrenFn);
            }
        }else{
          this.userassetList = [];
          this.numbers = ['1'];
        }
      },
    error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
     // Getting User and Role List
     this.getUserList();
     this.getRoleList();
  }

  DeleteUser(assetdata) {
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Asset User.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('assetUser/deleteAssetUser?assetId='+this.assetDataId+'&userId='+assetdata.userId,'').subscribe(
          data => {
            if(data.statusCode == 100){
                this.numbers.pop();
                $("#closemodal").click();
                this.getAllAssetList();
                this.alertService.sweetMessage('success','Asset User deleted successfully');
                this.getAssetDataAfterUserAdd(this.assetDataId);
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
          );
      }
    })    
  } 

UserToAsset:any = {"createdTime":"","callSequence":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
UserList:any=[]; 
roleList:any=[];
  // Getting User List
  getUserList(){
    // this.apiService.getAll('public/user/getUserByTier?clientId='+this.clientId+'&assetId='+this.assetDataId+"&isActive=true").subscribe(
    this.apiService.getAll('user/getUserByTierWithCustomerUser?assetId='+this.assetDataId+"&isActive=true&clientId="+this.SelectedAssetData.client.clientId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.UserList = data.userList;
        }else{
          this.UserList =[];
         /* this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
         }
    );
  }

  // Getting Role List
  getRoleList(){
    this.apiService.getAll('assetRole/getAllAssetRole').subscribe(
      data => {
        if(data.statusCode == 100){
          this.roleList = data.responseList;
        }else{
          this.roleList =[];
          /*this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
    error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }


  // Add Asset User
  AddUserByAsset(){
    this.UserToAsset.asset.assetId = this.assetDataId;
    this.apiService.create('assetUser/addUserToAsset', this.UserToAsset).subscribe(
      data => {
        if(data.statusCode == 100){
          // $("#closemodal").click();
          this.getAssetDataAfterUserAdd(this.assetDataId);
          // this.getUserByAsset();
          this.getAllAssetList();
          this.alertService.sweetMessage('success','Asset User added successfully');
          this.UserToAsset = {"createdTime":"","callSequence":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};
        }else{
          this.UserToAsset = {"createdTime":"","callSequence":"","modifiedTime":"","creator":"","lastModifier":"","user":{"userId":""},"asset":{"assetId":''},"assetRole":{"assetRoleId":""}};

        this.alertService.sweetMessage('error',data.responseMessage);

        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
    );
  }

  getAssetDataAfterUserAdd(data22){

    // this.isShowCallOrder = data22.notificationPlan ?  (data22.notificationPlan.callSchedule == 1 ? true : false) : false
    // console.log(this.isShowCallOrder,data22);
    
    // this.assetDataId = JSON.parse(JSON.stringify(data22.assetId));
    this.apiService.getAll('user/getUserByAsset?clientId='+this.clientFilterId+'&assetId='+this.assetDataId).subscribe(
      data => {
        if(data.statusCode == 100){
          this.userassetList = data.userList;
          this.total = this.userassetList.length + 2;
          this.numbers = [];
            for (var i = 1; i < this.total; i++) {
                this.numbers.push(i);
                //visit(children[i], visitFn, childrenFn);
            }
        }else{
          this.userassetList = [];
          this.numbers = ['1'];
        }
      },
     error =>{
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
    }
    );

    // Getting User and Role List
    this.getUserList();
    this.getRoleList();
  }


getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('asset/getAssetHistoryByAsset?assetId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}
setList(users){
  this.assetUserList = users;
}
DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}
}
