import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, AlertService, FormatDateService } from 'src/app/services';
import * as _ from "lodash";
import 'datatables.net';
declare var jQuery : any;
declare var $ : any;
declare var d3:any,moment:any;
import 'datatables.net-bs4';
import Swal from 'sweetalert2';
import { error } from 'protractor';

@Component({
  selector: 'app-runtime-event-detail',
  templateUrl: './runtime-event-detail.component.html',
  styleUrls: ['./runtime-event-detail.component.css']
})
export class RuntimeEventDetailComponent implements OnInit {

  @Input() callInstructionData:any;
  @Output() valueChange = new EventEmitter();
  // @Input() isShow:any;


  followUpData : any = JSON.parse(localStorage.getItem('FollowUpData'));
    
  EventDataFlags:any = {};

  runTimeEventId = null;
  runTimeEventObj:any = {};
  runTimeObj:any = {};
  EditButtonShow:any = true
  ShowResponsibilitySection:any = true
  UseFirstFault:any = null
  assignOwner:any;
  eventType:any = '';
  clientId:any = null;
  clientCode:any = null;
  userId:any = null;
  rollUpdataList:any = [];
  assetId:any = null;
  rcpId:any = null;
  assetsumaryData:any = {};
  reasonListData : any = [];
  technicianList : any = [];
  responsbilityList : any = [];
  firstFaultResponsibilityArr : any = [];
  tempArr : any = [];
  runTimeEventResponsibilities : any = [];
  responsibilityArr : any = [];

  reasonCodeId : any = '';
  technicianId : any = '';
  comment : any = '';
  notificationId : any = '';

  updateruntimeObj : any =  {
    "runTimeEventId": '',
    "reasonCode": {
        "reasonCodeId": ''
    }
  }

  currClientId: any = localStorage.getItem("clientId");
  assignedContactListData : any = [];
  eventIdArr : any = [];
  sMSMsg : any = '';
  emailMsg : any = '';

  addReasonCodeData:any = {
    "name" : "",
    "client" : {
       "clientId" : (localStorage.getItem("clientId") ? JSON.parse(localStorage.getItem("clientId")) : '')
    }
  }    
  rollUpList : any = [];
  performAction : any = 0;
  permissionData : any = [];
  remainingTimeToAllocate : any = '';
  dataTable: any;
  licenseIsTechEntry : any = '';
  notficationType : any = '';
  assignOwnerTechObj : any = {};
  eventStatus : any = '';
  isParent : any = '';
  sumOfDowntime : number = 0;
  listMemberIdArr : any = [];
  wffEventDetailData : any = [];
  childEventFirstFaultCode : any = '';
  isChildEventsInDetail : any;
  total_duration : number = 0;
  remaining_mins : number = 0;
  unassignedTechnicianList : any = [];
  usersArr : any = [];
  newTechnicianId : any = '';
  userIdArr : any = [];
  mode : any = 'view';
  eventListType : any;
  totalDowntime : number = 0;    
  /***
    This parameter is user for showing Update and close button 
    i.e. if all responsilbilities are filled then it will be active
  ***/
  closeActive : number = 0; 
  heading : any = '';
  testArr :any = [];
  firstFaultCodeSelected : number = 0;
  dropdownSettings: any = {};
  selectedTechnicians : any = [];
  unassignedSelectedTechnicians : any = [];
  unassignedDropdownSettings: any = {};
  showAckBtn : number = 0;
  showAckErcBtn : number = 0;
  showERC : number = 0;

  runtimeAckData : any =  {
    "runtimeEvent":{
      "runTimeEventId":''
    },
    "isAck":true,
    "user":{
      "userId": (localStorage.getItem("userId") ? JSON.parse(localStorage.getItem("userId")) : '')
    },
    "agentType":"WEB"
  };

  ackData : any =  {
    "wffNotification":{
      "wffNotificationId":''
    },
    "isAck":true,
    "user":{
      "userId": (localStorage.getItem("userId") ? JSON.parse(localStorage.getItem("userId")) : '')
    },
    "agentType":"WEB"
  };
  runTimeWffEventObj : any = [];
  showAckTime : any = '';
  assignToMe : number = 0;
  isAckTag : number = 0;
  primaryTechnicianList : any = [];
  runTimeStatus : any;
  CallLogDetails:any = []
  viewtechnicianList : any = [];
  ReasonCodeError = null;
  standBy: any = false;

  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private apiService: ApiService, 
    private formatDateService: FormatDateService, 
    private alertService: AlertService,
    private chRef: ChangeDetectorRef
    ) { 
    this.permissionData = ApiService.permission;
  }

  ngOnInit() {
    // console.log('followUpData', this.followUpData);
    // this.isParent = this.followUpData.isParent;
    // this.eventType = this.followUpData.type;
    // this.runTimeEventId = this.followUpData.runTimeEventId;
    // this.notificationId = this.followUpData.notificationId;
    // this.rcpId = this.followUpData.runTimeCollectionPointId;
    // this.performAction = this.followUpData.performAction;  
    // this.showAckBtn = this.followUpData.showAckBtn; 
    // this.showAckErcBtn = this.followUpData.showAckErcBtn;
    // this.showERC = this.followUpData.showERC;
    // this.notficationType = this.followUpData.type;
    // this.licenseIsTechEntry = this.followUpData.licenseIsTechEntry;    
    // this.eventListType = this.followUpData.eventListType;
    // this.assignToMe = this.followUpData.assignToMe;
    // this.isAckTag = this.followUpData.isAck;
    // this.clientId = this.followUpData.clientId;
    // console.log('this client id', this.clientId);
    // this.assetId = this.followUpData.assetId;
    // this.runTimeStatus = this.followUpData.runTimeStatus;

    console.log(this.isParent, this.assetId);
      this.dropdownSettings = {
          singleSelection: false,
          idField: 'userId',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 0,
          allowSearchFilter: true
      };

      this.unassignedDropdownSettings = {
          singleSelection: false,
          idField: 'userId',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 0,
          allowSearchFilter: true
      };

      this.clientCode = this.alertService.getClientCode();

      this.route.params.subscribe(params => {
          if (params['id']) {
              // this.assetId = params['id'];                
          }
          
      });
      // this.clientId = localStorage.getItem("clientId");
      this.userId = localStorage.getItem("userId");

      this.route
          .queryParams
          .subscribe(params => {
            
              this.clientId = params['clientId'];
              this.runTimeEventId = params['runTimeEventId'];
              // this.notificationId = params['notificationId'];
              // this.rcpId = params['rcpId'];
              // this.performAction = params['performAction'];  
              // this.showAckBtn = params['showAckBtn']; 
              // this.showAckErcBtn = params['showAckErcBtn'];
              // this.showERC = params['showERC'];
              this.eventType = params['type'];
              // this.licenseIsTechEntry = params['licenseIsTechEntry'];
              // // this.isParent = this.followUpData.isParent;
              // this.eventListType = params['eventListType'];
              // this.assignToMe = params['assignToMe'];
              // this.isAckTag = params['isAck'];
          });

          if(this.eventListType == 'liveFeeds') {
            this.heading = 'Runtime Event Root Cause Entry';
          } else if(this.eventListType == 'approval') {
            this.heading = 'Items Waiting for Approval';
          } else if(this.eventListType == 'approved') {
            this.heading = 'Approved Items';
          }


          // if (this.eventType == "Notification") {
          //   if(this.eventListType == 'liveFeeds') {
          //     this.getInstructionsInfo();  
          //   }              
          //   // this.getRunTimeEventDetails();
          //   this.getWffEventDetails();
          //   this.getWffEventByID(); 
          // }else if(this.eventType == "RunTime") {
          //   //this.getRunTimeEvenListForreasonCode();

          //   this.getRunTimeEventDetails();
          //   this.getMemberResponsibility();
          //   if(this.eventListType == 'liveFeeds') {
          //     this.getInstructionsInfo();  
          //   }
          // } 

          if (this.eventType == "Notification") {
            if (this.notificationId) {
              this.getWffFlagDetails(true)
            }
          }else if(this.eventType == "RunTime") {
            if (this.runTimeEventId) {
              this.getEventByID(true)
            }
            
          } 

          this.addReasonCodeData.client.clientId = this.clientId;

          // this.getWffEventByID();          
  }

  getEventByID(flag) {
    this.apiService.getAll('runTimeEvent/getTEWPermissionByRunTimeEventId?runTimeEventId=' + this.runTimeEventId).subscribe(
      data => {
        console.log(data, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ event data");
        if (data.statusCode == 100) {

          let eventData = data.response
          this.EventDataFlags = data.response


          // ack: false
          // acknowledgeByMe: false
          // assignToMe: true
          // isApproved: false
          // isChild: false
          // isParent: false
          // licenseIsAck: true
          // licenseIsTechEntry: true
          // runTimeCollectionPointId: 5711
          // runTimeEventId: 828665
          // runTimeStatus: 1
          // technicianId: null

          let runTimeStatus = eventData.runTimeStatus;
          let licenseIsAck = eventData.licenseIsAck;
          let acknowledgeByMe = eventData.acknowledgeByMe;
          let assignToMe = eventData.assignToMe;
          let technicianId = eventData.technicianId;
          let licenseIsTechEntry = eventData.licenseIsTechEntry;
          let isAck = eventData.isAck;

          if (this.userId == eventData.technicianId && runTimeStatus == 2) {
            this.performAction = 1;
          }

          // console.log('notification detail', eventData.acknowledgeByMe, eventData.licenseIsAck, eventData.assignToMe);

          // console.log('notification detail 2', eventData.technicianId, eventData.runTimeStatus, eventData.licenseIsTechEntry);


          if (licenseIsAck == true && acknowledgeByMe == false && assignToMe == true) {
            console.log('in if detail');
            this.showAckBtn = 1;
          } else {
            this.showAckBtn = 0;
            console.log('in else detail');
          }
          if (licenseIsAck == true /* && acknowledgeByMe == false */ && assignToMe == true  /* && licenseIsTechEntry == true */ ) {
            console.log('in if detail');
            // this.normalAckButtonShow = true;
          } else {
            // this.normalAckButtonShow = false;
            console.log('in else detail');
          }

          if ( licenseIsAck == true && acknowledgeByMe == false && assignToMe == true && (technicianId == null || technicianId == 0) && licenseIsTechEntry == true && runTimeStatus == 1 ) {
            console.log('in if detail showAckErcBtn');
            this.showAckErcBtn = 1;
          } else {
            this.showAckErcBtn = 0;
            console.log('in else detail showAckErcBtn');
          }

          if ( licenseIsAck == true /* && assignToMe == true */ && (technicianId == null || technicianId == 0) && runTimeStatus == 1 ) {
            this.showERC = 1;
          } else {
            this.showERC = 0;
          }

          let obj = {
            id: null,
            type: null
          }
          let pVal = 0;
          if (eventData.isParent == false) {
            pVal = 0;
          } else if (eventData.isParent == true) {
            pVal = 1;
          }
          obj.type = eventData.notificationType;
          if (eventData.notificationType == "Notification") {
            obj.id = eventData.notificationId;
          } else if (eventData.notificationType == "RunTime") {
            obj.id = eventData.runTimeEventId;
          }
          if (this.eventListType == 'approval' || this.eventListType == 'approved') {
            obj.type = 'RunTime';
            obj.id = eventData.runTimeEventId;
            this.performAction = 1;
            eventData.licenseIsTechEntry = true;
          }

          // this.router.navigate(["eventDetails", eventData.assetId,], 
          //   {
          //     queryParams: 
          //     { 
          //       type: obj.type, 
          //       id :obj.id, 
          //       rcpId:eventData.runTimeCollectionPointId, 
          //       notificationId : eventData.notificationId, 
          //       isParent : pVal, 
          //       performAction : this.performAction, 
          //       showAckBtn : this.showAckBtn,
          //       showAckErcBtn : this.showAckErcBtn,
          //       showERC : this.showERC,
          //       licenseIsTechEntry : eventData.licenseIsTechEntry, 
          //       eventListType : this.eventListType,
          //       assignToMe : assignToMe == true ? 1 : 0,
          //       isAck : isAck == true ? 1 : 0
          //     }
          // });
// *********TODO***********TODO*****************TODO****************TODO*******************TODO*********************TODO*******************TODO*******TODO******TODO
          // this.eventType = obj.type//params['type'];//==================================================take from url params
          // this.notificationId = eventData.notificationId//params['notificationId'];
          // this.showAckBtn = params['showAckBtn']; 
          // this.showAckErcBtn = params['showAckErcBtn'];
          // this.showERC = params['showERC'];

          console.log('followUpData', this.followUpData);
          this.isParent = eventData.isParent;
          // this.eventType = this.followUpData.type;
          this.runTimeEventId = eventData.runTimeEventId;
          this.notificationId = eventData.notificationId;
          this.rcpId = eventData.runTimeCollectionPointId;
          this.performAction = "1" //this.followUpData.performAction;  
          this.showAckBtn = 0//this.followUpData.showAckBtn; 
          this.showAckErcBtn = 0//this.followUpData.showAckErcBtn;
          this.showERC = 0//this.followUpData.showERC;
          this.notficationType = this.eventType //  this.followUpData.type;
          this.licenseIsTechEntry = eventData.licenseIsTechEntry//this.followUpData.licenseIsTechEntry;    
          this.eventListType = "liveFeeds" //this.followUpData.eventListType;
          this.assignToMe = 1 //this.followUpData.assignToMe;
          this.isAckTag = 1 //this.followUpData.isAck;
          this.clientId = this.clientId // this.followUpData.clientId;
          console.log('this client id', this.clientId);
          this.assetId = eventData.assetId; // --------------------------------------------------------------------TO-DO  need to check
          this.runTimeStatus = eventData.runTimeStatus;


          // this.runTimeEventId = eventData.runTimeEventId//obj.id//params['id'];
          // this.rcpId = eventData.runTimeCollectionPointId//params['rcpId'];
          // this.performAction = this.performAction//params['performAction'];  
          // this.notficationType = this.eventType//obj.type//params['type'];
          // this.licenseIsTechEntry = eventData.licenseIsTechEntry//params['licenseIsTechEntry'];
          // this.isParent = pVal //params['isParent'];
          // // this.eventListType = params['eventListType'];
          // this.assignToMe = assignToMe == true ? 1 : 0//params['assignToMe'];
          // this.isAckTag = isAck == true ? 1 : 0//params['isAck']; //===================================------------------------  change when available in api response


          // this.assignedContactListData = data.responseList;

          console.log(this.notficationType, "runTimeEventId::",this.runTimeEventId,this.eventListType,"performAction::",this.performAction,".showAckBtn :: ", this.showAckBtn );
          
        } else {
          // this.assignedContactListData =[];
          /* this.alertService.sweetMessage('error',data.responseMessage); */
        }
        this.getCallLogDetails();

        if (flag) {
          if (this.eventType == "Notification") {
            // if (this.eventListType == 'liveFeeds') {
            //   this.getInstructionsInfo();
            // }
            // this.getRunTimeEventDetails();
            this.getWffEventDetails();
            this.getWffEventByID();
          } else if (this.eventType == "RunTime") {
            //this.getRunTimeEvenListForreasonCode();
            console.log("-------------------------------------------------- get event detail");
            
            this.getRunTimeEventDetails();
            this.getMemberResponsibility();
            // if (this.eventListType == 'liveFeeds') {
            //   this.getInstructionsInfo();
            // }
          }
        }

      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  getCallLogDetails(){
    console.log(this.runTimeStatus);
    var Url = ""
    if (this.runTimeStatus == 1) {
      Url = "user/getRunTimeEventTechnician?clientId="+this.clientId+"&assetId="+this.assetId+"&assetRoleId=1"
    }else{
      Url = "user/getAssignedTechnicianByRuntimeEvent?runtimeEventId="+this.runTimeEventId
    }
    this.apiService.getAll(Url ).subscribe(
      data => {
        console.log(data, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ ");
        if (data.statusCode == 100) {
          this.CallLogDetails = data.userList;

        }else{
          this.CallLogDetails = [];
        }
      },
      error=>{
        console.log(error);
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      })
    
  }

  getWffFlagDetails(flag){
    // https://demo.satalarm.com/client/api/v1/wffnotification/getTEWPermissionByWffNotificationEventId?notificationId=9809056
    this.apiService.getAll('wffnotification/getTEWPermissionByWffNotificationEventId?notificationId='+ this.notificationId ).subscribe(
      data => {
        console.log(data, "+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ event data");
        if (data.statusCode == 100) {

          let eventData = data.response
          // this.EventDataFlags = data.response


          // ack: false
          // acknowledgeByMe: false
          // assignToMe: true
          // isApproved: false
          // isChild: false
          // isParent: false
          // licenseIsAck: true
          // licenseIsTechEntry: true
          // runTimeCollectionPointId: 5711
          // runTimeEventId: 828665
          // runTimeStatus: 1
          // technicianId: null

          let runTimeStatus = eventData.runTimeStatus;
          let licenseIsAck = eventData.licenseIsAck;
          let acknowledgeByMe = eventData.acknowledgeByMe;
          let assignToMe = eventData.assignToMe;
          let technicianId = eventData.technicianId;
          let licenseIsTechEntry = eventData.licenseIsTechEntry;
          let isAck = eventData.isAck;

          if (this.userId == eventData.technicianId && runTimeStatus == 2) {
            this.performAction = 1;
          }

          // console.log('notification detail', eventData.acknowledgeByMe, eventData.licenseIsAck, eventData.assignToMe);

          // console.log('notification detail 2', eventData.technicianId, eventData.runTimeStatus, eventData.licenseIsTechEntry);


          if (licenseIsAck == true && acknowledgeByMe == false && assignToMe == true) {
            console.log('in if detail');
            this.showAckBtn = 1;
          } else {
            this.showAckBtn = 0;
            console.log('in else detail');
          }
//           if (licenseIsAck == true /* && acknowledgeByMe == false */ && assignToMe == true  /* && licenseIsTechEntry == true */ ) {
//             console.log('in if detail');
//             this.normalAckButtonShow = true;
//           } else {
//             this.normalAckButtonShow = false;
//             console.log('in else detail');
//           }

//           if ( licenseIsAck == true && acknowledgeByMe == false && assignToMe == true && (technicianId == null || technicianId == 0) && licenseIsTechEntry == true && runTimeStatus == 1 ) {
//             console.log('in if detail showAckErcBtn');
//             this.showAckErcBtn = 1;
//           } else {
//             this.showAckErcBtn = 0;
//             console.log('in else detail showAckErcBtn');
//           }

//           if ( licenseIsAck == true /* && assignToMe == true */ && (technicianId == null || technicianId == 0) && runTimeStatus == 1 ) {
//             this.showERC = 1;
//           } else {
//             this.showERC = 0;
//           }

//           let obj = {
//             id: null,
//             type: null
//           }
//           let pVal = 0;
//           if (eventData.isParent == false) {
//             pVal = 0;
//           } else if (eventData.isParent == true) {
//             pVal = 1;
//           }
//           obj.type = eventData.notificationType;
//           if (eventData.notificationType == "Notification") {
//             obj.id = eventData.notificationId;
//           } else if (eventData.notificationType == "RunTime") {
//             obj.id = eventData.runTimeEventId;
//           }
//           if (this.eventListType == 'approval' || this.eventListType == 'approved') {
//             obj.type = 'RunTime';
//             obj.id = eventData.runTimeEventId;
//             this.performAction = 1;
//             eventData.licenseIsTechEntry = true;
//           }

//           // this.router.navigate(["eventDetails", eventData.assetId,], 
//           //   {
//           //     queryParams: 
//           //     { 
//           //       type: obj.type, 
//           //       id :obj.id, 
//           //       rcpId:eventData.runTimeCollectionPointId, 
//           //       notificationId : eventData.notificationId, 
//           //       isParent : pVal, 
//           //       performAction : this.performAction, 
//           //       showAckBtn : this.showAckBtn,
//           //       showAckErcBtn : this.showAckErcBtn,
//           //       showERC : this.showERC,
//           //       licenseIsTechEntry : eventData.licenseIsTechEntry, 
//           //       eventListType : this.eventListType,
//           //       assignToMe : assignToMe == true ? 1 : 0,
//           //       isAck : isAck == true ? 1 : 0
//           //     }
//           // });
// // *********TODO***********TODO*****************TODO****************TODO*******************TODO*********************TODO*******************TODO*******TODO******TODO
//           // this.eventType = obj.type//params['type'];//==================================================take from url params
//           // this.notificationId = eventData.notificationId//params['notificationId'];
//           // this.showAckBtn = params['showAckBtn']; 
//           // this.showAckErcBtn = params['showAckErcBtn'];
//           // this.showERC = params['showERC'];
//           this.runTimeEventId = eventData.runTimeEventId//obj.id//params['id'];
//           this.rcpId = eventData.runTimeCollectionPointId//params['rcpId'];
//           this.performAction = this.performAction//params['performAction'];  
//           this.notficationType = this.eventType//obj.type//params['type'];
//           this.licenseIsTechEntry = eventData.licenseIsTechEntry//params['licenseIsTechEntry'];
//           this.isParent = pVal //params['isParent'];
//           // this.eventListType = params['eventListType'];
//           this.assignToMe = assignToMe == true ? 1 : 0//params['assignToMe'];
//           this.isAckTag = isAck == true ? 1 : 0//params['isAck']; //===================================------------------------  change when available in api response


//           // this.assignedContactListData = data.responseList;

//           console.log(this.notficationType, "runTimeEventId::",this.runTimeEventId,this.eventListType,"performAction::",this.performAction,".showAckBtn :: ", this.showAckBtn );
          
        } else {
          // this.assignedContactListData =[];
          /* this.alertService.sweetMessage('error',data.responseMessage); */
        }

        if (flag) {
          if (this.eventType == "Notification") {
            // if (this.eventListType == 'liveFeeds') {
            //   this.getInstructionsInfo();
            // }
            // this.getRunTimeEventDetails();
            this.getWffEventDetails();
            this.getWffEventByID();
          } else if (this.eventType == "RunTime") {
            //this.getRunTimeEvenListForreasonCode();

            this.getRunTimeEventDetails();
            this.getMemberResponsibility();
            // if (this.eventListType == 'liveFeeds') {
            //   this.getInstructionsInfo();
            // }
          }
        }

      },
      error => {
        this.alertService.sweetMessage('error', "Something went wrong, please try again ");
      }
    );
  }

  // // To format date
  // DateFormat(startDate, gmtOffset){
  //     return this.formatDateService.formatDate(startDate, gmtOffset);
  // }

  DateFormat(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    // console.log(d, startDate,d1, moment(d1).format("MM-DD-YYYY HH:mm:ss"));
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
      // return this.formatDateService.formatDate(startDate, gmtOffset);
  }

  changeCheckBox(event, roleup){
    // console.log('start', event, roleup, this.eventIdArr, this.rollUpdataList);
      // adding First fault in reason code if first fault is on 
      let firstFaultStatus = $('#location').prop("checked");       
      let startTimeConverted = roleup.startTimeConverted;
      if(startTimeConverted != '' || startTimeConverted != null) {
          let tempArr = [];
          this.eventIdArr.map((val,key)=>{
            tempArr.push(val.runTimeEventId); 
          }); 

          let rolupIndex = _.findIndex(this.rollUpdataList, { 'runTimeEventId': roleup.runTimeEventId });   
          // console.log('rolupIndex', rolupIndex, rolupIndex + 1);
          
          this.rollUpdataList.map((val,key)=>{ 
            // console.log('rollupo 1');
            if(key > rolupIndex) {
              $('#select_'+key).prop('checked', false); 
              this.rollUpdataList[key].select = false;
            } 
          });
          // setTimeout(1000);
          // this.eventIdArr = [];
          this.total_duration = 0;
          this.rollUpdataList.map((val,key)=>{    
            // console.log('rollupo 2'); 
            let dd = val.duration;
            let selectVal = val.select;
            if(selectVal) {
              this.total_duration = this.total_duration  + dd;                
            }
          });
          

          // eventIdArr : Child event ids and check whether new id is present in that array or not
          let index = _.findIndex(this.rollUpdataList, { 'runTimeEventId': roleup.runTimeEventId });
          // console.log('index rollUpdataList', index, index - 1);
          
          // remaining time for adding responsibility
          let rt = this.remaining_mins;
          // total duration at first will be the sum of child runtime that are in detail api (that already selected)
          if (event.target.checked) {
            if(firstFaultStatus) {
              this.pushDataInResonCode(roleup);  
            }   
            // this.eventIdArr.push({ 'runTimeEventId': roleup.runTimeEventId });
            // normal date of selected rollup Event 
            let nrml_dt = this.rollUpdataList[index].startTimeConverted ? (this.DateFormat(this.rollUpdataList[index].startTimeConverted, '')+' '+this.rollUpdataList[index].stz) : '--';
            // duration for particular rollup event
            let duration = roleup.duration;
            // plus checked duration frm total
            // this.total_duration = this.total_duration + duration;
            // this.remaining_mins = this.remaining_mins + duration;
            // console.log('nrml_dt ', nrml_dt);              
            $('#nrml_dt').html(nrml_dt);

            if(roleup.startTimeConverted == null){
              this.remaining_mins = this.total_duration;
              this.sumOfDowntime = 0;
              this.closeActive = 0;
              this.listMemberIdArr = [];
              this.responsibilityArr = [];
              this.runTimeEventResponsibilities = [];
              $('.update_close').attr('disabled', true);
              $('.update_close').attr('id', 'cursor-not-allowed');
              $('.add_resp_btn').attr('disabled', true);
              $('.add_resp_btn').attr('id', 'cursor-not-allowed');
            }
          }else{   
            // console.log('roleup.startTimeConverted', roleup.startTimeConverted);
            // this.eventIdArr.splice(index,1);
            // console.log('index else', index - 1);
            // normal date of selected rollup Event at one prvious index
            let nrml_dt_prev = this.rollUpdataList[index - 1].startTimeConverted ? (this.DateFormat(this.rollUpdataList[index - 1].startTimeConverted, '')+' '+this.rollUpdataList[index - 1].stz) : '';
            // duration for particular rollup event
            let duration = roleup.duration;
            // minus unchecked duration frm total
            // this.total_duration = this.total_duration - duration;
            $('#nrml_dt').html(nrml_dt_prev);          
            if(rt != 0){ 
              // this.remaining_mins = this.remaining_mins - duration;            
            } else {
              // remaining time = 0 : responsibilities 0
              this.sumOfDowntime = 0;
              this.closeActive = 0;
              this.listMemberIdArr = [];
              this.responsibilityArr = [];
              this.runTimeEventResponsibilities = [];
              this.remaining_mins = this.total_duration;
              $('.update_close').attr('disabled', true);
              $('.update_close').attr('id', 'cursor-not-allowed');
            } 

            if(roleup.startTimeConverted == null){
              // console.log('in 2 null condition');
              $('.add_resp_btn').removeAttr('disabled');
              $('.add_resp_btn').removeClass('disabled');
              $('.add_resp_btn').attr('id', '');
              this.remaining_mins = this.total_duration;
              this.sumOfDowntime = 0;
              this.closeActive = 0;
              this.listMemberIdArr = [];
              this.responsibilityArr = [];
              this.runTimeEventResponsibilities = [];
              $('.update_close').attr('disabled', true);
              $('.update_close').attr('id', 'cursor-not-allowed');
            }
              
          }

          this.remaining_mins = this.total_duration - this.sumOfDowntime;
          // $('#remaining_mins').text(this.remaining_mins);
          
          if(this.remaining_mins < 0){
            this.remaining_mins = this.total_duration;
            this.sumOfDowntime = 0;
            this.closeActive = 0;
            this.listMemberIdArr = [];
            this.responsibilityArr = [];
            this.runTimeEventResponsibilities = [];
            $('.update_close').attr('disabled', true);
            $('.update_close').attr('id', 'cursor-not-allowed');
          }
          // Changing duration and remaining time dynamically wrt to rollup select deselect
          // $('#duration').html(this.total_duration);
          // $('#remaining_mins').html(this.remaining_mins);
          let objValue = roleup.firstFaultCodeDesc;
          if (roleup.firstFaultCodeDesc) {
            var exist = this.firstFaultResponsibilityArr.indexOf(roleup.firstFaultCodeDesc.toString());
            if (exist == '-1') {
              this.firstFaultResponsibilityArr.push(objValue);
            }
          }
          // console.log('sumOfDowntime', this.sumOfDowntime, this.remaining_mins, this.total_duration);

          
          
      }
      // console.log('this.eventIdArr last', this.eventIdArr);
      
  }

  // Add New Technician
  addTechnician() {
    let newTechnicianId = $('#newTechnician option:selected').val();
    let newTechnicianName = $('#newTechnician option:selected').text();
  }

  // Add New Reason Code Api
  addNewReasonCode() {
    let reasonCode = $('#addNewReasonCode').val();
    this.addReasonCodeData.name = reasonCode;
    this.apiService.create('reasonCode/createReasonCode', this.addReasonCodeData).subscribe(
        data => {
            if (data.statusCode == 100) {
              let reasonCode = $('#addNewReasonCode').val();
              if(reasonCode) {
                $('#reason_code_err').css('display', 'none');
                var exist = this.reasonListData.indexOf(reasonCode.toString());
                if (exist == '-1') {                    
                  let nameObj = {
                    'name' : reasonCode,
                    reasonCodeId:data.response.reasonCodeId
                  }
                  if (!this.UseFirstFault) {
                    this.reasonListData.push(nameObj);
                  }
                  // this.reasonListData.push(nameObj);
                }
              } else {
                $('#reason_code_err').css('display', 'block');
              }
              $('#addNewReasonCode').val('');
              $('#reason_code_exist_err').css('display', 'none');
              this.alertService.sweetMessage('success', 'Added successfully');
            } else {
              $('#reason_code_exist_err').html(data.responseMessage);
              $('#reason_code_err').css('display', 'none');
              $('#reason_code_exist_err').css('display', 'block');
            }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }

  getAssignedContactsList(){
    this.apiService.getAll('asset/getAssetContact?assetId=10147418').subscribe(
      data => {
        if(data.statusCode == 100){
          this.assignedContactListData = data.responseList;
        }else{
          this.assignedContactListData =[];
         /* this.alertService.sweetMessage('error',data.responseMessage); */
        }
      },
      error =>{
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // get asset detail for wff nitification 
  getWffEventDetails() {
    this.apiService.getAll('wffnotification/getAssetDetailByWffNotificationId?wffNotificationId='+this.notificationId+'&clientId='+this.clientId).subscribe(
        data => {
          // if(data.statusCode == 100){
          if(data){      
          // console.log('getWffEventDetails', data)        ;
            this.wffEventDetailData = data.response;
          }else{
            this.wffEventDetailData = [];
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }

  // Instruction tab data
  getInstructionsInfo(){  
    // if ($.fn.DataTable.isDataTable('#instruction_table')) {
    //   $('#instruction_table').DataTable().destroy();
    //   this.assignedContactListData = [];
    // };    
    // this.apiService.getAll('currrentEscalation/getAssignedContactByWffNotification?wffNotificationId='+this.notificationId).subscribe(
      this.apiService.getAll('wffnotification/getEscalationNotificationByEvent?wffNotificationId='+this.notificationId).subscribe(
        data => {
          if(data.statusCode == 100){
            this.chRef.detectChanges();
            if ($.fn.DataTable.isDataTable('#instruction_table')) {
              $('#instruction_table').DataTable().destroy();
            };
            //console.log('currrentEscalation', data);
            //this.assetsumaryData= data.response;
            this.assignedContactListData = data.responseList;
            this.emailMsg = data.emailMsg;
            this.sMSMsg = data.sMSMsg;
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#instruction_table');
              this.dataTable = table.DataTable();
            }, 500);             
          }else{
            this.assignedContactListData = [];
            const table: any = $('#instruction_table');
            this.dataTable = table.DataTable();
              //this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }

  getAssetDetail(){
      this.apiService.getAll('asset/getAssetSummaryById?clientId='+this.clientId+'&userId='+this.userId+'&assetId='+this.assetId).subscribe(
          data => {
            if(data.statusCode == 100){
                this.assetsumaryData= data.response;
            }else{
                this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  removeResponsibility(i, listMemberId, downtime) {
    // console.log('brfore remove', listMemberId, this.runTimeEventResponsibilities);
    this.responsibilityArr.splice(i, 1);
    this.runTimeEventResponsibilities.splice(i, 1);
    // console.log('index', jQuery.inArray(listMemberId, this.runTimeEventResponsibilities));
    let listMemberIdIndex = jQuery.inArray(listMemberId, this.listMemberIdArr);
    this.listMemberIdArr.splice(jQuery.inArray(listMemberId, this.listMemberIdArr), 1);
    // console.log('after remove', listMemberId, this.responsibilityArr);
    this.remaining_mins = this.remaining_mins + parseInt(downtime);
    // console.log('responsibility downtime remove', this.remaining_mins);
    // $('#remaining_mins').html(this.remaining_mins);
    this.sumOfDowntime = this.sumOfDowntime - downtime;
    $('.update_close').attr('id', 'cursor-not-allowed');
    $('.update_close').attr('disabled', true);
  }

  addResponsibility () {
    if(this.remaining_mins != 0 || this.responsibilityArr.length >= 0){
      // console.log('remainingTimeToAllocate', this.remainingTimeToAllocate, this.responsibilityArr);
      let listMemberId = $('#listMemberId').val();
      let responsibility = $('#listMemberId option:selected').text();
      let downtime = $('#downtime').val();
      let downtimeExp = new RegExp("^[0-9]{1,6}$");//regular expression for non decimal positive downtime
      let isValidDownTime = downtimeExp.test(downtime);//check downtime is valid 
      console.log(downtime);
      
      // console.log('initial 1', this.sumOfDowntime);
      
      // console.log('initial 2', this.sumOfDowntime);
      if(listMemberId == '' && (downtime == '' || downtime == 0) ) {
        $('#responsibility_err').css('display', 'block');
        $('#downtime_err').css('display', 'block');
        return;
      } else {
        $('#responsibility_err').css('display', 'none');
        $('#downtime_err').css('display', 'none');
      }

      if (downtime == '' || downtime == 0) {
        $('#downtime_valid_err').css('display', 'block');return;
      }else{
        $('#downtime_valid_err').css('display', 'none');
      }

      if(listMemberId == '' && !isValidDownTime) {
        $('#responsibility_err').css('display', 'block');
        $('#downtime_valid_err').css('display', 'block');
        return;
      } else {
        $('#responsibility_err').css('display', 'none');
        $('#downtime_valid_err').css('display', 'none');
      }

      if(listMemberId == '') {
        $('#responsibility_err').css('display', 'block');
        return;
      } else {
        $('#responsibility_err').css('display', 'none');
      }

      if(downtime == '') {
        $('#downtime_err').css('display', 'block');
        return;
      } else {
        $('#downtime_err').css('display', 'none');
      }
      if (!isValidDownTime) {
        $('#downtime_valid_err').css('display', 'block');return;
      }else{
        $('#downtime_valid_err').css('display', 'none');
      }
       // console.log('this.sumOfDowntime', this.sumOfDowntime, downtime);
       let tempSum = this.sumOfDowntime + parseInt(downtime);
       // console.log('calculations', tempSum, this.total_duration, this.remaining_mins + 1, tempSum > this.remaining_mins + 1 );
      if(tempSum > this.total_duration ) {
        //this.sumOfDowntime = this.sumOfDowntime - parseInt(downtime);
        if(this.remaining_mins == 0) {
          // console.log('remaining 0');
          $('#res_err').html('All responsibilities are already filled !!');  
        } else {
          console.log('downtime > remaining', tempSum, this.remaining_mins, downtime, this.sumOfDowntime,this.total_duration)
          $('#res_err').html('Downtime must be less than remaining time !!');  
        }          
        $('#res_err').css('display', 'block');  
      } else {
        let obj = {
          'listMemberId' : listMemberId,
          'name' : responsibility,
          'duration' : downtime
        };

        if(jQuery.inArray(listMemberId.toString(), this.listMemberIdArr) == -1) {
          this.sumOfDowntime = this.sumOfDowntime + parseInt(downtime);
          this.listMemberIdArr.push(listMemberId); 
          this.responsibilityArr.push(obj); 

          this.totalDowntime = this.totalDowntime + downtime;

          this.remaining_mins = this.remaining_mins - downtime;
          // if(this.remaining_mins == 0 && (this.rollUpdataList.length == this.eventIdArr.length)) {
          if(this.remaining_mins == 0) {
            this.closeActive = 1;
            $('.update_close').removeAttr('disabled');
            $('.update_close').removeClass('disabled');
            $('.update_close').attr('id', '');
          } else {
            this.closeActive = 0;
            $('.update_close').attr('disabled', true);
            $('.update_close').attr('id', 'cursor-not-allowed');
          }
          // $('#remaining_mins').html(this.remaining_mins);

          let runtimeEventObj = {
              'runTimeEventRcpId' : this.rcpId,
              responsibilityListMember : {
                  'listMemberId' : listMemberId,    
              },            
              'downtime' : downtime
          };
          
          this.runTimeEventResponsibilities.push(runtimeEventObj);  
          $('#listMemberId').val('');  
          $('#downtime').val('');    
          $('#res_err').css('display', 'none');
          setTimeout(function(){
            $('.resb_action_td').css('display', 'table-cell');  
          }, 100);            
          // console.log('listMemberIdArr', this.listMemberIdArr);            
        } else {
          //this.sumOfDowntime = this.sumOfDowntime - parseInt(downtime);
          $('#res_err').html('Responsibility Already Exist');
          $('#res_err').css('display', 'block');
        }          
      }  
      this.closeActive = 0;     
    } else {
      this.closeActive = 1;
      $('#res_err').html('All responsibilities are filled, check above details and close this event.');
      $('#res_err').css('display', 'block');
    }
    if(this.remaining_mins == 0) {
      this.closeActive = 1;
      $('.update_close').removeAttr('disabled');
      $('.update_close').removeClass('disabled');
      $('.update_close').attr('id', '');
    } else {
      this.closeActive = 0;
      $('.update_close').attr('disabled', true);
      $('.update_close').attr('id', 'cursor-not-allowed');
    }
  }

  // Getting Reason Code
  getRunTimeEvenListForreasonCode(flag) {
    // console.log('in getRunTimeEvenListForreasonCode', flag);
      this.apiService.getAll('reasonCode/getReasonCodeList?clientId=' + this.clientId).subscribe(
          data => {
            if (data.statusCode == 100) {
              let objArr =  data.responseList;
              objArr.map((val,key)=>{
                if(val.reasonCodeId != 0) {
                  this.reasonListData.push(val);
                } 
              }); 
              console.log("-----------------------------");
              
                if(flag == 1) { 
                  this.reasonListData = []
                    this.tempArr = []
                    console.log("=========================1", this.runTimeObj);
                    
                  if(this.runTimeObj.reasonCode != null){
                    console.log("this.runTimeObj.reasonCode != null");
                    
                    if((data.responseList[0].reasonCodeId == this.runTimeObj.reasonCode.reasonCodeId) && (this.runTimeObj.reasonCodeName != null)) {
                         // console.log('in if get reason codee');
                        this.firstFaultCodeSelected = 1;
                        this.UseFirstFault = true
                        //if(this.runTimeObj.childRunTimeEvents.length > 0) {
                          this.reasonListData = [];
                          //console.log('is child');
                          let reasonCodeNameDetail = this.runTimeObj.reasonCodeName;
                          let index = _.findIndex(this.runTimeObj.childRunTimeEvents, { 'firstFaultCodeDesc': reasonCodeNameDetail });
                          // console.log('index reason code', this.eventIdArr, this.firstFaultResponsibilityArr);
                          //if(index != -1) {
                            // console.log('this.runTimeObj.childRunTimeEvents', this.runTimeObj.childRunTimeEvents);
                            // this.runTimeObj.childRunTimeEvents.map((valC, keyC)=>{
                            this.firstFaultResponsibilityArr.map((valC, keyC)=>{  
                              // console.log('valC, keyC', this.runTimeObj.reasonCodeName, valC);
                              let selected = 0;
                              if(this.runTimeObj.reasonCodeName == valC) {
                                selected = 1;
                                this.reasonCodeId = (this.eventIdArr.length > 0) ? this.eventIdArr[keyC].runTimeEventId : 0

                              }
                              let nameObj = {
                                'reasonCodeId'  : (this.eventIdArr.length > 0) ? this.eventIdArr[keyC].runTimeEventId : 0,
                                'name' : valC,
                                'selectt' : selected
                                //'select' : (this.runTimeObj.reasonCode.name == valC.firstFaultCodeDesc) ? 1 : 0
                              }
                              let check = _.findIndex(this.tempArr, { 'name': valC.firstFaultCodeDesc });
                              if(check == -1) {
                                this.tempArr.push(nameObj);  
                              }                            
                            });
                            this.reasonListData = this.tempArr; 
                            console.log('reasonListData', this.reasonListData);
                          //}
                        //}
                    } else {
                      this.reasonListData = [];
                      console.log('in else get reason codee');
                      this.firstFaultCodeSelected = 0;
                      this.UseFirstFault = false;
                      // this.reasonListData = data.responseList;
                      let objArr =  data.responseList;
                      objArr.map((val,key)=>{
                        if(val.reasonCodeId != 0) {
                          this.reasonListData.push(val);
                        } 
                      }); 
                    }  
                  }else{
                    // this.firstFaultCodeSelected = 0;
                    // this.UseFirstFault = false;
                    this.reasonListData = data.responseList;
                  }                  
              }
              

            } else {
              console.log("-----------------------------------------2");
              
                this.reasonListData = [];
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  getUnassignedTechnicianList() {
    this.apiService.getAll('user/getUnAssignedTechnician?clientId=' + this.clientId + '&assetId=' + this.assetId+"&runTimeEventId="+this.runTimeEventId).subscribe(
          data => {
              if (data.statusCode == 100) {
                // console.log('unassignedTechnicianList', data);
                  this.unassignedTechnicianList = data.userList;
                  // console.log('this.runTimeObj', this.runTimeObj);
                  // jQuery.inArray(listMemberId, this.listMemberIdArr)
                  var realArray = $.makeArray( this.runTimeObj.users );
                  // let userIdArr = [];
                  realArray.map((val,key)=>{
                    this.userIdArr.push(val.userId);
                  });
                  // console.log('userIdArr', this.userIdArr);
                  this.unassignedTechnicianList.map((val,key)=>{
                    //console.log(val,key, jQuery.inArray(val.userId, userIdArr));
                    if((jQuery.inArray(parseInt(val.userId), this.userIdArr)) != -1){                        
                      this.unassignedTechnicianList[key].select = true;
                      this.newTechnicianId = val.userId;
                    } else {
                      this.unassignedTechnicianList[key].select = false;
                    }
                  });

                  let eventDetailUsers = this.runTimeObj.users;
                  // console.log('unass eventDetailUsers', eventDetailUsers);
                  let mapArr = [];
                  eventDetailUsers.map((val,key)=>{                      
                    // console.log(eventDetailUsers[key].userId);
                    let i = _.findIndex(this.unassignedTechnicianList, { 'userId': eventDetailUsers[key].userId });
                    // console.log('i', i);
                    if(i != -1) {
                      let obj = {
                        'userId' : eventDetailUsers[key].userId,
                        'userName' : eventDetailUsers[key].userName 
                      };
                      mapArr.push(obj);
                    }
                  });   
                  this.unassignedSelectedTechnicians = mapArr;
                  // console.log('unassignedSelectedTechnicians', this.unassignedSelectedTechnicians);  

                  // this.technicianList.map((val,key)=>{
                  //   if((jQuery.inArray(parseInt(val.userId), this.userIdArr)) != -1){                        
                  //     this.technicianList[key].select = true;
                  //     this.technicianId = val.userId;
                  //   } else {
                  //     this.technicianList[key].select = false;
                  //   }
                  // });
                   // console.log('unassignedTechnicianList skect', this.unassignedTechnicianList);
                  //this.temptechnicianList = data.userList;
              } else {}
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
  }

  /* Get primary Assigned Technicians list */
  getPrimaryTechnicianList() {
    this.apiService.getAll('user/getRunTimeEventTechnician?clientId=' + this.clientId + '&assetId=' + this.assetId +'&assetRoleId=1'/* +"&runTimeEventId="+this.runTimeEventId */).subscribe(
        data => {
            if (data.statusCode == 100) {
              this.primaryTechnicianList = data.userList;                
            } else {
              this.primaryTechnicianList = [];
            }
        },
        error =>{
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
         }
    );
}

  /* Get TechnicianList list */
  getTechnicianList() {
      this.apiService.getAll('user/getRunTimeEventTechnician?clientId=' + this.clientId + '&assetId=' + this.assetId).subscribe(
          data => {
              if (data.statusCode == 100) {
                this.viewtechnicianList = []
                  this.technicianList = data.userList;
                  // console.log('technicianList', this.technicianList);
                  let eventDetailUsers = this.runTimeObj.users;
                  // console.log('t eventDetailUsers', eventDetailUsers);
                  let mapArr = [];
                  eventDetailUsers.map((val,key)=>{                      
                    // console.log(eventDetailUsers[key].userId);
                    if (eventDetailUsers[key].userId == 0) {
                      let obj = {
                        'userId' : eventDetailUsers[key].userId,
                        'userName' : eventDetailUsers[key].userName 
                      };
                      this.viewtechnicianList.push(obj);
                      // mapArr.push(obj);                      
                    }else{
                      let i = _.findIndex(this.technicianList, { 'userId': eventDetailUsers[key].userId });
                    // console.log('i', i);
                      if(i != -1) {
                        let obj = {
                          'userId' : eventDetailUsers[key].userId,
                          'userName' : eventDetailUsers[key].userName 
                        };
                        this.viewtechnicianList.push(obj);
                        mapArr.push(obj);
                      }
                    }
                    console.log(mapArr);
                    
                  });  
                  this.selectedTechnicians = mapArr;
                  // console.log('selectedTechnicians', this.selectedTechnicians);  

                  //this.temptechnicianList = data.userList;
              } else {}
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
  }

   /* Get TechnicianList list */
  getMemberResponsibility() {
      // this.apiService.getAll('listMember/getListMamberResponsibility?runTimeEventId=' + this.runTimeEventId + '&assetId=' + this.assetId).subscribe(
      this.apiService.getAll('listMember/getListMamberResponsibility?runTimeEventId=' + this.runTimeEventId + '&assetId='+this.assetId).subscribe(
          data => {
              if (data.statusCode == 100) {
                  this.responsbilityList = data.responseList;                     
                  // console.log('test', data, this.responsbilityList);
                  //this.temptechnicianList = data.userList;
              } else {}
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
           }
      );
  }


  getRunTimeEventDetails() {   
    this.responsibilityArr = [];
    this.runTimeEventResponsibilities = [];   
      this.apiService.getAll('runTimeEvent/runTimeEventDetail?runTimeEventId=' +this.runTimeEventId).subscribe(
          data => {
            if(data.statusCode == 100){
              this.runTimeEventResponsibilities = [];
              this.responsibilityArr = [];
              this.sumOfDowntime = 0;
              this.totalDowntime = 0;           
              //console.log('chold runtime events', data.response.childRunTimeEvents.length);
              this.runTimeObj = data.response;
              this.getUnassignedTechnicianList();
              this.getTechnicianList();
              this.getPrimaryTechnicianList();
              this.getRollUpData();
              this.getRunTimeEvenListForreasonCode(1);
              this.standBy = this.runTimeObj.standBy;              
              if(this.runTimeObj.childRunTimeEvents.length > 0) {
                //console.log('data found', this.runTimeObj.childRunTimeEvents.length - 1, this.runTimeObj.childRunTimeEvents[this.runTimeObj.childRunTimeEvents.length - 1].firstFaultCodeDesc);
                this.childEventFirstFaultCode = this.runTimeObj.childRunTimeEvents[this.runTimeObj.childRunTimeEvents.length - 1].firstFaultCodeDesc;
                this.isChildEventsInDetail = true;
              } else {
                this.isChildEventsInDetail = false;
              }

              let evntResponsilities = this.runTimeObj.runTimeEventResponsibilities;
              //console.log('evntResponsilities', evntResponsilities, evntResponsilities.length);
              if(evntResponsilities.length > 0) {
                  evntResponsilities.map((val,key)=>{
                    let downtime = parseInt(evntResponsilities[key].downtime);
                    this.totalDowntime = this.totalDowntime + downtime;
                    this.remaining_mins = this.remaining_mins - parseInt(evntResponsilities[key].downtime);
                  });
                  // to show total if responsibilities are there
                  this.sumOfDowntime = this.totalDowntime;
              }
              // $('.remaining_mins').html(this.remaining_mins);
              let status =  this.runTimeObj.status;
              this.eventStatus = status;
              
              let eventDetailUsers = data.response.users;                
              if(this.performAction == 0) {                  
                  eventDetailUsers.map((val,key)=>{
                    if(eventDetailUsers[key].userId == this.userId) {
                      this.performAction = 1;
                      console.log('found', this.userId);         
                    }                      
                  });         
              } else {
                // console.log('already found');
              }
              if(this.runTimeObj.firstFaultCodeDesc!=null){
                var exist = this.firstFaultResponsibilityArr.indexOf(this.runTimeObj.firstFaultCodeDesc.toString());
                if (exist == '-1') {                    
                    this.firstFaultResponsibilityArr.push(this.runTimeObj.firstFaultCodeDesc);                    
                }
              }
              this.comment = decodeURIComponent(this.runTimeObj.comment ? this.runTimeObj.comment : '');
              this.runTimeObj.runTimeEventResponsibilities.forEach(key=> {
                  let listMemberId = key.responsibilityListMember.listMemberId;
                  let obj = {
                    'listMemberId' : listMemberId,
                    'name' : key.responsibilityName,
                    'duration' : key.downtime
                  };                    
                  this.responsibilityArr.push(obj);   
                  this.listMemberIdArr.push(listMemberId.toString());

                  let runtimeEventObj = {
                      'runTimeEventRcpId' : this.rcpId,
                      responsibilityListMember : {
                          'listMemberId' : listMemberId,    
                      },            
                      'downtime' : key.downtime
                  };
                  // console.log('runtimeEventObj', runtimeEventObj);
                  this.runTimeEventResponsibilities.push(runtimeEventObj);
              }); 
              // console.log('this.listMemberIdArr', this.listMemberIdArr);

              if(this.runTimeObj.reasonCode){
                this.reasonCodeId = this.runTimeObj.reasonCode.reasonCodeId;
              }
              if(this.runTimeObj.users[0]){                   
                this.technicianId = this.runTimeObj.users[0].userId;
              }
              // console.log('get responsibility', this.runTimeEventResponsibilities);

            }else{
              this.runTimeObj = [];
            }
            console.log(this.responsibilityArr ,"--------responsibilityArr");
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  getWffEventByID(){
      // wffnotification//getEventDetailByWffNotificationId?wffNotificationId=9740208&clientId=5
      this.apiService.getAll('wffnotification/getEventDetailByWffNotificationId?wffNotificationId=' +this.runTimeEventId + '&clientId='+this.clientId).subscribe(
          data => {
            if(data.statusCode == 100){
             this.runTimeWffEventObj = data.response;
             this.showAckTime = this.runTimeWffEventObj.ackTime != 'null' ? this.runTimeWffEventObj.ackTime : '';
            }else{
              this.runTimeEventObj = data.response;
              const table: any = $('#recentStatus');

              // this.dataTable = table.DataTable();

            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  assignOwnerTechnician() {
    let primaryTechId = $('#primaryTechId option:selected').val();
    console.log('primaryTechId', primaryTechId);
    this.assignOwnerTechObj = {
      "runTimeEventId" : this.runTimeEventId,
      "technician" : { 
          userId : primaryTechId
      },
      "isApproved" : true
    };

    this.apiService.update('runTimeEvent/assignOwnerTechnician', this.assignOwnerTechObj).subscribe(
        data => {
          if (data.statusCode == 100) {       
            console.log('in ode');
            this.mode = 'edit';
            this.showERC = 0;
            this.showAckBtn = 0;
            this.showAckErcBtn = 0;
            this.performAction = 1;
            this.runTimeStatus = 2;             
            this.eventStatus = 2;
            this.getEventByID(true)      
          }
          else
          {                
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error =>
        { 
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
    );
  }
 
  // re-open event functionality
  reopenEvent() {
    Swal({
      title: 'Are you sure?',
      text: "You want to Re-Open !!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        let reopenEventRequest = {
          "runTimeEventId" : this.runTimeEventId,
          "status" : 2
        }
        let URL = 'runTimeEvent/updateRunTimeEventStatus';
        this.apiService.update(URL, reopenEventRequest).subscribe(
              data => {
                if (data.statusCode == 100) {
                   this.alertService.sweetMessage('success', 'Re-Opened Successfully');
                   this.router.navigate(["callcenter/call-center-dashboard"]);
                }
                else
                {                
                  this.alertService.sweetMessage('error', data.responseMessage);
                }
              },
              error =>
              { 
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );   
      }         
    }) 
  }

  // Update, Update and Close & Approval functionality
  updateEventDetail(btnStatus) {
    this.ReasonCodeError = null
    if (btnStatus == 2) {
      console.log(this.reasonCodeId, "+++++++++++++++++++++++++++++++");
      
      if (!this.reasonCodeId) {
        this.ReasonCodeError = "please select reason code";
        return;
      }
    }
    Swal({
      title: 'Are you sure?',
      text: "You want update !!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {

        let URL = 'runTimeEvent/updateRunTimeEvents?userId='+this.userId;

        let flag = $('#location').prop("checked");
        // console.log('eventStatus', this.eventStatus);
        // btnStatus = 1 : Update, 2: Update and close, 3: Approval, 4 : Re-Open
        let status = 1;
        if (btnStatus == 1) {
            status = 2;
          } else if (btnStatus == 2) {
            status = 3;
            //this.updateruntimeObj.isClosed = true;
          } else if (btnStatus == 3) {
            status = 3;
            URL = 'runTimeEvent/editRollUpEventDetailApprover?userId='+this.userId;
            //this.updateruntimeObj.isApproved = true;
          }
          // parent event or not flag 
          let isParent = this.isParent;            
          // Adding technicians
          this.selectedTechnicians.map((valC, keyC)=>{
               let techObj = {
                  userId : valC.userId
               };
               this.usersArr.push(techObj);      
          });

          this.unassignedSelectedTechnicians.map((valC, keyC)=>{
               let unTechObj = {
                  userId : valC.userId
               };
               this.usersArr.push(unTechObj);      
          });
          // console.log('user array', this.usersArr);
          let userList = [];
          this.usersArr.map((obj,index)=>{
            let i = _.findIndex(userList, { 'userId': obj.userId });
            console.log(i);
            if (i == -1) {
              userList.push(obj)
            }
            
          }) 
          let standByresponsibility = [];
              if (this.standBy) {
    
                this.runTimeObj.runTimeEventResponsibilities.forEach(key=> {
                  let listMemberId = key.responsibilityListMember.listMemberId;
                  let obj = {
                    'listMemberId' : listMemberId,
                    'name' : key.responsibilityName,
                    'duration' : key.downtime
                  };                    
                  // this.responsibilityArr.push(obj);   
                  // this.listMemberIdArr.push(listMemberId.toString());
        
                  let runtimeEventObj = {
                      'runTimeEventRcpId' : this.rcpId,
                      responsibilityListMember : {
                          'listMemberId' : listMemberId,    
                      },            
                      'downtime' : key.downtime
                  };
                  // console.log('runtimeEventObj', runtimeEventObj);
                  standByresponsibility.push(runtimeEventObj);
                }); 
                
              }
          // if(this.technicianId) {
          //   let obj = {
          //     userId : this.technicianId
          //   };
          //   this.usersArr.push(obj);  
          // }            
          // // Adding Unassigned technicians
          // if(this.newTechnicianId) {
          //   let obj1 = {
          //     userId : this.newTechnicianId
          //   };
          //   this.usersArr.push(obj1);  
          // }             
          
          if(btnStatus == 3) {
            this.updateruntimeObj = {
              "runTimeEventId": this.rcpId ,
              "reasonCode": {
                reasonCodeId : this.reasonCodeId
              },
              "users": userList,
              "runTimeEventResponsibilities" : this.standBy ? standByresponsibility : this.runTimeEventResponsibilities,
              "comment" : encodeURIComponent(this.comment),
              "status" : status,
              "isApproved" : true,
              standBy:this.standBy
            };
          } else if(btnStatus == 2) {
            this.updateruntimeObj = {
              "runTimeEventId": this.rcpId ,
              "reasonCode": {
                reasonCodeId : this.reasonCodeId
              },
              "users": userList,
              "runTimeEventResponsibilities" : this.standBy ? standByresponsibility : this.runTimeEventResponsibilities,
              "comment" : encodeURIComponent(this.comment),
              "status" : status,
              "isClosed" : true,
              standBy:this.standBy
            };
          } else {
            this.updateruntimeObj = {
              "runTimeEventId": this.rcpId ,
              "reasonCode": {
                reasonCodeId : this.reasonCodeId
              },
              "users": userList,
              "runTimeEventResponsibilities" : this.standBy ? standByresponsibility : this.runTimeEventResponsibilities,
              "comment" : encodeURIComponent(this.comment),
              "status" : status,
              standBy:this.standBy
            };
          }

          this.currClientId = localStorage.getItem("userId");

          if(this.UseFirstFault) {
            let reasonCodeId = $('#reasonCodeId option:selected').val();
            if(reasonCodeId > 0) {
              this.updateruntimeObj.firstFaultRunTimeEventId = this.reasonCodeId;
            } else {
              this.updateruntimeObj.firstFaultRunTimeEventId = null;
            }
            this.updateruntimeObj.reasonCode = null;
              
          }

          // console.log('this.rollUpdataList', this.rollUpdataList);
          this.eventIdArr = []; 
          if(this.rollUpdataList.length > 0) {
            this.rollUpdataList.map((val,key)=>{    
              // console.log('rollupo 3');
              let selectVal = val.select;                
              if(selectVal) {
                let index = _.findIndex(this.eventIdArr, { 'runTimeEventId': val.runTimeEventId });
                if(index == -1) {
                  this.eventIdArr.push({ 'runTimeEventId': val.runTimeEventId });  
                }                  
              }
            });
          }
          // console.log('update final event arr', this.eventIdArr);
          

          if(this.isParent == false){
            // console.log('in false');
            this.updateruntimeObj.runTimeEventId     = null;
            this.updateruntimeObj.childRunTimeEvents = this.eventIdArr;
          }else{
            this.updateruntimeObj.runTimeEventId     = this.runTimeEventId ;
            this.updateruntimeObj.childRunTimeEvents = this.eventIdArr;
          }
          
          console.log('final', this.updateruntimeObj, "resion code is", this.reasonCodeId);
          
          this.apiService.update(URL, this.updateruntimeObj).subscribe(
              data => {
                if (data.statusCode == 100) {
                   this.alertService.sweetMessage('success', 'Updated Successfully');
                   if (btnStatus == 3) {
                    this.router.navigate(["approvedlist"]); 
                   } else {
                    this.router.navigate(["callcenter/call-center-dashboard"]); 
                   }                 
                }
                else
                {                
                  this.alertService.sweetMessage('error', data.responseMessage);
                }
              },
              error =>
              { 
                this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
              }
          );
      }
    })
    
  }

  rollUpEventSelect(rollupData) {
      let objValue = rollupData.firstFaultCodeDesc;
      if (rollupData.firstFaultCodeDesc) {
        var exist = this.firstFaultResponsibilityArr.indexOf(rollupData.firstFaultCodeDesc.toString());
        if (exist == '-1') {
            this.firstFaultResponsibilityArr.push(objValue);
        }
      }
  }

  getRollUpData(){

    /*                                         // --------------------------------------------------TO-DO check flag
    let str = '';
        if(this.eventListType == 'liveFeeds') { 
          str += '';
        } 
        else if(this.eventListType == 'approval') {
          str += '&isApproved=true';
        }
        else if(this.eventListType == 'approved') {
          str += '&isApproved=true';
        }
    
    */
      this.apiService.getAll('runTimeEvent/rollUpRunTimeEvents?clientId='+this.clientId+'&userId='+this.userId+'&parentRunTimeEventId='+this.runTimeEventId+'&rcpId='+this.rcpId).subscribe(
          data => {
            if(data.statusCode == 100){
              this.rollUpdataList = data.responseList ? data.responseList : [];
              //if(this.mode == 'edit') {
                this.total_duration = 0;
                this.eventIdArr = [];
                if(this.runTimeObj.childRunTimeEvents.length == this.rollUpdataList.length) {
                  //this.closeActive = 1;
                }

              //}
              // console.log('rollup child', this.runTimeObj.childRunTimeEvents.length, this.runTimeObj.childRunTimeEvents);
              this.rollUpdataList.map((val, key)=>{                  
                if(!this.isChildEventsInDetail) { 
                  $('.rollupSection').css('display', 'none');
                  $('.noRollups').css('display', 'contents');
                  if(this.runTimeEventId == this.rollUpdataList[key].runTimeEventId){
                      this.rollUpdataList[0].select = true;
                      this.rollUpList = this.rollUpdataList[0];                                          
                      this.eventIdArr.push({ 'runTimeEventId': this.rollUpdataList[0].runTimeEventId });
                  }
                  // console.log('no runtime', this.eventIdArr);
                  this.childEventFirstFaultCode = this.rollUpdataList[0].firstFaultCodeDesc;                    
                  this.total_duration = this.remaining_mins = this.runTimeObj.duration;
                } else {
                  // console.log('this.mode', this.mode);
                  this.runTimeObj.childRunTimeEvents.map((valC, keyC)=>{
                    if(this.runTimeObj.childRunTimeEvents[keyC].runTimeEventId == this.rollUpdataList[key].runTimeEventId) {                        
                      // console.log('is if child events', key);
                      // console.log('before', this.total_duration);
                      this.rollUpdataList[key].select = true;
                      this.rollUpdataList[key].showHideFlag = true;
                      this.total_duration = this.total_duration + valC.duration;
                      this.remaining_mins = this.remaining_mins + valC.duration;
                      // console.log('after', this.total_duration);
                      let html = this.rollUpdataList[key].startTimeConverted ? (this.DateFormat(this.rollUpdataList[key].startTimeConverted, '')+' '+this.rollUpdataList[key].stz) : '--';
                      $('.nrml_dt').html(html);
                      //  $('.nrml_dt').html(this.rollUpdataList[key].startTimeConverted+' '+this.rollUpdataList[key].stz);
                      this.rollUpList = this.rollUpdataList[key]; 
                      // console.log('length', this.runTimeObj.childRunTimeEvents.length, this.eventIdArr.length);                                         
                      this.eventIdArr.push({ 'runTimeEventId': this.runTimeObj.childRunTimeEvents[keyC].runTimeEventId });
                      if (this.runTimeObj.childRunTimeEvents[keyC].firstFaultCodeDesc) {
                        var exist = this.firstFaultResponsibilityArr.indexOf(this.runTimeObj.childRunTimeEvents[keyC].firstFaultCodeDesc.toString());
                       
                       if (exist == '-1') {                    
                           this.firstFaultResponsibilityArr.push(this.runTimeObj.childRunTimeEvents[keyC].firstFaultCodeDesc.toString());                    
                       }
                      }
                    }
                  });
                  //this.eventIdArr.push({ 'runTimeEventId': this.rollUpdataList[key].runTimeEventId });                    
                }
              }); 
              // console.log('get 1', this.eventIdArr);
              this.remaining_mins = this.total_duration - this.sumOfDowntime;
              // console.log('final rollup', this.rollUpdataList);
              // $('.duration').html(this.total_duration ? this.total_duration : 0);
              // $('.remaining_mins').html(this.remaining_mins); 

              if(this.mode == 'edit') {
                $('.rollupSection').css('display', 'contents');
                  $('.noRollups').css('display', 'none');
                this.rollUpdataList.map((val, key)=>{
                  this.rollUpdataList[key].showHideFlag = true;
                });
              }
            }else{
              this.rollUpdataList = [];
            }
            this.getRunTimeEvenListForreasonCode(1);
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
      );
  }

  // function to switch between edit and view mode of runctime details
  editViewRuntime(mode) {
    this.mode = mode;      
    if(mode == 'ack') {
      // console.log('ack', this.runTimeEventId);
      this.acknowledge(this.runTimeEventId, this.eventType);
    } else if(mode == 'ackErc') {
      // console.log('ackErc', this.runTimeEventId);
      this.acknowledge(this.runTimeEventId, this.eventType);
      this.assignOwnerTechnician();
    } else if(mode == 'erc') {
      // console.log('erc', this.runTimeEventId);
      this.assignOwnerTechnician();
    } else if(mode == 'edit') { 
      this.getRollUpData();
      // console.log('eventStatus', this.eventStatus);
      // $('#view_btn').css('display', 'inline-block');
      this.EditButtonShow = false
      // $('#edit_btn').css('display', 'none');
      // $('.add_responsibility_section').css('display', 'flex');
      this.ShowResponsibilitySection = false;
      this.ShowResponsibilitySection = false;    
      // $('#view_btn').css('display', 'inline-block');
      // $('#edit_btn').css('display', 'none');
      $('.add_responsibility_section').css('display', 'flex');
      $('.resb_action_th').css('display', 'table-cell');
      $('.resb_action_td').css('display', 'table-cell');
      $('.add_new_reason_code_section').css('display', 'flex');
      $('.unassigned_technician_section').css('display', 'flex');
      $('.technician_edit_mode').css('display', 'block');
      $('.technician_view_mode').css('display', 'none');
      //$('.firstFaultBlock').css('display', 'block');
      // console.log('in edit', this.total_duration, this.sumOfDowntime,  this.remaining_mins, this.total_duration == this.sumOfDowntime )
      if((this.total_duration == this.sumOfDowntime && this.remaining_mins == 0) || this.standBy) {
        this.closeActive = 1;
        $('.update_close').removeAttr('disabled');
        $('.update_close').removeClass('disabled');
        $('.update_close').attr('id', '');
      }
    } else if(mode == 'view')  {
      this.getRollUpData();        
      // $('#view_btn').css('display', 'none');
        // $('#edit_btn').css('display', 'inline-block');
        this.EditButtonShow = true
        this.ShowResponsibilitySection = true;
        // $('.add_responsibility_section').css('display', 'none');
      $('.resb_action_th').css('display', 'none');
      $('.resb_action_td').css('display', 'none');
      $('.add_new_reason_code_section').css('display', 'none');
      $('.unassigned_technician_section').css('display', 'none');
      $('.technician_edit_mode').css('display', 'none');
      $('.technician_view_mode').css('display', 'block');
      //$('.firstFaultBlock').css('display', 'none');
    } else {
      // $('#edit_btn').css('display', 'inline-block');
      // $('#view_btn').css('display', 'none');
      $('.add_responsibility_section').css('display', 'none');
      $('.resb_action_th').css('display', 'none');
      $('.resb_action_td').css('display', 'none');
      $('.add_new_reason_code_section').css('display', 'none');
      $('.unassigned_technician_section').css('display', 'none');
      $('.technician_edit_mode').css('display', 'none');
      $('.technician_view_mode').css('display', 'block');
      //$('.firstFaultBlock').css('display', 'none');
    }
  }

  // acknowledment functionality 
  acknowledge(wffid, notificationType){
    Swal({
      title: 'Are you sure?',
      text: "You want to Acknowledge this event.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#28a745',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Acknowledge!'
    }).then((result) => {
      if (result.value) {
        if(notificationType === 'Notification'){
          this.ackData.wffNotification.wffNotificationId = wffid;
        }else if (notificationType === 'RunTime') {
          this.runtimeAckData.runtimeEvent.runTimeEventId = wffid;
          this.ackData = this.runtimeAckData;
        }
        this.apiService.create('wffnotification/createEventHsitory',this.ackData).subscribe(
          data => {
            if(data.statusCode == 100){                
              console.log('in ack mode');                
               this.showERC = 1;
               this.showAckBtn = 0;
               this.showAckErcBtn = 0;
               console.log('changed params ack', this.mode, this.showAckErcBtn, this.showERC, this.showAckBtn);                 

                console.log('test', this.eventType, this.runTimeEventId, this.notificationId, this.isParent, this.performAction, this.showAckBtn, this.showAckErcBtn, this.licenseIsTechEntry, this.eventListType)

                this.router.navigate(["eventDetails", this.assetId,], 
                {
                  queryParams: 
                  { 
                    type: this.eventType, 
                    id : this.runTimeEventId, 
                    rcpId : this.rcpId, 
                    notificationId : this.notificationId, 
                    isParent : this.isParent, 
                    performAction : this.performAction, 
                    showAckBtn : this.showAckBtn,
                    showAckErcBtn : this.showAckErcBtn,
                    showERC : this.showERC,
                    licenseIsTechEntry : this.licenseIsTechEntry, 
                    eventListType : this.eventListType,
                    assignToMe : this.assignToMe,
                    isAck : this.isAckTag
                  }
              });
              this.getRunTimeEventDetails();
              this.getWffEventByID();
              // if(notificationType == 'Notification') {
              //   $('#acknowledged_btn').css('display', 'block');
              // }                
              this.alertService.sweetMessage('success','Acknowledged Successfully');                            
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error =>{
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
             }
          );


      }
    })
  }

  // Click on first fault code
  firstFaultClick() {
      this.pushDataInResonCode(this.runTimeObj);
      this.reasonCodeId = null;
  }

  // Adding Reason code
  pushDataInResonCode(data){
    // console.log('pushDataInResonCode', data);
    let flag = $('#location').prop("checked");
    var _this = this;
    this.firstFaultResponsibilityArr.forEach(function(element) {
        _this.testArr.push(element);
    });
    // console.log(this.testArr, jQuery.inArray(data.firstFaultCodeDesc.toString(), this.testArr));
    if(data.firstFaultCodeDesc != null) { 
      if(jQuery.inArray(data.firstFaultCodeDesc.toString(), this.testArr) < 0) {
        _this.firstFaultResponsibilityArr.push(data.firstFaultCodeDesc);
      }
    }
    

    if(flag) {
      // console.log('firstFaultResponsibilityArr', this.firstFaultResponsibilityArr, this.rollUpdataList);        
      let objArr =  this.firstFaultResponsibilityArr;
      this.tempArr = [];
      objArr.map((val, key)=>{
        let getIndex = _.findIndex(this.rollUpdataList, { 'firstFaultCodeDesc': val });
        // console.log('getIndex', getIndex, this.rollUpdataList[getIndex].runTimeEventId);
        let getRuntimeEventId = this.rollUpdataList[getIndex].runTimeEventId;
        let nameObj = {
          'reasonCodeId'  : getRuntimeEventId,
          'name' : val 
        }
        this.tempArr.push(nameObj);
      }); 
      this.reasonListData = this.tempArr;
      if (this.reasonListData.length > 0) {
        this.reasonCodeId = this.reasonListData[0].reasonCodeId
        setTimeout(() => {
          this.reasonCodeId = this.reasonListData[0].reasonCodeId
          console.log(this.reasonCodeId);
            
          }, 2000);
      }else{
        this.ReasonCodeError = 'First fault code not available, please select reason code.'
        setTimeout(() => {
          this.UseFirstFault = false
          // this.firstFaultCodeSelected = 0;
          this.reasonListData = [];
          this.getRunTimeEvenListForreasonCode(0);
        }, 1000);

      }
      console.log(this.reasonCodeId);
      setTimeout(() => {
      console.log(this.reasonCodeId);
        
      }, 2000);
  
    } else {  
      this.reasonListData = [];
      this.getRunTimeEvenListForreasonCode(0);
    }
  }

  UserClick(user){

    this.valueChange.emit(user)
  }

  changeReasonCode(){
    this.ReasonCodeError = null;
  }

    changeStandBy(event){
      console.log(event.target.checked, '---standBY');
      if (event.target.checked) {
        this.closeActive = 1
        $('.update_close').removeAttr('disabled');
        $('.update_close').removeAttr('id');
        $('.update_close').removeClass('disabled');
      } else {
        this.closeActive = 0;
        $('.update_close').attr('disabled', true);
        $('.update_close').attr('id', 'cursor-not-allowed');
        // this.runTimeObj.runTimeEventResponsibilities.forEach(key=> {
        //   let listMemberId = key.responsibilityListMember.listMemberId;
        //   let obj = {
        //     'listMemberId' : listMemberId,
        //     'name' : key.responsibilityName,
        //     'duration' : key.downtime
        //   };                    
        //   this.responsibilityArr.push(obj);   
        //   // this.listMemberIdArr.push(listMemberId.toString());

        //   // let runtimeEventObj = {
        //   //     'runTimeEventRcpId' : this.rcpId,
        //   //     responsibilityListMember : {
        //   //         'listMemberId' : listMemberId,    
        //   //     },            
        //   //     'downtime' : key.downtime
        //   // };
        //   // // console.log('runtimeEventObj', runtimeEventObj);
        //   // this.runTimeEventResponsibilities.push(runtimeEventObj);
        // }); 
      }
      
    } 
}
