import { Component, OnInit ,ChangeDetectorRef,ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService , AlertService, CommonServicesService}from '../../../../../services/index';
import Swal from 'sweetalert2'
import { Subscription, Subject } from 'rxjs';
import {componentDestroyed} from "@w11k/ngx-componentdestroyed";
// import * as $ from 'jquery'
import * as _ from "lodash";
import 'datatables.net';
declare var jQuery : any;
declare var $ : any;
declare var d3:any,moment:any;
import 'datatables.net-bs4';

@Component({
  selector: 'app-call-center-managment',
  templateUrl: './call-center-managment.component.html',
  styleUrls: ['./call-center-managment.component.css']
})
export class CallCenterManagmentComponent implements OnInit {

message: any;
subscription: any = new Subject();
clientFilterId : any = '';
  HistoryList: any = [];

constructor(
  private router:Router,
  private route: ActivatedRoute,
  private apiService: ApiService ,
  private chRef: ChangeDetectorRef,
  private alertService: AlertService,
  private CommonServicesService: CommonServicesService ) { 
    // subscribe to header component sendClientId to get Client Id
    this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
    .subscribe(
      message => {
        this.clientFilterId = message.id;
        this.getCallCenterlist();
        setTimeout(() => {
          this.clientName = localStorage.getItem("clientName");
        }, 1000);
      }
    );
  }

  ngOnDestroy() {
    // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.subscription.next()
    this.subscription.complete()
    // if ($.fn.DataTable.isDataTable('#lsit_dataTable')){
    //   $('#lsit_dataTable').DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 5 }]}).destroy();
    //   this.callDataList = [];
    // };
  }

  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tiersId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  newUserId : any = 0;
  clientDataList:any = [];
  clientIds:any= []
  callDataList : any = [];
  dataTable :any;
  clientName : any = localStorage.getItem("clientName");

  ngOnInit() {
    console.log("clientName--->",this.clientName);
    // this.getCallCenterlist();
  }

  // Get Call Center List
  getCallCenterlist(){
    if ($.fn.DataTable.isDataTable('#lsit_dataTable')){
      $('#lsit_dataTable').DataTable().destroy();
      this.callDataList = [];
    };    
    
    var URL = 'callcenter/getCallCenterList?userId='+this.userId;
    if(this.clientFilterId && this.clientFilterId !='' && this.clientFilterId != 0) {
      var params = '&clientId='+this.clientFilterId;
      URL = URL.concat(params);
    }else{
      URL = URL;
    }

    // this.apiService.getAll('callcenter/getCallCenterList?userId='+this.userId).subscribe(
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){          
          if ($.fn.DataTable.isDataTable('#lsit_dataTable')){
            $('#lsit_dataTable').DataTable().destroy();
            this.callDataList = [];
          };
          this.callDataList = data.response;
          // this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#lsit_dataTable');
            this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 5 }]});
          }, 700);
          // this.chRef.detectChanges();
          // const table: any = $('#lsit_dataTable');
          // this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 5 }]});
        }else{
          this.callDataList = [];
          // this.chRef.detectChanges();
          // const table: any = $('#lsit_dataTable');
          // this.dataTable = table.DataTable({"order": [],"columnDefs": [{ "orderable": false, "targets": 5 }]});
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#lsit_dataTable');
            this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 5 }]});
          }, 700);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  deleteCallCenter(id){
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('callcenter/deleteCallCenter?callCenterId='+id, '').subscribe(
          data => {
            if(data.statusCode == 100){
              this.getCallCenterlist();
              this.alertService.sweetMessage('success','Call Center deleted successfully');
            }else{
              this.alertService.sweetMessage('error',data.responseMessage);
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }


  getHistory(id){
    this.HistoryList = []
    if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
      $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
    };
        this.apiService.getAll('callcenter/getCallCenterHistoryBycallCenterId?callCenterId=' + id).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.HistoryList = data.responseList;
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
            } else {
              setTimeout(() => {
                const table: any = $('#HistoryDatatable');
                let dataTable = table.DataTable({ "order": [], "destroy": true });
              }, 500);
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 1500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        ); 
  }

  DateFormat(dateStr){
    if(dateStr){
        // return moment(dateStr).format("YYYY-MM-DD hh:mm:ss");
        var dateTime = new Date(dateStr);
        return moment(dateTime).format("YYYY-MM-DD HH:MM:SS");
    }else{
        return "-";
    }
  }

  DateFormatEvent(startDate, gmtOffset){
    let d1  = new Date(startDate)
    var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
    
    if(startDate){
      var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
      return formattedDate;
    }else{
      return "";
    }
  }

}
