import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './structure/login/login.component';
import { ForgotPasswordComponent } from './structure/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './structure/reset-password/reset-password.component';
import { EventAckComponent } from './structure/event-ack/event-ack.component';
import { UserActiveComponent } from './structure/user-activation/user-activation.component';

// import { CallInstructionComponent } from './structure/admin/section/Call-Center-User/call-instruction/call-instruction.component';

import { CallInstructionEmailComponent } from './structure/call-instruction-email/call-instruction-email.component';
import { DashboardClassicComponent } from './structure/Classic/dashboard-classic.component';
import { TransferSiteComponent } from './structure/Classic/transfer-site/transfer-site.component';
import { CompanyComponent } from './structure/Classic/company/company.component';
import { AssetComponent } from './structure/Classic/asset/asset.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  { path: 'event-ack', component: EventAckComponent },
  { path: 'user-activation', component: UserActiveComponent },
  { path: 'user-activation/:id', component: UserActiveComponent },
  { path: 'call-instruction-email/call-instruction/:id', component: CallInstructionEmailComponent },
  { path: 'dashboard-classic', component: DashboardClassicComponent },
  { path: 'transfer-site/:assetId/:companyId', component: TransferSiteComponent },
  { path: 'company/:companyId', component: CompanyComponent },
  { path: 'asset/:assetId', component: AssetComponent },
  { path: 'event-ack/?k=:kid/e:eid', component: EventAckComponent },
  { path: '**', redirectTo: '/login' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
