import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService ,AlertService} from '../../services/index';
import * as $ from 'jquery';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.css']
})
export class UserActiveComponent implements OnInit {

token:any="";
accessToken:any={"accessToken":""}
msg:any="";
loginPageContentData:any;
imageFile:any;

constructor(private router:Router, private route: ActivatedRoute, private apiService: ApiService, private alertService: AlertService) { }

  	ngOnInit() {
  		// Getting Login Data
  		this.getLoginData();
		$(document).ready(function () {
			// CSS
		    $("body").css({"padding-top": "0px"});
	    });
	    this.route.params.subscribe(params => {
		    if(params['id']){
		      this.token = params['id'];
		      this.resetPassword();
		    }
	  	});
	}

	// Reset Password
	resetPassword(){
		if(!this.token){
			this.alertService.sweetMessage('error',"Invalid token.");
		}else{
			this.accessToken = {"accessToken":this.token};
			this.apiService.userActivate('user/userActivation', this.accessToken).subscribe(
				data => {
					if(data.statusCode == 100 || data.statusCode == 103){
						this.msg = data.responseMessage
					}else{
						this.alertService.sweetMessage('error',data.responseMessage);
					}
				},
				error => {
					this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
				}
			);
		}
   	}

   	// Getting Login Data
 	
      // Getting Login Data
	  getLoginData(){
		var url = location.host//this.apiService.appUrl;
		  this.apiService.getLoginDatawithoutToken('client/getClientByDomain?domain='+url).subscribe(
			data => {
			if(data.statusCode == 100){
			 this.loginPageContentData=data.response.loginPageContent;
			 this.imageFile = data.response.appLogoPath;
			 let innerLogo = data.response.parentOrgInnerLogoPath;
	
			 // Loader Image for client
			 let loaderImg = data.response.loaderImg;
			 localStorage.setItem("loaderImg", loaderImg);
			 localStorage.setItem("logoImage", this.apiService.imageBasePath+innerLogo);
			 console.log(this.imageFile);
			 
			 if(document.getElementById('imageData')){
				if (this.imageFile) {
					document.getElementById('imageData').setAttribute( 'src', this.apiService.imageBasePath+this.imageFile );
				}else{
					document.getElementById('imageData').setAttribute( 'src', 'assets/img/default_logo.png' );
				}
			 }
		 }else{
			localStorage.removeItem("loaderImg");
			document.getElementById('imageData').setAttribute( 'src', 'assets/img/default_logo.png' );
		 }
			  },
			  error => {
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			  }
		  );
		}
}
