import { Component, OnInit,ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService , AlertService}from '../../../../services/index';
import Swal from 'sweetalert2'
import * as $ from 'jquery'
import 'datatables.net';
import 'datatables.net-bs4';

@Component({
  selector: 'app-notification-event',
  templateUrl: './notification-event.component.html',
  styleUrls: ['./notification-event.component.css']
})
export class NotificationEventComponent implements OnInit {
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  customerId:any =(localStorage.getItem("customerId")?JSON.parse(localStorage.getItem("customerId")):[]);
  tierId:any =(localStorage.getItem("tierId")?JSON.parse(localStorage.getItem("tierId")):[]);
  eventDatalist : any = [];
  dataTable: any;
  dropdownSettingsSite = {};
  dropdownSettingsState = {};
  dropdownSettingsStatus = {};
  search:any='';
  site:any='';
  county:any='';
  status:any='';
  checkSiteArray:any=[];
  checkStateArray:any=[];
  checkStatusArray:any=[];
  runTimeData:any=[];
  alarmlist:any=[];
  countryId:number=0;
  state:any='';
  countryList:any=[];
  countyList:any=[];
  stateList:any=[];
  siteList:any=[];
  statusList=[{"status":"Alarm","statusName":"Alarm"},{"status":"Close","statusName":"Close"},{"status":"In Progress","statusName":"In Progress"},{"status":"Normal","statusName":"Normal"},{"status":"Open","statusName":"Open"},{"status":"Offline","statusName":"Offline"},{"status":"Suspended","statusName":"Suspended"},{"status":"Warning","statusName":"Warning"}];
  clientName : any = localStorage.getItem("clientName");
  
constructor(private router:Router, private apiService: ApiService , private alertService: AlertService , private chRef: ChangeDetectorRef) { }

  ngOnInit() {
  	this.getAllNotificationEventList();
    this.getCountryList();
    this.getSiteManagementList();
    this.getCountyList();
    this.getAllAlarmList();
    this.getRunTimeEventData();
    
    this.dropdownSettingsSite = {
      singleSelection: false,
      idField: 'siteId',
      textField: 'siteName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.dropdownSettingsState = {
      singleSelection: false,
      idField: 'stateId',
      textField: 'stateName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'status',
      textField: 'statusName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
  }
/* On Item Select Status */
onItemSelectStatus(item: any) {
    var exist = this.checkStatusArray.indexOf(item);
    if(exist == '-1'){
        this.checkStatusArray.push(item);    
      }  
  }

/* On Item DeSelect Status */
onItemDeselectStatus(item: any){
  var exist = this.checkStatusArray.indexOf(item);
    if(exist != '-1'){
        this.checkStatusArray.splice(exist,1);
      }  
}
/* On All Select Status */
onSelectAllStatus(items: any) {
    this.checkStatusArray = [];
  Object.keys(items).forEach(key=> {
      this.checkStatusArray.push(items[key].toString());   
    });
}
/* On All DeSelect Status */
onDeSelectAllStatus(){
  this.checkStatusArray = [];
}

/* On Item Select State */
onItemSelectState(item: any) {
    var exist = this.checkStateArray.indexOf(item.stateId.toString());
    if(exist == '-1'){
        this.checkStateArray.push(item.stateId.toString());    
      }  
  }

/* On Item DeSelect State */
onItemDeselectState(item: any){
  var exist = this.checkStateArray.indexOf(item.stateId.toString());
    if(exist != '-1'){
        this.checkStateArray.splice(exist,1);
      }  
}
/* On All Select State */
onSelectAllState(items: any) {
  this.checkStateArray = [];
  Object.keys(items).forEach(key=> {
      this.checkStateArray.push(items[key]['stateId'].toString());   
    });
}
/* On All DeSelect State */
onDeSelectAllState(){
  this.checkStateArray = [];
}
/* On Item Select Site */
onItemSelectSite(item: any) {
    var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if(exist == '-1'){
        this.checkSiteArray.push(item.siteId.toString());    
      }  
  }

/* On Item DeSelect Site */
onItemDeselectSite(item: any){
  var exist = this.checkSiteArray.indexOf(item.siteId.toString());
    if(exist != '-1'){
        this.checkSiteArray.splice(exist,1);
      }  
}
/* On All Select Site */
onSelectAllSite(items: any) {
  this.checkSiteArray = [];
  Object.keys(items).forEach(key=> {
      this.checkSiteArray.push(items[key]['siteId'].toString());   
    });
}
/* On All DeSelect Site */
onDeSelectAllSite(){
  this.checkSiteArray = [];
}

/* On Search */
seachRecord(){
  this.site     = this.checkSiteArray;
  this.state  = this.checkStateArray;
  this.status  = this.checkStatusArray;
  this.getAllNotificationEventList();
}
/* Reset Search Record */
resetSearch(){
  this.search = "";
  this.site = "";
  this.county="";
  this.status="";
  this.countryId=0;
  this.state="";
  this.status = "";
  this.checkSiteArray=[];
  this.checkStateArray=[];
  this.checkStatusArray=[];
  this.getAllNotificationEventList();
  
}

/* Get Site Management list */
getSiteManagementList(){
  var URL = 'site/getSiteByClient?clientId='+this.clientId+'&userId='+this.userId;
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.siteList = data.responseList;
        }else{
          this.siteList = [];
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
}

/* Get state list by country id */
getStateListByCountryId(countryId){
  if(countryId != 0){
  this.apiService.getAll('state/getStateList?countryId='+countryId).subscribe(
   data => {
       if(data.statusCode == 100){
          this.stateList = data.responseList;          
        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
     error => {
      this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
  }
}

/* Get Country list */
getCountryList(){
   this.apiService.getAll('country/getCountry').subscribe(
   data => {
       if(data.statusCode == 100){
          this.countryList = data.responseList;          
        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
      error => {
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }

 /* Function for getting All Notification EventList */
  getAllNotificationEventList(){
    //asset/getLatestLiveFeed?clientId=1&userId=2&countryId=1&stateId=1,1036&siteId=297,336&tierId=10,9&search=20097&status=Open,Closed,Alarm&county=countySearch
   var URL = 'asset/getLatestLiveFeed?clientId='+this.clientId+'&userId='+this.userId;  
  if(this.countryId != 0){
    var countryString  = '&countryId='+this.countryId;
    var URL = URL.concat(countryString);
  }
  if(this.state != ''){
    var stateString    = '&stateId='+this.state;
    var URL = URL.concat(stateString);
  } 
  if(this.site !=''){
    var siteString     = '&siteId='+this.site;
    var URL = URL.concat(siteString);
  }
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
  if(this.search !=''){
    var searchString   = '&search='+this.search;
    var URL = URL.concat(searchString);
  }
  if(this.status != ''){
    var statusString    = '&status='+this.status;
    var URL = URL.concat(statusString);
  }
  if(this.county !=''){
    var countyString   = '&county='+this.county;
    var URL = URL.concat(countyString);
  }
    this.apiService.getAll(URL).subscribe(
      data => {
        if(data.statusCode == 100){
          this.eventDatalist = data.responseList;  
          this.chRef.detectChanges(); 
          const table: any = $('#data_table');
          this.dataTable = table.DataTable();       
        }else{
           this.eventDatalist = [];
           this.chRef.detectChanges(); 
            const table: any = $('#data_table');
            this.dataTable = table.DataTable();
          /*Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })*/
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  /* Function for getting Alarm State data */
  getAllAlarmList(){
    // var URL ='asset/getAssetByClient?clientId='+this.clientId+'&userId='+this.userId+'&status=a';
    var URL = 'asset/getAssetByClient';
    let reqObj:any = {
      "clientId": this.clientId,
      userId:this.userId,
      status:'a'
     }
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
    reqObj.customerIds = this.customerId.toString();
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
    reqObj.tierIds = this.tierId.toString();
  }
    this.apiService.create(URL, reqObj).subscribe(
      data => {
        if(data.statusCode == 100){
          this.alarmlist = data.responseList;
          this.chRef.detectChanges(); 
          const table: any = $('#status_table');
          this.dataTable = table.DataTable();       
        }else{
           this.alarmlist = [];
           this.chRef.detectChanges(); 
           const table: any = $('#status_table');
           this.dataTable = table.DataTable();       
          /*Swal({
            position: 'top-end',
            type: 'error',
            title: data.responseMessage,
            showConfirmButton: false,
            timer: 1500
          })*/
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

/* Get Run Time Normal State*/
getRunTimeEventData(){
  var URL = 'runTimeEvent/runTimeEventList?clientId='+this.clientId+'&userId='+this.userId+'&status=3&startlimit=0&endlimit=100';
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
  this.apiService.getAll(URL).subscribe(
      data => {
          if(data.statusCode == 100){
              this.runTimeData = data.responseList; 
              this.chRef.detectChanges(); 
              const table: any = $('#runTime_table');
              this.dataTable = table.DataTable();                      
             }else{
              this.runTimeData = [];
              this.chRef.detectChanges(); 
              const table: any = $('#runTime_table');
              this.dataTable = table.DataTable();                      
          }
      },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");          
      }
    );
  }
  /* Get CountyList list */
getCountyList(){
  var URL = 'site/getSiteCountyByClient?clientId='+this.clientId+'&userId='+this.userId;
  if(this.customerId != null && this.customerId !=''){
    var customerString     = '&customerId='+this.customerId.toString();
    var URL = URL.concat(customerString);
  }
  if(this.tierId != null && this.tierId !=''){
    var tierString     = '&tierId='+this.tierId.toString();
    var URL = URL.concat(tierString);
  }
   this.apiService.getAll(URL).subscribe(
   data => {
       if(data.statusCode == 100){
          this.countyList = data.responseList;          
        }else{
          Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
         }
      },
      error => {
				this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
			}
      );
      }

}
