import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AlertService, CommonServicesService } from '../../../../services/index';
import Swal from 'sweetalert2'
import { Subscription, Subject } from 'rxjs';

import * as _ from 'lodash';
// import $ from 'jquery';
// import 'datatables.net';
// import 'datatables.net-bs4';
// declare var $:any,jquery:any;
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";

declare var $: any;
declare var jQuery: any;
declare var d3: any, moment: any;
import 'datatables.net';
import 'datatables.net-bs4';




@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})

export class ClientComponent implements OnInit, OnDestroy {
  clientUser: any = JSON.parse(localStorage.getItem("clientUser"));
  clientId: any = localStorage.getItem("clientId");
  userId: any = localStorage.getItem("userId");
  clientList: any = [];
  dataTable: any;
  imagePath: any;
  isLive: any = '';
  isDeactivated: any = '';
  clientdetailId: any = 0;
  selectedItems: any = [];
  filterArray: any = [];
  assetArray: any = { 'assets': [] };
  filterValue: any = 'All';
  dropdownList: any = [
    { item_id: 1, item_text: 'All' },
    { item_id: 2, item_text: 'Active' },
    { item_id: 3, item_text: 'InActive' },
    { item_id: 4, item_text: 'IsLive' },
    { item_id: 5, item_text: 'NotLive' }
  ];

  clientUserList: any = [];
  clientAssetList: any = [];

  dropdownSettings: any = {
    singleSelection: false,
    idField: 'tierId',
    textField: 'tierName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 0,
    allowSearchFilter: true
  };

  MsgBoxError: any = 'Email has been sent to below mentioned Users';

  message: any;
  subscription: any = new Subject();
  clientFilterId: any = '';
  blankArray: any = [];
  clientGoLive: any =
    {
      "clientId": "",
      "isLive": true,
      "asset": null
    }
  clientName: any = localStorage.getItem("clientName");
  imageUrl: any = "assets/img/user_icon.png";

  tempAssetList: any = [];
  selectAllCheckBox: boolean = false;
  HistoryList: any = [];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef,
    private CommonServicesService: CommonServicesService
  ) {
    // subscribe to header component sendClientId to get Client Id
    // this.subscription = this.CommonServicesService.getClientId().takeUntil(componentDestroyed(this))
      // .subscribe(
        // message => {
          // this.clientFilterId = message.id;
          // console.log(this.clientFilterId);

          // setTimeout(() => {
          //   this.clientName = localStorage.getItem("clientName");
          // }, 1000);
          // this.getClientList();
      //   }
      // );
  }


  ngOnDestroy() {
    // clear the header filter when user leave the page
    this.subscription = this.CommonServicesService.clearClientFilter().subscribe();
    // unsubscribe to ensure no memory leaks
    // console.log(typeof(this.subscription), this.subscription);

    this.subscription.unsubscribe();
    // this.subscription = null;
    this.subscription.next()
    this.subscription.complete()
    // this.subscription.remove(this.subscription);
    if ($.fn.DataTable.isDataTable('#data_table')) {
      $('#data_table').DataTable().destroy();
      this.clientList = [];
    };
  }


  ngOnInit() {
    // console.log('local client name', localStorage.getItem("clientName"));
    // this.getClientList();
    this.imagePath = this.apiService.imageBasePath;
    localStorage.removeItem('clientIsLive');
    this.getClientList();
  }

  getTierbyClient(clientId) {
    this.apiService.getAll("tier/getTiersByClient?clientId=" + clientId).subscribe(
      data => {
        // this.clientList = [];
        if (data.statusCode == 100) {
          this.dropdownList = data.responseList;
          this.selectedItems = JSON.parse(JSON.stringify(this.dropdownList))
          // let arr = [];
          // this.dropdownList.map((obj,index)=>{
          //   let tempObj = {
          //     tierId:
          //   }
          // })
          console.log(data, "data=====================")
        } else {
          this.dropdownList = [];
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );

  }
  /* Getting Client list */
  getClientList() {
    // console.log(this.clientFilterId)
    if ($.fn.DataTable.isDataTable('#data_table_client_list')) {
      $('#data_table_client_list').DataTable().destroy();
      this.clientList = [];
    };
    this.clientList = [];

    var URL = 'client/getClientList';
    // console.log(this.clientFilterId)
    if (this.clientFilterId && this.clientFilterId != '' && this.clientFilterId != 0) {
      var params = '?clientId=' + this.clientFilterId;
      URL = URL.concat(params);

      this.apiService.getAll(URL).subscribe(
        data => {
          // this.clientList = [];
          if (data.statusCode == 100) {
            this.clientList = data.responseList;
            // this.chRef.detectChanges();
            if ($.fn.DataTable.isDataTable('#data_table_client_list')) {
              $('#data_table_client_list').DataTable().destroy();
            };
            setTimeout(() => {
            const table: any = $('#data_table_client_list');
            this.dataTable = table.DataTable({ "order": [], "destroy": true, "columnDefs": [{ "orderable": false, "targets": 0 }, { "orderable": false, "targets": 7 }, { "orderable": false, "targets": 8 }, { "orderable": false, "targets": 9 }] });
            }, 1000);
          } else {
            this.clientList = [];
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_client_list');
              this.dataTable = table.DataTable({ "order": [], "destroy": true, "columnDefs": [{ "orderable": false, "targets": 0 }, { "orderable": false, "targets": 7 }, { "orderable": false, "targets": 8 }, { "orderable": false, "targets": 9 }] });
            }, 1000);
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
    } else {
      if ($.fn.DataTable.isDataTable('#data_table_client_list')) {
        $('#data_table_client_list').DataTable().destroy();
      };
      this.clientList = [];

      this.apiService.getAll(URL).subscribe(
        data => {
          if (data.statusCode == 100) {
            // let dataobj = JSON.parse(JSON.stringify(data.responseList))
            this.clientList = data.responseList;
            // console.log("else:::::::::::", new Date(), data.responseList);            
            // this.chRef.detectChanges();
            console.log($.fn.DataTable.isDataTable('#data_table_client_list'));

            if ($.fn.DataTable.isDataTable('#data_table_client_list')) {
              $('#data_table_client_list').DataTable().destroy();
            };
            setTimeout(() => {
              const table: any = $('#data_table_client_list');
              table.DataTable({ "order": [], "destroy": true, "search": { regex: false, smart: false }, "columnDefs": [{ "orderable": false, "targets": 0 }, { "orderable": false, "targets": 7 }, { "orderable": false, "targets": 8 }, { "orderable": false, "targets": 9 }] });
            }, 1000);
          } else {
            this.clientList = [];
            this.chRef.detectChanges();
            setTimeout(() => {
              const table: any = $('#data_table_client_list');
              table.DataTable({ "order": [], "destroy": true,"search": { regex: false, smart: false }, "columnDefs": [{ "orderable": false, "targets": 0 }, { "orderable": false, "targets": 7 }, { "orderable": false, "targets": 8 }, { "orderable": false, "targets": 9 }] });
            }, 2000);
            this.alertService.sweetMessage('error', data.responseMessage);
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      );
      // if ($.fn.DataTable.isDataTable('#data_table')){
      //     $('#data_table').DataTable().destroy();
      //    };
      // const table: any = $('#data_table');
      // this.dataTable   = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 7 },{ "orderable": false, "targets": 8 }]});
      // if ($.fn.DataTable.isDataTable('#data_table')){
      //   $('#data_table').DataTable().destroy();
      //  };
      //  const table: any = $('#data_table');
      //  this.dataTable = table.DataTable({"order": [], "destroy": true,"columnDefs": [{ "orderable": false, "targets": 7 },{ "orderable": false, "targets": 8 }]});
    }
  }

  // Getting assets associated with the client
  getAssetsOfClient() {
    $('#closemodalUsers').click();
    this.tempAssetList = [];
    // var URL = 'asset/getAssetByClient?clientId=' + this.clientdetailId;
    var URL = 'asset/getAssetByClient';
    let reqObj:any = {
      "clientId": this.clientdetailId,
    
     }
    this.apiService.create(URL, reqObj).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.clientAssetList = data.responseList;

          this.clientAssetList.map((obj, index) => {
            obj.isSelected = obj.isAssetLive;
            this.tempAssetList.push(obj);
          })

          // this.tempAssetList = JSON.parse(JSON.stringify(this.clientAssetList));
          $('.assets').click();
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // Asset Go Live Function
  assetsGoLive() {

    var _this = this;
    // Deep copy
    // var newObject = jQuery.extend(true, {}, _this.clientGoLive);

    // Deep copy
    // var newObject = jQuery.extend(true, {}, _this.clientGoLive);
    var newObject = {
      "clientId": '',
      "isLive": '',
      "asset": { "assets": '' }
    }

    // console.log(this.assetArray.assets)

    if (this.assetArray.assets.length > 0) {
      newObject.clientId = this.clientGoLive.clientId;
      newObject.isLive = this.clientGoLive.isLive
      newObject.asset.assets = this.assetArray.assets;
    } else {
      newObject.clientId = this.clientGoLive.clientId;
      newObject.isLive = this.clientGoLive.isLive
      newObject.asset.assets = this.blankArray;
    }

    this.clientGoLive.asset = this.assetArray.assets;

    console.log("newobj================", newObject)
    // this.apiService.update('asset/makeAssetGoLive', this.assetArray).subscribe(
    this.apiService.update('client/makeClientGoLive', newObject).subscribe(
      data => {
        if (data.statusCode == 100) {
          let response = data.responseList;
          if (response) {
            let flag = 1;
            Object.keys(response).forEach(key => {
              let curAssetId = response[key]['assetId'];
              let curIsLive = response[key]['islive'];
              if (curIsLive == 'no') {
                flag = 0;
                $('#' + curAssetId).parent('tr').css('background', '#e8b8bf');
              }
            });

            if (flag == 1) {
              // $('#shwAssetMsgSucc').css('display', 'block');
              $('#shwAssetMsg').css('display', 'none');
              this.assetsOk();
            } else {
              $('#shwAssetMsg').css('display', 'block');
              $('#shwAssetMsgSucc').css('display', 'none');
            }

            // $('#shwAssetMsg').css('display', 'block');
            $('.assetOkBtn').css('display', 'block');
            $('.assetLiveBtn').css('display', 'none');
          } else {
            $("#closemodalAssets").click();
            this.getClientList();
          }
          // $("#closemodalAssets").click();
          // this.getClientList();

        } else if (data.statusCode == 111) {
          $("#closemodalAssets").click();
          this.getClientList();
          this.alertService.sweetMessage('success', data.responseMessage);
        } else {
          this.alertService.sweetMessage('error', data.responseMessage);
        }
      },
      error => {
        this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
      }
    );
  }

  // Setting Array for asset to make it live
  setAssetArray(val, event) {
    console.log(val, "=============================val++++++++++++event=========================", event)
    if (event.target.checked) {
      var Object1 = {
        "assetId": val
      }

      //var exist = this.assetArray.indexOf(item.assetId);
      //if(exist == '-1'){
      this.assetArray.assets.push(Object1);
      //}
    } else {
      let IndeXVal = -1
      for (let index = 0; index < this.assetArray.assets.length; index++) {
        const element = this.assetArray.assets[index];
        if (element.assetId == val) {
          IndeXVal = index
          break;
        }
      }
      if (IndeXVal > -1) {
        this.assetArray.assets.splice(IndeXVal, 1)
      }

    }

    // console.log(this.assetArray.assets);

  }

  showmsg() {
    $('#shwUserMsg').css('display', 'block');
    $('.nextBtn').css('display', 'none');
    $('.okBtn').css('display', 'block');
  }

  assetsOk() {
    $('#closemodalAssets').click();
    this.getClientList();
    // this.alertService.sweetMessage('success', "Client is live now.");
    this.alertService.sweetMessage('success', "Client is marked live successfully.");
  }

  

  onItemSelect(event) {
    console.log("===========on item select=============", this.selectedItems)
    setTimeout(() => {
      this.selectedItems.map((obj, index) => {
        this.clientAssetList.map((items) => {
          if (obj.tierId == items.tier.tierId) {
            var exist = _.findIndex(this.tempAssetList, function (o) { return o.assetId == items.assetId; });
            // var exist = this.tempAssetList.indexOf(items.tier.tierId);
            console.log(exist);

            if (exist == -1) {
              items.isSelected = items.isAssetLive
              this.tempAssetList.push(items);
            }
          }
        })
      })
      if(this.selectAllCheckBox) {
      this.selectAll({ target: { checked: true } });
      }
    }, 500);
  }


  onItemDeselect(item) {
    this.clientAssetList.map((items) => {
      if (item.tierId == items.tier.tierId) {

        var exist = _.findIndex(this.tempAssetList, function (o) { return o.tier.tierId == items.tier.tierId; });
        console.log(exist);
        if (exist != -1) {
          this.tempAssetList.splice(exist, 1);
        }

      }
    })
    console.log(this.tempAssetList);
    
    setTimeout(() => {
      let assetIdArr = [];
      this.assetArray.assets.map((obj,index)=>{
        var exist = _.findIndex(this.tempAssetList, function (o) { return o.assetId == obj.assetId; });  
        console.log(exist ,"----------------======================");
         
        if (exist != -1) {
          assetIdArr.push(obj)
          console.log(assetIdArr);
          
        }
      })
      this.assetArray.assets = assetIdArr;
      console.log(this.assetArray.assets);
      
    }, 1000);

  }


  /*************************
  Filter the records on basis of drop down values
  **************************************/
  clientFilter(element) {
    /*
      Active&live
      InActive&live
      Active&NotLive
      InActive&NotLive
    */

    if ($.fn.DataTable.isDataTable('#data_table_client_list')) {
      $('#data_table_client_list').DataTable().destroy();
      this.clientList = [];
    };

    var URL = 'client/getClientList';

    if (element != 'All') {

      // if(element == 'Active&live'){
      if (element == 'live') {
        // this.isDeactivated = 1;
        this.isLive = 1;
        // var pp  = '?isDeactivated='+this.isDeactivated+'&isLive='+this.isLive;
        var pp = '?isLive=' + this.isLive;
        URL = URL.concat(pp);
      }

      // if(element == 'InActive&live') {
      //   this.isDeactivated = 0;
      //   this.isLive = 1;
      //   var pp  = '?isDeactivated='+this.isDeactivated+'&isLive='+this.isLive;
      //   URL = URL.concat(pp);
      // }

      // if(element == 'Active&NotLive'){
      if (element == 'NotLive') {
        this.isLive = 0;
        // this.isDeactivated = 1;
        // var pp  = '?isDeactivated='+this.isDeactivated+'&isLive='+this.isLive;
        var pp = '?isLive=' + this.isLive;
        URL = URL.concat(pp);
      }

      // if(element == 'InActive&NotLive') {
      if (element == 'InActive') {
        this.isLive = 0;
        // this.isDeactivated = 0;
        // var pp  = '?isDeactivated='+this.isDeactivated+'&isLive='+this.isLive;
        var pp = '?isLive=' + this.isLive;
        URL = URL.concat(pp);
      }
      if (this.clientFilterId && this.clientFilterId != '' && this.clientFilterId != 0) {
        var params = '&clientId=' + this.clientFilterId;
        URL = URL.concat(params);
      }

    } else {
      this.isLive = 2;
      this.isDeactivated = 2;
      URL = 'client/getClientList';

      if (this.clientFilterId && this.clientFilterId != '' && this.clientFilterId != 0) {
        var params = '?clientId=' + this.clientFilterId;
        URL = URL.concat(params);
      }
    }

    this.clientList = [];
    this.apiService.getAll(URL).subscribe(
      data => {
        if (data.statusCode == 100) {
          this.clientList = data.responseList;
          this.chRef.detectChanges();
          const table: any = $('#data_table_client_list');
          this.dataTable = table.DataTable({
            "order": [], "destroy": true,
            "columnDefs": [{ "orderable": false, "targets": 0 }, { "orderable": false, "targets": 7 }, { "orderable": false, "targets": 8 }, { "orderable": false, "targets": 9 }]
          });
        } else {
          this.clientList = [];
          this.chRef.detectChanges();
          setTimeout(() => {
            const table: any = $('#data_table_client_list');
            this.dataTable = table.DataTable({
              "order": [], "destroy": true,
              "columnDefs": [{ "orderable": false, "targets": 0 }, { "orderable": false, "targets": 7 }, { "orderable": false, "targets": 8 }, { "orderable": false, "targets": 9 }]
            });
          }, 1000);
        }
      },
      error => { this.alertService.errorMessage(error, error.status, "Something went wrong, please try again "); }
    );
  }

  /* Delete Client */
  DeleteClient(clientdata) {
    this.clientdetailId = clientdata.clientId;
    Swal({
      title: 'Are you sure?',
      text: "You want to delete this Client.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.apiService.delete('client/deleteClient?clientId=' + this.clientdetailId, '').subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getClientList();
              this.chRef.detectChanges();
              const table: any = $('.table');
              this.dataTable = table.DataTable();
              Swal({
                position: 'top-end',
                type: 'success',
                title: 'Client deleted successfully',
                showConfirmButton: false,
                timer: 3500
              });

            } else {
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 3500
              })
            }
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }


  /* Client Live Function */
  goClientLive(clientdata) {
    this.clientdetailId = clientdata.clientId;
    Swal({
      title: 'Are you sure?',
      text: "Do you wish to make this client live?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        let obj = clientdata;

        this.clientGoLive.clientId = this.clientdetailId;
        var goLiveObj = {
          "clientId": this.clientGoLive.clientId,
          "isLive": this.clientGoLive.isLive,
          "asset": null
        }
        this.apiService.update('client/makeClientGoLive', goLiveObj).subscribe(
          data => {
            if (data.statusCode == 100) {
              this.getClientList();
              this.clientUserList = data.responseList;
              // console.log(data.responseList[0].message)
              if (data.responseList[0].message != "SUCCESS") {
                this.MsgBoxError = data.responseList[0].message
              }
              this.getTierbyClient(this.clientGoLive.clientId);
              $('#shwUserMsg').css('display', 'block');
              $('.users').click();
            } else {
              Swal({
                position: 'top-end',
                type: 'error',
                title: data.responseMessage,
                showConfirmButton: false,
                timer: 3500
              })
            }
            $('.assetOkBtn').css('display', 'none');
            $('.assetLiveBtn').css('display', 'block');
            this.selectAllCheckBox = false;
          },
          error => {
            this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
          }
        );
      }
    })
  }



  selectAll(event) {
      console.log("-------------------1");
      if (event.target.checked) {
      console.log("-------------------2");
      
      this.tempAssetList.map((item, index) => {
        if (!item.isAssetLive /* && item.totalUser != 0 */) {
          this.tempAssetList[index].isSelected = true;
          console.log(item.isAssetLive, item.isSelected);
          
          var exist = _.findIndex(this.assetArray.assets, function (o) { return o.assetId == item.assetId; });
          if (exist == -1) {
            var Object1 = {
              "assetId": item.assetId
            }
            this.assetArray.assets.push(Object1);
          }

        }
      })
      console.log(this.tempAssetList, "-------------------");
      
    } else {
      this.tempAssetList.map((item) => {
        if (!item.isAssetLive) {
          item.isSelected = false;
          let IndeXVal = -1
          for (let index = 0; index < this.assetArray.assets.length; index++) {
            const element = this.assetArray.assets[index];
            if (element.assetId == item.assetId) {
              IndeXVal = index
              break;
            }
          }
          if (IndeXVal > -1) {
            this.assetArray.assets.splice(IndeXVal, 1)
          }
        }
      })
    }
  }


  onSelectAll(event) {

  }

  onDeSelectAll() {
    this.tempAssetList = [];
    this.assetArray.assets = []
  }

  checkChange(event) {
    console.log(event.target.checked);
    
    if (!event.target.checked) { 
      this.selectAllCheckBox = false;      
    }    
  }


getHistory(id){
  this.HistoryList = []
  if ($.fn.DataTable.isDataTable('#HistoryDatatable')) {
    $('#HistoryDatatable').DataTable({ "order": [], "destroy": true }).destroy();
  };
      this.apiService.getAll('client/getClientHistoryById?clientId=' + id).subscribe(
        data => {
          if (data.statusCode == 100) {
            this.HistoryList = data.responseList;
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true, "search": { regex: false, smart: false }, "columnDefs": [{ "orderable": false, "targets": 0 }] });
            }, 500);
          } else {
            setTimeout(() => {
              const table: any = $('#HistoryDatatable');
              this.dataTable = table.DataTable({ "order": [], "destroy": true, "search": { regex: false, smart: false }, "columnDefs": [{ "orderable": false, "targets": 0 }] });
            }, 500);
            Swal({
              position: 'top-end',
              type: 'error',
              title: data.responseMessage,
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
        error => {
          this.alertService.errorMessage(error, error.status, "Something went wrong, please try again ");
        }
      ); 
}
DateFormatEvent(startDate, gmtOffset){
  let d1  = new Date(startDate)
  var d = moment(startDate, "YYYY-MM-DD HH:mm:ss:SSSZ").toDate();
  
  if(startDate){
    var formattedDate = /* moment(d1).format("MM-DD-YYYY HH:mm:ss")// */moment(d).format("MM-DD-YYYY HH:mm:ss");
    return formattedDate;
  }else{
    return "";
  }
}

}
